import React from "react";
import { observer } from "mobx-react";
import { IReactionDisposer, reaction } from "mobx";
//
import { E5BandEnum, E5BandEnumIsEth, E5NetElementType, E5NodeTypeEnum } from "../../../entity/E5Enums";
import { E5EntHMetWifi, E5HMetWifiField } from "../../../entity/household/metric/E5EntHMetWifi";
import { E5XYChart } from "../../../global/plot/E5XYChart";//eslint-disable-line
import { E5EntWifiChanTime } from "../../../entity/E5EntWifiChanTime";
import { E5Store, E5StoreLangInfo } from "../../../store/E5Store";
import { E5RequestMeta } from "../../../request/E5RequestMeta";
import { E5PieChart } from "../../../global/plot/E5PieChart";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5HMetricIndic } from "./E5HMetricIndic";
import { E5HMetric } from "./E5HMetric";
import {
	E5StoreHTopoSelected, E5StoreHStations, E5StoreHEquips, E5StoreHMetWifi, E5StoreH
} from "../../../store/E5StoreH";
//
import "./E5HMetricWifiLink.css";
import { E5EntHEquip } from "../../../entity/household/topo/E5EntHEquip";
import { Select, OutlinedInput, MenuItem, InputLabel, FormControl } from '@mui/material'
import { Spinner } from "@blueprintjs/core";
import { channelBandChangesChartOptions } from "../../../util/E5HMetricWiFiLinkUtils";
import ReactECharts from 'echarts-for-react';


//E5
interface E5HMetricWifiLinkState {
	mindictype: E5NetElementType;
	mindicname: string;
	mindiceth?: number;
	mindicwifi?: number;

	leftsource: string[];
	leftfield: (E5HMetWifiField | null)[];
	leftfieldoption: string[];

	rightsource: string[];
	rightfield: (E5HMetWifiField | null)[];
	rightfieldoption: string[];

	xydata: any;
	chanconntimes: E5EntWifiChanTime[];

	pieids: string[];
	pieparents: string[];
	pievalues: number[];
	pielabels: string[];

	forceUpdate: boolean;

	channelBandChartData: string[][];
}

//E5
interface E5HMetricWifiLinkProps {
	langinfo: E5StoreLangInfo;
	equipinfo: E5StoreHEquips;
	stationinfo: E5StoreHStations;
	selectedinfo: E5StoreHTopoSelected;
	metricinfo: E5StoreHMetWifi;
	station?: string | null;
}

//E5
export const E5HMetricWifiLink = observer(class E5HMetricWifiLink extends React.PureComponent
	<E5HMetricWifiLinkProps, E5HMetricWifiLinkState> {

	// ---------------- MEMBERS ----------------

	//E5
	stop_change_selected_item?: IReactionDisposer;
	stop_change_curlang?: IReactionDisposer;
	selected_is_not_gw?: boolean;
	options: JSX.Element[];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HMetricWifiLinkProps, state: E5HMetricWifiLinkState) {
		super(props, state);
		let { fields } = E5EntHMetWifi, udf: undefined = undefined;
		this.options = this.BuildOptions();
		this.CheckSelectedIsNotGW();
		this.state = {
			mindictype: E5NetElementType.none, mindicname: "", mindiceth: udf, mindicwifi: udf,

			leftsource: ["rssi", "wifi", "noise", "airtime"],
			leftfield: [fields[11], fields[14], fields[12], fields[0]], leftfieldoption: ["", "", "", ""],

			rightsource: ["wifi", "wifi", "wifi", ""],
			rightfield: [fields[14], fields[14], fields[14], null], rightfieldoption: ["", "", "", ""],

			xydata: {
				left: [
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf }
				],
				right: [
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf },
					{ numdatas: udf, categdata: udf }, { numdatas: udf, categdata: udf }
				]
			},

			chanconntimes: [], pieids: [], pieparents: [], pievalues: [], pielabels: [], channelBandChartData: [],
		};
	}

	//E5
	componentDidMount(): void {
		if (this.props.station) {
			const sta = E5StoreH.Ins().stationinfo.stations.find(station => station.macaddress === this.props.station)
			if (sta) {
				E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.device, new E5EntHEquip(), sta);
			}
		}
		this.BuildAll();
		this.stop_change_selected_item = reaction(() => {
			let { type, equip, station } = this.props.selectedinfo;
			return String(E5StoreH.Ins().metwifiinfo.loading) + (type === E5NetElementType.node ?
				equip.imei : station.macaddress);
		}, () => this.BuildAll());
		this.stop_change_curlang =
			reaction(() => this.props.langinfo.curlang, () => this.options = this.BuildOptions());
	}
	componentWillUpdate() {
		if (!E5StoreH.Ins().selectedinfo.station.macaddress) {
			const stationSelected = E5StoreH.Ins().stationinfo?.stations[0];

			if (stationSelected) {
				this.handleChange(stationSelected.macaddress, true)
			}
		}
	}
	//E5
	componentWillUnmount(): void {
		this.stop_change_selected_item?.();
		this.stop_change_curlang?.();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { type, equip, station } = this.props.selectedinfo, { node, device } = E5NetElementType;
		let scorevalue: number | undefined = undefined;
		if (this.selected_is_not_gw === true)
			scorevalue = type === node ? equip.health : type === device ? station.health : undefined;

		let xytitle: string = _("wifih-metrics-charttitle");
		if (this.selected_is_not_gw === true)
			xytitle +=
				type === device ? " (" + _("netelementtype-" + device) + " " + station.macaddress + ")" :
					type === node ? " (" + _("netelementtype-" + node) + " " + equip.imei + ")" : "";

		let descs: string[] = ["", "", "", ""], descmap: Map<string, string> = new Map();
		//descmap.set("airtime", "wifih-metrics-airtimeisparent");
		//descmap.set("noise", "wifih-metrics-noiseisparent");
		descmap.set("lastratelink", "wifih-metrics-lastratelinkisparent");
		descmap.set("lastratelink_up", "wifih-metrics-lastratelinkupisparent");
		descmap.set("lastratelink_down", "wifih-metrics-lastratelinkdownisparent");
		descmap.set("byterate", "wifih-metrics-byterateisparent");
		descmap.set("byterate_up", "wifih-metrics-byterateupisparent");
		descmap.set("byterate_down", "wifih-metrics-byteratedownisparent");
		descmap.set("packrate", "wifih-metrics-packrateisparent");
		descmap.set("packrate_up", "wifih-metrics-packrateupisparent");
		descmap.set("packrate_down", "wifih-metrics-packratedownisparent");
		descmap.set("pack", "wifih-metrics-packisparent");
		let idx: number, name: string, desc: string;
		for (idx = 0; idx < 4; idx++)
			for ([name, desc] of descmap)
				if (this.state.leftsource[idx] === name || this.state.rightsource[idx] === name) {
					if (descs[idx] !== "") descs[idx] += ", ";
					descs[idx] += _(desc);
				}


		let pie2ids: string[] = ["WiFi"], pie2parents: string[] = [""], pie2values: number[] = [0];
		let pie2labels: string[] = ["WiFi"];

		this.state.chanconntimes.forEach(value => {
			pie2ids.push(value.band)
			pie2values.push(0)
			pie2parents.push('WiFi')
			pie2labels.push(value.band)

			value.chantime.forEach((v, key) => {
				pie2ids.push(`${value.band}:${key}`)
				pie2values.push(v)
				pie2parents.push(value.band)
				pie2labels.push(`Channel ${key}`)
			})

		})
		return <div className="e5wifih-metrics-content e5columnfull  e5wifih-metrics-wifi-link">
			{/* <div className="tabdesc">{E5UtilI18n._("wifih-metrics-wifi-link-tab-desc")}</div> */}
			<div className="e5wifih-metrics-components e5column-20">
				<div className="e5line-20">
					<div className="e5linefull e5column-20">
						<div className="e5wifih-metrics-links e5column-20">
							<E5HMetricIndic langinfo={E5Store.Ins().langinfo} elemtype={this.state.mindictype}
								elemname={this.state.mindicname} avgeth={this.state.mindiceth}
								avgwifi={this.state.mindicwifi} health={scorevalue} mode={'wifi'}>
								<FormControl className={"filterbloc"} sx={{ m: 1, width: 250 }}>
									<InputLabel id="name-label">Station</InputLabel>
									<Select labelId="name-label" onChange={this.handleChange} value={E5StoreH.Ins().selectedinfo.station.macaddress} input={<OutlinedInput label="Station" />}>
										<MenuItem value={''}>
											{E5UtilI18n._('wifih-metric-select-station')}
										</MenuItem>

										{E5StoreH.Ins().stationinfo.stations.map((station) => {
											return <MenuItem value={station.macaddress} key={station.macaddress}>
												<div className='e5wifih-metrics-station-options'>
													<div className="e5-align-center">
														<img src={this.GetDeviceImage(station.macaddress)} className='e5wifih-metrics-station-icon' alt={station.devicetype} />
													</div>

													<div className="e5wifih-metrics-nodes-select-wrapper">
														{station.macaddress}
													</div>
												</div>
											</MenuItem>
										})}
									</Select>
								</FormControl>
							</E5HMetricIndic>
							<div className="e5column-20 e5metrics-scrollable">
								<div className="e5line-20">
									<E5PieChart pieinfo={{
										parents: this.state.pieparents, loading: this.props.metricinfo.loading,
										title: _("wifih-metrics-pietitle"), ids: this.state.pieids,
										values: this.state.pievalues, labels: this.state.pielabels,
										valueisseconds: true, labelisincident: false
									}} withNewComponent chartOption={{ type: 'sunburst', parentId: 'WiFi' }} />
									<E5PieChart pieinfo={{
										title: _("wifih-metrics-chanconntime"), ids: pie2ids, parents: pie2parents, values: pie2values, labels: pie2labels,
										loading: this.props.metricinfo.loading, valueisseconds: true, labelisincident: false
									}} withNewComponent chartOption={{ type: 'sunburst', parentId: 'WiFi' }} />
								</div>
								<div className="e5compo">
									<div className="e5compotitle" style={{ height: 20 }}>{`${E5UtilI18n._('wifih-metrics-ovrview-header')} ${this.props?.selectedinfo?.equip?.imei})`}</div>
									<div className="e5compotsubtitile">{_("wifih-metrics-chartsubtitle")}</div>
									{this.RenderChart(0, descs, E5UtilI18n._('wifih-metrics-chart-titile-1'), null, true)}
									{this.RenderChannelBandChart()}
									{this.RenderChart(2, descs, E5UtilI18n._('wifih-metrics-chart-titile-3'), null, true)}
									{this.RenderChart(3, descs, E5UtilI18n._('wifih-metrics-chart-titile-4'), null, true)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}

	//E5
	GetDeviceImage(macAddress: string): string {
		const device = E5StoreH.Ins()?.indicglobalinfo?.fingerprintresult.find((fingerprint) => fingerprint.childId === macAddress)?.deviceType;

		switch (device?.toLowerCase()) {
			case 'computer':
				return './img/v3/metrics/wifi-link/computer.png';
			case 'game console':
				return './img/v3/metrics/wifi-link/control.png';
			case 'third party ap':
				return './img/v3/metrics/wifi-link/router.png';
			case 'printer':
				return './img/v3/metrics/wifi-link/printer.png';
			case 'multimedia device':
				return './img/v3/metrics/wifi-link/screen.png';
			case 'sound device':
				return './img/v3/metrics/wifi-link/sound.png';
			case 'iot device':
				return './img/v3/metrics/wifi-link/iot.png';
			case 'smart device':
				return './img/v3/metrics/wifi-link/smart.png';
			case 'smartphone':
			case 'tablet':
			case 'smartphone or tablet':
				return './img/v3/metrics/wifi-link/smartphone.png';
			case 'unknown':
			default:
				return './img/v3/metrics/wifi-link/unknown.png';
		}
	}

	//E5
	RenderChart = (idx: number, descs: string[], title?: string, subtitlenormal?: string | null, withFullDayXAxis?: boolean | null): JSX.Element => <div>
		<div className="e5wifih-metrics-desc">'{descs[idx]}'</div>

		<E5XYChart loading={this.props.metricinfo.loading} height={200} baseYaxis withFullDayXAxis={withFullDayXAxis}
			leftsource={this.state.xydata.left[idx]} rightsource={{}} subtitlenormal={subtitlenormal ? subtitlenormal : null} 
			title={title ? title : null} withNewComponent chartOption={{ withoutSymbol: true, logarithmic: idx === 3 }} />
	</div>;


	handleChange = (e: any, isValue: boolean) => {
		let value: string;
		if (isValue && typeof isValue !== 'object') {
			value = e;
		} else {
			value = e.target.value

		}

		const sta = E5StoreH.Ins().stationinfo.stations.find(station => station.macaddress === value)

		if (sta) {
			E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.device, new E5EntHEquip(), sta);
			this.setState({ forceUpdate: true })
		}
	}

	//E5
	BuildOptions = (): JSX.Element[] => {
		let opts: JSX.Element[] = [<option value="" key={-1} id="" />], idx: number, idx2: number;
		for (idx = 0; idx < E5EntHMetWifi.fields.length; idx++) {
			let item: E5HMetWifiField = E5EntHMetWifi.fields[idx], unit: string = item.unit;
			if (unit !== "") unit = " [" + unit + "]";
			if (item.options === undefined)
				opts.push(<option value={item.name} key={idx} id={"" + idx}>
					{E5UtilI18n._("wifih-metrics-name-" + item.name) + unit}
				</option>);
			else {
				opts.push(<option key={idx} value={"~" + item.name} disabled>
					{E5UtilI18n._("wifih-metrics-name-" + item.name) + unit}
				</option>);
				let strarr: string[];
				// if (item.name === "s")
				// 	strarr = E5BackEndMeta.Ins().wifih_anomalycodes;
				// else
				strarr = item.options;
				for (idx2 = 0; idx2 < strarr.length; idx2++) {
					let opt: string = strarr[idx2];
					opts.push(<option key={(idx + 1) * 1000 + idx2} value={item.name + opt} id={"" + idx + "-" + idx2}>
						{" • " + E5UtilI18n._("wifih-metrics-name-" + item.name + opt)}
					</option>);
				}
			}
		}
		return opts;
	};

	// ---------------- EVENTS ----------------

	//E5
	ChangeField = (event: React.ChangeEvent<HTMLSelectElement>, idx: number, side: "left" | "right"): void => {
		let st: Partial<E5HMetricWifiLinkState> = {
			leftsource: JSON.parse(JSON.stringify(this.state.leftsource)),
			leftfield: JSON.parse(JSON.stringify(this.state.leftfield)),
			leftfieldoption: JSON.parse(JSON.stringify(this.state.leftfieldoption)),
			rightsource: JSON.parse(JSON.stringify(this.state.rightsource)),
			rightfield: JSON.parse(JSON.stringify(this.state.rightfield)),
			rightfieldoption: JSON.parse(JSON.stringify(this.state.rightfieldoption))
		};

		let { options, selectedIndex, value } = event.currentTarget, opt: HTMLOptionElement = options[selectedIndex];
		let selectedfield: E5HMetWifiField | null = null, selectedopt: string = "";
		let selectedidx: number = -1, selectedoptidx: number = -1, pos: number = -1;
		if (opt.id !== "") {
			pos = opt.id.indexOf("-");
			if (pos < 0) {
				selectedidx = parseInt(opt.id);
				selectedfield = E5EntHMetWifi.fields[selectedidx];
			} else {
				selectedidx = parseInt(opt.id.substr(0, pos));
				selectedoptidx = parseInt(opt.id.substr(pos + 1));
				selectedfield = E5EntHMetWifi.fields[selectedidx];
				if (Array.isArray(selectedfield.options))
					if (selectedfield.options.length > 0) selectedopt = selectedfield.options[selectedoptidx];
					else selectedopt = E5RequestMeta.Ins().h_anomalycodes[selectedoptidx];
			}
		}

		if (st.leftsource !== undefined && st.rightsource !== undefined)
			if (side === "left") st.leftsource[idx] = value;
			else st.rightsource[idx] = value;

		if (st.leftfield !== undefined && st.rightfield !== undefined)
			if (side === "left") st.leftfield[idx] = selectedfield;
			else st.rightfield[idx] = selectedfield;

		if (st.leftfieldoption !== undefined && st.rightfieldoption !== undefined)
			if (side === "left") st.leftfieldoption[idx] = selectedopt;
			else st.rightfieldoption[idx] = selectedopt;

		this.setState(st as E5HMetricWifiLinkState, () => {
			let st2: Partial<E5HMetricWifiLinkState> = {};
			E5HMetric.BuildGraphData(false, this.state, "", this.props.selectedinfo,
				this.props.equipinfo, this.props.metricinfo, st2);
			this.setState(st2 as E5HMetricWifiLinkState);
		});
	};

	// ---------------- UTILS ----------------

	//E5
	BuildSelectedData(st: Partial<E5HMetricWifiLinkState>): void {
		let { type, equip, station } = this.props.selectedinfo;
		st.mindictype = type;
		st.mindicname =
			type === E5NetElementType.node ? equip.imei : type === E5NetElementType.device ? station.macaddress : "";
	}

	//E5
	CheckSelectedIsNotGW(): void {
		this.selected_is_not_gw = true;
		if (this.props.selectedinfo.type === E5NetElementType.node)
			if (this.props.selectedinfo.equip.nodetype === E5NodeTypeEnum.gw)
				this.selected_is_not_gw = false;
	}

	//E5
	BuildAll(): void {
		this.CheckSelectedIsNotGW();
		if (this.selected_is_not_gw === true) {
			let { selectedinfo, metricinfo } = this.props, st: Partial<E5HMetricWifiLinkState> = {};
			E5HMetric.BuildGraphData(false, this.state, "", selectedinfo, this.props.equipinfo,
				metricinfo, st);
			E5HMetric.BuildAverageData(false, "", selectedinfo, metricinfo, st);
			E5HMetric.BuildPieData(false, "", selectedinfo, metricinfo, st);
			this.BuildChanData();
			this.BuildSelectedData(st);
			this.BuildChannelBandChartData(metricinfo);
			this.setState(st as E5HMetricWifiLinkState);
		}
	}

	//E5
	BuildChannelBandChartData(metricinfo: E5StoreHMetWifi): void {
		const currentEquipment = E5StoreH.Ins().selectedinfo.station.macaddress;
		const metricsFromCurrentEquipment = metricinfo.metrics.filter(metric => metric.devicemac === currentEquipment && !E5BandEnumIsEth(metric.wifiband) && metric.wifiband !== E5BandEnum.none);
		const parsedMetrics = metricsFromCurrentEquipment.map(metric => {
			return [metric.starttime.format('YYYY-MM-DD HH:mm'), `${metric.wifiband.substring(0,1)}G-ch${metric.wifichannel}`, E5UtilI18n._('wifih-metrics-chart-titile-2')]
		});
		this.setState({ channelBandChartData: parsedMetrics })
	}

	//E5
	RenderChannelBandChart(): JSX.Element {
		return (
			<div className="e5hactivity-box">
				<span className="e5hactivity-graph-title">{E5UtilI18n._('wifih-metrics-chart-titile-2')} {this.props.metricinfo.loading ? <Spinner className="e5spinwait" size={15} /> : null}</span>

				<ReactECharts option={channelBandChangesChartOptions(this.state.channelBandChartData)} />
			</div>
		);
	}

	//E5
	BuildChanData = (): void => {
		let { type, equip, station } = this.props.selectedinfo, { none, node, device } = E5NetElementType;
		let chanconntimes: E5EntWifiChanTime[] = [];
		if (type !== none) {
			let idx: number, idx2: number, chansetmap: Map<string, Set<number>> = new Map();
			for (idx = 0; idx < this.props.metricinfo.metrics.length; idx++) {
				let { wifiband, nodeimei, devicemac, wifichannel, duration } = this.props.metricinfo.metrics[idx];

				if (E5BandEnumIsEth(wifiband) || wifiband === E5BandEnum.none) continue;
				if ((type !== node || nodeimei !== equip.imei) && (type !== device || devicemac !== station.macaddress))
					continue;

				let chanconntime: E5EntWifiChanTime = new E5EntWifiChanTime();
				let chanset: Set<number> | undefined = chansetmap.get(wifiband);
				if (chanset === undefined) {
					chanconntime.band = wifiband;
					chanconntimes.push(chanconntime);
					chanset = new Set();
					chanset.add(wifichannel);
					chanconntime.chantime.set(String(wifichannel), duration);
					chansetmap.set(wifiband, chanset);
				} else {
					for (idx2 = 0; idx2 < chanconntimes.length; idx2++)
						if (chanconntimes[idx2].band === wifiband) {
							chanconntime = chanconntimes[idx2];
							break;
						}
					let chan: string = String(wifichannel);
					if (!chanset.has(wifichannel)) {
						chanset.add(wifichannel);
						chanconntime.chantime.set(chan, duration);
					} else {
						let conntime: number | undefined = chanconntime.chantime.get(chan);
						if (conntime !== undefined) {
							conntime += duration;
							chanconntime.chantime.set(chan, conntime);
						}
					}
				}
			}
		}
		this.setState({ chanconntimes });
	};
});
