import React from "react";

import { E5Text } from "../../../util/E5Text";
import { E5Time } from "../../../util/E5Time";
import Table from '../../../global/component/E5Table'
import "./E5HEventLog.css";
import { StringLiteral } from "typescript";
import { E5UtilI18n } from "../../../global/E5MainLang";

type Event = {
    eventTime: number;
    deviceImei: StringLiteral;
    eventType: string;
}

interface E5HEventLogTableProps {
    data: any | Event[];
    eventType: string;
    chosenItems: string[]
    langinfo: any;
}

export const E5HEventLogTable: React.FC<E5HEventLogTableProps> = ({ data, eventType, chosenItems, langinfo }) => {
    const headerTitles: { [key: string]: string } = {
        'EVT_NAME': E5UtilI18n._("cb-eventlog-event-name"),
        "STA_MAC": E5UtilI18n._("cb-eventlog-station-mac"),
        'AP_NAME': E5UtilI18n._("cb-eventlog-access-point"),
        'REASON': E5UtilI18n._("cb-eventlog-reason"),
        'BAND_FROM': E5UtilI18n._("cb-eventlog-previous-band"),
        'BAND_TO': E5UtilI18n._("cb-eventlog-new-band")
    };

    const filteredData = [...data]
        .filter((eventLog: any) => eventLog.eventType === eventType)
        .filter((eventLog: any) => {
            if (chosenItems.length) {
                return chosenItems.includes(eventLog.deviceImei);
            }
            return true;
        });

    let columnsNames: string[] = []

    filteredData.forEach(eventLog => {
        if (Object.keys(eventLog).length > columnsNames.length) {
            columnsNames = Object.keys(eventLog);
        }
    });

    return (
        <div className='event-table'>
            <Table
                pageSize={25}
                checkboxSelection={false}
                className=''
                columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    accessPoint: eventType === '1' ? false : true,
                    reason: eventType === '1' ? false : true,
                }}
                columns={[
                    {
                        id: false,
                        field: 'eventTIME',
                        headerName: E5UtilI18n?._("cb-eventlog-event-time"),
                        hide: true,
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 100,
                        width: 100,
                        renderCell: (params) => {
                            return params.row.eventTime;
                        }
                    },
                    {
                        field: 'deviceEmei',
                        headerName: E5UtilI18n?._("cb-eventlog-device"),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 150,
                        renderCell: (params) => {
                            return params.row['Device IMEI'];
                        }
                    },
                    {
                        field: 'eventName',
                        headerName: E5UtilI18n?._("cb-eventlog-event-name"),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 100,
                        renderCell: (params) => {
                            return params.row[E5UtilI18n?._("cb-eventlog-event-name")];
                        }
                    },
                    {
                        field: 'macAddress',
                        headerName: E5UtilI18n?._("cb-eventlog-station-mac"),
                        editable: true,
                        headerAlign: 'center',
                        align: 'center',
                        minWidth: 150,
                        renderCell: (params) => {
                            return params.row[E5UtilI18n?._("cb-eventlog-station-mac")];
                        }
                    },
                    {
                        field: 'accessPoint',
                        headerName: E5UtilI18n?._("cb-eventlog-access-point"),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 150,
                        renderCell: (params) => {
                            return params.row[E5UtilI18n?._("cb-eventlog-access-point")];
                        }
                    },
                    {
                        field: 'reason',
                        headerName: E5UtilI18n?._("cb-eventlog-reason"),
                        editable: true,
                        headerAlign: 'center',
                        align: 'left',
                        minWidth: 150,
                        renderCell: (params) => {
                            return params.row[E5UtilI18n?._("cb-eventlog-reason")];
                        }
                    }
                ]}
                rows={
                    [...filteredData.map((eventLog, index) => {
                        const result: any = {};
                        columnsNames.forEach(name => {
                            result[headerTitles[name]] = eventLog[name] || '-'
                        })
                        return {
                            id: index,
                            eventTime: E5Text.Tm2MomentTz(eventLog.eventTime, E5Time.h_tzoffset).format('HH:mm:ss'),
                            'Device IMEI': eventLog.deviceImei,
                            ...result
                        }
                    }
                    )]} />
        </div>
    )

}

