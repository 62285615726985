import React from 'react';
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5StoreHSearchNi} from "../../store/E5StoreH";
import {E5RequestH} from "../../request/E5RequestH";
import {E5StoreLangInfo} from "../../store/E5Store";
import {E5UtilI18n} from "../../global/E5MainLang";
import {E5Time} from "../../util/E5Time";
//
import "./E5HStatus.css"

//E5
interface E5HStatusState {}

//E5
interface E5HStatusProps {
	langinfo: E5StoreLangInfo;
	searchniinfo: E5StoreHSearchNi;
}

//E5
export const E5HStatus = observer(class E5HStatus extends React.PureComponent<E5HStatusProps, E5HStatusState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let datetimeformat = E5UtilI18n._("dateformat") + " HH:mm:ss",
			requesttimestr: string = this.props.searchniinfo.requesttime?.format(datetimeformat) ?? "",
			datatimestr: string = this.props.searchniinfo.datatime?.format(datetimeformat) ?? "",
			timezone: string = "";

		if (E5RequestH.timezoneposix !== "") {
			timezone = "" + E5Time.h_tzoffset;
			if (timezone.charAt(0) !== "-") timezone = "+" + timezone;
			timezone = "GMT " + timezone;
		}

		return <div className="e5wifihstatus e5line-10">
			<div>
				<BP.Tooltip content={E5UtilI18n._("wifih-status-networkid-tip")}>
					<div>
						<div className="e5wifihstatustitle">{E5UtilI18n._("wifih-status-networkid")} :</div>
						{this.props.searchniinfo.networkid}
					</div>
				</BP.Tooltip>
			</div>
			<div>
				<BP.Tooltip content={E5UtilI18n._("wifih-status-last-tip")}>
					<div>
						<div className="e5wifihstatustitle">{E5UtilI18n._("wifih-status-last")} :</div>
						{datatimestr}
					</div>
				</BP.Tooltip>
			</div>
			<div>
				<BP.Tooltip content={E5UtilI18n._("wifih-status-timezone-tip")}>
					<div>
						<div className="e5wifihstatustitle">{E5UtilI18n._("wifih-status-timezone")} :</div>
						{timezone}
					</div>
				</BP.Tooltip>
			</div>
			<div>
				<BP.Tooltip content={E5UtilI18n._("wifih-status-now-tip")}>
					<div>
						<div className="e5wifihstatustitle">{E5UtilI18n._("wifih-status-now")} :</div>
						{requesttimestr}
					</div>
				</BP.Tooltip>
			</div>
		</div>;
	}
});
