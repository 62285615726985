import moment from "moment";
import { action, makeObservable, observable } from "mobx";
//
import { E5Page, E5Path } from "../global/E5MainStatics";
import { E5MainConfig } from "../global/E5MainConfig";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5EntUser } from "../entity/E5EntUser";
import { E5Storage } from "../util/E5Storage";
import { E5RequestAdminRdm } from "../request/E5RequestAdminRdm";

// --------- CONTEXT -------------

//E5
export interface E5StoreLangInfo {
	curlang: string;
}

//E5
export interface E5StoreUserInfo {
	curuser: E5EntUser;
}

//E5
export interface E5StorePageInfo {
	curpage: string;
}

//E5
export interface E5StoreTodayInfo {
	today: string;//YYYY-MM-YY
}

// --------- REPORT -------------

//E5
export interface E5StoreReportInfo {
	inreportpage: boolean;
}

// --------- STORE ITSELF -------------

//E5
export class E5Store {

	// --------- STATIC MEMBERS -------------

	//E5
	private static ins: E5Store;

	//E5
	static Ins(): E5Store {
		if (E5Store.ins === undefined) E5Store.ins = new E5Store();
		return E5Store.ins;
	}

	// --------- STATIC STORE MODIFICATION -------------

	/*
	Good way to change the store
	- using this syntax :
		static SetLoadIncidents(status: E5BackEndStatus, incidents: E5EntIncident[]): void {
			E5Store.Ins().incidentinfo = {status: status, incidents: incidents};
		=> react components do not react to the change of incidentinfo
	- using this syntax :
		static SetLoadIncidents(status: E5BackEndStatus, incidents: E5EntIncident[]): void {
			E5Store.Ins().incidentinfo.status = status;
			E5Store.Ins().incidentinfo.incidents = incidents;
		=> there will be 2 executions of autorun() !
	- best way (make a transaction)
		@action
		static SetLoadIncidents(status: E5BackEndStatus, incidents: E5EntIncident[]): void {
			E5Store.Ins().incidentinfo.status = status;
			E5Store.Ins().incidentinfo.incidents = incidents;
	*/

	// --- context

	//E5
	ChangeLang(lang: string): void {
		// first change i18n lang
		E5UtilI18n.SetLanguage(lang);
		E5Store.Ins().langinfo.curlang = lang;
		E5RequestAdminRdm.Ins().LoadLists(lang);
	}

	//E5
	GetUser(): E5EntUser {
		return E5Store.Ins().userinfo.curuser;
	}

	//E5
	// history can be undefined (ChangeUser called when session expired)
	ChangeUser(user: E5EntUser, history: any): void {
		E5Store.Ins().userinfo.curuser = user;
		if (user.isconnected) {
			// save bearer and goto home page if connected
			E5Storage.SetLSString(E5MainConfig.ls_bearer, user.bearer);
			let changepage: string = E5Page.HOME;
			if (E5MainConfig.GetDevMode() && E5MainConfig.GetDevModePage() !== "")
				changepage = E5MainConfig.GetDevModePage();
			E5Store.Ins().ChangePage(changepage, history);
		} else {
			E5Storage.SetLSString(E5MainConfig.ls_bearer, "");
		}
	}

	//E5
	// history may be undefined (ChangeUser called when session expired)
	ChangePage(page: string, history: any): void {
		let url: string = E5Path.Get(page);
		if (history !== undefined && history.location.pathname !== url)
			history.push(url);
		E5Store.Ins().pageinfo.curpage = page;
	}

	//E5
	// today=YYYY-MM-DD
	ChangeToday(today: string): void {
		E5Store.Ins().todayinfo.today = today;
	}

	// --- report

	//E5
	ChangeReport(inreportpage: boolean): void {
		E5Store.Ins().reportinfo.inreportpage = inreportpage;
	}

	// --------- INSTANCE -------------

	// context
	langinfo: E5StoreLangInfo = { curlang: "" };
	userinfo: E5StoreUserInfo = { curuser: new E5EntUser() };
	pageinfo: E5StorePageInfo = { curpage: "" };
	todayinfo: E5StoreTodayInfo = { today: moment().format("YYYY-MM-DD") };
	// report
	reportinfo: E5StoreReportInfo = { inreportpage: false };

	//E5
	constructor() {
		makeObservable(this, {
			langinfo: observable, userinfo: observable, pageinfo: observable, todayinfo: observable,
			reportinfo: observable, ChangeLang: action, ChangeUser: action, ChangePage: action, ChangeToday: action,
			ChangeReport: action
		});
	}
}
