import {E5Store} from "../store/E5Store";

//E5
export enum E5Page {
	WELCOME = "welcome", CB_DASHBOARD = "cb_dashboard", CB_POPULATIONS = "cb_populations", H_DASHBOARD = "h_dashboard",
	H_INCIDENTS = "h_incidents", H_CONNECTIVITY = "h_connectivity", H_EVENTLOGS = "h_eventlogs", H_METRICS = "h_metrics",
	MANAGEMENT_USERS = "management_users", MANAGEMENT_RDM = "management_rdm", MANAGEMENT_ACL = "management_acl", PROFILE='profile',
	// test
	T_XY_CHART = "t_xy_chart", T_PIE_CHART = "t_pie_chart", T_RADAR_CHART = "t_radar_chart",
	T_ANGULAR_GAUGE = "t_angular_gauge", T_BULLET_GAUGE = "t_bullet_gauge", T_CB_POPULATION = "t_cb_population",
	T_H_INDIC = "t_h_indic", T_H_INCID_RECOM = "t_h_incid_recom", T_H_TOPO_ITEM = "t_h_topo_item",
	T_H_TOPO_GRAPH = "t_h_topo_graph", T_H_CONNECTION = "t_h_connection", T_H_HEATMAP = "t_h_heatmap",
	T_ADMIN_USER = "t_admin_user", WELCOME_HOW = 'how_it_works', DEVELOPERS = 'developers', HOME = 'home', SYSTEM_UPDATE = 'update', EQUIPMENTS = 'equipments', STATIONS = 'stations',
}

//E5
export class E5Path {
	//E5
	static page2path: any = {
		[E5Page.WELCOME]: "welcome", [E5Page.CB_DASHBOARD]: "CustomerBaseDashboard", [E5Page.H_EVENTLOGS]: "HouseholdEventLogs",
		[E5Page.CB_POPULATIONS]: "CustomerBasePopulations", [E5Page.H_DASHBOARD]: "HouseholdDashboard",
		[E5Page.H_INCIDENTS]: "HouseholdIncidents", [E5Page.H_CONNECTIVITY]: "HouseholdConnectivity",
		[E5Page.H_METRICS]: "HouseholdMetrics", [E5Page.MANAGEMENT_USERS]: "ManagementUsers",
		[E5Page.MANAGEMENT_RDM]: "ManagementEquipments", [E5Page.MANAGEMENT_ACL]: "ManagementAclGroups", [E5Page.PROFILE]: "Profile",
		// test
		[E5Page.T_XY_CHART]: "TestXYChart", [E5Page.T_PIE_CHART]: "TestPieChart",
		[E5Page.T_RADAR_CHART]: "TestRadarChart", [E5Page.T_ANGULAR_GAUGE]: "TestAngularGauge",
		[E5Page.T_BULLET_GAUGE]: "TestBulletGauge", [E5Page.T_CB_POPULATION]: "TestCBPopulation",
		[E5Page.T_H_INDIC]: "TestHIndic", [E5Page.T_H_INCID_RECOM]: "TestHIncidRecom",
		[E5Page.T_H_TOPO_ITEM]: "TestHTopoItem", [E5Page.T_H_TOPO_GRAPH]: "TestHTopoGraph",
		[E5Page.T_H_CONNECTION]: "TestHConnection", [E5Page.T_H_HEATMAP]: "TestHHeatmap",
		[E5Page.T_ADMIN_USER]: "TestAdminUser", [E5Page.WELCOME_HOW]:'How',[E5Page.DEVELOPERS]:'developers', [E5Page.HOME]:'', [E5Page.SYSTEM_UPDATE]: 'SystemMaintenance', [E5Page.STATIONS]: 'stations',[E5Page.EQUIPMENTS]: 'equipments'
	};

	//E5
	static Get = (page: string): string => "/" + this.page2path[page];

	//E5
	static IsAllowed(page: E5Page): boolean {
		// welcome page is available for everybody
		let allow: boolean = page === E5Page.WELCOME;

		// map describing which role can access which page
		let allowmap: Map<string, E5Page[]> = new Map();
		allowmap.set("ROLE_L1", [E5Page.H_DASHBOARD, E5Page.H_INCIDENTS]);
		allowmap.set("ROLE_L2", [E5Page.H_DASHBOARD, E5Page.H_INCIDENTS, E5Page.H_CONNECTIVITY]);
		allowmap.set("ROLE_L3", [E5Page.H_DASHBOARD, E5Page.H_INCIDENTS, E5Page.H_CONNECTIVITY, E5Page.H_METRICS, E5Page.H_EVENTLOGS]);
		allowmap.set("ROLE_MANAGER", [E5Page.CB_DASHBOARD, E5Page.CB_POPULATIONS]);
		allowmap.set("ROLE_ANALYST", [E5Page.CB_DASHBOARD]);
		allowmap.set("ROLE_RDMANAGER", [E5Page.MANAGEMENT_RDM]);

		for (let role of E5Store.Ins().userinfo.curuser.roles) {
			let pages: E5Page[]|undefined = allowmap.get(role);
			allow = role === "ROLE_ADMIN" || pages?.includes(page) === true;
			if (allow) break;
		}
		return allow;
	}
}

//E5
export class E5Static {
	//E5
	static plotcolors: any = {
		plotred: "#dc143c", plotorange: "#ff7f50", plotyellow: "#ffd700",
		plotgreenlight: "#7fff00", plotgreendark: "#228b22",
	};

	//E5
	static gaugethresholds: number[] = [0, 20, 40, 60, 80, 100];

	//E5
	static newtresholds: number[] = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
}
