import {E5EntHIncidSys} from "./E5EntHIncidSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidSysTemp extends E5EntHIncidSys {
	highthreshold: number|null|undefined = undefined;
	lowthreshold: number|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.highthreshold = E5Entity.ParseIntNull(json.high_threshold);
		this.lowthreshold = E5Entity.ParseIntNull(json.low_threshold);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), high_threshold: this.highthreshold, low_threshold: this.lowthreshold};
	}
}
