import { Dialog, FocusStyleManager, Spinner, Tab, Tabs, Toaster } from "@blueprintjs/core";
import Backdrop from "@mui/material/Backdrop";
import React, { useCallback, useEffect, useState } from "react";
import { E5EntUser } from "../../../entity/E5EntUser";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Request } from "../../../request/E5Request";
import { getUser, logout } from "../../../request/E5RequestProfile";
import { E5Store } from "../../../store/E5Store";
import E5EditProfilePage from "../edit_profile/E5EditProfilePage";
import E5ProfilePassword from "../password/E5ProfilePassword";
import E5ProfileInfo from "../profile_info/E5ProfileInfo";
import "./E5MyProfilePage.css";

export default function E5MyProfilePage() {
    const [user, setUser] = useState<E5EntUser>(E5Store.Ins().GetUser());
    const [loading, setLoading] = useState<boolean>(true);
    const [modalWarningOpen, setModalWarningOpen] = useState<boolean>(false);
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

    const toasterref: React.RefObject<Toaster> = React.createRef();

    FocusStyleManager.onlyShowFocusOnTabs();

    const getUserInfo = useCallback(async () => {
        try {
            const response = await getUser(E5Store.Ins().GetUser().username);
            setUser(new E5EntUser(response));
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        getUserInfo();
    }, [getUserInfo]);

    const callToaster = (result: string, message: string, sendLogout = false) => {
        if (result === "success") {
            toasterref.current?.show({ message: message, intent: "success" });

            if (sendLogout) {
                setModalWarningOpen(true);
                setTimeout(() => {
                    E5Request.Ins().ClearAll();
                    E5Store.Ins().ChangeUser(new E5EntUser(), undefined);
                    logout();
                }, 4000);
            } else {
                setShowBackdrop(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } else {
            toasterref.current?.show({ message: message, intent: "danger" });
        }
    }

    if (loading) {
        return (
            <div className="e5page e5columnfull e5column-20">
                <div className="e5spinwait e5-align-center">
                    <Spinner size={30} />
                </div>
            </div>
        )
    }

    return (
        <>
            <Toaster className="profile-toast" ref={toasterref} />

            <div className="e5page e5columnfull e5column-20">
                <div className="e5flexcenter">
                    <h1>{E5UtilI18n._("mainheader-my-profile")}</h1>
                </div>

                <E5ProfileInfo user={user} />

                <Tabs className="profile-tabs e5line-10" vertical={true} defaultSelectedTabId="edit-profile" key={"horizontal"} >
                    <Tab id="edit-profile" title={E5UtilI18n._("profile-edit-profile")}
                        panel={<E5EditProfilePage user={user} updateFunction={callToaster} />} />
                    <Tab id="admin-users-password" title={E5UtilI18n._("admin-users-password")}
                        panel={<E5ProfilePassword user={user} updateFunction={callToaster} />} />
                </Tabs>
            </div>

            <Dialog isOpen={modalWarningOpen} onClose={() => setModalWarningOpen(false)} canOutsideClickClose={false} canEscapeKeyClose={false} isCloseButtonShown={false}>
                <div className="center-alignment">
                    <span className="dialog-title">{E5UtilI18n._("profile-modal-title")}</span>
                </div>

                <div className="top-spacement bp3-dialog-body">
                    <p>{E5UtilI18n._("profile-modal-body")}</p>
                </div>
            </Dialog>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showBackdrop}> </Backdrop>
        </>
    )
}