import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {
	E5StoreH,
	E5StoreHEquips,
	E5StoreHIndicSys,
	E5StoreHTopoSelected
} from "../../store/E5StoreH";
import { E5EntHIndSysCpuMem } from "../../entity/household/indic/E5EntHIndSysCpuMem";
import { E5EntHStation } from "../../entity/household/topo/E5EntHStation";
import { E5CBDashboard } from "../customer_base/dashboard/E5CBDashboard";
import { E5NetElementType, E5NodeTypeEnum } from "../../entity/E5Enums";
import { E5Store, E5StoreLangInfo } from "../../store/E5Store";
import { E5MainConfig } from "../../global/E5MainConfig";
import { E5StoreAdmin } from "../../store/E5StoreAdmin";
import { E5UtilI18n } from "../../global/E5MainLang";
import { E5HStationList } from "./E5HStationList";
import { E5HEquipMore } from "./E5HEquipMore";
import { E5Text } from "../../util/E5Text";
//
import "./E5HEquipList.css";

//E5
interface E5HEquipListState { }

//E5
interface E5HEquipListProps {
	selectedinfo: E5StoreHTopoSelected;
	indicsysinfo: E5StoreHIndicSys;
	equipinfo: E5StoreHEquips;
	langinfo: E5StoreLangInfo;
	hidescoreimg?: boolean;
	minimal: boolean;
	showbox: boolean;
}

//E5
export const E5HEquipList = observer(class E5HEquipList extends React.PureComponent
	<E5HEquipListProps, E5HEquipListState> {

	// ---------------- MEMBERS ----------------

	selected: boolean = false;

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		return this.props.minimal ? this.RenderMinimal() : this.RenderNormal();
	}

	//E5
	RenderMinimal(): JSX.Element {
		if (this.selected && this.props.equipinfo.equips.length === 0) this.selected = false;

		let eqpjsx: JSX.Element[] = [];
		for (let eqp of this.props.equipinfo.equips) {
			if (!this.props.showbox && eqp.nodetype === E5NodeTypeEnum.gw) continue;

			if (!this.selected) {
				this.selected = true;
				E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.node, eqp, new E5EntHStation());
			}

			let img: string = "/img/nodes_and_devices/" +
				(eqp.nodetype === E5NodeTypeEnum.none ? "default" : eqp.nodetype) + "-black.png",

				scoreimg: string = eqp.health !== null ? `/img/scores/score-${E5HStationList.GetScorePart(eqp.health)}.png` : "/img/scores/score-eth.png",
				selectedclass: string = this.props.selectedinfo.id === eqp.imei ? "selected" : "";

			eqpjsx.push(
				<div onClick={this.Selected} id={eqp.imei} key={eqp.imei} className={"clickable " + selectedclass}>
					<div className="e5line-5">
						<img className="img" src={img} alt="" />
						{this.props.hidescoreimg !== true && <img className="scoreimg" src={scoreimg} alt="" />}
						<div className="minimaltext">
							<div className="value">{eqp.imei}</div>
						</div>
					</div>
				</div>);
		}

		return <div className="e5compo e5wifih-health-nodes minimal">
			<div className="e5column-5">{eqpjsx}</div>
		</div>;
	}

	//E5

	RenderNormal(): JSX.Element {
		let { _ } = E5UtilI18n, eqpjsx: JSX.Element[] = [];
		let indicglobalinfo = E5StoreH.Ins().indicglobalinfo;
		let samplingperiod = indicglobalinfo.samplingperiod
		let sendingperiod = indicglobalinfo.sendingperiod

		// @ts-ignore
		let sendingperiodmn = Math.floor(sendingperiod / 60)
		// @ts-ignore
		let sendingperiodsec = sendingperiod % 60;
		for (let eqp of this.props.equipinfo.equips) {
			if (!this.props.showbox && eqp.nodetype === E5NodeTypeEnum.gw) continue;

			let img: string = "/img/nodes_and_devices/" +
				(eqp.nodetype === E5NodeTypeEnum.none ? "default" : eqp.nodetype) + "-black.png",
				selectedclass: string = this.props.selectedinfo.id === eqp.imei ? "selected" : "",
				percent: number | string = typeof eqp.health === 'number' ? E5Text.ScoreToPercent(eqp.health) : 'N/A', cpumem: E5EntHIndSysCpuMem | null = null;
			for (let cpumem2 of this.props.indicsysinfo.cpumem) if (cpumem2.imei === eqp.imei) cpumem = cpumem2;

			eqpjsx.push(
				<div onClick={this.Selected} id={eqp.imei} key={eqp.imei} className={"clickable " + selectedclass}>
					<div className="e5column-5">
						<div className="e5line-5">
							<div className="e5column-5 iconhealth">
								<img className="img" src={img} alt="" />
								{eqp.health !== null && eqp.health >= 0 ? E5MainConfig.GetWifiEnabled() && <div className="scorediv e5column-5">
									<div className="scorewifi" style={{
										backgroundColor: E5CBDashboard.GetGradientColor(
											E5MainConfig.GetHealthColorSteps(), +percent),
										color: +percent <= 20 || +percent >= 80 ? "#ffffff" : "#000000"
									}}>Wi-Fi
									</div>
									<div className="scorevalue">{E5Text.ScoreToPercent(+eqp.health)}%</div>
								</div> : <div />}
								{eqp.health === null && E5MainConfig.GetWifiEnabled() && <div className="scorediv e5column-5">
									<div className="scorewifi" style={{
										backgroundColor: E5CBDashboard.GetGradientColor(
											E5MainConfig.GetHealthColorSteps(), 22),
										color: "#ffffff"
									}}>Wi-Fi
									</div>
									<div className="scorevalue">N/A</div>
								</div>}
							</div>
							<div className="texts e5linefull">
								<div className="label">{_("h-equip-imei")}</div>
								<div className="label">{_("h-equip-model")}</div>
								<div className="label">{_("h-equip-lastseen")}</div>
								<div className="value">{eqp.imei}</div>
								<div className="value">{eqp.model}</div>
								{<div className="value">
									{eqp.lastseen?.format("HH:mm:ss") ?? "N/A"}
									{eqp.lastseen !== null &&
										E5StoreH.Ins().curdateinfo.curdate === E5Store.Ins().todayinfo.today &&
										<span>&nbsp;<i>({eqp.lastseen.fromNow()})</i></span>}
								</div>}
								{(!E5MainConfig.GetSystemEnabled() || !E5MainConfig.GetSystemRebootEnabled()) &&
									<div className="value">N/A</div>}
								<BP.Divider />
								<BP.Divider />
								<BP.Divider />
								<div className="label">{_("h-equip-datacollector")}</div>
								<div className="label">{_("h-equip-sys-softwarev")}</div>
								<div className="label">{_("h-equip-uptime")}</div>
								<div className="value">{samplingperiod} sec / {sendingperiodmn} mn {sendingperiodsec} sec</div>
								<div className="value">{E5HEquipMore.ToCsv(eqp.softwarev, "")}</div>
								{E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemRebootEnabled() &&
									<div className="value">
										{cpumem instanceof E5EntHIndSysCpuMem ? typeof cpumem.uptime === "number" ?
											E5Text.Seconds2DHMS_str(cpumem.uptime) : "N/A" :
											this.props.indicsysinfo.status.success ? "N/A" : ""}</div>}
							</div>
						</div>
						<E5HEquipMore
							requestinfo={E5StoreAdmin.Ins().rdmrequestinfo} searchniinfo={E5StoreH.Ins().searchniinfo}
							nodesrdminfo={E5StoreH.Ins().equiprdminfo} statusinfo={E5StoreAdmin.Ins().rdmstatusinfo}
							ttlinfo={E5StoreAdmin.Ins().rdmttlinfo} rdminfo={E5StoreAdmin.Ins().rdminfo}
							langinfo={E5Store.Ins().langinfo} userinfo={E5Store.Ins().userinfo} node={eqp} />
					</div>
				</div>);
		}

		return <div className="e5compo e5wifih-health-nodes">
			<div className="e5column-5">{eqpjsx}</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Selected = (event: React.MouseEvent<HTMLElement>) =>
		E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.node,
			this.props.equipinfo.equips.filter(({ imei }) => imei === event.currentTarget.id)[0],
			new E5EntHStation());
});
