import React from "react";
import moment from "moment";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5SelectDate} from "../../../global/component/E5SelectDate";
import {E5EntCBPop} from "../../../entity/customer_base/E5EntCBPop";
import {E5RequestCBPop} from "../../../request/E5RequestCBPop";
import {E5MainConfig} from "../../../global/E5MainConfig";
import {E5StoreCBPop} from "../../../store/E5StoreCBPop";
import {E5StoreCBSys} from "../../../store/E5StoreCBSys";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5StoreCB} from "../../../store/E5StoreCB";
import {E5Store} from "../../../store/E5Store";
import {ReactComponent as SearchIcon} from "../../../assets/icon-searc.svg"
//
import "./E5CBDashboardFilters.css";
import { E5CBDashboard } from "./E5CBDashboard";

//E5
interface E5CBDashboardFiltersState {
	selectedstartdate: string; // YYYY-MM-DD
	selectedenddate: string; // YYYY-MM-DD
}

//E5
interface E5CBDashboardFiltersProps {}

//E5
export const E5CBDashboardFilters = observer(class E5CBDashboardFilters extends React.PureComponent
	<E5CBDashboardFiltersProps, E5CBDashboardFiltersState> {

	// ---------------- INIT ----------------
	constructor(props: E5CBDashboardFiltersProps, state: E5CBDashboardFiltersState) {
		super(props, state);
		this.state = {
			selectedstartdate: E5StoreCB.Ins().filterinfo.startdate,
			selectedenddate: E5StoreCB.Ins().filterinfo.enddate
		};
	}
	//E5
	componentDidMount(): void {
		if (E5StoreCBPop.Ins().popinfo.pops.length === 0) E5RequestCBPop.Ins().FetchPops(undefined);
		if (E5StoreCB.Ins().filterinfo.populationid) {
			E5StoreCB.Ins().SetRequestSet(new Set());
			const pageRequests = ["cb-dashboard-over", "cb-dashboard-wifi", "cb-dashboard-wan", "cb-sys-overview", "system-cpu", "system-mem", "system-reb", "system-temp", "system-proc", "system-flash"];
			pageRequests.forEach((req) => E5CBDashboard.Fetch(req));
		}
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {

		let {global, wifi, wan} = E5StoreCB.Ins(), {popinfo} = E5StoreCBPop.Ins(),
			{overview, cpu, memory, reboot, temperature, process, flash} = E5StoreCBSys.Ins();

		let popoptions: JSX.Element[] = [], pop: E5EntCBPop;
		popoptions.push(<option key="-1" value="">{E5UtilI18n._("wificb-dashboard-selectpop")}</option>);
		for (pop of popinfo.pops)
			popoptions.push(<option key={pop.populationid} value={pop.populationid}>{pop.name}</option>);

		let loading: boolean = global.status.loading || wifi.status.loading || wan.status.loading ||
			popinfo.status.loading || overview.status.loading || cpu.status.loading || memory.status.loading ||
			reboot.status.loading || temperature.status.loading || process.status.loading || flash.status.loading;
		
		let yesterday: string =
			moment(E5Store.Ins().todayinfo.today).subtract(1, "day").format("YYYY-MM-DD");

		return <div className="e5cb-dashboard-filters e5line-10">
			<SearchIcon className='search-icon' onClick={() => E5StoreCB.Ins().SetDates(this.state.selectedstartdate, this.state.selectedenddate)}/>
			<select className="filters-poplist" value={E5StoreCB.Ins().filterinfo.populationid}
						   onChange={this.ChangePop} disabled={loading}>{popoptions}</select>
			<div>{E5UtilI18n._("wificb-dashboard-from")}</div>
			<E5SelectDate nbdays={E5MainConfig.GetNbDaysCustomerBase()} curdate={this.state.selectedstartdate}
						  limitdate={undefined} updatefunc={this.ChangeStartDate}
						  langinfo={E5Store.Ins().langinfo} disabled={loading} enddate={yesterday} key={'start'}/>
			<div>{E5UtilI18n._("wificb-dashboard-to")}</div>
			<E5SelectDate langinfo={E5Store.Ins().langinfo} disabled={loading} enddate={yesterday} limitdate={undefined} mindate={this.state.selectedstartdate}
						  nbdays={E5MainConfig.GetNbDaysCustomerBase()} curdate={this.state.selectedenddate}
						  updatefunc={this.ChangeEndDate}/>
			{loading && <BP.Spinner className="e5spinwait" size={25}/>}
			{global.status.message !== "" && <div className="e5statusmessage">{global.status.message}</div>}
			{wifi.status.message !== "" && <div className="e5statusmessage">{wifi.status.message}</div>}
			{wan.status.message !== "" && <div className="e5statusmessage">{wan.status.message}</div>}
			{popinfo.status.message !== "" && <div className="e5statusmessage">{popinfo.status.message}</div>}
			{overview.status.message !== "" && <div className="e5statusmessage">{overview.status.message}</div>}
			{cpu.status.message !== "" && <div className="e5statusmessage">{cpu.status.message}</div>}
			{memory.status.message !== "" && <div className="e5statusmessage">{memory.status.message}</div>}
			{reboot.status.message !== "" && <div className="e5statusmessage">{reboot.status.message}</div>}
			{temperature.status.message !== "" && <div className="e5statusmessage">{temperature.status.message}</div>}
			{process.status.message !== "" && <div className="e5statusmessage">{process.status.message}</div>}
			{flash.status.message !== "" && <div className="e5statusmessage">{flash.status.message}</div>}
			<div className="e5linefull"/>
			{/* <BP.Button disabled={E5StoreCB.Ins().filterinfo.populationid === ""} icon="print"
					   text={E5UtilI18n._("cbreport-button")} onClick={this.OpenReport}/> */}
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	ChangePop = (event: React.ChangeEvent<HTMLSelectElement>): void => E5StoreCB.Ins().SetCurPopulation(event.currentTarget.value, this.state.selectedstartdate, this.state.selectedenddate);

	//E5
	// dt=YYYY-MM-DD
	ChangeStartDate = (dt: string): void => {
		if(dt > this.state.selectedenddate){
			this.setState({selectedenddate:dt})
		}
		this.setState({selectedstartdate: dt})
	};

	//E5
	// dt=YYYY-MM-DD
	ChangeEndDate = (dt: string): void => this.setState({selectedenddate: dt});

	//E5
	OpenReport = (): void => E5Store.Ins().ChangeReport(true);
});
