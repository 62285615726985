import { E5UtilI18n } from "../global/E5MainLang";

export function rebootChartOptions(data: any): any {
    const seriesData = data.map((item: any) => {
        return {
            value: [item.xaxis, item.yaxis, item.tooltipValues],
            itemStyle: {
                color: item.isFwUpdate ? '#e97132' : '#156082'
            },
            tooltip: {
                borderColor: item.isFwUpdate ? '#e97132' : '#156082',
                formatter(params) {
                    const tooltipValues = params.value[2];
                    const tooltip = Object.keys(tooltipValues).map((key, index) => {
                        if (index === 0) {
                            return `<span style="text-align: start"><b>${E5UtilI18n._(key)} - ${tooltipValues[key]}</b></span>`;
                        }
                        return `<span style="text-align: start">${E5UtilI18n._(key)} <b>${tooltipValues[key]}</b></span>`;
                    });
                    
                    return `<div style="display:flex; flex-direction: column; justify-content: flex-start">${tooltip.join('')}</div>`;
                }
            }
        }
    });

    return {
        tooltip: {
            trigger: 'item',
        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '15%',
            containLabel: true
        },
        toolbox: {
            right: '4%',
            feature: {
                dataView: {
                    show: true,
                    readOnly: true
                },
                dataZoom: {
                    yAxisIndex: 'none'
                },
                magicType: {
                    show: false,
                },
                restore: {
                    show: true,
                },
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: ['50%', '50%'],
        },
        yAxis: {
            type: 'value',
            boundaryGap: ['50%', '50%'],
            minInterval: 1,
            min: 0,
            axisLabel: {
                show: true,
            }
        },
        series: [
            {
                symbolSize: 15,
                data: seriesData,
                type: 'scatter'
            }
        ]
    }
}