import {E5Entity} from "../../E5Entity";

//E5
export interface E5SysFlashDetail {
	sizeavail: number;
	sizebad: number;
	corruptcnt: number;
	validcnt: number;
	name: string;
	sizeused: number;
}

//E5
export class E5EntHIndSysFlash extends E5Entity {
	imei: string = "";
	flashdetail: E5SysFlashDetail[]|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);

		let idx: number, det: any;
		this.flashdetail = null;
		if (Array.isArray(json.flash_memory_details)) {
			this.flashdetail = [];
			for (idx = 0; idx < json.flash_memory_details.length; idx++) {
				det = json.flash_memory_details[idx];
				this.flashdetail.push({
					sizeavail: E5Entity.ParseInt(det.available_block_size),
					sizebad: E5Entity.ParseInt(det.bad_block_size),
					corruptcnt: E5Entity.ParseInt(det.is_corrupted_volumes?.true),
					validcnt: E5Entity.ParseInt(det.is_corrupted_volumes?.false),
					name: E5Entity.AssignString(det.name), sizeused: E5Entity.ParseInt(det.used_block_size)
				});
			}
		}
	}

	//E5
	CopyToJson(): any {
		let flash_memory_details: any = null, idx: number;
		if (Array.isArray(this.flashdetail)) {
			flash_memory_details = [];
			for (idx = 0; idx < this.flashdetail.length; idx++) flash_memory_details.push({
				available_block_size: this.flashdetail[idx].sizeavail, bad_block_size: this.flashdetail[idx].sizebad,
				is_corrupted_volumes: {true: this.flashdetail[idx].corruptcnt, false: this.flashdetail[idx].validcnt},
				name: this.flashdetail[idx].name, used_block_size: this.flashdetail[idx].sizeused
			});
		}

		return {node_imei: this.imei, flash_memory_details};
	}
}
