import React from 'react';
import {observer} from "mobx-react";
import moment, {Moment} from "moment";
// import * as BP from "@blueprintjs/core";
//
import {E5StoreLangInfo} from "../../store/E5Store";
import {E5UtilI18n} from "../E5MainLang";
//
import "./E5SelectDate.css"

//E5
interface E5SelectDateState {
	selecteddate: string; // YYYY-MM-DD
}

//E5
interface E5SelectDateProps {
	langinfo: E5StoreLangInfo;
	disabled: boolean;
	updatefunc: (dt: string) => void;
	enddate: string; // YYYY-MM-DD
	nbdays: number;
	curdate: string; // YYYY-MM-DD
	// limitdate must be between enddate and enddate-nbdays
	// the days between limitdate+1 and enddate are not shown
	limitdate: string|undefined; // YYYY-MM-DD
	mindate?: string;
}

//E5
export const E5SelectDate = observer(class E5SelectDate extends React.PureComponent
	<E5SelectDateProps, E5SelectDateState> {

	//E5
	constructor(props: E5SelectDateProps, state: E5SelectDateState) {
		super(props, state);
		this.state = {selecteddate: this.props.curdate};
	}


	componentDidUpdate(prevProps:E5SelectDateProps) {
		if (prevProps.curdate !== this.props.curdate) {
		  this.setState({selecteddate:this.props.curdate})
		}
	}

	//E5
	render() {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		//
		let opt: JSX.Element[] = [];
		let idx: number;
		let enddate: Moment = moment(this.props.enddate);
		let startdate: Moment = moment(enddate);
		startdate.subtract(this.props.nbdays, "days");
		//
		if (this.props.limitdate !== undefined)
			enddate = moment(this.props.limitdate);
		//
		let curdate: Moment = moment(enddate);
		let mindate: any = this.props.mindate ? moment(this.props.mindate) : null;
		let curdatestr: string = "";
		let found: boolean = false;
		for (idx = 0; idx < this.props.nbdays; idx++) {
			curdatestr = curdate.format("YYYY-MM-DD");
			if (curdatestr === this.state.selecteddate)
				found = true;
			opt.push(
				<option
					key={idx}
					value={curdatestr}
				>
					{curdate.format(E5UtilI18n._("dateformat"))}
				</option>
			);
			curdate.subtract(1, "days");
			if(mindate && (curdate < mindate))
				break;
			if (curdate < startdate)
				break;
		}
		if (!found) {
			// the first option is automatically selected when the value is not present in options
			opt.splice(0, 0,
				<option
					key={-1}
					value="-"
				>
					{"-"}
				</option>
			);
		}
		//
		return <div className="e5selectdate e5line-10">
			<div className="e5selectdatedates">
				<select
					onChange={this.ChangeDate}
					disabled={this.props.disabled}
					value={this.state.selecteddate}
				>
					{opt}
				</select>
			</div>
		</div>;
	}

	//E5
	ChangeDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this.setState({selecteddate: event.currentTarget.value}, () => {
			this.props.updatefunc(this.state.selecteddate); // YYYY-MM-DD
		});
	};
});
