import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import "./E5TreeView.css";

//E5
interface E5TreeViewState {
	isopen: boolean;
}

//E5
interface E5TreeViewProps {
	label: string;
	value: any;
	isinobject: boolean;
}

//E5
export const E5TreeView = observer(class E5TreeView extends React.PureComponent<E5TreeViewProps, E5TreeViewState> {

	//E5
	constructor(props: E5TreeViewProps, state: E5TreeViewProps) {
		super(props, state);
		this.state = {isopen: true};
	}

	//E5
	render(): JSX.Element {
		let children: JSX.Element[] = [];
		let idx: number;
		let key: string;
		let dot: JSX.Element = <div/>;
		if (this.props.isinobject)
			dot = <BP.Icon icon="dot" className="e5dot"/>;
		if (Array.isArray(this.props.value)) {
			let lst: JSX.Element[] = [];
			lst.push(
				<div className="e5treeview-label">
					{dot}
					<BP.Icon icon="caret-right" className="e5action" onClick={this.ToggleAction}/>
					{this.props.label}
					<BP.Icon icon="align-justify" className="e5symbol"/>
				</div>
			);
			if (this.state.isopen) {
				for (idx = 0; idx < this.props.value.length; idx++)
					lst.push(<div className="e5treeview-listitem">
						<E5TreeView key={idx} isinobject={false} label="" value={this.props.value[idx]}/>
					</div>);
			}
			children.push(<div className="e5treeview-list">{lst}</div>);
		} else {
			if (typeof this.props.value === "object") {
				let lst: JSX.Element[] = [];
				lst.push(
					<div className="e5treeview-label">
						{dot}
						<BP.Icon icon="caret-right" className="e5action" onClick={this.ToggleAction}/>
						{this.props.label}
						<BP.Icon icon="properties" className="e5symbol"/>
					</div>
				);
				if (this.state.isopen) {
					let idx: number = 0;
					for (key in this.props.value) {
						lst.push(<div className="e5treeview-objitem">
							<E5TreeView key={idx} isinobject={true} label={key} value={this.props.value[key]}/>
						</div>);
						idx++;
					}
				}
				children.push(<div className="e5treeview-obj">{lst}</div>);
			} else {
				children.push(
					<div className="e5treeview-label">
						{dot}
						{this.props.label}
					</div>
				);
				children.push(<span> : </span>);
				children.push(<div className="e5treeview-value">{this.props.value}</div>);
			}
		}
		//
		return <div className="e5treeview">
			{children}
		</div>;
	}

	//E5
	ToggleAction = (): void => {
		this.setState({isopen: !this.state.isopen});
	}
});

/*
TEST OBJECTS
[
  {
  "a":"1",
  "b":"B",
  "c":
    [
      "x",
      "y",
      "z",
      {
        "n":"5"
      }
    ],
  "d":
    {
  	  "d0": "0",
  	  "d1": "1"
    }
  },
  {
    "u":"1535"
  }
]

{
  "a":
    [
      "123",
      {
        "p":"5"
      }
    ],
  "b":"354"
}
*/