import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5EntHTopology} from "../../../entity/household/topo/E5EntHTopology";
import {E5EntHStation} from "../../../entity/household/topo/E5EntHStation";
import {E5HInstability} from "../../household/connectivity/E5HInstability";
import {E5EntHEquip} from "../../../entity/household/topo/E5EntHEquip";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5BandEnum} from "../../../entity/E5Enums";
import {E5TestTopoGraph} from "./E5TestTopoGraph";
import {E5Store} from "../../../store/E5Store";
import {E5TestHeatmap} from "./E5TestHeatmap";
//
import "./E5TestConnections.css";

//E5
interface E5TestConnectionsState {
	nbsta: number;
	gw: E5EntHEquip;
	ext: E5EntHEquip;
}

//E5
interface E5TestConnectionsProps {}

//E5
export const E5TestConnections = observer(class E5TestConnections extends React.PureComponent
	<E5TestConnectionsProps, E5TestConnectionsState> {

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestConnectionsProps, state: E5TestConnectionsState) {
		super(props, state);
		this.state = {
			nbsta: 7, gw: new E5EntHEquip({
				imei: "TOPO_GATEWAY_NODE_FAKE_IMEI_STRING", node_type: "GW", interfaces: [
					{mac_address: E5TestTopoGraph.GetFakeMacAddress(), band: E5BandEnum.freq2ghz},
					{mac_address: E5TestTopoGraph.GetFakeMacAddress(), band: E5BandEnum.freq5ghz},
					{mac_address: E5TestTopoGraph.GetFakeMacAddress(), band: E5BandEnum.freq6ghz}
				]
			}), ext: new E5EntHEquip({
				imei: "TOPO_EXTENDER_FAKE_IMEI_STRING", node_type: "EXT", interfaces: [
					{mac_address: E5TestTopoGraph.GetFakeMacAddress(), band: E5BandEnum.freq2ghz},
					{mac_address: E5TestTopoGraph.GetFakeMacAddress(), band: E5BandEnum.freq5ghz},
					{mac_address: E5TestTopoGraph.GetFakeMacAddress(), band: E5BandEnum.freq6ghz}
				]
			})
		};
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let stations: E5EntHStation[] = E5TestHeatmap.GetStations(this.state.nbsta),
			topologies: E5EntHTopology[] = this.GetTopologies(stations);

		return <div className="e5testpage e5test-connections e5fullheight e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-connections-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Number of stations">
					<div className="e5line-20">
						<BP.Button disabled={this.state.nbsta < 2} text="-"
								   onClick={() => this.UpdateNbDevicesBy(-1)}/>
						<BP.Button text="+" onClick={() => this.UpdateNbDevicesBy(+1)}/>
						<BP.H4>{this.state.nbsta}</BP.H4>
					</div>
				</BP.Callout>
			</div>
			<E5HInstability langinfo={E5Store.Ins().langinfo} stationinfo={{stations, stationmap: new Map()}}
							connectivityinfo={{loading: false, topologies, rssipersta: []}}
							equipinfo={{equips: [this.state.gw, this.state.ext], equipmap: new Map()}}
							searchniinfo={{
								status: {loading: false, success: true, message: ""}, networkid: "", networkidtyped: "",
								timezone: "", timezonedelta: 0, requesttime: undefined, datatime: undefined
							}}/>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	UpdateNbDevicesBy = (amount: number): void =>
		this.setState({nbsta: amount < 0 && this.state.nbsta < 2 ? 1 : this.state.nbsta + amount});

	// ---------------- UTILS ----------------

	//E5
	GetTopologies = (devices: E5EntHStation[]): E5EntHTopology[] => {
		let topologies: E5EntHTopology[] = [];

		// extender to gateway
		let connectivities: any[] = [];
		for (let idx2 = 0; idx2 < this.state.gw.interfaces.length; idx2++) if (Math.random() > 0.5)
			connectivities.push({
				connection_count: Math.ceil(Math.random() * 3),
				connection_time: Math.round(Math.random() * 3600 * 3),
				interface_mac_address: this.state.gw.interfaces[idx2].macaddress
			});
		topologies.push(new E5EntHTopology({
			child: this.state.ext.imei, is_backhaul: true, parent: this.state.gw.imei, links: [], connectivities
		}));

		// devices to gateway
		for (let idx = 0; idx < devices.length; idx++) {
			connectivities = [];
			for (let idx2 = 0; idx2 < this.state.gw.interfaces.length; idx2++) if (Math.random() > 0.5)
				connectivities.push({
					connection_count: Math.ceil(Math.random() * 3),
					connection_time: Math.round(Math.random() * 3600 * 3),
					interface_mac_address: this.state.gw.interfaces[idx2].macaddress
				});
			topologies.push(new E5EntHTopology({
				child: devices[idx].macaddress, is_backhaul: false, parent: this.state.gw.imei, links: [],
				connectivities
			}));
		}

		// devices to extender
		for (let idx = 0; idx < devices.length; idx++) {
			connectivities = [];
			for (let idx2 = 0; idx2 < this.state.ext.interfaces.length; idx2++) if (Math.random() > 0.5)
				connectivities.push({
					connection_count: Math.ceil(Math.random() * 3),
					connection_time: Math.round(Math.random() * 3600 * 3),
					interface_mac_address: this.state.ext.interfaces[idx2].macaddress
				});
			topologies.push(new E5EntHTopology({
				child: devices[idx].macaddress, is_backhaul: false, parent: this.state.ext.imei, links: [],
				connectivities
			}));
		}
		return topologies;
	};
});
