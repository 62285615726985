import { E5Store } from "../../store/E5Store";
import { E5Text } from "../../util/E5Text";
import { E5UtilI18n } from "../E5MainLang";
import ReactECharts from 'echarts-for-react';

import './E5NewAngularGauge.css';

interface E5NewAngularGaugeProps {
    label: string;
    value: number | null | undefined;
    displayZero?: boolean;
}

export default function E5NewAngularGauge(props: Readonly<E5NewAngularGaugeProps>) {
    // force rerender when lang changes
    let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

    if (props.value === null || props.value === undefined || (props.value === 0 && !props.displayZero)) {
        return (
            <div className="e5compo e5angular-gauge e5columnfull">
                <div className="e5compotitle">{props.label}</div>
                <div className="no-data-container">
                    <div className="no-data">
                        {E5UtilI18n._("no-data-collected")}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="e5compo e5angular-gauge e5columnfull">
            <div className="e5compotitle">{props.label}</div>
            <div className="e5linefull">
                <ReactECharts option={getGaugeOptions(props.value)} />
            </div>
        </div>
    )

}

function getGaugeOptions(value: number) {
    return {
        visualMap: {
            show: false,
            type: 'piecewise',
            bottom: 50,
            left: 'center',
            orient: 'horizontal',
            pieces: [
                { min: 0, max: 20, color: '#EF5350' },
                { min: 20, max: 40, color: '#FFA726' },
                { min: 40, max: 60, color: '#FFEE58' },
                { min: 60, max: 80, color: '#9CCC65' },
                { min: 80, max: 100, color: '#66BB6A' }
            ],
            calculable: true
        },
        series: [
            {
                type: 'gauge',
                startAngle: 185,
                endAngle: -5,
                center: ['50%', '75%'],
                radius: '90%',
                min: 0,
                max: 100,
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        width: 20,
                        color: [
                            [0.2, '#EF5350'],
                            [0.4, '#FFA726'],
                            [0.6, '#FFEE58'],
                            [0.8, '#9CCC65'],
                            [1, '#66BB6A']
                        ]
                    }
                },
                pointer: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                title: {
                    show: false
                },
                detail: {
                    show: false
                }
            },
            {
                type: 'gauge',
                progress: {
                    show: true,
                    width: 40
                },
                startAngle: 185,
                endAngle: -5,
                center: ['50%', '75%'],
                radius: '79%',
                min: 0,
                max: 100,
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        width: 40
                    }
                },
                pointer: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                title: {
                    show: false
                },
                detail: {
                    fontSize: 40,
                    offsetCenter: [0, -3],
                    valueAnimation: true,
                    formatter: '{value}%',
                    color: 'inherit'
                },
                data: [
                    {
                        value: E5Text.ScoreToPercent(value),
                        name: 'Grade Rating'
                    }
                ]
            }
        ]
    }
}