import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIndSysCpuMem extends E5Entity {
	corecnt: number|null|undefined = undefined;
	load1: number|null|undefined = undefined;
	load5: number|null|undefined = undefined;
	load15: number|null|undefined = undefined;
	memusedmb: number|null|undefined = undefined;
	memusedpercent: number|null|undefined = undefined;
	imei: string = "";
	memtotal: number|null|undefined = undefined;
	uptime: number|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.corecnt = E5Entity.ParseIntNull(json.cpu_core_count);
		this.load1 = E5Entity.ParseFloatNull(json.load1);
		this.load5 = E5Entity.ParseFloatNull(json.load5);
		this.load15 = E5Entity.ParseFloatNull(json.load15);
		this.memusedmb = E5Entity.ParseIntNull(json.memory_used_MB);
		this.memusedpercent = E5Entity.ParseFloatNull(json.memory_used_percent);
		this.imei = E5Entity.AssignString(json.node_imei);
		this.memtotal = E5Entity.ParseIntNull(json.total_memory);
		this.uptime = E5Entity.ParseIntNull(json.uptime);
	}

	//E5
	CopyToJson(): any {
		return {
			cpu_core_count: this.corecnt, load1: this.load1, load5: this.load5, load15: this.load15,
			memory_used_MB: this.memusedmb, memory_used_percent: this.memusedpercent, node_imei: this.imei,
			total_memory: this.memtotal, uptime: this.uptime
		};
	}
}
