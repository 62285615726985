import axios from 'axios';
import { E5MainConfig } from '../global/E5MainConfig';
import { E5Storage } from '../util/E5Storage';

export const requestApi = () => {
    return axios.create({
        baseURL: E5MainConfig.GetBackendUrl(),
        headers: {
            'Authorization': E5Storage.GetLSString(E5MainConfig.ls_bearer),
        },
    });
};