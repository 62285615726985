import {E5EntMetaLangs} from "./E5EntMetaLangs";
import {E5Entity} from "../E5Entity";

//E5
export class E5EntMetaCtxInfo extends E5Entity {
	ctxinfoid: string = "";
	langs: E5EntMetaLangs = new E5EntMetaLangs();

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.ctxinfoid = E5Entity.AssignString(json.context_information_id);
		this.langs = new E5EntMetaLangs(json.languages);
	}

	//E5
	CopyToJson(): any {
		return {context_information_id: this.ctxinfoid, languages: this.langs.CopyToJson()};
	}
}
