import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
import Plot, {PlotParams} from "react-plotly.js";
//
import {E5StoreRadarInfo} from "../../request/E5ServiceCommon";
import {E5Static} from "../E5MainStatics";
import {E5Text} from "../../util/E5Text";
//
import "./E5RadarChart.css";

//E5
interface E5RadarChartState {}

//E5
interface E5RadarChartProps {
	radarinfo: E5StoreRadarInfo;
}

//E5
export const E5RadarChart = observer(class E5RadarChart extends React.PureComponent
	<E5RadarChartProps, E5RadarChartState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		let fillcolor: string = this.props.radarinfo.fillcolor === undefined ?
			"#00000033" : this.props.radarinfo.fillcolor;
		//
		let values: (number|null)[] = this.props.radarinfo.values.map(val => val === null ?
			null : E5Text.ScoreToPercent(val));
		values = [...values, values[0]];
		//
		let colors: string[] = values.map(val => val === null ? "#00000000" : val < 20 ? E5Static.plotcolors.plotred
			: val < 40 ? E5Static.plotcolors.plotorange : val < 60 ? E5Static.plotcolors.plotyellow : val < 80 ?
				E5Static.plotcolors.plotgreenlight : E5Static.plotcolors.plotgreendark);
		//
		let graphdata: PlotParams = {
			data: [
				{
					type: "scatterpolar",
					name: this.props.radarinfo.title,
					theta: [...this.props.radarinfo.labels, this.props.radarinfo.labels[0]],
					r: values,
					mode: "markers",
					line: {shape: this.props.radarinfo.hardedge !== true ? "spline" : "linear"},
					marker: {size: 15, color: colors},
					hoverlabel: {font: {family: "montserrat", size: 21}},
					hovertemplate: "%{r}%<extra>%{theta}</extra>",
					fill: "toself",
					fillcolor: fillcolor
				}
			],
			layout: {
				font: {family: "montserrat"},
				width: 285,
				height: 285,
				margin: {t: 5, r: 35, l: 35, b: 15},
				polar: {
					bgcolor: "#00000000",
					angularaxis: {visible: true, ticks: "inside", tickfont: {size: 11}},
					radialaxis: {
						visible: this.props.radarinfo.hideraxis !== true,
						layer: "above traces",
						range: [0, 100]
					}
				},
				paper_bgcolor: "#00000000",
				showlegend: false
			},
			config: {displayModeBar: false}
		};
		//
		return <div className="e5compo e5radar-chart e5columnfull e5column-5">
			<div className="e5compotitle">{this.props.radarinfo.title}
				{this.props.radarinfo.loading && <BP.Spinner className="e5spinwait" size={15}/>}
			</div>
			{!this.props.radarinfo.loading &&
			<>
				<div className="e5columnfull e5line-0">
					<div className="e5linefull"/>
					<Plot {...graphdata}/>
					<div className="e5linefull"/>
				</div>
			</>
			}
		</div>;
	}
});
