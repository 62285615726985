import {E5Entity} from "../E5Entity";

//E5
export class E5EntRdmTtl extends E5Entity {

	//E5
	id: number = 0;
	name: string = "";
	durationsec: number = 0;
	timezone: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.id = E5Entity.ParseInt(json.ttlId);
		this.name = E5Entity.AssignString(json.ttlName);
		this.durationsec = E5Entity.ParseInt(json.ttlDurationInSeconds);
		this.timezone = E5Entity.AssignString(json.timeZone);
	}

	//E5
	CopyToJson(): any {
		return {ttlId: this.id, ttlName: this.name, ttlDurationInSeconds: this.durationsec, timeZone: this.timezone};
	}
}
