export class E5Storage {

	//E5
	static GetLSString(key: string): string|undefined {
		let str: string|undefined|null = undefined;
		try {str = localStorage.getItem(key);} catch (ex) {}
		if (str === null)
			str = undefined;
		return str;
	}

	//E5
	static SetLSString(key: string, value: string): boolean {
		let success: boolean = false;
		try {
			localStorage.setItem(key, value);
			success = true;
		} catch (ex) {}
		return success;
	}

}
