import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5StoreAdminRdmRequest } from "../../../store/E5StoreAdmin";
import { E5StoreLangInfo } from "../../../store/E5Store";
import { E5UtilI18n } from "../../../global/E5MainLang";
//
import "./E5AdminRdmReqLegend.css";
import { Popover, Position, Button, Icon } from "@blueprintjs/core";

//E5
interface E5AdminRdmReqLegendState { }

//E5
interface E5AdminRdmReqLegendProps {
	langinfo: E5StoreLangInfo;
	requestinfo: E5StoreAdminRdmRequest;
	onlyicon?: boolean;
}

//E5
export const E5AdminRdmReqLegend = observer(class E5AdminRdmReqLegend extends React.PureComponent
	<E5AdminRdmReqLegendProps, E5AdminRdmReqLegendState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		//
		let reqnames: JSX.Element[] = [<div key="reqname">{E5UtilI18n._("admin-rdm-reqlegend-action")}</div>];
		let reqdescs: JSX.Element[] = [<div key="reqdesc">{E5UtilI18n._("admin-rdm-reqlegend-description")}</div>];
		let idx: number;
		for (idx = 0; idx < this.props.requestinfo.requestlist.length; idx++) {
			reqnames.push(<div key={"reqname" + idx}>{this.props.requestinfo.requestlist[idx].name}</div>);
			reqdescs.push(<div key={"reqdesc" + idx}>{this.props.requestinfo.requestlist[idx].description}</div>);
		}
		//
		return <div className="e5admin-rdm-req-legend">
			<Popover
				content={<div className="e5admin-rdm-req-legend-table">
					<div className="e5column-0">{reqnames}</div>
					<div className="e5column-0">{reqdescs}</div>
				</div>}
				position={Position.BOTTOM_LEFT}
			>
				{
					this.props.onlyicon ?
						<Icon icon="help" iconSize={16} /> :
						<Button icon="help" text={E5UtilI18n._("admin-rdm-reqlegend")} />
				}
			</Popover>
		</div>;
	}
});
