import {E5EntHIncidSys} from "./E5EntHIncidSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidSysMem extends E5EntHIncidSys {
	memtotal: number|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.memtotal = E5Entity.ParseIntNull(json.total_memory);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), total_memory: this.memtotal};
	}
}
