import { E5RestFetchResult, E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender } from "../util/E5RestSender";
import { E5RequestCallback, E5RequestStatus } from "./E5ServiceCommon";
import { E5EntMetaIncident } from "../entity/meta/E5EntMetaIncident";
import { E5EntMetaAnomaly } from "../entity/meta/E5EntMetaAnomaly";
import { E5EntMetaCtxInfo } from "../entity/meta/E5EntMetaCtxInfo";
import { E5EntMetaFilter } from "../entity/meta/E5EntMetaFilter";
import { E5EntMetaRecom } from "../entity/meta/E5EntMetaRecom";
import { E5MainConfig } from "../global/E5MainConfig";
import { E5StoreAdmin } from "../store/E5StoreAdmin";
import { E5EntMeta } from "../entity/meta/E5EntMeta";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5Entity } from "../entity/E5Entity";
import { E5Storage } from "../util/E5Storage";
import { E5Request } from "./E5Request";

//E5
export enum E5RequestMetaTags {
	CONFIG = "config",
	META = "meta",
	NONE = ""
}

//E5
export class E5RequestMeta implements E5RestResponder {

	// --------- STATIC -------------

	//E5
	private static ins: E5RequestMeta;

	//E5
	static Ins = (): E5RequestMeta => {
		if (E5RequestMeta.ins === undefined)
			E5RequestMeta.ins = new E5RequestMeta();
		return E5RequestMeta.ins;
	};

	// --------- INSTANCE -------------

	//E5
	sender: E5RestSender | undefined;
	tokenmap: Map<string, number>;

	//E5
	total_network_id: number;
	h_anomalycodes: string[];
	h_anomalymap: Map<string, string[]>;
	h_anomalycategs: string[];
	h_anomalycateglen: number;
	h_incidentcategs: string[];
	h_incidentcateglen: number;
	h_incidentcodes: string[];
	h_anomalyNames: any[];
	h_incidentsNames: any[];
	cb_filtermap: Map<string, string[]>;

	//E5
	static h_inc_wifi_nb: string = "I01";
	static h_inc_wan_nb: string = "I02";
	static h_inc_sys_nb: string = "I03";
	static h_inc_sys_reb_nb: string = "I0301";
	static h_inc_sys_cpu_nb: string = "I0302";
	static h_inc_sys_mem_nb: string = "I0303";
	static h_inc_sys_proc_nb: string = "I0304";
	static h_inc_sys_flash_nb: string = "I0305";
	static h_inc_sys_temp_nb: string = "I0306";
	static h_anom_wifi_nb: string = "A01";
	static h_anom_wan_nb: string = "A02";
	static h_anom_sys_nb: string = "A03";

	//E5
	constructor() {
		this.tokenmap = new Map();

		this.total_network_id = 0;
		this.h_anomalycodes = [];
		this.h_anomalymap = new Map();
		this.h_anomalycategs = [];
		this.h_anomalycateglen = -1;
		this.h_incidentcategs = [];
		this.h_incidentcateglen = -1;
		this.h_incidentcodes = [];
		this.h_anomalyNames = [];
		this.h_incidentsNames = [];
		this.cb_filtermap = new Map();
	}

	//E5
	RequestCallback(resp: E5RestResponse): void {
		if (resp.request.otherdata.requesttag === E5RequestMetaTags.CONFIG) this.ConfigCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestMetaTags.META) this.MetaCB(resp);
	};

	//E5
	CancelTag(tag: E5RequestMetaTags): void {
		if (this.sender !== undefined) this.sender.Cancel(this.tokenmap.get(tag));
	};

	//E5
	ClearAll(): void {
		this.h_anomalycategs = [];
		this.h_anomalycateglen = -1;
		this.h_incidentcategs = [];
		this.h_incidentcateglen = -1;
		this.h_incidentcodes = [];

		E5RequestMeta.Ins().CancelTag(E5RequestMetaTags.META);
	};

	// --------- Config -------------

	//E5
	FetchConfig(successcb: (data: any) => void, errorcb1: () => void, errorcb2: () => void): void {
		this.sender = new E5RestSender(this, "");
		let req: E5RestRequest = {
			method: "GET", uri: E5MainConfig.configurl, header: undefined, body: undefined, otherdata:
				{ requesttag: E5RequestMetaTags.CONFIG, successcb: successcb, errorcb1: errorcb1, errorcb2: errorcb2 }
		};
		this.sender.Send(req);
	};

	//E5
	ConfigCB(resp: E5RestResponse): void {
		try {
			if (resp.fetchresult === E5RestFetchResult.ReceiveERR) {
				if (resp.request.otherdata.errorcb1 !== undefined) resp.request.otherdata.errorcb1();
			} else if (resp.fetchresult === E5RestFetchResult.ReceiveEX) {
				if (resp.request.otherdata.errorcb2 !== undefined) resp.request.otherdata.errorcb2();
			} else {
				// main config must be set here because used for sender
				E5MainConfig.SetConfig(resp.body as E5MainConfig);
				// proper sender for future backend requests
				this.sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v3");

				if (resp.request.otherdata.successcb !== undefined) resp.request.otherdata.successcb();
			}
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
	};

	// --------- Meta -------------

	//E5
	FetchMeta(callback: E5RequestCallback): void {
		if (this.sender !== undefined) {
			this.h_anomalycategs = [];
			this.h_incidentcategs = [];
			this.h_incidentcodes = [];
			this.sender.Cancel(this.tokenmap.get(E5RequestMetaTags.META));
			let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
			let req: E5RestRequest = {
				method: "GET", uri: "/metadata/all",
				header: [["Content-Type", "application/json"], ["Authorization", bearer]], body: undefined,
				otherdata: { requesttag: E5RequestMetaTags.META, callback: callback }
			};
			this.tokenmap.set(E5RequestMetaTags.META, this.sender.Send(req));
		}
	};

	//E5
	MetaCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			if (status.success) this.SetMeta(resp.body);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	};

	// --------- Set Meta -------------

	//E5
	SetMeta(respbody: any): void {
		let meta: E5EntMeta = new E5EntMeta(respbody);
		let idx: number, idx2: number;
		let frmap: any = E5UtilI18n.langmap.get("fr");//plain object
		let enmap: any = E5UtilI18n.langmap.get("en");//plain object

		// total network id
		this.total_network_id = E5Entity.ParseInt(respbody.total_network_id);

		// anomalies
		let anoms: string[] = [];
		let anomcategs: string[] = [];
		let anomcategset: Set<string> = new Set();
		for (idx = 0; idx < meta.anomalies.length; idx++) {
			let anom: E5EntMetaAnomaly = meta.anomalies[idx];
			anoms.push(anom.id);
			frmap["wifih-metrics-name-s" + anom.id] = anom.langs.fr;
			enmap["wifih-metrics-name-s" + anom.id] = anom.langs.en;
			frmap["meta-wifi-anom-categ-code-" + anom.categ.categid] = anom.categ.langs.fr;
			enmap["meta-wifi-anom-categ-code-" + anom.categ.categid] = anom.categ.langs.en;
			if (!anomcategset.has(anom.categ.categid)) {
				anomcategset.add(anom.categ.categid);
				anomcategs.push(anom.categ.categid);
				if (this.h_anomalycateglen === -1) this.h_anomalycateglen = anom.categ.categid.length;
			}
		}
		this.h_anomalycodes = anoms;

		// incidents
		let inccategs: string[] = [];
		let inccodes: string[] = [];
		let inccategset: Set<string> = new Set();
		let inccodeset: Set<string> = new Set();
		let anommap: Map<string, string[]> = new Map();
		for (idx = 0; idx < meta.incidents.length; idx++) {
			let inc: E5EntMetaIncident = meta.incidents[idx];
			frmap["meta-wifi-incident-code-" + inc.id] = inc.langs.fr;
			enmap["meta-wifi-incident-code-" + inc.id] = inc.langs.en;
			frmap["meta-wifi-incidentcateg-code-" + inc.categ.categid] = inc.categ.langs.fr;
			enmap["meta-wifi-incidentcateg-code-" + inc.categ.categid] = inc.categ.langs.en;
			if (!inccodeset.has(inc.id)) {
				inccodeset.add(inc.id);
				inccodes.push(inc.id);
			}
			if (!inccategset.has(inc.categ.categid)) {
				inccategset.add(inc.categ.categid);
				inccategs.push(inc.categ.categid);
				if (this.h_incidentcateglen === -1) this.h_incidentcateglen = inc.categ.categid.length;
			}
			anommap.set(inc.id, inc.anomids);
		}
		this.h_anomalymap = anommap;
		this.h_anomalycategs = anomcategs;
		this.h_incidentcategs = inccategs;
		this.h_incidentcodes = inccodes;

		this.h_anomalyNames = meta.anomalies.map((item) => {
			return { id: item.id, name: item.langs.en }
		});
		this.h_incidentsNames = meta.incidents.map((item) => {
			return { id: item.id, name: item.langs.en }
		});
		// contexts
		for (idx = 0; idx < meta.ctxinfos.length; idx++) {
			let ctxinfo: E5EntMetaCtxInfo = meta.ctxinfos[idx];
			frmap["meta-wifi-recom-context-code-" + ctxinfo.ctxinfoid] = ctxinfo.langs.fr;
			enmap["meta-wifi-recom-context-code-" + ctxinfo.ctxinfoid] = ctxinfo.langs.en;
		}

		// recommendations
		for (idx = 0; idx < meta.recoms.length; idx++) {
			let recom: E5EntMetaRecom = meta.recoms[idx];
			for (idx2 = 0; idx2 < meta.recomtargets.length; idx2++)
				if (meta.recomtargets[idx2].id === recom.targetid) {
					frmap["meta-wifi-recom-target-code-" + recom.recomid] = meta.recomtargets[idx2].langs.fr;
					enmap["meta-wifi-recom-target-code-" + recom.recomid] = meta.recomtargets[idx2].langs.en;
				}
			frmap["meta-wifi-recom-code-" + recom.recomid] = recom.langs.fr;
			enmap["meta-wifi-recom-code-" + recom.recomid] = recom.langs.en;
		}

		// filters
		let filtermap: Map<string, string[]> = new Map();
		for (idx = 0; idx < meta.filters.length; idx++) {
			let filter: E5EntMetaFilter = meta.filters[idx];
			filtermap.set(filter.keyname, filter.operators);
			frmap["meta-pop-filter-" + filter.keyname] = filter.langs.fr;
			enmap["meta-pop-filter-" + filter.keyname] = filter.langs.en;
		}
		this.cb_filtermap = filtermap;

		// roles
		for (let [name, role] of meta.roles) {
			frmap["admin-users-role-" + name] = role.fr.short;
			frmap["admin-users-role-" + name + "_long"] = role.fr.long;
			enmap["admin-users-role-" + name] = role.en.short;
			enmap["admin-users-role-" + name + "_long"] = role.en.long;
		}

		// rdm
		E5StoreAdmin.Ins().SetRdmCustomerId(meta.rdminfo.customerid);

		// lang
		E5UtilI18n.SetLanguage(E5UtilI18n.curlang);//to reload the current langmap
	};
}
