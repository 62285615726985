import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5XYChart, E5XYSource} from "../../../global/plot/E5XYChart";
import {E5HDashboard} from "../../household/dashboard/E5HDashboard";
import {E5AngularGauge} from "../../../global/plot/E5AngularGauge";
import {E5PageTitle} from "../../../global/component/E5PageTitle";
import {E5RequestCBSys} from "../../../request/E5RequestCBSys";
import {E5RadarChart} from "../../../global/plot/E5RadarChart";
import {E5RequestMeta} from "../../../request/E5RequestMeta";
import {E5PieChart} from "../../../global/plot/E5PieChart";
import {E5MainConfig} from "../../../global/E5MainConfig";
import {E5StoreCBSys} from "../../../store/E5StoreCBSys";
import {E5RequestCB} from "../../../request/E5RequestCB";
import {E5CBOverviewIndic} from "./E5CBOverviewIndic";
import {E5CBSysOverview} from "./sys/E5CBSysOverview";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5CBSysProcess} from "./sys/E5CBSysProcess";
import {E5StoreCB} from "../../../store/E5StoreCB";
import {E5CBSysReboot} from "./sys/E5CBSysReboot";
import {E5Store} from "../../../store/E5Store";
import {E5CBDashboard} from "./E5CBDashboard";
import {E5CBWifiIndic} from "./E5CBWifiIndic";
import {E5CBOverview} from "./E5CBOverview";
import {E5CBWifi} from "./E5CBWifi";
import {E5CBWan} from "./E5CBWan";
//
import "./E5CBReport.css";

//E5
interface E5CBReportState {}

//E5
interface E5CBReportProps {}

//E5
export const E5CBReport = observer(class E5CBReport extends React.PureComponent<E5CBReportProps, E5CBReportState> {

	// ---------------- MEMBERS ----------------

	//E5
	toasterref: React.RefObject<BP.Toaster>;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5CBReportProps, state: E5CBReportState) {
		super(props, state);
		this.toasterref = React.createRef();
	}

	//E5
	componentDidMount() {
		this.CheckAllStores();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		let {_} = E5UtilI18n, {global, wifi, wan} = E5StoreCB.Ins(), {
			RenderEquipsPie, PieClickEquip1, PieClickEquip2, RenderDetailsPie, PieClickDetail1, PieClickDetail2,
			RenderChart
		} = E5CBDashboard, {overview, cpu, memory, reboot, temperature, process, flash} = E5StoreCBSys.Ins();

		let chanok: boolean = wifi.chanconntimes.length > 0,
			chansrc: E5XYSource = {numdatas: [], options: {bar: chanok, yistime: chanok}};
		E5HDashboard.BuildChanConnTimeSource(wifi.chanconntimes, chansrc, true);

		let pieincinfo: any = E5CBSysOverview.GetPieIncidentInfo(overview, E5RequestMeta.Ins().h_incidentcateglen),
			radarvalues: number[] = E5CBSysOverview.GetRadarValue(overview),
			avghealthcolor: string = E5CBSysOverview.GetAvgHealthColor(radarvalues);


		let [sfwids, sfwparents, sfwvalues, sfwlabels] = E5CBOverview.GetSoftwarePieData(),
			[eqpids, eqpparents, eqpvalues, eqplabels] = E5CBOverview.GetEquipPieData(),
			[staids, staparents, stavalues, stalabels] = E5CBOverview.GetStationPieData(),
			[autoids, autoparents, autovalues, autolabels] = E5CBWifi.GetAutoPieData(),
			[stdids, stdparents, stdvalues, stdlabels] = E5CBWifi.GetStandardPieData(),
			[wiincids, wiincparents, wiincvalues, wiinclabels] = E5CBWifi.GetIncidentPieData(),
			[waincids, waincparents, waincvalues, wainclabels] = E5CBWan.GetIncidentPieData(),
			[accids, accparents, accvalues, acclabels] = E5CBWan.GetAccessPieData(),
			[rebids, rebparents, rebvalues, reblabels] = E5CBSysReboot.GetRebootPieData();

		return <div className="e5page e5cbreport e5fullheight e5column-10">
			<BP.Toaster ref={this.toasterref}/>
			<div className="maintitle">{_("mainwifititle")}</div>
			<div className="e5line-10">
				<E5PageTitle titlekey="pagetitle-cb-dashboard"/>
				<div className="e5linefull"/>
				<BP.Button disabled={!this.IsDataMissing()} onClick={this.LoadAllData}
						   text={_("cbreport-loadall")} icon="refresh"/>
				<BP.Button text={_("cbreport-close")} icon="cross" onClick={this.CloseReport}/>
			</div>

			{/* ------------------------------------ OVERVIEW ------------------------------- */}

			<BP.Divider/>
			<div className="section">{_("wificb-dashboard-over")}</div>
			<E5CBOverviewIndic/>
			<div className="e5line-10">
				<div className="e5linefull">
					<E5AngularGauge gaugeinfo={{value: global.health, title: _("cb-health"), loading: false}}/>
				</div>
				<div className="e5linefull2">
					<E5XYChart leftsource={E5CBDashboard.GetScores(global.healthmetmap, E5CBOverview)} rightsource={{}}
							   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
							   title={_("cb-health-over-time")} loading={false} height={350}/>
				</div>
			</div>
			<div className="e5line-10">
				<div className="e5linefull">
					<E5PieChart pieinfo={{
						title: _("cb-overview-pie-softwarev"), valueisseconds: false, labelisincident: false,
						ids: sfwids, parents: sfwparents, values: sfwvalues, labels: sfwlabels, loading: false
					}}/>
				</div>
				<div className="e5linefull2 e5line-10">
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-overview-pie-equips"), valueisseconds: false, labelisincident: false,
							ids: eqpids, parents: eqpparents, values: eqpvalues, labels: eqplabels, loading: false
						}}/>
					</div>
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-overview-pie-stations"), valueisseconds: false, labelisincident: false,
							ids: staids, parents: staparents, values: stavalues, labels: stalabels, loading: false
						}}/>
					</div>
				</div>
			</div>
			<div className="e5line-10">
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBOverview.GetGrowthEquips()} rightsource={{}}
							   title={_("cb-overview-growth-equips")} loading={false} height={350}
							   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}/>
				</div>
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBOverview.GetGrowthStations()} rightsource={{}}
							   title={_("cb-overview-growth-stations")} loading={false} height={350}
							   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}/>
				</div>
			</div>
			<div className="e5line-10">
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBOverview.GetEquip()} rightsource={{}} title={_("cb-overview-equip")}
							   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
							   loading={false} height={350}/>
				</div>
			</div>

			{/* ------------------------------------ WIFI ----------------------------------- */}

			{E5MainConfig.GetWifiEnabled() && <>
				<BP.Divider/>
				<div className="section wifisection">{_("wificb-dashboard-wifi")}</div>
				<E5CBWifiIndic/>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={
							{value: wifi.health, loading: false, label: "Wi-Fi", title: _("cb-health")}}/>
					</div>
					<div className="e5linefull2">
						<E5XYChart leftsource={E5CBDashboard.GetScores(wifi.healthmetmap, E5CBWifi)} rightsource={{}}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
								   title={_("cb-health-over-time")} loading={false} height={350}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull2">
						<E5XYChart
							leftsource={E5CBWifi.GetIncidents()} rightsource={{}} title={_("cb-inc-over-time")}
							xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
							loading={false} height={350} subtitle={_("cb-incidents-sub")}/>
					</div>
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-pie-inc-cat"), valueisseconds: true, labelisincident: true, ids: wiincids,
							parents: wiincparents, values: wiincvalues, labels: wiinclabels, loading: false
						}}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5XYChart leftsource={E5CBWifi.GetConnectionsBands()} rightsource={{}}
								   title={_("cb-wifi-connections-bands")} loading={false} height={350}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}/>
					</div>
					<div className="e5linefull">
						<E5XYChart leftsource={E5CBWifi.GetConnectionsStandards()} rightsource={{}}
								   title={_("cb-wifi-connections-standards")} loading={false} height={350}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5XYChart leftsource={chansrc} rightsource={{}} title={_("cb-wifi-pie-channelusage")}
								   hidelegend={true} loading={false} height={350}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-wifi-pie-autochan"), valueisseconds: false, labelisincident: false,
							ids: autoids, parents: autoparents, values: autovalues, labels: autolabels, loading: false
						}}/>
					</div>
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-wifi-pie-bandstd"), valueisseconds: true, labelisincident: false,
							ids: stdids, parents: stdparents, values: stdvalues, labels: stdlabels, loading: false
						}}/>
					</div>
				</div>
			</>}

			{/* ------------------------------------ WAN ------------------------------------ */}

			{E5MainConfig.GetWanEnabled() && <>
				<BP.Divider/>
				<div className="section">{_("wificb-dashboard-wan")}</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={
							{value: wan.health, loading: false, label: "WAN", title: _("cb-health")}}/>
					</div>
					<div className="e5linefull2">
						<E5XYChart leftsource={E5CBDashboard.GetScores(wan.healthmetmap, E5CBWan)} rightsource={{}}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
								   title={_("cb-health-over-time")} loading={false} height={350}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-wan-pie-incid"), ids: waincids, parents: waincparents, values: waincvalues,
							labels: wainclabels, loading: false, valueisseconds: true, labelisincident: true
						}}/>
					</div>
					<div className="e5linefull2">
						<E5PieChart pieinfo={{
							title: _("cb-wan-pie-access"), valueisseconds: true, labelisincident: false,
							ids: accids, parents: accparents, values: accvalues, labels: acclabels, loading: false
						}}/>
					</div>
				</div>
			</>}

			{/* ------------------------------------ SYSTEM OVERVIEW ------------------------ */}

			{E5MainConfig.GetSystemEnabled() && <>
				<BP.Divider/>
				<div className="section">{_("cb-sys-overview")}</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={{
							value: overview.health, loading: false, label: _("system"), title: _("cb-health")
						}}/>
					</div>
					<div className="e5linefull2">
						<E5XYChart leftsource={E5CBSysOverview.GetScores(overview)} rightsource={{}}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
								   title={_("cb-health-over-time")} loading={false} height={350}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull2">
						<E5XYChart leftsource={E5CBSysOverview.GetIncidents(overview)} rightsource={{}}
								   title={_("cb-inc-over-time")} loading={false} height={350}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}/>
					</div>
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-pie-inc-cat"), loading: false, valueisseconds: true, labelisincident: true,
							ids: pieincinfo.incids, parents: pieincinfo.incparents, values: pieincinfo.incvalues,
							labels: pieincinfo.inclabels
						}} clickcb={E5CBSysOverview.PieClick}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5RadarChart radarinfo={{
							values: radarvalues, title: _("cb-sys-overview-healths"),
							loading: false, fillcolor: avghealthcolor, labels: [
								_("system-cpu"), _("system-mem"), _("system-flash"),
								_("system-reb"), _("system-temp"), _("system-proc")
							]
						}}/>
					</div>
					<div className="e5linefull2"/>
				</div>
			</>}

			{/* ------------------------------------ SYSTEM CPU ----------------------------- */}

			{E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemCpuEnabled() && <>
				<BP.Divider/>
				<div className="section syscpusection">{_("system-cpu")}</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={{
							value: cpu.health, loading: false, label: _("system-cpu"), title: _("cb-health")
						}}/>
					</div>
					<div className="e5linefull">
						{RenderEquipsPie("cpu", cpu.equips, false, PieClickEquip1)}
					</div>
					<div className="e5linefull">
						{RenderDetailsPie("cpu", cpu.details, false, PieClickDetail1)}
					</div>
				</div>
				{RenderChart("cpu", "gw", false, true)}
				{E5MainConfig.GetExtEnabled() && RenderChart("cpu", "ext", false, true)}
			</>}

			{/* ------------------------------------ SYSTEM MEMORY -------------------------- */}

			{E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemMemoryEnabled() && <>
				<BP.Divider/>
				<div className="section sysmemsection">{_("system-mem")}</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={{
							value: memory.health, loading: false, label: _("system-mem"), title: _("cb-health")
						}}/>
					</div>
					<div className="e5linefull">
						{RenderEquipsPie("memory", memory.equips, false, PieClickEquip1)}
					</div>
					<div className="e5linefull">
						{RenderDetailsPie("memory", memory.details, false, PieClickDetail1)}
					</div>
				</div>
				{RenderChart("memory", "gw", false, true)}
				{E5MainConfig.GetExtEnabled() && RenderChart("memory", "ext", false, true)}
			</>}

			{/* ------------------------------------ SYSTEM REBOOT -------------------------- */}

			{E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemRebootEnabled() && <>
				<BP.Divider/>
				<div className="section sysrebsection">{_("system-reb")}</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={{
							value: reboot.health, loading: false, label: _("system-reb"), title: _("cb-health")
						}}/>
					</div>
					<div className="e5linefull">
						{RenderEquipsPie("reboot", reboot.rebequips, false, PieClickEquip1, "reb")}
					</div>
					<div className="e5linefull">
						{RenderDetailsPie("reboot", reboot.rebdetails, false, PieClickDetail1, "reb")}
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-sys-reboot-piereb"), roundvalues: true, ids: rebids, parents: rebparents,
							subtitle: _("cb-sys-reboot-pie-reason-sub"), loading: false, values: rebvalues,
							valueisseconds: false, labelisincident: false, labels: reblabels
						}}/>
					</div>
					<div className="e5linefull">
						{RenderEquipsPie("reboot", reboot.uptequips, false, PieClickEquip2, "upt")}
					</div>
					<div className="e5linefull">
						{RenderDetailsPie("reboot", reboot.uptdetails, false, PieClickDetail2, "upt")}
					</div>
				</div>
				{RenderChart("reboot", "gw", false, true, undefined, "reb")}
				{E5MainConfig.GetExtEnabled() &&
				RenderChart("reboot", "ext", false, true, undefined, "reb")}
				<div className="section sysrebsection2"/>
				{RenderChart("reboot", "gw", false, true, undefined, "upt")}
				{E5MainConfig.GetExtEnabled() &&
				RenderChart("reboot", "ext", false, true, undefined, "upt")}
			</>}

			{/* ------------------------------------ SYSTEM TEMPERATURE --------------------- */}

			{E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemTemperatureEnabled() && <>
				<BP.Divider/>
				<div className="section systempsection">{_("system-temp")}</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={{
							value: temperature.health, loading: false,
							label: _("system-temp"), title: _("cb-health")
						}}/>
					</div>
					<div className="e5linefull">
						{RenderEquipsPie("temperature", temperature.equips, false, PieClickEquip1)}
					</div>
					<div className="e5linefull">
						{RenderDetailsPie("temperature", temperature.details, false, PieClickDetail1)}
					</div>
				</div>
				{RenderChart("temperature", "gw", false, true)}
				{E5MainConfig.GetExtEnabled() && RenderChart("temperature", "ext", false, true)}
			</>}

			{/* ------------------------------------ SYSTEM PROCESS ------------------------- */}

			{E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemProcessEnabled() && <>
				<BP.Divider/>
				<div className="section">{_("system-proc")}</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={{
							value: process.health, loading: false, label: _("system-proc"),
							title: _("cb-health")
						}}/>
					</div>
					<div className="e5linefull2">
						<E5XYChart leftsource={E5CBSysProcess.GetSource("status")} rightsource={{}} height={350}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
								   title={E5UtilI18n._("cb-sys-process-statusmet")} loading={false}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">{E5CBSysProcess.RenderProcTable("cpu")}</div>
					<div className="e5linefull2">
						<E5XYChart leftsource={E5CBSysProcess.GetSource("cpu")} rightsource={{}} height={350}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
								   title={E5UtilI18n._("cb-sys-process-cpumet")} loading={false}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">{E5CBSysProcess.RenderProcTable("mem")}</div>
					<div className="e5linefull2">
						<E5XYChart leftsource={E5CBSysProcess.GetSource("mem")} rightsource={{}} height={350}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
								   title={E5UtilI18n._("cb-sys-process-memmet")} loading={false}/>
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull">{E5CBSysProcess.RenderProcTable("crash")}</div>
					<div className="e5linefull2">
						<E5XYChart leftsource={E5CBSysProcess.GetSource("crash")} rightsource={{}} height={350}
								   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
								   title={E5UtilI18n._("cb-sys-process-crashmet")} loading={false}/>
					</div>
				</div>
			</>}

			{/* ------------------------------------ SYSTEM FLASH --------------------------- */}

			{E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemFlashEnabled() && <>
				<BP.Divider/>
				<div className="section sysflashsection">{_("system-flash")}</div>
				<div className="e5line-10">
					<div className="e5linefull">
						<E5AngularGauge gaugeinfo={{
							value: flash.health, loading: false, label: _("system-flash"), title: _("cb-health")
						}}/>
					</div>
					<div className="e5linefull">
						{RenderEquipsPie("flash", flash.flusaequips, false, PieClickEquip1, "flusa")}
					</div>
					<div className="e5linefull">
						{RenderDetailsPie("flash", flash.flusadetails, false, PieClickDetail1, "flusa")}
					</div>
				</div>
				<div className="e5line-10">
					<div className="e5linefull"/>
					<div className="e5linefull">
						{RenderEquipsPie("flash", flash.flcorequips, false, PieClickEquip2, "flcor")}
					</div>
					<div className="e5linefull">
						{RenderDetailsPie("flash", flash.flcordetails, false, PieClickDetail2, "flcor")}
					</div>
				</div>
				{RenderChart("flash", "gw", false, true, undefined, "flusa")}
				{E5MainConfig.GetExtEnabled() &&
				RenderChart("flash", "ext", false, true, undefined, "flusa")}
				{RenderChart("flash", "gw", false, true, undefined, "flcor")}
				{E5MainConfig.GetExtEnabled() &&
				RenderChart("flash", "ext", false, true, undefined, "flcor")}
				<div className="section sysflashsection2"/>
			</>}
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	CloseReport = (): void => E5Store.Ins().ChangeReport(false);

	//E5
	LoadAllData = (): void => {
		let {populationid, startdate, enddate} = E5StoreCB.Ins().filterinfo;
		if (E5StoreCB.Ins().global.health === undefined)
			E5RequestCB.FetchGlobal(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("cb-dashboard-over"));
		if (E5MainConfig.GetWifiEnabled() && E5StoreCB.Ins().wifi.health === undefined)
			E5RequestCB.FetchWifi(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("cb-dashboard-wifi"));
		if (E5MainConfig.GetWanEnabled() && E5StoreCB.Ins().wan.health === undefined)
			E5RequestCB.FetchWan(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("cb-dashboard-wan"));
		if (E5MainConfig.GetSystemEnabled() && E5StoreCBSys.Ins().overview.health === undefined)
			E5RequestCBSys.FetchOverview(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("cb-sys-overview"));
		if (E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemCpuEnabled() &&
			E5StoreCBSys.Ins().cpu.health === undefined)
			E5RequestCBSys.FetchCpu(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("system-cpu"));
		if (E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemMemoryEnabled() &&
			E5StoreCBSys.Ins().memory.health === undefined)
			E5RequestCBSys.FetchMemory(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("system-mem"));
		if (E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemRebootEnabled() &&
			E5StoreCBSys.Ins().reboot.health === undefined)
			E5RequestCBSys.FetchReboot(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("system-reb"));
		if (E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemTemperatureEnabled() &&
			E5StoreCBSys.Ins().temperature.health === undefined)
			E5RequestCBSys.FetchTemperature(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("system-temp"));
		if (E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemProcessEnabled() &&
			E5StoreCBSys.Ins().process.health === undefined)
			E5RequestCBSys.FetchProcess(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("system-proc"));
		if (E5MainConfig.GetSystemEnabled() && E5MainConfig.GetSystemFlashEnabled() &&
			E5StoreCBSys.Ins().flash.health === undefined)
			E5RequestCBSys.FetchFlash(populationid, startdate, enddate, () =>
				E5StoreCB.Ins().request.set.add("system-flash"));
		this.toasterref.current?.show(
			{message: E5UtilI18n._("cbreport-loadall-wait"), intent: BP.Intent.PRIMARY});
	}


	// ---------------- UTILS ----------------

	//E5
	IsDataMissing(): boolean {
		return E5StoreCB.Ins().global.health === undefined || (E5MainConfig.GetWifiEnabled() &&
			E5StoreCB.Ins().wifi.health === undefined) || (E5MainConfig.GetWanEnabled() &&
			E5StoreCB.Ins().wan.health === undefined) || (E5MainConfig.GetSystemEnabled() &&
			E5StoreCBSys.Ins().overview.health === undefined) || (E5MainConfig.GetSystemEnabled() &&
			E5StoreCBSys.Ins().cpu.health === undefined) || (E5MainConfig.GetSystemEnabled() &&
			E5StoreCBSys.Ins().memory.health === undefined) || (E5MainConfig.GetSystemEnabled() &&
			E5StoreCBSys.Ins().reboot.health === undefined) || (E5MainConfig.GetSystemEnabled() &&
			E5StoreCBSys.Ins().temperature.health === undefined) || (E5MainConfig.GetSystemEnabled() &&
			E5StoreCBSys.Ins().process.health === undefined) || (E5MainConfig.GetSystemEnabled() &&
			E5StoreCBSys.Ins().flash.health === undefined);
	}

	//E5
	CheckAllStores(): void {
		if (this.IsDataMissing()) this.toasterref.current?.show(
			{message: E5UtilI18n._("cbreport-notalldata"), intent: BP.Intent.DANGER});
	}
});
