import { InputBaseComponentProps, styled, TextField, TextFieldVariants } from "@mui/material";
import { Ref } from "react";

import "./InputStyledField";

interface InputStyledFieldInterface {
    inputRef?: Ref<InputStyledFieldInterface>;
    id?: string;
    label?: string | React.ReactElement<any, any>;
    variant?: TextFieldVariants;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    className?: string;
    value?: unknown;
    inputProps?: InputBaseComponentProps;
    autoComplete?: string;
    required?: boolean;
    title?: string;
    disabled?: boolean;
}

const CssTextField = styled(TextField)({
    '& label': {
        color: '#0080FF',
        fontFamily: 'Montserrat',
        fontSize: '14px',
    },
    '& input': {
        borderRadius: '10px',
        fontFamily: 'Montserrat',
        height: '18px',
        fontSize: '14px',
    },
    '& label.Mui-focused': {
        color: '#0080FF',
    },
    '& label.Mui': {
        color: '#0080FF',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0080FF'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        '&:hover fieldset': {
            borderColor: '#0080FF',
        },
        '& fieldset': {
            borderColor: '#0080FF',
            color: '#0080FF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0080FF',
            color: '#0080FF',
        },
    },
    '& input:-ms-input-placeholder, & input::-ms-input-placeholder, & input::placeholder': {
        color: '#0080FF', // Set the desired color for the placeholder text
        opacity: 1,
    },
});

export default function InputStyledField({
    inputRef,
    id,
    label,
    variant,
    onChange,
    className,
    value,
    inputProps,
    autoComplete,
    required,
    title,
    disabled,
}: Readonly<InputStyledFieldInterface>) {
    return (
        <CssTextField inputRef={inputRef} id={id} label={label} variant={variant} inputProps={inputProps} required={required ?? false} title={title}
            onChange={onChange} className={className} value={value} autoComplete={autoComplete} size='small' disabled={disabled ?? false} />
    );
}