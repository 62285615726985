import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5AdminUsersAction, E5AdminUserSaveReceive, E5AdminUsersList } from "./E5AdminUsersList";
import { E5StoreAdmin, E5StoreAdminUsers } from "../../../store/E5StoreAdmin";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5RequestStatus } from "../../../request/E5ServiceCommon";
import { E5RequestAdmin } from "../../../request/E5RequestAdmin";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5EntUser } from "../../../entity/E5EntUser";
import { E5Store } from "../../../store/E5Store";
//
import "./E5AdminUsers.css";

//E5
interface E5AdminUsersState { }

//E5
interface E5AdminUsersProps {
	userinfo: E5StoreAdminUsers;
}

//E5
export const E5AdminUsers = observer(class E5AdminUsers extends React.PureComponent
	<E5AdminUsersProps, E5AdminUsersState> {

	// ---------------- MEMBERS ----------------

	//E5
	toasterref: React.RefObject<BP.Toaster>;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5AdminUsersProps, state: E5AdminUsersState) {
		super(props, state);
		this.toasterref = React.createRef();
	}

	//E5
	componentDidMount(): void {
		if (this.props.userinfo.users.length === 0) E5RequestAdmin.Ins().LoadUsers(undefined);
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		return <div className="e5page e5admin-users e5columnfull e5column-20">
			<BP.Toaster ref={this.toasterref} />
			<div className="e5column-5">
				{/* <BP.Breadcrumbs
					items={[
						{ icon: "bookmark", text: E5UtilI18n._("mainmenu-management") },
						{ text: E5UtilI18n._("mainmenu-m-users") }
					]}
				/> */}
				<E5PageTitle titlekey="pagetitle-management-users" />
			</div>
			<div className="e5admin-users-content e5columnfull">
				<E5AdminUsersList langinfo={E5Store.Ins().langinfo} userinfo={E5StoreAdmin.Ins().userinfo}
					usergetinfo={E5StoreAdmin.Ins().usergetinfo} savefunc={this.SaveUser} downloadUsers={this.DownloadUsers} />
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	// the callback will inform userlist if save have succeded or not
	DownloadUsers = async (callback) => {
		const res = await E5RequestAdmin.Ins().DownloadUsers((status: E5RequestStatus) => {
			if (status.success) {
				this.toasterref.current?.show(
					{ message: 'Download successful', intent: BP.Intent.SUCCESS });
				callback()

			} else this.toasterref.current?.show({
				message: 'Downloading failed',
				intent: BP.Intent.DANGER
			});
		});
	}
	SaveUser = async (action: E5AdminUsersAction, user: E5EntUser, username: string, userlistcb: E5AdminUserSaveReceive, usernames, usersForRemove, updateRows) => {
		if (action === E5AdminUsersAction.update) {

			E5RequestAdmin.Ins().UpdateUser(username, user, (status: E5RequestStatus) => {
				// notify the list
				userlistcb(status.success);

				if (status.success) {
					this.toasterref.current?.show(
						{ message: E5UtilI18n._("admin-users-updateok"), intent: BP.Intent.SUCCESS });
					// reload the user
					E5RequestAdmin.Ins().GetUser(user.username, (status: E5RequestStatus, user: E5EntUser) => {
						if (status.success) this.ChangeUserInList(username, user);
						else this.toasterref.current?.show({
							message: E5UtilI18n._("admin-users-get-failed") + " : " + status.message,
							intent: BP.Intent.DANGER
						});
					});
				} else this.toasterref.current?.show({
					message: E5UtilI18n._("admin-users-update-failed") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});
		} else if (action === E5AdminUsersAction.add) {
			E5RequestAdmin.Ins().AddUser(user, (status: E5RequestStatus) => {
				// notify the list
				userlistcb(status.success);

				if (status.success) {
					this.toasterref.current?.show(
						{ message: E5UtilI18n._("admin-users-updateok"), intent: BP.Intent.SUCCESS });
					// reload the user
					E5RequestAdmin.Ins().GetUser(user.username, (status: E5RequestStatus, user: E5EntUser) => {
						if (status.success) this.AddUserInList(user);
						else this.toasterref.current?.show({
							message: E5UtilI18n._("admin-users-get-failed") + " : " + status.message,
							intent: BP.Intent.DANGER
						});
					});
				} else this.toasterref.current?.show({
					message: E5UtilI18n._("admin-users-update-failed") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});
		} else if (action === E5AdminUsersAction.delete) {
			updateRows()
			E5RequestAdmin.Ins().DeleteUser(username, (status: E5RequestStatus) => {
				// notify the list
				userlistcb(status.success);

				if (status.success) {
					this.toasterref.current?.show(
						{ message: E5UtilI18n._("admin-users-updateok"), intent: BP.Intent.SUCCESS });
					this.DeleteUserInList(username);
				} else this.toasterref.current?.show({
					message: E5UtilI18n._("admin-users-update-failed") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});
		} else if (action === E5AdminUsersAction.deleteMany) {
			updateRows()
			for (let user of usersForRemove) {
				await E5RequestAdmin.Ins().DeleteUser(user.username, (status: E5RequestStatus) => {
					if (status.success) {
						this.toasterref.current?.show(
							{ message: E5UtilI18n._("admin-users-updateok"), intent: BP.Intent.SUCCESS });
					} else this.toasterref.current?.show({
						message: E5UtilI18n._("admin-users-update-failed") + " : " + status.message,
						intent: BP.Intent.DANGER
					});
				});
			}
			this.DeleteUsersInList(usernames)
			userlistcb(true);

		}
	};

	//E5
	ChangeUserInList = (username: string, user: E5EntUser): void =>
		E5StoreAdmin.Ins().SetUsers({ loading: false, success: true, message: "" },
			this.props.userinfo.users.map(curuser => curuser.username === username ? user : curuser));

	//E5
	AddUserInList = (user: E5EntUser): void =>
		E5StoreAdmin.Ins().SetUsers({ loading: false, success: true, message: "" },
			[...this.props.userinfo.users, user]);

	//E5
	DeleteUserInList = async (username: string): Promise<void> =>
		E5StoreAdmin.Ins().SetUsers({ loading: false, success: true, message: "" },
			this.props.userinfo.users.filter(user => user.username !== username));
	DeleteUsersInList = async (usernames: string): Promise<void> => {
		E5StoreAdmin.Ins().SetUsers(
			{ loading: false, success: true, message: "" },
			this.props.userinfo.users.filter(user => !usernames.includes(user.username)))
			;
	}

});
