import {E5EntHIncid} from "./E5EntHIncid";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidSys extends E5EntHIncid {
	software: string = "";
	model: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.software = E5Entity.AssignString(json.software_version);
		this.model = E5Entity.AssignString(json.model_name);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), software_version: this.software, model_name: this.model};
	}
}
