import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import './SelectStyled.css';

// Enum for default project colors ([refactor]- create separate file in future)
enum Colors {
    Primary = '#0080FF',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const useStyles = makeStyles((theme) => ({
    customMenu: {
        '& .MuiList-root': {
            padding: '0',
        },
    },
}));


const StyledSelect = styled(Select)`
  && {
    &.MuiOutlinedInput-root {
      min-width: 100px;
      fieldset {
        border: none;
      }
    },
    & .MuiOutlinedInput-input {
      padding: 0 !important;
      color: ${Colors.Primary};
    },
    & .MuiSvgIcon-root {
      fill: ${Colors.Primary} !important;
    },
  }
`;

interface SelectStyledProps {
    data: string[];
    onChange: (value: string) => void;
    value: string;
    className?: string;
}

const DefaultSelect: React.FC<SelectStyledProps> = ({ data, onChange, value, className }) => {
    const generateMenuItems = () => {
        return data.map((item: string) => (
            <MenuItem className='select-styled-item' key={item} value={item}>
                {item}
            </MenuItem>
        ));
    };

    const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
        const {
            target: { value },
        } = event;
        onChange(value as string);
    };

    const classes = useStyles();

    const menuProps = {
        classes: {
            paper: classes.customMenu,
        },
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minWidth: 130,
            },
        },
    };

    return (
        <div>
            <StyledSelect value={value} onChange={handleChange} MenuProps={menuProps} className={className}>
                {generateMenuItems()}
            </StyledSelect>
        </div>
    );
};

export default DefaultSelect;