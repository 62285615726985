import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5EntHIncidWifi} from "../../../entity/household/incid/E5EntHIncidWifi";
import {E5HTop5Incidents} from "../../household/dashboard/E5HTop5Incidents";
import {E5EntHStation} from "../../../entity/household/topo/E5EntHStation";
import {E5HIncidentList} from "../../household/incident/E5HIncidentList";
import {E5EntHEquip} from "../../../entity/household/topo/E5EntHEquip";
import {E5BandEnum, E5NodeTypeEnum} from "../../../entity/E5Enums";
import {E5EntHIncidAny, E5StoreH} from "../../../store/E5StoreH";
import {E5RequestMeta} from "../../../request/E5RequestMeta";
import {E5RequestH} from "../../../request/E5RequestH";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5TestTopoGraph} from "./E5TestTopoGraph";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestIncidsRecoms.css";

//E5
interface E5TestIncidsRecomsState {
	incidents: E5EntHIncidAny[];
	equips: E5EntHEquip[];
	equipmap: Map<string, E5EntHEquip>;
	stations: E5EntHStation[];
	stationmap: Map<string, E5EntHStation>;
}

//E5
interface E5TestIncidsRecomsProps {}

//E5
export const E5TestIncidsRecoms = observer(class E5TestIncidsRecoms extends React.PureComponent
	<E5TestIncidsRecomsProps, E5TestIncidsRecomsState> {

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestIncidsRecomsProps, state: E5TestIncidsRecomsState) {
		super(props, state);
		let idx: number, eqps: E5EntHEquip[] = [], eqpmap: Map<string, E5EntHEquip> = new Map();
		for (idx = 0; idx < 4; idx++) {
			let tp: E5NodeTypeEnum = E5NodeTypeEnum.gw;
			if (idx % 3 === 1)
				tp = E5NodeTypeEnum.ext;
			if (idx % 3 === 2)
				tp = E5NodeTypeEnum.stb;
			let eqp: E5EntHEquip = new E5EntHEquip({imei: E5TestTopoGraph.GetFakeImei(), node_type: tp});
			eqps.push(eqp);
			eqpmap.set(eqp.imei, eqp);
		}
		let stas: E5EntHStation[] = [], stamap: Map<string, E5EntHStation> = new Map();
		for (idx = 0; idx < 4; idx++) {
			let sta: E5EntHStation = new E5EntHStation({mac_address: E5TestTopoGraph.GetFakeMacAddress()});
			stas.push(sta);
			stamap.set(sta.macaddress, sta);
		}
		this.state = {incidents: [], equips: eqps, equipmap: eqpmap, stations: stas, stationmap: stamap};
	}

	//E5
	componentDidMount(): void {
		this.setState({incidents: this.GetIncidents(20)});
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let seldatestr: string = E5StoreH.Ins().curdateinfo.curdate,
			incidentmap: Map<string, E5EntHIncidAny[]> = new Map();
		incidentmap.set(seldatestr, this.state.incidents);

		return <div className="e5testpage e5test-incids-recoms e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-incids-recoms-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Refresh">
					<BP.Button onClick={this.Refresh} icon="refresh"/>
				</BP.Callout>
			</div>
			<div className="e5line-20">
				<E5HIncidentList
					incidentinfo={{loading: false, incidentmap, messagemap: new Map(), seldatestr}}
					equipinfo={{equips: this.state.equips, equipmap: this.state.equipmap}}
					stationinfo={{stations: this.state.stations, stationmap: this.state.stationmap}}
					selincidentinfo={{incidentindex: 2}} showgraph={false}
					searchniinfo={{
						status: {loading: false, success: false, message: ""}, networkidtyped: "",
						networkid: "", timezone: "", timezonedelta: 0, requesttime: undefined, datatime: undefined
					}}
				/>
				<E5HTop5Incidents
					langinfo={E5Store.Ins().langinfo}
					incidentinfo={{loading: false, incidentmap, messagemap: new Map(), seldatestr}}
					equipinfo={{equips: this.state.equips, equipmap: this.state.equipmap}}
					stationinfo={{stations: this.state.stations, stationmap: this.state.stationmap}}
					selincidentinfo={{incidentindex: 2}}
					searchniinfo={{
						status: {loading: false, success: false, message: ""}, networkidtyped: "",
						networkid: "", timezone: "", timezonedelta: 0, requesttime: undefined, datatime: undefined
					}}
				/>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Refresh = (): void => {
		this.setState({incidents: this.GetIncidents(20)});
	};

	// ---------------- UTILS ----------------

	//E5
	GetIncidents = (quantity: number): E5EntHIncidAny[] => {
		let incidents: E5EntHIncidAny[] = [], idx: number;
		let incident_ids: string[] = E5RequestMeta.Ins().h_incidentcodes;
		// fake incidents
		for (idx = 0; idx < quantity; idx++) {
			let recoms: string[] = [], imeis: string[] = [], macaddresses: string[] = [], idx2: number, len: number;
			len = Math.round(Math.random() * 4);
			for (idx2 = 0; idx2 < len; idx2++)
				recoms.push("incident" + idx + " :: recom" + idx2);
			len = Math.round(Math.random() * 4);
			for (idx2 = 0; idx2 < len; idx2++)
				imeis.push(this.state.equips[idx2].imei);
			len = Math.round(Math.random() * 4);
			for (idx2 = 0; idx2 < len; idx2++)
				macaddresses.push(this.state.stations[idx2].macaddress);
			let bands: string[] = [
				E5BandEnum.freq2ghz, E5BandEnum.freq5ghz, E5BandEnum.freq6ghz,
				E5EntHIncidWifi.bandmultiple, E5EntHIncidWifi.bandunknown
			];
			let incident: E5EntHIncidAny = new E5EntHIncidWifi({
				incident_id: incident_ids[Math.floor(Math.random() * 10)],
				duration: Math.random() * 1000,
				criticality: 10 * 60 + Math.random() * (90 * 60),
				recommendations: recoms,
				nodes: imeis,
				devices: macaddresses,
				severity_over_time: [],
				band: bands[Math.floor(Math.random() * bands.length)],
				affects_backhaul: idx % 2 === 0
			});
			incidents.push(incident);
		}
		incidents.sort(E5RequestH.CompareIncident)
		return incidents;
	};
});
