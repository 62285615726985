import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIndSysReb extends E5Entity {
	imei: string = "";
	rebcnt: number|null|undefined = undefined;
	reasonmap: Map<string, number>|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);
		this.rebcnt = E5Entity.ParseIntNull(json.reboot_count);

		let key: string;
		this.reasonmap = null;
		if (typeof json.reboot_reason_count === "object" && json.reboot_reason_count !== null) {
			this.reasonmap = new Map();
			for (key in json.reboot_reason_count) if (json.reboot_reason_count.hasOwnProperty(key))
				this.reasonmap.set(key, E5Entity.ParseInt(json.reboot_reason_count[key]));
		}
	}

	//E5
	CopyToJson(): any {
		let reboot_reason_count: any = null, reason: string, cnt: number;
		if (this.reasonmap instanceof Map) {
			reboot_reason_count = {};
			for ([reason, cnt] of this.reasonmap) reboot_reason_count[reason] = cnt;
		}

		return {node_imei: this.imei, reboot_count: this.rebcnt, reboot_reason_count};
	}
}
