import {E5Entity} from "../E5Entity";

//E5
export class E5EntMetaRdmInfo extends E5Entity {
	customerid: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.customerid = E5Entity.AssignString(json.customerId);
	}

	//E5
	CopyToJson(): any {
		return {customerId: this.customerid};
	}
}
