import {E5EntHIncidSys} from "./E5EntHIncidSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidSysFlash extends E5EntHIncidSys {
	ubiname: string = "";
	totalsize: number|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.ubiname = E5Entity.AssignString(json.ubi_device_name);
		this.totalsize = E5Entity.ParseIntNull(json.total_volume_size);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), ubi_device_name: this.ubiname, total_volume_size: this.totalsize};
	}
}
