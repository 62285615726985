import {E5BandEnum} from "./E5Enums";
import {E5Entity} from "./E5Entity";

//E5
export class E5EntWifiChanTime extends E5Entity {
	band: E5BandEnum = E5BandEnum.none;
	chantime: Map<string, number> = new Map();

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.band = Object.values(E5BandEnum).includes(json.band) ? json.band : E5BandEnum.none;

		let key: string;
		this.chantime = new Map();
		if (typeof json.time === "object" && json.time !== null)
			for (key in json.time) if (json.time.hasOwnProperty(key))
				this.chantime.set(key, E5Entity.ParseInt(json.time[key]))
	}

	//E5
	CopyToJson(): any {
		let chantime: any = {}, chan: string, time: number;
		for ([chan, time] of this.chantime) chantime[chan] = time;

		return {band: this.band, time: chantime};
	}
}
