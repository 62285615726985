import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5MetricChart, E5MetricNumData } from "../../../global/plot/E5MetricChart";
import { E5Store } from "../../../store/E5Store";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5HMetricIndic } from "./E5HMetricIndic";
import { E5HEquipList } from "../E5HEquipList";
import { E5Time } from "../../../util/E5Time";
import {
    E5StoreHTopoSelected, E5StoreHEquips, E5StoreH, E5StoreHMetProcess
} from "../../../store/E5StoreH";
import { E5EntHMetSys, E5HMetSysField } from "../../../entity/household/metric/E5EntHMetSys";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5NetElementType } from "../../../entity/E5Enums";
import { E5EntHMetSysReb } from "../../../entity/household/metric/E5EntHMetSysReb";
import { E5EntHStation } from "../../../entity/household/topo/E5EntHStation";

import { Select, OutlinedInput, MenuItem, InputLabel, FormControl } from '@mui/material'

import './E5HMetricProcess.css'
import {E5Text} from "../../../util/E5Text";
interface E5HMetricProcessProps {
    equipinfo: E5StoreHEquips;
    selectedinfo: E5StoreHTopoSelected;
    metricinfo: E5StoreHMetProcess
}


const options: E5HMetSysField[] = [
    { name: "processcputime", curveper: "name", unit: "h", addholes: true },
    { name: "processmemsize", curveper: "name", unit: "MB", addholes: true },
    { name: "processmemusage", curveper: "name", unit: "%", addholes: true },
    { name: "processcpuusage", curveper: "name", unit: "MB", addholes: true },
]

const { node } = E5NetElementType;
const { _ } = E5UtilI18n;
export const E5HMetricProcess: React.FC<E5HMetricProcessProps> = observer((props) => {


    const handleChange = (e: any) => {
        const { value } = e.target
        const eqp = E5StoreH.Ins().equipinfo.equips.find(equip => equip.imei === value)
        if (eqp) {
            E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.node, eqp, new E5EntHStation());
        }
    }

    const [selectedOption, setSelectedOption] = useState("processmemusage")
    const { equipinfo, selectedinfo, metricinfo } = props

    const { type, equip } = selectedinfo;

    useEffect(() => {
        let content: any = document.getElementsByClassName("e5wifih-metrics-content");
        if (content !== undefined && content !== null && content.length > 0) {
            let inner: any = document.getElementsByClassName("e5wifih-metrics-leftcolumn");
            if (inner !== undefined && inner !== null && inner.length > 0) {
                let innerheight: number = content[0].clientHeight - 10;
                inner[0].style.height = innerheight + "px";
                if (equipinfo.equips.length * 47 > innerheight)
                    inner[0].className = "e5wifih-metrics-leftcolumn scroll-less-wide";
                else inner[0].className = "e5wifih-metrics-leftcolumn";
            }

            inner = document.getElementsByClassName("e5metrics-scrollable");
            if (inner !== undefined && inner !== null && inner.length > 0)
                inner[0].style.height = (content[0].clientHeight - 73) + "px";
        }

    }, [equipinfo])


    const xydata = useMemo(() => {
        let udf: undefined = undefined;
        let sides: string[] = ["left", 'right', 'additional'];
        let xydata: any = { left: [{ numdatas: udf, categdata: udf }], right: [{ numdatas: udf, categdata: udf }], additional: [{ numdatas: udf, categdata: udf }] };

        // for (let idx = 0; idx < 1; idx++) {
        //     xydata.left.push({ numdatas: udf, categdata: udf });
        // }



        if (selectedinfo.type === E5NetElementType.node)
            for (let side of sides) {
                for (let idx = 0; idx < 1; idx++) {
                    if (side === 'right' || side === 'additional') {
                        let numdatas: E5MetricNumData[] = [];
                        let numdata: E5MetricNumData = { xaxisdata: [], yaxisdata: [], hovertext: ['', ''] };
                        numdata.datalabel = side === 'additional' ? "Firmware Update" : E5UtilI18n._("h-metric-sys-field-reboot")
                        let gfieldname: string | undefined = "rebootcnt";
                        let gstaticval: number = 0;
                        let metlist: E5EntHMetSysReb[] = metricinfo.reboot.filter(met => side === 'additional' ? met.source === 'FU TR69' : met.source !== 'FU TR69');
                        numdata.markers = true;
                        numdata.fillcolor = side === 'additional' ? "orange" : 'blue'
                        for (let met of metlist) {
                            if (met.imei !== selectedinfo.equip.imei) continue;
                            let val: number = gstaticval;
                            if (gfieldname !== udf) {
                                val = (met as any)[gfieldname];
                                if (gfieldname === "uptime") val = E5Text.RoundUp(val / 3600);
                            }
                            let timestr: string = met.time.format();
                            let labeltime = met.time.format('HH:mm')
                            const isUpdate = met.source === 'FU TR69'
                            numdata.hovertemplate = `none`
                            numdata.hovertext?.push(`${isUpdate ? 'Firmware Update' : "Equipment reboot"} - ${labeltime}<br>Reason: ${met.reason}<br>Source: ${met.source}`)
                            numdata.xaxisdata.push(timestr);
                            numdata.yaxisdata.push(val);
                        }
                        numdatas.push(numdata);
                        xydata[side][idx].numdatas = numdatas;

                    } else {
                        let field: E5HMetSysField | undefined = options.find(opt => opt.name === selectedOption);
                        if (field) {
                            let { curveper, name, addholes } = field;
                            let numdatas: E5MetricNumData[] = [];
                            if (curveper !== udf) {
                                xydata[side][idx].options = { stacked: true };
                                let metlist: E5EntHMetSys[] = [];
                                let gfieldname: string = "";
                                switch (name) {
                                    case "processmemusage":
                                        metlist = metricinfo.process;
                                        gfieldname = "memusage";
                                        break;
                                    case "processcpuusage":
                                        metlist = metricinfo.process;
                                        gfieldname = "cpuusage";
                                        break;
                                    case "processmemsize":
                                        metlist = metricinfo.process;
                                        gfieldname = "memsize";
                                        break;
                                    case "processcputime":
                                        metlist = metricinfo.process;
                                        gfieldname = "cputime";
                                        break;
                                }
                                let curveset: Set<string> = new Set(), curvename: string;
                                for (let met of metlist) {
                                    if (met.imei !== selectedinfo.equip.imei) continue;
                                    curvename = (met as any)[curveper];
                                    if (!curveset.has(curvename)) curveset.add(curvename);
                                }
                                for (curvename of curveset) {
                                    let numdata: E5MetricNumData = {
                                        xaxisdata: [], yaxisdata: [],
                                        datalabel: curvename.length > 20 ? curvename.substring(0, 16) + " ..." : curvename,
                                        hovertemplate: "%{y} (%{x})<extra>" + (curvename.length > 63 ?
                                            curvename.substring(0, 59) + " ..." : curvename) + "</extra>"
                                    };
                                    let prevmet: E5EntHMetSys | undefined = udf;
                                    for (let met of metlist) {
                                        if (met.imei !== selectedinfo.equip.imei) continue;
                                        if ((met as any)[curveper] === curvename) {
                                            let value: number = name === 'crashevent' ? 1 : (met as any)[gfieldname], prevval: number = 0;
                                            if (gfieldname === "cputime") value = E5Text.RoundUp(value / 3600);
                                            if (["totalerase", "availerase"].includes(gfieldname)) value = E5Text.RoundUp(value / (1024 * 1024));
                                            // process cut = prev.end < cur.start - holesizesec
                                            if (addholes) {
                                                if (prevmet !== udf && prevmet.endtime.unix() < met.starttime.unix() -
                                                    E5MainConfig.GetHoleSizeSec()) {
                                                    if (gfieldname !== udf) {
                                                        prevval = (prevmet as any)[gfieldname];
                                                        if (gfieldname === "cputime") prevval = E5Text.RoundUp(prevval / 3600);
                                                    }
                                                    numdata.xaxisdata.push(prevmet.endtime.format());
                                                    numdata.yaxisdata.push(prevval);
                                                    numdata.xaxisdata.push(prevmet.endtime.format());
                                                    numdata.yaxisdata.push(udf);
                                                    numdata.xaxisdata.push(met.starttime.format());
                                                    numdata.yaxisdata.push(udf);
                                                    numdata.xaxisdata.push(met.starttime.format());
                                                    numdata.yaxisdata.push(value);
                                                }
                                            }
                                            numdata.xaxisdata.push(met.time.format());
                                            numdata.yaxisdata.push(value);
                                            prevmet = met;
                                        }
                                    }
                                    numdatas.push(numdata);
                                }
                                xydata[side][idx].numdatas = numdatas;
                            }
                        }
                    }
                }
            }
        return xydata;
    }, [selectedOption, metricinfo.process, metricinfo.reboot, selectedinfo.equip.imei, selectedinfo.type])


    return <div className="e5wifih-metrics-content e5columnfull e5wifih-metrics-process">
        <div className="e5wifih-metrics-components e5column-20">
            <div className="e5line-20">
                <div className="e5wifih-metrics-leftcolumn ">
                    <div className="e5wifih-metrics-householdelems">
                        <E5HEquipList
                            indicsysinfo={E5StoreH.Ins().indicsysinfo} langinfo={E5Store.Ins().langinfo}
                            selectedinfo={E5StoreH.Ins().selectedinfo} showbox hidescoreimg={true}
                            equipinfo={E5StoreH.Ins().equipinfo} minimal />
                    </div>
                </div>
                <div className="e5linefull e5column-20">
                    <div className="e5h-metric-sys-equip e5column-20">
                        <E5HMetricIndic langinfo={E5Store.Ins().langinfo}
                            elemtype={type} elemname={equip.imei}
                            health={type === node ? equip.health : undefined} mode="process" >
                            <FormControl className={"filterbloc"} sx={{ m: 1, width: 250 }}>
                                <InputLabel id="name-label">Equipment</InputLabel>
                                <Select labelId="name-label" onChange={handleChange} value={E5StoreH.Ins().selectedinfo.equip.imei} input={<OutlinedInput label="Equipment" />}>
                                    <MenuItem value={''}>
                                        Select equipment
                                    </MenuItem>
                                    {E5StoreH.Ins().equipinfo.equips.map((equip) => {
                                        const equipmentType = equip.nodetype;
                                        let img = <div></div>
                                        if (equipmentType === 'ext') {
                                            img = <img src='./img/v3/metrics/extender.svg' className='e5wifih-metrics-nodes-select-svg' alt="Extender" />
                                        } else {
                                            img = <img src='./img/v3/metrics/gateway.svg' className='e5wifih-metrics-nodes-select-svg' alt="Gateway" />
                                        }
                                        return <MenuItem value={equip.imei} key={equip.imei}>
                                            <div className='e5wifih-metrics-nodes-select-wrapper'>
                                                <div style={{ width: '30px', display: 'flex', justifyContent: "center" }}>
                                                    {img}
                                                </div>
                                                {equip.imei}
                                            </div>
                                        </MenuItem>

                                    }
                                    )}
                                </Select>
                            </FormControl>
                        </E5HMetricIndic>
                        <div className="e5column-20 e5metrics-scrollable">
                            <div className="e5compo">
                                <p className="e5h-metric-sys-equip-title">{_("wifih-metrics-chart-title")}</p>
                                {/* <div>{_("wifih-metrics-chartsubtitle")}</div> */}
                                <div className="e5wifih-metrics-leftright e5line-5">
                                    <div>
                                        <div>{E5UtilI18n._("wifih-metrics-leftsource")} :</div>
                                        <BP.HTMLSelect className={'process-select'} id="leftsource" value={selectedOption} onChange={
                                            evt => setSelectedOption(evt.target.value)} children={options.map((opt, idx) => (<option value={opt.name} key={idx} id={"" + idx}>
                                                {E5UtilI18n._("h-metric-sys-field-" + opt.name)}{' '}[{opt.unit}]
                                            </option>))} />
                                    </div>
                                    <div className="e5linefull" />
                                </div>
                                <E5MetricChart loading={metricinfo.loading} height={200} xoptions={{ xtimezone: E5Time.h_tzoffset }}
                                    leftsource={xydata.left[0]} rightsource={xydata.right[0]} additionalsource={xydata.additional[0]} 
                                    isSingleSelected={true} withNewComponent chartOption={{ withoutSymbol: true }} scrollableLegend={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
})