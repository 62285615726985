import {E5BandEnum} from "../E5Enums";
import {E5Entity} from "../E5Entity";

//E5
export class E5EntHWifiTime extends E5Entity {
	band: E5BandEnum = E5BandEnum.none;
	standard: string = "";
	bandwidth: string = "";
	duration: number = 0;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.band = Object.values(E5BandEnum).includes(json.band) ? json.band : E5BandEnum.none;

		this.standard = json.operating_standard;
		if (this.standard === "null" || this.standard === undefined || this.standard === null
			|| this.standard === "unspecified") this.standard = "";

		this.bandwidth = json.bandwidth;
		if (this.bandwidth === "null" || this.bandwidth === undefined || this.bandwidth === null
			|| this.bandwidth === "unspecified") this.bandwidth = "";

		this.duration = E5Entity.ParseInt(json.duration);
	}

	//E5
	CopyToJson(): any {
		return {band: this.band, operating_standard: this.standard, bandwidth: this.bandwidth, duration: this.duration};
	}
}
