import {E5EntMetaLangs} from "./E5EntMetaLangs";
import {E5Entity} from "../E5Entity";

//E5
export class E5EntMetaFilter extends E5Entity {
	keyname: string = "";
	langs: E5EntMetaLangs = new E5EntMetaLangs();
	operators: string[] = [];

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.keyname = E5Entity.AssignString(json.keyname);
		this.langs = new E5EntMetaLangs(json.languages);

		let idx: number;
		if (Array.isArray(json.operators)) for (idx = 0; idx < json.operators.length; idx++)
			this.operators.push(E5Entity.AssignString(json.operators[idx].operator));
	}

	//E5
	CopyToJson(): any {
		let operators: any[] = [], idx: number;
		for (idx = 0; idx < this.operators.length; idx++) operators.push({operator: this.operators[idx]});

		return {keyname: this.keyname, languages: this.langs.CopyToJson(), operators};
	}
}
