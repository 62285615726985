import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5AdminUsersAction, E5AdminUserSaveReceive, E5AdminUsersList } from "../admin/user/E5AdminUsersList";
import { E5UtilI18n } from "../../global/E5MainLang";
import { E5EntUser } from "../../entity/E5EntUser";
import { E5Store } from "../../store/E5Store";
//
import "./E5TestAdminUsers.css";

//E5
interface E5TestAdminUsersState {
	users: E5EntUser[];
}

//E5
interface E5TestAdminUsersProps { }

//E5
export const E5TestAdminUsers = observer(class E5TestAdminUsers extends React.PureComponent
	<E5TestAdminUsersProps, E5TestAdminUsersState> {

	// ---------------- MEMBERS ----------------

	//E5
	json1: any[] = [];
	json2: any[] = [];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestAdminUsersProps, state: E5TestAdminUsersState) {
		super(props, state);
		this.state = { users: [] };
		this.json1 = [
			{
				lastName: "Barker", firstName: "Farrah", username: "fbarker", email: "fbarker@sagecmcom.com",
				company: "Sagemcom", jobTitle: "Developer", phoneNumber: "0705497390", credentialsNonExpired: true,
				roles: [{ name: "ROLE_ADMIN" }, { name: "ROLE_MANAGER" }, { name: "ROLE_L1" }], accountNonLocked: true,
				lastSuccessfulLogin: 0, accountExpirationDate: 0, enabled: true, accountNonExpired: true
			},
			{
				lastName: "Barron", firstName: "Troy", username: "tbarron", email: "fbarker@sagecmcom.com",
				company: "5team", jobTitle: "Administrator", phoneNumber: "0705497390", credentialsNonExpired: true,
				roles: [{ name: "ROLE_ADMIN" }, { name: "ROLE_MANAGER" }, { name: "ROLE_L1" }], accountNonLocked: true,
				lastSuccessfulLogin: 1588391200, accountExpirationDate: 1588391200, enabled: true,
				accountNonExpired: true
			},
			{
				lastName: "CCC", firstName: "ccc", username: "ccc", email: "cc@c.com", company: "C3",
				jobTitle: "Primary", phoneNumber: "0705497390", roles: [{ name: "ROLE_L2" }], credentialsNonExpired: true,
				lastSuccessfulLogin: 1588291200, accountExpirationDate: 1588291200, enabled: true,
				accountNonExpired: true, accountNonLocked: true
			}
		];
		this.json2 = [
			{
				lastName: "Bass", firstName: "Maxine", username: "mbass", email: "fbarker@sagecmcom.com",
				company: "Sagemcom", jobTitle: "Developer", phoneNumber: "0705497390", credentialsNonExpired: true,
				roles: [{ name: "ROLE_ADMIN" }, { name: "ROLE_MANAGER" }, { name: "ROLE_L1" }], accountNonLocked: true,
				lastSuccessfulLogin: 0, accountExpirationDate: 0, enabled: true, accountNonExpired: true
			},
			{
				lastName: "Bell", firstName: "Brittany", username: "bbell", email: "fbarker@sagecmcom.com",
				company: "Sagemcom", jobTitle: "Developer", phoneNumber: "0705497390", credentialsNonExpired: true,
				roles: [{ name: "ROLE_ADMIN" }, { name: "ROLE_MANAGER" }, { name: "ROLE_L1" }], accountNonLocked: true,
				lastSuccessfulLogin: 0, accountExpirationDate: 0, enabled: true, accountNonExpired: true
			}
		];
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		let userinfo = { status: { loading: false, success: true, message: "" }, users: this.state.users };
		let usergetinfo = { status: { loading: false, success: true, message: "" }, user: undefined };
		//
		return <div className="e5testpage e5test-admin-users e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-admin-users-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Test list 1">
					<BP.Button onClick={() => { this.ChangeList(this.json1) }} icon="refresh" />
				</BP.Callout>
				<BP.Callout title="Test list 2">
					<BP.Button onClick={() => { this.ChangeList(this.json2) }} icon="refresh" />
				</BP.Callout>
			</div>
			<div className="e5admin-users-content e5columnfull">
				<E5AdminUsersList
					langinfo={E5Store.Ins().langinfo}
					userinfo={userinfo}
					usergetinfo={usergetinfo}
					savefunc={this.SaveUser}
				/>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	ChangeList = (json: any) => {
		let dataset: E5EntUser[] = [], idx: number;
		for (idx = 0; idx < json.length; idx++)
			dataset.push(new E5EntUser(json[idx]));
		this.setState({ users: dataset });
	};

	//E5
	SaveUser = (action: E5AdminUsersAction, user: E5EntUser, username: string,
		userlistcb: E5AdminUserSaveReceive) => {
		console.log('SaveUser')
		let users: E5EntUser[] = [], idx: number;
		for (idx = 0; idx < this.state.users.length; idx++) {
			let curuser: E5EntUser = this.state.users[idx];
			if (action === E5AdminUsersAction.update) {
				if (curuser.username === username)
					users.push(user);
				else
					users.push(curuser);
			} else if (action === E5AdminUsersAction.delete) {
				if (curuser.username !== username)
					users.push(curuser);
			} else if (action === E5AdminUsersAction.add) {
				users.push(curuser);
			}
		}
		if (action === E5AdminUsersAction.add)
			users.push(user);
		//
		this.setState({ users: users }, () => userlistcb(true));
	};
});
