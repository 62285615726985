import { E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender } from "../util/E5RestSender";
import { E5EntCBSysOverMet } from "../entity/customer_base/E5EntCBSysOverMet";
import { E5RequestCallback, E5RequestStatus } from "./E5ServiceCommon";
import { E5MainConfig } from "../global/E5MainConfig";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5CBHealthClass, E5StoreCB } from "../store/E5StoreCB";
import { E5Storage } from "../util/E5Storage";
import { E5Entity } from "../entity/E5Entity";
import { E5RequestCB } from "./E5RequestCB";
import { E5Request } from "./E5Request";
import {
	CPUExGtClass,
	E5CBSysClass, E5CBSysClassDetails, E5CBSysMet, E5CBSysMetType, E5CBSysProcMet, E5StoreCBSys
} from "../store/E5StoreCBSys";

//E5
enum E5RequestCBSysTags {
	OVERVIEW = "overview", CPU = "cpu", MEMORY = "memory", REBOOT = "reboot", TEMPERATURE = "temperature",
	PROCESS = "process", FLASH = "flash", NILIST = "nilist"
}

//E5
type E5EntCBSysMetrics = E5EntCBSysOverMet | E5CBSysMet | E5CBSysProcMet;

//E5
export class E5RequestCBSys implements E5RestResponder {

	// --------- STATIC -------------

	//E5
	private static ins: E5RequestCBSys;

	//E5
	static Ins(): E5RequestCBSys {
		if (E5RequestCBSys.ins === undefined) E5RequestCBSys.ins = new E5RequestCBSys();
		return E5RequestCBSys.ins;
	}

	// --------- INSTANCE -------------

	//E5
	private sender: E5RestSender;
	private tokenmap: Map<string, number>;

	//E5
	private constructor() {
		this.sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v1");
		this.tokenmap = new Map();
	}

	//E5
	RequestCallback(resp: E5RestResponse): void {
		let { OVERVIEW, CPU, MEMORY, REBOOT, TEMPERATURE, PROCESS, FLASH, NILIST } = E5RequestCBSysTags;
		E5RequestCB.Ins().ClearMessageStatus();
		E5RequestCBSys.Ins().ClearMessageStatus();
		if (resp.request.otherdata.requesttag === OVERVIEW) E5RequestCBSys.OverviewCB(resp);
		else if (resp.request.otherdata.requesttag === CPU) E5RequestCBSys.CpuCB(resp);
		else if (resp.request.otherdata.requesttag === MEMORY) E5RequestCBSys.MemoryCB(resp);
		else if (resp.request.otherdata.requesttag === REBOOT) E5RequestCBSys.RebootCB(resp);
		else if (resp.request.otherdata.requesttag === TEMPERATURE) E5RequestCBSys.TemperatureCB(resp);
		else if (resp.request.otherdata.requesttag === PROCESS) E5RequestCBSys.ProcessCB(resp);
		else if (resp.request.otherdata.requesttag === FLASH) E5RequestCBSys.FlashCB(resp);
		else if (resp.request.otherdata.requesttag === NILIST) E5RequestCBSys.NiListCB(resp);
	}

	//E5
	private CancelTag = (tag: E5RequestCBSysTags): void => this.sender.Cancel(this.tokenmap.get(tag));

	//E5
	ClearAll(): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" }, udf: undefined = undefined;
		E5StoreCBSys.Ins().SetOverview({ ...status }, udf, udf, udf, udf, udf, udf, udf, udf, udf, []);
		E5StoreCBSys.Ins().SetData("cpu", { ...status }, udf, udf, udf, [], [], []);
		E5StoreCBSys.Ins().SetData("memory", { ...status }, udf, udf, udf, [], [], []);
		E5StoreCBSys.Ins().SetReboot({ ...status }, udf, udf, udf, udf, [], udf, udf, []);
		E5StoreCBSys.Ins().SetData("temperature", { ...status }, udf, udf, udf, [], [], []);
		E5StoreCBSys.Ins().SetProcess({ ...status }, udf, udf, udf, udf, [], [], [], []);
		E5StoreCBSys.Ins().SetFlash({ ...status }, udf, udf, udf, [], udf, udf, []);

		E5RequestCBSys.Ins().CancelTag(E5RequestCBSysTags.OVERVIEW);
		E5RequestCBSys.Ins().CancelTag(E5RequestCBSysTags.CPU);
		E5RequestCBSys.Ins().CancelTag(E5RequestCBSysTags.MEMORY);
		E5RequestCBSys.Ins().CancelTag(E5RequestCBSysTags.REBOOT);
		E5RequestCBSys.Ins().CancelTag(E5RequestCBSysTags.TEMPERATURE);
		E5RequestCBSys.Ins().CancelTag(E5RequestCBSysTags.PROCESS);
		E5RequestCBSys.Ins().CancelTag(E5RequestCBSysTags.FLASH);
		E5RequestCBSys.Ins().CancelTag(E5RequestCBSysTags.NILIST);
	}

	ClearMessageStatus(): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" }
		E5StoreCBSys.Ins().overview.status = status;
		E5StoreCBSys.Ins().cpu.status = status;
		E5StoreCBSys.Ins().memory.status = status;
		E5StoreCBSys.Ins().reboot.status = status;
		E5StoreCBSys.Ins().temperature.status = status;
		E5StoreCBSys.Ins().process.status = status;
		E5StoreCBSys.Ins().flash.status = status;
	}
	// --------- Common -------------

	//E5
	private Fetch(uri: string, requesttag: E5RequestCBSysTags, callback: E5RequestCallback): void {
		this.sender.Cancel(this.tokenmap.get(requesttag));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer), req: E5RestRequest = {
			method: "GET", header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			uri, body: undefined, otherdata: { requesttag, callback }
		};
		this.tokenmap.set(requesttag, this.sender.Send(req));
	}

	// --------- Overview -------------

	//E5
	static FetchOverview(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = { loading: true, success: false, message: "" }, udf: undefined = undefined;
		E5StoreCBSys.Ins().SetOverview(status, udf, udf, udf, udf, udf, udf, udf, udf, udf, []);
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/global/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBSysTags.OVERVIEW, callback);
	}

	//E5
	private static OverviewCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		E5RequestCBSys.HandleStatusCode(resp, status);
		try {
			let udf: undefined = undefined,
				health: number | null | undefined = udf, cpuhealth: number | null | undefined = udf,
				memhealth: number | null | undefined = udf, rebhealth: number | null | undefined = udf,
				temphealth: number | null | undefined = udf, prochealth: number | null | undefined = udf,
				flashhealth: number | null | undefined = udf, healthmetmap: Map<string, Map<number, E5CBHealthClass>> | null | undefined = udf,
				inccritdist: Map<string, number> | null | undefined = udf, metrics: E5EntCBSysOverMet[] = [];
			if (status.success && typeof resp.body === "object" && resp.body !== null) {
				if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
					if (Array.isArray(resp.body.kpi.system_criticality_distribution))
						inccritdist = resp.body.kpi.system_criticality_distribution.reduce(
							(acc: Map<string, number>, cur: any) =>
								acc.set(E5Entity.AssignString(cur.incident_type), E5Entity.ParseFloat(cur.criticality)),
							new Map());
					else inccritdist = null;
					if (typeof resp.body.kpi.healths === "object" && resp.body.kpi.healths !== null) {
						health = E5Entity.ParseFloatNull(resp.body.kpi.healths.system_health);
						cpuhealth = E5Entity.ParseFloatNull(resp.body.kpi.healths.cpu_health);
						memhealth = E5Entity.ParseFloatNull(resp.body.kpi.healths.memory_health);
						rebhealth = E5Entity.ParseFloatNull(resp.body.kpi.healths.reboot_health);
						temphealth = E5Entity.ParseFloatNull(resp.body.kpi.healths.temperature_health);
						prochealth = E5Entity.ParseFloatNull(resp.body.kpi.healths.process_health);
						flashhealth = E5Entity.ParseFloatNull(resp.body.kpi.healths.flash_memory_health);
					} else health = cpuhealth = memhealth = rebhealth = temphealth = prochealth = flashhealth = null;
				} else health = cpuhealth = memhealth = rebhealth = temphealth = prochealth = flashhealth =
					inccritdist = null;
				if (Array.isArray(resp.body.metrics)) {
					healthmetmap = E5RequestCB.ParseHealthMetrics(resp.body.metrics.map(metric => {
						return {
							date: metric.date,
							classes: metric.system_health_overview
						}
					}));
					metrics = resp.body.metrics.map((met: any) => new E5EntCBSysOverMet(met));
				}
			}
			metrics.sort(E5RequestCBSys.CompareMetric);
			E5StoreCBSys.Ins().SetOverview(status, health, cpuhealth, memhealth, rebhealth, temphealth, prochealth,
				flashhealth, inccritdist, metrics, healthmetmap);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Cpu -------------

	//E5
	static FetchCpu(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = { loading: true, success: false, message: "" }, udf: undefined = undefined;
		E5StoreCBSys.Ins().SetData("cpu", status, udf, udf, udf, [], [], []);
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/cpu/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBSysTags.CPU, callback);
	}

	//E5
	private static CpuCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		E5RequestCBSys.HandleStatusCode(resp, status);
		try {
			let udf: undefined = undefined,
				health: number | null | undefined = udf, equips: E5CBSysClass[] | null | undefined = udf,
				details: E5CBSysClassDetails[] | null | undefined = udf, metrics: E5CBSysMet[] = [],
				detailsPerExtender: E5CBSysClass[] | null | undefined, detailsPerGateway: E5CBSysClass[] | null | undefined;
			if (status.success && typeof resp.body === "object" && resp.body !== null) {
				if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
					health = E5Entity.ParseFloatNull(resp.body.kpi.cpu_health);
					equips = E5RequestCBSys.ParseEquips(resp.body.kpi.cpu_per_node_type);
					details = E5RequestCBSys.ParseDetails(resp.body.kpi.cpu_per_firmware_model);
					detailsPerExtender = E5RequestCBSys.ParseExtenderAndGateway(resp.body.kpi.cpu_per_firmware_model_extender);
					detailsPerGateway = E5RequestCBSys.ParseExtenderAndGateway(resp.body.kpi.cpu_per_firmware_model_gateway);
				} else health = equips = details = detailsPerExtender = detailsPerGateway = null;
				if (Array.isArray(resp.body.metrics))
					metrics = resp.body.metrics.map((met: any) => E5RequestCBSys.ParseMetric("cpu", met));
			}
			metrics.sort(E5RequestCBSys.CompareMetric);

			E5StoreCBSys.Ins().SetData("cpu", status, health, equips, details, metrics, detailsPerExtender, detailsPerGateway);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Memory -------------

	//E5
	static FetchMemory(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = { loading: true, success: false, message: "" }, udf: undefined = undefined;
		E5StoreCBSys.Ins().SetData("memory", status, udf, udf, udf, [], [], []);
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/memory/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBSysTags.MEMORY, callback);
	}

	//E5
	private static MemoryCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		E5RequestCBSys.HandleStatusCode(resp, status);
		try {
			let udf: undefined = undefined,
				health: number | null | undefined = udf, equips: E5CBSysClass[] | null | undefined = udf,
				details: E5CBSysClassDetails[] | null | undefined = udf, metrics: E5CBSysMet[] = [];
			if (status.success && typeof resp.body === "object" && resp.body !== null) {
				if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
					health = E5Entity.ParseFloatNull(resp.body.kpi.memory_health);
					equips = E5RequestCBSys.ParseEquips(resp.body.kpi.memory_per_node_type);
					details = E5RequestCBSys.ParseDetails(resp.body.kpi.memory_per_firmware_model);
				} else health = equips = details = null;
				if (Array.isArray(resp.body.metrics))
					metrics = resp.body.metrics.map((met: any) => E5RequestCBSys.ParseMetric("memory", met));
			}
			metrics.sort(E5RequestCBSys.CompareMetric);
			E5StoreCBSys.Ins().SetData("memory", status, health, equips, details, metrics, [], []);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Reboot -------------

	//E5
	static FetchReboot(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = { loading: true, success: false, message: "" }, udf: undefined = undefined;
		E5StoreCBSys.Ins().SetReboot(status, udf, udf, udf, udf, [], udf, udf, []);
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/reboot/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBSysTags.REBOOT, callback);
	}

	//E5
	private static RebootCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		E5RequestCBSys.HandleStatusCode(resp, status);
		try {
			let udf: undefined = undefined, health: number | null | undefined = udf,
				reasonmap: Map<string, number> | null | undefined = udf, rebequips: E5CBSysClass[] | null | undefined = udf,
				rebdetails: E5CBSysClassDetails[] | null | undefined = udf, rebmetrics: E5CBSysMet[] = [],
				uptequips: E5CBSysClass[] | null | undefined = udf, uptdetails: E5CBSysClassDetails[] | null | undefined = udf,
				uptmetrics: E5CBSysMet[] = [];
			if (status.success && typeof resp.body === "object" && resp.body !== null) {
				if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
					health = E5Entity.ParseFloatNull(resp.body.kpi.reboot.reboot_health);
					if (Array.isArray(resp.body.kpi.reboot.reboot_reason)) {
						reasonmap = new Map();
						for (let obj of resp.body.kpi.reboot.reboot_reason)
							reasonmap.set(E5RequestCBSys.KeyOrNA(obj.reason), E5Entity.ParseInt(obj.count));
					} else reasonmap = null;
					rebequips = E5RequestCBSys.ParseEquips(resp.body.kpi.reboot.reboot_per_node_type);
					rebdetails = E5RequestCBSys.ParseDetails(resp.body.kpi.reboot.reboot_per_node_model);
					uptequips = E5RequestCBSys.ParseEquips(resp.body.kpi.uptime.uptime_per_node_type);
					uptdetails = E5RequestCBSys.ParseDetails(resp.body.kpi.uptime.uptime_per_node_model);
				} else health = reasonmap = rebequips = rebdetails = uptequips = uptdetails = null;
				if (typeof resp.body.metrics === "object" && resp.body.metrics !== null) {
					if (Array.isArray(resp.body.metrics.reboot))
						rebmetrics = resp.body.metrics.reboot.map((met: any) =>
							E5RequestCBSys.ParseMetric("reboot", met));
					if (Array.isArray(resp.body.metrics.uptime))
						uptmetrics = resp.body.metrics.uptime.map((met: any) =>
							E5RequestCBSys.ParseMetric("uptime", met));
				}
			}
			rebmetrics.sort(E5RequestCBSys.CompareMetric);
			uptmetrics.sort(E5RequestCBSys.CompareMetric);
			E5StoreCBSys.Ins().SetReboot(status, health, reasonmap, rebequips, rebdetails, rebmetrics, uptequips,
				uptdetails, uptmetrics);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Temperature -------------

	//E5
	static FetchTemperature(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = { loading: true, success: false, message: "" }, udf: undefined = undefined;
		E5StoreCBSys.Ins().SetData("temperature", status, udf, udf, udf, [], [], []);
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/temperature/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBSysTags.TEMPERATURE, callback);
	}

	//E5
	private static TemperatureCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		E5RequestCBSys.HandleStatusCode(resp, status);
		try {
			let udf: undefined = undefined,
				health: number | null | undefined = udf, equips: E5CBSysClass[] | null | undefined = udf,
				details: E5CBSysClassDetails[] | null | undefined = udf, metrics: E5CBSysMet[] = [];
			if (status.success && typeof resp.body === "object" && resp.body !== null) {
				if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
					health = E5Entity.ParseFloatNull(resp.body.kpi.temperature_health);
					equips = E5RequestCBSys.ParseEquips(resp.body.kpi.temperature_per_node_type);
					details = E5RequestCBSys.ParseDetails(resp.body.kpi.temperature_per_firmware_model);
				} else health = equips = details = null;
				if (Array.isArray(resp.body.metrics))
					metrics = resp.body.metrics.map((met: any) => E5RequestCBSys.ParseMetric("temperature", met));
			}
			metrics.sort(E5RequestCBSys.CompareMetric);
			E5StoreCBSys.Ins().SetData("temperature", status, health, equips, details, metrics, [], []);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Process -------------

	//E5
	static FetchProcess(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = { loading: true, success: false, message: "" }, udf: undefined = undefined;
		E5StoreCBSys.Ins().SetProcess(status, udf, udf, udf, udf, [], [], [], []);
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/process/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBSysTags.PROCESS, callback);
	}

	//E5
	private static ProcessCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		E5RequestCBSys.HandleStatusCode(resp, status);
		try {
			let udf: undefined = undefined,
				health: number | null | undefined = udf,
				cpuprocmap: Map<string, number> | null | undefined = udf,
				crashprocmap: Map<string, number> | null | undefined = udf,
				memprocmap: Map<string, number> | null | undefined = udf,
				cpuprocmetrics: E5CBSysProcMet[] = [],
				crashprocmetrics: E5CBSysProcMet[] = [],
				memprocmetrics: E5CBSysProcMet[] = [],
				procstatmetrics: E5CBSysProcMet[] = [];
			if (status.success && typeof resp.body === "object" && resp.body !== null) {
				if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
					health = E5Entity.ParseFloatNull(resp.body.kpi.process_health);
					if (Array.isArray(resp.body.kpi.cpu_process_usage)) {
						cpuprocmap = new Map();
						for (let obj of resp.body.kpi.cpu_process_usage)
							cpuprocmap.set(E5Entity.AssignString(obj.name), E5Entity.ParseFloat(obj.usage));
					} else cpuprocmap = null;
					if (Array.isArray(resp.body.kpi.memory_process_usage)) {
						memprocmap = new Map();
						for (let obj of resp.body.kpi.memory_process_usage)
							memprocmap.set(E5Entity.AssignString(obj.name), E5Entity.ParseFloat(obj.usage));
					} else memprocmap = null;
					if (Array.isArray(resp.body.kpi.crash_process_usage)) {
						crashprocmap = new Map();
						for (let obj of resp.body.kpi.crash_process_usage)
							crashprocmap.set(E5Entity.AssignString(obj.name), E5Entity.ParseFloat(obj.usage));
					} else crashprocmap = null;
				} else health = cpuprocmap = crashprocmap = memprocmap = null;
				if (typeof resp.body.metrics === "object" && resp.body.metrics !== null) {
					if (Array.isArray(resp.body.metrics.cpu_process_usage))
						cpuprocmetrics = resp.body.metrics.cpu_process_usage.map((met: any) =>
							E5RequestCBSys.ParseProcMetric("usage", met));
					if (Array.isArray(resp.body.metrics.memory_process_usage))
						memprocmetrics = resp.body.metrics.memory_process_usage.map((met: any) =>
							E5RequestCBSys.ParseProcMetric("usage", met));
					if (Array.isArray(resp.body.metrics.crash_process_usage))
						crashprocmetrics = resp.body.metrics.crash_process_usage.map((met: any) =>
							E5RequestCBSys.ParseProcMetric("usage", met));
					if (Array.isArray(resp.body.metrics.process_status_frequency))
						procstatmetrics = resp.body.metrics.process_status_frequency.map((met: any) =>
							E5RequestCBSys.ParseProcMetric("frequency_status", met));
				}
				cpuprocmetrics.sort(E5RequestCBSys.CompareMetric);
				crashprocmetrics.sort(E5RequestCBSys.CompareMetric);
				memprocmetrics.sort(E5RequestCBSys.CompareMetric);
				procstatmetrics.sort(E5RequestCBSys.CompareMetric);
				E5StoreCBSys.Ins().SetProcess(status, health, cpuprocmap, crashprocmap, memprocmap, cpuprocmetrics, memprocmetrics, crashprocmetrics,
					procstatmetrics);
			}
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Flash -------------

	//E5
	static FetchFlash(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = { loading: true, success: false, message: "" }, udf: undefined = undefined;
		E5StoreCBSys.Ins().SetFlash(status, udf, udf, udf, [], udf, udf, []);
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/flash/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBSysTags.FLASH, callback);
	}

	//E5
	private static FlashCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		E5RequestCBSys.HandleStatusCode(resp, status);
		try {
			let udf: undefined = undefined, health: number | null | undefined = udf,
				flusaequips: E5CBSysClass[] | null | undefined = udf,
				flusadetails: E5CBSysClassDetails[] | null | undefined = udf, flusametrics: E5CBSysMet[] = [],
				flcorequips: E5CBSysClass[] | null | undefined = udf,
				flcordetails: E5CBSysClassDetails[] | null | undefined = udf, flcormetrics: E5CBSysMet[] = [];
			if (status.success && typeof resp.body === "object" && resp.body !== null) {
				if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
					health = E5Entity.ParseFloatNull(resp.body.kpi.flash_memory_health);
					flusaequips = E5RequestCBSys.ParseEquips(resp.body.kpi.flash_memory_usage_per_node_type);
					flusadetails = E5RequestCBSys.ParseDetails(resp.body.kpi.flash_memory_usage_per_firmware_model);
					flcorequips = E5RequestCBSys.ParseEquips(resp.body.kpi.flash_memory_corruption_per_node_type);
					flcordetails = E5RequestCBSys.ParseDetails(resp.body.kpi.flash_memory_corruption_per_firmware_model);
				} else health = flusaequips = flusadetails = flcorequips = flcordetails = null;
				if (Array.isArray(resp.body.metrics)) {
					flusametrics = resp.body.metrics.map((met: any) =>
						E5RequestCBSys.ParseMetric("flash_memory_usage", met));
					flcormetrics = resp.body.metrics.map((met: any) =>
						E5RequestCBSys.ParseMetric("flash_memory_corruption", met));
				}
			}
			flusametrics.sort(E5RequestCBSys.CompareMetric);
			flcormetrics.sort(E5RequestCBSys.CompareMetric);
			E5StoreCBSys.Ins().SetFlash(status, health, flusaequips, flusadetails, flusametrics, flcorequips,
				flcordetails, flcormetrics);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- NiList -------------

	//E5
	static FetchNiListIncid(popid: string, inckey: string, startdate: string, enddate: string,
		callback: E5RequestCallback): void {
		E5StoreCB.Ins().SetNiList({ loading: true, success: false, message: "" }, []);
		E5StoreCB.Ins().SetNiType("none");
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/incident/" + inckey +
			"/bystartdate/" + startdate + "/byenddate/" + enddate, E5RequestCBSysTags.NILIST, callback);
	}

	//E5
	static FetchNiListHealth(popid: string, cbtab: string, cla: string, date: string,
		callback: E5RequestCallback): void {
		E5StoreCB.Ins().SetNiList({ loading: true, success: false, message: "" }, []);
		E5StoreCB.Ins().SetNiType("none");
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/health/" + cbtab +
			"/category/" + cla + "/bydate/" + date, E5RequestCBSysTags.NILIST, callback);
	}
	static FetchNiListProcess(popid: string, cbtab: string, cla: string, date: string,
		callback: E5RequestCallback): void {
		E5StoreCB.Ins().SetNiList({ loading: true, success: false, message: "" }, []);
		E5StoreCB.Ins().SetNiListNodes([]);
		E5StoreCB.Ins().SetNiListUsages([]);
		E5StoreCB.Ins().SetNiType(cbtab);
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/process/" + cbtab +
			"/processName/" + cla + "/bydate/" + date, E5RequestCBSysTags.NILIST, callback);
	}

	//E5
	static FetchNiListEquip(popid: string, systype: string, eqptype: string, cla: string, startdate: string,
		enddate: string, callback: E5RequestCallback): void {
		E5StoreCB.Ins().SetNiList({ loading: true, success: false, message: "" }, []);
		E5StoreCB.Ins().SetNiType("none");
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/" + systype + "/equipment/" +
			eqptype.toLowerCase() + "/category/" + cla + "/bystartdate/" + startdate + "/byenddate/" + enddate,
			E5RequestCBSysTags.NILIST, callback);
	}

	//E5
	static FetchNiListDetail(popid: string, systype: string, model: string, sofv: string, cla: string,
		startdate: string, enddate: string, callback: E5RequestCallback): void {
		E5StoreCB.Ins().SetNiList({ loading: true, success: false, message: "" }, []);
		E5StoreCB.Ins().SetNiType("none");
		E5RequestCBSys.Ins().Fetch("/populationdata/" + popid + "/system/" + systype + "/model/" + model +
			"/firmware/" + sofv + "/category/" + cla + "/bystartdate/" + startdate + "/byenddate/" + enddate,
			E5RequestCBSysTags.NILIST, callback);
	}

	//E5
	private static NiListCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		E5RequestCBSys.HandleStatusCode(resp, status);
		try {
			let ids: string[] = [], nodes: string[] = [], usages: string[] = [], id: string, node: string, usage: string;
			if (status.success && typeof resp.body === "object" && resp.body !== null)
				if (Array.isArray(resp.body.network_ids)) for (id of resp.body.network_ids) ids.push(id);
				if (Array.isArray(resp.body.node_imeis)) for (node of resp.body.node_imeis) nodes.push(node);
				if (Array.isArray(resp.body.network_usages)) for (usage of resp.body.network_usages) usages.push(usage);
			E5StoreCB.Ins().SetNiList(status, ids);
			E5StoreCB.Ins().SetNiListNodes(nodes);
			E5StoreCB.Ins().SetNiListUsages(usages);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// ---------------- UTILS ----------------

	//E5
	static KeyOrNA = (key: any): string => [null, undefined, ""].includes(key) ? "N/A" : E5Entity.AssignString(key);

	//E5
	static HandleStatusCode = (resp: E5RestResponse, status: E5RequestStatus): void => {
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("wificb-dashboard-notfound");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
	};

	//E5
	static ParseEquips = (json: any): E5CBSysClass[] | null => {
		let equips: E5CBSysClass[] | null = null;
		if (Array.isArray(json)) {
			equips = [];
			for (let obj of json) if (typeof obj.class === "object" && obj.class !== null) equips?.push({
				class: E5RequestCBSys.KeyOrNA(obj.class.label), eqptype: E5RequestCBSys.KeyOrNA(obj.node_type),
				rank: E5Entity.ParseInt(obj.class.rank), count: E5Entity.ParseInt(obj.count)
			});
			equips.sort(E5RequestCB.CompareRank);
		}
		return equips;
	};
	static ParseExtenderAndGateway = (json: any): CPUExGtClass[] | null => {
		let extendersOrGateways: CPUExGtClass[] | null = null;
		if (Array.isArray(json)) {
			extendersOrGateways = [];
			for (let obj of json) if (typeof obj.class === "object" && obj.class !== null) extendersOrGateways?.push({
				class: E5RequestCBSys.KeyOrNA(obj.class.label),
				eqptype: E5RequestCBSys.KeyOrNA(obj.node_type),
				rank: E5Entity.ParseInt(obj.class.rank), count: E5Entity.ParseInt(obj.count),
				sofv: obj.software_version,
				model: obj.model_name,
			});
			extendersOrGateways.sort(E5RequestCB.CompareRank);
		}
		return extendersOrGateways;
	};
	//E5
	static ParseDetails = (json: any): E5CBSysClassDetails[] | null => {
		let details: E5CBSysClassDetails[] | null = null;
		if (Array.isArray(json)) {
			details = [];
			for (let obj of json) if (typeof obj.class === "object" && obj.class !== null) details?.push({
				class: E5RequestCBSys.KeyOrNA(obj.class.label),
				model: E5RequestCBSys.KeyOrNA(obj.model_name), sofv: E5RequestCBSys.KeyOrNA(obj.software_version),
				rank: E5Entity.ParseInt(obj.class.rank), count: E5Entity.ParseInt(obj.count)
			});
			details.sort(E5RequestCB.CompareRank);
		}
		return details;
	};

	//E5
	static ParseMetric = (key: E5CBSysMetType | "reboot" | "uptime" | "flash_memory_usage" | "flash_memory_corruption",
		json: any): E5CBSysMet => {
		let gw: E5CBSysClass[] = [], ext: E5CBSysClass[] = [],
			stb: E5CBSysClass[] = [], metjson: any = json["equipment_" + key + "_per_node_type"];
		if (Array.isArray(metjson)) for (let obj of metjson) if (typeof obj.class === "object" && obj.class !== null) {
			let label: string = E5Entity.AssignString(obj.class.label),
				rank: number = E5Entity.ParseInt(obj.class.rank),
				eqptype: string = E5Entity.AssignString(obj.node_type), count: number = E5Entity.ParseInt(obj.count);
			if (obj.node_type === "GW") gw.push({ class: label, rank, eqptype, count });
			else if (obj.node_type === "EXT") ext.push({ class: label, rank, eqptype, count });
			else if (obj.node_type === "STB") stb.push({ class: label, rank, eqptype, count });
		}
		gw.sort(E5RequestCB.CompareRank);
		ext.sort(E5RequestCB.CompareRank);
		stb.sort(E5RequestCB.CompareRank);
		return { date: E5Entity.AssignString(json.date), gw, ext, stb };
	};

	//E5
	static ParseProcMetric = (key: "usage" | "frequency_status", json: any): E5CBSysProcMet => {
		let vals: Map<string, number> = new Map(), metjson: any = json[key];
		if (Array.isArray(metjson)) for (let obj of metjson)
			vals.set(E5Entity.AssignString(key === "usage" ? obj.process_name : obj.status),
				E5Entity.ParseFloat(key === "usage" ? obj.percent_memory_usage : obj.frequency));
		return { date: E5Entity.AssignString(json.date), vals };
	};

	//E5
	static CompareMetric = (m1: E5EntCBSysMetrics, m2: E5EntCBSysMetrics): number => m1.date.localeCompare(m2.date);
}