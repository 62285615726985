import {E5EntMetaLangs} from "./E5EntMetaLangs";
import {E5Entity} from "../E5Entity";

//E5
export class E5EntMetaRecom extends E5Entity {
	recomid: string = "";
	targetid: string = "";
	langs: E5EntMetaLangs = new E5EntMetaLangs();

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.recomid = E5Entity.AssignString(json.recommendation_id);

		this.targetid = "";
		if (typeof json.recommendation_target === "object" && json.recommendation_target !== null)
			this.targetid = E5Entity.AssignString(json.recommendation_target.id);

		this.langs = new E5EntMetaLangs(json.languages);
	}

	//E5
	CopyToJson(): any {
		return {
			recommendation_id: this.recomid, recommendation_target: {id: this.targetid},
			languages: this.langs.CopyToJson()
		};
	}
}
