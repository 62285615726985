import React from "react";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import * as BP from "@blueprintjs/core";
import * as BPD from "@blueprintjs/datetime";
import MomentLocaleUtils from "react-day-picker/moment";
//
import { E5EntAclGroup } from "../../../entity/E5EntAclGroup";
import { E5StoreLangInfo } from "../../../store/E5Store";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5StoreACL } from "../../../store/E5StoreACL";
import { E5EntUser } from "../../../entity/E5EntUser";
//
import "./E5AdminUsersPopup.css";
//E5
interface E5AdminUsersPopupState {
	stateuser: E5EntUser;
	stateusername: string;
	role: string;
}

//E5
export type E5AdminUserUpdateCallback = ((user: E5EntUser) => void);

//E5
interface E5AdminUsersPopupProps {
	langinfo: E5StoreLangInfo;
	user: E5EntUser;
	updatefunc: E5AdminUserUpdateCallback;
}

//E5
export const E5AdminUsersPopup = observer(class E5AdminUsersPopup extends React.PureComponent
	<E5AdminUsersPopupProps, E5AdminUsersPopupState> {

	// ---------------- MEMBERS ----------------

	//E5
	usernameinput: HTMLInputElement | undefined;
	usernameinputref = (ref: any) => { this.usernameinput = ref };

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5AdminUsersPopupProps, state: E5AdminUsersPopupState) {
		super(props, state);
		let stateuser: E5EntUser = new E5EntUser();
		stateuser.Copy(this.props.user);
		if (stateuser.username === "") {
			stateuser.enabled = true;
			stateuser.accountnonexpired = true;
			stateuser.credentialsnonexpired = true;
			stateuser.accountnonlocked = true;
		}
		this.state = { stateuser, stateusername: stateuser.username, role: 'ROLE_ADMIN' };
	}

	//E5
	componentDidMount(): void {
		this.usernameinput?.focus();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let redstarjsx: JSX.Element = <span style={{ color: "#FF0000" }}>*</span>, roleslistpopup: string[] =
			["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_RDMANAGER", "ROLE_ANALYST", "ROLE_L1", "ROLE_L2", "ROLE_L3"];

		let roleslist: any = {};
		for (let role of roleslistpopup) roleslist[role] = false;
		for (let role of this.state.stateuser.roles) roleslist[role] = true;
		//generate roles components
		let rolesjsx: JSX.Element[] = [];
		// for (let role of roleslistpopup) rolesjsx.push(<BP.Radio
		// 	className="e5admin-users-popup-form-checkbox" value={role}

		// 	label={E5UtilI18n._("admin-users-role-" + role + "_long")}
		// />);

		let expdate: Date | undefined = this.state.stateuser.accountexpirationdate?.toDate(),
			logindate: Date | undefined = this.state.stateuser.lastsuccessfullogin?.toDate();
		let passcomment: string = this.props.user.username !== "" ?
			E5UtilI18n._("admin-users-password-comment") : "";

		let acljsx: JSX.Element[] = [];
		for (let { name } of E5StoreACL.Ins().aclinfo.groups)
			acljsx.push(<BP.Checkbox key={name} label={name} value={name} onChange={this.ToggleAclGroup}
				checked={this.state.stateuser.aclgroups.some(group => group.name === name)} />);
		for (let role of roleslistpopup) rolesjsx.push(<BP.Checkbox
			className="e5admin-users-popup-form-checkbox" checked={roleslist[role]} key={role}
			onChange={(event: any) => { this.ChangeRole(event, role, roleslist) }}
			label={E5UtilI18n._("admin-users-role-" + role + "_long")}
		/>);

		return <div className="e5admin-users-popup e5column-5">

			<div className="e5line-10">
				<head>
					<meta charset="utf-8" />
				</head>
				<div className="e5linefull">
					<BP.FormGroup
						labelFor="text-input" labelInfo={redstarjsx} className='acl-form-group'>
						<div className="label">{E5UtilI18n._("admin-users-username")}{redstarjsx}</div>
						<BP.InputGroup className="required" onChange={(event: any) => { this.ChangeTextValue("username", event) }}
							value={this.state.stateuser.username} inputRef={this.usernameinputref} />
					</BP.FormGroup>
					<BP.FormGroup
						labelFor="text-input" labelInfo={redstarjsx} className='acl-form-group'>
						<div className="label">{E5UtilI18n._("admin-users-lastname")}{redstarjsx}</div>
						<BP.InputGroup className='required' onChange={(event: any) => { this.ChangeTextValue("lastname", event) }}
							value={this.state.stateuser.lastname} title="lastname" />

					</BP.FormGroup>
					<BP.FormGroup
						labelFor="text-input" labelInfo={redstarjsx} className='acl-form-group'>
						<div className="label">{E5UtilI18n._("admin-users-firstname")}{redstarjsx}</div>
						<BP.InputGroup className='required' onChange={(event: any) => { this.ChangeTextValue("firstname", event) }}
							value={this.state.stateuser.firstname} title="firstname" />
					</BP.FormGroup>
					<BP.FormGroup
						labelFor="text-input" className='acl-form-group'>
						<div className="label">{E5UtilI18n._("admin-users-email")}{redstarjsx}</div>
						<BP.InputGroup className='required' onChange={(event: any) => { this.ChangeTextValue("email", event) }}
							value={this.state.stateuser.email} title="email" />
					</BP.FormGroup>
					<BP.FormGroup labelFor="text-input">
						<BP.InputGroup placeholder={E5UtilI18n._("admin-users-company")} onChange={(event: any) => { this.ChangeTextValue("company", event) }}
							value={this.state.stateuser.company} title="company" />
					</BP.FormGroup>
					<BP.FormGroup labelFor="text-input">
						<BP.InputGroup placeholder={E5UtilI18n._("admin-users-jobtitle")} onChange={(event: any) => { this.ChangeTextValue("jobtitle", event) }}
							value={this.state.stateuser.jobtitle} title="jobtitle" />
					</BP.FormGroup>
					<BP.FormGroup labelFor="text-input">
						<BP.InputGroup placeholder={E5UtilI18n._("admin-users-phonenumber")} onChange={(event: any) => { this.ChangeTextValue("phonenumber", event) }}
							value={this.state.stateuser.phonenumber} title="phonenumber" />
					</BP.FormGroup>
					<BP.Popover openOnTargetFocus content={<div className="aclgroups">{acljsx}</div>}>
						<BP.Button className="admin-users-aclgroups-select">
							<div className="e5line-5">
								{E5UtilI18n._("admin-users-aclgroups")}&nbsp;{redstarjsx}&nbsp;:&nbsp;
								{E5UtilI18n._("admin-users-aclgroups-select")}

							</div>

						</BP.Button>
					</BP.Popover>
					{/* <FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">Age</InputLabel>
						
					</FormControl> */}
				</div>
				<div style={{ width: "10px" }} />
				<div className="e5linefull">
					<BP.FormGroup
						labelFor="text-input" labelInfo={this.props.user.username === "" && redstarjsx}>
						<BP.InputGroup
							placeholder={E5UtilI18n._("admin-users-password")}
							className='required'
							//rightElement={<BP.Icon icon="lock" iconSize={20} intent={BP.Intent.PRIMARY} />}
							value={this.state.stateuser.password} title="phonenumber"
							type="password" onChange={(event: any) => { this.ChangeTextValue("password", event) }} />
					</BP.FormGroup>
					<BP.FormGroup labelFor="text-input" className="expiration-form-group">
						<BPD.DateInput
							styles={{ width: '225px' }}
							placeholder={E5UtilI18n._("admin-users-accountexpirationdate")}
							formatDate={this.FormatDate} parseDate={this.ParseDate} localeUtils={MomentLocaleUtils}
							value={expdate} locale={E5UtilI18n.curlang} maxDate={this.GetDateMax()}
							onChange={(val: Date, dateclicked: boolean) =>
								this.ChangeDate(val, dateclicked, "accountexpirationdate")} />
					</BP.FormGroup>
					{/* <BP.FormGroup labelFor="text-input">
						<BPD.DateInput placeholder={E5UtilI18n._("admin-users-lastsuccessfullogin")} formatDate={this.FormatDateTime} parseDate={this.ParseDateTime} value={logindate}
							localeUtils={MomentLocaleUtils} locale={E5UtilI18n.curlang} disabled={true} />
					</BP.FormGroup> */}
					<BP.Label>
						{E5UtilI18n._("admin-users-roles")} {redstarjsx} {<span className={'roles_question'}>?</span>}
					</BP.Label>
					{rolesjsx}
					<hr />
					<BP.Checkbox className="e5admin-users-popup-form-checkbox" checked={this.state.stateuser.enabled}
						onChange={(event: any) => { this.ChangeCheckBoxValue("enabled", event) }}
						label={E5UtilI18n._("admin-users-enabled")} />
					<BP.Checkbox
						className="e5admin-users-popup-form-checkbox" checked={this.state.stateuser.accountnonexpired}
						disabled={true} label={E5UtilI18n._("admin-users-accountnonexpired")} />
					<BP.Checkbox checked={this.state.stateuser.credentialsnonexpired}
						className="e5admin-users-popup-form-checkbox" disabled={true}
						label={E5UtilI18n._("admin-users-credentialsnonexpired")} />
					<BP.Checkbox
						className="e5admin-users-popup-form-checkbox" checked={this.state.stateuser.accountnonlocked}
						onChange={(event: any) => { this.ChangeCheckBoxValue("accountnonlocked", event) }}
						label={E5UtilI18n._("admin-users-accountnonlocked")} />
				</div>
			</div>
		</div >;
	}

	// ---------------- EVENTS ----------------

	ToggleAclGroup = (event: React.ChangeEvent<HTMLInputElement>): void => {
		let name: string = event.target.value, aclgroup: E5EntAclGroup = new E5EntAclGroup();
		for (let group of E5StoreACL.Ins().aclinfo.groups) if (group.name === name) {
			aclgroup.Copy(group);
			break;
		}
		let user: E5EntUser = new E5EntUser();
		user.Copy(this.state.stateuser);
		if (user.aclgroups.some(group => group.name === name))
			user.aclgroups = user.aclgroups.filter(group => group.name !== name);
		else user.aclgroups.push(aclgroup);
		this.setState({ stateuser: user });
		this.props.updatefunc(user);
	};

	//E5
	ChangeTextValue = (field: any, event: any) => {
		let user: E5EntUser = new E5EntUser();
		user.Copy(this.state.stateuser);
		(user as any)[field] = event.currentTarget.value;
		this.setState({ stateuser: user });
		this.props.updatefunc(user);
	};

	//E5
	ChangeCheckBoxValue = (field: any, event: any) => {
		let user: E5EntUser = new E5EntUser();
		user.Copy(this.state.stateuser);
		(user as any)[field] = event.currentTarget.checked;
		this.setState({ stateuser: user });
		this.props.updatefunc(user);
	};

	//E5
	ChangeDate = (val: Date, dateclicked: boolean, field: string) => {
		let mm: Moment | undefined = undefined;
		if (val !== null) mm = moment(val);
		if (mm !== undefined && field === "accountexpirationdate") {
			mm.hour(23);
			mm.minute(59);
		}
		let user: E5EntUser = new E5EntUser();
		user.Copy(this.state.stateuser);
		(user as any)[field] = mm;
		this.setState({ stateuser: user });
		this.props.updatefunc(user);
	};

	//E5
	ChangeRole = (event: any, role: string, roleslist: any) => {
		roleslist[role] = event.currentTarget.checked;
		let user: E5EntUser = new E5EntUser();
		user.Copy(this.state.stateuser);
		user.roles = [];
		for (let key in roleslist) if (roleslist.hasOwnProperty(key)) if (roleslist[key]) user.roles.push(key);
		this.setState({ stateuser: user });
		this.props.updatefunc(user);
	};
	// ChangeRole = (role: string) => {

	// 	let user: E5EntUser = new E5EntUser();
	// 	user.Copy(this.state.stateuser);
	// 	user.roles = [role];
	// 	//for (let key in roleslist) if (roleslist.hasOwnProperty(key)) if (roleslist[key]) user.roles.push(key);

	// 	this.setState({ stateuser: user });
	// 	this.props.updatefunc(user);
	// };
	// ---------------- UTILS ----------------

	//E5
	FormatDate = (dt: Date): string => moment(dt).format(E5UtilI18n._("dateformat"));

	//E5
	ParseDate = (str: string): Date => moment(str, E5UtilI18n._("dateformat")).toDate();

	//E5
	FormatDateTime = (dt: Date): string => moment(dt).format(E5UtilI18n._("dateformat") + " HH:mm");

	//E5
	ParseDateTime = (str: string): Date => moment(str, E5UtilI18n._("dateformat") + " HH:mm").toDate();

	//E5
	GetDateMax = () => {
		let mm: Moment = moment();
		mm.add(1, "year");
		return mm.toDate();
	};
});
