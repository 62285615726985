import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5StoreLangInfo} from "../../../store/E5Store";
import {E5UtilI18n} from "../../../global/E5MainLang";
//
import "./E5HIncidentHelp.css";

//E5
interface E5HIncidentHelpState {}

//E5
interface E5HIncidentHelpProps {
	langinfo: E5StoreLangInfo;
}

//E5
export const E5HIncidentHelp = observer(class E5HIncidentHelp extends React.PureComponent
	<E5HIncidentHelpProps, E5HIncidentHelpState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		return <div className="e5h-incident-help">
			<BP.Popover position={BP.Position.BOTTOM_LEFT} content={<div className="e5h-incident-help-img">
				<img src={"/img/help/crit-incid-" + E5UtilI18n.curlang + ".jpeg"} alt=""/>
			</div>} children={<BP.Button icon="help" small={true}/>}/>
		</div>;
	}
});
