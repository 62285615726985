import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5Text } from "../../util/E5Text";
//
import "./E5TextIndic.css";

//E5
interface E5TextIndicState {
	loading: boolean;
}

export enum E5TextIndicUnitEnum {
	count = "count",
	seconds = "seconds",
	bytes = "bytes",
	bytespersec = "bytespersec",
	score = "score"
}

//E5
interface E5TextIndicProps {
	value?: number | null;
	valuestr?: string;
	color?: string;
	textAlign?: string;
	label?: string;
	small?: boolean;
	unit?: string;
	unittype?: E5TextIndicUnitEnum;
	imageurl?: string;
	tooltip?: string | JSX.Element;
}

//E5
export const E5TextIndic = observer(class E5TextIndic extends React.PureComponent
	<E5TextIndicProps, E5TextIndicState> {

	//E5
	constructor(props: E5TextIndicProps, state: E5TextIndicState) {
		super(props, state);
		this.state = { loading: false };
	}

	//E5
	render(): JSX.Element {
		let small: boolean = true;
		if (this.props.small !== undefined)
			small = this.props.small;
		//
		let sizing: string = "";
		if (small)
			sizing = "small";
		//
		let tooltip: string | JSX.Element = "";
		if (this.props.tooltip !== undefined)
			tooltip = this.props.tooltip;
		//
		let unit: string = "";
		if (this.props.unit !== undefined)
			unit = this.props.unit;
		//
		let label: string = "";
		if (this.props.label !== undefined)
			label = this.props.label;
		//
		let img: JSX.Element = <div />;
		if (this.props.imageurl !== undefined)
			img = <img src={this.props.imageurl} alt="" />;
		//
		let valuestr: string = "", tmpval: string, tmpunit: string;
		if (this.props.value === null) {
			valuestr = "N/A";
		} else if (this.props.value !== undefined && !isNaN(this.props.value)) {
			let propval: number = this.props.value;
			if (!small)
				valuestr += " : ";
			switch (this.props.unittype) {
				case E5TextIndicUnitEnum.count:
				case E5TextIndicUnitEnum.bytes:
				case E5TextIndicUnitEnum.bytespersec:
					tmpval = "" + propval;
					tmpunit = "";
					if (propval >= 1000000) {
						tmpval = "" + Math.round(propval / 100000) / 10;
						tmpunit = "M";
					} else if (propval >= 1000) {
						tmpval = "" + Math.round(propval / 100) / 10;
						tmpunit = "K";
					}
					valuestr += tmpval;
					unit = tmpunit;
					if (this.props.unittype === E5TextIndicUnitEnum.bytespersec)
						unit += "B/sec";
					break;
				case E5TextIndicUnitEnum.seconds:
					// tmpval = "";
					// let tmpst = E5Text.Seconds2DHMS(propval);
					// //console.log(tmpst);
					// if (tmpst.days > 0)
					// 	tmpval += tmpst.days;
					// if (tmpval !== "")
					// 	tmpval += " j ";
					// if (tmpst.days > 0 || tmpst.hours > 0)
					// 	tmpval += tmpst.hours;
					// if (tmpval !== "")
					// 	tmpval += " h ";
					// if (tmpst.days > 0 || tmpst.hours > 0 || tmpst.minutes > 0)
					// 	tmpval += tmpst.minutes;
					// if (tmpval !== "")
					// 	tmpval += " mn ";
					// if (tmpst.days > 0 || tmpst.hours > 0 || tmpst.minutes > 0 || tmpst.seconds > 0)
					// 	tmpval += tmpst.seconds;
					// if (tmpval !== "")
					// 	tmpval += " sec ";
					valuestr += E5Text.Seconds2DHMS_str(propval);
					break;
				case E5TextIndicUnitEnum.score:
					valuestr = Math.round(propval * 1000) / 10 + "%";
					break;
				default:
					valuestr += propval;
					break;
			}
		} else if (this.props.valuestr !== undefined)
			valuestr += this.props.valuestr;
		//
		return <div className={"e5text-indic " + sizing}>
			<BP.Tooltip content={tooltip} position={BP.Position.BOTTOM} boundary="window">
				<div className="e5line-5 content">
					{small
						? <>
							<div className="img">
								{img}
							</div>
							<div className="e5column-0 label-container">
								<div className="label">{label}</div>
								<div className="value-unit" style={{ color: this.props.color, textAlign: this.props.textAlign }}>
									<span className="value">{valuestr}</span>
									&nbsp;
									<span className="unit">{unit}</span>
								</div>
							</div>
						</>
						: <>
							<div className="img">
								{img}
							</div>
							<div className="label">{label}</div>
							<div className="value-unit" style={{ color: this.props.color, textAlign: this.props.textAlign }}>
								<span className="value">{valuestr}</span>
								&nbsp;
								<span className="unit">{unit}</span>
							</div>
						</>
					}
				</div>
			</BP.Tooltip>
		</div>;
	}
});
