import {E5EntMetaCtxTarget} from "./E5EntMetaCtxTarget";
import {E5EntMetaIncident} from "./E5EntMetaIncident";
import {E5EntMetaAnomaly} from "./E5EntMetaAnomaly";
import {E5EntMetaRdmInfo} from "./E5EntMetaRdmInfo";
import {E5EntMetaCtxInfo} from "./E5EntMetaCtxInfo";
import {E5EntMetaFilter} from "./E5EntMetaFilter";
import {E5EntMetaRecom} from "./E5EntMetaRecom";
import {E5EntMetaRole} from "./E5EntMetaRole";
import {E5Entity} from "../E5Entity";

//E5
export class E5EntMeta extends E5Entity {
	anomalies: E5EntMetaAnomaly[] = [];
	ctxinfos: E5EntMetaCtxInfo[] = [];
	recomtargets: E5EntMetaCtxTarget[] = [];
	filters: E5EntMetaFilter[] = [];
	incidents: E5EntMetaIncident[] = [];
	recoms: E5EntMetaRecom[] = [];
	roles: Map<string, E5EntMetaRole> = new Map();
	rdminfo: E5EntMetaRdmInfo = new E5EntMetaRdmInfo();

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		let idx: number;
		if (Array.isArray(json.anomalies)) for (idx = 0; idx < json.anomalies.length; idx++)
			this.anomalies.push(new E5EntMetaAnomaly(json.anomalies[idx]));

		if (Array.isArray(json.context_informations)) for (idx = 0; idx < json.context_informations.length; idx++)
			this.ctxinfos.push(new E5EntMetaCtxInfo(json.context_informations[idx]));

		if (Array.isArray(json.recommendation_targets)) for (idx = 0; idx < json.recommendation_targets.length; idx++)
			this.recomtargets.push(new E5EntMetaCtxTarget(json.recommendation_targets[idx]));

		if (Array.isArray(json.filters)) for (idx = 0; idx < json.filters.length; idx++)
			this.filters.push(new E5EntMetaFilter(json.filters[idx]));

		if (Array.isArray(json.incidents)) for (idx = 0; idx < json.incidents.length; idx++)
			this.incidents.push(new E5EntMetaIncident(json.incidents[idx]));

		if (Array.isArray(json.recommendations)) for (idx = 0; idx < json.recommendations.length; idx++)
			this.recoms.push(new E5EntMetaRecom(json.recommendations[idx]));

		let key: string;
		this.roles = new Map();
		if (typeof json.roles === "object" && json.roles !== null) for (key in json.roles)
			if (json.roles.hasOwnProperty(key)) this.roles.set(key, new E5EntMetaRole(json.roles[key]));

		if (json.rdm !== undefined) this.rdminfo.CopyFromJson(json.rdm);
	}

	//E5
	CopyToJson(): any {
		let anomalies: any[] = [], idx: number;
		for (idx = 0; idx < this.anomalies.length; idx++) anomalies.push(this.anomalies[idx].CopyToJson());

		let ctxinfos: any[] = [];
		for (idx = 0; idx < this.ctxinfos.length; idx++) ctxinfos.push(this.ctxinfos[idx].CopyToJson());

		let recomtargets: any[] = [];
		for (idx = 0; idx < this.recomtargets.length; idx++) recomtargets.push(this.recomtargets[idx].CopyToJson());

		let filters: any[] = [];
		for (idx = 0; idx < this.filters.length; idx++) filters.push(this.filters[idx].CopyToJson());

		let incidents: any[] = [];
		for (idx = 0; idx < this.incidents.length; idx++) incidents.push(this.incidents[idx].CopyToJson());

		let recoms: any[] = [];
		for (idx = 0; idx < this.recoms.length; idx++) recoms.push(this.recoms[idx].CopyToJson());

		let roles: any = {};
		for (let [name, role] of this.roles) roles[name] = role.CopyToJson();

		return {
			anomalies, context_informations: ctxinfos, recommendation_targets: recomtargets, filters, incidents,
			recommendations: recoms, roles, rdm: this.rdminfo.CopyToJson()
		};
	}
}
