import {E5EntHMetSys} from "./E5EntHMetSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHMetSysTemp extends E5EntHMetSys {
	highlimit: number|undefined = undefined;
	lowlimit: number|undefined = undefined;
	tempval: number|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.highlimit = E5Entity.ParseFloatUndef(json.high_threshold_limit);
		this.lowlimit = E5Entity.ParseFloatUndef(json.low_threshold_limit);
		this.tempval = E5Entity.ParseFloatUndef(json.temperature_value);
	}

	//E5
	CopyToJson(): any {
		return {
			...super.CopyToJson(), high_threshold_limit: this.highlimit, low_threshold_limit: this.lowlimit,
			temperature_value: this.tempval
		};
	}
}
