import moment, {Moment} from "moment";
//
import {E5Text} from "../../../util/E5Text";
import {E5Time} from "../../../util/E5Time";
import {E5BandEnum} from "../../E5Enums";
import {E5Entity} from "../../E5Entity";

//E5
export interface E5HMetWifiField {
	name: string;
	options?: string[];
	group?: string[],
	categ?: boolean,
	unit: string
}

//E5
export class E5EntHMetWifi extends E5Entity {

	// ------------- STATIC --------------------

	static fields: E5HMetWifiField[] = [
		{name: "airtime", group: ["_idle", "_interference", "_recvother", "_recvself", "_sent"], unit: "0-255"},
		{name: "byterate", group: ["_up", "_down"], unit: "Mb/s"},
		{name: "byterate_up", unit: "Mb/s"},
		{name: "byterate_down", unit: "Mb/s"},
		{name: "packrate", group: ["_up", "_down"], unit: "P/s"},
		{name: "packrate_up", unit: "P/s"},
		{name: "packrate_down", unit: "P/s"},
		{name: "lastratelink", group: ["_up_avg", "_down_avg"], unit: "Mb/s"},
		{name: "lastratelink_down", group: ["_min", "_avg", "_max"], unit: "Mb/s"},
		{name: "lastratelink_up", group: ["_min", "_avg", "_max"], unit: "Mb/s"},
		{name: "pack", group: ["_up", "_down"], unit: ""},
		{name: "rssi", group: ["_min", "_avg", "_max"], unit: "dBm"},
		{name: "noise", group: ["_min", "_avg", "_max"], unit: "dBm"},
		{name: "glitchrate", unit: "Hz"},
		{name: "Wi-Fi", categ: true, unit: ""},
		{name: "parentinterfmac", categ: true, unit: ""},
		// {name: "s", options: [], unit: ""}
	];

	// ------------- INSTANCE --------------------

	nodeimei: string = "";
	devicemac: string = "";
	parentimei: string = "";
	parentinterfmac: string = "";
	time: Moment = moment();
	endtime: Moment = moment();
	starttime: Moment = moment();
	isbackhaul: boolean = false;
	duration: number = 0;
	wifistandard: string = "";
	connectionid: string = "";

	airtime_idle: number|undefined = undefined;
	airtime_interference: number|undefined = undefined;
	airtime_recvother: number|undefined = undefined;
	airtime_recvself: number|undefined = undefined;
	airtime_sent: number|undefined = undefined;

	byterate_down: number|undefined = undefined;
	byterate_up: number|undefined = undefined;

	packrate_down: number|undefined = undefined;
	packrate_up: number|undefined = undefined;

	lastratelink_down_min: number|undefined = undefined;
	lastratelink_down_avg: number|undefined = undefined;
	lastratelink_down_max: number|undefined = undefined;

	lastratelink_up_min: number|undefined = undefined;
	lastratelink_up_avg: number|undefined = undefined;
	lastratelink_up_max: number|undefined = undefined;

	pack_down: number|undefined = undefined;
	pack_up: number|undefined = undefined;

	glitchrate: number|undefined = undefined;

	rssi_min: number|undefined = undefined;
	rssi_avg: number|undefined = undefined;
	rssi_max: number|undefined = undefined;

	noise_min: number|undefined = undefined;
	noise_avg: number|undefined = undefined;
	noise_max: number|undefined = undefined;

	sA010000: number|undefined = undefined;
	sA010101: number|undefined = undefined;
	sA010102: number|undefined = undefined;
	sA010103: number|undefined = undefined;
	sA010104: number|undefined = undefined;
	sA010200: number|undefined = undefined;
	sA010201: number|undefined = undefined;
	sA010202: number|undefined = undefined;
	sA010203: number|undefined = undefined;
	sA010204: number|undefined = undefined;
	sA010301: number|undefined = undefined;
	sA010302: number|undefined = undefined;
	sA010401: number|undefined = undefined;
	sA010402: number|undefined = undefined;
	sA010403: number|undefined = undefined;
	sA010404: number|undefined = undefined;
	sA010501: number|undefined = undefined;
	sA010502: number|undefined = undefined;
	sA010503: number|undefined = undefined;
	sA010504: number|undefined = undefined;
	sA020000: number|undefined = undefined;
	sA020101: number|undefined = undefined;
	sA020200: number|undefined = undefined;
	sA020201: number|undefined = undefined;
	sA020202: number|undefined = undefined;
	sA020203: number|undefined = undefined;
	sA020204: number|undefined = undefined;

	wifiband: E5BandEnum = E5BandEnum.none;
	wifichannel: number = 0;
	wifibandwidth: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.connectionid = E5Entity.AssignString(json.connection_id);
		this.isbackhaul = json.is_backhaul === true;
		this.parentimei = E5Entity.AssignString(json.node_imei);

		if (this.isbackhaul) {
			this.nodeimei = E5Entity.AssignString(json.child_id);
			this.devicemac = "";
		} else {
			this.nodeimei = "";
			this.devicemac = E5Entity.AssignString(json.child_id);
		}

		this.parentinterfmac = E5Entity.AssignString(json.node_mac_address);
		let tm: number = E5Entity.ParseInt(json.time);
		this.time = E5Text.Tm2MomentTz(tm, E5Time.h_tzoffset);
		tm = E5Entity.ParseInt(json.bucket_start_time);
		this.starttime = E5Text.Tm2MomentTz(tm, E5Time.h_tzoffset);
		tm = E5Entity.ParseInt(json.bucket_end_time);
		this.endtime = E5Text.Tm2MomentTz(tm, E5Time.h_tzoffset);
		this.duration = E5Entity.ParseInt(json.duration);
		this.wifistandard = E5Entity.AssignString(json.operating_standard);

		if (typeof json.airtime === "object" && json.airtime !== null) {
			this.airtime_idle = E5Entity.ParseFloatUndef(json.airtime.idle);
			this.airtime_interference = E5Entity.ParseFloatUndef(json.airtime.interference);
			this.airtime_recvother = E5Entity.ParseFloatUndef(json.airtime.receive_other);
			this.airtime_recvself = E5Entity.ParseFloatUndef(json.airtime.receive_self);
			this.airtime_sent = E5Entity.ParseFloatUndef(json.airtime.transmit);
		} else {
			this.airtime_idle = undefined;
			this.airtime_interference = undefined;
			this.airtime_recvother = undefined;
			this.airtime_recvself = undefined;
			this.airtime_sent = undefined;
		}

		this.byterate_down = E5Entity.ParseFloatUndef(json.down_byte_rate);
		if (this.byterate_down !== undefined) this.byterate_down *= 8 / 1024 / 1024;// Bytes -> Mb
		this.byterate_up = E5Entity.ParseFloatUndef(json.up_byte_rate);
		if (this.byterate_up !== undefined) this.byterate_up *= 8 / 1024 / 1024;// Bytes -> Mb
		this.packrate_down = E5Entity.ParseFloatUndef(json.down_packet_rate);
		this.packrate_up = E5Entity.ParseFloatUndef(json.up_packet_rate);

		if (typeof json.last_data_down_link_rate === "object" && json.last_data_down_link_rate !== null) {
			this.lastratelink_down_min = E5Entity.ParseFloatUndef(json.last_data_down_link_rate.min);
			if (this.lastratelink_down_min !== undefined) this.lastratelink_down_min = E5Text.RoundUp(this.lastratelink_down_min / 1024);// Kb -> Mb
			this.lastratelink_down_avg = E5Entity.ParseFloatUndef(json.last_data_down_link_rate.average);
			if (this.lastratelink_down_avg !== undefined) this.lastratelink_down_avg = E5Text.RoundUp(this.lastratelink_down_avg / 1024);// Kb -> Mb
			this.lastratelink_down_max = E5Entity.ParseFloatUndef(json.last_data_down_link_rate.max);
			if (this.lastratelink_down_max !== undefined) this.lastratelink_down_max = E5Text.RoundUp(this.lastratelink_down_max / 1024);// Kb -> Mb
		} else {
			this.lastratelink_down_min = undefined;
			this.lastratelink_down_avg = undefined;
			this.lastratelink_down_max = undefined;
		}

		if (typeof json.last_data_up_link_rate === "object" && json.last_data_up_link_rate !== null) {
			this.lastratelink_up_min = E5Entity.ParseFloatUndef(json.last_data_up_link_rate.min);
			if (this.lastratelink_up_min !== undefined) this.lastratelink_up_min = E5Text.RoundUp(this.lastratelink_up_min / 1024);// Kb -> Mb
			this.lastratelink_up_avg = E5Entity.ParseFloatUndef(json.last_data_up_link_rate.average);
			if (this.lastratelink_up_avg !== undefined) this.lastratelink_up_avg = E5Text.RoundUp(this.lastratelink_up_avg / 1024);// Kb -> Mb
			this.lastratelink_up_max = E5Entity.ParseFloatUndef(json.last_data_up_link_rate.max);
			if (this.lastratelink_up_max !== undefined) this.lastratelink_up_max = E5Text.RoundUp(this.lastratelink_up_max / 1024);// Kb -> Mb
		} else {
			this.lastratelink_up_min = undefined;
			this.lastratelink_up_avg = undefined;
			this.lastratelink_up_max = undefined;
		}

		this.pack_down = E5Entity.ParseIntUndef(json.packets_received);
		this.pack_up = E5Entity.ParseIntUndef(json.packets_sent);

		if (typeof json.severities === "object" && json.severities !== null) {
			this.sA010000 = E5Entity.ParseFloatUndef(json.severities.A010000);
			this.sA010101 = E5Entity.ParseFloatUndef(json.severities.A010101);
			this.sA010102 = E5Entity.ParseFloatUndef(json.severities.A010102);
			this.sA010103 = E5Entity.ParseFloatUndef(json.severities.A010103);
			this.sA010104 = E5Entity.ParseFloatUndef(json.severities.A010104);
			this.sA010200 = E5Entity.ParseFloatUndef(json.severities.A010200);
			this.sA010201 = E5Entity.ParseFloatUndef(json.severities.A010201);
			this.sA010202 = E5Entity.ParseFloatUndef(json.severities.A010202);
			this.sA010203 = E5Entity.ParseFloatUndef(json.severities.A010203);
			this.sA010204 = E5Entity.ParseFloatUndef(json.severities.A010204);
			this.sA010301 = E5Entity.ParseFloatUndef(json.severities.A010301);
			this.sA010302 = E5Entity.ParseFloatUndef(json.severities.A010302);
			this.sA010401 = E5Entity.ParseFloatUndef(json.severities.A010401);
			this.sA010402 = E5Entity.ParseFloatUndef(json.severities.A010402);
			this.sA010403 = E5Entity.ParseFloatUndef(json.severities.A010403);
			this.sA010404 = E5Entity.ParseFloatUndef(json.severities.A010404);
			this.sA010501 = E5Entity.ParseFloatUndef(json.severities.A010501);
			this.sA010502 = E5Entity.ParseFloatUndef(json.severities.A010502);
			this.sA010503 = E5Entity.ParseFloatUndef(json.severities.A010503);
			this.sA010504 = E5Entity.ParseFloatUndef(json.severities.A010504);
			this.sA020000 = E5Entity.ParseFloatUndef(json.severities.A020000);
			this.sA020101 = E5Entity.ParseFloatUndef(json.severities.A020101);
			this.sA020200 = E5Entity.ParseFloatUndef(json.severities.A020200);
			this.sA020201 = E5Entity.ParseFloatUndef(json.severities.A020201);
			this.sA020202 = E5Entity.ParseFloatUndef(json.severities.A020202);
			this.sA020203 = E5Entity.ParseFloatUndef(json.severities.A020203);
			this.sA020204 = E5Entity.ParseFloatUndef(json.severities.A020204);
		} else {
			this.sA010000 = undefined;
			this.sA010101 = undefined;
			this.sA010102 = undefined;
			this.sA010103 = undefined;
			this.sA010104 = undefined;
			this.sA010200 = undefined;
			this.sA010201 = undefined;
			this.sA010202 = undefined;
			this.sA010203 = undefined;
			this.sA010204 = undefined;
			this.sA010301 = undefined;
			this.sA010302 = undefined;
			this.sA010401 = undefined;
			this.sA010402 = undefined;
			this.sA010403 = undefined;
			this.sA010404 = undefined;
			this.sA010501 = undefined;
			this.sA010502 = undefined;
			this.sA010503 = undefined;
			this.sA010504 = undefined;
			this.sA020000 = undefined;
			this.sA020101 = undefined;
			this.sA020200 = undefined;
			this.sA020201 = undefined;
			this.sA020202 = undefined;
			this.sA020203 = undefined;
			this.sA020204 = undefined;
		}

		if (typeof json.rssi === "object" && json.rssi !== null) {
			this.rssi_min = E5Entity.ParseFloatUndef(json.rssi.min);
			this.rssi_avg = E5Entity.ParseFloatUndef(json.rssi.average);
			this.rssi_max = E5Entity.ParseFloatUndef(json.rssi.max);
		} else {
			this.rssi_min = undefined;
			this.rssi_avg = undefined;
			this.rssi_max = undefined;
		}

		if (typeof json.noise === "object" && json.noise !== null) {
			this.noise_min = E5Entity.ParseFloatUndef(json.noise.min);
			this.noise_avg = E5Entity.ParseFloatUndef(json.noise.average);
			this.noise_max = E5Entity.ParseFloatUndef(json.noise.max);
		} else {
			this.noise_min = undefined;
			this.noise_avg = undefined;
			this.noise_max = undefined;
		}

		this.glitchrate = E5Entity.ParseFloatUndef(json.glitch_rate);
		this.wifiband = Object.values(E5BandEnum).includes(json.band) ? json.band : E5BandEnum.none;
		this.wifichannel = E5Entity.ParseInt(json.channel);
		this.wifibandwidth = E5Entity.AssignString(json.current_operating_bandwidth);
	}

	//E5
	CopyToJson(): any {
		return {
			connection_id: this.connectionid, is_backhaul: this.isbackhaul,
			child_id: this.isbackhaul ? this.nodeimei : this.devicemac, node_mac_address: this.parentinterfmac,
			node_imei: this.parentimei, time: this.time.unix(), bucket_start_time: this.starttime.unix(),
			bucket_end_time: this.endtime.unix(), duration: this.duration, airtime: {
				idle: this.airtime_idle, interference: this.airtime_interference, receive_other: this.airtime_recvother,
				receive_self: this.airtime_recvself, transmit: this.airtime_sent
			}, down_byte_rate: (this.byterate_down !== undefined ? this.byterate_down / 8 * 1024 * 1024 : undefined),// Mb -> Bytes
			up_byte_rate: (this.byterate_up !== undefined ? this.byterate_up / 8 * 1024 * 1024 : undefined),// Mb -> Bytes
			down_packet_rate: this.packrate_down, up_packet_rate: this.packrate_up, last_data_down_link_rate: { // Mb -> Kb
				min: (this.lastratelink_down_min !== undefined ? this.lastratelink_down_min * 1024 : undefined),
				average: (this.lastratelink_down_avg !== undefined ? this.lastratelink_down_avg * 1024 : undefined),
				max: (this.lastratelink_down_max !== undefined ? this.lastratelink_down_max * 1024 : undefined)
			}, last_data_up_link_rate: { // Mb -> Kb
				min: (this.lastratelink_up_min !== undefined ? this.lastratelink_up_min * 1024 : undefined),
				average: (this.lastratelink_up_avg !== undefined ? this.lastratelink_up_avg * 1024 : undefined),
				max: (this.lastratelink_up_max !== undefined ? this.lastratelink_up_max * 1024 : undefined)
			}, packets_received: this.pack_down, packets_sent: this.pack_up, severities: {
				A010000: this.sA010000, A010101: this.sA010101, A010102: this.sA010102, A010103: this.sA010103,
				A010104: this.sA010104, A010200: this.sA010200, A010201: this.sA010201, A010202: this.sA010202,
				A010203: this.sA010203, A010204: this.sA010204, A010301: this.sA010301, A010302: this.sA010302,
				A010401: this.sA010401, A010402: this.sA010402, A010403: this.sA010403, A010404: this.sA010404,
				A010501: this.sA010501, A010502: this.sA010502, A010503: this.sA010503, A010504: this.sA010504,
				A020000: this.sA020000, A020101: this.sA020101, A020200: this.sA020200, A020201: this.sA020201,
				A020202: this.sA020202, A020203: this.sA020203, A020204: this.sA020204
			}, rssi: {min: this.rssi_min, average: this.rssi_avg, max: this.rssi_max},
			noise: {min: this.noise_min, average: this.noise_avg, max: this.noise_max}, glitch_rate: this.glitchrate,
			band: this.wifiband, channel: this.wifichannel, operating_standard: this.wifistandard,
			current_operating_bandwidth: this.wifibandwidth
		};
	}
}
