import React from "react";
import moment from "moment";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5CBPopAction, E5CBPopSaveReceive, E5CBPopList} from "../../customer_base/population/E5CBPopList";
import {E5EntCBPop} from "../../../entity/customer_base/E5EntCBPop";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestPopulations.css";

//E5
interface E5TestPopulationsState {
	pops: E5EntCBPop[];
}

//E5
interface E5TestPopulationsProps {}

//E5
export const E5TestPopulations = observer(class E5TestPopulations extends React.PureComponent
	<E5TestPopulationsProps, E5TestPopulationsState> {

	// ---------------- MEMBERS ----------------

	//E5
	json1: any[] = [];
	json2: any[] = [];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestPopulationsProps, state: E5TestPopulationsState) {
		super(props, state);
		this.state = {pops: []};
		this.json1 = [
			{
				populationID: "0", name: "population 0", totalNetwork: null, criterias: null, networkIds: 2,
				creationDate: moment().unix()
			},
			{
				populationID: "1", name: "population 1", networkIds: null, totalNetwork: 1,
				creationDate: moment().subtract(1, "days").unix(),
				criterias: [
					{criteria: "zero criterion", condition: "==", value: 0},
					{criteria: "one criterion", condition: "!=", value: 1}
				]
			}
		];
		this.json2 = [
			{
				populationID: "2", name: "population 2", totalNetwork: 2, criterias: null, networkIds: 3,
				creationDate: moment().subtract(2, "days").unix()
			},
			{
				populationID: "3", name: "population 3", totalNetwork: null, networkIds: null,
				creationDate: moment().subtract(3, "days").unix(),
				criterias: [
					{criteria: "two criterion", condition: "==", value: 2},
					{criteria: "three criterion", condition: "!=", value: 3},
					{criteria: "four criterion", condition: "<", value: 4},
					{criteria: "five criterion", condition: "<=", value: 5},
					{criteria: "six criterion", condition: ">", value: 6},
					{criteria: "seven criterion", condition: ">=", value: 7},
					{criteria: "eight criterion", condition: "==", value: 8},
					{criteria: "nine criterion", condition: "!=", value: 9},
					{criteria: "ten criterion", condition: "<", value: 10},
					{criteria: "eleven criterion", condition: "<=", value: 11},
					{criteria: "twelve criterion", condition: ">", value: 12},
					{criteria: "thirteen criterion", condition: ">=", value: 13},
					{criteria: "fourteen criterion", condition: "==", value: 14}
				]
			}
		];
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		let popinfo = {status: {loading: false, success: true, message: ""}, pops: this.state.pops};
		let popgetinfo =
			{status: {loading: false, success: true, message: ""}, pop: new E5EntCBPop()};
		//
		return <div className="e5testpage e5test-populations e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-populations-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Test list 1">
					<BP.Button onClick={() => {this.ChangeList(this.json1)}} icon="refresh"/>
				</BP.Callout>
				<BP.Callout title="Test list 2">
					<BP.Button onClick={() => {this.ChangeList(this.json2)}} icon="refresh"/>
				</BP.Callout>
			</div>
			<div className="e5wificb-populations-content e5columnfull">
				<E5CBPopList
					langinfo={E5Store.Ins().langinfo}
					popinfo={popinfo}
					popgetinfo={popgetinfo}
					savefunc={this.SavePop}
					nonetidsfunc={(): void => {}}
				/>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	ChangeList = (json: any) => {
		let pops: E5EntCBPop[] = [], idx: number;
		for (idx = 0; idx < json.length; idx++)
			pops.push(new E5EntCBPop(json[idx]));
		this.setState({pops: pops});
	};

	// ---------------- UTILS ----------------

	//E5
	SavePop = (action: E5CBPopAction, pop: E5EntCBPop, popid: string, infoonly: boolean,
		callback: E5CBPopSaveReceive) => {
		//
		let pops: E5EntCBPop[] = [], idx: number;
		for (idx = 0; idx < this.state.pops.length; idx++) {
			let curpop: E5EntCBPop = this.state.pops[idx];
			if (action === E5CBPopAction.update) {
				if (curpop.populationid === popid)
					pops.push(pop);
				else
					pops.push(curpop);
			} else if (action === E5CBPopAction.delete) {
				if (curpop.populationid !== popid)
					pops.push(curpop);
			} else if (action === E5CBPopAction.add) {
				pops.push(curpop);
			}
		}
		if (action === E5CBPopAction.add) {
			pop.populationid = "" + Math.random();
			pop.creationdate = moment();
			pops.push(pop);
		}
		//
		this.setState({pops: pops}, () => callback(true, ""));
	};
});
