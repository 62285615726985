import moment from "moment";
import { heatmapTimeSlots } from "../../../util/E5HActivityUtils";
import { E5Entity } from "../../E5Entity";

export class E5EntHActivityDevice extends E5Entity {
    activityInfo: Array<{name: string, value: number, bytes?: number}> = [];
    private readonly isActivityDetection: boolean = false;
    private readonly isDeviceCount: boolean = false;
    label: string = "";

    constructor(key?: string, json?: any, isActivityDetection: boolean = false, isDeviceCount: boolean = false) {
        super();
        this.isActivityDetection = isActivityDetection;
        this.isDeviceCount = isDeviceCount;

        if (json !== undefined) {
            this.CopyFromJson(json);
        }

        if (key !== undefined) {
            if (this.isActivityDetection) {
                this.label = this.ParseLabel(key);
            } else {
                this.label = key;
            }
        }
    }

    ParseLabel(key: string): string {
        return moment(key, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    CopyFromJson(json: any): void {
        if (this.isActivityDetection) {
            this.activityInfo = heatmapTimeSlots.map((timeSlot: string) => {
                const dataTimeSlot = json.find((activity: any) => activity.timeslot === timeSlot);
                const slotInfo: {name: string, value: number, bytes: number} = {
                    name: timeSlot.slice(0, 5),
                    value: -1,
                    bytes: -1
                };

                if (dataTimeSlot) {
                    slotInfo.value = dataTimeSlot.activity;
                    slotInfo.bytes = dataTimeSlot.bytes;
                }

                return slotInfo;
            });
        } else if (this.isDeviceCount) {
            Object.keys(json).forEach((key: any) => {
                const deviceInfo = {
                    name: key,
                    value: Array.isArray(json[key]) ? [...new Set(json[key])].length : json[key]
                }
    
                this.activityInfo.push(deviceInfo);
            });
        } else {
            Object.keys(json).forEach((key: any) => {
                const deviceInfo = {
                    name: key,
                    value: json[key]
                }
    
                this.activityInfo.push(deviceInfo);
            });
        }

    }
}