import React from "react";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import * as BP from "@blueprintjs/core";
import * as BPT from "@blueprintjs/table";
// import * as BPD from "@blueprintjs/datetime";
// import {LocaleUtils} from "react-day-picker";
import { IReactionDisposer, reaction } from "mobx";
//
// import {E5FormBPDateUtils} from "../../../util/E5FormDatetimeUtils";
import Button from '../../../global/component/Button';
import { E5RequestStatus } from "../../../request/E5ServiceCommon";
import { E5EntAclGroup } from "../../../entity/E5EntAclGroup";
import { E5RequestACL } from "../../../request/E5RequestACL";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5StoreACL } from "../../../store/E5StoreACL";
import { E5AdminAclPopup } from "./E5AdminAclPopup";
import { E5Store } from "../../../store/E5Store";
//
import "./E5AdminAclList.css";

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactComponent as Filter } from '../../../assets/table-filter.svg'
import Table from '../../../global/component/E5Table'
import TextFieldStyled from '../../../global/component/TextFieldStyled';
import RemoveSelectedAclGroupsModal from "./RemoveSelectedAclGroupsModal.component"
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import StyledMobileDatePicker from '../../../global/component/DatePickerStyled';
enum E5AclSortDir { up = "up", down = "down" }


//E5
const tableRows = [
	{
		field: 'name',
		headerName: 'Name',
		//width: 100,
		editable: true,
		headerAlign: 'center',
		align: 'left',
		renderCell: (params) => {
			return params.row.name;
		},
	},
	{
		field: 'creationdate',
		headerName: 'Creation',
		editable: true,
		headerAlign: 'center',
		align: 'left',
		renderCell: (params) => {
			return params.row.creationdate;
		},
	},
	{
		field: 'modification',
		headerName: 'Modification',
		//width: 100,
		editable: true,
		headerAlign: 'center',
		align: 'left',
		renderCell: (params) => {
			return params.row.updatedate;
		},
	},
	{
		field: 'bydefault',
		headerName: 'By Default',
		width: 100,
		editable: true,
		headerAlign: 'center',
		align: 'left',
		renderCell: (params) => {
			return params.row.default;
		},
	},
	{
		field: 'type',
		headerName: 'Type',
		//width: 100,
		editable: true,
		headerAlign: 'center',
		align: 'left',
		renderCell: (params) => {
			return params.row.type;
		},
	},
	{
		field: 'updatedbyuser',
		headerName: 'Modified by',
		//width: 100,
		editable: true,
		headerAlign: 'center',
		align: 'left',
		renderCell: (params) => {
			return params.row.updatedbyuser;
		},
	}
]
interface E5AdminAclListState {
	filtereddata: E5EntAclGroup[];
	filtereddatanew: E5EntAclGroup[];
	fromDate: string;
	untilDate: string;
	filteredName: string | null;
	filteredUpdatedByName: string | null;
	isAnyFilterActive: boolean;
	filterByNameInputValue: string;
	filterUpdatedByNameInputValue: string;
	filterFromDateInputValue: string;
	filterUntilDateInputValue: string;
	activefilter: any;
	sortfield: string;
	sortdir: E5AclSortDir;
	filterinput: any;
	listdeleteopen: boolean;
	deleteopen: boolean;
	netidopen: boolean;
	dialopen: boolean;
	selgroup: E5EntAclGroup;
	editgroup: E5EntAclGroup;
	editgroupchanged: boolean;
	showFilter: boolean;
	isRemoveAclModalOpen: boolean;
	selectedAclGroupsIds: number[],
	selectedAclGroupsNames: string[]
	rowSelectionModel: number[]
}

//E5
interface E5AdminAclListProps { }

//E5
export const E5AdminAclList = observer(class E5AdminAclList extends React.PureComponent
	<E5AdminAclListProps, E5AdminAclListState> {

	// ---------------- MEMBERS ----------------

	//E5
	filtertimer?: NodeJS.Timeout;
	toasterref: React.RefObject<BP.Toaster>;
	stop_change_groups?: IReactionDisposer;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5AdminAclListProps, state: E5AdminAclListState) {
		super(props, state);
		this.toasterref = React.createRef();
		let group: E5EntAclGroup = new E5EntAclGroup();
		this.state = {
			sortfield: "type", sortdir: E5AclSortDir.up,
			filtereddata: [], filtereddatanew: [], activefilter: {}, filterinput: {}, listdeleteopen: false, deleteopen: false,
			netidopen: false, dialopen: false, editgroup: group, selgroup: group, editgroupchanged: false, showFilter: false,
			isRemoveAclModalOpen: false, selectedAclGroupsIds: [], selectedAclGroupsNames: [],
			filteredName: null,
			filteredUpdatedByName: null,
			fromDate: new Date(0).toUTCString(),
			untilDate: new Date().toString(),
			filterByNameInputValue: '',
			filterUpdatedByNameInputValue: '',
			filterFromDateInputValue: new Date(0).toUTCString(),
			filterUntilDateInputValue: new Date().toString(),
			isAnyFilterActive: false,
			rowSelectionModel: [],

		};
	}

	//E5
	componentDidMount(): void {
		if (E5StoreACL.Ins().aclinfo.groups.length === 0) this.LaunchSearch();
		this.BuildFiltered();
		this.stop_change_groups = reaction(() => E5StoreACL.Ins().aclinfo.groups,
			() => this.BuildFiltered());
	}

	//E5
	componentWillUnmount(): void {
		if (this.filtertimer !== undefined) clearTimeout(this.filtertimer);
		this.stop_change_groups?.();
	}
	componentDidUpdate(previousProps, previousState) {
		if (previousState.filteredName !== this.state.filteredName) {
			this.FilterAll({})
		};
		if (previousState.fromDate !== this.state.fromDate) {
			this.FilterAll({})
		};
		if (previousState.untilDate !== this.state.untilDate) {
			this.FilterAll({})
		};
		if (previousState.filteredUpdatedByName !== this.state.filteredUpdatedByName) {
			this.FilterAll({})
		};
	}
	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { loading } = E5StoreACL.Ins().aclinfo.status, { _ } = E5UtilI18n,
			groupisnew: boolean = this.state.selgroup.name === "";

		// filter updatedate
		let updatedatestarthasfilter: string = (this.state.filterinput.updatedate !== undefined &&
			this.state.filterinput.updatedate.start !== undefined) ? " hasfilter" : "",
			updatedateendhasfilter: string = (this.state.filterinput.updatedate !== undefined &&
				this.state.filterinput.updatedate.end !== undefined) ? " hasfilter" : "";

		return <div className="e5admin-acl-list e5columnfull e5column-20">
			<BP.Toaster ref={this.toasterref} />
			<div className="e5compo e5column-5 e5admin-acl-header">
				<div className="e5line-5 e5admin-acl-titleline">
					<div className="e5compotitle">{_("admin-acl-filters")}</div>
					{loading && <BP.Spinner className="e5spinwait" size={25} />}
				</div>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<div className="e5line-5 e5admin-acl-filterline e5admin-acl-titleline">

						<div className="e5admin-acl-filterbloc">
							<TextFieldStyled
								className='e5admin-users-filterbloc-search'
								id="outlined-basic" label={_("admin-acl-name")}
								variant="outlined"
								onChange={this.onChangeFilterByUser}
								value={this.state.filterByNameInputValue}
								InputProps={{ sx: { borderRadius: 3 } }}
							/>
						</div>
						<div className="e5admin-acl-filterbloc">

							<TextFieldStyled
								className='e5admin-users-filterbloc-search'
								id="outlined-basic" label={_("admin-acl-updatedbyuser")}
								variant="outlined"
								onChange={this.onChangeFilterByUpdatedUser}
								value={this.state.filterUpdatedByNameInputValue}
								InputProps={{ sx: { borderRadius: 3 } }}
							/>
						</div>
						<div className={"e5admin-acl-filterbloc e5admin-acl-filterbloc-date" + updatedatestarthasfilter}>
							<StyledMobileDatePicker onChange={this.onChangeFilterByDateFrom} label={_("admin-acl-updatedatestart")}
								value={this.state.fromDate === new Date(0).toUTCString() ? null : dayjs(this.state.fromDate)}
							/>
						</div>
						<div className={"e5admin-acl-filterbloc e5admin-acl-filterbloc-date" + updatedateendhasfilter}>
							<StyledMobileDatePicker label={_("admin-acl-updatedateend")} onChange={this.onChangeFilterByDateUntil}
								value={this.state.untilDate === new Date().toString() ? null : dayjs(this.state.untilDate)} />
						</div>
						{/* <div className={"e5admin-acl-filterbloc"}>
							<Filter className='filter-icon' onClick={() => this.setState({ showFilter: !this.state.showFilter })} />
							{this.state.showFilter && <div className='filterModal'>
								<div className='filterHeader'>Filter<div className='filterButton'>Reset Filter</div></div>
							</div>}
						</div> */}
						{!loading && this.state.isAnyFilterActive &&
							<BP.Button icon="cross" className={'delete-filters-button'} text={_("delfilters")} onClick={this.onResetFilters} />}
						<div className="e5admin-acl-filterbloc">
							{this.state.selectedAclGroupsNames.length ? <div onClick={() => this.setState({ isRemoveAclModalOpen: true })} className='deleteAclGroupsButton'>Delete the selected groups</div> : null}
						</div>
						<div className="e5linefull" />
						<BP.Button className='button' style={{ width: '165px' }} icon="add" text={_("admin-acl-popup-add")} onClick={this.AddGroup} />
					</div>
				</LocalizationProvider>
			</div>
			<Table className=''
				pageSize={25}
				checkboxSelection={true}
				onCellClick={(cell) => {
					if (cell.field === "__check__") {
						const currentCheckedRows = [...this.state.rowSelectionModel];
						if (!currentCheckedRows.includes(cell.id)) {
							currentCheckedRows.push(cell.id)
						} else {
							var index = currentCheckedRows.indexOf(cell.id);    // <-- Not supported in <IE9
							if (index !== -1) {
								currentCheckedRows.splice(index, 1);
							}
						}
						this.setState({ rowSelectionModel: currentCheckedRows, selectedAclGroupsIds: currentCheckedRows, selectedAclGroupsNames: currentCheckedRows })
					}
				}}
				rowSelectionModel={this.state.rowSelectionModel}
				onRowDoubleClick={(data) => {
					const group = this.state.filtereddata.filter((item) => item.name === data.id);
					this.OpenGroup(group[0])
				}}
				columns={[...tableRows]}
				rows={[...this.state.filtereddata.map((row, index) =>
				({
					...row,
					id: row.name,
					creationdate: row.creationdate?.format(_("dateformat") + " HH:mm"),
					updatedate: row.updatedate?.format(_("dateformat") + " HH:mm"),
					default: row.default ? _("admin-acl-true") : _("admin-acl-false")
				})
				)]}
				translation="admin-acl-" />
			<BP.Dialog isOpen={this.state.dialopen} canOutsideClickClose={false}
				canEscapeKeyClose={false} onClose={this.CloseGroup} style={{ width: "800px" }}
				className="acl-popup"
				title={_(groupisnew ? "admin-acl-popup-add" : "admin-acl-uiactionedit")}>
				{/* <E5AdminAclPopup group={this.state.editgroup} updatefunc={this.EditGroup}
					defaultexists={E5StoreACL.Ins().aclinfo.groups.some(group => group.default)} /> */}
				<div className={BP.Classes.DIALOG_BODY}>
					<E5AdminAclPopup group={this.state.editgroup} updatefunc={this.EditGroup}
						defaultexists={E5StoreACL.Ins().aclinfo.groups.some(group => group.default)} />
				</div>
				<div className={BP.Classes.DIALOG_FOOTER}>
					<div className={BP.Classes.DIALOG_FOOTER_ACTIONS}>
						{!E5StoreACL.Ins().aclinfo.status.loading && <>
							<div className="admin-acl-footer-left">
								{this.state.editgroupchanged && this.state.editgroup.name !== "" &&
									<BP.Button text={_(groupisnew ? "Create" : "Save")}
										className='admin-acl-save-button'
										intent={BP.Intent.PRIMARY} onClick={this.SaveGroup} />}
								{!groupisnew &&
									<BP.Popover enforceFocus={false} isOpen={this.state.deleteopen}>
										<BP.Tooltip content={_("admin-acl-uiactiondelete")}>
											<Button theme="red"
												onClick={this.DeleteGroup} >
												Delete
											</Button>

										</BP.Tooltip>
										<div className="e5admin-acl-delete-confirm e5column-10">
											<div>{_("admin-acl-confirmdelete")}</div>

											<div className="admin-acl-button-wrapper" >
												<Button theme="red"
													onClick={() => this.DeleteGroup2(true)} >
													{_("admin-acl-delete")}
												</Button>
												<Button theme="blue"
													onClick={this.CloseDelete} >
													{_("admin-acl-close-delete")}
												</Button>
											</div>

										</div>
									</BP.Popover>}
								{/* //<div className='e5admin-acl-buttons-wrapper'> */}


								{/* </div> */}
							</div>
							<Button theme="blue"
								onClick={this.CloseGroup} >
								{_("admin-acl-closenosave")}
							</Button>

						</>}
					</div>
				</div>
			</BP.Dialog>
			{this.state.isRemoveAclModalOpen && <RemoveSelectedAclGroupsModal groups={[...this.state.filtereddata]} onClose={this.onCloseRemoveAclGroupsModal} onDelete={this.DeleteGroups} aclIds={this.state.selectedAclGroupsIds} aclNames={this.state.selectedAclGroupsNames} />}
			<BP.Dialog icon="delete" title={_("admin-acl-uiactiondelete")} isOpen={this.state.listdeleteopen}
				canOutsideClickClose canEscapeKeyClose onClose={this.CloseDelete}
				style={{ width: "800px" }}>
				<div className={BP.Classes.DIALOG_BODY}>
					<div>{_("admin-acl-confirmdelete")}</div>
				</div>
				<div className={BP.Classes.DIALOG_FOOTER}>
					<div className={BP.Classes.DIALOG_FOOTER_ACTIONS}>
						{!E5StoreACL.Ins().aclinfo.status.loading ? <>
							<Button theme="red"
								onClick={() => this.DeleteGroup2(false)} >
								<>{_("admin-acl-delete")}
									<b>{this.state.selgroup.name}</b></>
							</Button>
							<Button theme="blue"
								onClick={() => this.DeleteGroup2(false)} >
								{_("admin-acl-close-delete")}
							</Button>
						</> : <div style={{ height: "40px" }}><BP.Spinner size={30} /></div>}
					</div>
				</div>
			</BP.Dialog>
			<BP.Dialog icon="horizontal-bar-chart" title={_("admin-acl-listnetid")} isOpen={this.state.netidopen}
				canOutsideClickClose canEscapeKeyClose onClose={this.CloseNetIds}
				style={{ width: "800px", height: "400px" }}>
				<div className={BP.Classes.DIALOG_BODY}>
					<div className="e5line-10">
						<BP.TextArea value={this.state.selgroup.listnetid} />
					</div>
				</div>
			</BP.Dialog>
		</div>;
	}


	// ---------------- EVENTS ----------------

	//E5
	onCloseRemoveAclGroupsModal = () => {
		this.setState({ isRemoveAclModalOpen: false })
	};

	LaunchSearch = (): void => E5RequestACL.Ins().LoadGroups((status: E5RequestStatus): void => {
		if (!status.success) this.toasterref.current?.show({
			message: E5UtilI18n._("admin-acl-reqerror") + " : " + status.message, intent: BP.Intent.DANGER
		});
	});
	//E5
	FilterAll = ({ }) => {
		const fromDate = dayjs(this.state.fromDate).format('YYYY-MM-DD')
		const untilDate = dayjs(this.state.untilDate).format('YYYY-MM-DD')
		const name = this.state.filteredName;
		const updaterName = this.state.filteredUpdatedByName;
		const tableData = this.state.filtereddatanew;
		const filterByUpdaterName = updaterName ? tableData.filter(item => item.updatedbyuser.toLowerCase().includes(updaterName)) : tableData;
		const filterByName = name ? filterByUpdaterName.filter(item => item.name.toLowerCase().includes(name)) : filterByUpdaterName;
		const filterFromDate = filterByName.filter(({ creationdate }) => {
			return new Date(creationdate) > new Date(fromDate)
		});
		const filterUntilDate = filterFromDate.filter(({ creationdate }) => new Date(creationdate) < new Date(untilDate));
		this.setState({ filtereddata: filterUntilDate })

	}
	//E5
	DelFilters = (): void => this.setState({ filterinput: {} }, () => this.BuildFiltered({}));

	//E5
	ChangeSort = (sortfield: string): void => {
		let { up, down } = E5AclSortDir, sortdir: E5AclSortDir = up;
		if (this.state.sortfield === sortfield && this.state.sortdir === up) sortdir = down;
		this.setState({ sortfield, sortdir },
			() => this.BuildFiltered(undefined, sortfield, sortdir));
	};

	//E5
	ListDeleteGroup = (group: E5EntAclGroup): void => this.setState({ listdeleteopen: true, selgroup: group });

	//E5
	showResetFiltersButton = () => this.setState({ isAnyFilterActive: true });
	hideResetFiltersButton = () => this.setState({ isAnyFilterActive: false });
	onChangeFilterByUser = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!event.currentTarget.value) {
			this.setState({ filteredName: null, filterByNameInputValue: '' })
		} else {
			this.setState({
				filteredName: event.currentTarget.value,

				filterByNameInputValue: event.currentTarget.value
			})
			this.showResetFiltersButton()
		}
	};
	onChangeFilterByUpdatedUser = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!event.currentTarget.value) {
			this.setState({ filteredUpdatedByName: null, filterUpdatedByNameInputValue: '' })
		} else {
			this.setState({
				filteredUpdatedByName: event.currentTarget.value,
				filterUpdatedByNameInputValue: event.currentTarget.value
			})
			this.showResetFiltersButton()
		}

	};
	onChangeFilterByDateFrom = (date: any) => {
		const formattedDate = dayjs(date.$d).format('YYYY-MM-DD');
		this.setState({ fromDate: formattedDate })
		this.showResetFiltersButton()
	}
	onChangeFilterByDateUntil = (date: any) => {
		const formattedDate = dayjs(date.$d).format('YYYY-MM-DD');
		this.setState({ untilDate: formattedDate })
		this.showResetFiltersButton()
	}
	onResetFilters = () => {
		this.setState({
			filteredName: null,
			filteredUpdatedByName: null,
			fromDate: new Date(0).toUTCString(),
			untilDate: new Date().toString(),
			filterByNameInputValue: '',
			filterUpdatedByNameInputValue: '',
			isAnyFilterActive: false
		})
	}
	DeleteGroup = (): void => this.setState({ deleteopen: true });

	//E5
	DeleteGroup2 = async (frompopup: boolean): Promise<void> => {
		if (!E5StoreACL.Ins().aclinfo.status.loading)
			await E5RequestACL.Ins().DeleteGroup(this.state.selgroup.name, (status: E5RequestStatus) => {
				if (status.success) {
					if (frompopup) this.CloseGroup();
					this.CloseDelete();
				} else this.toasterref.current?.show({
					message: status.message,
					intent: BP.Intent.DANGER
				});
			});
	};
	DeleteGroups = async (groupsForRemoving: E5EntAclGroup[]) => {
		const names: string[] = [];
		for (let group of groupsForRemoving) {
			names.push(group.name);
			await E5RequestACL.Ins().DeleteGroup(group.name, (status: E5RequestStatus) => {
				if (status.success) {
				} else this.toasterref.current?.show({
					message: E5UtilI18n._("admin-acl-reqerror") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});
		};
		E5StoreACL.Ins().SetAclInfo({ success: true, loading: false, message: 'Groups was deleted' }, E5StoreACL.Ins().aclinfo.groups.filter(group => !names.includes(group.name)));
		this.onCloseRemoveAclGroupsModal();
		this.setState({ selectedAclGroupsNames: []});
	}
	//E5
	CloseDelete = (): void => this.setState({ listdeleteopen: false, deleteopen: false });

	//E5
	OpenNetIds = (group: E5EntAclGroup): void => {
		if (group.name !== "" && group.id === null) E5RequestACL.Ins().GetGroup(group.name, true,
			(status, group) => {
				if (status.success) this.setState({ netidopen: true, selgroup: group });
			}, null);
		else this.setState({ netidopen: true, selgroup: group });
	}

	//E5
	CloseNetIds = (): void => this.setState({ netidopen: false });

	//E5
	OpenGroup = (group: E5EntAclGroup): void => {
		if (group.name !== "" && group.id === null) E5RequestACL.Ins().GetGroup(group.name, true,
			(status, group) => {
				if (status.success) this.OpenGroup2(group);
			}, null);
		else this.OpenGroup2(group);
	}

	//E5
	OpenGroup2 = (group: E5EntAclGroup): void =>
		this.setState({ dialopen: true, editgroup: group, selgroup: group, editgroupchanged: false });

	//E5
	AddGroup = (): void => this.OpenGroup(new E5EntAclGroup());

	//E5
	CloseGroup = (): void => this.setState({ deleteopen: false },
		() => this.setState({ dialopen: false }));

	//E5
	EditGroup = (group: E5EntAclGroup): void => this.setState({ editgroup: group, editgroupchanged: true });

	//E5
	DuplicatedNetworksAcl() {
		let networkIds = this.state.editgroup.listnetid.split(/\r\n|\r|\n|,|;| /g)
		let duplicates = [];
		for (let i = 0; i < networkIds.length; i++) {
			if (networkIds.indexOf(networkIds[i]) !== networkIds.lastIndexOf(networkIds[i])) {
				if (networkIds[i] !== "") duplicates.push(networkIds[i])
			}
		}
		return duplicates
	}
	SaveGroup = (): void => {
		if (this.DuplicatedNetworksAcl().length > 0) {
			this.toasterref.current?.show({
				message: E5UtilI18n._("wificb-pops-updateok-withduplicates") + " : " + [...new Set(this.DuplicatedNetworksAcl())]
					+ "\n Duplicates will be auto removed and only uniques left",
				intent: BP.Intent.WARNING
			})
			let editedGroup = this.state.editgroup
			editedGroup.listnetid = [...new Set(editedGroup.listnetid.split(/\r\n|\r|\n|,|;| /g))].toString();
			this.setState({ editgroup: editedGroup })
		}
		if (this.state.selgroup.name === "")
			E5RequestACL.Ins().AddGroup(this.state.editgroup, (status: E5RequestStatus) => {
				if (status.success) this.CloseGroup();
				else this.toasterref.current?.show({
					message: E5UtilI18n._("admin-acl-reqerror") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});
		else E5RequestACL.Ins().UpdateGroup(this.state.selgroup.name, this.state.editgroup,
			(status: E5RequestStatus) => {
				if (status.success) this.CloseGroup();
				else this.toasterref.current?.show({
					message: E5UtilI18n._("admin-acl-reqerror") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});
	}

	// ---------------- UTILS ----------------

	//E5
	BuildFiltered = (activefilter?: any, sortfield?: string, sortdir?: E5AclSortDir) => {
		if (activefilter === undefined) activefilter = this.state.activefilter;
		if (sortfield === undefined) sortfield = this.state.sortfield;
		if (sortdir === undefined) sortdir = this.state.sortdir;
		let filtereddata: any[] = [];
		for (let group of E5StoreACL.Ins().aclinfo.groups) {
			let updatedateok: boolean = true;
			if (activefilter["updatedate"] !== undefined && group.updatedate !== null) {
				updatedateok = false;
				let date: Moment = group.updatedate, filtermin: Moment = activefilter["updatedate"].start,
					filtermax: Moment = activefilter["updatedate"].end;
				if (filtermin !== undefined && filtermax !== undefined)
					updatedateok = date.unix() >= filtermin.unix() && date.unix() <= filtermax.unix();
				else if (filtermin !== undefined) updatedateok = date.unix() >= filtermin.unix();
				else if (filtermax !== undefined) updatedateok = date.unix() <= filtermax.unix();
				else updatedateok = true;
			}

			let nameok: boolean = activefilter["name"] === undefined ||
				this.NormalizeSyntax(group.name).indexOf(activefilter["name"]) >= 0,
				defaultok: boolean = activefilter["default"] === undefined ||
					(group.default && activefilter["default"] === "admin-acl-true") ||
					(!group.default && activefilter["default"] === "admin-acl-false"),
				typeok: boolean = activefilter["type"] === undefined || group.type === activefilter["type"],
				updatedbyuserok: boolean = activefilter["updatedbyuser"] === undefined ||
					this.NormalizeSyntax(group.updatedbyuser).indexOf(activefilter["updatedbyuser"]) >= 0;

			if (updatedateok && nameok && defaultok && typeok && updatedbyuserok) filtereddata.push(group);
		}
		this.setState({ activefilter, sortfield, sortdir }, () => {
			filtereddata.sort(this.Compare);
			this.setState({ filtereddata })
			this.setState({ filtereddatanew: filtereddata })
		});
	};

	//E5
	CheckHasFilter = (): boolean => {
		let key: string, has: boolean = false;
		for (key in this.state.filterinput) if (this.state.filterinput.hasOwnProperty(key)) {
			let value = this.state.filterinput[key];
			if (value !== undefined) {
				has = true;
				break;
			}
		}
		return has;
	};

	//E5
	Compare = (va: E5EntAclGroup, vb: E5EntAclGroup): number => {
		let ret: number = 0, { sortfield, sortdir } = this.state;
		if (sortfield === "name" || sortfield === "type" || sortfield === "updatedbyuser")
			ret = va[sortfield].localeCompare(vb[sortfield]);
		else if (sortfield === "creationdate" && va.creationdate !== null && vb.creationdate !== null)
			ret = va.creationdate.unix().toString().localeCompare(vb.creationdate.unix().toString());
		else if (sortfield === "updatedate" && va.updatedate !== null && vb.updatedate !== null)
			ret = va.updatedate.unix().toString().localeCompare(vb.updatedate.unix().toString());
		else if (sortfield === "default")
			ret = (va.default ? "true" : "false").localeCompare(vb.default ? "true" : "false");
		if (sortdir === E5AclSortDir.down) ret = -ret;
		return ret;
	};

	//E5
	NormalizeSyntax = (val: string | undefined): any => {
		// normalize = sépare les caractères spéciaux (é => e')
		// replace = supprimer les caractères spéciaux
		if (val !== undefined)
			val = val.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		return val;
	};

	//E5
	CloneActiveFilter = (): any => Object.assign({}, this.state.activefilter);

	//E5
	CloneFilterInput = (): any => Object.assign({}, this.state.filterinput);

	//E5
	FormatDate = (date: Date): string => moment(date).format(E5UtilI18n._("dateformat"));

	//E5
	ParseDate = (str: string): Date => moment(str, E5UtilI18n._("dateformat")).toDate();
});
