import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHKpiSysProcStatus extends E5Entity {
	imei: string = "";
	statusmap: Map<string, number>|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);

		let key: string;
		this.statusmap = null;
		if (typeof json.processes_status === "object" && json.processes_status !== null) {
			this.statusmap = new Map();
			for (key in json.processes_status) if (json.processes_status.hasOwnProperty(key))
				this.statusmap.set(key, E5Entity.ParseInt(json.processes_status[key]));
		}
	}

	//E5
	CopyToJson(): any {
		let processes_status: any = null, key: string, val: number|null;
		if (this.statusmap instanceof Map) for ([key, val] of this.statusmap) {
			if (processes_status === null) processes_status = {};
			processes_status[key] = val;
		}

		return {node_imei: this.imei, processes_status};
	}
}
