import moment, {Moment} from "moment";
//
import {E5Text} from "../../util/E5Text";
import {E5Entity} from "../E5Entity";

//E5
export enum E5AdminRdmSortDir {
	up = "asc",
	down = "desc"
}

//E5
export class E5EntRdmCommand extends E5Entity {

	//E5
	uuid: string = "";
	customerid: string = "";
	createtime: Moment = moment();
	updatetime: Moment = moment();
	expiretime: Moment = moment();
	userlogin: string = "";
	requestid: number = 0;
	firmware: string = "";
	model: string = "";
	networkid: string = "";
	nodeimei: string = "";
	ttlid: number = 0;
	statusid: number = 0;
	statusreason: string = "";
	response: string = "";
	comment: string = "";
	// not stored
	timezone: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	Copy(json: any): void {
		this.uuid = E5Entity.AssignString(json.uuid);
		this.customerid = E5Entity.AssignString(json.customerid);
		this.createtime = E5Text.Tm2MomentTzStr(E5Entity.ParseInt(json.creationtime), this.timezone);
		this.updatetime = E5Text.Tm2MomentTzStr(E5Entity.ParseInt(json.lastUpdatetime), this.timezone);
		this.expiretime = E5Text.Tm2MomentTzStr(E5Entity.ParseInt(json.expirationtime), this.timezone);
		this.userlogin = E5Entity.AssignString(json.userlogin);
		this.requestid = E5Entity.ParseInt(json.requestid);
		this.firmware = E5Entity.AssignString(json.firmware);
		this.model = E5Entity.AssignString(json.model);
		this.networkid = E5Entity.AssignString(json.networkid);
		this.nodeimei = E5Entity.AssignString(json.nodeimei);
		this.ttlid = E5Entity.ParseInt(json.ttlid);
		this.statusid = E5Entity.ParseInt(json.statusid);
		this.statusreason = E5Entity.AssignString(json.statusreason);
		this.response = E5Entity.AssignString(json.response);
		this.comment = E5Entity.AssignString(json.comment);
	}

	//E5
	CopyFromJson(json: any): void {
		this.uuid = E5Entity.AssignString(json.requestUuid);
		this.customerid = E5Entity.AssignString(json.customerId);
		this.createtime = E5Text.Tm2MomentTzStr(E5Entity.ParseInt(json.creationTime), this.timezone);
		this.updatetime = E5Text.Tm2MomentTzStr(E5Entity.ParseInt(json.lastUpdateTime), this.timezone);
		this.expiretime = E5Text.Tm2MomentTzStr(E5Entity.ParseInt(json.expirationTime), this.timezone);
		this.userlogin = E5Entity.AssignString(json.loginId);
		this.requestid = E5Entity.ParseInt(json.requestId);
		this.firmware = E5Entity.AssignString(json.firmwareId);
		this.model = E5Entity.AssignString(json.modelId);
		this.networkid = E5Entity.AssignString(json.networkId);
		this.nodeimei = E5Entity.AssignString(json.deviceId);
		this.ttlid = E5Entity.ParseInt(json.ttlId);
		this.statusid = E5Entity.ParseInt(json.requestStatusId);
		this.statusreason = E5Entity.AssignString(json.requestStatusReason);
		this.response = E5Entity.AssignString(json.requestResult);
		this.comment = E5Entity.AssignString(json.comment);
	}

	//E5
	CopyToJson(): any {
		return {
			requestUuid: this.uuid, customerId: this.customerid, creationTime: this.createtime.unix(),
			lastUpdateTime: this.updatetime.unix(), expirationTime: this.expiretime.unix(), loginId: this.userlogin,
			firmwareId: this.firmware, modelId: this.model, networkId: this.networkid, deviceId: this.nodeimei,
			requestId: this.requestid, ttlId: this.ttlid, requestStatusId: this.statusid,
			requestStatusReason: this.statusreason, requestResult: this.response, comment: this.comment
		};
	}

	//E5
	CopyToCreate(): any {
		return {
			customerId: this.customerid, loginId: this.userlogin, firmwareId: this.firmware, modelId: this.model,
			networkId: this.networkid, deviceId: this.nodeimei, requestId: this.requestid, ttlId: this.ttlid,
			comment: this.comment
		};
	}

	//E5
	// sortfield is one of the RdmCommand members
	static GetJavaSortField(sortfield: string): string {
		let javasortfield: string = "";
		switch (sortfield) {
			case "uuid":
				javasortfield = "requestUuid";
				break;
			case "firmware":
				javasortfield = "firmwareId";
				break;
			case "userlogin":
				javasortfield = "loginId";
				break;
			case "model":
				javasortfield = "modelId";
				break;
			case "nodeimei":
				javasortfield = "deviceId";
				break;
			case "networkid":
				javasortfield = "networkId";
				break;
			case "requestid":
				javasortfield = "requestId";
				break;
			case "createtime":
				javasortfield = "creationTime";
				break;
			case "statusid":
				javasortfield = "statusId";
				break;
		}
		return javasortfield;
	}
}
