import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
import * as BPT from "@blueprintjs/table";
//
import {E5StoreAdminRdmRequest, E5StoreAdminRdmStatus} from "../../../store/E5StoreAdmin";
import {E5EntRdmRequest} from "../../../entity/rdm/E5EntRdmRequest";
import {E5EntRdmCommand} from "../../../entity/rdm/E5EntRdmCommand";
import {E5EntRdmStatus} from "../../../entity/rdm/E5EntRdmStatus";
import {E5TreeView} from "../../../global/component/E5TreeView";
import {E5StoreLangInfo} from "../../../store/E5Store";
import {E5UtilI18n} from "../../../global/E5MainLang";
//
import "./E5AdminRdmResponse.css";

//E5
interface E5AdminRdmResponseState {}

//E5
interface E5AdminRdmResponseProps {
	langinfo: E5StoreLangInfo;
	statusinfo: E5StoreAdminRdmStatus;
	requestinfo: E5StoreAdminRdmRequest;
	cmd: E5EntRdmCommand;
}

//E5
export const E5AdminRdmResponse = observer(class E5AdminRdmResponse extends React.PureComponent
	<E5AdminRdmResponseProps, E5AdminRdmResponseState> {

	// ---------------- MEMBERS ----------------

	//E5
	wifiscanitems: any[] = [];

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		//
		this.wifiscanitems = [];
		let jsonbody: any = {};
		let txtbody: string = "";
		if (this.props.cmd.statusid === this.props.statusinfo.failureid ||
			this.props.cmd.statusid === this.props.statusinfo.timeoutid) {
			txtbody = this.props.cmd.response;
		} else if (this.props.cmd.requestid !== this.props.requestinfo.wifiscanid) {
			try {
				jsonbody = JSON.parse(this.props.cmd.response);
			} catch (ex) {
				jsonbody = {"error": "parsing error", "textualcontent": this.props.cmd.response};
			}
		} else {
			try {
				//txtcontent = JSON.stringify(JSON.parse(this.props.cmd.response), null, 2);
				let obj = JSON.parse(this.props.cmd.response);
				if (obj !== undefined && obj.wifiScanResult)
					this.wifiscanitems = obj.wifiScanResult;
			} catch (ex) {
			}
		}
		//
		// status
		let statusname: string = "";
		let status: E5EntRdmStatus|undefined = this.props.statusinfo.statusmap.get(this.props.cmd.statusid);
		if (status !== undefined) {
			statusname = status.name;
		}
		// created
		let created: string = this.props.cmd.createtime.format(E5UtilI18n._("dateformat") + " HH:mm");
		// request id
		let requestname: string = "";
		let req: E5EntRdmRequest|undefined = this.props.requestinfo.requestmap.get(this.props.cmd.requestid);
		if (req !== undefined)
			requestname = req.name;
		//
		return <div className="e5admin-rdm-response">
			<div className="e5admin-rdm-responseheader">
				<div className="">
					{requestname}
				</div>
				<div className="">
					{created}
				</div>
				<div className="">
					{statusname}
				</div>
				<div className="">
					{"(" + this.props.cmd.uuid + ")"}
				</div>
			</div>
			{(this.props.cmd.statusid === this.props.statusinfo.failureid ||
				this.props.cmd.statusid === this.props.statusinfo.timeoutid) &&
			<BP.TextArea value={txtbody}/>
			}
			{((this.props.cmd.statusid !== this.props.statusinfo.failureid &&
				this.props.cmd.statusid !== this.props.statusinfo.timeoutid) &&
				this.props.cmd.requestid !== this.props.requestinfo.wifiscanid) &&
			<E5TreeView label="" value={jsonbody} isinobject={false}/>
			}
			{((this.props.cmd.statusid !== this.props.statusinfo.failureid &&
				this.props.cmd.statusid !== this.props.statusinfo.timeoutid) &&
				this.props.cmd.requestid === this.props.requestinfo.wifiscanid) &&
			<BPT.Table
				className="e5admin-rdm-responsetable"
				numRows={this.wifiscanitems.length}
				numFrozenRows={0}
				selectionModes={BPT.SelectionModes.ROWS_AND_CELLS}
				enableRowHeader={false}
				columnWidths={[
					150, // bssid
					60,  // channel
					50,  // mode
					100,  // radio
					50,  // rssi
					70,  // signalStrength
					250  // ssid
				]}
			>
				<BPT.Column
					cellRenderer={(ridx) => {
						return <BPT.Cell>{this.wifiscanitems[ridx].bssid}</BPT.Cell>;
					}}
					columnHeaderCellRenderer={() => {
						return <div className="tbhead">bssid</div>;
					}}
				/>
				<BPT.Column
					cellRenderer={(ridx) => {
						return <BPT.Cell>{this.wifiscanitems[ridx].channel}</BPT.Cell>;
					}}
					columnHeaderCellRenderer={() => {
						return <div className="tbhead">channel</div>;
					}}
				/>
				<BPT.Column
					cellRenderer={(ridx) => {
						return <BPT.Cell>{this.wifiscanitems[ridx].mode}</BPT.Cell>;
					}}
					columnHeaderCellRenderer={() => {
						return <div className="tbhead">mode</div>;
					}}
				/>
				<BPT.Column
					cellRenderer={(ridx) => {
						return <BPT.Cell>{this.wifiscanitems[ridx].radio}</BPT.Cell>;
					}}
					columnHeaderCellRenderer={() => {
						return <div className="tbhead">radio</div>;
					}}
				/>
				<BPT.Column
					cellRenderer={(ridx) => {
						return <BPT.Cell>{this.wifiscanitems[ridx].rssi}</BPT.Cell>;
					}}
					columnHeaderCellRenderer={() => {
						return <div className="tbhead">rssi</div>;
					}}
				/>
				<BPT.Column
					cellRenderer={(ridx) => {
						return <BPT.Cell>{this.wifiscanitems[ridx].signalStrength}</BPT.Cell>;
					}}
					columnHeaderCellRenderer={() => {
						return <div className="tbhead">strength</div>;
					}}
				/>
				<BPT.Column
					cellRenderer={(ridx) => {
						return <BPT.Cell>{this.wifiscanitems[ridx].ssid}</BPT.Cell>;
					}}
					columnHeaderCellRenderer={() => {
						return <div className="tbhead">ssid</div>;
					}}
				/>
			</BPT.Table>
			}
		</div>;
	}
});
