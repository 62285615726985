import moment, { Moment } from "moment";
//
import { E5Entity } from "../E5Entity";

//E5
export class E5CBPopCriteria {
	criteria: string | null = "";
	condition: string | null = "";
	value: string | null = "";

	//E5
	constructor(json?: any) {
		if (json !== undefined) {
			this.criteria = json.criteria;
			if (json.criteria === "null" || json.criteria === undefined) this.criteria = "";

			this.condition = json.condition;
			if (json.condition === "null" || json.condition === undefined) this.condition = "";

			this.value = json.value;
			if (json.value === "null" || json.value === undefined) this.value = "";
		}
	}
}

//E5
export class E5EntCBPop extends E5Entity {
	aclname: string = "";
	populationid: string = "";
	name: string = "";
	description: string = "";
	networkidcount: number | null = null;
	networkids: string[] | number | null = null;
	creationdate: Moment | null = null;
	criterias: E5CBPopCriteria[] | null = null;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.aclname = E5Entity.AssignString(json.aclGroupName);
		this.populationid = E5Entity.AssignString(json.populationID);
		this.name = E5Entity.AssignString(json.name);
		this.description = E5Entity.AssignString(json.description);
		this.networkidcount = E5Entity.ParseIntNull(json.totalNetwork);

		this.networkids = Array.isArray(json.networkIds) ? json.networkIds : E5Entity.ParseIntNull(json.networkIds);

		this.creationdate = null;
		if (typeof json.creationDate === "string" && json.creationDate !== "null" && json.creationDate !== "")
			this.creationdate = moment(json.creationDate);

		this.criterias = null;
		if (Array.isArray(json.criterias)) {
			this.criterias = [];
			let idx: number;
			for (idx = 0; idx < json.criterias.length; idx++)
				this.criterias.push(new E5CBPopCriteria(json.criterias[idx]));
		}
	}

	//E5
	CopyToJson(): any {
		let idx: number, creationDate: string | null = null;
		if (this.creationdate !== null) creationDate = this.creationdate.format().substr(0, 19);

		let criterias: any[] = [];
		if (this.criterias !== null) for (idx = 0; idx < this.criterias.length; idx++)
			criterias.push({
				criteria: this.criterias[idx].criteria, condition: this.criterias[idx].condition,
				value: this.criterias[idx].value
			});

		let networkIds: string[] | number | null = this.networkids !== null ? this.networkids : [];

		return {
			aclGroupName: this.aclname, populationID: this.populationid, name: this.name.trim(), description: this.description,
			totalNetwork: this.networkidcount, networkIds: networkIds, creationDate: creationDate, criterias: criterias
		};
	}
}
