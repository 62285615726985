import {Moment} from "moment";
//
import {E5Text} from "../../../util/E5Text";
import {E5Time} from "../../../util/E5Time";
import {E5Entity} from "../../E5Entity";

//E5
export interface E5HAnomSever {
	start: Moment;
	end: Moment;
	severity: number;
}

//E5
export class E5EntHAnom extends E5Entity {
	id: string = "";
	severs: E5HAnomSever[] = [];

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		let idx: number;
		this.id = E5Entity.AssignString(json.anomaly_id);

		this.severs = [];
		if (Array.isArray(json.severities_over_time)) for (idx = 0; idx < json.severities_over_time.length; idx++) {
			let sever: any = json.severities_over_time[idx];
			let start: Moment = E5Text.Tm2MomentTz(E5Entity.ParseInt(sever.bucket_start_time), E5Time.h_tzoffset);
			let end: Moment = E5Text.Tm2MomentTz(E5Entity.ParseInt(sever.bucket_end_time), E5Time.h_tzoffset);
			this.severs.push({start, end, severity: E5Entity.ParseFloat(sever.severity)});
		}
		this.severs.sort(E5EntHAnom.CompareAnom);
	}

	//E5
	CopyToJson(): any {
		return {
			anomaly_id: this.id, severities_over_time: this.severs.map(sever => ({
				bucket_start_time: sever.start.unix(), bucket_end_time: sever.end.unix(), severity: sever.severity
			}))
		};
	}

	//E5
	static CompareAnom = (t1: E5HAnomSever, t2: E5HAnomSever): number => t1.start.unix() - t2.start.unix();
}
