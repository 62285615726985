import { ReactComponent as Icon } from '../../assets/not-found.svg'
import { useHistory } from 'react-router'
import { E5Page, E5Path } from '../../global/E5MainStatics'
import './E5NotFoundPage.css';
import { E5UtilI18n } from '../../global/E5MainLang';

export default function E5NotFoundPage() {
    const history = useHistory()

    return (
        <div className='wildcard-routes'>
            <div className='wildcard-page-container'>
                <div className="wildcard-page-text-container">
                    <span className='wildcard-page-title'>{E5UtilI18n._("not-found-title")}</span>
                    <span className='wildcard-page-description mt-5'>{E5UtilI18n._("not-found-desc")}</span>

                    <div className="mt-5">
                        <button className='e5-primary-button' onClick={() => history.push(E5Path.Get(E5Page.HOME))}>{E5UtilI18n._("not-found-action")}</button>
                    </div>
                </div>

                <Icon className='wildcard-page-icon' />
            </div>
        </div>
    )
}