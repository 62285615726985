import React, { MouseEventHandler } from 'react';
import styles from './Button.module.css';



type ButtonProps = {
    onClick?: MouseEventHandler<HTMLDivElement>,
    theme: string,
    style?: object,
    children: | JSX.Element
    | JSX.Element[]
    | string
    | string[];
}

const Button = ({ onClick, theme, children, style }: ButtonProps) => {
    switch (theme) {
        case 'red':
            return <div style={style} className={styles.redButton} onClick={onClick}>
                {children}
            </div>
        case 'green':
            return <div style={style} className={styles.greenButton} onClick={onClick}>
                {children}
            </div>;
        case "blue":
            return <div style={style} className={styles.blueButton} onClick={onClick}>
                {children}
            </div>;
        default: return <div style={style} className={styles.blueButton} onClick={onClick}>
            {children}
        </div>;

    }
};
export default Button;