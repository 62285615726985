import moment from "moment";
import { E5Text } from "../../util/E5Text";
import { E5Time } from "../../util/E5Time";
import { E5Entity } from "../E5Entity";

interface E5EntHWanMetricJson {
    min: number;
    max: number;
    avg: number;
    time: number;
}

export class E5EntHWanMetric extends E5Entity {
    min: number = 0;
    max: number = 0;
    avg: number = 0;
    time = moment();

    //E5
    constructor(json?: E5EntHWanMetricJson) {
        super();
        if (json !== undefined) {
            this.CopyFromJson(json)
        };
    }

    //E5
    CopyFromJson(json: E5EntHWanMetricJson): void {
        this.min = json.min;
        this.max = json.max;
        this.avg = json.avg;
        this.time = E5Text.Tm2MomentTz(json.time, E5Time.h_tzoffset);
    }
}