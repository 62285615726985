import {E5Entity} from "../../E5Entity";

//E5
export interface E5SysProcDetail {
	cputime: number|null;
	memsize: number|null;
	memusage: number|null;
	name: string;
	pid: number|null;
	priority: number|null;
}

//E5
export class E5EntHIndSysProc extends E5Entity {
	imei: string = "";
	proccnt: number|null|undefined = undefined;
	procdetail: E5SysProcDetail[]|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);
		this.proccnt = E5Entity.ParseIntNull(json.process_count);

		let idx: number, det: any;
		this.procdetail = null;
		if (Array.isArray(json.process_details)) {
			this.procdetail = [];
			for (idx = 0; idx < json.process_details.length; idx++) {
				det = json.process_details[idx];
				this.procdetail.push({
					cputime: E5Entity.ParseIntNull(det.cpu_time), memsize: E5Entity.ParseIntNull(det.memory_size),
					memusage: E5Entity.ParseFloatNull(det.memory_usage), name: E5Entity.AssignString(det.name),
					pid: E5Entity.ParseIntNull(det.pid), priority: E5Entity.ParseIntNull(det.priority)
				});
			}
		}
	}

	//E5
	CopyToJson(): any {
		let process_details: any = null, idx: number;
		if (Array.isArray(this.procdetail)) {
			process_details = [];
			for (idx = 0; idx < this.procdetail.length; idx++) process_details.push({
				cpu_time: this.procdetail[idx].cputime, memory_size: this.procdetail[idx].memsize,
				memory_usage: this.procdetail[idx].memusage, name: this.procdetail[idx].name,
				pid: this.procdetail[idx].pid, priority: this.procdetail[idx].priority
			});
		}

		return {node_imei: this.imei, process_count: this.proccnt, process_details};
	}
}
