import React from "react";
import {observer} from "mobx-react";
//
import {E5Store} from "../../store/E5Store";
import {E5UtilI18n} from "../E5MainLang";
//
import "./E5PageTitle.css";

//E5
interface E5PageTitleState {}

//E5
interface E5PageTitleProps {
	titlekey: string;
}

//E5
export const E5PageTitle = observer(class E5PageTitle extends React.PureComponent<E5PageTitleProps, E5PageTitleState> {

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		return <div className="e5page-title">{E5UtilI18n._(this.props.titlekey)}</div>;
	}
});
