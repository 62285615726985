import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5AngularGauge} from "../../../global/plot/E5AngularGauge";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestAngularGauge.css";

//E5
interface E5TestAngularGaugeState {
	value: number;
	value2: number;
	value3: number;
}

//E5
interface E5TestAngularGaugeProps {}

//E5
export const E5TestAngularGauge = observer(class E5TestAngularGauge extends React.PureComponent
	<E5TestAngularGaugeProps, E5TestAngularGaugeState> {

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestAngularGaugeProps, state: E5TestAngularGaugeState) {
		super(props, state);
		this.state = {value: 0, value2: 0, value3: 0};
	}

	//E5
	componentDidMount() {
		this.Refresh();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		return <div className="e5testpage e5test-angular-gauge e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-angular-gauge-long")}</BP.H3>
			<BP.Callout title="Refresh">
				<BP.Button onClick={this.Refresh} icon="refresh"/>
			</BP.Callout>
			<div className="e5line-20">
				<E5AngularGauge gaugeinfo={
					{value: this.state.value, label: "Wi-Fi", title: "One angular gauge", loading: false}}/>
				<E5AngularGauge gaugeinfo={{
					value: this.state.value, label: "Wi-Fi", value2: this.state.value2, label2: "WAN",
					title: "Two angular gauges", loading: false
				}}/>
			</div>
			<div className="e5line-20">
				<E5AngularGauge gaugeinfo={{
					value: this.state.value, label: "Wi-Fi", value2: this.state.value2, label2: "WAN",
					value3: this.state.value3, label3: "System", title: "Three angular gauges", loading: false
				}}/>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Refresh = (): void => {
		this.setState({value: Math.random(), value2: Math.random(), value3: Math.random()});
	};
});
