import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import { IReactionDisposer, reaction } from "mobx";
//
import { E5StoreHTopoSelected, E5StoreHStations, E5StoreHEquips, E5StoreH } from "../../../store/E5StoreH";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5NetElementType } from "../../../entity/E5Enums";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5HStationList } from "../E5HStationList";
import { E5HInstability } from "./E5HInstability";
import { E5Store } from "../../../store/E5Store";
import { E5HEquipList } from "../E5HEquipList";
import { E5HFilters } from "../E5HFilters";
import { E5HHeatmap } from "./E5HHeatmap";
//
import "./E5HConnectivity.css";

//E5
interface E5HConnectivityState {
	onedevice: E5StoreHStations;
	onenode: E5StoreHEquips;
	mindictype: E5NetElementType;
	mindicwifi: number | undefined;
	mindiceth: number | undefined;
	mindicname: string;
	curtabid: BP.TabId;
}

//E5
interface E5HConnectivityProps {
	selectedinfo: E5StoreHTopoSelected;
}

//E5
export const E5HConnectivity = observer(class E5HConnectivity extends React.PureComponent
	<E5HConnectivityProps, E5HConnectivityState> {

	// ---------------- MEMBERS ----------------

	//E5
	stop_change_selected_item?: IReactionDisposer;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HConnectivityProps, state: E5HConnectivityState) {
		super(props, state);
		this.state = {
			onenode: { equips: [], equipmap: new Map() }, mindicname: "", mindiceth: undefined, mindicwifi: undefined,
			mindictype: E5NetElementType.none, onedevice: { stations: [], stationmap: new Map() },
			curtabid: "h-conn-topology"
		};
	}

	//E5
	componentDidMount(): void {
		this.BuildSelectedData();
		this.stop_change_selected_item = reaction(() => {
			let { type, equip, station } = this.props.selectedinfo;
			return type === E5NetElementType.node ? equip.imei : station.macaddress;
		}, () => this.BuildSelectedData());
	}

	//E5
	componentWillUnmount(): void {
		this.stop_change_selected_item?.();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { searchniinfo, equipinfo, stationinfo, connectivityinfo, selectedinfo } = E5StoreH.Ins();

		return <div className="e5page e5h-connectivity e5columnfull e5column-20">
			<div className="e5column-5">
				{/* <BP.Breadcrumbs items={[
					{icon: "bookmark", text: E5UtilI18n._("mainwifititle")},
					{text: E5UtilI18n._("mainmenu-household")},
					{text: E5UtilI18n._("mainmenu-h-connectivity")}
				]}/> */}
				<E5PageTitle titlekey="pagetitle-h-connectivity" />
			</div>
			<E5HFilters
				langinfo={E5Store.Ins().langinfo} searchniinfo={searchniinfo} pageinfo={E5Store.Ins().pageinfo}
				searchniautoinfo={E5StoreH.Ins().searchniautoinfo} />
			<div className="e5h-connectivity-content e5columnfull e5column-0">
				<div className="e5h-connectivity-components e5columnfull e5column-0">
					<BP.Tabs className="e5columnfull" onChange={this.TabChanged}>
						<BP.Tab title={E5UtilI18n._("wifih-topology-tab-instability")} id="h-conn-instability"
							panel={<div><E5HInstability langinfo={E5Store.Ins().langinfo} searchniinfo={searchniinfo}
								stationinfo={stationinfo} equipinfo={equipinfo}
								connectivityinfo={connectivityinfo} /></div>} />
						<BP.Tab title={E5UtilI18n._("wifih-topology-tab-heatmap")} id="h-conn-heatmap" panel={
							<E5HHeatmap langinfo={E5Store.Ins().langinfo} connectivityinfo={connectivityinfo}
								stationinfo={stationinfo} searchniinfo={searchniinfo} />} />
					</BP.Tabs>
				</div>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	TabChanged = (newtabid: BP.TabId, prevtabid: BP.TabId, evt: React.MouseEvent<HTMLElement>): void =>
		this.setState({ curtabid: newtabid });

	// ---------------- UTILS ----------------

	//E5
	BuildSelectedData(): void {
		let st: any = {
			onenode: { equips: [], equipmap: new Map() }, onedevice: { stations: [], stationmap: new Map() },
			mindictype: E5NetElementType.none, mindicname: "", mindiceth: undefined, mindicwifi: undefined
		};
		if (this.props.selectedinfo.type === E5NetElementType.node) {
			st.onenode.equips.push(this.props.selectedinfo.equip);
			st.onenode.equipmap.set(this.props.selectedinfo.equip.imei, this.props.selectedinfo.equip);
			st.mindictype = this.props.selectedinfo.type;
			st.mindicname = this.props.selectedinfo.equip.imei;
		}
		if (this.props.selectedinfo.type === E5NetElementType.device) {
			st.onedevice.stations.push(this.props.selectedinfo.station);
			st.onedevice.stationmap.set(this.props.selectedinfo.station.macaddress, this.props.selectedinfo.station);
			st.mindictype = this.props.selectedinfo.type;
			st.mindicname = this.props.selectedinfo.station.macaddress;
		}
		this.setState(st);
	}
});
