import moment, { Moment } from "moment";
//
import { E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender } from "../util/E5RestSender";
import { E5EntHKpiSysProcStatus } from "../entity/household/metric/E5EntHKpiSysProcStatus";
import { E5BandEnumIsEth, E5NetElementType, E5NodeTypeEnum } from "../entity/E5Enums";
import { E5EntHKpiSysProcMem } from "../entity/household/metric/E5EntHKpiSysProcMem";
import { E5EntHKpiSysProcCpu } from "../entity/household/metric/E5EntHKpiSysProcCpu";
import { E5EntHIncidSysFlash } from "../entity/household/incid/E5EntHIncidSysFlash";
import { E5EntHMetSysCpuMem } from "../entity/household/metric/E5EntHMetSysCpuMem";
import { E5EntHIndSysHealth } from "../entity/household/indic/E5EntHIndSysHealth";
import { E5EntHIndSysCpuMem } from "../entity/household/indic/E5EntHIndSysCpuMem";
import { E5EntHIncidSysConf } from "../entity/household/incid/E5EntHIncidSysConf";
import { E5EntHIncidSysProc } from "../entity/household/incid/E5EntHIncidSysProc";
import { E5EntHIncidSysTemp } from "../entity/household/incid/E5EntHIncidSysTemp";
import { E5EntHMetSysCrash } from "../entity/household/metric/E5EntHMetSysCrash";
import { E5EntHMetSysFlash } from "../entity/household/metric/E5EntHMetSysFlash";
import { E5EntHIndSysCrash } from "../entity/household/indic/E5EntHIndSysCrash";
import { E5EntHIndSysFlash } from "../entity/household/indic/E5EntHIndSysFlash";
import { E5EntHIncidSysCpu } from "../entity/household/incid/E5EntHIncidSysCpu";
import { E5EntHIncidSysMem } from "../entity/household/incid/E5EntHIncidSysMem";
import { E5EntHIncidSysReb } from "../entity/household/incid/E5EntHIncidSysReb";
import { E5EntHSummaryAnom } from "../entity/household/anom/E5EntHSummaryAnom";
import { E5EntHStationRssi } from "../entity/household/topo/E5EntHStationRssi";
import { E5EntHMetSysProc } from "../entity/household/metric/E5EntHMetSysProc";
import { E5EntHMetSysTemp } from "../entity/household/metric/E5EntHMetSysTemp";
import { E5StoreH, E5HHealthScores, E5EntHIncidAny, FingerprintResult, FingerprintResultResponse } from "../store/E5StoreH";
import { E5EntHIndSysProc } from "../entity/household/indic/E5EntHIndSysProc";
import { E5EntHIndSysTemp } from "../entity/household/indic/E5EntHIndSysTemp";
import { E5EntHKpiSysReb } from "../entity/household/metric/E5EntHKpiSysReb";
import { E5EntHMetSysReb } from "../entity/household/metric/E5EntHMetSysReb";
import { E5EntHIndSysReb } from "../entity/household/indic/E5EntHIndSysReb";
import { E5EntHIncidWifi } from "../entity/household/incid/E5EntHIncidWifi";
import { E5EntHIncidWan } from "../entity/household/incid/E5EntHIncidWan";
import { E5EntHAnomWifi } from "../entity/household/anom/E5EntHAnomWifi";
import { E5EntHTopology } from "../entity/household/topo/E5EntHTopology";
import { E5EntHMetWifi } from "../entity/household/metric/E5EntHMetWifi";
import { E5RequestCallback, E5RequestStatus } from "./E5ServiceCommon";
import { E5EntHAnomSys } from "../entity/household/anom/E5EntHAnomSys";
import { E5EntHStation } from "../entity/household/topo/E5EntHStation";
import { E5EntHWanBytes } from "../entity/household/E5EntHWanBytes";
import { E5EntHWifiTime } from "../entity/household/E5EntHWifiTime";
import { E5EntHEquip } from "../entity/household/topo/E5EntHEquip";
import { E5EntHAnom } from "../entity/household/anom/E5EntHAnom";
import { E5EntWifiChanTime } from "../entity/E5EntWifiChanTime";
import { E5EntRdmCommand } from "../entity/rdm/E5EntRdmCommand";
import { E5MainConfig } from "../global/E5MainConfig";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5RequestMeta } from "./E5RequestMeta";
import { E5Storage } from "../util/E5Storage";
import { E5Entity } from "../entity/E5Entity";
import { E5Request } from "./E5Request";
import { E5Text } from "../util/E5Text";
import { E5Time } from "../util/E5Time";
import { E5EntHEventLog } from "../entity/household/eventlog/E5EntHEventLog";
import { E5EntHActivityDevice } from "../entity/household/activity/E5EntHActivityDevices";
import { E5EntHEquipmentRdm, E5EntHEquipmentRDMState } from "../entity/household/equipment/E5EntHEquipmentRdm";
import { E5EntHWanMetric } from "../entity/household/E5EntHWanMetric";

//E5
export enum E5RequestHTags {
	NETWORK_IDS = "network-ids",
	INDIC_GLOBAL = "indic-global",
	INDIC_WIFI = "indic-wifi",
	INDIC_WAN = "indic-wan",
	INDIC_SYS = "indic-sys",
	ANOM_SUMMARY = "anom-summary",
	EQUIPS_RDM = "equips-rdm",
	EQUIPS_RDM_CREATE = "equips-rdm-create",
	INCIDENTS = "incidents",
	INCIDENT_ANOMALY = "incident-anomaly",
	CONNECTIVITY = "connectivity",
	METRIC_WIFI = "metric-wifi",
	METRIC_SYS = "metric-sys",
	METRIC_PROCESS = "metric-process",
	EVENTLOGS = "eventlogs",
	ACTIVITY_DEVICES_WEEK = "activity-week",
	ACTIVITY_DEVICES_DAY = "activity-day",
	EQUIPMENT_RDM = "equipment-rdm",
	NONE = ""
}
const ParseFigerprintResponse = (fingerprintresult: FingerprintResultResponse[]) => {
	const result: FingerprintResult[] = [];
	fingerprintresult?.forEach((item): FingerprintResult[] => {
		const formattedItem: Partial<FingerprintResult> = {};
		formattedItem.childId = item.child_id;
		formattedItem.confidence = item.confidence;
		formattedItem.deviceInfo = item.device_info;
		formattedItem.deviceType = item.device_type;
		formattedItem.networkId = item.network_id;
		formattedItem.vendor = item.vendor;
		result.push(formattedItem)

	})
	return result;
}
//E5
type MetricSysCommon = E5EntHMetSysCpuMem | E5EntHMetSysTemp | E5EntHMetSysFlash | E5EntHMetSysReb | E5EntHMetSysCrash | E5EntHMetSysProc;

//E5
export class E5RequestH implements E5RestResponder {

	// --------- STATIC -------------

	//E5
	private static ins: E5RequestH;
	public static timezoneposix: string = "";

	//E5
	static Ins(): E5RequestH {
		if (E5RequestH.ins === undefined)
			E5RequestH.ins = new E5RequestH();
		return E5RequestH.ins;
	}

	//E5
	static GetCurDate = (): string => E5StoreH.Ins().curdateinfo.curdate;

	// --------- INSTANCE -------------

	sender: E5RestSender;
	rdmsender: E5RestSender;
	tokenmap: Map<string, number>;

	//E5
	constructor() {
		this.sender = new E5RestSender(this, E5MainConfig.GetBackendUrl());
		this.rdmsender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v1/operator/request");
		this.tokenmap = new Map();
	}

	//E5
	RequestCallback(resp: E5RestResponse): void {
		let { requesttag } = resp.request.otherdata;
		if (requesttag === E5RequestHTags.NETWORK_IDS) this.NetworkIdsCB(resp);
		else if (requesttag === E5RequestHTags.INDIC_GLOBAL) this.IndicGlobalCB(resp);
		else if (requesttag === E5RequestHTags.INDIC_WIFI) this.IndicWifiCB(resp);
		else if (requesttag === E5RequestHTags.INDIC_WAN) this.IndicWanCB(resp);
		else if (requesttag === E5RequestHTags.INDIC_SYS) this.IndicSysCB(resp);
		else if (requesttag === E5RequestHTags.ANOM_SUMMARY) this.AnomSummaryCB(resp);
		else if (requesttag === E5RequestHTags.EQUIPS_RDM) this.EquipsRdmCB(resp);
		else if (requesttag === E5RequestHTags.EQUIPS_RDM_CREATE) this.EquipsRdmCreateCB(resp);
		else if (requesttag === E5RequestHTags.INCIDENTS) this.IncidentsCB(resp);
		else if (requesttag === E5RequestHTags.INCIDENT_ANOMALY) this.IncidentAnomalyCB(resp);
		else if (requesttag === E5RequestHTags.CONNECTIVITY) this.ConnectivityCB(resp);
		else if (requesttag === E5RequestHTags.METRIC_WIFI) this.MetricWifiCB(resp);
		else if (requesttag === E5RequestHTags.METRIC_SYS) this.MetricSysCB(resp);
		else if (requesttag === E5RequestHTags.METRIC_PROCESS) this.MetricProcessCB(resp);
		else if (requesttag === E5RequestHTags.EVENTLOGS) this.EventLogsCB(resp);
		else if (requesttag === E5RequestHTags.ACTIVITY_DEVICES_WEEK) this.ActivityDevicesCB(resp, "week");
		else if (requesttag === E5RequestHTags.ACTIVITY_DEVICES_DAY) this.ActivityDevicesCB(resp, "day");
		else if (requesttag === E5RequestHTags.EQUIPMENT_RDM) this.EquipmentRdmCB(resp);
	}

	//E5
	CancelTag = (tag: E5RequestHTags): void => this.sender.Cancel(this.tokenmap.get(tag));

	//E5
	ClearAll(networkid?: string): void {
		E5RequestH.timezoneposix = "";
		E5Time.h_tzoffset = moment().utcOffset() / 60;
		if (E5StoreH.Ins().searchniinfo?.networkid !== networkid) {
			E5StoreH.Ins().SetIncidents(false, new Map(), new Map(), "");
			E5StoreH.Ins().SetSelectedIncident(-1);
		}
		E5StoreH.Ins().SetSearchNiAuto({ loading: false, success: false, message: "" }, []);
		E5StoreH.Ins().SetSearchNi({ loading: false, success: false, message: "" });
		E5StoreH.Ins().SetNi("", undefined, undefined);
		E5StoreH.Ins().SetIndicGlobal(false, [], undefined, undefined, undefined,
			undefined, false, undefined, undefined, undefined,
			undefined, undefined);
		E5StoreH.Ins().SetTopoItems([], [], false);
		E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.none, new E5EntHEquip(), new E5EntHStation());
		E5StoreH.Ins().SetIndicWifi({ loading: false, success: false, message: "" }, undefined,
			undefined, [], []);
		E5StoreH.Ins().SetIndicWan({ loading: false, success: false, message: "" }, [], "",
			"", "", [], [], undefined, undefined, undefined, undefined);
		E5StoreH.Ins().SetIndicSys({ loading: false, success: false, message: "" }, [], [], [],
			[], [], [], []);
		E5StoreH.Ins().SetAnomSummary({ loading: false, success: false, message: "" }, []);
		E5StoreH.Ins().SetAnoms({ loading: false, success: false, message: "" }, []);
		E5StoreH.Ins().SetConnectivity(false, [], []);
		E5StoreH.Ins().SetEventLogs(false, []);
		E5StoreH.Ins().SetMetricWifi(false, []);
		E5StoreH.Ins().SetMetricSys(false, [], [], [], [], [],
			[], [], [], [], []);
		E5StoreH.Ins().SetActivityDevice(false, [], [], [], [], [], [], "");
		E5StoreH.Ins().SetEquipmentRdm(false, []);

		E5RequestH.Ins().CancelTag(E5RequestHTags.INDIC_GLOBAL);
		E5RequestH.Ins().CancelTag(E5RequestHTags.INDIC_WIFI);
		E5RequestH.Ins().CancelTag(E5RequestHTags.INDIC_WAN);
		E5RequestH.Ins().CancelTag(E5RequestHTags.INDIC_SYS);
		E5RequestH.Ins().CancelTag(E5RequestHTags.ANOM_SUMMARY);
		E5RequestH.Ins().CancelTag(E5RequestHTags.EQUIPS_RDM);
		E5RequestH.Ins().CancelTag(E5RequestHTags.EQUIPS_RDM_CREATE);
		E5RequestH.Ins().CancelTag(E5RequestHTags.INCIDENTS);
		E5RequestH.Ins().CancelTag(E5RequestHTags.INCIDENT_ANOMALY);
		E5RequestH.Ins().CancelTag(E5RequestHTags.CONNECTIVITY);
		E5RequestH.Ins().CancelTag(E5RequestHTags.EVENTLOGS);
		E5RequestH.Ins().CancelTag(E5RequestHTags.METRIC_WIFI);
		E5RequestH.Ins().CancelTag(E5RequestHTags.METRIC_SYS);
		E5RequestH.Ins().CancelTag(E5RequestHTags.ACTIVITY_DEVICES_WEEK);
		E5RequestH.Ins().CancelTag(E5RequestHTags.ACTIVITY_DEVICES_DAY);
		E5RequestH.Ins().CancelTag(E5RequestHTags.EQUIPMENT_RDM);
	}

	// --------- Network Ids -------------

	//E5
	FetchNetworkIds(networkid: string, callback: E5RequestCallback, max?: number, fromBeginning?: boolean): void {
		E5StoreH.Ins().SetSearchNiAuto({ loading: true, success: false, message: "" }, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.NETWORK_IDS));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/networkids/" + networkid +
				"/bydate/" + E5RequestH.GetCurDate() + "?max=" + max || E5MainConfig.GetMaxInList(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.NETWORK_IDS, callback }
		};
		if (fromBeginning) {
			req.uri = req.uri + "&fromBeginning=true"
		}
		this.tokenmap.set(E5RequestHTags.NETWORK_IDS, this.sender.Send(req));
	}

	//E5
	NetworkIdsCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("cmswifisearchnetids-notfound");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		let searchnetids: string[] = [];
		try {
			if (status.success) {
				searchnetids = resp.body.networkIds;
				E5StoreH.Ins().SetSearchNiAuto(status, searchnetids);
			} else E5StoreH.Ins().SetSearchNiAuto(status, []);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Indic Global -------------

	//E5
	FetchIndicGlobal(networkid: string, callback: E5RequestCallback): void {
		E5RequestH.Ins().ClearAll(networkid);
		E5StoreH.Ins().SetSearchNi({ loading: true, success: false, message: "" });
		E5StoreH.Ins().SetIndicGlobal(true, [], undefined, undefined, undefined,
			undefined, false, undefined, undefined, undefined,
			undefined, undefined);
		E5StoreH.Ins().SetTopoItems([], [], true);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.INDIC_GLOBAL));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/indicator/global/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.INDIC_GLOBAL, networkid, callback }
		};
		this.tokenmap.set(E5RequestHTags.INDIC_GLOBAL, this.sender.Send(req));
	}

	//E5
	IndicGlobalCB(resp: E5RestResponse): void {

		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		//
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-indic-global-notfound"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			E5StoreH.Ins().SetSearchNi(status);
			if (status.success) {
				E5RequestH.timezoneposix = "";
				E5Time.h_tzoffset = moment().utcOffset() / 60;
				let healths: E5HHealthScores[] = [], idx: number;
				if (Array.isArray(resp.body.healths)) for (idx = 6; idx >= 0; idx--) if (idx < resp.body.healths.length)
					healths.push({
						date: resp.body.healths[idx].date === null ? "" : resp.body.healths[idx].date,
						sys: E5Entity.ParseFloatNull(resp.body.healths[idx].system),
						wan: E5Entity.ParseFloatNull(resp.body.healths[idx].wan),
						wifi: E5Entity.ParseFloatNull(resp.body.healths[idx].wifi)
					});
				else healths.push({ date: "", sys: null, wan: null, wifi: null });

				let wifihealth: number | null = E5Entity.ParseFloatNull(resp.body.wifi_health);
				let wanhealth: number | null = E5Entity.ParseFloatNull(resp.body.wan_health);
				let syshealth: number | null = E5Entity.ParseFloatNull(resp.body.system_health);
				let globalhealth: number | null = E5Entity.ParseFloatNull(resp.body.global_health);
				let samplingperiod: number | null = E5Entity.ParseIntNull(resp.body.sampling_period);
				let sendingperiod: number | null = E5Entity.ParseIntNull(resp.body.sending_period);
				let fingerprintresult: FingerprintResult[] = ParseFigerprintResponse(resp.body.fingerprint_results);
				let equips: E5EntHEquip[] = [], stations: E5EntHStation[] = [], ext: number = 0, stb: number = 0;
				let unsureextstb: boolean = false, datatime: Moment | undefined = undefined;

				if (Array.isArray(resp.body.nodes)) for (idx = 0; idx < resp.body.nodes.length; idx++) {
					let equip: E5EntHEquip = new E5EntHEquip(resp.body.nodes[idx]);
					if (equip.nodetype === E5NodeTypeEnum.none) unsureextstb = true;
					else if (equip.nodetype === E5NodeTypeEnum.ext) ext++;
					else if (equip.nodetype === E5NodeTypeEnum.stb) stb++;
					equips.push(equip);
					if (equip.nodetype === E5NodeTypeEnum.gw) {
						datatime = equip.lastseen ?? undefined;
						E5RequestH.timezoneposix = equip.posixtimezone;
						E5Time.h_tzoffset = E5Text.GetOffsetFromPosix(E5RequestH.timezoneposix,
							moment().unix());
					}
				}
				console.log(resp.body.devices)
				if (Array.isArray(resp.body.devices)) for (idx = 0; idx < resp.body.devices.length; idx++)
					stations.push(new E5EntHStation(resp.body.devices[idx]));

				E5StoreH.Ins().SetNi(resp.request.otherdata.networkid, moment(), datatime, E5RequestH.GetCurDate());
				E5StoreH.Ins().SetIndicGlobal(false, healths, wifihealth, wanhealth, syshealth, globalhealth,
					unsureextstb, ext, stb, stations.length, samplingperiod, sendingperiod, fingerprintresult);
				equips.sort(E5RequestH.CompareEquip);
				stations.sort(E5RequestH.CompareStation);
				E5StoreH.Ins().SetTopoItems(equips, stations, false);
			} else {
				E5StoreH.Ins().SetNi("", undefined, undefined, undefined);
				E5StoreH.Ins().SetIndicGlobal(false, [], undefined, undefined,
					undefined, undefined, false, undefined, undefined,
					undefined, undefined, undefined);
			}
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	}

	// --------- Indic Wifi -------------

	//E5
	FetchIndicWifi(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetIndicWifi({ loading: true, success: false, message: "" }, undefined,
			undefined, [], []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.INDIC_WIFI));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/indicator/wifi/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.INDIC_WIFI, networkid, callback }
		};
		this.tokenmap.set(E5RequestHTags.INDIC_WIFI, this.sender.Send(req));
	}

	//E5
	IndicWifiCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		//
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-indic-wifi-notfound"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			if (status.success) {
				let conntimeperdev: number = E5Entity.ParseInt(resp.body.average_connection_time_per_device);
				let totalconntime: number = E5Entity.ParseInt(resp.body.total_connection_time);

				let chanconntimes: E5EntWifiChanTime[] = [], wifitimes: E5EntHWifiTime[] = [], idx: number;

				if (Array.isArray(resp.body.channel_usage))
					for (idx = 0; idx < resp.body.channel_usage.length; idx++)
						chanconntimes.push(new E5EntWifiChanTime(resp.body.channel_usage[idx]));

				if (Array.isArray(resp.body.connection_times))
					for (idx = 0; idx < resp.body.connection_times.length; idx++)
						wifitimes.push(new E5EntHWifiTime(resp.body.connection_times[idx]));

				E5StoreH.Ins().SetIndicWifi(status, conntimeperdev, totalconntime, chanconntimes, wifitimes);
			} else E5StoreH.Ins().SetIndicWifi(status, undefined, undefined, [],
				[]);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Indic Wan -------------

	FetchIndicWan(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetIndicWan({ loading: true, success: false, message: "" }, [], "",
			"", "", [], [], undefined, undefined, undefined, undefined);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.INDIC_WAN));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/indicator/wan/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.INDIC_WAN, callback }
		};
		this.tokenmap.set(E5RequestHTags.INDIC_WAN, this.sender.Send(req));
	}

	//E5
	IndicWanCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			// this is when datascience have not yet added the field metric
			status.message = E5Text.Substitute(E5UtilI18n._("h-indic-wan-not-found"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			if (status.success) {
				let interfname: string = E5Entity.AssignString(resp.body.interface_name);
				let interftype: string = E5Entity.AssignString(resp.body.interface_type);
				let wanStatus: string = E5Entity.AssignString(resp.body.status);
				let totalbytesreceived: number = E5Entity.ParseInt(resp.body.total_bytes_received);
				let totalbytessent: number = E5Entity.ParseInt(resp.body.total_bytes_sent);

				let bytesovertime: E5EntHWanBytes[] = [], ipv4addrs: string[] = [], ipv6addrs: string[] = [];
				const dns: E5EntHWanMetric[] = [];
				const ping: E5EntHWanMetric[] = [];

				if (Array.isArray(resp.body.bytes))
					resp.body.bytes.forEach((json: any) => bytesovertime.push(new E5EntHWanBytes(json)));
				bytesovertime.sort(E5EntHWanBytes.CompareWanBytes);

				if (Array.isArray(resp.body.ipv4_addresses))
					resp.body.ipv4_addresses.forEach((json: any) => ipv4addrs.push(E5Entity.AssignString(json.ip)));

				if (Array.isArray(resp.body.ipv6_addresses))
					resp.body.ipv6_addresses.forEach((json: any) => ipv6addrs.push(E5Entity.AssignString(json.ip)));

				if (Array.isArray(resp.body.dns_and_ping_metrics)) {
					resp.body.dns_and_ping_metrics.forEach((metric: any) => {
						const dnsMetric = {
							min: metric.dns_min_time,
							max: metric.dns_max_time,
							avg: metric.dns_avg_time,
							time: metric.time
						};

						const pingMetric = {
							min: metric.ping_min_time,
							max: metric.ping_max_time,
							avg: metric.ping_avg_time,
							time: metric.time
						};

						dns.push(new E5EntHWanMetric(dnsMetric));
						ping.push(new E5EntHWanMetric(pingMetric));
					});
				}

				E5StoreH.Ins().SetIndicWan(status, bytesovertime, interfname, interftype, wanStatus, ipv4addrs,
					ipv6addrs, totalbytesreceived, totalbytessent, dns, ping);
			} else E5StoreH.Ins().SetWanStatus(status);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Indic Sys -------------

	FetchIndicSys(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetIndicSys({ loading: true, success: false, message: "" }, [], [], [],
			[], [], [], []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.INDIC_SYS));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v1/household/" + networkid + "/indicator/system/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.INDIC_SYS, callback }
		};
		this.tokenmap.set(E5RequestHTags.INDIC_SYS, this.sender.Send(req));
	}

	//E5
	IndicSysCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			// this is when datascience have not yet added the field metric
			status.message = E5Text.Substitute(E5UtilI18n._("h-indic-sys-not-found"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let idx: number, crash: E5EntHIndSysCrash[] = [], flash: E5EntHIndSysFlash[] = [];
			let health: E5EntHIndSysHealth[] = [], cpumem: E5EntHIndSysCpuMem[] = [];
			let process: E5EntHIndSysProc[] = [], reboot: E5EntHIndSysReb[] = [], temperature: E5EntHIndSysTemp[] = [];
			if (status.success) {
				if (Array.isArray(resp.body.crash_events_kpi))
					for (idx = 0; idx < resp.body.crash_events_kpi.length; idx++)
						crash.push(new E5EntHIndSysCrash(resp.body.crash_events_kpi[idx]));

				if (Array.isArray(resp.body.flash_memory_kpi))
					for (idx = 0; idx < resp.body.flash_memory_kpi.length; idx++)
						flash.push(new E5EntHIndSysFlash(resp.body.flash_memory_kpi[idx]));

				if (Array.isArray(resp.body.health_kpi)) for (idx = 0; idx < resp.body.health_kpi.length; idx++)
					health.push(new E5EntHIndSysHealth(resp.body.health_kpi[idx]));

				if (Array.isArray(resp.body.cpu_mem_kpi)) for (idx = 0; idx < resp.body.cpu_mem_kpi.length; idx++)
					cpumem.push(new E5EntHIndSysCpuMem(resp.body.cpu_mem_kpi[idx]));

				if (Array.isArray(resp.body.process_kpi)) for (idx = 0; idx < resp.body.process_kpi.length; idx++)
					process.push(new E5EntHIndSysProc(resp.body.process_kpi[idx]));

				if (Array.isArray(resp.body.reboot_kpi)) for (idx = 0; idx < resp.body.reboot_kpi.length; idx++)
					reboot.push(new E5EntHIndSysReb(resp.body.reboot_kpi[idx]));

				if (Array.isArray(resp.body.temperature_kpi))
					for (idx = 0; idx < resp.body.temperature_kpi.length; idx++)
						temperature.push(new E5EntHIndSysTemp(resp.body.temperature_kpi[idx]));
			}
			E5StoreH.Ins().SetIndicSys(status, crash, flash, health, cpumem, process, reboot, temperature);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Anom Summary -------------

	FetchAnomSummary(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetAnomSummary({ loading: true, success: false, message: "" }, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.ANOM_SUMMARY));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			uri: "v3/household/" + networkid + "/incident/critical/anomaly/bydate/" + E5RequestH.GetCurDate(),
			body: undefined, otherdata: { requesttag: E5RequestHTags.ANOM_SUMMARY, callback }
		};
		this.tokenmap.set(E5RequestHTags.ANOM_SUMMARY, this.sender.Send(req));
	}

	//E5
	AnomSummaryCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			// this is when datascience have not yet added the field metric
			status.message = E5Text.Substitute(E5UtilI18n._("h-indic-sys-not-found"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let anoms: E5EntHSummaryAnom[] = [], idx: number;
			if (status.success && Array.isArray(resp.body)) for (idx = 0; idx < resp.body.length; idx++)
				anoms.push(new E5EntHSummaryAnom(resp.body[idx]));
			E5StoreH.Ins().SetAnomSummary(status, anoms);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Equips Rdm -------------

	//E5
	FetchEquipsRdm(node: E5EntHEquip, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetEquipsRdm(true);
		this.rdmsender.Cancel(this.tokenmap.get(E5RequestHTags.EQUIPS_RDM));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "?orderField=creationTime&orderType=desc&deviceId=" + node.imei +
				"&networkId=" + E5StoreH.Ins().searchniinfo.networkid,
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.EQUIPS_RDM, imei: node.imei, callback }
		};
		this.tokenmap.set(E5RequestHTags.EQUIPS_RDM, this.rdmsender.Send(req));
	}

	//E5
	EquipsRdmCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		//
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status !== 200 && resp.status !== 404) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let statusmap: Map<string, Map<number, number>> = new Map(E5StoreH.Ins().equiprdminfo.statusmap);
			let lastcmdsmap: Map<string, E5EntRdmCommand[]> = new Map(E5StoreH.Ins().equiprdminfo.lastcmdsmap);
			if (status.success) {
				let idx: number, nodestatusmap: Map<number, number> = new Map(), num: number | undefined;
				let cmds: E5EntRdmCommand[] = [], cmd: E5EntRdmCommand;
				if (Array.isArray(resp.body)) {
					for (idx = 0; idx < resp.body.length; idx++) {
						cmd = new E5EntRdmCommand(resp.body[idx]);
						num = nodestatusmap.get(cmd.statusid);
						if (num === undefined) nodestatusmap.set(cmd.statusid, 1);
						else nodestatusmap.set(cmd.statusid, num + 1);
						if (idx < 5) cmds.push(cmd);
					}
					statusmap.set(resp.request.otherdata.imei, nodestatusmap);
					lastcmdsmap.set(resp.request.otherdata.imei, cmds);
				}
			}
			E5StoreH.Ins().SetEquipsRdm(false, statusmap, lastcmdsmap);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Equips Rdm Create -------------

	//E5
	FetchEquipsRdmCreate(cmd: E5EntRdmCommand, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetEquipsRdm(true);
		this.rdmsender.Cancel(this.tokenmap.get(E5RequestHTags.EQUIPS_RDM_CREATE));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "POST", uri: "", header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: cmd.CopyToCreate(), otherdata: { requesttag: E5RequestHTags.EQUIPS_RDM_CREATE, callback }
		};
		this.tokenmap.set(E5RequestHTags.EQUIPS_RDM_CREATE, this.rdmsender.Send(req));
	}

	//E5
	EquipsRdmCreateCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		//
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status !== 202) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			E5StoreH.Ins().SetEquipsRdm(false);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Incidents -------------

	//E5
	FetchWeekIncidents(netid: string, callback: E5RequestCallback): void {
		let udf: undefined = undefined, no: boolean = false;
		E5RequestH.FetchIncidents(netid, 0, udf, true, () =>
			E5RequestH.FetchIncidents(netid, -1, udf, no, () =>
				E5RequestH.FetchIncidents(netid, -2, udf, no, () =>
					E5RequestH.FetchIncidents(netid, -3, udf, no, () =>
						E5RequestH.FetchIncidents(netid, -4, udf, no, () =>
							E5RequestH.FetchIncidents(netid, -5, udf, no, () =>
								E5RequestH.FetchIncidents(netid, -6, udf, no, (status) => {
									E5StoreH.Ins().SetIncidents(false, null, null,
										null);
									callback?.(status);
								})))))));
	}

	//E5
	static DateOrYesterday(date: string): Moment {
		let today: Moment = moment(), datemoment: Moment = moment(date);
		return today.format("YYYY-MM-DD") === datemoment.format("YYYY-MM-DD") ?
			today.subtract(1, "day") : datemoment;
	}

	//E5
	static FetchIncidents(networkid: string, dayrank: number, date: string | undefined, reset: boolean,
		callback: E5RequestCallback): void {
		if (reset) E5StoreH.Ins().SetIncidents(true, new Map(), new Map(), "");
		E5StoreH.Ins().SetSearchNi({ loading: true, success: false, message: "" });
		E5RequestH.Ins().sender.Cancel(E5RequestH.Ins().tokenmap.get(E5RequestHTags.INCIDENTS));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let incdate: Moment;

		if (date === undefined) {
			incdate = moment().subtract(1, "day");
			dayrank = Math.round(dayrank);
			if (dayrank > 0) dayrank = 0;
			else if (dayrank < -6) dayrank = -6;
			if (dayrank < 0) incdate.add(dayrank, "day");
		} else incdate = this.DateOrYesterday(date);

		let reqdatestr: string = incdate.format("YYYY-MM-DD");
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/incident/bydate/" + reqdatestr,
			header: [["Content-Type", "application/json"], ["Authorization", bearer]], body: undefined, otherdata: {
				requesttag: E5RequestHTags.INCIDENTS, callback, networkid, reqdatestr,
				incidentdatestr: incdate.format(E5UtilI18n._("dateformat"))
			}
		};
		E5RequestH.Ins().tokenmap.set(E5RequestHTags.INCIDENTS, E5RequestH.Ins().sender.Send(req));
	}

	//E5
	IncidentsCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			// this is when datascience have not yet added the field incident
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-incident-notyet"),
				[resp.request.otherdata.incidentdatestr]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let incs: E5EntHIncidAny[] = [], idx: number, inc: any, id: any;
			if (status.success) {
				for (idx = 0; idx < resp.body.length; idx++) {
					inc = resp.body[idx];
					id = inc.incident_id ?? "";
					inc.reqdatestr = resp.request.otherdata.reqdatestr;
					if (id.startsWith(E5RequestMeta.h_inc_wifi_nb)) incs.push(new E5EntHIncidWifi(inc));
					else if (id.startsWith(E5RequestMeta.h_inc_wan_nb)) incs.push(new E5EntHIncidWan(inc));
					else if (id.startsWith(E5RequestMeta.h_inc_sys_reb_nb)) incs.push(new E5EntHIncidSysReb(inc));
					else if (id.startsWith(E5RequestMeta.h_inc_sys_cpu_nb)) incs.push(new E5EntHIncidSysCpu(inc));
					else if (id.startsWith(E5RequestMeta.h_inc_sys_mem_nb)) incs.push(new E5EntHIncidSysMem(inc));
					else if (id.startsWith(E5RequestMeta.h_inc_sys_proc_nb)) incs.push(new E5EntHIncidSysProc(inc));
					else if (id.startsWith(E5RequestMeta.h_inc_sys_flash_nb)) incs.push(new E5EntHIncidSysFlash(inc));
					else if (id.startsWith(E5RequestMeta.h_inc_sys_temp_nb)) incs.push(new E5EntHIncidSysTemp(inc));
				}
				if (incs.length === 0) status.message = E5Text.Substitute(E5UtilI18n._("wifih-incident-notfound"),
					[resp.request.otherdata.incidentdatestr]);
				else incs.sort(E5RequestH.CompareIncident);
			}
			E5StoreH.Ins().SetSearchNi(status);
			let incidentmap: Map<string, E5EntHIncidAny[]> = new Map(E5StoreH.Ins().incidentinfo.incidentmap);
			incidentmap.set(resp.request.otherdata.reqdatestr, incs);
			let messagemap: Map<string, string> = new Map(E5StoreH.Ins().incidentinfo.messagemap);
			messagemap.set(resp.request.otherdata.reqdatestr, status.message);
			E5StoreH.Ins().SetIncidents(null, incidentmap, messagemap,
				E5StoreH.Ins().incidentinfo.seldatestr === "" && incs.length > 0 ?
					resp.request.otherdata.reqdatestr : null);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	}

	// --------- Incident Anomaly -------------

	//E5
	FetchIncidentAnomaly(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetAnoms({ loading: true, success: false, message: "" }, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.INCIDENT_ANOMALY));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/incident/anomaly/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.INCIDENT_ANOMALY, networkid, callback }
		};
		this.tokenmap.set(E5RequestHTags.INCIDENT_ANOMALY, this.sender.Send(req));
	}

	//E5
	IncidentAnomalyCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		//
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-incident-anomaly-notfound"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let anoms: E5EntHAnom[] = [], anom: any, id: any, idx: number, ent: E5EntHAnom | undefined = undefined;
			if (status.success) if (Array.isArray(resp.body)) for (idx = 0; idx < resp.body.length; idx++) {
				anom = resp.body[idx];
				id = anom.anomaly_id ?? "";
				if (id.startsWith(E5RequestMeta.h_anom_wifi_nb)) ent = new E5EntHAnomWifi(anom);
				else if (id.startsWith(E5RequestMeta.h_anom_wan_nb)) ent = new E5EntHAnom(anom);
				else if (id.startsWith(E5RequestMeta.h_anom_sys_nb)) ent = new E5EntHAnomSys(anom);
				if (ent !== undefined &&
					ent.severs.reduce((acc, cur) => acc + cur.severity, 0) > 0) anoms.push(ent);
				ent = undefined;
			}
			E5StoreH.Ins().SetAnoms(status, anoms);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Connectivity -------------

	//E5
	FetchConnectivity(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetSearchNi({ loading: true, success: false, message: E5StoreH.Ins().searchniinfo.status.message });
		E5StoreH.Ins().SetConnectivity(true, [], []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.CONNECTIVITY));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/connectivity/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.CONNECTIVITY, callback, networkid }
		};
		this.tokenmap.set(E5RequestHTags.CONNECTIVITY, this.sender.Send(req));
	}

	//E5
	ConnectivityCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: E5StoreH.Ins().searchniinfo.status.message };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-connectivity-notfound"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			if (status.success) {
				let idx: number, rssipersta: E5EntHStationRssi[] = [], topologies: E5EntHTopology[] = [];

				if (Array.isArray(resp.body.rssi_per_station))
					for (idx = 0; idx < resp.body.rssi_per_station.length; idx++)
						rssipersta.push(new E5EntHStationRssi(resp.body.rssi_per_station[idx]));

				if (Array.isArray(resp.body.topologies))
					for (idx = 0; idx < resp.body.topologies.length; idx++)
						topologies.push(new E5EntHTopology(resp.body.topologies[idx]));

				E5StoreH.Ins().SetConnectivity(false, rssipersta, topologies);
			}
			E5StoreH.Ins().SetSearchNi(status);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Eventlogs -------------

	//E5
	FetchEventLogs(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetSearchNi({ loading: true, success: false, message: "" });
		E5StoreH.Ins().SetEventLogs(true, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.EVENTLOGS));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/eventLogs/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.EVENTLOGS, callback, networkid }
		};
		this.tokenmap.set(E5RequestHTags.EVENTLOGS, this.sender.Send(req));
	}

	//E5
	EventLogsCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-connectivity-notfound"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			if (status.success) {
				let idx: number, events: E5EntHEventLog[] = [];
				for (idx = 0; idx < resp.body.eventLogs.length; idx++) {
					events.push(resp.body.eventLogs[idx]);
				}
				E5StoreH.Ins().SetEventLogs(false, events);
			}
			E5StoreH.Ins().SetSearchNi(status);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Metric Wifi -------------

	//E5
	FetchMetricWifi(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetSearchNi({ loading: true, success: false, message: E5StoreH.Ins().searchniinfo.status.message });
		E5StoreH.Ins().SetMetricWifi(true, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.METRIC_WIFI));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/metric/wifi/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.METRIC_WIFI, callback, networkid }
		};
		this.tokenmap.set(E5RequestHTags.METRIC_WIFI, this.sender.Send(req));
	}

	// --------- Network Weekly Activity -------------
	//E5
	FetchActivityDevices(networkid: string, callback: E5RequestCallback, timeframe: string): void {
		E5StoreH.Ins().SetActivityDevice(true, [], [], [], [], [], [], timeframe);
		const url = timeframe === "week" ?
			`v3/household/${networkid}/indicator/profile/bystartdate/${E5RequestH.GetDateWeekLater(moment().format('YYYY-MM-DD'))}/byenddate/${moment().format('YYYY-MM-DD')}?timeRangeMinuteLength=30` :
			`v3/household/${networkid}/indicator/profile/bydate/${E5RequestH.GetCurDate()}?timeRangeMinuteLength=30`;

		if (timeframe === "week") {
			this.sender.Cancel(this.tokenmap.get(E5RequestHTags.ACTIVITY_DEVICES_WEEK));
		} else {
			this.sender.Cancel(this.tokenmap.get(E5RequestHTags.ACTIVITY_DEVICES_DAY));
		}

		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: url,
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: timeframe === "week" ? E5RequestHTags.ACTIVITY_DEVICES_WEEK: E5RequestHTags.ACTIVITY_DEVICES_DAY, callback, networkid }
		};

		if (timeframe === "week") {
			this.tokenmap.set(E5RequestHTags.ACTIVITY_DEVICES_WEEK, this.sender.Send(req))
		} else {
			this.tokenmap.set(E5RequestHTags.ACTIVITY_DEVICES_DAY, this.sender.Send(req));
		}
	}

	// --------- Equipment -------------
	//E5
	FetchEquipmentRdm(callback?: E5RequestCallback): void {
		E5StoreH.Ins().SetEquipmentRdm(true, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.EQUIPMENT_RDM));
		const bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		const endpoint = `v1/operator/request?pageSize=1000&orderField=${E5EntRdmCommand.GetJavaSortField("createtime")}&orderType=desc`;
		const req: E5RestRequest = {
			method: "GET", uri: endpoint,
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.EQUIPMENT_RDM, callback }
		};
		this.tokenmap.set(E5RequestHTags.EQUIPMENT_RDM, this.sender.Send(req));
	}

	//E5
	MetricWifiCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: E5StoreH.Ins().searchniinfo.status.message };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			// this is when datascience have not yet added the field metric
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-metrics-wifi-notfound"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let metrics: E5EntHMetWifi[] = [], idx: number;
			if (status.success) {
				if (Array.isArray(resp.body)) for (idx = 0; idx < resp.body.length; idx++)
					metrics.push(new E5EntHMetWifi(resp.body[idx]));

				if (metrics.length === 0)
					status.message = E5Text.Substitute(E5UtilI18n._("wifih-metrics-wifi-notfound"),
						[E5StoreH.Ins().GetCurDateFormatted()]);
				else metrics.sort(E5RequestH.CompareMetricWifi);
			}
			E5StoreH.Ins().SetSearchNi(status);
			E5StoreH.Ins().SetMetricWifi(false, metrics);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Metric Sys -------------

	//E5
	FetchMetricProcess(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetSearchNi({ loading: true, success: false, message: E5StoreH.Ins().searchniinfo.status.message });
		E5StoreH.Ins().SetMetricProcess(true, [], []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.METRIC_PROCESS));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v3/household/" + networkid + "/metric/process/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.METRIC_PROCESS, callback, networkid }
		};
		this.tokenmap.set(E5RequestHTags.METRIC_PROCESS, this.sender.Send(req));
	}

	MetricProcessCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: E5StoreH.Ins().searchniinfo.status.message };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			// this is when datascience have not yet added the field metric
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-metrics-process-notfound"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let process: E5EntHMetSysProc[] = [];
			let rebootmet: E5EntHMetSysReb[] = []
			if (status.success) {
				process = resp.body?.processes.map((item: any) => new E5EntHMetSysProc(item))
				if (process?.length > 0) process.sort(E5RequestH.CompareMetricSys);
				rebootmet = resp.body?.reboots.map((item: any) => new E5EntHMetSysReb(item))
				if (rebootmet.length > 0) rebootmet.sort(E5RequestH.CompareMetricSys);
			}
			E5StoreH.Ins().SetSearchNi(status);
			E5StoreH.Ins().SetMetricProcess(false, process, rebootmet);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	//E5
	FetchMetricSys(networkid: string, callback: E5RequestCallback): void {
		E5StoreH.Ins().SetSearchNi({ loading: true, success: false, message: E5StoreH.Ins().searchniinfo.status.message });
		E5StoreH.Ins().SetMetricSys(true, [], [], [], [], [],
			[], [], [], [], []);
		this.sender.Cancel(this.tokenmap.get(E5RequestHTags.METRIC_SYS));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "v1/household/" + networkid + "/metric/system/bydate/" + E5RequestH.GetCurDate(),
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestHTags.METRIC_SYS, callback, networkid }
		};
		this.tokenmap.set(E5RequestHTags.METRIC_SYS, this.sender.Send(req));
	}

	//E5
	MetricSysCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: E5StoreH.Ins().searchniinfo.status.message };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 404) {
			// this is when datascience have not yet added the field metric
			status.message = E5Text.Substitute(E5UtilI18n._("wifih-metrics-sys-notfound"),
				[E5StoreH.Ins().GetCurDateFormatted()]);
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let idx: number, rebootkpi: E5EntHKpiSysReb[] = [], procmemusage: E5EntHKpiSysProcMem[] = [];
			let proccputime: E5EntHKpiSysProcCpu[] = [], procstatus: E5EntHKpiSysProcStatus[] = [];
			let rebootmet: E5EntHMetSysReb[] = [], cpumem: E5EntHMetSysCpuMem[] = [], crash: E5EntHMetSysCrash[] = [];
			let temperature: E5EntHMetSysTemp[] = [], flash: E5EntHMetSysFlash[] = [], process: E5EntHMetSysProc[] = [];
			if (status.success) {
				if (Array.isArray(resp.body.reboot_reason_source_kpi))
					for (idx = 0; idx < resp.body.reboot_reason_source_kpi.length; idx++)
						rebootkpi.push(new E5EntHKpiSysReb(resp.body.reboot_reason_source_kpi[idx]));

				if (Array.isArray(resp.body.processes_memory_usage_kpi))
					for (idx = 0; idx < resp.body.processes_memory_usage_kpi.length; idx++)
						procmemusage.push(new E5EntHKpiSysProcMem(resp.body.processes_memory_usage_kpi[idx]));

				if (Array.isArray(resp.body.processes_cpu_time_kpi))
					for (idx = 0; idx < resp.body.processes_cpu_time_kpi.length; idx++)
						proccputime.push(new E5EntHKpiSysProcCpu(resp.body.processes_cpu_time_kpi[idx]));

				if (Array.isArray(resp.body.processes_status_kpi))
					for (idx = 0; idx < resp.body.processes_status_kpi.length; idx++)
						procstatus.push(new E5EntHKpiSysProcStatus(resp.body.processes_status_kpi[idx]));

				if (Array.isArray(resp.body.reboot_metrics))
					for (idx = 0; idx < resp.body.reboot_metrics.length; idx++)
						rebootmet.push(new E5EntHMetSysReb(resp.body.reboot_metrics[idx]));
				if (rebootmet.length > 0) rebootmet.sort(E5RequestH.CompareMetricSys);

				if (Array.isArray(resp.body.cpu_memory_metrics))
					for (idx = 0; idx < resp.body.cpu_memory_metrics.length; idx++)
						cpumem.push(new E5EntHMetSysCpuMem(resp.body.cpu_memory_metrics[idx]));
				if (cpumem.length > 0) cpumem.sort(E5RequestH.CompareMetricSys);

				if (Array.isArray(resp.body.temperature_metrics))
					for (idx = 0; idx < resp.body.temperature_metrics.length; idx++)
						temperature.push(new E5EntHMetSysTemp(resp.body.temperature_metrics[idx]));
				if (temperature.length > 0) temperature.sort(E5RequestH.CompareMetricSys);

				if (Array.isArray(resp.body.flash_memory_metrics))
					for (idx = 0; idx < resp.body.flash_memory_metrics.length; idx++)
						flash.push(new E5EntHMetSysFlash(resp.body.flash_memory_metrics[idx]));
				if (flash.length > 0) flash.sort(E5RequestH.CompareMetricSys);

				if (Array.isArray(resp.body.crashes_events_metrics))
					for (idx = 0; idx < resp.body.crashes_events_metrics.length; idx++)
						crash.push(new E5EntHMetSysCrash(resp.body.crashes_events_metrics[idx]));
				if (crash.length > 0) crash.sort(E5RequestH.CompareMetricSys);

				if (Array.isArray(resp.body.processes_metrics))
					for (idx = 0; idx < resp.body.processes_metrics.length; idx++)
						process.push(new E5EntHMetSysProc(resp.body.processes_metrics[idx]));
				if (process.length > 0) process.sort(E5RequestH.CompareMetricSys);
			}
			E5StoreH.Ins().SetSearchNi(status);
			E5StoreH.Ins().SetMetricSys(false, rebootkpi, procmemusage, proccputime, procstatus, rebootmet,
				cpumem, temperature, flash, crash, process);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	//E5
	ActivityDevicesCB(resp: E5RestResponse, timeframe: string): void {
		let status: E5RequestStatus = { loading: false, success: false, message: E5StoreH.Ins().searchniinfo.status.message };
		switch (resp.status) {
			case 400:
				status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
				break;
			case 401:
				E5Request.Ins().SessionExpired();
				break;
			case 403:
				status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
				break;
			case 404:
				status.message = E5Text.Substitute(E5UtilI18n._("wifih-metrics-sys-notfound"), [E5StoreH.Ins().GetCurDateFormatted()]);
				break;
			case 502:
			case 503:
			case 504:
			case 0:
				status.message = E5UtilI18n._("servernotresponding");
				break;
			default:
				status.success = true;
		}

		try {
			const activityDetection: E5EntHActivityDevice[] = [];
			const deviceCountAll: E5EntHActivityDevice[] = [];
			const deviceCountActive: E5EntHActivityDevice[] = [];
			const mostActiveDevicesEthernet: E5EntHActivityDevice[] = [];
			const mostActiveDevicesWiFi: E5EntHActivityDevice[] = [];
			const mostConsumingDevices: E5EntHActivityDevice[] = [];

			if (status.success && Object.keys(resp.body).length > 0) {
				Object.keys(resp.body.activity_detection).forEach((key: string) => {
					activityDetection.push(new E5EntHActivityDevice(key, resp.body.activity_detection[key], true, timeframe === 'day'));
				});

				Object.keys(resp.body['device-counts-all']).forEach((key: string) => {
					deviceCountAll.push(new E5EntHActivityDevice(key, resp.body['device-counts-all'][key], false, true));
				});

				Object.keys(resp.body['device-counts-active']).forEach((key: string) => {
					deviceCountActive.push(new E5EntHActivityDevice(key, resp.body['device-counts-active'][key], false, true));
				});

				Object.keys(resp.body.most_active_devices_ethernet).forEach((key: string) => {
					mostActiveDevicesEthernet.push(new E5EntHActivityDevice(key, resp.body.most_active_devices_ethernet[key]));
				});

				Object.keys(resp.body.most_active_devices_wifi).forEach((key: string) => {
					mostActiveDevicesWiFi.push(new E5EntHActivityDevice(key, resp.body.most_active_devices_wifi[key]));
				});

				Object.keys(resp.body.most_consuming_devices).forEach((key: string) => {
					mostConsumingDevices.push(new E5EntHActivityDevice(key, resp.body.most_consuming_devices[key]));
				});
			}
			E5StoreH.Ins().SetSearchNi(status);
			E5StoreH.Ins().SetActivityDevice(false, activityDetection, deviceCountActive, deviceCountAll, 
				mostActiveDevicesEthernet, mostActiveDevicesWiFi, mostConsumingDevices, timeframe);
		} catch (ex) {
			// to avoid crashing the app
		}
	}

	// --------- Equipment -------------
	EquipmentRdmCB(resp: E5RestResponse): void {
		const status: E5RequestStatus = { loading: false, success: false, message: "" };
		switch (resp.status) {
			case 400:
				status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
				break;
			case 401:
				E5Request.Ins().SessionExpired();
				break;
			case 403:
				status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
				break;
			case 404:
				status.message = E5Text.Substitute(E5UtilI18n._("wifih-metrics-sys-notfound"), [E5StoreH.Ins().GetCurDateFormatted()]);
				break;
			case 502:
			case 503:
			case 504:
			case 0:
				status.message = E5UtilI18n._("servernotresponding");
				break;
			default:
				status.success = true;
		}

		try {
			const action: E5EntHEquipmentRdm[] = [];

			if (status.success && Array.isArray(resp.body)) {
				action.push(...resp.body.map((item: E5EntHEquipmentRDMState) => new E5EntHEquipmentRdm(item)));
			}

			E5StoreH.Ins().SetSearchNi(status);
			E5StoreH.Ins().SetEquipmentRdm(false, action);
		} catch (ex) {
			// to avoid crashing the app
		}
	}
	// --------- Utils -------------

	//E5
	static CompareMetricWifi = (m1: E5EntHMetWifi, m2: E5EntHMetWifi): number =>
		m1.starttime.unix() - m2.starttime.unix();

	//E5
	static CompareMetricSys = (m1: MetricSysCommon, m2: MetricSysCommon): number =>
		m1.starttime.unix() - m2.starttime.unix();

	//E5
	static CompareIncident = (i1: E5EntHIncidAny, i2: E5EntHIncidAny): number =>
		i1.reqdatestr.localeCompare(i2.reqdatestr) || i2.criticality - i1.criticality;

	//E5
	static CompareEquip(n1: E5EntHEquip, n2: E5EntHEquip): number {
		let ret: number = 0;
		if (n1.nodetype === E5NodeTypeEnum.gw) ret = -1;
		else if (n2.nodetype === E5NodeTypeEnum.gw) ret = 1;
		return ret;
	}

	//E5
	static CompareStation(d1: E5EntHStation, d2: E5EntHStation): number {
		let ret: number;
		if (E5BandEnumIsEth(d1.band) && !E5BandEnumIsEth(d2.band)) ret = 1;
		else ret = d1.band.localeCompare(d2.band);
		return ret;
	}

	//E5
	static GetDateWeekLater(date: string): string {
		return moment(date).subtract(6, "day").format("YYYY-MM-DD");
	}
}
