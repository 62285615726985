import {action, makeObservable, observable} from "mobx";
//
import {E5RequestStatus} from "../request/E5ServiceCommon";
import {E5EntAclGroup} from "../entity/E5EntAclGroup";

//E5
export interface E5StoreACLGroups {
	status: E5RequestStatus;
	groups: E5EntAclGroup[];
}

// --------- STORE ITSELF -------------

//E5
export class E5StoreACL {

	// --------- STATIC MEMBERS -------------

	//E5
	private static ins: E5StoreACL;

	//E5
	static Ins(): E5StoreACL {
		if (E5StoreACL.ins === undefined)
			E5StoreACL.ins = new E5StoreACL();
		return E5StoreACL.ins;
	}

	// --------- STATIC STORE MODIFICATION -------------

	//E5
	SetAclInfo(status: E5RequestStatus, groups: E5EntAclGroup[]) {
		E5StoreACL.Ins().aclinfo.status = status;
		E5StoreACL.Ins().aclinfo.groups = groups;
	}

	// --------- INSTANCE -------------

	aclinfo: E5StoreACLGroups = {status: {loading: false, success: false, message: ""}, groups: []};

	//E5
	private constructor() {
		makeObservable(this, {aclinfo: observable, SetAclInfo: action});
	}
}
