import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIndSysHealth extends E5Entity {
	cpuhealth: number|null|undefined = undefined;
	flashhealth: number|null|undefined = undefined;
	memhealth: number|null|undefined = undefined;
	prochealth: number|null|undefined = undefined;
	rebhealth: number|null|undefined = undefined;
	temphealth: number|null|undefined = undefined;
	imei: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.cpuhealth = E5Entity.ParseFloatNull(json.cpu_health);
		this.flashhealth = E5Entity.ParseFloatNull(json.flash_memory_health);
		this.memhealth = E5Entity.ParseFloatNull(json.memory_health);
		this.prochealth = E5Entity.ParseFloatNull(json.process_health);
		this.rebhealth = E5Entity.ParseFloatNull(json.reboot_health);
		this.temphealth = E5Entity.ParseFloatNull(json.temperature_health);
		this.imei = E5Entity.AssignString(json.node_imei);
	}

	//E5
	CopyToJson(): any {
		return {
			cpu_health: this.cpuhealth, flash_memory_health: this.flashhealth,
			memory_health: this.memhealth, process_health: this.prochealth, reboot_health: this.rebhealth,
			temperature_health: this.temphealth, node_imei: this.imei
		};
	}
}
