import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5StoreH, E5StoreHEquipsRdm, E5StoreHSearchNi} from "../../store/E5StoreH";
import {E5EntHEquip, E5EquipInterf} from "../../entity/household/topo/E5EntHEquip";
import {E5Store, E5StoreLangInfo, E5StoreUserInfo} from "../../store/E5Store";
import {E5AdminRdmReqLegend} from "../admin/rdm/E5AdminRdmReqLegend";
import {E5AdminRdmResponse} from "../admin/rdm/E5AdminRdmResponse";
import {E5RequestAdminRdm} from "../../request/E5RequestAdminRdm";
import {E5EntRdmCommand} from "../../entity/rdm/E5EntRdmCommand";
import {E5EntRdmRequest} from "../../entity/rdm/E5EntRdmRequest";
import {E5EntRdmStatus} from "../../entity/rdm/E5EntRdmStatus";
import {E5RequestStatus} from "../../request/E5ServiceCommon";
import {E5Page, E5Path} from "../../global/E5MainStatics";
import {E5EntRdmTtl} from "../../entity/rdm/E5EntRdmTtl";
import {E5MainConfig} from "../../global/E5MainConfig";
import {E5NodeTypeEnum} from "../../entity/E5Enums";
import {E5HEquipSys} from "./dashboard/E5HEquipSys";
import {E5RequestH} from "../../request/E5RequestH";
import {E5UtilI18n} from "../../global/E5MainLang";
import {
	E5StoreAdminRdmRequest, E5StoreAdminRdmStatus, E5StoreAdminRdmInfo, E5StoreAdminRdmTtl, E5StoreAdmin
} from "../../store/E5StoreAdmin";
//
import "./E5HEquipMore.css";

//E5
interface E5HEquipMoreState {
	cancelcmdopen: boolean;
	cmdresponseopen: boolean;
	curcmd: E5EntRdmCommand|undefined;
	newcmd: E5EntRdmCommand;
	detailsopen: Set<string>;
	systemopen: boolean;
	interfacesopen: boolean;
	managementopen: boolean;
}

//E5
interface E5HEquipMoreProps {
	requestinfo: E5StoreAdminRdmRequest;
	searchniinfo: E5StoreHSearchNi;
	nodesrdminfo: E5StoreHEquipsRdm;
	statusinfo: E5StoreAdminRdmStatus;
	rdminfo: E5StoreAdminRdmInfo;
	ttlinfo: E5StoreAdminRdmTtl;
	langinfo: E5StoreLangInfo;
	userinfo: E5StoreUserInfo;
	node: E5EntHEquip;
}

//E5
export const E5HEquipMore = observer(class E5HEquipMore extends React.PureComponent
	<E5HEquipMoreProps, E5HEquipMoreState> {

	// ---------------- MEMBERS ----------------

	//E5
	toasterref: React.RefObject<BP.Toaster>;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HEquipMoreProps, state: E5HEquipMoreState) {
		super(props, state);
		this.toasterref = React.createRef();
		let cmd: E5EntRdmCommand = this.GetNewCmd();
		cmd.ttlid = this.props.ttlinfo.ttlid30mn;
		this.state = {
			cancelcmdopen: false, cmdresponseopen: false, curcmd: undefined, newcmd: cmd, detailsopen: new Set(),
			systemopen: false, interfacesopen: false, managementopen: false
		};
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let interfacesjsx: JSX.Element[] = [], idx: number;
		for (idx = 0; idx < this.props.node.interfaces.length; idx++) {
			let itf: E5EquipInterf = this.props.node.interfaces[idx];
			let intclass: string = itf.status === "up" ? "up" : "down";

			interfacesjsx.push(<BP.Divider key={"itfdivider" + idx}/>);
			interfacesjsx.push(<div className={"e5line-0 interf " + intclass} key={"interface" + idx}>
				<div className="e5linefull">
					<div className="e5line-5">
						<BP.Button disabled small
								   text={E5UtilI18n._("wifih-health-desc-interface") + " " + (idx + 1)}/>
						<div className="inline">
							{E5UtilI18n._("wifih-health-desc-status")} :&nbsp;
							<span className="value">{itf.status}</span>
						</div>
					</div>
					<div className="datalist">
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{E5UtilI18n._("wifih-health-desc-macaddress")} :&nbsp;
								<span className="value">{itf.macaddress}</span>
							</div>
						</div>
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{E5UtilI18n._("wifih-health-desc-band")} :&nbsp;
								<span className="value">{E5UtilI18n._(itf.band)}</span>
							</div>
						</div>
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{E5UtilI18n._("wifih-health-desc-maxbitrate")} :&nbsp;
								<span className="value">{itf.maxbitrate} Mb/s</span>
							</div>
						</div>
					</div>
				</div>
				<div className="e5linefull">
					<div className="e5line-5">
						<BP.Button id={"" + idx} onClick={this.ToggleDetails} small
								   icon={this.state.detailsopen.has("" + idx) ? "minus" : "plus"}
								   text={<div>&nbsp;&nbsp;{E5UtilI18n._("wifih-health-desc-details")}</div>}/>
					</div>
					<BP.Collapse isOpen={this.state.detailsopen.has("" + idx)}>
						<div className="datalist">
							<div className="e5line-0">
								<div className="dot">&bull;</div>
								<div className="e5linefull">
									{E5UtilI18n._("wifih-health-desc-wifissid")} :&nbsp;
									<span className="value">{itf.wifissid}</span>
								</div>
							</div>
							<div className="e5line-0">
								<div className="dot">&bull;</div>
								<div className="e5linefull">
									<div className="subcat">
										{E5UtilI18n._("wifih-health-desc-standards")}</div>
									<div className="e5line-0">
										<div className="dot">&bull;</div>
										<div className="e5linefull">
											{E5UtilI18n._("wifih-health-desc-available")} :&nbsp;
											<span className="value">{E5HEquipMore.ToCsv(itf.wifistdavailable)}</span>
										</div>
									</div>
									<div className="e5line-0">
										<div className="dot">&bull;</div>
										<div className="e5linefull">
											{E5UtilI18n._("wifih-health-desc-in-use")} :&nbsp;
											<span className="value">{E5HEquipMore.ToCsv(itf.wifistdused)}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="e5line-0">
								<div className="dot">&bull;</div>
								<div className="e5linefull">
									<div className="subcat">
										{E5UtilI18n._("wifih-health-desc-curbandwidth")}
									</div>
									<div className="e5line-0">
										<div className="dot">&bull;</div>
										<div className="e5linefull">
											{E5UtilI18n._("wifih-health-desc-supported")} :&nbsp;
											<span className="value">{E5HEquipMore.ToCsv(itf.supportbandwidth)}</span>
										</div>
									</div>
									<div className="e5line-0">
										<div className="dot">&bull;</div>
										<div className="e5linefull">
											{E5UtilI18n._("wifih-health-desc-current")} :&nbsp;
											<span className="value">{itf.curbandwidth}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="e5line-0">
								<div className="dot">&bull;</div>
								<div className="e5linefull">
									<div className="subcat">
										{E5UtilI18n._("wifih-health-desc-channels")}
									</div>
									<div className="e5line-0">
										<div className="dot">&bull;</div>
										<div className="e5linefull">
											{E5UtilI18n._("wifih-health-desc-available")} :&nbsp;
											<span className="value">{E5HEquipMore.ToCsv(itf.possiblechannels)}</span>
										</div>
									</div>
									<div className="e5line-0">
										<div className="dot">&bull;</div>
										<div className="e5linefull">
											{E5UtilI18n._("wifih-health-desc-in-use")} :&nbsp;
											<span className="value">{E5HEquipMore.ToCsv(itf.channelsinuse)}</span>
										</div>
									</div>
									<div className="e5line-0">
										<div className="dot">&bull;</div>
										<div className="e5linefull">
											{E5UtilI18n._("wifih-health-desc-autochan")} :&nbsp;
											<span className="value">{"" + itf.autochanenable}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</BP.Collapse>
				</div>
			</div>);
		}

		let rdmstatusjsx: JSX.Element[] = [], statusid: number, status: E5EntRdmStatus|undefined, num: number,
			nodestatusmap: Map<number, number>|undefined = this.props.nodesrdminfo.statusmap.get(this.props.node.imei);
		if (nodestatusmap !== undefined) {
			idx = 0;
			for ([statusid, num] of nodestatusmap) {
				idx++;
				status = this.props.statusinfo.statusmap.get(statusid);
				if (status !== undefined)
					rdmstatusjsx.push(<div className="rdmstatus" key={"rdmstatus" + idx}>
						<b>{num} </b>
						{rdmstatusjsx.length === 0 ? E5UtilI18n._("wifih-health-desc-rdmaction") + " " : ""}
						{status.name}
						{idx < nodestatusmap.size ? "," : ""}
					</div>);
			}
		}

		let lastcmdsjsx: JSX.Element[] = [], request: E5EntRdmRequest|undefined, ttl: E5EntRdmTtl|undefined,
			createtimestr: string, updatetimestr: string, requeststr: string, statusstr: string, ttlstr: string,
			lastcmds: E5EntRdmCommand[]|undefined = this.props.nodesrdminfo.lastcmdsmap.get(this.props.node.imei);
		if (lastcmds !== undefined) {
			for (idx = 0; idx < lastcmds.length; idx++) {
				request = this.props.requestinfo.requestmap.get(lastcmds[idx].requestid);
				status = this.props.statusinfo.statusmap.get(lastcmds[idx].statusid);
				ttl = this.props.ttlinfo.ttlmap.get(lastcmds[idx].ttlid);

				createtimestr = lastcmds[idx].createtime.format(E5UtilI18n._("dateformat") + " HH:mm");
				updatetimestr = lastcmds[idx].updatetime.format(E5UtilI18n._("dateformat") + " HH:mm");
				requeststr = request === undefined ? "" : request.name;
				statusstr = status === undefined ? "" : status.name;
				ttlstr = ttl === undefined ? "" : ttl.name;

				lastcmdsjsx.push(<BP.Tooltip
					interactionKind={"hover"} position={BP.Position.TOP_RIGHT} hoverCloseDelay={50}
					className="lastcmdparent" key={"lastcmd" + idx} content={<div className="e5column-5">
					<div>{E5UtilI18n._("admin-rdm-createtime")} : {createtimestr}</div>
					<div>{E5UtilI18n._("admin-rdm-updatetime")} : {updatetimestr}</div>
					<div>{E5UtilI18n._("admin-rdm-requestid")} : {requeststr}</div>
					<div>{E5UtilI18n._("admin-rdm-statusid")} : {statusstr}&nbsp;
						<div className="e5wifih-nodeplus-statusimgdivtip">
							<img src={"/img/rdm/status-" + statusstr + ".png"} alt=""/>
						</div>
					</div>
					<div>{E5UtilI18n._("admin-rdm-userlogin")} : {lastcmds[idx].userlogin}</div>
					<div>{E5UtilI18n._("admin-rdm-ttlid")} : {ttlstr}</div>
				</div>}>
					<div className="lastcmd">
						<BP.Button icon="delete" small minimal onClick={this.OpenCancelCmd} id={"" + idx}
								   disabled={lastcmds[idx].statusid !== this.props.statusinfo.scheduledid}/>
						<BP.Button icon="horizontal-bar-chart" small minimal onClick={this.OpenCmdResponse}
								   id={"" + idx} disabled={lastcmds[idx].response === ""}/>
						<div className="lastcmdfield">{createtimestr}</div>
						<div className="lastcmdfield">{requeststr}</div>
						<div className="lastcmdfield">
							<div className="e5wifih-nodeplus-statusimgdiv">
								<img alt={statusstr} src={"/img/rdm/status-" + statusstr + ".png"}/>
							</div>
						</div>
						<div className="lastcmdfield">{lastcmds[idx].userlogin}</div>
						<div className="lastcmdfield">{ttlstr}</div>
					</div>
				</BP.Tooltip>);
			}
		}
		// request type
		let reqopts: JSX.Element[] = [<option key={"reqopt"} value={0}/>], idx2: number;
		for (idx = 0; idx < this.props.requestinfo.requestlist.length; idx++) {
			let req: E5EntRdmRequest = this.props.requestinfo.requestlist[idx], isavailable: boolean = false;
			for (idx2 = 0; idx2 < req.devices.length; idx2++)
				if ((req.devices[idx2].firmware === "*" || req.devices[idx2].firmware === this.props.node.softwarev) &&
					(req.devices[idx2].model === "*" || req.devices[idx2].model === this.props.node.model)) {
					isavailable = true;
					break;
				}
			if (isavailable) reqopts.push(<option key={"reqopt" + idx} value={req.id}>{req.name}</option>);
		}
		// ttl
		let ttlopts: JSX.Element[] = [<option key={"ttlopt"} value={0}/>];
		for (idx = 0; idx < this.props.ttlinfo.ttllist.length; idx++) {
			let ttl: E5EntRdmTtl = this.props.ttlinfo.ttllist[idx];
			ttlopts.push(<option key={"ttlopt" + idx} value={ttl.id}>{ttl.name}</option>);
		}

		let iscmdready: boolean = this.state.newcmd.requestid !== 0 && this.state.newcmd.ttlid !== 0
			&& !this.props.nodesrdminfo.loading;

		return <div className="e5wifih-nodeplus">
			<BP.Toaster ref={this.toasterref}/>
			<div className="e5line-5">
				<BP.Button onClick={this.ToggleInterfaces} icon={this.state.interfacesopen ? "minus" : "plus"}
						   disabled={this.props.node.interfaces.length === 0} small text={<div>
					&nbsp;&nbsp;{E5UtilI18n._("wifih-health-desc-interfaces")}
					&nbsp;<b>({this.props.node.interfaces.length})</b>
				</div>}/>

				{E5MainConfig.GetRdmEnabled() &&
				<BP.Button onClick={this.ToggleManagement} small icon={this.state.managementopen ? "minus" : "plus"}
						   disabled={this.props.node.nodetype === E5NodeTypeEnum.none ||
						   this.props.nodesrdminfo.loading || !E5Path.IsAllowed(E5Page.MANAGEMENT_RDM)}
						   text={<div>&nbsp;&nbsp;{E5UtilI18n._("wifih-health-desc-management")}</div>}/>}

				{E5MainConfig.GetSystemEnabled() &&
				<BP.Button disabled={this.props.node.nodetype === E5NodeTypeEnum.none}
						   onClick={this.ToggleSystem} small icon={this.state.systemopen ? "minus" : "plus"}
						   text={<div>&nbsp;&nbsp;{E5UtilI18n._("system")}</div>}/>}
			</div>

			{E5MainConfig.GetSystemEnabled() &&
			<BP.Collapse isOpen={this.state.systemopen}>
				<BP.Divider/>
				<E5HEquipSys searchniinfo={E5StoreH.Ins().searchniinfo} indicsysinfo={E5StoreH.Ins().indicsysinfo}
							 langinfo={E5Store.Ins().langinfo} node={this.props.node}/>
			</BP.Collapse>}

			{E5MainConfig.GetRdmEnabled() && <BP.Collapse isOpen={this.state.managementopen}>
				<div>
					<BP.Divider/>
					<div className="e5column-5">
						<div className="e5line-5">
							<BP.Button small text={E5UtilI18n._("wifih-health-desc-createaction")}
									   disabled={!iscmdready} intent={iscmdready ? BP.Intent.PRIMARY : BP.Intent.NONE}
									   onClick={this.CreateCommand}/>
							<BP.FormGroup inline label={E5UtilI18n._("admin-rdm-ttlid")}
										  labelInfo={<BP.Icon icon="star" iconSize={5} style={{color: "red"}}/>}>
								<BP.HTMLSelect value={this.state.newcmd.ttlid} onChange={this.ChangeRdmTtl}
											   onClick={(event) => event.stopPropagation()}>
									{ttlopts}
								</BP.HTMLSelect>
							</BP.FormGroup>
							<BP.FormGroup inline label={E5UtilI18n._("admin-rdm-requestid")}
										  className="rdmcmdrequestid"
										  labelInfo={<BP.Icon icon="star" iconSize={5} style={{color: "red"}}/>}>
								<BP.HTMLSelect className="rdmcmdttlid"
											   value={this.state.newcmd.requestid} onChange={this.ChangeRdmType}
											   onClick={(event) => event.stopPropagation()}>
									{reqopts}
								</BP.HTMLSelect>
							</BP.FormGroup>
						</div>
						<div className="e5line-5">
							<E5AdminRdmReqLegend langinfo={E5Store.Ins().langinfo}
												 requestinfo={E5StoreAdmin.Ins().rdmrequestinfo}/>
							<BP.FormGroup inline label={E5UtilI18n._("admin-rdm-comment")}>
								<BP.TextArea className="rdmcmdcomment" rows={1} small
											 value={this.state.newcmd.comment} onChange={this.ChangeRdmComment}
											 onClick={(event) => event.stopPropagation()}/>
							</BP.FormGroup>
						</div>
					</div>
					{this.props.nodesrdminfo.loading && <BP.Spinner className="e5spinwait" size={15}/>}
					{!this.props.nodesrdminfo.loading && <>
						<BP.Divider/>
						<div className="e5line-5">
							<BP.Button disabled small text={E5UtilI18n._("wifih-health-desc-rdmstatus")}/>
							{rdmstatusjsx.length > 0 ? rdmstatusjsx : <div className="rdmstatus none">
								{E5UtilI18n._("wifih-health-desc-rdmstatus-none")}
							</div>}
						</div>
						<BP.Divider/>
						<div className="e5line-5">
							<BP.Button disabled small text={<div>
								{E5UtilI18n._("wifih-health-desc-lastcmds")}
								&nbsp;<b>({lastcmdsjsx.length})</b>
							</div>}/>
							<BP.Button small icon="refresh" onClick={this.RefreshRdm}/>
						</div>
						<div>{lastcmdsjsx}</div>
					</>}
				</div>
			</BP.Collapse>}

			<BP.Collapse isOpen={this.state.interfacesopen}>{interfacesjsx}</BP.Collapse>
			<BP.Dialog icon="delete" title={E5UtilI18n._("admin-rdm-cancel")} isOpen={this.state.cancelcmdopen}
					   canOutsideClickClose canEscapeKeyClose onClose={this.CloseDialogs} style={{width: "600px"}}>
				<div className={BP.Classes.DIALOG_BODY}>
					<div>{E5UtilI18n._("admin-rdm-confirmcancel")}</div>
				</div>
				<div className={BP.Classes.DIALOG_FOOTER}>
					<div className={BP.Classes.DIALOG_FOOTER_ACTIONS}>
						<BP.Button text={E5UtilI18n._("admin-rdm-cancel")} intent={BP.Intent.DANGER}
								   onClick={this.DoCancelCmd}/>
						<BP.Button text={E5UtilI18n._("admin-rdm-closenocancel")} onClick={this.CloseDialogs}/>
					</div>
				</div>
			</BP.Dialog>
			<BP.Dialog icon="horizontal-bar-chart" title={E5UtilI18n._("admin-rdm-fullresponse")} canEscapeKeyClose
					   isOpen={this.state.cmdresponseopen} canOutsideClickClose onClose={this.CloseDialogs}
					   style={{width: "800px", height: "500px", transition: "none"}}>
				<div className={BP.Classes.DIALOG_BODY + " e5wifih-rdm-fullresponsedial"}>
					<div className="e5wifih-rdm-fullresponsecontent">
						{this.state.curcmd !== undefined && <E5AdminRdmResponse
							cmd={this.state.curcmd} requestinfo={E5StoreAdmin.Ins().rdmrequestinfo}
							statusinfo={E5StoreAdmin.Ins().rdmstatusinfo} langinfo={E5Store.Ins().langinfo}/>}
					</div>
				</div>
			</BP.Dialog>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	OpenCancelCmd = (event: React.MouseEvent): void => {
		event.stopPropagation();
		let lastcmds: E5EntRdmCommand[]|undefined = this.props.nodesrdminfo.lastcmdsmap.get(this.props.node.imei);
		if (lastcmds !== undefined) this.setState(
			{cancelcmdopen: true, cmdresponseopen: false, curcmd: lastcmds[parseInt(event.currentTarget.id)]});
	};

	//E5
	OpenCmdResponse = (event: React.MouseEvent): void => {
		event.stopPropagation();
		let lastcmds: E5EntRdmCommand[]|undefined = this.props.nodesrdminfo.lastcmdsmap.get(this.props.node.imei);
		if (lastcmds !== undefined) this.setState(
			{cancelcmdopen: false, cmdresponseopen: true, curcmd: lastcmds[parseInt(event.currentTarget.id)]});
	};

	//E5
	CloseDialogs = (): void =>
		this.setState({cancelcmdopen: false, cmdresponseopen: false, curcmd: undefined});

	//E5
	DoCancelCmd = (): void => {
		if (this.state.curcmd !== undefined && this.state.curcmd.statusid === this.props.statusinfo.scheduledid) {
			E5RequestAdminRdm.Ins().CancelCommand(this.state.curcmd.uuid, (status: E5RequestStatus) => {
				if (status.success) {
					this.CloseDialogs();
					this.RefreshRdm();
				} else this.toasterref.current?.show({
					message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message, intent: BP.Intent.DANGER
				});
			});
		}
	};

	//E5
	CreateCommand = (event: React.MouseEvent): void => {
		event.stopPropagation();
		E5RequestH.Ins().FetchEquipsRdmCreate(this.state.newcmd, () =>
			E5RequestH.Ins().FetchEquipsRdm(this.props.node, () =>
				this.setState({newcmd: this.GetNewCmd()})));
	};

	//E5
	RefreshRdm = (event?: React.MouseEvent): void => {
		if (event !== undefined) event.stopPropagation();
		E5RequestH.Ins().FetchEquipsRdm(this.props.node, undefined);
	};

	//E5
	ChangeRdmType = (event: React.FormEvent<HTMLSelectElement>): void => {
		let cmd: E5EntRdmCommand = new E5EntRdmCommand();
		cmd.Copy(this.state.newcmd);
		cmd.requestid = parseInt(event.currentTarget.value);
		this.setState({newcmd: cmd});
	};

	//E5
	ChangeRdmTtl = (event: React.FormEvent<HTMLSelectElement>): void => {
		let cmd: E5EntRdmCommand = new E5EntRdmCommand();
		cmd.Copy(this.state.newcmd);
		cmd.ttlid = parseInt(event.currentTarget.value);
		this.setState({newcmd: cmd});
	};

	//E5
	ChangeRdmComment = (event: React.FormEvent<HTMLTextAreaElement>): void => {
		let cmd: E5EntRdmCommand = new E5EntRdmCommand();
		cmd.Copy(this.state.newcmd);
		cmd.comment = event.currentTarget.value;
		this.setState({newcmd: cmd});
	};

	//E5
	ToggleInterfaces = (event: React.MouseEvent<HTMLElement>): void => {
		event.stopPropagation();
		this.setState({interfacesopen: !this.state.interfacesopen, managementopen: false, systemopen: false});
	};

	//E5
	ToggleManagement = (event: React.MouseEvent<HTMLElement>): void => {
		event.stopPropagation();
		let already: boolean = this.props.nodesrdminfo.lastcmdsmap.get(this.props.node.imei) !== undefined;
		if (!this.state.managementopen && !already)
			E5RequestH.Ins().FetchEquipsRdm(this.props.node, () => this.setState(
				{managementopen: !this.state.managementopen, interfacesopen: false, systemopen: false}));
		else this.setState(
			{managementopen: !this.state.managementopen, interfacesopen: false, systemopen: false});
	};

	//E5
	ToggleSystem = (event: React.MouseEvent<HTMLElement>): void => {
		event.stopPropagation();
		this.setState({systemopen: !this.state.systemopen, interfacesopen: false, managementopen: false});
	};

	//E5
	ToggleDetails = (event: React.MouseEvent<HTMLElement>): void => {
		event.stopPropagation();
		let detailsopen: Set<string> = new Set(this.state.detailsopen);
		if (detailsopen.has(event.currentTarget.id)) detailsopen.delete(event.currentTarget.id);
		else detailsopen.add(event.currentTarget.id);
		this.setState({detailsopen});
	};

	// ---------------- UTILS ----------------

	//E5
	GetNewCmd = (): E5EntRdmCommand => {
		let cmd: E5EntRdmCommand = new E5EntRdmCommand();
		cmd.userlogin = this.props.userinfo.curuser.username;
		cmd.networkid = this.props.searchniinfo.networkid;
		cmd.customerid = this.props.rdminfo.customerid;
		cmd.firmware = this.props.node.softwarev;
		cmd.nodeimei = this.props.node.imei;
		cmd.model = this.props.node.model;
		return cmd;
	};

	//E5
	static ToCsv: (value: string|string[], separator?: string) => string =
		(value: string|string[], separator?: string): string => {
			let breakablecsv: string, sep: string = separator === undefined ? "," : separator;
			// \u200b is an invisible zero-width space that allows strings to break and wrap without css
			if (Array.isArray(value)) breakablecsv = value.join(sep + "\u200b");
			else breakablecsv = value.split(sep).join(sep + "\u200b");
			return breakablecsv;
		};
});
