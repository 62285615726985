import {action, makeObservable, observable} from "mobx";
//
import {E5EntCBPop} from "../entity/customer_base/E5EntCBPop";
import {E5RequestStatus} from "../request/E5ServiceCommon";

// --------- POPULATIONS -------------

//E5
export interface E5StoreCBPops {
	status: E5RequestStatus;
	pops: E5EntCBPop[]
}

//E5
export interface E5StoreCBPopGet {
	status: E5RequestStatus;
	pop: E5EntCBPop;
}

// --------- STORE ITSELF -------------

//E5
export class E5StoreCBPop {

	// --------- STATIC MEMBERS -------------

	//E5
	private static ins: E5StoreCBPop;

	//E5
	static Ins(): E5StoreCBPop {
		if (E5StoreCBPop.ins === undefined)
			E5StoreCBPop.ins = new E5StoreCBPop();
		return E5StoreCBPop.ins;
	}

	// --------- STATIC STORE MODIFICATION -------------

	//E5
	SetPops(status: E5RequestStatus, pops: E5EntCBPop[]) {
		E5StoreCBPop.Ins().popinfo.status = status;
		E5StoreCBPop.Ins().popinfo.pops = pops;
	}

	//E5
	SetPopGet(status: E5RequestStatus, pop: E5EntCBPop) {
		E5StoreCBPop.Ins().popgetinfo.status = status;
		E5StoreCBPop.Ins().popgetinfo.pop = pop;
	}

	// --------- INSTANCE -------------

	popinfo: E5StoreCBPops = {status: {loading: false, success: false, message: ""}, pops: []};
	popgetinfo: E5StoreCBPopGet = {status: {loading: false, success: false, message: ""}, pop: new E5EntCBPop()};

	//E5
	private constructor() {
		makeObservable(this,
			{popinfo: observable, popgetinfo: observable, SetPops: action, SetPopGet: action});
	}
}
