export const messages: any = {
	"error-rdm-403-401": "Connection error with the RDM module",
	// bands
	"bandunknown": "Unknown",
	"MULTIPLE": "Multiple",
	"2_4GHZ": "2.4GHz",
	"5GHZ": "5GHz",
	"6GHZ": "6GHz",
	"eth": "ETH",
	"ETH": "ETH",
	"ETH100": "ETH100",
	"ETH1000": "ETH1000",
	// terms
	"system": "System",
	"system-cpu": "CPU",
	"system-mem": "Memory",
	"system-reb": "Reboot",
	"system-temp": "Temperature",
	"system-proc": "Process",
	"system-flash": "Flash",
	// misc
	"hours": "hours",
	// global
	"back": "Back",
	"processingerror": "Processing error",
	"formaterror": "Format error",
	"conflicterror": "Conflict error",
	"servernotresponding": "Server not responding",
	"dateformat": "DD/MM/YYYY",
	"dayshort": "d",
	"netelementtype-device": "station",
	"netelementtype-node": "equipment",
	"nodetype-ext": "Extender",
	"nodetype-gw": "Gateway",
	"nodetype-stb": "TV adapter",
	"ok": "OK",
	"cancel": "Cancel",
	"delete": "Delete",
	"save": "Save updates",
	"update": "Update",
	"add": "Add",
	"all": "All",
	"none": "None",
	"operation-forbidden": "Operation not permitted",
	"total": "Total",
	"piefromparent": "of",
	"download": "Download",
	"delfilters": "Delete all filters",
	"nextpage": "next page",
	"prevpage": "previus page",
	"firstpage": "first page",
	"lastpage": "last page",
	"id": "Id",
	"no-data-collected": "No data collected",
	// main
	"mainwifititle": "COSMOS",
	// mainheader
	"mainheader-language": "Language",
	"mainheader-welcome": "Welcome, ",
	"mainheader-disconnect": "Disconnect",
	"mainheader-gofr": "Passer en français",
	"mainheader-my-profile": "My profile",
	// login
	"login-welcome": "Welcome !",
	"login-welcome-text": "Sign in to access the COSMOS portal",
	"login-title": "Identification",
	"login-username": "Identifier",
	"login-password": "Password",
	"login-ok": "Login",
	"login-baduser": "Incorrect identification",
	"login-expired": "Your session had expired, please reconnect",
	"login-forbidden-expired": "Connection not allowed, the account expired",
	"login-forbidden-locked": "Connection not allowed, the account is locked",
	"login-forbidden-Forbidden": "Connection not allowed, the session expired",
	"login-forbidden-disabled": "Connection not allowed, the account disabled",
	"login-forbidden-unknown": "Connection not allowed, unknown reason",
	// mainmenu
	"mainmenu-collapsetooltip": "Shrink menu",
	"mainmenu-expandtooltip": "Grow menu",
	"mainmenu-copyright": "© Sagemcom",
	"mainmenu-frontversion": "Frontend release #0",
	"mainmenu-cmsversion": "COSMOS release #0",
	"mainmenu-rc1.1-cmswifipage": "RC1.1 Wi-Fi Page",
	"mainmenu-customer-base": "Customer base",
	"mainmenu-cb-dashboard": "Dashboard",
	"mainmenu-cb-populations": "Populations",
	"mainmenu-household": "Household",
	"mainmenu-h-dashboard": "Dashboard",
	"mainmenu-h-incidents": "Incidents",
	"mainmenu-h-connectivity": "Connectivity",
	"mainmenu-h-eventlogs": "Eventlogs",
	"mainmenu-h-metrics": "Metrics",
	"mainmenu-h-stations": "Stations",
	"mainmenu-h-equipments": "Equipment",
	"mainmenu-management": "Management",
	"mainmenu-m-users": "Users",
	"mainmenu-m-rdm": "RDM",
	"mainmenu-m-acl": "ACL groups",
	// mainmenu-test
	"mainmenu-test": "Tests",
	"mainmenu-test-xy-chart": "T~ XY chart",
	"mainmenu-test-xy-chart-long": "Test page for the XY (cartesian) chart",
	"mainmenu-test-pie": "T~ pie chart",
	"mainmenu-test-pie-long": "Test page for the pie chart",
	"mainmenu-test-radar": "T~ radar (star)",
	"mainmenu-test-radar-long": "Test page for the radar (star) chart",
	"mainmenu-test-angular-gauge": "T~ angular gauge",
	"mainmenu-test-angular-gauge-long": "Test page for the angular gauge",
	"mainmenu-test-bullet-gauge": "T~ bullet gauge",
	"mainmenu-test-bullet-gauge-long": "Test page for the flat horizontal gauge (bullet)",
	"mainmenu-test-cb-indics-long": "Test page for the customer base indicators",
	"mainmenu-test-populations": "T~ populations",
	"mainmenu-test-populations-long": "Test page for the populations",
	"mainmenu-test-h-indics": "T~ H indics",
	"mainmenu-test-h-indics-long": "Test page for the household indicators",
	"mainmenu-test-incids-recoms": "T~ incids recoms",
	"mainmenu-test-incids-recoms-long": "Test page for the incidents and recommendations",
	"mainmenu-test-topo-items": "T~ topo items",
	"mainmenu-test-topo-items-long": "Test page for the topology items",
	"mainmenu-test-topo-graph": "T~ topo graph",
	"mainmenu-test-topo-graph-long": "Test page for the topology graph",
	"mainmenu-test-connections": "T~ connections",
	"mainmenu-test-connections-long": "Test page for the connections table",
	"mainmenu-test-heatmap": "T~ heatmap",
	"mainmenu-test-heatmap-long": "Test page for the heatmap",
	"mainmenu-test-admin-users": "T~ admin users",
	"mainmenu-test-admin-users-long": "Test page for the administration of users",
	// side menu
	"sidemenu-how-works": "How COSMOS works?",
	// pagetitle
	"pagetitle-cb-dashboard": "Population performance indicators",
	"pagetitle-cb-populations": "Populations management",
	"pagetitle-h-dashboard": "Household overview",
	"pagetitle-h-incidents": "Household incidents and recommendations",
	"pagetitle-h-connectivity": "Household network connectivity",
	"pagetitle-h-eventlogs": "Wi-Fi Event Logs",
	"pagetitle-h-metrics": "Household metrics",
	"pagetitle-h-stations": "Household stations",
	"pagetitle-h-equipments": "Household equipment",
	"pagetitle-management-users": "Users accounts management",
	"pagetitle-management-rdm": "Remote equipments management",
	"pagetitle-management-acl": "ACL groups management",
	// search household id
	"searchni-label": "Type a household identifier",
	"searchni-refresh": "Refresh data for the same identifier for the chosen date",
	"searchni-autocomplete": "Search household identifiers ending with this value",
	"searchni-autocomplete-none": "No identifier found",
	// welcomepage
	"home-title": "Home",
	"home-welcome": "Welcome to Sagemcom’s COSMOS",
	"home-how-to-work": "How COSMOS works ?",
	"home-data-collection": "Data collection",
	"home-data-collection-description": "CPEs’ data are collected at a high sampling frequency by the master COSMOS Agent (e.g. every 10 seconds) and are stored in memory. These data are then periodically formated into a Json message and sent to the CMS Platform using the HTTPs protocol (e.g. every 30 minutes).",
	"home-data-ingestion-aggregation": "Data ingestion and aggregation",
	"home-data-ingestion-aggregation-description": "Received Json messages are checked by the COSMOS Platform against a predefined data schema. Valid data are then aggregated into buckets of up to 10 minutes, where each bucket is associated with a set of descriptive metrics (e.g. average RSSI, maximal noise level, average CPU load, etc.).",
	"home-anomaly-severity-scoring": "Anomaly and severity scoring",
	"home-anomaly-severity-scoring-description": "Each bucket is analyzed against a set of predefined rules to detect anomalies (e.g. link instability, high CPU usage, etc.). A bounded severity score is then computed for each anomaly to assess its severity at the scale of the selected bucket.",
	"home-health-scores": "Health scores",
	"home-health-scores-description": "At any point in time, the COSMOS platform can evaluate the health score of each system’s element (e.g. station, link, equipment, household, population, etc.), based on the computed anomalies’ severity scores. These health scores enable the operators to assess the performance level of such elements.",
	"home-incidents-recommendations": "Incidents and recommendations",
	"home-incidents-recommendations-description": "At the end of each day, the COSMOS platform analyzes all the collected data, detects incidents and evaluates their criticality scores, based on their impact and occurrence. Then, for each detected incident, recommendations are generated to help the operators optimize the quality of experience.",
	"home-customer-base-kpis": "Customer base KPIs",
	"home-customer-base-kpis-description": "At the end of each day, the COSMOS platform will compute the key performance indicators (KPIs) of each defined population. These KPIs are displayed on a comprehensive dashboard to help the operators track and manage the quality of experience at the scale of their customer base.",
	"home-cosmos": "Cloud Operated Solution for Monitoring, Optimization and Services",
	"home-connect-cpes": "Connect CPEs",
	"home-cpes-platform": "Connect your CPEs to the COSMOS platform by enabling the Data Collectors (via TR-069) and start monitoring your",
	"home-customer-base" : "Customer Base",
	"home-household": "Household",
	"home-Metrics": "Metrics",
	"home-monitor-wiFi-networks": "Monitor in-Home Wi-Fi Networks",
	"home-households-using": "Monitor your Households using the",
	"home-dashboard": "Dashboard",
	"home-page-health-score": "page, in terms of health score, connected stations and nodes, in-home Wi-Fi KPIs, and collected",
	"home-comma": ",",
	"home-and": "and",
	"home-customer-base-analytics": "Customer Base Analytics",
	"home-monitor-deployment": "Monitor the deployment and quality of experience of your in-Home Wi-Fi networks at the scale of your",
	"home-target-populations": "or target Populations.",
	"home-manage-users-roles": "Manage Users and Roles",
	"home-create-manage": "Create and manage the list of the",
	"home-user": "User",
	"home-allowed-connect": "that are allowed to connect on the COSMOS platform and access its features. The list of available roles is available here:",
	"home-act-upon-incidents": "Act upon Incidents",
	"home-optimize-quality": "Optimize the quality of experience of your in-Home Wi-Fi networks by analyzing the detected",
	"home-incidents": "Incidents",
	"home-proactively-acting": "and proactively acting upon the generated",
	"home-recommendations": "Recommendations",
	"home-générées": "",
	"home-manage-populations": "Manage Populations",
	"home-finitune-your": "Finetune your",
	"home-analytics-target": "analytics by creating and managing a list of target",
	"home-populations": "Populations",
	"home-various-criteria": "based on various criteria.",

	// wificb-dashboard
	"wificb-dashboard-over": "General overview",
	"wificb-dashboard-wifi": "Wi-Fi",
	"wificb-dashboard-wan": "WAN",
	"wificb-dashboard-system": "System",
	"wificb-dashboard-notfound": "No data found",
	"wificb-dashboard-from": "from",
	"wificb-dashboard-to": "to",
	"wificb-dashboard-of": "of",
	"wificb-dashboard-selectpop": "Select one population...",
	"wificb-dashboard-downloadni-cat": "Do you want to display #0 household identifiers for the category \"#2\" (#1) ?",
	// cb
	"cb-health": "Health score",
	"cb-health-over-time": "Health scores over time",
	"cb-pie-inc-cat": "Criticality per incident category",
	"cb-inc-over-time": "Incident scorings over time",
	"cb-incidents-sub": "Average criticality and duration per incident category per household (in hours)",
	"cb-incidents-help": "Combined criticality and duration of all incidents per category, per household and per day, averaged over all households of the population.",
	"cb-dashboard-downloadni-health": "Do you want to display #0 household identifiers (maximum) for the category \"#1\" and the date \"#2\" ?",
	"cb-dashboard-downloadni-cpu": "Do you want to display up to #0 household identifiers (maximum) for the process \"#1\" and the date \"#2\" ?",
	"cb-dashboard-downloadni-mem": "Do you want to display up to #0 household identifiers (maximum) for the process \"#1\" and the date \"#2\" ?",
	"cb-dashboard-downloadni-crash": "Do you want to display up to #0 household identifiers (maximum) for the process \"#1\" and the date \"#2\" ?",
	"cb-dashboard-downloadni-incid": "Do you want to display #0 household identifiers (maximum) for the incidents \"#2\" (#1) in the category \"#3\" ?",
	"cb-dashboard-downloadni-equip": "Do you want to display #0 household identifiers (maximum) for the category \"#1\" and the equipment type \"#2\" ?",
	"cb-dashboard-downloadni-detail": "Do you want to display #0 household identifiers (maximum) for the category \"#1\", the firmware version \"#2\" and the model \"#3\"?",
	"cb-dashboard-serialnumber": "Household ID",
	"cb-dashboard-imei": "Equipment impacted",
	"cb-dashboard-avgval-none": "Average Value",
	"cb-dashboard-avgval-cpu": "Average CPU usage",
	"cb-dashboard-avgval-mem": "Average memory usage",
	"cb-dashboard-avgval-usage": "Average usage",
	"cb-dashboard-device-activity": "Devices activity detection",
	"cb-dashboard-device-disconnected": "Disconnected",
	"cb-dashboard-device-notreceived": "Not received",
	"cb-dashboard-device-mbsend": "MB sent and received",
	"cb-dashboard-device-lowactivity": "Low activity",
	"cb-dashboard-device-highactivity": "High activity",
	"cb-dashboard-day": "Day",
	"cb-dashboard-week": "Week",
	"cb-dashboard-avgval-crash": "Number of crash",
	"cb-dashboard-downloaderr": "Download error",
	"cb-dashboard-nilist": "Household identifiers",
	"cb-dashboard-topcontentswitch-true": "Percentage mode",
	"cb-dashboard-topcontentswitch-false": "Sum mode",
	// cb-overview
	"cb-overview-pie-softwarev": "Firmwares versions",
	"cb-overview-pie-equips": "Equipments types",
	"cb-overview-pie-stations": "Stations types",
	"cb-overview-growth-equips": "Growth over time (equipments)",
	"cb-overview-growth-stations": "Growth over time (stations)",
	"cb-overview-equip": "Households size over time",
	// cb-wifi
	"cb-wifi-pie-channelusage": "Channels usage",
	"cb-wifi-pie-autochan": "Auto-channel usage",
	"cb-wifi-pie-bandstd": "Bands and standards usage",
	"cb-wifi-pie-categ-help": "Combined criticality of all incidents per category and per household, averaged over all households of the population.",
	"cb-wifi-incidents-criticality": " (criticality)",
	"cb-wifi-incidents-duration": " (duration)",
	"cb-wifi-connections-bands": "Connections over time (bands)",
	"cb-wifi-connections-standards": "Connections over time (standards)",
	// cb-wan
	"cb-wan-pie-incid": "Criticality per connection and incident types",
	"cb-wan-pie-access": "Connection types",
	// cb-sys
	"cb-sys-pie-equips-sub": "Per equipment type",
	"cb-sys-pie-details-sub": "Per model and firmware version",
	"cb-sys-pie-gw-sub": "Per gateway model and firmware version",
	"cb-sys-pie-ext-sub": "Per extender model and firmware version",
	"cb-sys-pie-val-suffix": "equipments (sum)",
	// cb-sys-overview
	"cb-sys-overview": "System overview",
	"cb-sys-overview-healths": "System health scores",
	// cb-sys-cpu
	"cb-sys-cpu-pie": "CPU load",
	"cb-sys-cpu-gw": "Gateways' CPU load over time",
	"cb-sys-cpu-ext": "Extenders' CPU load over time",
	"cb-sys-cpu-stb": "Set-top boxes' CPU load over time",
	// cb-sys-memory
	"cb-sys-memory-pie": "Memory usage",
	"cb-sys-memory-gw": "Gateways' memory usage over time",
	"cb-sys-memory-ext": "Extenders' memory usage over time",
	"cb-sys-memory-stb": "Set-top boxes' memory usage over time",
	// cb-sys-reboot
	"cb-sys-reboot-pie-reason-sub": "Per reboot reason",
	"cb-sys-reboot-piereb": "Number of reboots",
	"cb-sys-reboot-pieupt": "Uptime",
	"cb-sys-reboot-gwreb": "Gateways' number of reboots over time",
	"cb-sys-reboot-extreb": "Extenders' number of reboots over time",
	"cb-sys-reboot-stbreb": "Set-top boxes' number of reboots over time",
	"cb-sys-reboot-gwupt": "Gateways' uptime over time",
	"cb-sys-reboot-extupt": "Extenders' uptime over time",
	"cb-sys-reboot-stbupt": "Set-top boxes' uptime over time",
	// cb-sys-temperature
	"cb-sys-temperature-pie": "Temperature",
	"cb-sys-temperature-gw": "Gateways' temperature over time",
	"cb-sys-temperature-ext": "Extenders' temperature over time",
	"cb-sys-temperature-stb": "Set-top boxes' temperature over time",
	// cb-sys-process
	"cb-sys-process-cpuproc": "Most CPU-intensive processes",
	"cb-sys-process-memproc": "Most memory-intensive processes",
	"cb-sys-process-crashproc": "Most frequent process crashes",
	"cb-sys-process-name": "Process name",
	"cb-sys-process-cpuval": "Average CPU usage",
	"cb-sys-process-memval": "Average memory usage",
	"cb-sys-process-crashval": "Average process crashes",
	"cb-sys-process-statusmet": "Process statuses' frequency over time",
	"cb-sys-process-cpumet": "Most CPU-intensive processes over time",
	"cb-sys-process-memmet": "Most memory-intensive processes over time",
	"cb-sys-process-crashmet": "Average number of process crashes on the population over time",
	// cb-sys-flash
	"cb-sys-flash-pieflusa": "Flash memory usage",
	"cb-sys-flash-pieflcor": "Flash memory corruption",
	"cb-sys-flash-gwflusa": "Gateways' flash memory usage over time",
	"cb-sys-flash-extflusa": "Extenders' flash memory usage over time",
	"cb-sys-flash-stbflusa": "Set-top boxes' flash memory usage over time",
	"cb-sys-flash-gwflcor": "Gateways' flash memory corruption over time",
	"cb-sys-flash-extflcor": "Extenders' flash memory corruption over time",
	"cb-sys-flash-stbflcor": "Set-top boxes' flash memory corruption over time",
	// cb-eventlog
	"cb-eventlog-time": "eventTime",
	"cb-eventlog-device": "Device IMEI",
	"cb-eventlog-type": "eventType",
	"cb-eventlog-args": "Key-Value",
	"cb-eventlog-args-key": "Key",
	"cb-eventlog-args-value": "Value",
	"cb-eventlog-access-point": "Access point MAC Adress",
	"cb-eventlog-event-name": "Event Name",
	"cb-eventlog-station-mac": "Station MAC Adress",
	"cb-eventlog-reason": "Reason",
	"cb-eventlog-previous-band": "Band from",
	"cb-eventlog-new-band": "Band to",
	"cb-eventlog-connectivity": "Connectivity",
	"cb-eventlog-band-steering": "Band steering",
	"cb-eventlog-filter-by-eqiupment": "Filter by equipment",
	"cb-eventlog-network": "Network",
	"cb-eventlog-select-eqiupment": "Select equipment",
	"cb-eventlog-event-time": "Event time",

	// wificb-pops
	"wificb-pops-nilist": "Household identifiers list (max. #0)",
	"wificb-pops-no-netids": "No data found",
	"wificb-pops-save-name": "Save new name",
	"wificb-pops-popup-update": "Update or delete a population",
	"wificb-pops-popup-add": "Add a new population",
	"wificb-pops-crits-add": "Add criterion",
	"wificb-pops-crit-integer": "Type an integer, for example: 12.",
	"wificb-pops-crit-string": "Type an exact version name.",
	"wificb-pops-crit-option": "Select an option.",
	"wificb-pops-crit-float": "Type a health score, for example: 0.85.",
	"wificb-pops-whole-park": "Whole park",
	"wificb-pops-remove-park": "Remove whole park",
	"wificb-pops-crits-remove": "Remove all criteria",
	"wificb-pops-netids-defids": "Define the household identifiers",
	"wificb-pops-unavailable": "Unavailable",
	"wificb-pops-netids-remove": "Remove all household identifiers",
	"wificb-pops-netids-explain": "Type/paste household ids separated by a whitespace, a comma, a semicolon or a new line.",
	"wificb-pops-deletetip": "Suppress this population after confirmation",
	"wificb-pops-duplicatetip": "Duplicate this population after confirmation",
	"wificb-pops-confirmdelete": "Do you really want to delete this population? This operation cannot be undone.",
	"wificb-pops-confirmduplicate": "Do you really want to duplicate this population? The new one will be called ",
	"wificb-pops-confirmmodify": "Do you really want to modify this population ? Updated data will be available tomorrow.",
	"wificb-pops-duplicate": "Duplicate",
	"wificb-pops-name": "Name",
	"wificb-pops-populationid": "UUID",
	"wificb-pops-populationid-pop": "UUID (non editable)",
	"wificb-pops-aclname": "ACL group",
	"wificb-pops-copynamesuffix": "Copy",
	"wificb-pops-creationdate": "Creation",
	"wificb-pops-networkidcount": "Households",
	"wificb-pops-criterias": "Criteria",
	"wificb-pops-criteria": "Criterion",
	"wificb-pops-condition": "Condition",
	"wificb-pops-value": "Value",
	"wificb-pops-actions": "Household identifiers",
	"wificb-pops-actions-display": "Display",
	"wificb-pops-actions-download": "Download (.csv)",
	"wificb-pops-actions-user-ids": "the identifiers set when creating the population",
	"wificb-pops-actions-active-ids": "the identifiers that were actually active yesterday",
	"wificb-pops-not-found": "Unknown population",
	"wificb-pops-updateok": "Operation succeeded",
	"wificb-pops-updateok-withduplicates": "List of Network IDs contains duplicate records",
	"wificb-pops-update-failed": "Update has failed",
	"wificb-pops-get-failed": "Read has failed",
	"wificb-pops-listof": "List of network ids",
	"wificb-pops-acl-ids-load": "Load the network identifiers of the group",
	"wificb-pops-acl-ids-warn": "This will replace the above network identifiers.",
	"wificb-pops-acl-ids-help": "You need to select an ACL group first.",
	"wificb-pops-acl-ids-fail": "The group's network identifiers failed to load",
	"wificb-pops-cond-contains": "Exists",
	"wificb-pops-cond-!contains": "Does not exist",
	"wificb-pops-filters": "Filters",
	"wificb-pops-createstart": "From",
	"wificb-pops-createend": "Until",
	// wificb-indic
	"wificb-indic-sta": "Stations",
	"wificb-indic-sta-tip": "Total number of stations",
	"wificb-indic-gw": "GW",
	"wificb-indic-gw-tip": "Total number of gateways (with CMS agent enabled)",
	"wificb-indic-ext": "EXT",
	"wificb-indic-ext-tip": "Total number of extenders (with CMS agent enabled)",
	"wificb-indic-stb": "STB",
	"wificb-indic-stb-tip": "Total number of set-top box (with CMS agent enabled)",
	"wificb-indic-5g-tip": "Total number of Wi-Fi 5GHz connections",
	"wificb-indic-6g-tip": "Total number of Wi-Fi 6GHz connections",
	"wificb-indic-2g-tip": "Total number of Wi-Fi 2.4GHz connections",
	"wificb-indic-wa": "802.11a",
	"wificb-indic-wa-tip": "Total number of Wi-Fi 802.11a connections",
	"wificb-indic-wb": "802.11b",
	"wificb-indic-wb-tip": "Total number of Wi-Fi 802.11b connections",
	"wificb-indic-wg": "802.11g",
	"wificb-indic-wg-tip": "Total number of Wi-Fi 802.11g connections",
	"wificb-indic-wn": "802.11n",
	"wificb-indic-wn-tip": "Total number of Wi-Fi 802.11n connections",
	"wificb-indic-wac": "802.11ac",
	"wificb-indic-wac-tip": "Total number of Wi-Fi 802.11ac connections",
	"wificb-indic-wax": "802.11ax",
	"wificb-indic-wax-tip": "Total number of Wi-Fi 802.11ax connections",
	"wificb-indic-extperh": "Avg EXT",
	"wificb-indic-stbperh": "Avg STB equipments per household",
	"wificb-indic-bandavg-2ghz": "Avg 2.4GHz",
	"wificb-indic-bandavg-5ghz": "Avg 5GHz",
	"wificb-indic-bandavg-6ghz": "Avg 6GHz",
	"wificb-indic-bandavg-eth": "Avg ETH",
	// customer base report
	"cbreport-button": "Full report",
	"cbreport-loadall": "Load all the data",
	"cbreport-loadall-wait": "Loading the entire data may take several seconds to complete.",
	"cbreport-close": "Close the report",
	"cbreport-notalldata": "The report is incomplete since not all tabs have been opened.",
	// wifih-status
	"wifih-status-networkid": "household id",
	"wifih-status-networkid-tip": "Household identifier",
	"wifih-status-last": "last message",
	"wifih-status-last-tip": "Upload time of the last received message",
	"wifih-status-now": "display time",
	"wifih-status-now-tip": "Time of displayed information",
	"wifih-status-timezone": "timezone",
	"wifih-status-timezone-tip": "Household timezone",
	// wifih-dashboard
	"wifih-indic-global-notfound": "No data found for the date #0",
	"wifih-indic-wifi-notfound": "No data found for the date #0",
	"h-indic-wan-not-found": "No data found for the date #0",
	"h-indic-sys-not-found": "No data found for the date #0",
	"wifih-health-scores": "Household health scores",
	"wifih-health-week-scores": "Historical health scores",
	"h-dashboard-equip": "Equipments",
	"h-dashboard-station": "Stations",
	"wifih-health-wan-interf": "WAN interface",
	"wifih-health-wan-interf-name": "Name",
	"wifih-health-wan-interf-type": "Type",
	"wifih-health-wan-interf-status": "Status",
	"wifih-health-wan-interf-totalbytesreceived": "Total download",
	"wifih-health-wan-interf-bytesreceived": "Download",
	"wifih-health-wan-interf-totalbytessent": "Total upload",
	"wifih-health-wan-interf-bytessent": "Upload",
	"wifih-health-wan-interf-totalbytessum": "Total",
	"wifih-health-wan-interf-bytes": "Throughput (Mb/s)",
	"wifih-health-wifi-info": "Wi-Fi data",
	"h-dashboard-health": "Health",
	"h-dashboard-incident": "Incidents",
	"h-dashboard-anomaly": "Anomalies",
	"h-dashboard-anomaly-button": "Anomaly data",
	"h-dashboard-anomaly-chart": "Top 5 anomalies",
	"wifih-health-incidents": "Top 5 incidents",
	"h-equip-imei": "IMEI",
	"h-equip-model": "Model",
	"h-equip-uptime": "Uptime",
	"h-equip-nb-reboot": "Number of reboots",
	"h-equip-lastseen": "Last seen",
	"wifih-health-desc-wifistdavailable": "Wi-Fi avail.",
	"wifih-health-desc-wifistdused": "Wi-Fi used",
	"wifih-health-desc-ethernet": "Ethernet",
	"wifih-health-desc-macaddress": "MAC addr.",
	"wifih-health-desc-hostname": "Hostname",
	"wifih-health-desc-band": "Band",
	"wifih-health-desc-standards": "Standards",
	"wifih-health-desc-available": "Available",
	"wifih-health-desc-supported": "Supported",
	"wifih-health-desc-current": "Current",
	"wifih-health-desc-in-use": "Used",
	"wifih-health-desc-channels": "Channels",
	"wifih-health-desc-chan": "Channel",
	"wifih-health-desc-bandchans": "#0 channels",
	"wifih-health-desc-curbandwidth": "Bandwidth",
	"wifih-health-desc-dev-lastseen": "Last seen",
	"wifih-health-desc-linktype": "Link type",
	"wifih-health-desc-maxbitrate": "Max bit rate",
	"wifih-health-desc-interfaces": "Interfaces",
	"wifih-health-desc-interface": "Interface",
	"wifih-health-desc-details": "Details",
	"wifih-health-desc-management": "Remote management",
	"wifih-health-desc-createaction": "Create an action",
	"wifih-health-desc-rdmaction": "action(s)",
	"wifih-health-desc-rdmstatus": "Status",
	"wifih-health-desc-rdmstatus-none": "No action",
	"wifih-health-desc-lastcmds": "Last actions created",
	"wifih-health-desc-status": "Status",
	"wifih-health-desc-wifissid": "SSID",
	"wifih-health-desc-autochan": "Auto channel enable",
	"wifih-health-pietitle": "Household stations connection time (seconds)",
	"wifih-health-chanconntime": "Connection time per channel in use",
	// h-equip-sys
	"h-equip-sys-data": "System data",
	"h-equip-sys-overview": "Overview",
	"h-equip-sys-manufacturer": "Manufacturer",
	"h-equip-sys-model": "Model",
	"h-equip-sys-cpu-cores": "CPU cores",
	"h-equip-sys-ram": "RAM",
	"h-equip-sys-flash": "Flash",
	"h-equip-sys-hardwarev": "Hardware",
	"h-equip-datacollector": "Data Collector",
	"h-equip-sys-softwarev": "Software",
	"h-equip-sys-health": "System health scores",
	"h-equip-sys-raw": "System metrics",
	"h-equip-sys-raw-mem-usage": "Memory",
	"h-equip-sys-raw-mem-usage-mb": "Memory used",
	"h-equip-sys-raw-mem-total-mb": "total",
	"h-equip-sys-raw-cpu-load1": "CPU Load 1",
	"h-equip-sys-raw-cpu-load5": "CPU Load 5",
	"h-equip-sys-raw-cpu-load15": "CPU Load 15",
	"h-equip-sys-mem-usage-mb": "Memory used:",
	"h-equip-sys-mem-total-mb": "total:",
	"h-equip-sys-raw-sys-load1": "CPU Load 1:",
	"h-equip-sys-raw-sys-load5": "CPU Load 5:",
	"h-equip-sys-raw-sys-load15": "CPU Load 15:",
	"h-equip-sys-raw-temperature": "Temperature",
	"h-equip-sys-raw-temperature-low": "Low threshold",
	"h-equip-sys-raw-temperature-high": "high threshold",
	"h-equip-sys-reb-detail": "Reboot reasons",
	"h-equip-sys-flash-detail-block": "Flash block types (MB)",
	"h-equip-sys-flash-detail-corrupt": "Flash volume integrity",
	"h-equip-sys-flash-avail": "Available",
	"h-equip-sys-flash-used": "Used",
	"h-equip-sys-flash-bad": "Bad",
	"h-equip-sys-flash-vol-corrupt": "Corrupted",
	"h-equip-sys-flash-vol-valid": "Valid",
	"h-equip-sys-proc": "Processes",
	"h-equip-sys-proc-pid": "Pid",
	"h-equip-sys-proc-name": "Name",
	"h-equip-sys-proc-priority": "Priority",
	"h-equip-sys-proc-memsize": "RAM (MB)",
	"h-equip-sys-proc-memusage": "RAM (%)",
	"h-equip-sys-proc-cputime": "CPU time",
	"h-equip-sys-crash": "Crashes",
	"h-equip-sys-crash-proc-id": "Process id",
	"h-equip-sys-crash-time": "Time",
	"h-equip-sys-crash-proc-name": "Process name",
	"h-equip-sys-crash-softwarev": "Software version",
	// wifih-incident
	"wifih-incident-anomaly-notfound": "No data found for the date #0",
	"wifih-incident-tab-incident": "Incidents",
	"wifih-incident-tab-incident-one": "Incident",
	"wifih-incident-tab-anomaly": "Anomalies",
	"wifih-incident-anom-info": "Anomalies data",
	"wifih-incident-anomaly-severity-none": "No anomaly data",
	"wifih-incident-anomaly-severity": "Anomalies severity",
	"wifih-incident-anomaly-details": "Anomaly details",
	"wifih-incident-anomaly-severity-sub": "Network perturbation scores over time per anomaly type",
	"wifih-incident-anomaly-severity-max": "Highest possible severity : ",
	"wifih-incident-anomaly-severity-notice": "* The A010000 score is calculated from the following anomalies : A010101, A010104, A010200, A010301, A010401, A010402, A010403, A010501, A010502, A010503, A010302.",
	"wifih-incident-anomaly-severity-notice2": "* The A020000 score is calculated from the following anomalies : A020101, A020200.",
	"wifih-incident-desc-duration": "Duration",
	"wifih-incident-desc-eqp": "Equip.",
	"wifih-incident-desc-recoms": "Recom.",
	"wifih-incident-desc-contexts": "Context information",
	"wifih-incident-desc-context-none": "None",
	"wifih-incident-desc-sta": "Stations",
	"wifih-incident-desc-criticality": "Criticality",
	"wifih-incident-desc-severity": "Average severity",
	"wifih-incident-desc-band": "Band",
	"wifih-incident-desc-wantype": "WAN type",
	"wifih-incident-desc-software": "Software",
	"wifih-incident-desc-model": "Model",
	"wifih-incident-desc-procname": "Process",
	"wifih-incident-desc-pid": "Pid",
	"wifih-incident-desc-coredump": "Coredump",
	"wifih-incident-desc-ubiname": "UBI device",
	"wifih-incident-desc-details": "Details",
	"wifih-incident-desc-type": "Type",
	"wifih-incident-notyet": "Incidents have not been computed yet for #0",
	"wifih-incident-load-button": "Load the incidents",
	"wifih-incident-day-load-button": "Load the incidents for ",
	"wifih-incident-week-mode": "For the whole week",
	"wifih-incident-criticality-filter": "Criticality more than",
	"wifih-incident-type-filter": "Type",
	"wifih-incident-categ-filter": "Category",
	"wifih-incident-band-filter": "Band",
	"wifih-incident-network-filter": "Network",
	"wifih-incident-all": "All",
	"wifih-incident-incidents": "Incidents list",
	"wifih-incident-notfound": "No incident for the date #0",
	"wifih-incident-notcomputed": "Incidents of #0 have not been computed yet",
	"wifih-incident-graphtitle": "Anomaly over time",
	"wifih-incident-graphtitle-help": "Combined anomaly scores of all Wi-Fi links affected by the selected incident as a function of time.",
	"wifih-incident-graphtitle-help2": "The anomaly associated with this incident is : ",
	"wifih-incident-equip-filter": "Filter by equipment",
	"wifih-incident-station-filter": "Filter by station",
	"wifih-incident-software-filter": "Filter by software version",
	"wifih-incident-model-filter": "Filter by model",
	"wifih-incident-week": "Incidents of the week (criticality in hours)",
	"wifih-incident-week-equipment": "Total criticality per equipment over week (in hours)",
	"wifih-incident-week-station": "Total criticality per station over week (in hours)",
	"wifih-incident-week-heatmap": "Heatmap of incidents criticalities over week (in hours)",
	"wifih-incident-date": "Incidents for #0",
	"wifih-incident-date-hint": "(click on the chart to change the date)",
	"wifih-incident-over-week": "Incident over week (criticality in hours)",
	"wifih-incident-open": "Open the details",
	"wifih-incident-close": "Close the details",
	"wifih-incident-bf_both": "Backhaul+Fronthaul",
	"wifih-incident-bf_backhaul": "Backhaul",
	"wifih-incident-bf_fronthaul": "Fronthaul",
	"wifih-incident-chart-incidentbar": "Incidents view",
	"wifih-incident-chart-equipmentbar": "Equipments view",
	"wifih-incident-chart-stationbar": "Stations view",
	"wifih-incident-chart-heatmap": "Heatmap",
	// wifih-connectivity
	"wifih-connectivity-notfound": "No time topology for the date #0",// TOCHECK
	"wifih-topology-tab-topology": "Topology",
	"wifih-topology-tab-instability": "Connections",
	"wifih-topology-tab-heatmap": "RSSI heatmap",
	"wifih-topology-avg-rssi": "Average RSSI (dBm)",
	"wifih-topology-unknown-band": "Unknown",
	"wifih-topology-fullday": "Full day",
	"wifih-topology-endofday": "End of day",
	"wifih-topology-now": "Now",
	"wifih-topology-layouts": "Layouts :",
	"wifih-topology-slider": "Times :",
	// wifih-metrics
	"wifih-metrics-filters": "Households metrics filters",
	"wifih-metrics-globalscore": "Health of selected element",
	"wifih-metrics-details": "Metrics details",
	"wifih-metrics-charttitle": "Metrics for the selected element",
	"wifih-metrics-charttitle-equip": "Metrics for the selected equipment",
	"wifih-metrics-nodecharttitle": "Metrics for the selected interface",
	"wifih-metrics-chartsubtitle": "Time is local to household",
	"wifih-metrics-ovrview-chart-1": "CPU usage",
	"wifih-metrics-ovrview-chart-2": "CPU time",
	"wifih-metrics-ovrview-chart-3": "CPU load",
	"wifih-metrics-ovrview-chart-4": "Memory available",
	"wifih-metrics-ovrview-chart-5": "Memory usage",
	"wifih-metrics-ovrview-chart-6": "Uptime and crash event",
	"wifih-metrics-ovrview-chart-7": "Reboot and Firmware update event over the day",
	"wifih-metrics-overview-reboot-reason-timestamp": "Timestamp",
	"wifih-metrics-overview-reboot-reason-reboot-reason": "Reboot reason",
	"wifih-metrics-overview-reboot-reason-reboot-source": "Reboot source",
	"wifih-metrics-overview-reboot-reason-self-healing-source": "Self healing source",
	"h-metric-sys-equip-reboot-event": "Reboot event",
	"h-metric-sys-equip-reboot-reason": "Reason:",
	"h-metric-sys-equip-reboot-source": "Source:",
	"h-metric-sys-equip-self-healing-source": "Self healing source:",
	"h-metric-sys-equip-software-version": "Software version:",
	"wifih-metrics-ovrview-header": 'Advanced metrics (equipment ',
	'wifih-metrics-chart-title': "Equipment process metrics analysis",
	"wifih-metrics-wifi-notfound": "No Wi-Fi metric for the date #0",
	"wifih-metrics-sys-notfound": "No system metric for the date #0",
	"wifih-metrics-process-notfound": "No process metric for the date #0",
	"wifih-metrics-pietitle": "Selected element connection time on Wi-Fi",
	"wifih-metrics-chanconntime": "Selected element connection time per channel in use",
	"wifih-metrics-desc-bandchans": "#0 channels",
	"wifih-metrics-leftsource": "Left axis data",
	"wifih-metrics-rightsource": "Right axis data",
	"wifih-metrics-name-airtime": "Airtime",
	"wifih-metrics-name-airtime_idle": "Airtime idle",
	"wifih-metrics-name-airtime_interference": "Airtime interferences",
	"wifih-metrics-name-airtime_recvother": "Airtime received other",
	"wifih-metrics-name-airtime_recvself": "Airtime received",
	"wifih-metrics-name-airtime_sent": "Airtime sent",
	"wifih-metrics-name-byterate": "Data transfer rate (sent+received)",
	"wifih-metrics-name-byterate_up": "Data transfer rate (sent)",
	"wifih-metrics-name-byterate_down": "Data transfer rate (received)",
	"wifih-metrics-name-packrate": "Packet transfer rate (sent+received)",
	"wifih-metrics-name-packrate_up": "Packet transfer rate (sent)",
	"wifih-metrics-name-packrate_down": "Packet transfer rate (received)",
	"wifih-metrics-name-lastratelink": "Last link rate (sent+received)",
	"wifih-metrics-name-lastratelink_up": "Last link rate (sent)",
	"wifih-metrics-name-lastratelink_down": "Last link rate (received)",
	"wifih-metrics-name-pack": "Packets count (sent+received)",
	"wifih-metrics-name-rssi": "RSSI (min/max/avg)",
	"wifih-metrics-name-noise": "Noise (min/max/avg)",
	"wifih-metrics-name-s": "Anomalies Severity",
	"wifih-metrics-name-wifi": "Channel/band changes",
	"wifih-metrics-name-parentinterfmac": "Wi-Fi Access Point changes",
	"wifih-metrics-name-parentip": "SSID/IP",
	"wifih-metrics-name-glitchrate": "Glitch rate",
	"wifih-metrics-group_min": "Minimum",
	"wifih-metrics-group_max": "Maximum",
	"wifih-metrics-group_avg": "Average",
	"wifih-metrics-group_up": "Sent",
	"wifih-metrics-group_down": "Received",
	"wifih-metrics-group_up_avg": "Sent (average)",
	"wifih-metrics-group_down_avg": "Received (average)",
	"wifih-metrics-group_idle": "Idle",
	"wifih-metrics-group_interference": "Interference",
	"wifih-metrics-group_recvother": "Received other",
	"wifih-metrics-group_recvself": "Received",
	"wifih-metrics-group_sent": "Sent",
	"wifih-metrics-airtimeisparent": "Airtime data is linked to parent equipment.",
	"wifih-metrics-chart-titile-1": "RSSI (min/max/avg) [dBm]",
	"wifih-metrics-chart-titile-2": "Channel/band changes",
	"wifih-metrics-chart-titile-3": "Noise (min/max/avg) [dBm]",
	"wifih-metrics-chart-titile-4": "Airtime [0-255]",
	"wifih-metrics-noiseisparent": "Noise data is linked to parent equipment.",
	"wifih-metrics-lastratelinkisparent": "The average physical data transmit/reception rate at the Access Point (AP) level that was the most recently used with respect to the associated station.",
	"wifih-metrics-lastratelinkupisparent": "The average physical data transmit rate at the Access Point (AP) level that was the most recently used with respect to the associated station.",
	"wifih-metrics-lastratelinkdownisparent": "The average physical data reception rate at the Access Point (AP) level that was the most recently used with respect to the associated station.",
	"wifih-metrics-byterateisparent": "The average number of bytes received/sent at the Access Point (AP) level with respect to the associated station, including framing characters.",
	"wifih-metrics-byterateupisparent": "The average number of bytes sent at the Access Point (AP) level with respect to the associated station, including framing characters.",
	"wifih-metrics-byteratedownisparent": "The average number of bytes received at the Access Point (AP) level with respect to the associated station, including framing characters.",
	"wifih-metrics-packrateisparent": "The average packet reception/transmission rate at the Access Point (AP) level with respect to the associated station.",
	"wifih-metrics-packrateupisparent": "The average packet transmission rate at the Access Point (AP) level with respect to the associated station.",
	"wifih-metrics-packratedownisparent": "The average packet reception rate at the Access Point (AP) level with respect to the associated station.",
	"wifih-metrics-packisparent": "The total number of packets received/sent at the Access Point (AP) level with respect to the associated station.",
	"wifih-metrics-wifi-link-tab": "Wi-Fi link",
	"wifih-metrics-wifi-link-tab-desc": "Metrics computed with respect to the corresponding parent gateway and/or extenders",
	"wifih-metrics-wifi-eqp-tab": "Wi-Fi equipment",
	"wifih-metrics-wifi-overview": "Wi-Fi overview",
	"wifih-metrics-wifi-eqp-tab-desc": "Metrics computed with respect to the connected child stations and/or extenders",
	"wifih-metrics-wifi-eqp-tab-iftitle": "Interfaces",
	"wifih-metrics-sys-eqp-tab": "System equipment metrics",
	"wifih-metrics-process-tab": "Process",
	"wifih-metric-status-up": "Up",
	"wifih-metric-status-down": "Down",
	"wifih-metric-status-disabled": "Disabled",
	"wifih-metric-dns-resolution": "DNS resolution over time",
	"wifih-metric-ping-wan-performance": "Ping WAN performance over time",
	"wifih-metric-select-station": "Select station",
	// h-metric-sys-equip
	"h-metric-sys-equip-flash": "Flash",
	"h-metric-sys-equip-proc": "Process",
	"h-metric-sys-equip-pie-reboot": "Reboot reasons",
	"h-metric-sys-equip-pie-procs": "Process",
	"h-metric-sys-equip-pie-procmem": "Process RAM usage",
	"h-metric-sys-equip-pie-proccpu": "Process CPU time",
	"h-metric-sys-equip-pie-procstatus": "Process statuses",
	// h-metric-sys-equip-summary-table
	"h-metric-sys-equip-summary-table": "System metrics",
	"h-metric-sys-equip-summary-table-cpu": "CPU load",
	"h-metric-sys-equip-summary-table-mem": "Memory usage (%)",
	"h-metric-sys-equip-summary-table-temperature": "Temperature (°C)",
	// h-metrics-sys-fields
	"h-metric-sys-field-reboot": "Reboot count",
	"h-metric-sys-field-uptime": "Uptime",
	"h-metric-sys-field-processmemusage": "Memory usage",
	"h-metric-sys-field-processcputime": "CPU time",
	"h-metric-sys-field-processcpuusage": "CPU usage",
	"h-metric-sys-field-processmemsize": "Memory size",
	"h-metric-sys-field-flashtotalerase": "Total eraseblocks size",
	"h-metric-sys-equip-flashtotalerase-desc": "Total amount of good (not marked as bad) physical eraseblocks",
	"h-metric-sys-field-flashavailerase": "Available eraseblocks",
	"h-metric-sys-equip-flashavailerase-desc": "Amount of available logical eraseblock",
	"h-metric-sys-field-flashbadpebcnt": "Bad PEB count",
	"h-metric-sys-equip-flashbadpebcnt-desc": "Count of bad physical eraseblocks",
	"h-metric-sys-field-flashmaxerasecnt": "Max eraseblock count",
	"h-metric-sys-equip-flashmaxerasecnt-desc": "Maximum physical eraseblock erase counter value",
	"h-metric-sys-field-flashvolumestrue": "Corrupted volumes",
	"h-metric-sys-field-flashvolumesfalse": "Uncorrupted volumes",
	"h-metric-sys-field-temperature": "Temperature",
	"h-metric-sys-field-crashevent": "Crash Events",
	"h-metric-sys-field-cpuload": "CPU load",
	"h-metric-sys-field-memory": "Memory",
	"h-metric-sys-group-lowlimit": "Low threshold limit",
	"h-metric-sys-group-highlimit": "High threshold limit",
	"h-metric-sys-group-tempval": "Temperature",
	"h-metric-sys-group-load1": "CPU Load 1",
	"h-metric-sys-group-load5": "CPU Load 5",
	"h-metric-sys-group-load15": "CPU Load 15",
	"h-metric-sys-group-memtotal": "Mem Total",
	"h-metric-sys-group-memfree": "Mem Free",
	"h-metric-sys-group-memcached": "Mem Cached",
	"h-metric-sys-group-memcommit": "Mem Commited",
	// wifih-metrics-eqp-summary-table
	"wifih-metrics-eqp-summary-table": "Wi-Fi Metrics",
	"wifih-metrics-eqp-summary-table-min": "Min.",
	"wifih-metrics-eqp-summary-table-avg": "Avg.",
	"wifih-metrics-eqp-summary-table-max": "Max.",
	"wifih-metrics-eqp-summary-table-total": "Total",
	"wifih-metrics-eqp-summary-table-rssi": "RSSI (dBm)",
	"wifih-metrics-eqp-summary-table-airtime": "Airtime usage (%)",
	"wifih-metrics-eqp-summary-table-noise": "Noise (dBm)",
	"wifih-metrics-eqp-summary-table-glitchrate": "Glitch rate (Hz)",
	"wifih-metrics-eqp-summary-table-uplinkrate": "Up link rate (Mb/s)",
	"wifih-metrics-eqp-summary-table-downlinkrate": "Down link rate (Mb/s)",
	// wifih-indic
	"h-indic-health": "Health",
	"h-dashboard-indic-health-global": "Household global health score",
	"h-dashboard-indic-health-wifi": "Household Wi-Fi health score",
	"h-dashboard-indic-health-wan": "Household WAN health score",
	"h-dashboard-indic-health-system": "Household system health score",
	"wifih-indic-ext": "EXT",
	"wifih-indic-ext-tip": "Total number of extenders (with CMS agent enabled)",
	"wifih-indic-stb": "STB",
	"wifih-indic-stb-tip": "Total number of set-top box (with CMS agent enabled)",
	"wifih-indic-dev": "Stations",
	"wifih-indic-dev-tip": "Total number of connected stations",
	"wifih-indic-timeperdev": "Time per station",
	"wifih-indic-timeperdev-tip": "Average connection time per station",
	"wifih-indic-elemname-tip": "Household identifier or MAC address of the selected station",
	"wifih-indic-avftimeeth": "Avg time on ETH",
	"wifih-indic-avftimeeth-tip": "Average connection time on Ethernet",
	"wifih-indic-avftimewifi": "Avg time on Wi-Fi",
	"wifih-indic-avftimewifi-tip": "Average connection time on Wi-Fi",
	"wifih-indic-rebootcnt": "Reboots",
	"wifih-indic-rebootcnt-tip": "Total number of reboots of the day",
	"wifih-indic-flashusage": "Flash usage",
	"wifih-indic-totaltime": "Total time",
	"wifih-indic-totaltime-tip": "Total connection time for all stations",
	// wifih-instability
	"wifih-instability-avg-time": "Average connection time",
	"wifih-instability-time": "See connection times",
	"wifih-instability-count": "See connection counts",
	"wifih-instability-bandstitle": "Bands",
	"wifih-instability-nodestitle": "Equipments",
	"wifih-instability-parentname": "Parent equipment",
	"wifih-instability-childname": "Connected element",
	// wifih-heatmap
	"wifih-heatmap-avg-rssi": "Average RSSI (dBm)",
	// management-users
	"admin-users-inputs": "Admin users inputs",
	"admin-users-status": "Status",
	"admin-users-filters": "Filters",
	"admin-users-list": "Users list",
	"admin-users-popup-update": "Update or delete a user",
	"admin-users-popup-add": "Add a new user",
	"admin-users-username": "Username",
	"admin-users-lastname": "Last Name",
	"admin-users-firstname": "First Name",
	"admin-users-email": "Email",
	"admin-users-company": "Company",
	"admin-users-jobtitle": "Job title",
	"admin-users-phonenumber": "Phone",
	"admin-users-password": "Password",
	"admin-users-password-comment": "Change the password",
	"admin-users-roles": "Roles",
	"admin-users-rolesbut": "Available roles",
	"admin-users-aclgroups": "ACL groups",
	"admin-users-aclgroups-select": "update",
	"admin-users-lastsuccessfullogin": "Last login",
	"admin-users-accountexpirationdate": "Expiration",
	"admin-users-enabled": "Enabled",
	"admin-users-accountnonexpired": "Account non-expired",
	"admin-users-credentialsnonexpired": "Credential non-expired",
	"admin-users-accountnonlocked": "Account non-locked",
	"admin-users-deletetip": "Suppress this user after confirmation",
	"admin-users-update-failed": "Update has failed",
	"admin-users-get-failed": "Read has failed",
	"admin-users-not-found": "Unknown user",
	"admin-users-confirmdelete": "Do you really want to delete this user, this operation cannot be undone",
	"admin-users-updateok": "Operation succeeded",
	// roles-legend-table
	"roles-legend-table-menu-page": "Menu / Page",
	"roles-legend-table-cust-supp-1": "Customer support Level 1",
	"roles-legend-table-cust-supp-2": "Customer support Level 2",
	"roles-legend-table-cust-supp-3": "Customer support Level 3",
	"roles-legend-table-cust-manager": "Customer base Manager",
	"roles-legend-table-cust-analyst": "Customer base Analyst",
	"roles-legend-table-rdm-manager": "Devices Manager",
	"roles-legend-table-admin": "Administrator",
	"roles-legend-table-welcome": "Home",
	"roles-legend-table-cust-dash": "Customer base / Dashboard",
	"roles-legend-table-cust-pops": "Customer base / Populations",
	"roles-legend-table-cust-rdm": "Customer base / RDM",
	"roles-legend-table-hoho-dash": "Household / Dashboard",
	"roles-legend-table-hoho-equip": "Household / Equipment",
	"roles-legend-table-hoho-stations": "Household / Stations",
	"roles-legend-table-hoho-incs": "Household / Incidents",
	"roles-legend-table-hoho-conn": "Household / Connectivity",
	"roles-legend-table-hoho-mets": "Household / Metrics",
	"roles-legend-table-hoho-elogs": "Household / Eventlogs",
	"roles-legend-table-mana-usrs": "Management / Users",
	"roles-legend-table-mana-acl": "Management / ACL Groups",
	// management-rdm
	"admin-rdm-filters": "Filters",
	"admin-rdm-uuid": "id",
	"admin-rdm-createtime": "Creation",
	"admin-rdm-updatetime": "Modification",
	"admin-rdm-expiretime": "Expiration",
	"admin-rdm-userlogin": "User",
	"admin-rdm-requestid": "Type",
	"admin-rdm-firmware": "Firmware",
	"admin-rdm-model": "Model",
	"admin-rdm-networkid": "Household Id",
	"admin-rdm-nodeimei": "Equipment Id",
	"admin-rdm-ttlid": "TTL",
	"admin-rdm-statusid": "Status",
	"admin-rdm-filter": "Filter",
	"admin-rdm-filterreset": "Filter Reset",
	"admin-rdm-filtertype": "Type",
	"admin-rdm-statusstatusreason": "Reason",
	"admin-rdm-response": "Response",
	"admin-rdm-comment": "Comment",
	"admin-rdm-uiaction": "",
	"admin-rdm-uiactionedit": "View action and modify comment",
	"admin-rdm-popup-add": "Create an action",
	"admin-rdm-createstart": "From",
	"admin-rdm-createend": "Until",
	"admin-rdm-button-refresh": "Refresh",
	"admin-rdm-button-search": "Search",
	"admin-rdm-canceltip": "Cancel this action after confirmation",
	"admin-rdm-cancel": "Cancel the current action",
	"admin-rdm-cancel-confirm": "Do you really want to cancel this action? This operation cannot be undone.",
	"admin-rdm-closenocancel": "Close without cancelling the action",
	"admin-rdm-closenosave": "Close without saving",
	"admin-rdm-confirmcancel": "Do you really want to cancel this action ? This operation cannot be undone.",
	"admin-rdm-createdby": "created by",
	"admin-rdm-reqerror": "Error while processing the request",
	"admin-rdm-reqlegend": "Available actions",
	"admin-rdm-reqlegend-action": "Action",
	"admin-rdm-reqlegend-description": "Description",
	"admin-rdm-showresponse": "Show full response",
	"admin-rdm-fullresponse": "Response",
	"admin-rdm-createaction": "Create this action",
	"admin-rdm-possiblereason": "Possible reasons",
	"admin-rdm-fill-field": "Please fill out this field",
	// management-acl
	"admin-acl-reqerror": "Error while processing the request",
	"acl-existing-name-error": "A group with this name already exists.",
	"acl-in-use-cannot-delete-error": "This group cannot be deleted since it is being used.",
	"admin-acl-filters": "Filters",
	"admin-acl-updatedatestart": "From",
	"admin-acl-updatedateend": "Until",
	"admin-acl-popup-add": "Create ACL group",
	"admin-acl-closenosave": "Close without saving",
	"admin-acl-uiaction": "",
	"admin-acl-uiactionedit": "View and update group",
	"admin-acl-uiactiondelete": "Delete the group after confirmation",
	"admin-acl-confirmdelete": "Do you really want to delete this group ? This operation cannot be undone.",
	"admin-acl-delete-blocked": "Deletion blocked: This ACL group is linked an active population",
	"admin-acl-delete": "Delete the group",
	"admin-acl-close-delete": "Close without deleting the group",
	"admin-acl-uiactionshow": "Display the list of household identifiers",
	"admin-acl-creationdate": "Creation",
	"admin-acl-updatedate": "Modification",
	"admin-acl-name": "Name",
	"admin-acl-default": "By default:",
	"admin-acl-default-exists": "A default group already exists.",
	"admin-acl-type": "Type",
	"admin-acl-id": "Identifier",
	"admin-acl-updatedbyuser": "Modified by",
	"admin-acl-listnetid": "List of network identifiers",
	"admin-acl-true": "True",
	"admin-acl-false": "False",
	// Dashboard > Activity
	"h-dashboard-activity": "Activity",
	"h-dashboard-devices": "Devices",
	"h-dashboard-stations": "Station(s)",
	"h-dashboard-connected-devices": "Connected devices",
	"h-dashboard-all-devices": "All devices",
	"h-dashboard-device-activity-detection": "Device activity detection",
	"h-dashboard-most-active-devices": "Most active devices",
	"h-dashboard-most-consuming-devices": "Most consuming devices",
	"h-dashboard-activity-low-activity": "Low activity",
	"h-dashboard-activity-high-activity": "High activity",
	"h-dashboard-activity-traffic": "Traffic",
	// Equipment
	"h-equipment-rdm-create-action": "Create an action:",
	"h-equipment-rdm-last-action": "Last actions created (#):",
	"h-equipment-date": "Date",
	// profile
	"profile-edit-profile": "Edit profile",
	"profile-last-connection": "Last connection",
	"profile-update-information": "Update information",
	"profile-current-password": "Current password",
	"profile-write-password": "Write your password",
	"profile-new-password": "New password",
	"profile-repeat-password": "Repeat password",
	"profile-password-validate-char-amount": "13 characters minimum",
	"profile-password-validate-lowercase": "At least one lowercase letter",
	"profile-password-validate-uppercase": "At least one uppercase letter",
	"profile-password-validate-number": "At least one number",
	"profile-password-validate-special-char": "At least one special character ! / : ; < = > ? @ [  \\  ] ^ _ ` { | } ~",
	"profile-change-password": "Change password",
	"profile-show-password": "Show password",
	"profile-phone-number": "Phone number",
	"profile-pic-updated": "Profile picture updated",
	"profile-pic-not-updated": "Profile picture could not be updated",	
	"profile-password-updated": "Password updated",
	"profile-password-not-updated": "Password could not be updated",
	"profile-password-same": "The new password must be different from the old one. Please enter a different password.",
	"profile-user-updated": "User information updated",
	"profile-user-not-updated": "User information could not be updated",
	"profile-modal-title": "Username Updated",
	"profile-modal-body": "Your profile has been updated. To ensure these changes take effect, you will be logged out. Please log in again to continue using the site.",
	// maintenance
	"maintenance-title": "System Maintenance",
	"maintenance-desc": "Sorry for the inconvenience but we’re performing some maintenance at the moment. We will be back online shortly!",
	"not-found-title": "Page not found",
	"not-found-desc": "Looks like you followed a bad link.",
	"not-found-action": "Home page",
	"not-applied": "N/A",
};
