import React from "react";
import moment from "moment";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5StoreH, E5StoreHStations, E5StoreHEquips} from "../../../store/E5StoreH";
import {E5EntHIncidWifi} from "../../../entity/household/incid/E5EntHIncidWifi";
import {E5EntHStation} from "../../../entity/household/topo/E5EntHStation";
import {E5EntHEquip} from "../../../entity/household/topo/E5EntHEquip";
import {E5HStationList} from "../../household/E5HStationList";
import {E5HEquipList} from "../../household/E5HEquipList";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5BandEnum} from "../../../entity/E5Enums";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestTopoItems.css";

//E5
interface E5TestTopoItemsState {}

//E5
interface E5TestTopoItemsProps {}

//E5
export const E5TestTopoItems = observer(class E5TestTopoItems extends React.PureComponent
	<E5TestTopoItemsProps, E5TestTopoItemsState> {

	// ---------------- MEMBERS ----------------

	//E5
	staidx: number = 0;

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let nodes: E5StoreHEquips = {equips: [], equipmap: new Map()},
			devices: E5StoreHStations = {stations: [], stationmap: new Map()},
			node: E5EntHEquip = new E5EntHEquip({
				imei: "imeiGW", manufacturer: "ManufacturerGW", model: "ModelGW", hardware_version: "GWHv1",
				software_version: "GWSV1", node_type: "GW", timezone: "TZ=CET-1CEST,M3.5.0/2,M10.5.0/3",
				health: Math.random(), last_seen: moment().unix(), time: moment().unix(),
				interfaces: [
					{
						supported_bandwidth: "20MHz,40MHz", current_operating_bandwidth: "40MHz",
						possible_channels: "1", channels_in_use: "1", auto_channel_enable: true, max_bit_rate: 4789,
						band: "2_4GHZ", status: "UP", capabilities: "ac,ax", standards_used: ["ax"],
						mac_address: "12:12:12:12:12:12", ssid: "WIFI1"
					},
					{
						supported_bandwidth: "40MHz,60MHz,80MHz,40MHz,60MHz,80MHz,40MHz,60MHz,80MHz",
						mac_address: "13:13:13:13:13:13", ssid: "WIFI2", status: "DOWN", band: "5GHZ",
						current_operating_bandwidth: "80MHz", auto_channel_enable: false, max_bit_rate: 3678,
						possible_channels: "1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9",
						channels_in_use: "1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9",
						capabilities: [
							"a", "n", "ax", "a", "n", "ax", "a", "n", "ax", "a", "n", "ax",
							"a", "n", "ax", "a", "n", "ax", "a", "n", "ax", "a", "n", "ax"
						],
						standards_used: [
							"a", "n", "ax", "a", "n", "ax", "a", "n", "ax", "a", "n", "ax",
							"a", "n", "ax", "a", "n", "ax", "a", "n", "ax", "a", "n", "ax"
						]
					},
					{
						supported_bandwidth: "20MHz", current_operating_bandwidth: "20MHz", possible_channels: "1,2,3",
						channels_in_use: "1,2", auto_channel_enable: true, max_bit_rate: 5890, band: "ETH1000",
						mac_address: "14:14:14:14:14:14", ssid: "ETH0", status: "UP"
					}
				]
			});
		nodes.equips.push(node);
		nodes.equipmap.set(node.imei, node);
		node = new E5EntHEquip({
			health: Math.random(), last_seen: moment().subtract(1, "minutes").unix(), time: moment().unix(),
			imei: "imeiEXT", manufacturer: "ManufacturerEXT", model: "ModelEXT", hardware_version: "EXTHv1",
			software_version: "EXTSV1", node_type: "EXT", timezone: "TZ=CET12CEST,M3.5.0/2,M10.5.0/3",
			interfaces: [
				{
					supported_bandwidth: "40MHz", current_operating_bandwidth: "40MHz", possible_channels: "1,2",
					channels_in_use: "1,2", auto_channel_enable: false, max_bit_rate: 2567, band: "2_4GHZ",
					mac_address: "20:20:20:20:20:20", ssid: "WIFI1", status: "UP"
				}
			]
		});
		nodes.equips.push(node);
		nodes.equipmap.set(node.imei, node);
		node = new E5EntHEquip({
			health: Math.random(), last_seen: moment().subtract(1, "hours").unix(), time: moment().unix(),
			imei: "imeiSTB", manufacturer: "ManufacturerSTB", model: "ModelSTB", hardware_version: "STBHv1",
			software_version: "STBSV1", node_type: "STB", timezone: "TZ=ZZZ0ZZZ,M3.5.0/2,M10.5.0/3",
			interfaces: [
				{
					supported_bandwidth: "40MHz,60MHz", current_operating_bandwidth: "60MHz",
					possible_channels: "1,2,3", channels_in_use: "1", auto_channel_enable: true, max_bit_rate: 6901,
					band: "2_4GHZ", mac_address: "20:20:20:20:20:20", ssid: "WIFI1", status: "up"
				}
			]
		});
		nodes.equips.push(node);
		nodes.equipmap.set(node.imei, node);

		let cnt: number = 12;
		for (let idx = 0; idx < cnt + 1; idx++) {
			let devtype: string = this.GetRandomDeviceType(), bands: string[] = [
				E5BandEnum.freq2ghz, E5BandEnum.freq5ghz, E5BandEnum.freq6ghz,
				E5BandEnum.eth, E5BandEnum.eth100, E5BandEnum.eth1000, E5EntHIncidWifi.bandunknown
			], iswifi: boolean = idx % 7 < 3, dev: E5EntHStation = new E5EntHStation({
				band: bands[idx % 7], current_operating_bandwidth: iswifi ? (idx + 1) * 5 + "MHZ" : "",
				channels_in_use: iswifi ? Array.from(Array(idx % 4 + 1).keys()).map(chan => chan + 1) : "",
				last_seen: moment().subtract(idx + 1, "minutes").unix(), link_type: iswifi ? "Wi-Fi" : "ETH",
				max_bit_rate: iswifi ? (idx + 2) * 256 : 0, hostname: devtype + " [" + idx + "]", device_type: devtype,
				ip: "192.168.0." + idx, mac_address: "80:80:80:80:80:" + (10 + idx), health: (idx / cnt),
				capabilities: iswifi ? ["n", "ac", "ax"] : "", standards_used: iswifi ? ["n", "ax"] : ""
			});
			dev.hostname += " => " + dev.devicetypecateg;
			devices.stations.push(dev);
			devices.stationmap.set(dev.macaddress, dev);
		}

		return <div className="e5testpage e5test-topo-items e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-topo-items-long")}</BP.H3>
			<div className="e5line-20">
				<div className="e5column-20">
					<BP.H3>Normal</BP.H3>
					<E5HEquipList indicsysinfo={E5StoreH.Ins().indicsysinfo} langinfo={E5Store.Ins().langinfo}
								  equipinfo={nodes} selectedinfo={E5StoreH.Ins().selectedinfo} minimal={false} showbox/>
					<E5HStationList langinfo={E5Store.Ins().langinfo} selectedinfo={E5StoreH.Ins().selectedinfo}
									searchniinfo={E5StoreH.Ins().searchniinfo} stationinfo={devices} minimal={false}/>
				</div>
				<div className="e5column-20">
					<BP.H3>Minimal</BP.H3>
					<E5HEquipList indicsysinfo={E5StoreH.Ins().indicsysinfo} langinfo={E5Store.Ins().langinfo}
								  equipinfo={nodes} selectedinfo={E5StoreH.Ins().selectedinfo} minimal showbox={false}/>
					<E5HStationList langinfo={E5Store.Ins().langinfo} searchniinfo={E5StoreH.Ins().searchniinfo}
									stationinfo={devices} selectedinfo={E5StoreH.Ins().selectedinfo} minimal/>
				</div>
			</div>
		</div>;
	}

	// ---------------- UTILS ----------------

	//E5
	GetRandomDeviceType = (): string => {
		let vals: string[] = [
			"phone", "pc", "smartphone", "null", "smart tv", "computer", "printer", "stb", "hidden", "router",
			"gameconsole", "tv_decoder", "audiovideo", "plc", "wifi_bridge", "network_storage", "BLACKLISTED",
			"IP phone", "FEMTO", "UNIK", "WIFI_REPEATER", "router", "PERIPHERAL", "MISCELLANEOUS", "NETWORKACCESSPOINT",
			"IMAGING", "TABLET", "NAS", "Multimedia device", "Game console", "DVR", "Camera", "eBook reader",
			"Unknown", "PERIPHERAL", "MISCELLANEOUS", "MOBILE_PHONE"
		];
		let val: string = vals[this.staidx];
		this.staidx++;
		if (this.staidx === vals.length) this.staidx = 0;
		return val;
	};
});
