import React from "react";
import { observer } from "mobx-react";
//
import { E5StoreLangInfo, E5StoreUserInfo } from "../../../store/E5Store";
import { E5EntRdmCommand } from "../../../entity/rdm/E5EntRdmCommand";
import { E5EntRdmStatus } from "../../../entity/rdm/E5EntRdmStatus";
import { E5UtilI18n } from "../../../global/E5MainLang";
import {
	E5StoreAdmin,
	E5StoreAdminRdmRequest, E5StoreAdminRdmStatus, E5StoreAdminRdmTtl
} from "../../../store/E5StoreAdmin";
//
import "./E5AdminRdmPopup.css";
import InputStyledField from "../../../global/component/InputStyledField";
import SelectStyled from "../../../global/component/SelectStyled";
import Table from '../../../global/component/E5Table'

//E5
interface E5AdminRdmPopupState {
	statecmd: E5EntRdmCommand;
	statecmdid: string;
	reqOpt: { label: string, value: number }[];
	ttlOpt: { label: string, value: number }[];
}

export interface E5AdminRdmPopupWiFiScanResult {
	bssid: string;
	channel: number;
	mode: number;
	radio: string;
	rssi: number;
	signalStrength: number;
	ssid: string;
}

//E5
export type E5AdminRdmUpdateCallback = ((rdm: E5EntRdmCommand) => void);

//E5
export type TableColumns = {
	row: {
		bssid: string;
		channel: number;
		mode: number;
		radio: string;
		rssi: number;
		signalStrength: number;
		ssid: string;
	}
}

//E5
interface E5AdminRdmPopupProps {
	langinfo: E5StoreLangInfo;
	cmd: E5EntRdmCommand;
	updatefunc: E5AdminRdmUpdateCallback;
	ttlinfo: E5StoreAdminRdmTtl;
	statusinfo: E5StoreAdminRdmStatus;
	requestinfo: E5StoreAdminRdmRequest;
	userinfo: E5StoreUserInfo;
}

//E5
export const E5AdminRdmPopup = observer(class E5AdminRdmPopup extends React.PureComponent
	<E5AdminRdmPopupProps, E5AdminRdmPopupState> {

	// ---------------- MEMBERS ----------------

	//E5
	netidinput: HTMLInputElement | undefined;
	netidinputref = (ref: any) => { this.netidinput = ref };

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5AdminRdmPopupProps, state: E5AdminRdmPopupState) {
		super(props, state);
		let statecmd: E5EntRdmCommand = new E5EntRdmCommand();
		statecmd.Copy(this.props.cmd);

		if (statecmd.uuid === "") {
			statecmd.userlogin = this.props.userinfo.curuser.username;
			statecmd.ttlid = 1;
			statecmd.requestid = 1;
		}

		this.state = { 
			statecmd: statecmd, 
			statecmdid: statecmd.uuid,
			reqOpt: this.props.requestinfo.requestlist.map((req) => { return { label: req.name, value: req.id }; }).sort((a, b) => a.value - b.value),
			ttlOpt: this.props.ttlinfo.ttllist.map((ttl) => { return { label: ttl.name, value: ttl.id }; }).sort((a, b) => a.value - b.value),
		};
	}

	//E5
	componentDidMount(): void {
		this.netidinput?.focus();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		let idx: number, isnew: boolean = this.state.statecmd.uuid === "";

		// dates
		let createdt: string = "", updatedt: string = "";
		if (!isnew) {
			createdt = this.state.statecmd.createtime.format(E5UtilI18n._("dateformat") + " HH:mm");
			updatedt = this.state.statecmd.updatetime.format(E5UtilI18n._("dateformat") + " HH:mm");
		}

		// status
		let statusdesc: string = "", statusname: string = "";
		let status: E5EntRdmStatus | undefined = this.props.statusinfo.statusmap.get(this.state.statecmd.statusid);
		if (status !== undefined) {
			statusdesc = status.description;
			statusname = status.name;
		}

		const newAction = () => {
			return (
				<>
					<div className="e5admin-rdm-popup-forms">
						<SelectStyled
							onChange={(event: any) => this.ChangeTextValue("requestid", event)}
							label={E5UtilI18n._("admin-rdm-requestid")}
							value={this.state.statecmd.requestid}
							defaultValue={this.state.reqOpt[0].value}
							data={this.state.reqOpt}
							required
						/>

						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-firmware")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("firmware", event)}
							value={this.state.statecmd.firmware}
							inputProps={{ sx: { borderRadius: 3 } }}
						/>

						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-networkid")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("networkid", event)}
							value={this.state.statecmd.networkid}
							inputProps={{ sx: { borderRadius: 3 } }}
						/>
					</div>

					<div className="e5admin-rdm-popup-forms e5admin-rdm-popup-margin">
						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-model")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("model", event)}
							value={this.state.statecmd.model}
							inputProps={{ sx: { borderRadius: 3 } }}
						/>

						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-nodeimei")}
							title={E5UtilI18n._("admin-rdm-fill-field")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("nodeimei", event)}
							value={this.state.statecmd.nodeimei}
							required
							inputProps={{ sx: { borderRadius: 3 } }}
						/>

						<SelectStyled
							onChange={(event: any) => this.ChangeTextValue("ttlid", event)}
							label={E5UtilI18n._("admin-rdm-ttlid")}
							value={this.state.statecmd.ttlid}
							defaultValue={this.state.ttlOpt[0].value}
							data={this.state.ttlOpt}
							required
						/>
					</div>

					<div className="e5admin-rdm-single-form e5admin-rdm-popup-margin">
						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-comment"
							label={E5UtilI18n._("admin-rdm-comment")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("comment", event)}
							value={this.state.statecmd.comment}
							inputProps={{ sx: { borderRadius: 3 } }}
						/>
					</div>
				</>
			)
		}

		const editAction = () => {
			return (
				<div>
					<div className="e5admin-rdm-popup-forms">
						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-createtime")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("createtime", event)}
							value={this.state.statecmd.createtime}
							disabled
							inputProps={{ sx: { borderRadius: 3 } }}
						/>

						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-updatetime")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("updatetime", event)}
							value={this.state.statecmd.updatetime}
							disabled
							inputProps={{ sx: { borderRadius: 3 } }}
						/>

						<SelectStyled
							onChange={(event: any) => this.ChangeTextValue("requestid", event)}
							label={E5UtilI18n._("admin-rdm-requestid")}
							value={this.state.statecmd.requestid}
							defaultValue={this.state.reqOpt[0].value}
							data={this.state.reqOpt}
							disabled
						/>
					</div>

					<div className="e5admin-rdm-popup-forms e5admin-rdm-popup-margin">
						<SelectStyled
							onChange={(event: any) => this.ChangeTextValue("ttlid", event)}
							label={E5UtilI18n._("admin-rdm-ttlid")}
							value={this.state.statecmd.ttlid}
							defaultValue={this.state.ttlOpt[0].value}
							data={this.state.ttlOpt}
							disabled
						/>

						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-networkid")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("networkid", event)}
							value={this.state.statecmd.networkid}
							disabled
							inputProps={{ sx: { borderRadius: 3 } }}
						/>

						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-nodeimei")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("nodeimei", event)}
							value={this.state.statecmd.nodeimei}
							disabled
							inputProps={{ sx: { borderRadius: 3 } }}
						/>
					</div>

					<div className="e5admin-rdm-popup-forms e5admin-rdm-popup-margin">
						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-model")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("model", event)}
							value={this.state.statecmd.model}
							disabled
							inputProps={{ sx: { borderRadius: 3 } }}
						/>

						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("id")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("uuid", event)}
							value={this.state.statecmd.uuid}
							disabled
							inputProps={{ sx: { borderRadius: 3 } }}
						/>

						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-popup-input"
							label={E5UtilI18n._("admin-rdm-firmware")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("firmware", event)}
							value={this.state.statecmd.firmware}
							disabled
							inputProps={{ sx: { borderRadius: 3 } }}
						/>
					</div>

					<div className="e5admin-rdm-single-form e5admin-rdm-popup-margin">
						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-comment"
							label={E5UtilI18n._("admin-rdm-comment")}
							variant="outlined"
							autoComplete="true"
							onChange={(event: any) => this.ChangeTextValue("comment", event)}
							value={this.state.statecmd.comment}
							inputProps={{ sx: { borderRadius: 3 } }}
						/>
					</div>

					<hr className="e5-admin-rdm-separator" />

					<h4 className="e5admin-rdm-response">{E5UtilI18n._("admin-rdm-response")}</h4>

					{this.state.statecmd.requestid === 8 && this.state.statecmd.statusid === 4 ? wifiScanResponse() : statusResponse()}
				</div>
			)
		}

		const statusResponse = () => {
			return (
				<>
					<div>
						<span className="e5admin-rdm-highlighted-text"> {E5UtilI18n._("admin-rdm-statusid")}:</span> 
						<span className={`role-in-table e5admin-popup-horizontalspacement rdm-status-id-${this.state.statecmd.statusid}`}>
							{`${statusname[0].toUpperCase()}${statusname.slice(1).toLowerCase()}`}
						</span>
					</div>
					<div className="e5admin-rdm-statusdesc e5admin-popup-verticalspacement">
						{statusdesc}
						<br />
						{this.state.statecmd.statusreason.split("Possible reasons:")[0]}

						<div className="e5admin-popup-verticalspacement">
							<span className="e5admin-rdm-highlighted-text">{E5UtilI18n._("admin-rdm-possiblereason")}:</span>
							{this.state.statecmd.statusreason.split("Possible reasons:")[1]}
						</div>
					</div>
				</>
			);
		};

		const wifiScanResponse = () => {
			return (
				<>
					<div>
						<span className="e5admin-rdm-highlighted-text">{E5UtilI18n._("admin-rdm-statusid")}:</span> <span className={`role-in-table rdm-status-id-${this.state.statecmd.statusid}`}>{statusname}</span>
					</div>

					<div className="e5admin-rdm-statusdesc e5admin-popup-verticalspacement">
						<Table className='' rowHeight={35}
							columns={[
								{
									field: 'bssid',
									headerName: 'BSSID',
									editable: true,
									headerAlign: 'center',
									align: 'left',
									width: 160,
									renderCell: (params: TableColumns) => {
										return params.row.bssid;
									},
								},
								{
									field: 'channel',
									headerName: 'Channel',
									editable: true,
									headerAlign: 'center',
									align: 'left',
									width: 80,
									renderCell: (params: TableColumns) => {
										return params.row.channel;
									},
								},
								{
									field: 'mode',
									headerName: 'Mode',
									editable: true,
									headerAlign: 'center',
									align: 'left',
									width: 80,
									renderCell: (params: TableColumns) => {
										return params.row.mode;
									},
								},
								{
									field: 'radio',
									headerName: 'Radio',
									editable: true,
									headerAlign: 'center',
									align: 'left',
									width: 100,
									renderCell: (params: TableColumns) => {
										return params.row.radio;
									},
								},
								{
									field: 'rssi',
									headerName: 'RSSI',
									editable: true,
									headerAlign: 'center',
									align: 'left',
									width: 80,
									renderCell: (params: TableColumns) => {
										return params.row.rssi;
									},
								},
								{
									field: 'signalStrength',
									headerName: 'Strength',
									editable: true,
									headerAlign: 'center',
									align: 'left',
									width: 90,
									renderCell: (params: TableColumns) => {
										return params.row.signalStrength;
									},
								},
								{
									field: 'ssid',
									headerName: 'SSID',
									editable: true,
									headerAlign: 'center',
									align: 'left',
									renderCell: (params: TableColumns) => {
										return params.row.ssid;
									},
								}
							]}
							rows={[
								...this.ParseJSON(this.state.statecmd.response).map((item, index) => {
									return ({
										...item,
										id: index,
									});
								})
							]}
						/>
					</div>
				</>
			);
		};

		return (
			<div className="e5admin-rdm-popup">
				{isnew ? newAction() : editAction()}
			</div>
		);
	}

	// ---------------- EVENTS ----------------

	//E5
	ChangeTextValue = (field: any, event: any) => {
		if (this.state.statecmd.uuid === "" || field === "comment") {
			let val = (field === "ttlid" || field === "requestid") ? event : event.target.value;
			let cmd: E5EntRdmCommand = new E5EntRdmCommand();

			cmd.Copy(this.state.statecmd);
			(cmd as any)[field] = val;

			this.setState({ statecmd: cmd });
			this.props.updatefunc(cmd);
		}
	};

	//E5
	ParseJSON(text: string): E5AdminRdmPopupWiFiScanResult[] {
		try {
			const parsed = JSON.parse(text);
			return parsed.wifiScanResult;
		} catch (e) {
			return [];
		}
	}
});
