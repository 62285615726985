import {E5EntHMetSys} from "./E5EntHMetSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHMetSysProc extends E5EntHMetSys {
	name: string = "";
	memusage: number = 0;
	cpuusage: number = 0;
	cputime: number = 0;
	memsize: number = 0;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.name = E5Entity.AssignString(json.name);
		this.memusage = E5Entity.ParseFloat(json.memory_usage);
		this.cputime = E5Entity.ParseInt(json.cpu_time);
		this.cpuusage = E5Entity.ParseInt(json.cpu_usage);
		this.memsize = E5Entity.ParseInt(json.mem_size);
		this.imei = E5Entity.AssignString(json.node_imei);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), name: this.name, memory_usage: this.memusage, cpu_time: this.cputime, cpu_usage: this.cpuusage, mem_size: this.memsize};
	}
}
