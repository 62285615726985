//E5
export class E5Entity {

	// -------- INSTANCE ------------

	//E5
	CopyFromJson(json: any): void {
		Object.assign(this, json);
	}

	//E5
	CopyToJson(): any {
		let json: any = {};
		Object.assign(json, this);
		return json;
	}

	//E5
	Copy(item: E5Entity): void {
		this.CopyFromJson(item.CopyToJson());
	}

	// -------- STATIC ------------

	//E5
	static ParseFloat(value: any): number {
		let nb: number = parseFloat(value);
		if (isNaN(nb))
			nb = 0;
		return nb;
	}

	//E5
	static ParseFloatNull(value: any): number | null {
		let nb: number | null = parseFloat(value);
		if (isNaN(nb))
			nb = null;
		return nb;
	}

	//E5
	static ParseFloatUndef(value: any): number | undefined {
		let nb: number | undefined = parseFloat(value);
		if (isNaN(nb))
			nb = undefined;
		return nb;
	}

	//E5
	static ParseInt(value: any): number {
		let nb: number = parseInt(value);
		if (isNaN(nb))
			nb = 0;
		return nb;
	}

	//E5
	static ParseIntNull(value: any): number | null {
		let nb: number | null = parseInt(value);
		if (isNaN(nb))
			nb = null;
		return nb;
	}

	//E5
	static ParseIntUndef(value: any): number | undefined {
		let nb: number | undefined = parseInt(value);
		if (isNaN(nb))
			nb = undefined;
		return nb;
	}

	//E5
	static ProcessStandards(std: any): string[] {
		let list: string[] = [], idx: number;
		if (typeof std === "string" && std.length > 0)
			list = std.split(",");
		else if (Array.isArray(std) && std.length > 0)
			list = std;
		for (idx = 0; idx < list.length; idx++) {
			list[idx] = list[idx].trim();//found some spaces sometimes...
			if (list[idx] === "unspecified" || list[idx] === "null")
				list[idx] = "";
		}
		for (idx = list.length - 1; idx >= 0; idx--) {
			if (list[idx] === "")
				list.splice(idx, 1)
		}
		return list;
	}

	//E5
	static AssignString(value: any): any {
		let ret: any;
		if (value === "null" || value === undefined || value === null)
			ret = "";
		else
			ret = value;
		return "" + ret;
	}

	//E5
	static AssignStringNA(value: any): any {
		let ret: any;
		if (value === "null" || value === undefined || value === null)
			ret = "N/A";
		else
			ret = value;
		return "" + ret;
	}
}
