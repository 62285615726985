import {E5EntHIncid} from "./E5EntHIncid";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidWan extends E5EntHIncid {
	wantype: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.wantype = E5Entity.AssignString(json.wan_type);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), wan_type: this.wantype};
	}
}
