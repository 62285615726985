import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5HDashboardIndic} from "../../household/dashboard/E5HDashboardIndic";
import {E5HMetricIndic} from "../../household/metric/E5HMetricIndic";
import {E5StoreHIndicGlobal} from "../../../store/E5StoreH";
import {E5NetElementType} from "../../../entity/E5Enums";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestHIndics.css";

//E5
interface E5TestHIndicsState {
	indic: E5StoreHIndicGlobal;
	elemtype: E5NetElementType;
	elemname: string;
	avgeth: number;
	avgwifi: number;
	health: number;
}

//E5
interface E5TestHIndicsProps {}

//E5
export const E5TestHIndics = observer(class E5TestHIndics extends React.PureComponent
	<E5TestHIndicsProps, E5TestHIndicsState> {

	// ---------------- MEMBERS ----------------

	//E5
	short: number = 10;
	long: number = 1000000;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestHIndicsProps, state: E5TestHIndicsState) {
		super(props, state);
		let elemtype: E5NetElementType = Math.random() > 0.5 ? E5NetElementType.node : E5NetElementType.device;
		this.state = {
			indic: this.GetIndic(this.short), elemtype: elemtype, elemname: elemtype, avgeth: this.GetInt(this.short),
			avgwifi: this.GetInt(this.short), health: Math.random()
		};
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		return <div className="e5testpage e5test-h-indics e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-h-indics-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Refresh short">
					<BP.Button onClick={() => this.Refresh(this.short)} icon="refresh"/>
				</BP.Callout>
				<BP.Callout title="Refresh long">
					<BP.Button onClick={() => this.Refresh(this.long)} icon="refresh"/>
				</BP.Callout>
			</div>
			<E5HDashboardIndic langinfo={E5Store.Ins().langinfo} indicglobalinfo={this.state.indic}/>
			<E5HMetricIndic
				langinfo={E5Store.Ins().langinfo} elemtype={this.state.elemtype} elemname={this.state.elemname}
				avgeth={this.state.avgeth} avgwifi={this.state.avgwifi} health={this.state.health}
			/>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Refresh = (num: number) => {
		let elemtype: E5NetElementType = Math.random() > 0.5 ? E5NetElementType.node : E5NetElementType.device;
		this.setState({
			indic: this.GetIndic(num), elemtype: elemtype, elemname: elemtype, avgeth: this.GetInt(num),
			avgwifi: this.GetInt(num), health: Math.random()
		});
	};

	// ---------------- UTILS ----------------

	//E5
	GetIndic = (num: number): E5StoreHIndicGlobal => {
		return {
			loading: false, healths: [], wifihealth: Math.random(), wanhealth: Math.random(),
			syshealth: Math.random(), globalhealth: Math.random(), unsureextstb: false, ext: this.GetInt(num),
			stb: this.GetInt(num), devices: this.GetInt(num), samplingperiod: this.GetInt(num),
			sendingperiod: this.GetInt(num)
		};
	};

	//E5
	GetInt = (num: number): number => {
		return Math.round(Math.random() * num);
	};
});
