import { observer } from "mobx-react";
import React from "react";
import { E5Store } from "../../../store/E5Store";
// import {E5Text} from "../../../util/E5Text";
// import {E5Time} from "../../../util/E5Time";
import * as BP from "@blueprintjs/core";
//
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5HEventLogTable } from "./E5HEventLogTable"
import { E5StoreH } from "../../../store/E5StoreH";
import { E5HFilters } from "../E5HFilters";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5EntHEventLog } from "../../../entity/household/eventlog/E5EntHEventLog";
import { Select, OutlinedInput, MenuItem, InputLabel, FormControl } from '@mui/material'
import { styled } from '@mui/system';
//
import "./E5HEventLog.css";

//E5
const StyledSelect = styled(Select)`
&& {
  &.MuiOutlinedInput-root {
    fieldset {
      border-color: #0080FF;
      border-radius: 10px;
    }
  },
 &.MuiFormLabel-root{
    display: none;
 }
&.MuiFormControl-root{
    margin: 0 !important;
}
}
`;
interface E5HEventlogState {
    selectedImeis: string[],
    chosenItems: string[],
    devicesInfo: { type: string, imei: string }[],
    isBandSteering: boolean,
}

//E5
interface E5HEventlogProps {
    location: any;
}

//E5
export const E5HEventlog = observer(class E5HEventlog extends React.PureComponent
    <E5HEventlogProps, E5HEventlogState> {

    toasterref: React.RefObject<BP.Toaster>;
    constructor(props: E5HEventlogProps, state: E5HEventlogState) {
        super(props, state);
        this.state = {
            selectedImeis: [],
            chosenItems: [],
            isBandSteering: false,
        }
        this.toasterref = React.createRef();
    }

    // ---------------- RENDER ----------------
    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const equip = params.get('equip');
        if (equip) {
            this.onChangeFilter(equip);
        } else {
            this.setState({ chosenItems: [] });
        }
    }

    componentDidUpdate() {
        const { eventlogsinfo } = E5StoreH.Ins();

        const eventLogs: E5EntHEventLog[] = eventlogsinfo.eventLogs;
        //nodeType
        const uniqueDevices = Array.from(new Set(eventLogs.map(({ deviceImei }) => deviceImei)))
        const devicesInfo = Array.from(new Set(eventLogs.map((item) => { return { imei: item.deviceImei, type: item.nodeType } })));

        const isDevicesUpdated = uniqueDevices.some(item => ![...this.state.selectedImeis || []].includes(item))

        if (isDevicesUpdated && eventLogs.length) {
            this.setState({ selectedImeis: uniqueDevices, devicesInfo: devicesInfo, chosenItems: [] })
        }
    }

    render(): JSX.Element {
        // force rerender when lang changes
        let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

        let { _ } = E5UtilI18n;//eslint-disable-line

        let { langinfo, pageinfo } = E5Store.Ins();

        let { searchniinfo, searchniautoinfo, eventlogsinfo } = E5StoreH.Ins();

        let eventLogs: E5EntHEventLog[] = eventlogsinfo.eventLogs;
        const { selectedImeis, isBandSteering } = this.state;
        const uniqueDevices = Array.from(new Set(eventLogs.map(({ deviceImei }) => deviceImei)))

        let filteredEventLogs = [...eventLogs].filter(({ deviceImei }) => {
            if (!selectedImeis) {
                return true
            } else {
                return selectedImeis.includes(deviceImei)
            }
        });

        const mappedEventLogs = filteredEventLogs.map(eventLog => {
            const trimmedArgs: any = {}
            Object.entries(eventLog.args).forEach(([key, value]) => {
                trimmedArgs[key.trim()] = value.trim()
            })
            return {
                eventTime: eventLog.eventTime,
                deviceImei: eventLog.deviceImei,
                eventType: eventLog.eventType,
                ...trimmedArgs
            }
        });

        return <div className="e5page e5columnfull e5column-20 e5h-eventlogs">
            <BP.Toaster ref={this.toasterref} />
            <div className="e5column-5">
                {/* <BP.Breadcrumbs items={[
                    { icon: "bookmark", text: E5UtilI18n._("mainwifititle") },
                    { text: E5UtilI18n._("mainmenu-household") },
                    { text: E5UtilI18n._("mainmenu-h-eventlogs") }
                ]} /> */}
                <E5PageTitle titlekey="pagetitle-h-eventlogs" />
            </div>
            <E5HFilters langinfo={langinfo} searchniinfo={searchniinfo}
                pageinfo={pageinfo} searchniautoinfo={searchniautoinfo} />
            <div className="e5compo e5h-eventlogs-content">
                <div className="e5line-20">
                    <div className='switch-container'>
                        <div className={!isBandSteering ? 'button active' : 'button'} onClick={() => this.setState({ isBandSteering: false })}>{E5UtilI18n._("cb-eventlog-connectivity")}</div>
                        <div className={isBandSteering ? 'button active' : 'button'} onClick={() => this.setState({ isBandSteering: true })}>{E5UtilI18n._("cb-eventlog-band-steering")}</div>
                    </div>
                    <FormControl className='filter' sx={{ m: 1, width: 300 }}>
                        <InputLabel id="name-label"> {E5UtilI18n._("cb-eventlog-filter-by-eqiupment")}</InputLabel>
                        <StyledSelect multiple labelId="name-label" value={this.state.chosenItems} input={<OutlinedInput label={E5UtilI18n._("cb-eventlog-filter-by-eqiupment")} />}>
                            <MenuItem value={''}>
                                {E5UtilI18n._("cb-eventlog-select-eqiupment")}
                            </MenuItem>
                            {uniqueDevices.map((name, index) => {
                                const deficeIinfo = this.state.devicesInfo?.find((item) => item.imei === name);
                                let img;
                                if (deficeIinfo?.type === 'EXT') {
                                    img = <img src='./img/v3/metrics/extender.svg' className='e5h-eventlogs-ext' alt="Extender" />
                                } else {
                                    img = <img src='./img/v3/metrics/gateway.svg' className='e5h-eventlogs-gtw' alt="Gateway" />
                                }
                                return <MenuItem onClick={() => this.onChangeFilter(name)} key={index} value={name}>
                                    {img}
                                    {name}
                                    {/* {this.state.chosenItems?.includes(name) ? <img className="logo" src="/img/v3/population/checked.svg" /> : null} */}
                                </MenuItem>
                            })}
                        </StyledSelect>
                    </FormControl>
                </div>
            </div>
            <E5HEventLogTable eventType={isBandSteering ? '1' : '0'} data={mappedEventLogs} chosenItems={this.state.chosenItems} langinfo={langinfo} />
        </div>;
    }

    onChangeFilter = (item: string) => {
        const chosenItems = [...this.state.chosenItems];
        if (chosenItems.includes(item)) {
            const index = chosenItems.indexOf(item);
            if (index > -1) { // only splice array when item is found
                chosenItems.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else {
            chosenItems.push(item)
        }
        this.setState({ chosenItems: chosenItems })
    }

    handleChange = (event: any) => {
        const { target: { value } } = event

        this.setState({ selectedImeis: typeof value === "string" ? value.split(",") : value })
    }
});
