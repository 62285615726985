//E5
export enum E5NodeTypeEnum {
	none = "",
	ext = "ext",
	gw = "gw",
	stb = "stb"
}

//E5
export enum E5BandEnum {
	none = "",
	freq2ghz = "2_4GHZ",
	freq5ghz = "5GHZ",
	freq6ghz = "6GHZ",
	eth = "ETH",
	eth100 = "ETH100",
	eth1000 = "ETH1000",
}

//E5
export function E5BandEnumIsEth(band: E5BandEnum): boolean {
	return band === E5BandEnum.eth || band === E5BandEnum.eth100 || band === E5BandEnum.eth1000;
}

//E5
export function E5BandEnumIsWifi(band: E5BandEnum): boolean {
	return band === E5BandEnum.freq2ghz || band === E5BandEnum.freq5ghz || band === E5BandEnum.freq6ghz;
}

//E5
export enum E5NetElementType {
	none = "",
	device = "device",
	node = "node"
}

//E5
export enum E5DeviceTypeEnum {
	unknown = "unknown",
	mobile = "mobile",
	desktop = "desktop",
	tv = "tv",
	tvadapter = "tvadapter",
	gaming = "gaming",
	tablet = "tablet",
	default = "default"
}

//E5
export enum E5TopologyLayoutTypeEnum {
	Breadthfirst = "breadthfirst",
	Cise = "cise",
	Cola = "cola",
	Cose = "cose",
	Dagre = "dagre",
	Euler = "euler",
	Klay = "klay",
	Null = "null",
}
