import React from 'react';
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import { RouteComponentProps } from "react-router";
//
import { E5Request } from "../request/E5Request";
import { E5EntUser } from "../entity/E5EntUser";
import { E5MainConfig } from "./E5MainConfig";
import { E5Store } from "../store/E5Store";
import { E5UtilI18n } from "./E5MainLang";
//
import "./E5MainHeader.css";
import { E5Page, E5Path } from './E5MainStatics';
import { E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender } from "../util/E5RestSender";
import { E5Storage } from "../util/E5Storage";
import DefaultSelect from './component/DefaultSelect';
import E5UserProfile from './component/E5UserProfile';
import { Classes } from '@blueprintjs/core';
import { getUser } from '../request/E5RequestProfile';

//E5
interface E5MainHeaderState {
	loaderactive: boolean;
	dimmererror: string;
	language: string;
	languageOptions: Array<string>;
	user: E5EntUser;
}

//E5
interface E5MainHeaderProps { }
// [refactor] for future - implementation link for API in ENV's file, create universal method
const openSwaggerInNewTab = () => {
	let url: string = E5MainConfig.GetBackendUrl();
	url = url.substr(0, url.length - 4);// removes "api/"
	window.open(url + "swagger-ui/index.html", "_blank");
};
//E5
export const E5MainHeader = observer(class E5MainHeader extends React.PureComponent
	<RouteComponentProps<{}> & E5MainHeaderProps, E5MainHeaderState> implements E5RestResponder {
	sender: E5RestSender;
	// ---------------- INIT ----------------
	//E5
	constructor(props: RouteComponentProps<{}> & E5MainHeaderProps, state: E5MainHeaderState) {
		super(props, state);
		this.sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v2");
		this.state = { loaderactive: true, dimmererror: "", language: 'English', user: E5Store.Ins().GetUser(), languageOptions: ['English', 'Français'] };
	}

	componentDidMount(): void {
		this.UpdateUser();
	}

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let userPopover = (
			<div className={Classes.POPOVER_DISMISS}>
				<div className="headermenu-v3 e5column-10">
					<div className='header-link-v3' onClick={() => this.props.history.push(E5Path.Get(E5Page.PROFILE))}>
						<img src="./img/v3/profile/profile.svg" alt={E5UtilI18n._("mainheader-my-profile")} />
						<span>
							{E5UtilI18n._("mainheader-my-profile")}
						</span>
					</div>
				</div>
			</div>
		)
		return <div className="e5mainheader e5line-10">
			<img className="logo" src="/img/logoblue.svg" alt="logo sagemcom" onClick={() => this.props.history.push(E5Path.Get(E5Page.HOME))} />
			<div className="sectiontitle">{E5UtilI18n._("mainwifititle")}</div>
			<div className="e5linefull" />

			<BP.Popover openOnTargetFocus hasBackdrop content={userPopover}>
				<div className="user">
					<E5UserProfile size={36} fontSize={15} user={this.state.user} />
				</div>
			</BP.Popover>

			<BP.Popover openOnTargetFocus hasBackdrop content={
				<div className="headermenu e5column-10">
					<span className='header-link' onClick={() => this.props.history.push(E5Path.Get(E5Page.WELCOME_HOW))}>{E5UtilI18n._("sidemenu-how-works")}</span>
					<span className='header-link'
						onClick={openSwaggerInNewTab}
					//onClick={()=>this.props.history.push(E5Path.Get(E5Page.DEVELOPERS))}
					>API dev</span>
					<div className='switch-container'>
						<span>Theme:</span>
						<div className='theme-switch-content'>
							<img src='./img/v3/burger_menu/light.svg' className="theme-switch-light-icon" alt="Light theme" />
							<BP.Switch disabled />
							<img src='./img/v3/burger_menu/dark.svg' alt="Dark theme" />
						</div>
					</div>
					<div className='switch-container'>

						<span>Language:</span>

						<DefaultSelect
							data={this.state.languageOptions}
							onChange={(language: string) => this.ChangeLang(language)}
							className='theme-switch-select text-spacement'
							value={this.state.language} />

					</div>
					<span className='header-link' onClick={() => this.props.history.push(E5Path.Get(E5Page.WELCOME))}>Help</span>
					<BP.Button onClick={this.Disconnect} className='disconnect-button'>
						{E5UtilI18n._("mainheader-disconnect")}
					</BP.Button>
				</div>
			}>
				<BP.Icon icon='menu' className='burgermenu' />
			</BP.Popover>
			<img className="e5custlogo" src={E5MainConfig.GetCustomerLogo()} alt="" />
		</div>;
	}

	//E5
	ChangeLang = (lang: string): void => {
		// [refactor] for future - implementation ENUM's file on global level
		switch (lang) {
			case 'English':
				this.setState({ language: lang });
				E5Store.Ins().ChangeLang('en')
				break;

			case 'Français':
				this.setState({ language: lang });
				E5Store.Ins().ChangeLang('fr')
				break
		}


	};

	//E5
	Disconnect = (): void => {
		E5Request.Ins().ClearAll();
		E5Store.Ins().ChangeUser(new E5EntUser(), this.props.history);
		let req: E5RestRequest = {
			method: "POST",
			uri: "/logout",
			header: [
				["Content-Type", "application/json"],
				["Authorization", E5Storage.GetLSString(E5MainConfig.ls_bearer)]
			],
			body: undefined
		};
		this.sender.Send(req)
	};

	//E5
	UpdateUser = () => {
		getUser(E5Store.Ins().GetUser().username)
			.then((user) => {
				this.setState({ user: new E5EntUser(user) })
			})
	};
});
