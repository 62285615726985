import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5CBPopAction, E5CBPopSaveReceive, E5CBPopList } from "./E5CBPopList";
import { E5StoreCBPop, E5StoreCBPops } from "../../../store/E5StoreCBPop";
import { E5EntCBPop } from "../../../entity/customer_base/E5EntCBPop";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5RequestStatus } from "../../../request/E5ServiceCommon";
import { E5RequestCBPop } from "../../../request/E5RequestCBPop";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Store } from "../../../store/E5Store";
//
import "./E5CBPopulations.css";

//E5
interface E5CBPopulationsState { }

//E5
interface E5CBPopulationsProps {
	popinfo: E5StoreCBPops;
}

//E5
export const E5CBPopulations = observer(class E5CBPopulations extends React.PureComponent
	<E5CBPopulationsProps, E5CBPopulationsState> {

	// ---------------- MEMBERS ----------------

	//E5
	toasterref: React.RefObject<BP.Toaster>;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5CBPopulationsProps, state: E5CBPopulationsState) {
		super(props, state);
		this.toasterref = React.createRef();
	}

	//E5
	componentDidMount(): void {
		if (this.props.popinfo.pops.length === 0) E5RequestCBPop.Ins().FetchPops(undefined);
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		return <div className="e5page e5columnfull e5column-20">
			<BP.Toaster ref={this.toasterref} />
			<div className="e5column-5">
				{/* <BP.Breadcrumbs items={[
					{ icon: "bookmark", text: E5UtilI18n._("mainwifititle") },
					{ text: E5UtilI18n._("mainmenu-customer-base") },
					{ text: E5UtilI18n._("mainmenu-cb-populations") }
				]} /> */}
				<E5PageTitle titlekey="pagetitle-cb-populations" />
			</div>
			<div className="e5wificb-populations-content e5columnfull">
				<E5CBPopList langinfo={E5Store.Ins().langinfo} popinfo={E5StoreCBPop.Ins().popinfo}
					popgetinfo={E5StoreCBPop.Ins().popgetinfo} savefunc={this.SavePop}
					nonetidsfunc={this.HandleNoNetIds} />
			</div>
		</div>;
	}

	// ---------------- UTILS ----------------

	//E5
	HandleNoNetIds = (status: E5RequestStatus): void => {
		if (!status.success) this.toasterref.current?.show({
			message: E5UtilI18n._("wificb-pops-actions") + " : " + status.message, intent: BP.Intent.DANGER
		});
	};

	//E5
	SavePop = (action: E5CBPopAction, pop: E5EntCBPop, popid: string, infoonly: boolean,
		callback: E5CBPopSaveReceive) => {
		if (action === E5CBPopAction.update) {
			E5RequestCBPop.Ins().UpdatePop(popid, pop, infoonly,
				(status: E5RequestStatus, newpop: E5EntCBPop) => {
					// notify the list
					callback(status.success, status.message);

					if (status.success) {
						this.toasterref.current?.show(
							{ message: E5UtilI18n._("wificb-pops-updateok"), intent: BP.Intent.SUCCESS });
						this.ChangePopInList(popid, newpop);
					} else this.toasterref.current?.show({
						message: E5UtilI18n._("wificb-pops-update-failed") + " : " + status.message,
						intent: BP.Intent.DANGER
					});
				});
		} else if (action === E5CBPopAction.add) {
			E5RequestCBPop.Ins().AddPop(pop, (status: E5RequestStatus, newpop: E5EntCBPop) => {
				// notify the list
				callback(status.success, status.message, newpop);

				if (status.success) {
					this.toasterref.current?.show(
						{ message: E5UtilI18n._("wificb-pops-updateok"), intent: BP.Intent.SUCCESS });
					this.AddPopInList(newpop);
				} else this.toasterref.current?.show({
					message: E5UtilI18n._("wificb-pops-update-failed") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});
		} else if (action === E5CBPopAction.delete) {
			E5RequestCBPop.Ins().DeletePop(popid, (status: E5RequestStatus) => {
				// notify the list
				callback(status.success, status.message);

				if (status.success) {
					this.toasterref.current?.show(
						{ message: E5UtilI18n._("wificb-pops-updateok"), intent: BP.Intent.SUCCESS });
					this.DeletePopInList(popid);
				} else this.toasterref.current?.show({
					message: E5UtilI18n._("wificb-pops-update-failed") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});
		}
	};

	//E5
	ChangePopInList = (popid: string, pop: E5EntCBPop): void =>
		E5StoreCBPop.Ins().SetPops({ loading: false, success: true, message: "" },
			this.props.popinfo.pops.map(curpop => curpop.populationid === popid ? pop : curpop));

	//E5
	AddPopInList = (pop: E5EntCBPop): void =>
		E5StoreCBPop.Ins().SetPops({ loading: false, success: true, message: "" },
			[...this.props.popinfo.pops, pop]);

	//E5
	DeletePopInList = (popid: string): void => {
		E5StoreCBPop.Ins().SetPops({ loading: false, success: true, message: "" },
			this.props.popinfo.pops.filter(pop => pop.populationid !== popid));
	};
});
