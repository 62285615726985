import { action, makeObservable, observable } from "mobx";
//
import { E5EntCBSysOverMet } from "../entity/customer_base/E5EntCBSysOverMet";
import { E5RequestStatus } from "../request/E5ServiceCommon";
import { E5CBHealthClass } from "./E5StoreCB";

// --------- DATA -------------

//E5
export type E5CBSysMetType = "cpu" | "memory" | "temperature";

export interface E5StoreCBSysOverview {
	status: E5RequestStatus;
	health: number | null | undefined;
	cpuhealth: number | null | undefined;
	memhealth: number | null | undefined;
	rebhealth: number | null | undefined;
	temphealth: number | null | undefined;
	prochealth: number | null | undefined;
	flashhealth: number | null | undefined;
	inccritdist: Map<string, number> | null | undefined;
	metrics: E5EntCBSysOverMet[];
	healthmetmap: Map<string, Map<number, E5CBHealthClass>> | null | undefined
}

export interface E5CBSysClass {
	class: string;
	rank: number;
	eqptype: string;
	count: number;
}
export interface CPUExGtClass {
	class: string;
	rank: number;
	eqptype: string;
	count: number;
	sofv: string;
	model: string;
}
export interface E5CBSysClassDetails {
	class: string;
	rank: number
	model: string;
	sofv: string;
	count: number;
}

export interface E5CBSysMet {
	date: string;
	gw: E5CBSysClass[];
	ext: E5CBSysClass[];
	stb: E5CBSysClass[];
}

export interface E5StoreCBSysData {
	status: E5RequestStatus;
	health: number | null | undefined;
	equips: E5CBSysClass[] | null | undefined;
	details: E5CBSysClassDetails[] | null | undefined;
	metrics: E5CBSysMet[];
	detailsPerExtender: E5CBSysClassDetails[] | null | undefined;
	detailsPerGateway: E5CBSysClassDetails[] | null | undefined;

}

export interface E5StoreCBSysReboot {
	status: E5RequestStatus;
	health: number | null | undefined;
	reasonmap: Map<string, number> | null | undefined;
	rebequips: E5CBSysClass[] | null | undefined;
	rebdetails: E5CBSysClassDetails[] | null | undefined;
	rebmetrics: E5CBSysMet[];
	uptequips: E5CBSysClass[] | null | undefined;
	uptdetails: E5CBSysClassDetails[] | null | undefined;
	uptmetrics: E5CBSysMet[];
}

export interface E5CBSysProcMet {
	date: string;
	vals: Map<string, number>;
}

export interface E5StoreCBSysProcess {
	status: E5RequestStatus;
	health: number | null | undefined;
	cpuprocmap: Map<string, number> | null | undefined;
	memprocmap: Map<string, number> | null | undefined;
	crashprocmap: Map<string, number> | null | undefined;
	cpuprocmetrics: E5CBSysProcMet[];
	memprocmetrics: E5CBSysProcMet[];
	crashprocmetrics: E5CBSysProcMet[];
	procstatmetrics: E5CBSysProcMet[];
}

export interface E5StoreCBSysFlash {
	status: E5RequestStatus;
	health: number | null | undefined;
	flusaequips: E5CBSysClass[] | null | undefined;
	flusadetails: E5CBSysClassDetails[] | null | undefined;
	flusametrics: E5CBSysMet[];
	flcorequips: E5CBSysClass[] | null | undefined;
	flcordetails: E5CBSysClassDetails[] | null | undefined;
	flcormetrics: E5CBSysMet[];
}

// --------- STORE ITSELF -------------

//E5
export class E5StoreCBSys {

	// --------- STATIC MEMBERS -------------

	//E5
	private static ins: E5StoreCBSys;

	//E5
	static Ins(): E5StoreCBSys {
		if (E5StoreCBSys.ins === undefined) E5StoreCBSys.ins = new E5StoreCBSys();
		return E5StoreCBSys.ins;
	}

	// --------- STATIC STORE MODIFICATION -------------

	//E5
	SetOverview(status: E5RequestStatus, health: number | null | undefined, cpuhealth: number | null | undefined,
		memhealth: number | null | undefined, rebhealth: number | null | undefined, temphealth: number | null | undefined,
		prochealth: number | null | undefined, flashhealth: number | null | undefined,
		inccritdist: Map<string, number> | null | undefined, metrics: E5EntCBSysOverMet[], healthmetmap: Map<string, Map<number, E5CBHealthClass>> | null | undefined
	): void {
		E5StoreCBSys.Ins().overview.status = status;
		E5StoreCBSys.Ins().overview.health = health;
		E5StoreCBSys.Ins().overview.cpuhealth = cpuhealth;
		E5StoreCBSys.Ins().overview.memhealth = memhealth;
		E5StoreCBSys.Ins().overview.rebhealth = rebhealth;
		E5StoreCBSys.Ins().overview.temphealth = temphealth;
		E5StoreCBSys.Ins().overview.prochealth = prochealth;
		E5StoreCBSys.Ins().overview.flashhealth = flashhealth;
		E5StoreCBSys.Ins().overview.inccritdist = inccritdist;
		E5StoreCBSys.Ins().overview.metrics = metrics;
		E5StoreCBSys.Ins().overview.healthmetmap = healthmetmap;
	}

	//E5
	SetData(key: E5CBSysMetType, status: E5RequestStatus, health: number | null | undefined,
		equips: E5CBSysClass[] | null | undefined, details: E5CBSysClassDetails[] | null | undefined, metrics: E5CBSysMet[],
		detailsPerExtender: E5CBSysClass[] | null | undefined, detailsPerGateway: E5CBSysClass[] | null | undefined
	): void {
		E5StoreCBSys.Ins()[key].status = status;
		E5StoreCBSys.Ins()[key].health = health;
		E5StoreCBSys.Ins()[key].equips = equips;
		E5StoreCBSys.Ins()[key].details = details;
		E5StoreCBSys.Ins()[key].metrics = metrics;
		E5StoreCBSys.Ins()[key].detailsPerExtender = detailsPerExtender;
		E5StoreCBSys.Ins()[key].detailsPerGateway = detailsPerGateway;

	}

	//E5
	SetReboot(status: E5RequestStatus, health: number | null | undefined,
		reasonmap: Map<string, number> | null | undefined, rebequips: E5CBSysClass[] | null | undefined,
		rebdetails: E5CBSysClassDetails[] | null | undefined, rebmetrics: E5CBSysMet[],
		uptequips: E5CBSysClass[] | null | undefined, uptdetails: E5CBSysClassDetails[] | null | undefined,
		uptmetrics: E5CBSysMet[]): void {
		E5StoreCBSys.Ins().reboot.status = status;
		E5StoreCBSys.Ins().reboot.health = health;
		E5StoreCBSys.Ins().reboot.reasonmap = reasonmap;
		E5StoreCBSys.Ins().reboot.rebequips = rebequips;
		E5StoreCBSys.Ins().reboot.rebdetails = rebdetails;
		E5StoreCBSys.Ins().reboot.rebmetrics = rebmetrics;
		E5StoreCBSys.Ins().reboot.uptequips = uptequips;
		E5StoreCBSys.Ins().reboot.uptdetails = uptdetails;
		E5StoreCBSys.Ins().reboot.uptmetrics = uptmetrics;
	}

	//E5
	SetProcess(status: E5RequestStatus, health: number | null | undefined,
		cpuproc: Map<string, number> | null | undefined, crashproc: Map<string, number> | null | undefined, memproc: Map<string, number> | null | undefined,
		cpuprocmetrics: E5CBSysProcMet[], memprocmetrics: E5CBSysProcMet[], crashprocmetrics: E5CBSysProcMet[], procstatmetrics: E5CBSysProcMet[]): void {
		E5StoreCBSys.Ins().process.status = status;
		E5StoreCBSys.Ins().process.health = health;
		E5StoreCBSys.Ins().process.cpuprocmap = cpuproc;
		E5StoreCBSys.Ins().process.memprocmap = memproc;
		E5StoreCBSys.Ins().process.crashprocmap = crashproc;
		E5StoreCBSys.Ins().process.cpuprocmetrics = cpuprocmetrics;
		E5StoreCBSys.Ins().process.memprocmetrics = memprocmetrics;
		E5StoreCBSys.Ins().process.crashprocmetrics = crashprocmetrics;
		E5StoreCBSys.Ins().process.procstatmetrics = procstatmetrics;
	}

	//E5
	SetFlash(status: E5RequestStatus, health: number | null | undefined,
		flusaequips: E5CBSysClass[] | null | undefined, flusadetails: E5CBSysClassDetails[] | null | undefined,
		flusametrics: E5CBSysMet[], flcorequips: E5CBSysClass[] | null | undefined,
		flcordetails: E5CBSysClassDetails[] | null | undefined, flcormetrics: E5CBSysMet[]): void {
		E5StoreCBSys.Ins().flash.status = status;
		E5StoreCBSys.Ins().flash.health = health;
		E5StoreCBSys.Ins().flash.flusaequips = flusaequips;
		E5StoreCBSys.Ins().flash.flusadetails = flusadetails;
		E5StoreCBSys.Ins().flash.flusametrics = flusametrics;
		E5StoreCBSys.Ins().flash.flcorequips = flcorequips;
		E5StoreCBSys.Ins().flash.flcordetails = flcordetails;
		E5StoreCBSys.Ins().flash.flcormetrics = flcormetrics;
	}

	// --------- INSTANCE -------------

	overview: E5StoreCBSysOverview = {
		status: { loading: false, success: false, message: "" }, health: undefined, cpuhealth: undefined,
		memhealth: undefined, rebhealth: undefined, temphealth: undefined, prochealth: undefined,
		flashhealth: undefined, inccritdist: undefined, metrics: [], healthmetmap: undefined
	};
	cpu: E5StoreCBSysData = {
		status: { loading: false, success: false, message: "" }, health: undefined, equips: undefined, details: undefined,
		metrics: [], detailsPerExtender: [], detailsPerGateway: [],
	};
	memory: E5StoreCBSysData = {
		status: { loading: false, success: false, message: "" }, health: undefined, equips: undefined, details: undefined,
		metrics: [], detailsPerExtender: [], detailsPerGateway: []
	};
	reboot: E5StoreCBSysReboot = {
		status: { loading: false, success: false, message: "" }, health: undefined, reasonmap: undefined,
		rebequips: undefined, rebdetails: undefined, rebmetrics: [], uptequips: undefined, uptdetails: undefined,
		uptmetrics: []
	};
	temperature: E5StoreCBSysData = {
		status: { loading: false, success: false, message: "" }, health: undefined, equips: undefined, details: undefined,
		metrics: [], detailsPerExtender: [], detailsPerGateway: [],
	};
	process: E5StoreCBSysProcess = {
		status: { loading: false, success: false, message: "" }, health: undefined, cpuprocmap: undefined, crashprocmap: undefined,
		memprocmap: undefined, memprocmetrics: [], cpuprocmetrics: [], crashprocmetrics: [], procstatmetrics: []
	};
	flash: E5StoreCBSysFlash = {
		status: { loading: false, success: false, message: "" }, health: undefined, flusaequips: undefined,
		flusadetails: undefined, flusametrics: [], flcorequips: undefined, flcordetails: undefined, flcormetrics: []
	};

	//E5
	private constructor() {
		makeObservable(this, {
			overview: observable, cpu: observable, memory: observable, reboot: observable, temperature: observable,
			process: observable, flash: observable, SetOverview: action, SetData: action, SetReboot: action,
			SetProcess: action, SetFlash: action
		});
	}
}
