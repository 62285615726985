import {E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender} from "../util/E5RestSender";
import {E5RequestCallback, E5RequestStatus} from "./E5ServiceCommon";
import {E5CBHealthClass, E5StoreCB} from "../store/E5StoreCB";
import {E5EntWifiChanTime} from "../entity/E5EntWifiChanTime";
import {E5MainConfig} from "../global/E5MainConfig";
import {E5UtilI18n} from "../global/E5MainLang";
import {E5RequestMeta} from "./E5RequestMeta";
import {E5BandEnum} from "../entity/E5Enums";
import {E5Storage} from "../util/E5Storage";
import {E5Entity} from "../entity/E5Entity";
import {E5Request} from "./E5Request";
import {E5RequestCBSys} from "./E5RequestCBSys";
//E5
export enum E5RequestCBTags {GLOBAL = "global", WIFI = "Wi-Fi", WAN = "wan", NILIST = "nilist"}

//E5
export class E5RequestCB implements E5RestResponder {

	// --------- STATIC -------------

	//E5
	private static ins: E5RequestCB;

	//E5
	static Ins(): E5RequestCB {
		if (E5RequestCB.ins === undefined) E5RequestCB.ins = new E5RequestCB();
		return E5RequestCB.ins;
	}

	// --------- INSTANCE -------------

	//E5
	sender: E5RestSender;
	tokenmap: Map<string, number>;

	//E5
	constructor() {
		this.sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v4");
		this.tokenmap = new Map();
	}

	//E5
	RequestCallback(resp: E5RestResponse): void {
		E5RequestCB.Ins().ClearMessageStatus();
		E5RequestCBSys.Ins().ClearMessageStatus();
		if (resp.request.otherdata.requesttag === E5RequestCBTags.GLOBAL) E5RequestCB.GlobalCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestCBTags.WIFI) E5RequestCB.WifiCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestCBTags.WAN) E5RequestCB.WanCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestCBTags.NILIST) E5RequestCB.NiListCB(resp);
	}

	//E5
	CancelTag = (tag: E5RequestCBTags): void => this.sender.Cancel(this.tokenmap.get(tag));

	//E5
	ClearAll(): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""}, udf: undefined = undefined;
		E5StoreCB.Ins().SetRequestSet(new Set());
		E5StoreCB.Ins().SetGlobal({...status}, udf, udf, udf, udf, udf, udf, udf);
		E5StoreCB.Ins().SetWifi({...status}, udf, udf, udf, udf, udf, udf, udf, udf, [], udf, udf);
		E5StoreCB.Ins().SetWan({...status}, udf, udf, udf, udf);
		E5StoreCB.Ins().SetNiList({...status}, []);
		E5StoreCB.Ins().SetNiListNodes([]);
		E5StoreCB.Ins().SetNiListUsages([]);

		E5RequestCB.Ins().CancelTag(E5RequestCBTags.GLOBAL);
		E5RequestCB.Ins().CancelTag(E5RequestCBTags.WIFI);
		E5RequestCB.Ins().CancelTag(E5RequestCBTags.WAN);
		E5RequestCB.Ins().CancelTag(E5RequestCBTags.NILIST);
	}

	ClearMessageStatus(): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""}
		E5StoreCB.Ins().global.status=status;
		E5StoreCB.Ins().wifi.status=status;
		E5StoreCB.Ins().wan.status=status;
		E5StoreCB.Ins().nilist.status=status;
	}
	// --------- Common -------------

	//E5
	private Fetch(uri: string, requesttag: E5RequestCBTags, callback: E5RequestCallback): void {
		this.sender.Cancel(this.tokenmap.get(requesttag));
		let bearer: string|undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer), req: E5RestRequest = {
			method: "GET", header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			uri, body: undefined, otherdata: {requesttag, callback}
		};
		this.tokenmap.set(requesttag, this.sender.Send(req));
	}

	// --------- Global -------------

	//E5
	static FetchGlobal(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = {loading: true, success: false, message: ""}, udf: undefined = undefined;
		E5StoreCB.Ins().SetGlobal(status, udf, udf, udf, udf, udf, udf, udf);
		E5RequestCB.Ins().Fetch("/populationdata/" + popid + "/global/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBTags.GLOBAL, callback);
	}

	//E5
	private static GlobalCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("wificb-dashboard-notfound");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let udf: undefined = undefined, health: number|null|undefined = udf,
				healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined = udf,
				statypemap: Map<string, number>|null|undefined = udf,
				eqpdetailmap: Map<string, Map<string, number>>|null|undefined = udf,
				equipmap: Map<string, Map<string, number>>|null|undefined = udf,
				stationmap: Map<string, Map<string, number>>|null|undefined = udf,
				maxavgmap: Map<string, Map<string, number>>|null|undefined = udf;
			if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
				health = E5Entity.ParseFloatNull(resp.body.kpi.global_health);
				statypemap = new Map();
				if (Array.isArray(resp.body.kpi.station_count_per_type))
					statypemap = resp.body.kpi.station_count_per_type.reduce((acc: Map<string, number>, cur: any) =>
						acc.set(cur.device_type === null ? "N/A" : E5Entity.AssignString(cur.device_type),
							E5Entity.ParseFloat(cur.average)), new Map());
			}
			if (typeof resp.body.metrics === "object" && resp.body.metrics !== null) {
				healthmetmap = E5RequestCB.ParseHealthMetrics(resp.body.metrics.global_health_metric);
				eqpdetailmap = new Map();
				if (Array.isArray(resp.body.metrics.equipment_per_model_metric)) {
					eqpdetailmap = resp.body.metrics.equipment_per_model_metric.reduce(
						(acc: Map<string, Map<string, number>>, cur: any) => {
							if (Array.isArray(cur.node_count_per_model))
								return acc.set(E5Entity.AssignString(cur.date), cur.node_count_per_model.reduce(
									(acc2: Map<string, number>, cur2: any) =>
										acc2.set(E5Entity.AssignString(cur2.manufacturer_name) + ";" +
											E5Entity.AssignString(cur2.model_name) + ";" +
											E5Entity.AssignString(cur2.software_version),
											E5Entity.ParseInt(cur2.count)), new Map()));
							else return acc;
						}, new Map());
					if (eqpdetailmap !== null && eqpdetailmap !== undefined) eqpdetailmap =
						new Map([...eqpdetailmap.entries()].sort(([date1], [date2]) =>
							E5RequestCB.CompareDate({date: date1}, {date: date2})));
				}
				equipmap = new Map();
				if (Array.isArray(resp.body.metrics.equipment_per_type_metric)) {
					equipmap = resp.body.metrics.equipment_per_type_metric.reduce(
						(acc: Map<string, Map<string, number>>, cur: any) => {
							if (Array.isArray(cur.node_count_per_type))
								return acc.set(E5Entity.AssignString(cur.date), cur.node_count_per_type.reduce(
									(acc2: Map<string, number>, cur2: any) => {
										if (["GW", "EXT", "STB"].includes(cur2.node_type))
											return acc2.set(cur2.node_type, E5Entity.ParseInt(cur2.count));
										else return acc2;
									}, new Map()));
							else return acc;
						}, new Map());
					if (equipmap !== null && equipmap !== undefined) equipmap =
						new Map([...equipmap.entries()].sort(([date1], [date2]) =>
							E5RequestCB.CompareDate({date: date1}, {date: date2})));
				}
				stationmap = new Map();
				maxavgmap = new Map();
				if (Array.isArray(resp.body.metrics.station_per_band_metric)) {
					stationmap = resp.body.metrics.station_per_band_metric.reduce(
						(acc: Map<string, Map<string, number>>, cur: any) => {
							if (Array.isArray(cur.station_per_band))
								return acc.set(E5Entity.AssignString(cur.date), cur.station_per_band.reduce(
									(acc2: Map<string, number>, cur2: any) => {
										if ([
											E5BandEnum.eth, E5BandEnum.freq2ghz, E5BandEnum.freq5ghz,
											E5BandEnum.freq6ghz
										].includes(
											cur2.band)) return acc2.set(cur2.band, E5Entity.ParseInt(cur2.count));
										else return acc2;
									}, new Map()));
							else return acc;
						}, new Map());
					if (stationmap !== null && stationmap !== undefined) stationmap =
						new Map([...stationmap.entries()].sort(([date1], [date2]) =>
							E5RequestCB.CompareDate({date: date1}, {date: date2})));
					maxavgmap = resp.body.metrics.station_per_band_metric.reduce(
						(acc: Map<string, Map<string, number>>, cur: any) => {
							if (Array.isArray(cur.station_per_band)) {
								cur.station_per_band.sort((a, b) => a.band.localeCompare(b.band));
								return acc.set(E5Entity.AssignString(cur.date), cur.station_per_band.reduce(
									(acc2: Map<string, number>, cur2: any) => {
										if ([
											E5BandEnum.eth, E5BandEnum.freq2ghz, E5BandEnum.freq5ghz,
											E5BandEnum.freq6ghz
										].includes(cur2.band)) {
											acc2.set(cur2.band + ";avg", E5Entity.ParseFloat(cur2.average));
											return acc2;
										} else return acc2;
									}, new Map()));
							}
							else return acc;
						}, new Map());
					if (maxavgmap !== null && maxavgmap !== undefined) maxavgmap =
						new Map([...maxavgmap.entries()].sort(([date1], [date2]) =>
							E5RequestCB.CompareDate({date: date1}, {date: date2})));
				}
			}
			E5StoreCB.Ins().SetGlobal(status, health, healthmetmap, statypemap, eqpdetailmap, equipmap, stationmap,
				maxavgmap);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Wifi -------------

	//E5
	static FetchWifi(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = {loading: true, success: false, message: ""}, udf: undefined = undefined;
		E5StoreCB.Ins().SetWifi(status, udf, udf, udf, udf, udf, udf, udf, udf, [], udf, udf);
		E5RequestCB.Ins().Fetch("/populationdata/" + popid + "/wifi/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBTags.WIFI, callback);
	}

	//E5
	private static WifiCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("wificb-dashboard-notfound");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let obj: any, udf: undefined = undefined, health: number|null|undefined = udf,
				connmap: Map<string, number|null>|null|undefined = udf,
				catcritmap: Map<string, Map<string, number>>|null|undefined = udf,
				catdurmap: Map<string, Map<string, number>>|null|undefined = udf,
				healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined = udf,
				incidcritmap: Map<string, number>|null|undefined = udf,
				connbandmap: Map<string, Map<string, number>>|null|undefined = udf,
				connstdmap: Map<string, Map<string, number>>|null|undefined = udf,
				chanconntimes: E5EntWifiChanTime[] = [], autochanmap: Map<string, number>|null|undefined = udf,
				conntimemap: Map<string, number>|null|undefined;
			if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
				health = E5Entity.ParseFloatNull(resp.body.kpi.wifi_health);
				connmap = new Map().set(E5BandEnum.freq6ghz, null).set(E5BandEnum.freq5ghz, null).set(
					E5BandEnum.freq2ghz, null).set("a", null).set("b", null).set("g", null).set("n", null).set("ac",
					null).set("ax", null);
				if (Array.isArray(resp.body.kpi.wifi_connection_per_band))
					for (let obj of resp.body.kpi.wifi_connection_per_band)
						connmap.set(Object.values(E5BandEnum).includes(obj.band) ? obj.band : E5BandEnum.none,
							E5Entity.ParseIntNull(obj.count));
				if (Array.isArray(resp.body.kpi.wifi_connection_per_standard))
					for (let obj of resp.body.kpi.wifi_connection_per_standard)
						connmap.set(E5Entity.AssignString(obj.operating_standard), E5Entity.ParseIntNull(obj.count));
				incidcritmap = new Map();
				if (Array.isArray(resp.body.kpi.wifi_criticality_per_incident))
					incidcritmap = resp.body.kpi.wifi_criticality_per_incident.reduce(
						(acc: Map<string, number>, cur: any) =>
							acc.set(E5Entity.AssignString(cur.band) + ";" + E5Entity.AssignString(cur.incident_type),
								E5Entity.ParseFloat(cur.criticality)), new Map());
				if (Array.isArray(resp.body.kpi.wifi_duration_per_channel)) {
					let bandmap: Map<string, Map<string, any>> = new Map(), chanmap: Map<string, any>|undefined;
					for (let obj of resp.body.kpi.wifi_duration_per_channel) {
						let band: string = E5Entity.AssignString(obj.band),
							chan: string = E5Entity.AssignString(obj.channel),
							duration: number = E5Entity.ParseInt(obj.duration);
						if (band.toUpperCase().startsWith(E5BandEnum.eth)) continue;
						chanmap = bandmap.get(band);
						if (chanmap === undefined) {
							chanmap = new Map();
							bandmap.set(band, chanmap);
						}
						chanmap.set(chan, duration);
					}
					for (let [band, chanmap] of bandmap) {
						let time: any = {};
						for (let [chan, conntime] of chanmap) time[chan] = conntime;
						chanconntimes.push(new E5EntWifiChanTime({band: band, time: time}));
					}
				}
				autochanmap = new Map();
				if (Array.isArray(resp.body.kpi.auto_channel_enable))
					for (obj of resp.body.kpi.auto_channel_enable)
						autochanmap.set(obj.is_enabled === true ? "true" :
							obj.is_enabled === false ? "false" : "null", E5Entity.ParseInt(obj.count));
				conntimemap = new Map();
				if (Array.isArray(resp.body.kpi.wifi_duration_per_standard))
					conntimemap = resp.body.kpi.wifi_duration_per_standard.reduce(
						(acc: Map<string, number>, cur: any) =>
							acc.set(E5Entity.AssignString(cur.band) + ";" +
								E5Entity.AssignString(cur.operating_standard) + ";" +
								E5Entity.AssignString(cur.bandwidth), E5Entity.ParseInt(cur.duration)), new Map());
			}
			if (typeof resp.body.metrics === "object" && resp.body.metrics !== null) {
				catcritmap = new Map();
				if (Array.isArray(resp.body.metrics.wifi_incident_scorings_metric)) {
					catcritmap = resp.body.metrics.wifi_incident_scorings_metric.reduce(
						(acc: Map<string, Map<string, number>>, cur: any) => {
							if (Array.isArray(cur.wifi_incident_scoring))
								return acc.set(E5Entity.AssignString(cur.date), cur.wifi_incident_scoring.reduce(
									(acc2: Map<string, number>, cur2: any) =>
										acc2.set(E5Entity.AssignString(
											cur2.incident_type).substr(0, E5RequestMeta.Ins().h_incidentcateglen),
											E5Entity.ParseFloat(cur2.criticality)), new Map()));
							else return acc;
						}, new Map());
					if (catcritmap !== null && catcritmap !== undefined) catcritmap =
						new Map([...catcritmap.entries()].sort(([date1], [date2]) =>
							E5RequestCB.CompareDate({date: date1}, {date: date2})));
				}
				catdurmap = new Map();
				if (Array.isArray(resp.body.metrics.wifi_incident_scorings_metric)) {
					catdurmap = resp.body.metrics.wifi_incident_scorings_metric.reduce(
						(acc: Map<string, Map<string, number>>, cur: any) => {
							if (Array.isArray(cur.wifi_incident_scoring))
								return acc.set(E5Entity.AssignString(cur.date), cur.wifi_incident_scoring.reduce(
									(acc2: Map<string, number>, cur2: any) =>
										acc2.set(E5Entity.AssignString(
											cur2.incident_type).substr(0, E5RequestMeta.Ins().h_incidentcateglen),
											E5Entity.ParseFloat(cur2.duration)), new Map()));
							else return acc;
						}, new Map());
					if (catdurmap !== null && catdurmap !== undefined) catdurmap =
						new Map([...catdurmap.entries()].sort(([date1], [date2]) =>
							E5RequestCB.CompareDate({date: date1}, {date: date2})));
				}
				healthmetmap = E5RequestCB.ParseHealthMetrics(resp.body.metrics.wifi_health_metric);
				connbandmap = new Map();
				if (Array.isArray(resp.body.metrics.wifi_connection_per_band_count)) {
					connbandmap = resp.body.metrics.wifi_connection_per_band_count.reduce(
						(acc: Map<string, Map<string, number>>, cur: any) => {
							if (Array.isArray(cur.wifi_connections_per_band))
								return acc.set(E5Entity.AssignString(cur.date), cur.wifi_connections_per_band.reduce(
									(acc2: Map<string, number>, cur2: any) => {
										if ([E5BandEnum.freq2ghz, E5BandEnum.freq5ghz, E5BandEnum.freq6ghz].includes(
											cur2.band)) return acc2.set(cur2.band, E5Entity.ParseInt(cur2.count));
										else return acc2;
									}, new Map()));
							else return acc;
						}, new Map());
					if (connbandmap !== null && connbandmap !== undefined) connbandmap =
						new Map([...connbandmap.entries()].sort(([date1], [date2]) =>
							E5RequestCB.CompareDate({date: date1}, {date: date2})));
				}
				connstdmap = new Map();
				if (Array.isArray(resp.body.metrics.wifi_connection_per_standard_count)) {
					connstdmap = resp.body.metrics.wifi_connection_per_standard_count.reduce(
						(acc: Map<string, Map<string, number>>, cur: any) => {
							if (Array.isArray(cur.wifi_connection_per_standard))
								return acc.set(E5Entity.AssignString(cur.date), cur.wifi_connection_per_standard.reduce(
									(acc2: Map<string, number>, cur2: any) => {
										let std: string = E5Entity.AssignString(cur2.operating_standard);
										if (std !== "") return acc2.set(std, E5Entity.ParseInt(cur2.count));
										else return acc2;
									}, new Map()));
							else return acc;
						}, new Map());
					if (connstdmap !== null && connstdmap !== undefined) connstdmap =
						new Map([...connstdmap.entries()].sort(([date1], [date2]) =>
							E5RequestCB.CompareDate({date: date1}, {date: date2})));
				}
			}
			E5StoreCB.Ins().SetWifi(status, health, connmap, catcritmap, catdurmap, healthmetmap, incidcritmap,
				connbandmap, connstdmap, chanconntimes, autochanmap, conntimemap);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- Wan -------------

	//E5
	static FetchWan(popid: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		let status: E5RequestStatus = {loading: true, success: false, message: ""}, udf: undefined = undefined;
		E5StoreCB.Ins().SetWan(status, udf, udf, udf, udf);
		E5RequestCB.Ins().Fetch("/populationdata/" + popid + "/wan/bystartdate/" + startdate +
			"/byenddate/" + enddate, E5RequestCBTags.WAN, callback);
	}

	//E5
	private static WanCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("wificb-dashboard-notfound");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let obj: any, udf: undefined = undefined, health: number|null|undefined = udf,
				healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined = udf,
				incidcritmap: Map<string, number>|null|undefined = udf,
				wanaccessmap: Map<string, number>|null|undefined = udf;
			if (typeof resp.body.kpi === "object" && resp.body.kpi !== null) {
				health = E5Entity.ParseFloatNull(resp.body.kpi.wan_health);
				incidcritmap = new Map();
				if (Array.isArray(resp.body.kpi.wan_criticality_per_incident))
					for (obj of resp.body.kpi.wan_criticality_per_incident)
						incidcritmap.set(E5Entity.AssignString(obj.wan_type) + ";" +
							E5Entity.AssignString(obj.incident_type), E5Entity.ParseFloat(obj.criticality));
				wanaccessmap = new Map();
				if (Array.isArray(resp.body.kpi.wan_connection_time_per_interface_type))
					for (obj of resp.body.kpi.wan_connection_time_per_interface_type)
						wanaccessmap.set(E5Entity.AssignString(obj.interface_type), E5Entity.ParseInt(obj.count));
			}
			if (typeof resp.body.metrics === "object" && resp.body.metrics !== null) {
				healthmetmap = E5RequestCB.ParseHealthMetrics(resp.body.metrics.wan_health_metric);
			}
			E5StoreCB.Ins().SetWan(status, health, healthmetmap, incidcritmap, wanaccessmap);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// --------- NiList -------------

	//E5
	FetchNiList(popid: string, incidentkey: string, startdate: string, enddate: string, callback: E5RequestCallback): void {
		E5StoreCB.Ins().SetNiList({loading: true, success: false, message: ""}, []);
		let tag: E5RequestCBTags = E5RequestCBTags.NILIST;
		let command: string = "byincident";
		if (incidentkey.length === E5RequestMeta.Ins().h_incidentcateglen) command = "bycategory";
		this.sender.Cancel(this.tokenmap.get(tag));
		let bearer: string|undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET",
			uri: "/populationdata/" + popid + "/networkid/" + command + "/" + incidentkey +
				"/bystartdate/" + startdate + "/byenddate/" + enddate,
			header: [
				["Content-Type", "application/json"],
				["Authorization", bearer]
			],
			body: undefined,
			otherdata: {requesttag: tag, callback: callback}
		};
		this.tokenmap.set(tag, this.sender.Send(req));
	}

	//E5
	private static NiListCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = {loading: false, success: false, message: ""};
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 404) {
			status.message = E5UtilI18n._("wificb-dashboard-notfound");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let nilist: string[] = [];
			if (status.success) {
				if (Array.isArray(resp.body.network_ids)) nilist = resp.body.network_ids;
				if (nilist.length === 0) {
					status.success = false;//cannot be empty if pie zone clickable !
					status.message = E5UtilI18n._("wificb-dashboard-notfound");
				}
				E5StoreCB.Ins().SetNiList(status, nilist);
			} else E5StoreCB.Ins().SetNiList(status, []);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		if (resp.request.otherdata.callback !== undefined) resp.request.otherdata.callback(status);
	}

	// ---------------- UTILS ----------------

	//E5
	static ParseHealthMetrics = (json: any): Map<string, Map<number, E5CBHealthClass>> => {
		let healthmetmap: Map<string, Map<number, E5CBHealthClass>> = new Map();
		if (Array.isArray(json)) {
			healthmetmap = json.reduce(
				(acc: Map<string, Map<number, E5CBHealthClass>>, cur: any) => {
					if (Array.isArray(cur.classes)) {
						let classmap: Map<number, E5CBHealthClass> = cur.classes.reduce(
							(acc2: Map<number, E5CBHealthClass>, cur2: any) => {
								if (typeof cur2.class === "object" && cur2.class !== null)
									return acc2.set(E5Entity.ParseInt(cur2.class.rank), {
										cla: E5Entity.AssignString(cur2.class.label),
										rank: E5Entity.ParseInt(cur2.class.rank),
										count: E5Entity.ParseInt(cur2.count)
									});
								else return acc2;
							}, new Map());
						classmap = new Map([...classmap.entries()].sort(
							([, health1], [, health2]) =>
								E5RequestCB.CompareRank(health2, health1)));
						return acc.set(E5Entity.AssignString(cur.date), classmap);
					} else return acc;
				}, new Map());
			if (healthmetmap !== null && healthmetmap !== undefined)
				healthmetmap = new Map([...healthmetmap.entries()].sort(([date1], [date2]) =>
					E5RequestCB.CompareDate({date: date1}, {date: date2})));
		}
		return healthmetmap;
	};

	//E5
	static CompareRank = (m1: {rank: number}, m2: {rank: number}): number => m1.rank - m2.rank;

	//E5
	static CompareDate = (m1: {date: string}, m2: {date: string}) => m1.date.localeCompare(m2.date);
}
