import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHKpiSysProcMem extends E5Entity {
	imei: string = "";
	memusagemap: Map<string, number>|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);

		let key: string;
		this.memusagemap = null;
		if (typeof json.processes_memory_usage === "object" && json.processes_memory_usage !== null) {
			this.memusagemap = new Map();
			for (key in json.processes_memory_usage) if (json.processes_memory_usage.hasOwnProperty(key))
				this.memusagemap.set(key, E5Entity.ParseInt(json.processes_memory_usage[key]));
		}
	}

	//E5
	CopyToJson(): any {
		let processes_memory_usage: any = null, key: string, val: number|null;
		if (this.memusagemap instanceof Map) for ([key, val] of this.memusagemap) {
			if (processes_memory_usage === null) processes_memory_usage = {};
			processes_memory_usage[key] = val;
		}

		return {node_imei: this.imei, processes_memory_usage};
	}
}
