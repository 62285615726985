import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5AngularGauge} from "../../../../global/plot/E5AngularGauge";
import {E5PieChart} from "../../../../global/plot/E5PieChart";
import {E5MainConfig} from "../../../../global/E5MainConfig";
import {E5StoreCBSys} from "../../../../store/E5StoreCBSys";
import {E5UtilI18n} from "../../../../global/E5MainLang";
import {E5StoreCB} from "../../../../store/E5StoreCB";
import {E5Store} from "../../../../store/E5Store";
import {E5CBDashboard} from "../E5CBDashboard";
//
import "./E5CBSysReboot.css";

//E5
interface E5CBSysRebootState {}

//E5
interface E5CBSysRebootProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
	percent: boolean;
	togglefunc: () => void;
}

//E5
export const E5CBSysReboot = observer(class E5CBSysReboot extends React.PureComponent
	<E5CBSysRebootProps, E5CBSysRebootState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let {_} = E5UtilI18n, {status, health, rebequips, rebdetails, uptequips, uptdetails} =  //eslint-disable-line
				E5StoreCBSys.Ins().reboot, {loading} = status, {nilistsettings} = E5StoreCB.Ins(),
			{mode, cla, eqptype, sofv, model} = nilistsettings, {  //eslint-disable-line
				RenderPiePopover, DownloadNiListEquip, RenderEquipsPie, PieClickEquip1, PieClickEquip2,  //eslint-disable-line
				DownloadNiListDetail, RenderDetailsPie, PieClickDetail1, PieClickDetail2, RenderChart  //eslint-disable-line
			} = E5CBDashboard;

		let [ids, parents, values, labels] = E5CBSysReboot.GetRebootPieData();

		return <div className="e5cb-sys-reboot e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5AngularGauge
						gaugeinfo={{value: health, label: _("system-reb"), title: 'Reboot health score', loading}} isNewComponent chartOption={{type:'gauge'}}/>
				</div>
				<div className="e5linefull">
					<E5PieChart pieinfo={{
						title: E5UtilI18n._("cb-sys-reboot-piereb"), roundvalues: true,
						subtitle: E5UtilI18n._("cb-sys-reboot-pie-reason-sub"), loading,
						valueisseconds: false, labelisincident: false, ids, parents, values, labels
					}} withNewComponent/>
				</div>
			</div>
			<div className="e5line-20">
			<div className="e5linefull">
					<div className="e5line-0">
						{RenderPiePopover(mode === "detail1", this.props.downloadref,
							() => DownloadNiListDetail("reboot", model, sofv, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderDetailsPie("reboot", rebdetails, loading, PieClickDetail1, "reb"))}
					</div>
				</div>
			</div>
			{RenderChart("reboot", "gw", loading, this.props.percent, this.props.togglefunc, "reb",'bar')}
			{E5MainConfig.GetExtEnabled() && RenderChart(
				"reboot", "ext", loading, this.props.percent, this.props.togglefunc, "reb",'bar')}
		</div>;
	}

	// ---------------- UTILS ----------------

	//E5
	static GetRebootPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let {reasonmap} = E5StoreCBSys.Ins().reboot,
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (reasonmap === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (reasonmap !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				for (let [reason, count] of reasonmap) {
					ids.push(reason);
					parents.push("total");
					values.push(count);
					labels.push(reason);
				}
			}
			return [ids, parents, values, labels];
		};
});
