import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5AngularGauge } from "../../../../global/plot/E5AngularGauge";
import { E5MainConfig } from "../../../../global/E5MainConfig";
import { E5StoreCBSys } from "../../../../store/E5StoreCBSys";
import { E5UtilI18n } from "../../../../global/E5MainLang";
import { E5StoreCB } from "../../../../store/E5StoreCB";
import { E5Store } from "../../../../store/E5Store";
import { E5CBDashboard } from "../E5CBDashboard";
//
import "./E5CBSysCpu.css";

//E5
interface E5CBSysCpuState { }

//E5
interface E5CBSysCpuProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
	percent: boolean;
	togglefunc: () => void;
}

//E5
export const E5CBSysCpu = observer(class E5CBSysCpu extends React.PureComponent<E5CBSysCpuProps, E5CBSysCpuState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { status, health, equips, details, detailsPerExtender, detailsPerGateway } = E5StoreCBSys.Ins().cpu, { loading } = status,
			{ nilistsettings } = E5StoreCB.Ins(), { mode, cla, eqptype, sofv, model } = nilistsettings, {
				RenderPiePopover, DownloadNiListEquip, RenderEquipsPie, PieClickEquip1,
				DownloadNiListDetail, RenderDetailsPie, PieClickDetail1, RenderChart
			} = E5CBDashboard;
		return <div className="e5cb-sys-cpu e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5AngularGauge
						gaugeinfo={{ value: health, label: _("system-cpu"), title: 'CPU health score', loading }} isNewComponent chartOption={{ type: 'gauge' }} />
				</div>
				<div className="e5linefull">
					{/* <div className="e5line-0">
						{RenderPiePopover(mode === "equip1", this.props.downloadref,
							() => DownloadNiListEquip("cpu", eqptype, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderEquipsPie("cpu", equips, loading, PieClickEquip1))}
					</div> */}
					<div className="e5line-0">
						{RenderPiePopover(mode === "detail1", this.props.downloadref,
							() => DownloadNiListDetail("cpu", model, sofv, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderDetailsPie("cpu", detailsPerGateway, loading, PieClickDetail1, null, 'GW'))}
					</div>
				</div>
				<div className="e5linefull">
					<div className="e5line-0">
						{RenderPiePopover(mode === "detail1", this.props.downloadref,
							() => DownloadNiListDetail("cpu", model, sofv, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderDetailsPie("cpu", detailsPerExtender, loading, PieClickDetail1, null, 'EXT'))}
					</div>
					{/* <div className="e5line-0">
						{RenderPiePopover(mode === "detail1", this.props.downloadref,
							() => DownloadNiListEquip("cpu", eqptype, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderEquipsPie("cpu", detailsPerExtender, loading, PieClickEquip1))}
					</div> */}
				</div>
			</div>
			{RenderChart("cpu", "gw", loading, this.props.percent, this.props.togglefunc)}
			{E5MainConfig.GetExtEnabled() && RenderChart(
				"cpu", "ext", loading, this.props.percent, this.props.togglefunc)}
		</div>;
	}
});
