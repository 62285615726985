import {E5EntMetaLangs} from "./E5EntMetaLangs";
import {E5MetaCateg} from "./E5EntMetaIncident";
import {E5Entity} from "../E5Entity";

//E5
export class E5EntMetaAnomaly extends E5Entity {
	id: string = "";
	categ: E5MetaCateg = {categid: "", langs: new E5EntMetaLangs()};
	langs: E5EntMetaLangs = new E5EntMetaLangs();

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.id = E5Entity.AssignString(json.id);

		this.categ = {categid: "", langs: new E5EntMetaLangs()};
		if (typeof json.category === "object" && json.category !== null) this.categ = {
			categid: E5Entity.AssignString(json.category.category_id),
			langs: new E5EntMetaLangs(json.category.languages)
		};

		this.langs = new E5EntMetaLangs(json.languages);
	}

	//E5
	CopyToJson(): any {
		return {
			id: this.id, category: {category_id: this.categ.categid, languages: this.categ.langs.CopyToJson()},
			languages: this.langs.CopyToJson()
		};
	}
}
