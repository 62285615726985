import {action, makeObservable, observable} from "mobx";
//
import {E5EntRdmRequest} from "../entity/rdm/E5EntRdmRequest";
import {E5EntRdmCommand} from "../entity/rdm/E5EntRdmCommand";
import {E5EntRdmStatus} from "../entity/rdm/E5EntRdmStatus";
import {E5RequestStatus} from "../request/E5ServiceCommon";
import {E5EntRdmTtl} from "../entity/rdm/E5EntRdmTtl";
import {E5EntUser} from "../entity/E5EntUser";

// --------- USERS -------------

//E5
export interface E5StoreAdminUsers {
	status: E5RequestStatus;
	users: E5EntUser[];
}

//E5
export interface E5StoreAdminUserGet {
	status: E5RequestStatus;
	user: E5EntUser|undefined;
}

// --------- RDM -------------

//E5
export interface E5StoreAdminRdmTtl {
	status: E5RequestStatus;
	ttllist: E5EntRdmTtl[];
	ttlmap: Map<number, E5EntRdmTtl>;
	ttlid30mn: number;
}

//E5
export interface E5StoreAdminRdmStatus {
	status: E5RequestStatus;
	statuslist: E5EntRdmStatus[];
	statusmap: Map<number, E5EntRdmStatus>;
	scheduledid: number;
	failureid: number;
	timeoutid: number;
}

//E5
export interface E5StoreAdminRdmRequest {
	status: E5RequestStatus;
	requestlist: E5EntRdmRequest[];
	requestmap: Map<number, E5EntRdmRequest>;
	wifiscanid: number;
}

//E5
export interface E5StoreAdminRdmCmds {
	status: E5RequestStatus;
	commands: E5EntRdmCommand[];
}

//E5
export interface E5StoreAdminRdmUi {
	status: E5RequestStatus;
}

//E5
export interface E5StoreAdminRdmInfo {
	timezone: string;
	customerid: string;
}

// --------- STORE ITSELF -------------

//E5
export class E5StoreAdmin {

	// --------- STATIC MEMBERS -------------

	//E5
	private static ins: E5StoreAdmin;

	//E5
	static Ins(): E5StoreAdmin {
		if (E5StoreAdmin.ins === undefined)
			E5StoreAdmin.ins = new E5StoreAdmin();
		return E5StoreAdmin.ins;
	}

	// --------- STATIC STORE MODIFICATION -------------

	// --- users

	//E5
	SetUsers(status: E5RequestStatus, users: E5EntUser[]) {
		E5StoreAdmin.Ins().userinfo.status = status;
		E5StoreAdmin.Ins().userinfo.users = users;
	}

	//E5
	SetUserGet(status: E5RequestStatus, user: E5EntUser|undefined) {
		E5StoreAdmin.Ins().usergetinfo.status = status;
		E5StoreAdmin.Ins().usergetinfo.user = user;
	}

	// --- rdm

	//E5
	SetRdmTtlList(status: E5RequestStatus, ttllist: E5EntRdmTtl[]) {
		E5StoreAdmin.Ins().rdmttlinfo.status = status;
		E5StoreAdmin.Ins().rdmttlinfo.ttllist = ttllist;
		let idx: number;
		E5StoreAdmin.Ins().rdmttlinfo.ttlmap = new Map();
		for (idx = 0; idx < ttllist.length; idx++) {
			let ttl: E5EntRdmTtl = ttllist[idx];
			E5StoreAdmin.Ins().rdmttlinfo.ttlmap.set(ttl.id, ttl);
			if (ttl.name === "30 minutes")
				E5StoreAdmin.Ins().rdmttlinfo.ttlid30mn = ttl.id;
		}
	}

	//E5
	SetRdmStatusList(status: E5RequestStatus, statuslist: E5EntRdmStatus[]) {
		E5StoreAdmin.Ins().rdmstatusinfo.status = status;
		E5StoreAdmin.Ins().rdmstatusinfo.statuslist = statuslist;
		let idx: number;
		E5StoreAdmin.Ins().rdmstatusinfo.statusmap = new Map();
		for (idx = 0; idx < statuslist.length; idx++) {
			let st: E5EntRdmStatus = statuslist[idx];
			E5StoreAdmin.Ins().rdmstatusinfo.statusmap.set(st.id, st);
			if (st.name === "scheduled")
				E5StoreAdmin.Ins().rdmstatusinfo.scheduledid = st.id;
			if (st.name === "failure")
				E5StoreAdmin.Ins().rdmstatusinfo.failureid = st.id;
			if (st.name === "timeout")
				E5StoreAdmin.Ins().rdmstatusinfo.timeoutid = st.id;
		}
	}

	//E5
	SetRdmRequestList(status: E5RequestStatus, requestlist: E5EntRdmRequest[]) {
		E5StoreAdmin.Ins().rdmrequestinfo.status = status;
		E5StoreAdmin.Ins().rdmrequestinfo.requestlist = requestlist;
		let idx: number;
		E5StoreAdmin.Ins().rdmrequestinfo.requestmap = new Map();
		for (idx = 0; idx < requestlist.length; idx++) {
			let req: E5EntRdmRequest = requestlist[idx];
			E5StoreAdmin.Ins().rdmrequestinfo.requestmap.set(req.id, req);
			if (req.type === "WifiScan")
				E5StoreAdmin.Ins().rdmrequestinfo.wifiscanid = req.id;
		}
	}

	//E5
	SetRdmCmds(status: E5RequestStatus, cmds: E5EntRdmCommand[]) {
		E5StoreAdmin.Ins().rdmcmdinfo.status = status;
		E5StoreAdmin.Ins().rdmcmdinfo.commands = cmds;
	}

	//E5
	SetRdmUi(status: E5RequestStatus) {
		E5StoreAdmin.Ins().rdmuiinfo.status = status;
	}

	//E5
	SetRdmTimeZone(timezone: string) {
		E5StoreAdmin.Ins().rdminfo.timezone = timezone;
	}

	//E5
	SetRdmCustomerId(customerid: string) {
		E5StoreAdmin.Ins().rdminfo.customerid = customerid;
	}

	// --------- INSTANCE -------------

	userinfo: E5StoreAdminUsers = {status: {loading: false, success: false, message: ""}, users: []};
	usergetinfo: E5StoreAdminUserGet = {status: {loading: false, success: false, message: ""}, user: undefined};
	rdminfo: E5StoreAdminRdmInfo = {timezone: "", customerid: ""};
	rdmttlinfo: E5StoreAdminRdmTtl =
		{status: {loading: false, success: false, message: ""}, ttllist: [], ttlmap: new Map(), ttlid30mn: 0};
	rdmstatusinfo: E5StoreAdminRdmStatus = {
		status: {loading: false, success: false, message: ""}, statuslist: [], statusmap: new Map(), scheduledid: 0,
		failureid: 0, timeoutid: 0
	};
	rdmrequestinfo: E5StoreAdminRdmRequest = {
		status: {loading: false, success: false, message: ""}, requestlist: [], requestmap: new Map(), wifiscanid: 0
	};
	rdmcmdinfo: E5StoreAdminRdmCmds = {status: {loading: false, success: false, message: ""}, commands: []};
	rdmuiinfo: E5StoreAdminRdmUi = {status: {loading: false, success: false, message: ""}};

	//E5
	constructor() {
		makeObservable(this, {
			userinfo: observable, usergetinfo: observable, rdminfo: observable, rdmttlinfo: observable,
			rdmstatusinfo: observable, rdmrequestinfo: observable, rdmcmdinfo: observable, rdmuiinfo: observable,
			SetUsers: action, SetUserGet: action, SetRdmTtlList: action, SetRdmStatusList: action,
			SetRdmRequestList: action, SetRdmCmds: action, SetRdmUi: action, SetRdmTimeZone: action,
			SetRdmCustomerId: action
		})
	}
}
