import React from "react";
import { observer } from "mobx-react";
//
import { E5TextIndic, E5TextIndicUnitEnum } from "../../../global/component/E5TextIndic";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5BandEnum } from "../../../entity/E5Enums";
import { E5StoreCB } from "../../../store/E5StoreCB";
import { E5Store } from "../../../store/E5Store";
//
import "./E5CBWifiIndic.css";

//E5
interface E5CBWifiIndicState { }

//E5
interface E5CBWifiIndicProps { }

//E5
export const E5CBWifiIndic = observer(class E5CBWifiIndic extends React.PureComponent
	<E5CBWifiIndicProps, E5CBWifiIndicState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { connmap } = E5StoreCB.Ins().wifi;

		return <div className="e5compo e5wificb-wifi-indic e5column-0">
			<div className="e5line-0 wrappable">
				<E5TextIndic value={connmap?.get(E5BandEnum.freq2ghz)}
					label={_(E5BandEnum.freq2ghz)} tooltip={_("wificb-indic-2g-tip")}
					imageurl="/img/v3/textindic/wifi-2.4g.svg" unittype={E5TextIndicUnitEnum.count} />
				<E5TextIndic value={connmap?.get(E5BandEnum.freq5ghz)}
					label={_(E5BandEnum.freq5ghz)} tooltip={_("wificb-indic-5g-tip")}
					imageurl="/img/v3/textindic/wifi-5g.svg" unittype={E5TextIndicUnitEnum.count} />
				<E5TextIndic value={connmap?.get(E5BandEnum.freq6ghz)}
					label={_(E5BandEnum.freq6ghz)} tooltip={_("wificb-indic-6g-tip")}
					imageurl="/img/v3/textindic/wifi-6g.svg" unittype={E5TextIndicUnitEnum.count} />

			</div>
		</div>;
	}
});
