import React, { useEffect, useMemo, useState } from 'react'

import cn from 'classnames'

import { ReactComponent as MetricsIcon } from "../../../assets/stations/metrics-icon.svg"
import { ReactComponent as SearchIcon } from "../../../assets/stations/search-icon.svg"
import { ReactComponent as ChevronUp } from "../../../assets/stations/chevron-up.svg"
import { ReactComponent as ChevronDown } from "../../../assets/stations/chevron-down.svg"



import './E5HEquipmentsPage.css'

import * as BP from "@blueprintjs/core";
import { E5Text } from '../../../util/E5Text'
import { E5UtilI18n } from '../../../global/E5MainLang'
import { E5NodeTypeEnum } from '../../../entity/E5Enums'
import { E5StoreH, E5StoreHIndicGlobal, E5StoreHIndicSys } from '../../../store/E5StoreH'
import { E5Store } from '../../../store/E5Store'
import { useHistory } from 'react-router'
import { E5Page, E5Path } from '../../../global/E5MainStatics'
import { E5AngularGauge } from "../../../global/plot/E5AngularGauge";
import { E5EntHEquip } from '../../../entity/household/topo/E5EntHEquip'
import { E5HEquipMore } from '../E5HEquipMore'
import { E5EntHIndSysCpuMem } from '../../../entity/household/indic/E5EntHIndSysCpuMem'
import E5HInterfaceItem from './E5HInterfaceItem'
import { E5EntHIndSysReb } from '../../../entity/household/indic/E5EntHIndSysReb'
import { E5EntHIndSysFlash, E5SysFlashDetail } from '../../../entity/household/indic/E5EntHIndSysFlash'
import { E5StorePieInfo } from '../../../request/E5ServiceCommon'
import { E5PieChart } from "../../../global/plot/E5PieChart";
import { E5EntHIndSysHealth } from '../../../entity/household/indic/E5EntHIndSysHealth'
import { E5EntHIndSysProc, E5SysProcDetail } from '../../../entity/household/indic/E5EntHIndSysProc'
import { E5EntHIndSysCrash, E5SysCrashDetail } from '../../../entity/household/indic/E5EntHIndSysCrash'
import './E5HEquipmentItem.css'
import { E5EntHStation } from '../../../entity/household/topo/E5EntHStation'
import moment from 'moment'

import { E5EntHEquipmentRdm } from '../../../entity/household/equipment/E5EntHEquipmentRdm'
import E5HEquipmentItemRdm from './E5HEquipmentItemRdm'

interface IProps {
    equip: E5EntHEquip;
    indicglobalinfo: E5StoreHIndicGlobal;
    indicsysinfo: E5StoreHIndicSys;
    loading: boolean;
    uploading: boolean;
    connectedStations: E5EntHStation[];
    actions: E5EntHEquipmentRdm[];
}
const HealthScore = ({ score }) => {
    let text = '';
    let color = '';

    if (score === null) {
        text = 'N/A';
        color = 'grey'
    } else {
        const fixedScore = Math.round(score * 100);
        if (fixedScore >= 0 && fixedScore < 40) {
            text = 'Bad';
            color = 'red'
        }
        if (fixedScore >= 40 && fixedScore < 70) {
            text = 'Medium';
            color = '#EBBE4B'
        }
        if (fixedScore >= 70) {
            text = 'Excellent';
            color = '#19B98F'
        }
    }

    return <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '60px', height: '8px', borderRadius: '10px', backgroundColor: color, marginRight: '10px', marginTop: '2px' }}></div>
        <p style={{ margin: 0 }} color={color}>{text}</p>
    </div>

};
const generateStationImage = (deviceType: string) => {
    let deviceImgName = '';
    switch (deviceType) {
        case 'Smart device':
            deviceImgName = 'Smart_Device'
            break
        case 'Third Party AP':
            deviceImgName = 'Third_Party_AP'
            break
        case 'Printer':
            deviceImgName = 'printer'
            break
        case 'Computer':
            deviceImgName = 'laptop'
            break
        case 'Smartphone or tablet':
            deviceImgName = 'phone'
            break
        case 'Sound device':
            deviceImgName = 'soundsystem'
            break
        case 'Game console':
            deviceImgName = 'gameconsole'
            break
        case 'Multimedia device':
            deviceImgName = 'miltimedia-device'
            break
        case 'Wifi equipment':
            deviceImgName = 'wifi-equipment'
            break
        case 'IoT device':
            deviceImgName = 'IOT'
            break
        case 'Unknown':
            deviceImgName = "unknown"
            break

        default:
            deviceImgName = "unknown"
            break
    }
    const stationImageByFingerpring = "/img/nodes_and_devices/devices-by-fingerprint/" + deviceImgName + ".svg";
    return stationImageByFingerpring;
}
function durationFormatter(milliseconds: number): string {
    return moment.utc(milliseconds).format('HH:mm:ss');
}

const E5HEquipmentItem: React.FC<IProps> = ({ equip, indicglobalinfo, indicsysinfo, loading, connectedStations, actions }) => {    
    // force rerender when lang changes
    const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
    const history = useHistory();
    const [showMainDetails, setShowMainDetails] = useState<boolean>(false)
    const [showDetails, setShowDetails] = useState<number | null>(null)
    
    const { _ } = E5UtilI18n;

    const { health, cpumem, status } = indicsysinfo;

    useEffect(() => {
        if (showDetails === 3) {
            setShowDetails(null);
        }
    }, [curlang])

    const imgSrc: string = useMemo(() => {
        if (equip.nodetype !== E5NodeTypeEnum.stb) {
            return "/img/v3/nodes_and_devices/" + (equip.nodetype === E5NodeTypeEnum.none ? "default" : equip.nodetype) + "-black.svg";
        } else {
            return "/img/v3/nodes_and_devices/stb-black.png";
        }
    }, [equip]);

    const { cpuhealth, flashealth, memhealth, prochealth, rebhealth, temphealth } = useMemo(() => {
        const healthObj = {
            cpuhealth: 0,
            flashealth: 0,
            memhealth: 0,
            prochealth: 0,
            rebhealth: 0,
            temphealth: 0
        }

        const activeEqpHealth = health.find((h: E5EntHIndSysHealth) => h.imei === equip.imei);

        if (activeEqpHealth) {
            healthObj.cpuhealth = activeEqpHealth.cpuhealth ?? 0;
            healthObj.flashealth = activeEqpHealth.flashhealth ?? 0;
            healthObj.memhealth = activeEqpHealth.memhealth ?? 0;
            healthObj.prochealth = activeEqpHealth.prochealth ?? 0;
            healthObj.rebhealth = activeEqpHealth.rebhealth ?? 0;
            healthObj.temphealth = activeEqpHealth.temphealth ?? 0;
        }

        return healthObj;
    }, [health, equip])

    const { samplingperiod, sendingperiod } = indicglobalinfo;

    const sendingperiodmn = sendingperiod ? Math.floor(sendingperiod / 60) : null;
    const sendingperiodsec = sendingperiod ? sendingperiod % 60 : null;

    const getCPULoad = (load: number | null | undefined, coreAmount: number | null | undefined): number => {
        if (typeof coreAmount === 'number' && coreAmount > 0) {
            return ((load ?? 0)/ coreAmount);
        }
        return -1;
    }

    const { memtotal, memusagepercent, memused, cpuload1, cpuload5, cpuload15, uptime, corecnt } = useMemo(() => {
        const cpumemObj = {
            memtotal: '',
            uptime: '-',
            memusagepercent: 0,
            memused: '',
            cpuload1: 0,
            cpuload5: 0,
            cpuload15: 0,
            corecnt: '-',
        };
        const equipCpu = cpumem.find((item) => item.imei === equip.imei);

        if (equipCpu) {
            cpumemObj.memtotal = equipCpu instanceof E5EntHIndSysCpuMem ?
                typeof equipCpu.memtotal === "number" ?
                    equipCpu.memtotal < (1024 * 1024) ?
                        equipCpu.memtotal < 1024 ?
                            equipCpu.memtotal + "B"
                            : Math.round(equipCpu.memtotal / 1024 * 10) / 10 + "MB"
                        : Math.round(equipCpu.memtotal / (1024 * 1024) * 10) / 10 + "GB"
                    : "N/A"
                : status.success ? "N/A"
                    : "";
            cpumemObj.memused = equipCpu instanceof E5EntHIndSysCpuMem ?
                typeof equipCpu.memusedmb === "number" ?
                    equipCpu.memusedmb < (1024 * 1024) ?
                        equipCpu.memusedmb < 1024 ?
                            equipCpu.memusedmb + "B"
                            : Math.round(equipCpu.memusedmb / 1024 * 10) / 10 + "MB"
                        : Math.round(equipCpu.memusedmb / (1024 * 1024) * 10) / 10 + "GB"
                    : "N/A"
                : status.success ? "N/A"
                    : "";
            cpumemObj.memusagepercent = equipCpu.memusedpercent ?? 0;
            cpumemObj.uptime = equipCpu instanceof E5EntHIndSysCpuMem ?
                typeof equipCpu.uptime === "number" ?
                    E5Text.Seconds2DHMS_str(equipCpu.uptime)
                    : "N/A"
                : status.success ? "N/A" : "";
            //@ts-ignore
            cpumemObj.corecnt = equipCpu instanceof E5EntHIndSysCpuMem ?
                typeof equipCpu.corecnt === "number" ?
                    equipCpu.corecnt
                    : "N/A"
                : status.success ? "N/A" : ""
            cpumemObj.cpuload1 = getCPULoad(equipCpu.load1, equipCpu.corecnt);
            cpumemObj.cpuload5 = getCPULoad(equipCpu.load5, equipCpu.corecnt);
            cpumemObj.cpuload15 = getCPULoad(equipCpu.load15, equipCpu.corecnt);
        }
        return cpumemObj;
    }, [cpumem, status, equip]);

    //Overview
    let reboot: E5EntHIndSysReb | undefined = undefined;
    for (let idx = 0; idx < indicsysinfo.reboot.length; idx++)
        if (indicsysinfo.reboot[idx].imei === equip.imei) reboot = indicsysinfo.reboot[idx];

    let rebootids: string[] = [], rebootparents: string[] = [], rebootvalues: number[] = [],
        rebootlabels: string[] = [];

    if (reboot instanceof E5EntHIndSysReb) {
        rebootids = ["Reboot"];
        rebootparents = [""];
        rebootvalues = [0];
        rebootlabels = ["Reboot"];

        let key: string, cnt: number;
        if (reboot.reasonmap instanceof Map) for ([key, cnt] of reboot.reasonmap) {
            rebootids.push(key);
            rebootparents.push("Reboot");
            rebootvalues.push(cnt);
            rebootlabels.push(key);
        }
    }

    let rebootinfo: E5StorePieInfo = {
        title: 'Reboot reasons', loading: false, valueisseconds: false, labelisincident: false, width: 275, height: 275,
        ids: rebootids, parents: rebootparents, values: rebootvalues, labels: rebootlabels
    };


    let flash: E5EntHIndSysFlash | undefined = undefined;
    for (let idx = 0; idx < indicsysinfo.flash.length; idx++)
        if (indicsysinfo.flash[idx].imei === equip.imei) flash = indicsysinfo.flash[idx];

    let flashtotalstr: string, flashtotal: number | undefined;

    let flashblockids: string[] = [], flashblockparents: string[] = [], flashblockvalues: number[] = [],
        flashblocklabels: string[] = [];

    let flashcorruptids: string[] = [], flashcorruptparents: string[] = [], flashcorruptvalues: number[] = [],
        flashcorruptlabels: string[] = [];

    if (flash instanceof E5EntHIndSysFlash) {
        flashtotal = flash.flashdetail?.reduce((acc: number, cur: E5SysFlashDetail) =>
            acc + cur.sizebad + cur.sizeavail + cur.sizeused, 0);
        flashtotalstr = typeof flashtotal === "number" ? flashtotal < (1024 * 1024) ? flashtotal < 1024 ?
            flashtotal + "B" : Math.round(flashtotal / 1024 * 10) / 10 + "KB" :
            Math.round(flashtotal / (1024 * 1024) * 10) / 10 + "MB" : "N/A";

        flashblockids = ["Flash"];
        flashblockparents = [""];
        flashblockvalues = [0];
        flashblocklabels = ["Flash"];

        flashcorruptids = ["Flash"];
        flashcorruptparents = [""];
        flashcorruptvalues = [0];
        flashcorruptlabels = ["Flash"];

        if (Array.isArray(flash.flashdetail)) for (let idx = 0; idx < flash.flashdetail.length; idx++) {
            let det: E5SysFlashDetail = flash.flashdetail[idx];

            flashblockids.push(det.name);
            flashblockparents.push("Flash");
            flashblockvalues.push(0);
            flashblocklabels.push(det.name);

            flashblockids.push(det.name + "bad");
            flashblockparents.push(det.name);
            flashblockvalues.push(det.sizebad ? det.sizebad / 1024 / 1024 : 0);
            flashblocklabels.push(_("h-equip-sys-flash-bad"));

            flashblockids.push(det.name + "used");
            flashblockparents.push(det.name);
            flashblockvalues.push(det.sizeused ? det.sizeused / 1024 / 1024 : 0);
            flashblocklabels.push(_("h-equip-sys-flash-used"));

            flashblockids.push(det.name + "available");
            flashblockparents.push(det.name);
            flashblockvalues.push(det.sizeavail ? det.sizeavail / 1024 / 1024 : 0);
            flashblocklabels.push(_("h-equip-sys-flash-avail"));

            flashcorruptids.push(det.name);
            flashcorruptparents.push("Flash");
            flashcorruptvalues.push(0);
            flashcorruptlabels.push(det.name);

            flashcorruptids.push(det.name + "valid");
            flashcorruptparents.push(det.name);
            flashcorruptvalues.push(det.validcnt ?? 0);
            flashcorruptlabels.push(_("h-equip-sys-flash-vol-valid"));

            flashcorruptids.push(det.name + "corrupt");
            flashcorruptparents.push(det.name);
            flashcorruptvalues.push(det.corruptcnt ?? 0);
            flashcorruptlabels.push(_("h-equip-sys-flash-vol-corrupt"));
        }
    } else flashtotalstr = indicsysinfo.status.success ? "N/A" : "";

    let flashblockinfo: E5StorePieInfo = {
        loading: false, valueisseconds: false, labelisincident: false, title: "Flash block types (MB)", width: 275, height: 275,
        ids: flashblockids, parents: flashblockparents, values: flashblockvalues, labels: flashblocklabels
    }, flashcorruptinfo: E5StorePieInfo = {
        loading: false, valueisseconds: false, labelisincident: false, title: "Flash volume integrity", width: 275, height: 275,
        ids: flashcorruptids, parents: flashcorruptparents, values: flashcorruptvalues, labels: flashcorruptlabels
    };


    // process

    let process: E5EntHIndSysProc | undefined = undefined;
    for (let idx = 0; idx < indicsysinfo.process.length; idx++)
        if (indicsysinfo.process[idx].imei === equip.imei) process = indicsysinfo.process[idx];

    let proccnt: number | string = process?.proccnt ?? "N/A";

    let procpid: JSX.Element[] = [], procname: JSX.Element[] = [], procpriority: JSX.Element[] = [],
        procmemsize: JSX.Element[] = [], procmemusage: JSX.Element[] = [], proccputime: JSX.Element[] = [];

    if (process instanceof E5EntHIndSysProc && Array.isArray(process.procdetail))
        for (let idx = 0; idx < process.procdetail.length; idx++) {
            let det: E5SysProcDetail = process.procdetail[idx];

            procpid.push(<div key={"procpid" + idx}>{det.pid}</div>);
            procname.push(<div key={"procname" + idx}>
                {det.name.length > 20 && <BP.Tooltip content={det.name} position={BP.Position.BOTTOM_RIGHT}>
                    {det.name.substring(0, 16) + " ..."}</BP.Tooltip>}
                {det.name.length <= 20 && det.name}
            </div>);
            procpriority.push(<div key={"procpriority" + idx}>{det.priority}</div>);
            procmemsize.push(<div key={"procmemsize" + idx}>
                {det.memsize ? Math.round(det.memsize / 1024) : "N/A"}</div>);
            procmemusage.push(<div key={"procmemusage" + idx}>{det.memusage ? (Math.round(det.memusage * 100) / 100).toFixed(2) : "N/A"}</div>);
            proccputime.push(<div key={"proccputime" + idx}>
                {det.cputime === null ? "" : E5Text.Seconds2DHMS_str(det.cputime)}</div>);
        }

    // crash

    let crash: E5EntHIndSysCrash | undefined = undefined;
    for (let idx = 0; idx < indicsysinfo.crash.length; idx++)
        if (indicsysinfo.crash[idx].imei === equip.imei) crash = indicsysinfo.crash[idx];

    let crashprocid: JSX.Element[] = [], crashtime: JSX.Element[] = [], crashprocname: JSX.Element[] = [],
        crashsoftwarev: JSX.Element[] = [], crashcnt: number | string = crash?.crashdetail?.length ?? "N/A";

    if (crash instanceof E5EntHIndSysCrash && Array.isArray(crash.crashdetail))
        for (let idx = 0; idx < crash.crashdetail.length; idx++) {
            let det: E5SysCrashDetail = crash.crashdetail[idx];

            crashprocid.push(<div key={"crashprocid" + idx}>{det.procid}</div>);
            crashtime.push(<div key={"crashtime" + idx}>{det.time.format("HH:mm")}</div>);
            crashprocname.push(<div key={"crashprocname" + idx}>
                {det.procname.length > 20 && <BP.Tooltip content={det.procname} position={BP.Position.BOTTOM_RIGHT}>
                    {det.procname.substring(0, 16) + " ..."}</BP.Tooltip>}
                {det.procname.length <= 20 && det.procname}
            </div>);
            crashsoftwarev.push(<div key={"crashsoftwarev" + idx}>{det.softwarev}</div>);
        }

    const handleClickMetrics = (e: any) => {
        e.stopPropagation();
        history.push(E5Path.Get(E5Page.H_METRICS) + `?equip=${equip.imei}`)
    }

    const handleClickEventLog = (e: any) => {
        e.stopPropagation();
        history.push(E5Path.Get(E5Page.H_EVENTLOGS) + `?equip=${equip.imei}`)
    }

    const getChevron = (id: number) => {
        const Chevron = id === showDetails ? ChevronUp : ChevronDown;
        return <Chevron className='equip-chevron' />
    }

    const handleToggle = (id: number) => {
        setShowDetails(prev => prev === id ? null : id)
    }

    return <div className='equipments-list-item'>
        <div className={cn('list-item-header')} onClick={() => setShowMainDetails(prev => !prev)}>
            <img src={imgSrc} alt='cat' className='header-image device-img' />
            <div className='equip-header-title-container'>
                <div className='title-item'>
                    <span className='title-item-label'>IMEI : </span>
                    <div className='title-item-content'>{equip.imei}</div>
                </div>
                <div className='title-item'>
                    <span className='title-item-label title-item-label-with-margin'>Health score :</span>
                    <div className='title-item-content'><HealthScore score={equip.health} /></div>
                </div>
            </div>
            <MetricsIcon className='header-metrics' onClick={handleClickMetrics} />
            <SearchIcon className='header-metrics' onClick={handleClickEventLog} />
            <ChevronUp className='header-chevron' />
        </div>
        <div className={cn('equip-list-item-content', { 'show-details': !showMainDetails })}>
            <div className='equip-top-content'>
                <div className='content-item'>
                    <span className='equip-content-item-label'>Model :</span>
                    <div className='content-item-value'>{equip.model || '-'}</div>
                </div>
                <div className='content-item'>
                    <span className='equip-content-item-label'>Software :</span>
                    <div className='content-item-value'>{E5HEquipMore.ToCsv(equip.softwarev, "")}</div>
                </div>
                <div className='content-item'>
                    <span className='equip-content-item-label'>Health score :</span>
                    <div className='content-item-value'>{equip.health === null ? '-' : `${E5Text.ScoreToPercent(equip.health)}%`}</div>
                </div>
                <div className='content-item'>
                    <span className='equip-content-item-label'>Last seen :</span>
                    <div className='content-item-value'>
                        {equip.lastseen?.format("HH:mm:ss") ?? "N/A"}
                        {equip.lastseen !== null &&
                            E5StoreH.Ins().curdateinfo.curdate === E5Store.Ins().todayinfo.today &&
                            <span>&nbsp;<i>({equip.lastseen.fromNow()})</i></span>}
                    </div>
                </div>
                <div className='content-item'>
                    <span className='equip-content-item-label'>Sampling frequency :</span>
                    <div className='content-item-value'>{samplingperiod} sec / {sendingperiodmn} mn {sendingperiodsec} sec</div>
                </div>
                <div className='content-item'>
                    <span className='equip-content-item-label'>Uptime :</span>
                    <div className='content-item-value'>
                        {uptime}
                    </div>
                </div>
            </div>
            <div className='equip-bot-content'>
                <div className='equip-content-toggle' onClick={() => handleToggle(0)}>
                    {getChevron(0)}
                    {equip.interfaces.length} Interface(s)
                </div>
                <div className='equip-content-toggle' onClick={() => handleToggle(1)}>
                    {getChevron(1)}
                    System
                </div>
                {
                    connectedStations?.length ? (
                        <div className='equip-content-toggle' onClick={() => handleToggle(2)}>
                            {getChevron(2)}
                            {`${connectedStations?.length} Connected station(s)`}
                        </div>
                    ) : (
                        <div className='equip-content-toggle-empty'>
                            {`${connectedStations?.length} Connected station(s)`}
                        </div>
                    )
                }
                <div className='equip-content-toggle' onClick={() => handleToggle(3)}>
                    {getChevron(3)}
                    Remote management
                </div>
            </div>
            {showDetails !== null && <div className={cn('content-details')}>
                {loading && showDetails === 1 && <BP.Spinner className='content-details-spinner' />}
                {showDetails === 0 && <div className={cn('interfaces-list')}>
                    {equip.interfaces.map((inter, index) => <E5HInterfaceItem index={index + 1} key={index} intrf={inter} />)}
                </div>}
                {showDetails === 1 && !loading && <div className={cn('system-list')}>
                    <div className='system-list-label'>Overview</div>
                    <div className='equip-top-content'>
                        <div className='content-item'>
                            <span className='equip-content-item-label'>Manufacturer :</span>
                            <div className='content-item-value'>{equip.manufacturer || '-'}</div>
                        </div>
                        <div className='content-item'>
                            <span className='equip-content-item-label'>Model :</span>
                            <div className='content-item-value'>{E5HEquipMore.ToCsv(equip.model, "")}</div>
                        </div>
                        <div className='content-item'>
                            <span className='equip-content-item-label'>CPU cores :</span>
                            <div className='content-item-value'>
                                {corecnt}
                            </div>
                        </div>
                        <div className='content-item'>
                            <span className='equip-content-item-label'>Hardware :</span>
                            <div className='content-item-value'>
                                {equip.hardwarev}
                            </div>
                        </div>
                        <div className='content-item'>
                            <span className='equip-content-item-label'>Number of reboots :</span>
                            <div className='content-item-value'>
                                {reboot instanceof E5EntHIndSysReb ? typeof reboot.rebcnt === "number" ?
                                    reboot.rebcnt : "N/A" :
                                    indicsysinfo.status.success ? "N/A" : ""}
                            </div>
                        </div>
                        <div className='content-item'>
                            <span className='equip-content-item-label'>RAM & Flash :</span>
                            <div className='content-item-value'>
                                {memtotal || 'N/A'}
                                {flashtotalstr ? <> & {flashtotalstr}</> : <> & N/A</>}
                            </div>
                        </div>
                    </div>
                    <div className='system-list-scores'>
                        <div className='system-list-scores-item'>
                            <div className='system-list-label'>System health scores</div>
                            <E5AngularGauge
                                gaugeinfo={
                                    {
                                        values: [cpuhealth, memhealth, flashealth, rebhealth, temphealth, prochealth],
                                        labels: [_("system-cpu"), _("system-mem"), _("system-flash"),
                                        _("system-reb"), _("system-temp"), _("system-proc")], loading: false, title: ''
                                    }
                                }
                                isNewComponent
                                customWrapperClass='custom'
                                customChartClass='eqipment-progress-chart'
                                customListWidth='equipment-list-progress-charts'
                                canvasSmall30
                            />
                        </div>
                        <div className='system-list-scores-item'>
                            <div className='system-list-label'>System metrics</div>

                            <div className="system-metric-chart-container">
                                <span className="description-text">{E5UtilI18n._("h-equip-sys-raw-mem-usage")}</span>
                                <div className="chart">
                                    <div className="chart-percentage" style={{width: `${E5Text.ScoreToPercent(memusagepercent/100)}%`}}></div>
                                </div>
                                <span className="description-text">{E5Text.ScoreToPercent(memusagepercent/100)}%</span>
                            </div>

                            <div className="system-metrics-spacement">
                                <span>{E5UtilI18n._("h-equip-sys-mem-usage-mb")} {memused}, </span>
                                <span>{E5UtilI18n._("h-equip-sys-mem-total-mb")} {memtotal}</span>
                            </div>

                            <div className="content-item system-metrics-spacement">
                                <span className="equip-content-item-label">{E5UtilI18n._("h-equip-sys-raw-sys-load1")} </span>
                                <span className={`content-item-value ${cpuload1 > 1 ? 'over-capacity': ''}`}>{cpuload1 > 0 ? cpuload1.toFixed(2) : 'N/A'}</span>
                            </div>

                            <div className="content-item system-metrics-spacement">
                                <span className="equip-content-item-label">{E5UtilI18n._("h-equip-sys-raw-sys-load5")} </span>
                                <span className={`content-item-value ${cpuload5 > 1 ? 'over-capacity': ''}`}>{cpuload5 > 0 ? cpuload5.toFixed(2) : 'N/A'}</span>
                            </div>

                            <div className="content-item system-metrics-spacement">
                                <span className="equip-content-item-label">{E5UtilI18n._("h-equip-sys-raw-sys-load15")} </span>
                                <span className={`content-item-value ${cpuload15 > 1 ? 'over-capacity': ''}`}>{cpuload15 > 0 ? cpuload15.toFixed(2) : 'N/A'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='system-list-charts'>
                        <div className='system-list-charts-item'>
                            <E5PieChart withNewComponent pieinfo={rebootinfo} />
                        </div>
                        <div className='system-list-charts-item'>
                            <E5PieChart withNewComponent pieinfo={flashblockinfo} chartOption={{ type: 'sunburst' }} />
                        </div>
                        <div className='system-list-charts-item'>
                            <E5PieChart withNewComponent pieinfo={flashcorruptinfo} chartOption={{ type: 'sunburst' }} />
                        </div>
                    </div>
                    <div className='system-list-tables'>
                        <div className='system-list-tables-item'>
                            <div className="system-list-label">Processes ({proccnt})</div>
                            <div className="e5h-equipsys-table table-header proc-table">
                                <div>{_("h-equip-sys-proc-pid")}</div>
                                <div>{_("h-equip-sys-proc-name")}</div>
                                <div>{_("h-equip-sys-proc-priority")}</div>
                                <div>{_("h-equip-sys-proc-memsize")}</div>
                                <div>{_("h-equip-sys-proc-memusage")}</div>
                                <div>{_("h-equip-sys-proc-cputime")}</div>
                            </div>
                            <div className={cn("e5h-equipsys-table table-rows proc-table", { 'no-data-wrapper': !procpid?.length })}>
                                {procpid?.length ? <>
                                    <div className="e5column-0">{procpid}</div>
                                    <div className="e5column-0">{procname}</div>
                                    <div className="e5column-0">{procpriority}</div>
                                    <div className="e5column-0">{procmemsize}</div>
                                    <div className="e5column-0">{procmemusage}</div>
                                    <div className="e5column-0">{proccputime}</div>
                                </> : <span className='no-data'>No data collected</span>}

                            </div>
                        </div>
                        <div className='system-list-tables-item'>
                            <div className="system-list-label">Crashes({crashcnt})</div>
                            <div className="e5h-equipsys-table table-header crash-table">
                                <div>{_("h-equip-sys-crash-proc-id")}</div>
                                <div>{_("h-equip-sys-crash-proc-name")}</div>
                                <div>{_("h-equip-sys-crash-time")}</div>
                                <div>{_("h-equip-sys-crash-softwarev")}</div>
                            </div>
                            <div className={cn("e5h-equipsys-table table-rows crash-table", { 'no-data-wrapper': !crashprocid?.length })}>
                                {crashprocid?.length ? <>
                                    <div className="e5column-0">{crashprocid}</div>
                                    <div className="e5column-0">{crashprocname}</div>
                                    <div className="e5column-0">{crashtime}</div>
                                    <div className="e5column-0">{crashsoftwarev}</div>
                                </> : <span className='no-data'>No data collected</span>}
                            </div>
                        </div>
                    </div>
                </div>}
                {showDetails === 2 && connectedStations?.length && <div className={cn('system-list')}>
                    {connectedStations.map((station: E5EntHStation) => {
                        const indicglobalinfo = E5StoreH.Ins()?.indicglobalinfo;
                        const fingerprint = indicglobalinfo.fingerprintresult.find((fingerprint) => {
                            return fingerprint.childId === station.macaddress
                        });
                        const deviceType = fingerprint?.deviceType ? fingerprint?.deviceType : 'Unknown';
                        const category = fingerprint?.deviceType ? fingerprint?.deviceType : '-';
                        const imgSrc = generateStationImage(deviceType);

                        return <div className='equip-connected-stations'>
                            <img src={imgSrc} alt='category' className='header-image' />
                            <div className='equip-top-content'>
                                <div className='content-item'>
                                    <span className='equip-content-item-label'>Hostname :</span>
                                    <div className='content-item-value'>{station.hostname || '-'}</div>
                                </div>
                                <div className='content-item'>
                                    <span className='equip-content-item-label'>MAC addr :</span>
                                    <div className='content-item-value'>{station.macaddress}</div>
                                </div>
                                <div className='content-item'>
                                    <span className='equip-content-item-label'>Category :</span>
                                    <div className='content-item-value'>
                                        {category}
                                    </div>
                                </div>
                                <div className='content-item'>
                                    <span className='equip-content-item-label'>Health score :</span>
                                    <div className='content-item-value'>
                                        <HealthScore score={Math.round(station.health * 100)} />
                                    </div>
                                </div>
                                <div className='content-item'>
                                    <span className='equip-content-item-label'>Band :</span>
                                    <div className='content-item-value'>
                                        {station.band}
                                    </div>
                                </div>
                                <div className='content-item'>
                                    <span className='equip-content-item-label'>Uptime :</span>
                                    <div className='content-item-value'>
                                        {/* {moment.duration(station.uptime, "minutes").format("h:mm")} */}
                                        {E5Text.Seconds2DHMS_str(station.uptime)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </div>}
                {showDetails === 3 ? <E5HEquipmentItemRdm actions={actions} equip={equip} />: null}
            </div>}

        </div>
    </div>
}

export default E5HEquipmentItem