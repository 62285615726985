import React, { useEffect, useState } from 'react';
import './DeviceInfo.css'
import { E5HStationList } from '../E5HStationList';
import { E5StoreH, E5StoreHEquips, E5StoreHIndicSys, E5StoreHStations } from '../../../store/E5StoreH';
import { E5NetElementType } from '../../../entity/E5Enums';
import { E5Text } from '../../../util/E5Text';
import moment from 'moment';

type Props = {
    selectedinfo: any; // for future refactoring - update type
    selectednode: string;
    generateStationImageForTopology: Function;
    indicsysinfo: E5StoreHIndicSys
};

const DeviceInfo = ({ selectedinfo, selectednode, generateStationImageForTopology, indicsysinfo }: Props) => {
    interface IData {
        onenode: E5StoreHEquips,
        onedevice: E5StoreHStations;
    }
    const [data, setData] = useState<IData>({} as IData);
    function durationFormatter(milliseconds: number): string {

        return moment.utc(milliseconds).format('HH:mm:ss');
    }
    const RenderScore = (score: number | null): JSX.Element => {
        let jsx: JSX.Element;
        if (score === null) jsx = <div className="scorediv text">N/A</div>;
        else {
            let img: string = "/img/scores/score-" + E5HStationList.GetScorePart(score) + ".png";
            jsx = <div className="scorediv e5line-5">
                <img className="scoreimg" src={img} alt="" />
                <div>{E5Text.ScoreToPercent(score) + "%"}</div>
            </div>;
        }
        return jsx;
    };
    const handeScoreColor = (data: number) => {
        const score = E5HStationList.GetScorePart(data)
        if (score === 0) {
            return <div className='red'></div>
        }
        if (score === 1) {
            return <div className='orange'></div>
        }
        if (score === 2) {
            return <div className='yellow'></div>
        }
        if (score === 3) {
            return <div style={{ display: 'flex', color: 'darkgreen' }}>
                <div className='green'></div>
                <p style={{ marginLeft: '5px' }}>  {`  Excellent`}</p>
            </div>
        }
        if (score === 4) {
            return <div style={{ display: 'flex', color: 'darkgreen' }}>
                <div className='darkgreen'></div>
                <p style={{ marginLeft: '5px' }}>{`  Excellent`}</p>
            </div>
        }
    }
    const BuildSelectedData = () => {
        let st: any = {
            onenode: { equips: [], equipmap: new Map() }, onedevice: { stations: [], stationmap: new Map() },
            mindictype: E5NetElementType.none, mindicname: "", mindiceth: undefined, mindicwifi: undefined
        };
        if (selectedinfo.type === E5NetElementType.node) {
            st.onenode?.equips.push(selectedinfo.equip);
            st.onenode?.equipmap.set(selectedinfo.equip.imei, selectedinfo.equip);
            st.mindictype = selectedinfo.type;
            st.mindicname = selectedinfo.equip.imei;
        }
        if (selectedinfo.type === E5NetElementType.device) {
            st.onedevice?.stations.push(selectedinfo.station);
            st.onedevice?.stationmap.set(selectedinfo.station.macaddress, selectedinfo.station);
            st.mindictype = selectedinfo.type;
            st.mindicname = selectedinfo.station.macaddress;
        }
        setData(st);
    }
    useEffect(() => {
        BuildSelectedData()
    }, [selectednode]);
    const indicglobalinfo = E5StoreH.Ins()?.indicglobalinfo;
    const fingerprint = indicglobalinfo?.fingerprintresult?.find((fingerprint) => {
        return fingerprint.childId === data?.onedevice?.stations[0]?.macaddress
    });
    const category = fingerprint?.deviceType ? fingerprint?.deviceType : '-';
    const src = generateStationImageForTopology(category)
    if (selectedinfo.type === E5NetElementType.device) {
        return <div className='onedevice-wrapper'>
            <div className='onedevice-wrapper-left'>
                <img className='device-image' src={src} />
            </div>
            <div className='onedevice-wrapper-middle'>
                <div className='onedevice-item'>
                    <p className='title'>Hostname:</p>
                    <p className='value'>{data?.onedevice?.stations[0]?.hostname}</p>
                </div>
                <div className='onedevice-item'>
                    <p className='title'>MAC addr:</p>
                    <p className='value'>{data?.onedevice?.stations[0]?.macaddress}</p>
                </div>
                <div className='onedevice-item'>
                    <p className='title'>Category:</p>
                    <p className='value'>{category}</p>
                </div>
            </div>
            <div className='onedevice-wrapper-right'>
                <div className='onedevice-item'>
                    <p className='title'>Health score:</p>
                    <p className='value'>{handeScoreColor(data?.onedevice?.stations[0]?.health)}</p>
                </div>
                <div className='onedevice-item'>
                    <p className='title'>Band:</p>
                    <p className='value'>{data?.onedevice?.stations[0]?.band}</p>
                </div>
                <div className='onedevice-item'>
                    <p className='title'>Uptime:</p>
                    <p className='value'>{E5Text.Seconds2DHMS_str(data?.onedevice?.stations[0]?.uptime)}</p>
                </div>
            </div>
        </div>
    } else if (selectedinfo.type === E5NetElementType.node) {
        const { cpumem } = indicsysinfo;
        const imei = data.onenode?.equips[0]?.imei;
        const equipCpu = cpumem.find((item) => item.imei === imei);
        //@ts-ignore -- for future will update old types
        let uptime;
        if (equipCpu) {
            uptime = equipCpu.uptime;
        }
        const type = data?.onenode?.equips[0].nodetype;
        let src;
        if (type === 'ext' ||
            type === 'gw'
        ) {
            src = "/img/nodes_and_devices/" + type + "-black-round.png";
        } else {
            src = '/img/nodes_and_devices/ext-black.png'
        }

        return <div className='onedevice-wrapper'>
            <div className='onedevice-wrapper-left'>
                <img className='device-image' src={src} />
            </div>
            <div className='onedevice-wrapper-middle'>
                <div className='onedevice-item'>
                    <p className='title'>IMEI:</p>
                    <p className='value'>{imei}</p>
                </div>
                <div className='onedevice-item'>
                    <p className='title'>Model:</p>
                    <p className='value'>{data.onenode?.equips[0]?.model}</p>
                </div>
                <div className='onedevice-item'>
                    <p className='title'>Software:</p>
                    <p className='value'>{data.onenode?.equips[0]?.softwarev}</p>
                </div>
            </div>
            <div className='onedevice-wrapper-right'>
                <div className='onedevice-item'>
                    <p className='title'>Health score:</p>
                    <p className='value'>{handeScoreColor(data.onenode?.equips[0]?.health)}</p>
                </div>
                <div className='onedevice-item'>
                    <p className='title'>Hardware:</p>
                    <p className='value'>{data.onenode?.equips[0]?.hardwarev}</p>
                </div>
                <div className='onedevice-item'>
                    <p className='title'>Uptime:</p>
                    <p className='value'>{E5Text.Seconds2DHMS_str(uptime)}</p>
                </div>
            </div>
        </div>
    }
    return <div></div>

}

export default DeviceInfo;