import {E5EntHIncidSys} from "./E5EntHIncidSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidSysCpu extends E5EntHIncidSys {
	corecnt: number|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.corecnt = E5Entity.ParseIntNull(json.cpu_core_count);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), cpu_core_count: this.corecnt};
	}
}
