import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5EntHIndSysCrash, E5SysCrashDetail} from "../../../entity/household/indic/E5EntHIndSysCrash";
import {E5EntHIndSysFlash, E5SysFlashDetail} from "../../../entity/household/indic/E5EntHIndSysFlash";
import {E5EntHIndSysProc, E5SysProcDetail} from "../../../entity/household/indic/E5EntHIndSysProc";
import {E5EntHIndSysHealth} from "../../../entity/household/indic/E5EntHIndSysHealth";
import {E5EntHIndSysCpuMem} from "../../../entity/household/indic/E5EntHIndSysCpuMem";
import {E5EntHIndSysTemp} from "../../../entity/household/indic/E5EntHIndSysTemp";
import {E5EntHIndSysReb} from "../../../entity/household/indic/E5EntHIndSysReb";
import {E5BulletInfo, E5StorePieInfo} from "../../../request/E5ServiceCommon";
import {E5StoreHIndicSys, E5StoreHSearchNi} from "../../../store/E5StoreH";
import {E5EntHEquip} from "../../../entity/household/topo/E5EntHEquip";
import {E5BulletGauge} from "../../../global/plot/E5BulletGauge";
import {E5RadarChart} from "../../../global/plot/E5RadarChart";
import {E5PieChart} from "../../../global/plot/E5PieChart";
import {E5MainConfig} from "../../../global/E5MainConfig";
import {E5Static} from "../../../global/E5MainStatics";
import {E5StoreLangInfo} from "../../../store/E5Store";
import {E5RequestH} from "../../../request/E5RequestH";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5HEquipMore} from "../E5HEquipMore";
import {E5Text} from "../../../util/E5Text";
//
import "./E5HEquipSys.css";

//E5
interface E5HEquipSysState {}

//E5
interface E5HEquipSysProps {
	searchniinfo: E5StoreHSearchNi;
	indicsysinfo: E5StoreHIndicSys;
	langinfo: E5StoreLangInfo;
	node: E5EntHEquip;
}

//E5
export const E5HEquipSys = observer(class E5HEquipSys extends React.PureComponent<E5HEquipSysProps, E5HEquipSysState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let {_} = E5UtilI18n, {node, indicsysinfo} = this.props, {loading} = indicsysinfo.status;

		// crash

		let crash: E5EntHIndSysCrash|undefined = undefined, idx: number;
		for (idx = 0; idx < indicsysinfo.crash.length; idx++)
			if (indicsysinfo.crash[idx].imei === node.imei) crash = indicsysinfo.crash[idx];

		let crashprocid: JSX.Element[] = [], crashtime: JSX.Element[] = [], crashprocname: JSX.Element[] = [],
			crashsoftwarev: JSX.Element[] = [], crashcnt: number|string = crash?.crashdetail?.length ?? "N/A";

		if (crash instanceof E5EntHIndSysCrash && Array.isArray(crash.crashdetail))
			for (idx = 0; idx < crash.crashdetail.length; idx++) {
				let det: E5SysCrashDetail = crash.crashdetail[idx];

				crashprocid.push(<div key={"crashprocid" + idx}>{det.procid}</div>);
				crashtime.push(<div key={"crashtime" + idx}>{det.time.format("HH:mm")}</div>);
				crashprocname.push(<div key={"crashprocname" + idx}>
					{det.procname.length > 20 && <BP.Tooltip content={det.procname} position={BP.Position.BOTTOM_RIGHT}>
						{det.procname.substring(0, 16) + " ..."}</BP.Tooltip>}
					{det.procname.length <= 20 && det.procname}
				</div>);
				crashsoftwarev.push(<div key={"crashsoftwarev" + idx}>{det.softwarev}</div>);
			}

		// flash

		let flash: E5EntHIndSysFlash|undefined = undefined;
		for (idx = 0; idx < indicsysinfo.flash.length; idx++)
			if (indicsysinfo.flash[idx].imei === node.imei) flash = indicsysinfo.flash[idx];

		let flashtotalstr: string, flashtotal: number|undefined;

		let flashblockids: string[] = [], flashblockparents: string[] = [], flashblockvalues: number[] = [],
			flashblocklabels: string[] = [];

		let flashcorruptids: string[] = [], flashcorruptparents: string[] = [], flashcorruptvalues: number[] = [],
			flashcorruptlabels: string[] = [];

		if (flash instanceof E5EntHIndSysFlash) {
			flashtotal = flash.flashdetail?.reduce((acc: number, cur: E5SysFlashDetail) =>
				acc + cur.sizebad + cur.sizeavail + cur.sizeused, 0);
			flashtotalstr = typeof flashtotal === "number" ? flashtotal < (1024 * 1024) ? flashtotal < 1024 ?
					flashtotal + "B" : Math.round(flashtotal / 1024 * 10) / 10 + "KB" :
				Math.round(flashtotal / (1024 * 1024) * 10) / 10 + "MB" : "N/A";

			flashblockids = ["Flash"];
			flashblockparents = [""];
			flashblockvalues = [0];
			flashblocklabels = ["Flash"];

			flashcorruptids = ["Flash"];
			flashcorruptparents = [""];
			flashcorruptvalues = [0];
			flashcorruptlabels = ["Flash"];

			if (Array.isArray(flash.flashdetail)) for (idx = 0; idx < flash.flashdetail.length; idx++) {
				let det: E5SysFlashDetail = flash.flashdetail[idx];

				flashblockids.push(det.name);
				flashblockparents.push("Flash");
				flashblockvalues.push(0);
				flashblocklabels.push(det.name);

				flashblockids.push(det.name + "bad");
				flashblockparents.push(det.name);
				flashblockvalues.push(det.sizebad ? det.sizebad / 1024 / 1024 : 0);
				flashblocklabels.push(_("h-equip-sys-flash-bad"));

				flashblockids.push(det.name + "used");
				flashblockparents.push(det.name);
				flashblockvalues.push(det.sizeused ? det.sizeused / 1024 / 1024 : 0);
				flashblocklabels.push(_("h-equip-sys-flash-used"));

				flashblockids.push(det.name + "available");
				flashblockparents.push(det.name);
				flashblockvalues.push(det.sizeavail ? det.sizeavail / 1024 / 1024 : 0);
				flashblocklabels.push(_("h-equip-sys-flash-avail"));

				flashcorruptids.push(det.name);
				flashcorruptparents.push("Flash");
				flashcorruptvalues.push(0);
				flashcorruptlabels.push(det.name);

				flashcorruptids.push(det.name + "valid");
				flashcorruptparents.push(det.name);
				flashcorruptvalues.push(det.validcnt ?? 0);
				flashcorruptlabels.push(_("h-equip-sys-flash-vol-valid"));

				flashcorruptids.push(det.name + "corrupt");
				flashcorruptparents.push(det.name);
				flashcorruptvalues.push(det.corruptcnt ?? 0);
				flashcorruptlabels.push(_("h-equip-sys-flash-vol-corrupt"));
			}
		} else flashtotalstr = indicsysinfo.status.success ? "N/A" : "";

		let flashstr: string =
			typeof flash?.flashdetail?.length === "number" && flash.flashdetail.length > 0 ? "" : "(N/A)";

		let flashblockinfo: E5StorePieInfo = {
			loading, valueisseconds: false, labelisincident: false, title: "", width: 275, height: 275,
			ids: flashblockids, parents: flashblockparents, values: flashblockvalues, labels: flashblocklabels
		}, flashcorruptinfo: E5StorePieInfo = {
			loading, valueisseconds: false, labelisincident: false, title: "", width: 275, height: 275,
			ids: flashcorruptids, parents: flashcorruptparents, values: flashcorruptvalues, labels: flashcorruptlabels
		};

		// health

		let {health} = indicsysinfo, IsSameImei = (h: E5EntHIndSysHealth): boolean => h.imei === this.props.node.imei,
			{plotred, plotorange, plotyellow, plotgreenlight, plotgreendark} = E5Static.plotcolors;

		let cpuhealth: number|null = null, flashealth: number|null = null,
			memhealth: number|null = null, prochealth: number|null = null, rebhealth: number|null = null,
			temphealth: number|null = null, nonenull: boolean = false;

		if (health.some(IsSameImei)) {
			let eqphealth: E5EntHIndSysHealth = health.filter(IsSameImei)[0];

			cpuhealth = eqphealth.cpuhealth ?? null;
			flashealth = eqphealth.flashhealth ?? null;
			memhealth = eqphealth.memhealth ?? null;
			prochealth = eqphealth.prochealth ?? null;
			rebhealth = eqphealth.rebhealth ?? null;
			temphealth = eqphealth.temphealth ?? null;
			nonenull = [cpuhealth, flashealth, memhealth, prochealth, rebhealth, temphealth].reduce(
				(acc: boolean, cur: number|null) => acc && cur !== null, true);
		}

		let avghealth: number = ((cpuhealth ?? 0) + (flashealth ?? 0) + (memhealth ?? 0) +
				(prochealth ?? 0) + (rebhealth ?? 0) + (temphealth ?? 0)) / 7,
			avghealthcolor: string = nonenull ? (avghealth < 0.2 ? plotred : avghealth < 0.4 ? plotorange :
				avghealth < 0.6 ? plotyellow : avghealth < 0.8 ? plotgreenlight : plotgreendark) + "88" : "#00000000";

		// cpumem

		let cpumem: E5EntHIndSysCpuMem|undefined = undefined;
		for (idx = 0; idx < indicsysinfo.cpumem.length; idx++)
			if (indicsysinfo.cpumem[idx].imei === node.imei) cpumem = indicsysinfo.cpumem[idx];

		let memtotal: string = cpumem instanceof E5EntHIndSysCpuMem ?
			typeof cpumem.memtotal === "number" ? cpumem.memtotal < (1024 * 1024) ? cpumem.memtotal < 1024 ?
					cpumem.memtotal + "B" : Math.round(cpumem.memtotal / 1024 * 10) / 10 + "MB" :
				Math.round(cpumem.memtotal / (1024 * 1024) * 10) / 10 + "GB" : "N/A"
			: indicsysinfo.status.success ? "N/A" : "";

		let memusagepercent: number|null = cpumem?.memusedpercent ?? null,
			memusagemb: number|null = cpumem?.memusedmb ? Math.round(cpumem?.memusedmb / 1024) : null,
			memtotalmb: number|null = cpumem?.memtotal ? Math.round(cpumem?.memtotal / 1024) : null,
			cpuload1: number|null = cpumem?.load1 ?? null, cpuload5: number|null = cpumem?.load5 ?? null,
			cpuload15: number|null = cpumem?.load15 ?? null;

		// process

		let process: E5EntHIndSysProc|undefined = undefined;
		for (idx = 0; idx < indicsysinfo.process.length; idx++)
			if (indicsysinfo.process[idx].imei === node.imei) process = indicsysinfo.process[idx];

		let proccnt: number|string = process?.proccnt ?? "N/A";

		let procpid: JSX.Element[] = [], procname: JSX.Element[] = [], procpriority: JSX.Element[] = [],
			procmemsize: JSX.Element[] = [], procmemusage: JSX.Element[] = [], proccputime: JSX.Element[] = [];

		if (process instanceof E5EntHIndSysProc && Array.isArray(process.procdetail))
			for (idx = 0; idx < process.procdetail.length; idx++) {
				let det: E5SysProcDetail = process.procdetail[idx];

				procpid.push(<div key={"procpid" + idx}>{det.pid}</div>);
				procname.push(<div key={"procname" + idx}>
					{det.name.length > 20 && <BP.Tooltip content={det.name} position={BP.Position.BOTTOM_RIGHT}>
						{det.name.substring(0, 16) + " ..."}</BP.Tooltip>}
					{det.name.length <= 20 && det.name}
				</div>);
				procpriority.push(<div key={"procpriority" + idx}>{det.priority}</div>);
				procmemsize.push(<div key={"procmemsize" + idx}>
					{det.memsize ? Math.round(det.memsize / 1024) : "N/A"}</div>);
				procmemusage.push(<div key={"procmemusage" + idx}>{det.memusage ? (Math.round(det.memusage*100) / 100).toFixed(2): "N/A"}</div>);
				proccputime.push(<div key={"proccputime" + idx}>
					{det.cputime === null ? "" : E5Text.Seconds2DHMS_str(det.cputime)}</div>);
			}

		// reboot

		let reboot: E5EntHIndSysReb|undefined = undefined;
		for (idx = 0; idx < indicsysinfo.reboot.length; idx++)
			if (indicsysinfo.reboot[idx].imei === node.imei) reboot = indicsysinfo.reboot[idx];

		let rebootids: string[] = [], rebootparents: string[] = [], rebootvalues: number[] = [],
			rebootlabels: string[] = [];

		if (reboot instanceof E5EntHIndSysReb) {
			rebootids = ["Reboot"];
			rebootparents = [""];
			rebootvalues = [0];
			rebootlabels = ["Reboot"];

			let key: string, cnt: number;
			if (reboot.reasonmap instanceof Map) for ([key, cnt] of reboot.reasonmap) {
				rebootids.push(key);
				rebootparents.push("Reboot");
				rebootvalues.push(cnt);
				rebootlabels.push(key);
			}
		}

		let rebootinfo: E5StorePieInfo = {
			title: "", loading, valueisseconds: false, labelisincident: false, width: 275, height: 275,
			ids: rebootids, parents: rebootparents, values: rebootvalues, labels: rebootlabels
		};

		// temperature

		let temperature: E5EntHIndSysTemp|undefined = undefined;
		for (idx = 0; idx < indicsysinfo.temperature.length; idx++)
			if (indicsysinfo.temperature[idx].imei === node.imei) temperature = indicsysinfo.temperature[idx];

		let tempval: number|null = temperature?.tempval ?? null;

		let memdesc: string =
				_("h-equip-sys-raw-mem-usage-mb") + " : " + (memusagemb === null ? "N/A, " : memusagemb + "MB, ") +
				_("h-equip-sys-raw-mem-total-mb") + " : " + (memtotalmb === null ? "N/A" : memtotalmb + "MB"),
			tempdesc: string = _("h-equip-sys-raw-temperature-low") + " : " +
				(typeof temperature?.lowlimit === "number" ? temperature?.lowlimit + "°C, " : "N/A, ") +
				_("h-equip-sys-raw-temperature-high") + " : " +
				(typeof temperature?.highlimit === "number" ? temperature?.highlimit + "°C" : "N/A");
		let bullets: E5BulletInfo[] = [
			{value: memusagepercent, label: _("h-equip-sys-raw-mem-usage"), unit: "percent", desc: memdesc},
			{value: cpuload1, label: _("h-equip-sys-raw-cpu-load1"), unit: "raw", max: cpumem?.corecnt},
			{value: cpuload5, label: _("h-equip-sys-raw-cpu-load5"), unit: "raw", max: cpumem?.corecnt},
			{value: cpuload15, label: _("h-equip-sys-raw-cpu-load15"), unit: "raw", max: cpumem?.corecnt},
			{
				value: tempval, label: _("h-equip-sys-raw-temperature"), unit: "celsius",
				info: temperature?.status, desc: tempdesc
			}
		];

		return <div className="e5h-equipsys e5column-5">
			<div className="e5line-5">
				<BP.Button text={_("h-equip-sys-data")} small disabled/>
				<BP.Button onClick={this.RefreshIndicSys} small disabled={loading} icon="refresh"/>
				{loading && <BP.Spinner className="e5spinwait" size={15}/>}
			</div>
			<div className="e5compo">
				<div className="e5compotitle">{_("h-equip-sys-overview")}</div>
				<div className="e5line-5">
					<div className="e5linefull">
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{_("h-equip-sys-manufacturer")} :&nbsp;
								<span className="value">{node.manufacturer}</span>
							</div>
						</div>
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{_("h-equip-sys-model")} :&nbsp;
								<span className="value small">{E5HEquipMore.ToCsv(node.model, "")}</span>
							</div>
						</div>
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{_("h-equip-sys-cpu-cores")} :&nbsp;
								<span className="value">
									{cpumem instanceof E5EntHIndSysCpuMem ? typeof cpumem.corecnt === "number" ?
										cpumem.corecnt : "N/A" : indicsysinfo.status.success ? "N/A" : ""}</span>
							</div>
						</div>
					</div>
					<div className="e5linefull">
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{_("h-equip-sys-hardwarev")} :&nbsp;
								<span className="value">{node.hardwarev}</span>
							</div>
						</div>
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{_("h-equip-nb-reboot")} :&nbsp;
								<span className="value small">
									{reboot instanceof E5EntHIndSysReb ? typeof reboot.rebcnt === "number" ?
											reboot.rebcnt : "N/A" :
										this.props.indicsysinfo.status.success ? "N/A" : ""}</span>
							</div>
						</div>
						<div className="e5line-0">
							<div className="dot">&bull;</div>
							<div className="e5linefull">
								{_("h-equip-sys-ram")}<sup>1</sup> &&nbsp;
								{_("h-equip-sys-flash")}<sup>2</sup> :&nbsp;
								<span className="value small">
									{memtotal !== "" && <>{memtotal}<sup>1</sup></>}
									{flashtotalstr !== "" &&
									<>{memtotal !== "" && <> & </>}{flashtotalstr}<sup>2</sup></>}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{indicsysinfo.status.success && <>
				<div className="e5line-5">
					<E5RadarChart radarinfo={{
						values: [cpuhealth, memhealth, flashealth, rebhealth, temphealth, prochealth],
						title: _("h-equip-sys-health"), loading, fillcolor: avghealthcolor, labels: [
							_("system-cpu"), _("system-mem"), _("system-flash"),
							_("system-reb"), _("system-temp"), _("system-proc")
						]
					}}/>
					<E5BulletGauge
						gaugeinfo={{title: _("h-equip-sys-raw"), loading, bullets}}/>
				</div>
				{E5MainConfig.GetSystemRebootEnabled() && <div className="e5compo">
					<div className="e5compotitle">
						{_("h-equip-sys-reb-detail")}&nbsp;({reboot?.rebcnt ?? "N/A"})
					</div>
					{reboot !== undefined && <E5PieChart pieinfo={rebootinfo}/>}
				</div>}
				{E5MainConfig.GetSystemFlashEnabled() && <div className="e5line-5">
					<div className="e5linefull e5compo">
						<div className="e5compotitle">{_("h-equip-sys-flash-detail-block")}&nbsp;
							{flashstr}</div>
						{flashblockids.length > 0 &&
						<E5PieChart pieinfo={flashblockinfo}/>}
					</div>
					<div className="e5linefull e5compo">
						<div className="e5compotitle">{_("h-equip-sys-flash-detail-corrupt")}&nbsp;
							{flashstr}</div>
						{flashcorruptids.length > 0 &&
						<E5PieChart pieinfo={flashcorruptinfo}/>}
					</div>
				</div>}
				{E5MainConfig.GetSystemProcessEnabled() && <div className="e5compo">
					<div className="e5compotitle">{_("h-equip-sys-proc")}&nbsp;({proccnt})</div>
					{proccnt > 0 && <>
						<div className="e5h-equipsys-table table-header proc-table">
							<div children={<div>{_("h-equip-sys-proc-pid")}</div>}/>
							<div children={<div>{_("h-equip-sys-proc-name")}</div>}/>
							<div children={<div>{_("h-equip-sys-proc-priority")}</div>}/>
							<div children={<div>{_("h-equip-sys-proc-memsize")}</div>}/>
							<div children={<div>{_("h-equip-sys-proc-memusage")}</div>}/>
							<div children={<div>{_("h-equip-sys-proc-cputime")}</div>}/>
						</div>
						<div className="e5h-equipsys-table table-rows proc-table">
							<div className="e5column-0">{procpid}</div>
							<div className="e5column-0">{procname}</div>
							<div className="e5column-0">{procpriority}</div>
							<div className="e5column-0">{procmemsize}</div>
							<div className="e5column-0">{procmemusage}</div>
							<div className="e5column-0">{proccputime}</div>
						</div>
					</>}
				</div>}
				{E5MainConfig.GetSystemProcessEnabled() && <div className="e5compo">
					<div className="e5compotitle">{_("h-equip-sys-crash")}&nbsp;({crashcnt})</div>
					{crashcnt > 0 && <>
						<div className="e5h-equipsys-table table-header crash-table">
							<div children={<div>{_("h-equip-sys-crash-proc-id")}</div>}/>
							<div children={<div>{_("h-equip-sys-crash-proc-name")}</div>}/>
							<div children={<div>{_("h-equip-sys-crash-time")}</div>}/>
							<div children={<div>{_("h-equip-sys-crash-softwarev")}</div>}/>
						</div>
						<div className="e5h-equipsys-table table-rows crash-table">
							<div className="e5column-0">{crashprocid}</div>
							<div className="e5column-0">{crashprocname}</div>
							<div className="e5column-0">{crashtime}</div>
							<div className="e5column-0">{crashsoftwarev}</div>
						</div>
					</>}
				</div>}
			</>}
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	RefreshIndicSys = (event?: React.MouseEvent): void => {
		event?.stopPropagation();
		E5RequestH.Ins().FetchIndicSys(this.props.searchniinfo.networkid, undefined);
	};
});
