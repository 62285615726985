import { E5UtilI18n } from '../../../global/E5MainLang';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import './E5EditProfilePage.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import { E5EntUser } from '../../../entity/E5EntUser';
import { updateUserProfileData } from '../../../request/E5RequestProfile';
import intlTelInput from 'intl-tel-input';

export interface E5EditProfilePageProps {
    user: E5EntUser;
    updateFunction: (result: string, message: string, sendLogout: boolean) => void;
}

export default function E5EditProfilePage(props: Readonly<E5EditProfilePageProps>) {
    const defaultValues: { [key: string]: any } = {
        username: props.user.username,
        firstName: props.user.firstname,
        lastName: props.user.lastname,
        email: props.user.email,
        phoneNumber: props.user.phonenumber,
        company: props.user.company,
        jobTitle: props.user.jobtitle
    };

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        defaultValues,
        criteriaMode: 'all',
    });
    const formValues = watch();

    const phoneInputRef = useRef<HTMLInputElement>(null);
    const itiRef = useRef<any>(null);

    const disabled = !props.user.roles.includes("ROLE_ADMIN");
    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(true);

    const updatePhoneNumber = useCallback(() => {
        if (itiRef.current) {
            const currentCountry = itiRef.current.getSelectedCountryData();
            const number = itiRef.current.getNumber().slice(currentCountry.dialCode.length + 1);
            setValidPhoneNumber(number === '' ? true : itiRef.current.isValidNumber());

            if (validPhoneNumber && number.length === 9) {
                setValue("phoneNumber", `0${number}`);
            } else {
                setValue("phoneNumber", number);
            }

        }
    }, []);

    useEffect(() => {
        const phoneInput = phoneInputRef.current;
        if (phoneInput) {
            const iti = intlTelInput(phoneInput, {
                initialCountry: "fr",
                separateDialCode: false,
                autoInsertDialCode: false,
                autoPlaceholder: "aggressive",
                formatOnDisplay: true,
                customPlaceholder: (selectedCountryPlaceholder) => `${selectedCountryPlaceholder}`,
                utilsScript: "./scripts/utils.js"
            });

            itiRef.current = iti;

            // Set up the library's custom event listeners
            phoneInput.addEventListener('countrychange', updatePhoneNumber);
            phoneInput.addEventListener('input', updatePhoneNumber);

            // Clean up function to destroy the instance
            return () => {
                iti.destroy();
                phoneInput.removeEventListener('countrychange', updatePhoneNumber);
                phoneInput.removeEventListener('input', updatePhoneNumber);
            };
        }
    }, []);

    const canSave = () => {
        return JSON.stringify(defaultValues) !== JSON.stringify(formValues) && validPhoneNumber && !Object.keys(errors).length;
    };

    const onSubmit = (data: any) => {
        const dataToSave: { [key: string]: any } = {};

        const currentValues: { [key: string]: any } = data;

        Object.keys(currentValues).forEach(key => {
            if (currentValues[key] !== defaultValues[key]) {
                dataToSave[key] = currentValues[key];
            }
        });

        if (Object.keys(dataToSave).length) {
            updateUserProfileData(props.user.username, dataToSave)
                .then(() => {
                    const updateUsername = Object.keys(dataToSave).includes('username');

                    props.updateFunction('success', E5UtilI18n._('profile-user-updated'), updateUsername);
                })
                .catch(() => {
                    props.updateFunction('error', E5UtilI18n._('profile-user-not-updated'), false);
                });
        }
    }

    const getClasses = (formControl: string): string => {
        let className = 'e5column-20';

        if (disabled) {
            className += ' e5formgroupdisabled';
        } else {
            className += ' e5formgroup';
            if (errors[formControl]) {
                className += ' invalid-field';
            }
        }
        return className;
    }

    return (
        <div className="e5section">
            <div className="e5sectionpadding e5borderbottom">
                <h3 className="e5nomargin">{E5UtilI18n._("profile-edit-profile")}</h3>
            </div>

            <div className="e5sectionpadding e5ypadding-20">
                <form id="edit-profile" onSubmit={handleSubmit(onSubmit)}>
                    <div className={getClasses('username')} >
                        <label htmlFor="username">{E5UtilI18n._("admin-users-username")}</label>
                        <input type="text" id="username" disabled={disabled} {...register("username", { required: true })} />
                    </div>

                    <div className={getClasses('firstName')} >
                        <label htmlFor="firstname">{E5UtilI18n._("admin-users-firstname")}</label>
                        <input type="text" id="firstname" disabled={disabled} {...register("firstName", { required: true, maxLength: 255 })} />
                    </div>

                    <div className={getClasses('lastName')} >
                        <label htmlFor="lastname">{E5UtilI18n._("admin-users-lastname")}</label>
                        <input type="text" id="lastname" disabled={disabled} {...register("lastName", { required: true, maxLength: 255 })} />
                    </div>

                    <div className={getClasses('email')} >
                        <label htmlFor="email">{E5UtilI18n._("admin-users-email")}</label>
                        <input type="text" id="email" disabled={disabled} {...register("email", { required: true, pattern: /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/g })} />
                    </div>

                    <div className='phone-container'>
                        <span className="phone-number">{E5UtilI18n._("profile-phone-number")}</span>
                        <input id="phone" ref={phoneInputRef} type="tel" className={`iti__tel-input ${validPhoneNumber ? '' : 'invalid-field'}`} defaultValue={formValues.phoneNumber} />
                    </div>

                    <div className={`e5formgroup e5column-20 ${errors?.company?.types ? 'invalid-field' : ''}`}>
                        <label htmlFor="company">{E5UtilI18n._("admin-users-company")}</label>
                        <input type="company" id="company" {...register("company", { maxLength: 255 })} />
                    </div>

                    <div className={`e5formgroup e5column-20 ${errors?.jobTitle?.types ? 'invalid-field' : ''}`}>
                        <label htmlFor="jobtitle">{E5UtilI18n._("admin-users-jobtitle")}</label>
                        <input type="jobtitle" id="jobtitle" {...register("jobTitle", { maxLength: 255 })} />
                    </div>

                    <button className="e5button" type="submit" disabled={!canSave()}>
                        {E5UtilI18n._("profile-update-information")}
                    </button>
                </form>
            </div>
        </div>
    );
}