export function channelBandChangesChartOptions(data: string[][]): any {
    const seriesData = data?.map((value) => {
        return {
            value: [value[0], value[1], value[2]],
            tooltip: {
                borderColor: 'transparent',
                formatter: function (params: any) {
                    return `${params.value[1]}<br>${params.marker}<span style="float: right; margin-left: 20px">${params.value[2]} <b>${params.value[0]}</b></span>`;
                },
                textStyle: {
                    fontSize: 14,
                    fontFamily: 'Montserrat',
                }
            }
        };
    });

    return {
        tooltip: {
            trigger: 'item',
            formatter: function (params: any) {
                return `${params[0].value[1]}<br>${params[0].marker}<span style="float: right; margin-left: 20px">${params[0].value[2]} <b>${params[0].value[0]}</b></span>`;
            },
            textStyle: {
                fontSize: 14,
                fontFamily: 'Montserrat',
            }
        },
        grid: {
            left: '2%',
            right: '2%',
            bottom: '15%',
            containLabel: true
        },
        toolbox: {
            right: '4%',
            feature: {
                dataView: {
                    show: true,
                    readOnly: true
                },
                dataZoom: {
                    yAxisIndex: 'none'
                },
                magicType: {
                    show: true,
                    type: ['line', 'bar'],
                    option: { bar: { stack: 'Add' }, line: { stack: '' } }
                },
                restore: {
                    show: true,
                },
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: ['50%', '50%'],
            axisPointer: {
                show: true,
                snap: true,
                triggerTooltip: true,
                type: 'line',
                label: {
                    show: false,
                },
            },
            axisLine: {
                show: true,
            },
        },
        yAxis: {
            type: 'category',
            boundaryGap: ['50%', '50%'],
            axisLine: {
                show: seriesData.length > 0,
            }
        },
        legend: {
            show: true
        },
        series: [{
            data: seriesData,
            type: 'line',
            smooth: true,
        }],
    };
}