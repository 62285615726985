import React from "react";
import { observer } from "mobx-react";
//
import { E5StoreH, E5StoreHSearchNi, E5StoreHSearchNiAuto } from "../../store/E5StoreH";
import { E5Store, E5StoreLangInfo, E5StorePageInfo } from "../../store/E5Store";
import { E5SelectDate } from "../../global/component/E5SelectDate";
import { E5SearchNI } from "../../global/component/E5SearchNI";
import { E5Page, E5Path } from "../../global/E5MainStatics";
import { E5MainConfig } from "../../global/E5MainConfig";
import { E5RequestH } from "../../request/E5RequestH";
import { E5HStatus } from "./E5HStatus";
//
import "./E5HFilters.css";

//E5
interface E5HFiltersState { }

//E5
interface E5HFiltersProps {
	langinfo: E5StoreLangInfo;
	searchniinfo: E5StoreHSearchNi;
	searchniautoinfo: E5StoreHSearchNiAuto;
	pageinfo: E5StorePageInfo;
	systeminfo?: boolean;
	dashboardHPage?: boolean;
	withIncidents?: boolean;
	householdIdFromQuery: string;
}

//E5
export const E5HFilters = observer(class E5HFilters extends React.PureComponent<E5HFiltersProps, E5HFiltersState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		const { dashboardHPage, searchniautoinfo, searchniinfo } = this.props;

		return <div className="e5compo e5wifih-filters e5line-10">
			<img className="household-dashboard-search-svg" src='./img/welcome/search.svg' />
			<E5SearchNI langinfo={E5Store.Ins().langinfo} callback={this.StartSearch}
				searchniinfo={E5StoreH.Ins().searchniinfo} householdIdFromQuery={this.props.householdIdFromQuery} searchniautoinfo={E5StoreH.Ins().searchniautoinfo} />
			<p className="household-dashboard-date-header">Date</p>
			<E5SelectDate
				langinfo={E5Store.Ins().langinfo}
				disabled={searchniautoinfo.status.loading || searchniinfo.status.loading}
				enddate={E5Store.Ins().todayinfo.today} limitdate={undefined} nbdays={E5MainConfig.GetNbDaysHousehold()}
				curdate={E5StoreH.Ins().curdateinfo.curdate} updatefunc={this.ChangeDate} />

			{searchniinfo.status.message !== "" &&
				<div className="e5statusmessage">{searchniinfo.status.message}</div>}
			<div className="e5linefull" />
			{!dashboardHPage && <E5HStatus langinfo={E5Store.Ins().langinfo} searchniinfo={E5StoreH.Ins().searchniinfo} />}
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	ChangeDate = (dt: string): void => E5StoreH.Ins().SetCurDate(dt);// new date is YYYY-MM-DD

	//E5
	StartSearch = (networkid: string): void => {
		// search indic global
		E5RequestH.Ins().FetchIndicGlobal(networkid, (status) => {
			if (status.success) {
				// search system
				E5RequestH.Ins().FetchIndicSys(networkid, undefined);

				E5RequestH.Ins().FetchAnomSummary(networkid, undefined);
				E5RequestH.Ins().FetchIncidentAnomaly(networkid, undefined);
				E5RequestH.FetchIncidents(networkid,
					0, E5StoreH.Ins().curdateinfo.curdate, true, () =>
					E5StoreH.Ins().SetIncidents(false, null, null, null));
				// search connectivity
				if (E5MainConfig.GetWifiEnabled() && E5Path.IsAllowed(E5Page.H_CONNECTIVITY))
					E5RequestH.Ins().FetchConnectivity(networkid, undefined);
				// search metrics
				if (E5Path.IsAllowed(E5Page.H_METRICS)) {
					if (E5MainConfig.GetWifiEnabled())
						E5RequestH.Ins().FetchMetricWifi(networkid, undefined);
					E5RequestH.Ins().FetchIndicWifi(networkid, undefined);

					if (E5MainConfig.GetWanEnabled())
						E5RequestH.Ins().FetchIndicWan(networkid, undefined);

					if (E5MainConfig.GetSystemEnabled())
						E5RequestH.Ins().FetchMetricSys(networkid, undefined);
					E5RequestH.Ins().FetchMetricProcess(networkid, undefined);

				}
				E5RequestH.Ins().FetchActivityDevices(networkid, undefined, "week");
				E5RequestH.Ins().FetchActivityDevices(networkid, undefined, "day");
				E5RequestH.Ins().FetchEquipmentRdm();
				// search eventlogs
				if (E5Path.IsAllowed(E5Page.H_EVENTLOGS))
					E5RequestH.Ins().FetchEventLogs(networkid, undefined);
			}
		});
	};
});
