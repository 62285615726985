import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHKpiSysReb extends E5Entity {
	imei: string = "";
	reasonmap: Map<string, number>|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);

		let key: string;
		this.reasonmap = null;
		if (typeof json.reboot_reason_count === "object" && json.reboot_reason_count !== null) {
			this.reasonmap = new Map();
			for (key in json.reboot_reason_count) if (json.reboot_reason_count.hasOwnProperty(key))
				this.reasonmap.set(key, E5Entity.ParseInt(json.reboot_reason_count[key]));
		}
	}

	//E5
	CopyToJson(): any {
		let reboot_reason_count: any = null, key: string, val: number|null;
		if (this.reasonmap instanceof Map) for ([key, val] of this.reasonmap) {
			if (reboot_reason_count === null) reboot_reason_count = {};
			reboot_reason_count[key] = val;
		}

		return {node_imei: this.imei, reboot_reason_count};
	}
}
