import { E5UtilI18n } from '../../global/E5MainLang';
import { ReactComponent as Icon } from '../../assets/update.svg'
import './E5NotFoundPage.css'

export default function E5SystemUpdatePage() {
    return (
        <div className='wildcard-routes'>
            <div className='wildcard-page-container'>
                <div className="wildcard-page-text-container">
                    <span className='wildcard-page-title'>{E5UtilI18n._("maintenance-title")}</span>
                    <span className='wildcard-page-description mt-5'>{E5UtilI18n._("maintenance-desc")}</span>
                </div>

                <Icon className='wildcard-page-icon' />
            </div>
        </div>
    )
}
