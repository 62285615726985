import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIndSysTemp extends E5Entity {
	highlimit: number|null|undefined = undefined;
	lowlimit: number|null|undefined = undefined;
	imei: string = "";
	status: string = "";
	tempval: number|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.highlimit = E5Entity.ParseIntNull(json.high_threshold_limit);
		this.lowlimit = E5Entity.ParseIntNull(json.low_threshold_limit);
		this.imei = E5Entity.AssignString(json.node_imei);
		this.status = E5Entity.AssignString(json.status);
		this.tempval = E5Entity.ParseIntNull(json.temperature_value);
	}

	//E5
	CopyToJson(): any {
		return {
			high_threshold_limit: this.highlimit, low_threshold_limit: this.lowlimit, node_imei: this.imei,
			status: this.status, temperature_value: this.tempval
		};
	}
}
