import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5StoreAdmin } from "../../../store/E5StoreAdmin";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5AdminRdmList } from "./E5AdminRdmList";
import { E5Store } from "../../../store/E5Store";
//
import "./E5AdminRdm.css";

//E5
interface E5AdminRdmState { }

//E5
interface E5AdminRdmProps { }

//E5
export const E5AdminRdm = observer(class E5AdminRdm extends React.PureComponent<E5AdminRdmProps, E5AdminRdmState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { rdmstatusinfo,rdmttlinfo, rdmcmdinfo, rdmuiinfo, rdmrequestinfo, rdminfo } = E5StoreAdmin.Ins();
		let { langinfo } = E5Store.Ins();

		return <div className="e5page e5admin-rdm e5columnfull e5column-20">
			<div className="e5column-5">
				{/* <BP.Breadcrumbs items={[
					{icon: "bookmark", text: E5UtilI18n._("mainmenu-management")},
					{text: E5UtilI18n._("mainmenu-m-rdm")}
				]}/> */}
				<E5PageTitle titlekey="pagetitle-management-rdm" />
			</div>
			<div className="e5admin-rdm-content e5columnfull e5column-0">
				<E5AdminRdmList statusinfo={rdmstatusinfo}
					langinfo={langinfo} ttlinfo={rdmttlinfo}
					cmdinfo={rdmcmdinfo} uiinfo={rdmuiinfo}
					requestinfo={rdmrequestinfo} rdminfo={rdminfo} />
			</div>
		</div>;
	}
});
