
import React, { useMemo, useState } from "react";
import * as BP from "@blueprintjs/core";
import styles from './RemoveSelectedAclGroupsModal.module.css'
import { E5EntAclGroup } from "../../../entity/E5EntAclGroup";
import Button from "../../../global/component/Button";
type componentProps = {
    onClose: () => void;
    onDelete: (array: E5EntAclGroup[]) => void;
    groups: E5EntAclGroup[];
    aclIds: number[];
    aclNames: string[]
};

const RemoveSelectedAclGroupsModal = ({ onDelete, aclNames, groups, aclIds, onClose }: componentProps) => {

    const { groupsForRemoving } = useMemo(() => {
        const groupsForRemoving: E5EntAclGroup[] = []
        const usernames: string[] = [];
        aclNames && aclNames.length && aclNames.forEach((aclName => {
            const group = groups.filter((group) => group.name === aclName)
            groupsForRemoving.push(group[0]);
        }))
        return { groupsForRemoving };
    }, [groups]);

    return <BP.Dialog isOpen={true} canOutsideClickClose={false} canEscapeKeyClose={false} className="delete-acl-modal"
        title={'Delete the selected groups'}
        onClose={() => onClose()}>
        <div className={styles.usernameWrapper}>
            {(groupsForRemoving && groupsForRemoving.length) ? groupsForRemoving.map((group: any) => <p className={styles.userFullname}>{group?.name}</p>) : <div />}
        </div>

        <div className={styles.buttonsWrapper}>
            <Button theme="red" onClick={() => onDelete(groupsForRemoving)}>
                Delete
            </Button>
            <Button theme="blue" onClick={() => onClose()}>
                Close without deleting
            </Button>
        </div>
    </BP.Dialog>
}
export default RemoveSelectedAclGroupsModal;