import React from "react";
import moment from "moment";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5EntHIncidAny, E5StoreH, E5StoreHIncidents, E5StoreHIncidentSelIdx } from "../../../store/E5StoreH";
import { E5EntHIncidSysFlash } from "../../../entity/household/incid/E5EntHIncidSysFlash";
import { E5EntHIncidSysConf } from "../../../entity/household/incid/E5EntHIncidSysConf";
import { E5EntHIncidSysProc } from "../../../entity/household/incid/E5EntHIncidSysProc";
import { E5EntHIncidSysTemp } from "../../../entity/household/incid/E5EntHIncidSysTemp";
import { E5EntHIncidSysCpu } from "../../../entity/household/incid/E5EntHIncidSysCpu";
import { E5EntHIncidSysMem } from "../../../entity/household/incid/E5EntHIncidSysMem";
import { E5EntHIncidSysReb } from "../../../entity/household/incid/E5EntHIncidSysReb";
import { E5XYChart, E5XYNumData, E5XYSource } from "../../../global/plot/E5XYChart";
import { E5EntHIncidWifi } from "../../../entity/household/incid/E5EntHIncidWifi";
import { E5EntHIncidWan } from "../../../entity/household/incid/E5EntHIncidWan";
import { E5EntHIncidSys } from "../../../entity/household/incid/E5EntHIncidSys";
import { E5EntHStation } from "../../../entity/household/topo/E5EntHStation";
import { E5CBDashboard } from "../../customer_base/dashboard/E5CBDashboard";
import { E5HIncidSever } from "../../../entity/household/incid/E5EntHIncid";
import { E5EntHEquip } from "../../../entity/household/topo/E5EntHEquip";
import { E5RequestMeta } from "../../../request/E5RequestMeta";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5StoreLangInfo } from "../../../store/E5Store";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5BandEnum } from "../../../entity/E5Enums";
import { E5Text } from "../../../util/E5Text";
import { E5Time } from "../../../util/E5Time";
//
import "./E5HIncidentButton.css";

//E5
interface E5HIncidentButtonState {
	expanded: boolean;
	recomidx: number;
}

//E5
interface E5HIncidentButtonProps {
	langinfo: E5StoreLangInfo;
	incident: E5EntHIncidAny;
	indexinall: number;
	equipmap: Map<string, E5EntHEquip>;
	stationmap: Map<string, E5EntHStation>;
	incidentinfo: E5StoreHIncidents;
	selincidentinfo: E5StoreHIncidentSelIdx;
	showgraph?: boolean;
}

//E5
export const E5HIncidentButton = observer(class E5HIncidentButton extends React.PureComponent
	<E5HIncidentButtonProps, E5HIncidentButtonState> {

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HIncidentButtonProps, state: E5HIncidentButtonState) {
		super(props, state);
		this.state = { expanded: false, recomidx: 0 };
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { recomidx, expanded } = this.state;
		let { id, imeis, recoms, criticality, duration } = this.props.incident;
		let macs: string[] = this.props.incident instanceof E5EntHIncidWifi ? this.props.incident.macs : [];

		let idx: number, equipjsx: JSX.Element[] = [], imei: string, equip: E5EntHEquip | undefined, info: string;
		for (idx = 0; idx < imeis.length; idx++) {
			imei = imeis[idx];
			equip = this.props.equipmap.get(imei);
			info = equip !== undefined ?
				_("nodetype-" + equip.nodetype) + " " + imei + " (" + equip.model + ")" : imei;
			equipjsx.push(<div className="elem" key={"node" + idx}>{info}</div>);
		}

		let stationjsx: JSX.Element[] = [], macaddr: string, station: E5EntHStation | undefined;
		for (idx = 0; idx < macs.length; idx++) {
			macaddr = macs[idx];
			station = this.props.stationmap.get(macaddr);
			info = station !== undefined ? station.hostname + " " + macaddr + " (" + station.devicetype + ")" : macaddr;
			stationjsx.push(<div className="elem dev" key={"device" + idx}>{info}</div>);
		}

		let contextsjsx: JSX.Element[] = [];
		if (recomidx < recoms.length)
			for (idx = 0; idx < recoms[recomidx].contexts.length; idx++)
				contextsjsx.push(<div className="context" key={"context" + idx}>
					{_("meta-wifi-recom-context-code-" + recoms[recomidx].contexts[idx])}
				</div>);

		let bandval: string = this.props.incident instanceof E5EntHIncidWifi && [
			E5BandEnum.freq2ghz, E5BandEnum.freq5ghz, E5BandEnum.freq6ghz, E5EntHIncidWifi.bandmultiple
		].includes(this.props.incident.band)
			? _(this.props.incident.band) : _("bandunknown");
		let wantype: string = this.props.incident instanceof E5EntHIncidWan ? this.props.incident.wantype : "";
		let software: string = E5HIncidentButton.IsSysSubClassIncid(this.props.incident) ?
			(this.props.incident as E5EntHIncidSys).software : "";
		let model: string = E5HIncidentButton.IsSysSubClassIncid(this.props.incident) ?
			(this.props.incident as E5EntHIncidSys).model : "";
		let procname: string = this.props.incident instanceof E5EntHIncidSysProc ?
			this.props.incident.procname : "";
		let pid: string = this.props.incident instanceof E5EntHIncidSysProc ?
			this.props.incident.pid.toString() : "";
		let coredump: string = this.props.incident instanceof E5EntHIncidSysProc ?
			this.props.incident.coredump : "";
		let ubiname: string = this.props.incident instanceof E5EntHIncidSysFlash ?
			this.props.incident.ubiname : "";

		let expandjsx: JSX.Element = expanded ? <div className="expand">
			<BP.Text>{_("wifih-incident-desc-contexts")} :
				{contextsjsx.length > 0 ?
					<><br />{contextsjsx}</> : <i>&nbsp;{_("wifih-incident-desc-context-none")}</i>}
			</BP.Text>
			<BP.Divider />
			<div>
				{_("wifih-incident-desc-details")} :&nbsp;
				{this.props.incident instanceof E5EntHIncidWifi &&
					<div className="elem dev">{_("wifih-incident-desc-band")} : {bandval}</div>}
				{this.props.incident instanceof E5EntHIncidWan &&
					<div className="elem dev">{_("wifih-incident-desc-wantype")} : {wantype}</div>}
				{E5HIncidentButton.IsSysSubClassIncid(this.props.incident) && <>
					<div className="elem dev">{_("wifih-incident-desc-software")} : {software}</div>
					<div className="elem dev">{_("wifih-incident-desc-model")} : {model}</div>
					{this.props.incident instanceof E5EntHIncidSysProc &&
						<div className="elem dev">{_("wifih-incident-desc-procname")} : {procname}</div>}
					{this.props.incident instanceof E5EntHIncidSysProc && pid !== "" &&
						<div className="elem dev">{_("wifih-incident-desc-pid")} : {pid}</div>}
					{this.props.incident instanceof E5EntHIncidSysProc && coredump !== "" &&
						<div className="elem dev">{_("wifih-incident-desc-coredump")} : {coredump}</div>}
					{this.props.incident instanceof E5EntHIncidSysFlash &&
						<div className="elem dev">{_("wifih-incident-desc-ubiname")} : {ubiname}</div>}
				</>}
			</div>
			{equipjsx.length > 0 && <div>{_("wifih-incident-desc-eqp")} : {equipjsx}</div>}
			{stationjsx.length > 0 && <div>{_("wifih-incident-desc-sta")} : {stationjsx}</div>}
		</div> : <></>;

		let selectedclass = this.props.selincidentinfo.incidentindex === this.props.indexinall ? "selected" : "";

		// http://www.perbang.dk/rgbgradient/
		let grad: string[] = E5MainConfig.GetIncidColorSteps().map(val => E5CBDashboard.RgbToHex(val));
		// criticality = [10mn ; 300mn or +[
		let gradindex: number = Math.round(((criticality / 60) - 10) / 10);
		if (gradindex < 0) gradindex = 0;
		if (gradindex > grad.length - 1) gradindex = grad.length - 1;
		let critcolor: string = "#" + grad[gradindex], crittxtcolor: string = "#000000";
		if (gradindex > 6) crittxtcolor = "#FFFFFF";

		let critval: string = E5Text.Seconds2DHMS_str(criticality), durval: string = E5Text.Seconds2DHMS_str(duration);
		let sevval: string = E5Text.ScoreToPercent(criticality / duration) + " %";

		let incidentcateg: string = (id.startsWith(E5RequestMeta.h_inc_sys_nb) ?
			(" " + _("system")) : (id.startsWith(E5RequestMeta.h_inc_wan_nb) ? " WAN" :
				" Wi-Fi " + (this.props.incident instanceof E5EntHIncidWifi && this.props.incident.isbackhaul ?
					"Backhaul" : "Fronthaul")));

		let leftsource: E5XYSource = { numdatas: [{ xaxisdata: [], yaxisdata: [] }], options: { rangemode: "tozero", markers: true } };
		let selincidid: number = this.props.selincidentinfo.incidentindex, helpdetail: string | undefined;
		let incidents: E5EntHIncidAny[] | undefined =
			this.props.incidentinfo.incidentmap.get(this.props.incidentinfo.seldatestr);
		if (selincidid >= 0 && incidents !== undefined && selincidid < incidents.length) {
			let incident: E5EntHIncidAny = incidents[selincidid];
			let anomids: string[] | undefined = E5RequestMeta.Ins().h_anomalymap.get(incident.id);
			let idx: number, anomstr: string = "";
			if (anomids !== undefined) {
				for (idx = 0; idx < anomids.length; idx++) {
					if (idx > 0) anomstr += ", ";
					anomstr += anomids[idx] + " (" + _("wifih-metrics-name-s" + anomids[idx]) + ")";
				}
				helpdetail = _("wifih-incident-graphtitle-help2") + anomstr + ".";
			}

			for (idx = 0; idx < incident.severs.length; idx++) {
				let score: E5HIncidSever = incident.severs[idx];
				leftsource.numdatas?.[0].xaxisdata.push(score.time.format());
				leftsource.numdatas?.[0].yaxisdata.push(score.severity);
			}
		}

		let { incidentmap } = this.props.incidentinfo;
		let src: E5XYSource = { numdatas: undefined };
		src.numdatas = [];
		src.options = { stacked: false, bar: true, barstack: true, xtickvals: [] };
		if (incidentmap.size === 7) for (idx = 0; idx < 7; idx++) {
			let datestr: string = moment().subtract(1 + idx, "day").format("YYYY-MM-DD");
			src.options.xtickvals?.push(datestr);
		}
		let incmap: Map<string, E5XYNumData> = new Map();
		for (let [datestr, incids] of incidentmap) {
			let inccritmap: Map<string, number> = new Map();
			for (let incid of incids) {
				if (incid.id === "" || incid.id === id) {
					let crit: number | undefined = inccritmap.get(incid.id);
					if (crit === undefined) crit = 0;
					crit += incid.criticality;
					inccritmap.set(incid.id, crit);
				}
			}
			for (let [incidid, critical] of inccritmap) {
				let numdata: E5XYNumData | undefined = incmap.get(incidid);
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: "", text: [] };
					numdata.datalabel = incidid;
					numdata.fillcolor = E5MainConfig.GetIncidentColors()[incidid];
					numdata.hovertemplate = "%{text} (%{x})<extra>%{fullData.name}</extra>";
					if (incidid === "") numdata.hidelegend = true;
					incmap.set(incidid, numdata);
				}
				numdata.xaxisdata.push(datestr);
				numdata.yaxisdata.push(critical / 3600);
				numdata.text?.push(E5Text.Seconds2DHMS_str(critical));
			}
		}
		for (let [, numdata] of incmap) src.numdatas.push(numdata);

		return <div className="e5wifi-incident-button-outer">
			<div className={"e5wifi-incident-button clickable " + selectedclass} onClick={this.SelectIncident}>
				<div className="e5line-5">
					<div className="prehaul">{_("wifih-incident-tab-incident-one")}</div>
					<div className="haul">{incidentcateg}</div>
					<div className="haul" style={{ backgroundColor: critcolor, color: crittxtcolor }}>
						{_("meta-wifi-incident-code-" + id) !== undefined ?
							_("meta-wifi-incident-code-" + id) : id}
					</div>
				</div>
				<div className="e5line-5">
					<BP.Tooltip content={_(expanded ? "wifih-incident-close" : "wifih-incident-open")}>
						<BP.Button small={true} onClick={this.ToggleExpand} icon={expanded ? "minus" : "plus"} />
					</BP.Tooltip>
					<div className="incidentcolumn e5linefull">
						<div>{_("wifih-incident-desc-criticality")}</div>
						<div className="value">{critval}</div>
					</div>
					<div className="incidentcolumn e5linefull">
						<div>{_("wifih-incident-desc-duration")}</div>
						<div className="value">{durval}</div>
					</div>
					<div className="incidentcolumn e5linefull">
						<div>{_("wifih-incident-desc-severity")}</div>
						<div className="value">{sevval}</div>
					</div>
					<div className="incidentcolumn e5linefull">
						<div>{_("wifih-incident-desc-type")}</div>
						<div className="value">
							{_("meta-wifi-incidentcateg-code-" +
								id.substr(0, E5RequestMeta.Ins().h_incidentcateglen))}
						</div>
					</div>
					<div className="incidentcolumn e5linefull">
						<span>{_("wifih-incident-desc-recoms")}</span> :&nbsp;
						<span className="value">{recoms.length}</span><br />
						<span>{_("wifih-incident-desc-eqp")}</span> :&nbsp;
						<span className="value">{imeis.length}</span><br />
						{this.props.incident instanceof E5EntHIncidWifi && <>
							<span>{_("wifih-incident-desc-sta")}</span> :&nbsp;
							<span className="value">{macs.length}</span><br />
						</>}
					</div>
				</div>
				{recoms.length > 0 &&
					<div className="e5line-5 recom-line">
						<BP.Button icon="chevron-left" disabled={recomidx <= 0} small={true}
							onClick={this.SetPreviousRecom} />
						<BP.Button icon="chevron-right" disabled={recomidx >= recoms.length - 1} small={true}
							onClick={this.SetNextRecom} />
						<div className="recom-desc">{_("meta-wifi-recom-code-" + recoms[recomidx].id)}</div>
						&nbsp;({recomidx + 1}/{recoms.length})
					</div>}
				{expandjsx}
			</div>
			{this.props.showgraph && this.props.selincidentinfo.incidentindex === this.props.indexinall &&
				<div className="fixed2right e5column-10">
					<E5XYChart leftsource={src} rightsource={{}} title={_("wifih-incident-over-week")}
						loading={E5StoreH.Ins().incidentinfo.loading} height={200}
						subtitlebold={_("meta-wifi-incident-code-" + id)}
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} />
					<E5XYChart leftsource={leftsource} rightsource={{}} title={_("wifih-incident-graphtitle")}
						subtitlebelow={helpdetail} loading={E5StoreH.Ins().incidentinfo.loading} height={200}
						xoptions={{
							xisdaytime: true, xisday: false, xtimezone: E5Time.h_tzoffset,
							holesizesec: E5MainConfig.GetHoleSizeSec()
						}} helpcontent={<div className="e5wifih-incident-legend">
							{_("wifih-incident-graphtitle-help")}
						</div>} />
				</div>}
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	SetPreviousRecom = (event: React.MouseEvent) => {
		event.stopPropagation();
		if (this.state.recomidx > 0) this.setState({ recomidx: this.state.recomidx - 1 });
	};

	//E5
	SetNextRecom = (event: React.MouseEvent) => {
		event.stopPropagation();
		if (this.state.recomidx < this.props.incident.recoms.length - 1)
			this.setState({ recomidx: this.state.recomidx + 1 });
	};

	//E5
	SelectIncident = () =>
		E5StoreH.Ins().SetSelectedIncident(this.props.indexinall);

	//E5
	ToggleExpand = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.setState({ expanded: !this.state.expanded });
	};

	// ---------------- UTILS ----------------

	//E5
	static IsSysSubClassIncid: (incid: E5EntHIncidAny) => boolean = (incid: E5EntHIncidAny): boolean => [
		E5EntHIncidSysProc, E5EntHIncidSysMem, E5EntHIncidSysTemp, E5EntHIncidSysCpu, E5EntHIncidSysConf,
		E5EntHIncidSysFlash, E5EntHIncidSysReb
	].some(sysclass => incid instanceof sysclass);
});
