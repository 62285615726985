import moment, {Moment} from "moment";
//
import {E5Text} from "../../util/E5Text";
import {E5Time} from "../../util/E5Time";
import {E5Entity} from "../E5Entity";

//E5
export class E5EntHWanBytes extends E5Entity {
	downbyterate: number|undefined = undefined;
	upbyterate: number|undefined = undefined;
	time: Moment = moment();

	//E5
	static CompareWanBytes = (va: E5EntHWanBytes, vb: E5EntHWanBytes): number =>
		va.time.unix().toLocaleString().localeCompare(vb.time.unix().toLocaleString());

	//E5
	static BytesToMBytes = (val: number): number => Math.round(val / 10000) / 100;

	//E5
	static BytesToMbits = (val: number|undefined): number|undefined =>
		val !== undefined ? Math.round(val * 8 / 10000) / 100 : undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.downbyterate = E5Entity.ParseIntUndef(json.down_byte_rate);
		this.upbyterate = E5Entity.ParseIntUndef(json.up_byte_rate);
		let tm: number = E5Entity.ParseInt(json.time);
		this.time = E5Text.Tm2MomentTz(tm, E5Time.h_tzoffset);
	}

	//E5
	CopyToJson(): any {
		return {up_byte_rate: this.upbyterate, down_byte_rate: this.downbyterate, time: this.time.unix()};
	}
}
