import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5PieChart, E5PieChartClickCB} from "../../../global/plot/E5PieChart";
import {E5AngularGauge} from "../../../global/plot/E5AngularGauge";
import {E5RequestStatus} from "../../../request/E5ServiceCommon";
import {E5RequestMeta} from "../../../request/E5RequestMeta";
import {E5RequestCB} from "../../../request/E5RequestCB";
import {E5XYChart} from "../../../global/plot/E5XYChart";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5StoreCB} from "../../../store/E5StoreCB";
import {E5Store} from "../../../store/E5Store";
import {E5CBDashboard} from "./E5CBDashboard";
import {E5Text} from "../../../util/E5Text";
//
import "./E5CBWan.css";

//E5
interface E5CBWanState {
	downloadstr: string|undefined;
	nilistopen: boolean;
	incid: string;
}

//E5
interface E5CBWanProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
}

//E5
export const E5CBWan = observer(class E5CBWan extends React.PureComponent<E5CBWanProps, E5CBWanState> {

	// ---------------- MEMBERS ----------------

	static clarankmap: Map<string, string> = new Map();

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5CBWanProps, state: E5CBWanState) {
		super(props, state);
		this.state = {downloadstr: undefined, nilistopen: false, incid: ""};
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let {_} = E5UtilI18n, {wan} = E5StoreCB.Ins(), {nilist, nilistsettings} = E5StoreCB.Ins(),
			{status, ids} = nilist, {mode, downloadstr, date, cla} = nilistsettings;

		let [incids, incparents, incvalues, inclabels] = E5CBWan.GetIncidentPieData(),
			[accids, accparents, accvalues, acclabels] = E5CBWan.GetAccessPieData();

		return <div className="e5wificb-wan e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5AngularGauge gaugeinfo={
						{value: wan.health, label: "WAN", title: 'WAN Health score', loading: false}} isNewComponent chartOption={{type:'gauge'}}/>
				</div>
				<div className="e5linefull2">
					<div className="e5line-0">
						<BP.Popover className="e5linefull e5line-0" targetClassName="e5linefull"
									isOpen={mode === "health"} content={<div className="e5cb-dashboard-downloaddiv">
							<div className="title">{downloadstr}</div>
							{nilist.status.loading && <BP.Spinner className="e5spinwait" size={15}/>}
							{!nilist.status.loading && <BP.Button
								text={E5UtilI18n._("download")} intent={BP.Intent.PRIMARY} autoFocus
								onClick={() => E5CBDashboard.DownloadNiListHealth("wanHealth", date, cla,
									E5CBWan.clarankmap, this.props.toasterref)}
								onBlur={() => E5StoreCB.Ins().SetNiListSettings("none", "",
									undefined, undefined, undefined, undefined, undefined,
									undefined, undefined)} ref={this.props.downloadref}/>}
						</div>} position={BP.Position.BOTTOM_LEFT}>
							<E5XYChart leftsource={E5CBDashboard.GetScores(wan.healthmetmap, E5CBWan)}
									   rightsource={{}} title={_("cb-health-over-time")} loading={false}
									   xoptions={{xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0}}
									   clickcb={E5CBDashboard.HealthClick} height={350} withNewComponent chartOption={{ filled:true , newColorScheme: true }}/>
						</BP.Popover>
					</div>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">
					<div className="e5line-0">
						<BP.Popover
							className="e5linefull e5line-0" targetClassName="e5linefull"
							content={<div className="e5cb-dashboard-downloaddiv">
								<div className="title">{this.state.downloadstr}</div>
								{!status.loading && <BP.Button
									text={_("download")} onClick={this.DownloadNI} ref={this.props.downloadref}
									onBlur={() => this.setState({downloadstr: undefined})} autoFocus
									intent={BP.Intent.PRIMARY}/>}
								{status.loading && <BP.Spinner className="e5spinwait" size={15}/>}
							</div>} position={BP.Position.TOP_RIGHT} isOpen={this.state.downloadstr !== undefined}>
							<E5PieChart clickcb={this.PieClick} pieinfo={{
								ids: incids, parents: incparents, values: incvalues, labels: inclabels, loading: false,
								title: _("cb-wan-pie-incid"), valueisseconds: true, labelisincident: true
							}} withNewComponent/>
						</BP.Popover>
					</div>
				</div>
				<div className="e5linefull2">
					<E5PieChart pieinfo={{
						title: _("cb-wan-pie-access"), valueisseconds: true, labelisincident: false,
						ids: accids, parents: accparents, values: accvalues, labels: acclabels, loading: false
					}} withNewComponent/>
				</div>
			</div>
			<BP.Dialog icon="people" title={_("cb-dashboard-nilist")} onClose={this.CloseNiList}
					   isOpen={ids.length > 0 && this.state.nilistopen}>
				<BP.TextArea className="e5cb-dashboard-nilistarea" value={ids.join("\n")}/>
			</BP.Dialog>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	PieClick: E5PieChartClickCB = (id: string, label: string, parentlabel: string): void => {
		if (id.startsWith("I0")) {
			let title: string;
			[id] = id.split(";");
			if (id.length > E5RequestMeta.Ins().h_incidentcateglen) {
				// bug in plotly, sometimes the parentlabel is the parent id !
				[parentlabel] = parentlabel.split(";");
				if (parentlabel.startsWith("I0") && parentlabel.length === E5RequestMeta.Ins().h_incidentcateglen)
					parentlabel = E5UtilI18n._("meta-wifi-incidentcateg-code-" + parentlabel);
				id = id.substr(0, E5RequestMeta.Ins().h_incidentcateglen + 2);
				title = E5Text.Substitute(E5UtilI18n._("cb-dashboard-downloadni-incid"),
					["100", id, E5UtilI18n._("meta-wifi-incident-code-" + id), parentlabel]);
			} else title = E5Text.Substitute(E5UtilI18n._("wificb-dashboard-downloadni-cat"),
				["100", id, label]);
			this.setState({incid: id, downloadstr: title, nilistopen: true});
		}
	};

	//E5
	DownloadNI = (): void =>
		E5RequestCB.Ins().FetchNiList(E5StoreCB.Ins().filterinfo.populationid, this.state.incid,
			E5StoreCB.Ins().filterinfo.startdate, E5StoreCB.Ins().filterinfo.enddate,
			(status: E5RequestStatus) => {
				if (!status.success) this.props.toasterref.current?.show({
					message: E5UtilI18n._("cb-dashboard-downloaderr") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});

	//E5
	CloseNiList = (): void => {
		E5StoreCB.Ins().SetNiList({loading: false, success: false, message: ""}, []);
		this.setState({nilistopen: false});
	};

	// ---------------- UTILS ----------------

	//E5
	static GetIncidentPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let {wan} = E5StoreCB.Ins(), {_} = E5UtilI18n,
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (wan.incidcritmap === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (wan.incidcritmap !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [_("total")];
				let catset: Set<string> = new Set(), accset: Set<string> = new Set(), categ: string;
				for (let [key, criticality] of wan.incidcritmap) {
					let [access, incidcode] = key.split(";");
					if (!incidcode.startsWith("I02")) continue;
					categ = incidcode.substr(0, E5RequestMeta.Ins().h_incidentcateglen);
					if (!catset.has(categ + ";" + access)) {
						catset.add(categ + ";" + access);
						if (!accset.has(access)) {
							accset.add(access);
							ids.push(access);
							parents.push("total");
							values.push(0);
							labels.push(access);
						}
						ids.push(categ + ";" + access);
						parents.push(access);
						values.push(0);
						labels.push(_("meta-wifi-incidentcateg-code-" + categ));
					}
					ids.push(incidcode + ";" + categ + access);
					parents.push(categ + ";" + access);
					values.push(criticality);
					labels.push(incidcode);
				}
			}
			return [ids, parents, values, labels];
		};

	//E5
	static GetAccessPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let {wan} = E5StoreCB.Ins(),
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (wan.wanaccessmap === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (wan.wanaccessmap !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				for (let [access, conntime] of wan.wanaccessmap) {
					ids.push(access === "" ? "N/A" : access);
					parents.push("total");
					values.push(conntime);
					labels.push(access === "" ? "N/A" : access);
				}
			}
			return [ids, parents, values, labels];
		};
});
