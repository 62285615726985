import React from 'react';
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import * as BP from "@blueprintjs/core";
import "@blueprintjs/table/lib/css/table.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Route, RouteComponentProps, Switch } from "react-router";
//
import { E5Store, E5StoreLangInfo, E5StoreReportInfo, E5StoreUserInfo } from "../store/E5Store";
import { E5CBPopulations } from "../page/customer_base/population/E5CBPopulations";
import { E5TestPopulations } from "../page/test/customer_base/E5TestPopulations";
import { E5HConnectivity } from "../page/household/connectivity/E5HConnectivity";
import { E5HEventlog } from "../page/household/eventlog/E5HEventlog";
import { E5TestIncidsRecoms } from "../page/test/household/E5TestIncidsRecoms";
import { E5CBDashboard } from "../page/customer_base/dashboard/E5CBDashboard";
import { E5TestConnections } from "../page/test/household/E5TestConnections";
import { E5TestAngularGauge } from "../page/test/global/E5TestAngularGauge";
import { E5TestBulletGauge } from "../page/test/global/E5TestBulletGauge";
import { E5TestTopoItems } from "../page/test/household/E5TestTopoItems";
import { E5TestTopoGraph } from "../page/test/household/E5TestTopoGraph";
import { E5TestRadarChart } from "../page/test/global/E5TestRadarChart";
import { E5HDashboard } from "../page/household/dashboard/E5HDashboard";
import { E5CBReport } from "../page/customer_base/dashboard/E5CBReport";
import { E5TestHIndics } from "../page/test/household/E5TestHIndics";
import { E5TestHeatmap } from "../page/test/household/E5TestHeatmap";
import { E5HIncident } from "../page/household/incident/E5HIncident"
import { E5TestPieChart } from "../page/test/global/E5TestPieChart";
import { E5TestXYChart } from "../page/test/global/E5TestXYChart";
import { E5TestAdminUsers } from "../page/test/E5TestAdminUsers";
import { E5RequestAdminRdm } from "../request/E5RequestAdminRdm";
import { E5HMetric } from "../page/household/metric/E5HMetric";
import { E5AdminUsers } from "../page/admin/user/E5AdminUsers";
import { E5WelcomePage } from "../page/welcome/E5WelcomePage";
import { E5RequestStatus } from "../request/E5ServiceCommon";
import { E5AdminAcl } from "../page/admin/acl/E5AdminAcl";
import { E5AdminRdm } from "../page/admin/rdm/E5AdminRdm";
import { E5RequestMeta } from "../request/E5RequestMeta";
import { E5LoginPage } from "../page/login/E5LoginPage";
import E5HomePage from '../page/home/E5HomePage'
import E5NotFoundPage from '../page/home/E5NotFoundPage'
import E5SystemUpdatePage from '../page/home/E5SystemUpdatePage'
import E5HStationsPage from '../page/household/stations/E5HStationsPage'
import E5HEquipmentsPage from '../page/household/equipments/E5HEquipmentsPage'
import { E5RequestACL } from "../request/E5RequestACL";
import { E5StoreAdmin } from "../store/E5StoreAdmin";
import { E5StoreCBPop } from "../store/E5StoreCBPop";
import { E5Page, E5Path } from "./E5MainStatics";
import { E5EntUser } from "../entity/E5EntUser";
import { E5StoreCB } from "../store/E5StoreCB";
import { E5Storage } from "../util/E5Storage";
import { E5MainHeader } from "./E5MainHeader";
import { E5MainConfig } from "./E5MainConfig";
import { E5StoreH } from "../store/E5StoreH";
import { E5MainMenu } from "./E5MainMenu";
//
import "./E5MainFrameOverrides.css";
import "./E5MainFrame.css";
import E5MyProfilePage from '../page/profile/my_profile/E5MyProfilePage';

//E5
interface E5MainFrameState {
	loaderactive: boolean;
	dimmererror: string;
}

//E5
interface E5MainFrameProps {
	langinfo: E5StoreLangInfo;
	userinfo: E5StoreUserInfo;
	cbreportinfo: E5StoreReportInfo;
}

//E5
export const E5MainFrame = observer(class E5MainFrame extends React.PureComponent
	<RouteComponentProps<{}> & E5MainFrameProps, E5MainFrameState> {

	// ---------------- INIT ----------------

	//E5
	constructor(props: RouteComponentProps<{}> & E5MainFrameProps, state: E5MainFrameState) {
		super(props, state);
		this.state = { loaderactive: true, dimmererror: "" };
	}

	//E5
	componentDidMount(): void {
		// get all environment variables after mounting, to avoid a setState during render or before mounting
		this.GetEnv();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		return <div className="e5mainframe">
			{this.state.loaderactive && <BP.Overlay isOpen={true}>
				<div className="e5overlaycontent e5column-0">
					<div className="e5columnfull" />
					<div className="e5line-0">
						<div className="e5linefull" />
						<div className="e5overlaycontentinner">
							{this.state.dimmererror === "" && <BP.Spinner className={BP.Classes.ICON_LARGE} />}
							{this.state.dimmererror !== "" && <E5SystemUpdatePage />}
						</div>
						<div className="e5linefull" />
					</div>
					<div className="e5columnfull" />
				</div>
			</BP.Overlay>}
			{(!this.state.loaderactive && !this.props.userinfo.curuser.isconnected) &&
				<Route path={"/*"} render={(props) => <E5LoginPage
					{...props} userinfo={E5Store.Ins().userinfo} loginsuccess={this.GetMeta} />} />}
			{(!this.state.loaderactive && this.props.userinfo.curuser.isconnected &&
				this.props.cbreportinfo.inreportpage) && <Route path={"/*"} render={() => <E5CBReport />} />}
			{(!this.state.loaderactive && this.props.userinfo.curuser.isconnected &&
				!this.props.cbreportinfo.inreportpage) && <div className="e5column-2 e5fullheight">
					<Route path={"/*"} render={
						(props) => <E5MainHeader {...props} />} />
					<div className="mainpage e5line-0 e5columnfull">
						<Route path={"/*"} render={() => <E5MainMenu />} />
						<div className="e5maincontent e5column-5 e5linefull">
							<Switch>
								<Route exact path={E5Path.Get(E5Page.HOME)} render={() => <E5HomePage />} />
								<Route exact path={E5Path.Get(E5Page.WELCOME)} render={
									(props) => <E5WelcomePage {...props} />} />
								<Route exact path={E5Path.Get(E5Page.WELCOME_HOW)} render={
									(props) => <E5WelcomePage {...props} activePage='howitworks' />} />
								<Route exact path={E5Path.Get(E5Page.DEVELOPERS)} render={
									(props) => <E5WelcomePage {...props} activePage='developers' />} />
								<Route exact path={E5Path.Get(E5Page.SYSTEM_UPDATE)} render={() => <E5SystemUpdatePage />} />
								<Route exact path={E5Path.Get(E5Page.STATIONS)} render={() => <E5HStationsPage />} />
								<Route exact path={E5Path.Get(E5Page.EQUIPMENTS)} render={() => <E5HEquipmentsPage />} />
								<Route exact path={E5Path.Get(E5Page.PROFILE)} render={() => <E5MyProfilePage />} />
								<Route exact path={E5Path.Get(E5Page.CB_DASHBOARD)} render={() => <E5CBDashboard />} />
								<Route exact path={E5Path.Get(E5Page.CB_POPULATIONS)} render={() =>
									<E5CBPopulations popinfo={E5StoreCBPop.Ins().popinfo} />} />
								<Route exact path={E5Path.Get(E5Page.H_DASHBOARD)} render={(routeProps) =>
									<E5HDashboard indicglobalinfo={E5StoreH.Ins().indicglobalinfo}
										indicwifiinfo={E5StoreH.Ins().indicwifiinfo}
										stationinfo={E5StoreH.Ins().stationinfo}
										equipinfo={E5StoreH.Ins().equipinfo}
										{...routeProps} />} />
								<Route exact path={E5Path.Get(E5Page.H_INCIDENTS)} render={() =>
									<E5HIncident indicglobalinfo={E5StoreH.Ins().indicglobalinfo}
										incidentinfo={E5StoreH.Ins().incidentinfo}
										selincidentinfo={E5StoreH.Ins().selincidentinfo} />} />
								<Route exact path={E5Path.Get(E5Page.H_CONNECTIVITY)} render={() =>
									<E5HConnectivity selectedinfo={E5StoreH.Ins().selectedinfo} />} />
								<Route exact path={E5Path.Get(E5Page.H_EVENTLOGS)} render={() => <E5HEventlog location={this.props.location} />} />
								<Route exact path={E5Path.Get(E5Page.H_METRICS)} render={() => <E5HMetric location={this.props.location} />} />
								<Route exact path={E5Path.Get(E5Page.MANAGEMENT_USERS)} render={() =>
									<E5AdminUsers userinfo={E5StoreAdmin.Ins().userinfo} />} />
								{E5MainConfig.GetRdmEnabled() &&
									<Route exact path={E5Path.Get(E5Page.MANAGEMENT_RDM)} render={() => <E5AdminRdm />} />}
								<Route exact path={E5Path.Get(E5Page.MANAGEMENT_ACL)} render={() => <E5AdminAcl />} />
								<Route path={"/*"} render={() => <E5NotFoundPage />} />
								{/*{"TESTS"}*/}

								<Route exact path={E5Path.Get(E5Page.T_XY_CHART)} render={() => <E5TestXYChart />} />
								<Route exact path={E5Path.Get(E5Page.T_PIE_CHART)} render={() => <E5TestPieChart />} />
								<Route exact path={E5Path.Get(E5Page.T_RADAR_CHART)} render={() => <E5TestRadarChart />} />
								<Route exact path={E5Path.Get(E5Page.T_ANGULAR_GAUGE)}
									render={() => <E5TestAngularGauge />} />
								<Route exact path={E5Path.Get(E5Page.T_BULLET_GAUGE)} render={() => <E5TestBulletGauge />} />
								<Route exact path={E5Path.Get(E5Page.T_CB_POPULATION)} render={() => <E5TestPopulations />} />
								<Route exact path={E5Path.Get(E5Page.T_H_INDIC)} render={() => <E5TestHIndics />} />
								<Route exact path={E5Path.Get(E5Page.T_H_INCID_RECOM)}
									render={() => <E5TestIncidsRecoms />} />
								<Route exact path={E5Path.Get(E5Page.T_H_TOPO_ITEM)} render={() => <E5TestTopoItems />} />
								<Route exact path={E5Path.Get(E5Page.T_H_TOPO_GRAPH)}
									render={() => <E5TestTopoGraph selectedinfo={E5StoreH.Ins().selectedinfo} />} />
								<Route exact path={E5Path.Get(E5Page.T_H_CONNECTION)} render={() => <E5TestConnections />} />
								<Route exact path={E5Path.Get(E5Page.T_H_HEATMAP)} render={() => <E5TestHeatmap />} />
								<Route exact path={E5Path.Get(E5Page.T_ADMIN_USER)} render={() => <E5TestAdminUsers />} />
							</Switch>
						</div>
					</div>
				</div>}
		</div>;
	}

	// ---------------- UTILS ----------------

	//E5
	GetEnv(): void {
		E5RequestMeta.Ins().FetchConfig(() => {
			// change default date for wifih/cb
			let devdate: string = E5MainConfig.GetDevModeDate();
			if (E5MainConfig.GetDevMode() && devdate !== "") {
				E5Store.Ins().ChangeToday(devdate);
				E5StoreH.Ins().SetCurDate(devdate);
				let end: Moment = moment(devdate).subtract(1, "day"),
					start: Moment = moment(devdate).subtract(E5MainConfig.GetNbDaysCustomerBase(), "days");
				E5StoreCB.Ins().SetStartDate(start.format("YYYY-MM-DD"));
				E5StoreCB.Ins().SetEndDate(end.format("YYYY-MM-DD"));
			}

			E5Store.Ins().ChangeLang(E5MainConfig.GetDefaultLang());
			// check bearer
			let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
			if (bearer !== undefined && bearer !== "") {
				let user: E5EntUser = new E5EntUser();
				user.bearer = bearer;
				user.GetDataFromBearer();
				E5Store.Ins().ChangeUser(user, this.props.history);
				if (user.isconnected) this.GetMeta();
				else this.setState({ loaderactive: false });
			} else {
				// if no bearer, the login page will be displayed, remove dimmer and display all
				this.setState({ loaderactive: false });
			}
		}, () => {
			// cannot set localized language here !!
			this.setState({ dimmererror: "Configuration error" });
		}, () => {
			// cannot set localized language here !!
			this.setState({ dimmererror: "Configuration error,\ncannot load configuration file" });
		});
	}

	//E5
	GetMeta = (): void => {
		// show dimmer
		this.setState({ loaderactive: true }, () => {
			// fetch meta
			E5RequestMeta.Ins().FetchMeta((status: E5RequestStatus) => {
				if (status.success) {
					// remove dimmer and display all
					this.setState({ loaderactive: false });
					if (E5Store.Ins().userinfo.curuser.roles.includes("ROLE_ADMIN") || E5Store.Ins().userinfo.curuser.roles.includes("ROLE_MANAGER"))
						E5RequestACL.Ins().LoadGroups(undefined);
					E5RequestAdminRdm.Ins().LoadLists('en');
				} else {
					if (status.message !== "") this.setState({ dimmererror: status.message });
					else {
						// this is a bearer 401 error => session expired, remove dimmer and display all
						this.setState({ loaderactive: false });
					}
				}
			});
		});
	};
});
