import {E5EntHMetSys} from "./E5EntHMetSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHMetSysFlash extends E5EntHMetSys {
	name: string = "";
	totalerase: number|undefined = undefined;
	availerase: number|undefined = undefined;
	badpebcnt: number|undefined = undefined;
	maxerasecnt: number|undefined = undefined;
	volumestrue: number|undefined = undefined;
	volumesfalse: number|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.name = E5Entity.AssignString(json.name);
		this.totalerase = E5Entity.ParseIntUndef(json.total_erase_size);
		this.availerase = E5Entity.ParseIntUndef(json.available_erase_size);
		this.badpebcnt = E5Entity.ParseIntUndef(json.bad_peb_count);
		this.maxerasecnt = E5Entity.ParseIntUndef(json.max_erase_count);

		this.volumestrue = undefined;
		this.volumesfalse = undefined;
		if (typeof json.volumes === "object" && json.volumes !== null) {
			this.volumestrue = E5Entity.ParseIntUndef(json.volumes.true);
			this.volumesfalse = E5Entity.ParseIntUndef(json.volumes.false);
		}
	}

	//E5
	CopyToJson(): any {
		return {
			...super.CopyToJson(), name: this.name, total_erase_size: this.totalerase,
			available_erase_size: this.availerase, bad_peb_count: this.badpebcnt, max_erase_count: this.maxerasecnt,
			volumes: {true: this.volumestrue, false: this.volumesfalse}
		};
	}
}
