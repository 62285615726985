import React from 'react';
import moment from "moment";
import ReactDOM from 'react-dom';
import {HashRouter, Route} from "react-router-dom";
//
import {E5MainFrame} from "./global/E5MainFrame";
import {E5UtilI18n} from "./global/E5MainLang";
import {E5Store} from "./store/E5Store";
//
require("moment/locale/fr");

// load default locale for moment
moment.locale("fr");
// load localized strings
let langs: string[] = ["en", "fr"];
let messages: Map<string, any> = new Map();
let idx: number;
for (idx = 0; idx < langs.length; idx++) {
	let lang: string = langs[idx];
	let mesobj = require("./lang/" + lang + ".ts").messages;
	messages.set(lang, mesobj);
}
E5UtilI18n.Init(langs, messages);
// start application
ReactDOM.render(
	<HashRouter>
		<Route path={"/*"} render={(props) => <E5MainFrame
			{...props}
			langinfo={E5Store.Ins().langinfo}
			userinfo={E5Store.Ins().userinfo}
			cbreportinfo={E5Store.Ins().reportinfo}
		/>}/>
	</HashRouter>,
	document.getElementById("root") as HTMLElement
);
