import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5RadarChart} from "../../../global/plot/E5RadarChart";
import {E5Static} from "../../../global/E5MainStatics";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestRadarChart.css";

//E5
interface E5TestRadarChartState {
	values: number[];
	nbvalues: number;
	balloon: boolean;
	spline: boolean;
}

//E5
interface E5TestRadarChartProps {}

//E5
export const E5TestRadarChart = observer(class E5TestRadarChart extends React.PureComponent
	<E5TestRadarChartProps, E5TestRadarChartState> {

	// ---------------- MEMBERS ----------------

	labels: string[] = ["CPU", "Memory", "Temperature", "Flash", "Process", "Reboot"];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestRadarChartProps, state: E5TestRadarChartState) {
		super(props, state);
		this.state = {values: [], nbvalues: 6, balloon: false, spline: true};
	}

	//E5
	componentDidMount(): void {
		this.Refresh();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		let labels: string[] = this.labels.filter((val: string, idx: number) => idx < this.state.nbvalues);
		//
		let avg: number = this.state.values.reduce((sum: number, val: number) => sum + val, 0) / this.state.nbvalues;
		//
		let avgcolor: string =
			(avg < 0.2 ? E5Static.plotcolors.plotred : avg < 0.4 ? E5Static.plotcolors.plotorange :
				avg < 0.6 ? E5Static.plotcolors.plotyellow : avg < 0.8 ? E5Static.plotcolors.plotgreenlight :
					E5Static.plotcolors.plotgreendark) + "88";
		//
		return <div className="e5testpage e5test-radar-chart e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-radar-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Refresh">
					<BP.Button onClick={this.Refresh} icon="refresh"/>
				</BP.Callout>
				<BP.Callout title="Number of values">
					<div className="e5line-20">
						<BP.Button
							disabled={this.state.nbvalues < 4} text="-"
							onClick={() => this.UpdateNbValuesBy(-1)}/>
						<BP.Button
							disabled={this.state.nbvalues > this.labels.length - 1} text="+"
							onClick={() => this.UpdateNbValuesBy(+1)}/>
						<BP.H4>{this.state.nbvalues}</BP.H4>
					</div>
				</BP.Callout>
				<BP.Callout title="Balloon">
					<div className="e5line-10">
						<BP.Checkbox onClick={this.ToggleBalloon} checked={this.state.balloon}/>
						<div className="optiondesc">
							With this enabled, values are very close, so the area ends up looking like a balloon.
						</div>
					</div>
				</BP.Callout>
				<BP.Callout title="Spline">
					<BP.Checkbox onClick={this.ToggleSpline} checked={this.state.spline}/>
				</BP.Callout>
			</div>
			<div className="e5line-20">
				<E5RadarChart radarinfo={{
					title: "No radial axis & default grey", loading: false,
					labels: labels, values: this.state.values,
					hardedge: !this.state.spline, hideraxis: true
				}}/>
				<E5RadarChart radarinfo={{
					title: "Radial axis & default grey", loading: false,
					labels: labels, values: this.state.values,
					hardedge: !this.state.spline
				}}/>
				<E5RadarChart radarinfo={{
					title: "Radial axis & custom blue", loading: false,
					labels: labels, values: this.state.values,
					hardedge: !this.state.spline, fillcolor: "#0080FF"
				}}/>
				<E5RadarChart radarinfo={{
					title: "Radial axis & average color", loading: false,
					labels: labels, values: this.state.values,
					hardedge: !this.state.spline, fillcolor: avgcolor
				}}/>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Refresh = (): void => {
		let values: number[] = [], idx: number;
		if (this.state.balloon) {
			let ref: number = Math.random() * 0.9;
			for (idx = 0; idx < this.state.nbvalues; idx++)
				values.push(ref + Math.random() * 0.1);
		} else {
			for (idx = 0; idx < this.state.nbvalues; idx++)
				values.push(Math.random());
		}
		this.setState({values: values});
	};

	//E5
	UpdateNbValuesBy = (amount: number): void => {
		this.setState({
			nbvalues: amount < 0 && this.state.nbvalues < 4 ? 3
				: amount > 0 && this.state.nbvalues > this.labels.length - 1 ? this.labels.length
					: this.state.nbvalues + amount
		}, this.Refresh);
	};

	//E5
	ToggleBalloon = (): void => {
		this.setState({balloon: !this.state.balloon}, this.Refresh);
	};

	//E5
	ToggleSpline = (): void => {
		this.setState({spline: !this.state.spline});
	};
});
