import React, { useEffect, useMemo, useState } from 'react'
import { observer } from "mobx-react";
import { ReactComponent as HomeIcon } from '../../assets/home.svg'
import './E5HomePage.css'
import { E5StoreCBPop } from '../../store/E5StoreCBPop';
import { E5RequestCBPop } from '../../request/E5RequestCBPop';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { E5UtilI18n } from '../../global/E5MainLang';
import { E5StoreH } from '../../store/E5StoreH';
import { E5RequestH } from '../../request/E5RequestH';
import { E5Page, E5Path } from '../../global/E5MainStatics';
import { useHistory } from 'react-router';
import { E5StoreCB } from '../../store/E5StoreCB';
import { styled } from '@mui/material';
import * as BP from "@blueprintjs/core";

export const MenuItemStyled = styled(MenuItem)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  &.Mui-selected:hover{ 
    background-color: WhiteSmoke;
  }

  &:hover {
    background-color: WhiteSmoke;
  }
`;

type id = any;
const WAIT_INTERVAL = 1000
let timerID: NodeJS.Timeout;

const handleWithDelay = (calback: Function, e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timerID)
    timerID = setTimeout(() => {
        calback(e)
    }, WAIT_INTERVAL)
}

const E5HomePage = observer(() => {
    const history = useHistory();

    const [isPopulation, setIsPopulation] = useState(true)
    const [selectorValue, setSelectorValue] = useState("")
    const [searchResults, setSearchResults] = useState<id[]>([])
    const [population, setPopulation] = useState('');
    const [isLoading, setIsLoadingStatus] = useState(false)
    let { searchniautoinfo } = E5StoreH.Ins();
    const ids = Array.from(searchniautoinfo.ids);

    const { popinfo } = E5StoreCBPop.Ins();

    const handleChangeHouseholdId = (value: string) => {
        history.push(`${E5Path.Get(E5Page.H_DASHBOARD)}?householdId=${value}`);
    }

    useEffect(() => {
        if (searchResults.length === 1) {
            const householdFromSearch = searchResults[0];
            if (householdFromSearch.trim() === selectorValue.trim()) {
                handleChangeHouseholdId(householdFromSearch)
            }
        }
    }, [searchResults]);

    useEffect(() => {
        E5RequestCBPop.Ins().FetchPops(undefined)
    }, []);
    const listOfNetworksIds = useMemo(() => searchResults.map(
        (item) => <MenuItemStyled
            key={item}
            value={item}
            onClick={(event: any) => {
                handleChangeHouseholdId(item)
            }}>
            {item}
        </MenuItemStyled>), [searchResults, ids])
    const setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = (event.currentTarget as any).value;
        value.trim();
        setSelectorValue(value)
    }
    const onSearcNetworkIds = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value?.trim();
        if (value.length >= 4) {
            setSearchResults([])
            setIsLoadingStatus(true);
            E5RequestH.Ins().FetchNetworkIds(value, (value) => {
                setIsLoadingStatus(false);
                if (value.success === true) {
                    const ids = Array.from(searchniautoinfo.ids);
                    setSearchResults([...ids]);
                } else {
                    setSearchResults([])
                }
            }, 10, true);
        } else {
            setSearchResults([])
        }

    }

    const handleChange = (e: SelectChangeEvent) => {
        setPopulation(e.target.value)
        E5StoreCB.Ins().SetCurPopulation(e.target.value, '', '')
        history.push(E5Path.Get(E5Page.CB_DASHBOARD))
    }

    return <div className='home-page'>
        <div className='home-page-left-container'>
            <span className='home-page-title'>
                {E5UtilI18n._("home-welcome")}
            </span>
            <div className='switch-container'>
                <div className={isPopulation ? 'button active' : 'button'} onClick={() => setIsPopulation(true)}>Select one population</div>
                <div className={!isPopulation ? 'button active' : 'button'} onClick={() => setIsPopulation(false)}>Choose a household id</div>
            </div>
            {isPopulation ?
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, maxWidth: 360, paddingLeft: '20px' }}>
                    <img className="wificb-dashboard-search-svg" src='./img/welcome/search.svg' />
                    <InputLabel className='wificb-dashboard-search-label' id="population-label">{E5UtilI18n._("wificb-dashboard-selectpop")}</InputLabel>
                    <Select
                        labelId="population-label"
                        id="population-select"
                        value={population}
                        onChange={handleChange}
                        label="Age"

                    >
                        <MenuItem value="">
                            {E5UtilI18n._("wificb-dashboard-selectpop")}
                        </MenuItem>
                        {popinfo?.pops?.map(({ populationid, name }) =>
                            <MenuItem key={populationid} value={populationid}>{name}</MenuItem>
                        )}
                    </Select>
                </FormControl> :
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, maxWidth: 360, paddingLeft: '20px' }}>
                    <img className="wificb-dashboard-search-svg" src='./img/welcome/search.svg' />
                    <div className='wificb-dashboard-search-wrapper'>
                        <TextField className={'wificb-dashboard-search'} disabled={isLoading} size='small' value={selectorValue || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setValue(e); handleWithDelay(onSearcNetworkIds, e);
                            }}
                            variant="standard" label='Household Id' />
                        {isLoading && <BP.Spinner size={30} />}
                    </div>
                    <div className='wificb-dashboard-ids-list'>
                        {listOfNetworksIds}
                    </div>

                </FormControl>
            }
        </div>
        <HomeIcon className='home-page-icon' />
    </div>
})

export default E5HomePage;