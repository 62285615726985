import {E5EntMetaLangs} from "./E5EntMetaLangs";
import {E5Entity} from "../E5Entity";

//E5
export class E5EntMetaCtxTarget extends E5Entity {
	id: string = "";
	langs: E5EntMetaLangs = new E5EntMetaLangs();

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.id = E5Entity.AssignString(json.id);
		this.langs = new E5EntMetaLangs(json.languages);
	}

	//E5
	CopyToJson(): any {
		return {id: this.id, languages: this.langs.CopyToJson()};
	}
}
