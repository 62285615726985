import React from "react";
import { observer } from "mobx-react";
//
import { E5TextIndic, E5TextIndicUnitEnum } from "../../../global/component/E5TextIndic";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5StoreCB } from "../../../store/E5StoreCB";
import { E5Store } from "../../../store/E5Store";
//
import "./E5CBOverviewIndic.css";

//E5
interface E5CBOverviewIndicState { }

//E5
interface E5CBOverviewIndicProps { }

//E5
export const E5CBOverviewIndic = observer(class E5CBOverviewIndic extends React.PureComponent
	<E5CBOverviewIndicProps, E5CBOverviewIndicState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let udf: undefined = undefined, { _ } = E5UtilI18n, { equipmap, stationmap } = E5StoreCB.Ins().global;

		let nbgw: number | undefined = udf, nbext: number | undefined = udf;
		if (equipmap !== null && equipmap !== udf) {
			let eqpmapentries: [string, Map<string, number>][] = [...equipmap.entries()];
			if (eqpmapentries.length > 0) {
				let [, eqpmap] = eqpmapentries[equipmap.size - 1];
				nbgw = eqpmap.get("GW");
				nbext = eqpmap.get("EXT");
			}
		}

		let nbsta: number | undefined = udf;
		if (stationmap !== null && stationmap !== udf) {
			let stamapentries: [string, Map<string, number>][] = [...stationmap.entries()];
			if (stamapentries.length > 0) {
				let [, stamap] = stamapentries[stationmap.size - 1];
				nbsta = 0;
				for (let [, cnt] of stamap) nbsta += cnt;
			}
		}

		return <div className="e5compo e5wificb-overview-indic e5column-0">
			<div className="e5line-0 wrappable">
				<E5TextIndic value={nbgw} tooltip={_("wificb-indic-gw-tip")} unittype={E5TextIndicUnitEnum.count}
					imageurl="/img/v3/nodes_and_devices/gw-black.svg" label={_("wificb-indic-gw")} />
				{E5MainConfig.GetExtEnabled() && <E5TextIndic
					value={nbext} tooltip={_("wificb-indic-ext-tip")} unittype={E5TextIndicUnitEnum.count}
					imageurl="/img/v3/nodes_and_devices/ext-black.svg" label={_("wificb-indic-ext")} />}
				{E5MainConfig.GetWifiEnabled() && <E5TextIndic
					value={nbsta} imageurl="/img/v3/textindic/wifi.svg" unittype={E5TextIndicUnitEnum.count}
					label={_("wificb-indic-sta")} tooltip={_("wificb-indic-sta-tip")} />}
			</div>
		</div>;
	}
});
