import * as moment from "moment";

export class E5UtilI18n {

	// --------- ALL STATIC -------------

	static langs: string[];
	static langmap: Map<string, any>;
	static curlang: string;
	static curlangdict: any;

	//E5
	// dynamic require() does not work with complex expressions ! : messages must be loaded by caller
	static Init(langs: string[], langmaps: Map<string, any>): void {
		E5UtilI18n.langs = langs;
		E5UtilI18n.langmap = langmaps;
		E5UtilI18n.curlang = "";
		E5UtilI18n.curlangdict = {};
	}

	//E5
	static _(tag: string): string {
		let ret: string|undefined = undefined;
		ret = E5UtilI18n.curlangdict[tag];
		if (ret === undefined)
			ret = tag;
		return ret;
	}

	//E5
	static SetLanguage(lang: string): void {
		moment.locale(lang);
		E5UtilI18n.curlang = lang;
		E5UtilI18n.curlangdict = E5UtilI18n.langmap.get(E5UtilI18n.curlang);
	}

}

