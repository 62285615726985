import {E5EntHMetSys} from "./E5EntHMetSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHMetSysReb extends E5EntHMetSys {
	rebootcnt: number|undefined = undefined;
	reason: string|undefined = undefined;
	source: string|undefined = undefined;
	selfHealingSource: string|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.rebootcnt = E5Entity.ParseIntUndef(json.reboot_count);
		this.reason = E5Entity.AssignString(json.reboot_reason);
		this.source = E5Entity.AssignString(json.last_reboot_source);
		this.selfHealingSource = E5Entity.AssignString(json.last_selfhealing_source);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), reboot_count: this.rebootcnt};
	}
}
