import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
import Plot, {PlotParams} from "react-plotly.js";
//
import {E5BulletInfo, E5StoreBulletGaugeInfo} from "../../request/E5ServiceCommon";
//
import "./E5BulletGauge.css";

//E5
interface E5BulletGaugeState {}

//E5
interface E5BulletGaugeProps {
	gaugeinfo: E5StoreBulletGaugeInfo;
}

//E5
export const E5BulletGauge = observer(class E5BulletGauge extends React.PureComponent
	<E5BulletGaugeProps, E5BulletGaugeState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		let bulletsjsx: JSX.Element[] = [], idx: number, bullet: E5BulletInfo;
		for (idx = 0; idx < this.props.gaugeinfo.bullets.length; idx++) {
			bullet = this.props.gaugeinfo.bullets[idx];
			bulletsjsx.push(<div key={"bullet" + idx} className="e5column-0">
				<div className="e5line-0">
					<Plot {...this.GetGraphData(bullet, idx)}/>
					<div className="e5column-0 e5gauge-labelvalue">
						<div>{bullet.label}</div>
						<div className="e5gauge-value">
							{bullet.value === null ? "N/A" : bullet.unit === "celsius" ?
								Math.round(bullet.value * 100) / 100 + "°C" : bullet.unit === "percent" ?
									Math.round(bullet.value * 100) / 100 + "%" : bullet.value.toFixed(2)}
						</div>
						<div className="e5gauge-info">{bullet.info ?? ""}</div>
					</div>
				</div>
				{bullet.desc !== undefined && <div className="e5line-0">{bullet.desc}</div>}
			</div>);
		}

		return <div className="e5compo e5bullet-gauge e5columnfull e5column-5">
			<div className="e5compotitle">{this.props.gaugeinfo.title}
				{this.props.gaugeinfo.loading && <BP.Spinner className="e5spinwait" size={15}/>}
			</div>
			{!this.props.gaugeinfo.loading &&
			<div className="e5columnfull e5column-0">
				{bulletsjsx}
			</div>}
		</div>;
	}

	// ---------------- UTILS ----------------

	GetGraphData(bullet: E5BulletInfo, idx: number): PlotParams {
		let value: number = bullet.value ?? 0, max: number = bullet.max ?? 100;
		return {
			data: [
				{
					type: "indicator",
					mode: "gauge",
					value,
					gauge: {
						shape: "bullet",
						bar: {line: {width: 0}, thickness: 0.20, color: "#000000"},
						bgcolor: "white",
						bordercolor: "black",
						borderwidth: 1,
						axis: {
							range: [0, max],
							visible: false,
							showticklabels: false
						},
						steps: [{color: value > max ? "#FF800077" : "#0080FFAA", range: [0, max]}]
					}
				}
			],
			layout: {
				font: {family: "montserrat"},
				width: 195,
				height: 56.25,
				margin: {t: 10, r: 10, l: 10, b: 10},
				paper_bgcolor: "#00000000"
			},
			config: {displayModeBar: false}
		};
	}
});
