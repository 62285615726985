import moment, { Moment } from "moment";
//
import { E5UtilI18n } from "../global/E5MainLang";

export class E5Text {

	// -------------- String -------------------

	//E5
	static ReplaceAll(str: string, find: string, replace: string): string {
		return str.split(find).join(replace);
	}

	//E5
	static Substitute(str: string, vals: string[]): string {
		let idx: number;
		for (idx = 0; idx < vals.length; idx++)
			str = E5Text.ReplaceAll(str, "#" + idx, vals[idx]);
		return str;
	}

	// -------------- Format -------------------

	//E5
	// https://stackoverflow.com/questions/37096367/how-to-convert-seconds-to-minutes-and-hours-in-javascript
	static Seconds2DHMS(seconds: number): any {
		let days = Math.floor(seconds / (24 * 60 * 60));
		seconds -= days * (24 * 60 * 60);
		let hours = Math.floor(seconds / (60 * 60));
		seconds -= hours * (60 * 60);
		let minutes = Math.floor(seconds / (60));
		seconds -= minutes * (60);
		seconds = Math.round(seconds);
		return { days, hours, minutes, seconds };
	}

	//E5
	static Seconds2DHMS_str(seconds: number): string {
		let dhms: any = E5Text.Seconds2DHMS(seconds);
		let valstr: string = "";
		if (dhms.days > 0) valstr += dhms.days + "d ";
		if (dhms.days > 0 || dhms.hours > 0) valstr += dhms.hours + "h ";
		if (dhms.days > 0 || dhms.hours > 0 || dhms.minutes > 0) valstr += dhms.minutes + "mn ";
		if (dhms.days > 0 || dhms.hours > 0 || dhms.minutes > 0 || dhms.seconds > 0) valstr += dhms.seconds + "sec ";
		else valstr = "0sec";
		return valstr;
	}

	static Seconds2H(seconds: number): any {
		let hours: number = Math.floor(seconds / (60 * 60));
		let billions: number = Math.floor(hours / Math.pow(10, 9));
		hours -= billions * Math.pow(10, 9);
		let millions: number = Math.floor(hours / Math.pow(10, 6));
		hours -= millions * Math.pow(10, 6);
		let thousands: number = Math.floor(hours / Math.pow(10, 3));
		hours -= thousands * Math.pow(10, 3);
		return { billions, millions, thousands, hours };
	}

	//E5
	static Seconds2H_str(seconds: number): string {
		let obj: any = E5Text.Seconds2H(seconds), hstr: string = " " + E5UtilI18n._("hours"),
			ToDecimal = (val: number): string => (val / 1000).toFixed(1).slice(1);
		return obj.billions > 0 ? obj.billions + ToDecimal(obj.millions) + "G" + hstr :
			obj.millions > 0 ? obj.millions + ToDecimal(obj.thousands) + "M" + hstr :
				obj.thousands > 0 ? obj.thousands + ToDecimal(obj.hours) + "K" + hstr :
					obj.hours > 0 ? obj.hours + hstr : "0h";
	}

	static Seconds2Minutes(second: number): number {
		return Math.floor(second / 60);
	}

	//E5
	static ScoreToPercent(score: number): number {
		return Math.round(score * 1000) / 10;
	}

	static RoundUp(value: number): number {
		return Math.round((value + Number.EPSILON) * 100) / 100;
	}

	// -------------- Timezone -------------------

	//E5
	// https://www.gnu.org/software/libc/manual/html_node/TZ-Variable.html
	// https://developer.ibm.com/technologies/systems/articles/au-aix-posix
	// time zone database : https://www.iana.org/time-zones
	// posix = [TZ=] std offset dst [dstoffset],startday[/starttime],endday[/endtime] , ex: TZ=CST6CDT,M3.2.0/2:00:00,M11.1.0/2:00:00
	// start / end = Mm.w.d = day d of week w of month m
	// sagemcom : CET-1CEST,M3.5.0,M10.5.0/3
	// attention : offset must be reversed = nb hours to go back to utc
	// tm = time to be compared to start/end of dst
	static GetOffsetFromPosix(posix: string, tm: number): number {
		let offset: number = 0, idxc: number;
		let ch: string;
		if (posix === undefined)
			posix = "";
		if (posix.length > 2) {
			if (posix.charAt(2) === "=")
				posix = posix.substr(3);
		}
		if (posix.length > 1) {
			let withdst: boolean = false;
			let posixsplit: string[] = posix.split(",");
			// process part1 : std offset dst [dstoffset]
			let part1: string = posixsplit[0];
			let offstr: string = "";
			let offnum: number = 0;
			let dststr: string = "";
			let dstnum: number = 0;
			let isoff: boolean = true;
			for (idxc = 0; idxc < part1.length; idxc++) {
				ch = part1[idxc];
				if ((ch >= "0" && ch <= "9") || ch === "-") {
					if (isoff)
						offstr += ch;
					else
						dststr += ch;
				} else {
					if (offstr !== "")
						isoff = false;
				}
			}
			offnum = parseInt(offstr);
			if (isNaN(offnum))
				offnum = 0;
			offset = -offnum;//reversed
			// get dst offset if specified
			dstnum = parseInt(dststr);
			if (isNaN(dstnum))
				dstnum = 1;
			// process part 2/3 : start,end
			if (posixsplit.length > 2) {
				let start: string = posixsplit[1]
				let end: string = posixsplit[2]
				//Jn format
				// todo
				//n format
				// todo
				//Mm.w.d format
				let mm: Moment = moment.unix(tm);
				let startm: Moment | undefined = undefined;
				let endm: Moment | undefined = undefined;
				if (start.charAt(0) === "M")
					startm = E5Text.PosixStartEnd_M(start, mm);
				if (end.charAt(0) === "M")
					endm = E5Text.PosixStartEnd_M(end, mm);
				if (startm !== undefined && endm !== undefined)
					withdst = startm <= mm && mm < endm;
			}
			if (withdst)
				offset += dstnum;// not reversed
		}
		if (offset === -0)
			offset = 0; // this is a bug in jest, -0 is not equal to 0...
		return offset;
	}

	//E5
	// start = M3.2.0[/2:00:00]
	// curmm : reference moment with year and offset
	// return : moment corresponding to start, or undefined
	static PosixStartEnd_M(startend: string, curmm: Moment): Moment | undefined {
		let val: Moment | undefined = undefined;
		startend = startend.substr(1);
		let slash: number = startend.indexOf("/");
		if (slash > 0)
			startend = startend.substr(0, slash);
		if (startend.length > 0) {
			let startarr: string[] = startend.split(".");
			if (startarr.length === 3) {
				let reqmonth: number = parseInt(startarr[0]);//1-12
				let reqweeknb: number = parseInt(startarr[1]);//1=first week in which weekday appear, or last if = 5
				let reqweekday: number = parseInt(startarr[2]);//sunday:0, saturday:6
				val = moment(curmm);
				// respect order of set !
				val.month(reqmonth - 1);
				val.date(1);// day of month
				val.hour(0);
				val.minute(0);
				val.second(0);
				val.millisecond(0);
				let curdow: number, toadd: number;
				curdow = val.day();
				if (curdow !== reqweekday) {
					if (curdow < reqweekday)
						toadd = reqweekday - curdow;
					else
						toadd = 7 + reqweekday - curdow;
					val.add(toadd, "day");
				}
				if (reqweeknb > 1) {
					val.add(reqweeknb - 1, "week");
					if (val.month() === reqmonth) // passed the end of the month
						val.subtract(7, "day");
				}
			}
		}
		return val;
	}

	//E5
	// offset in hours
	static Tm2MomentTz(tm: number, offset: number): Moment {
		let mm: Moment = moment.unix(tm);
		mm.utcOffset(offset * 60);
		return mm;
	}

	//E5
	static Tm2MomentTzStr(tm: number, offset: string): Moment {
		let mm: Moment = moment.unix(tm);
		mm.utcOffset(offset);
		return mm;
	}


}
