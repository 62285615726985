import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5AngularGauge} from "../../../../global/plot/E5AngularGauge";
import {E5MainConfig} from "../../../../global/E5MainConfig";
import {E5StoreCBSys} from "../../../../store/E5StoreCBSys";
import {E5UtilI18n} from "../../../../global/E5MainLang";
import {E5StoreCB} from "../../../../store/E5StoreCB";
import {E5Store} from "../../../../store/E5Store";
import {E5CBDashboard} from "../E5CBDashboard";
//
import "./E5CBSysTemperature.css";

//E5
interface E5CBSysTemperatureState {}

//E5
interface E5CBSysTemperatureProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
	percent: boolean;
	togglefunc: () => void;
}

//E5
export const E5CBSysTemperature = observer(class E5CBSysTemperature extends React.PureComponent
	<E5CBSysTemperatureProps, E5CBSysTemperatureState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let {_} = E5UtilI18n, {status, health, equips, details} = E5StoreCBSys.Ins().temperature, {loading} = status,
			{nilistsettings} = E5StoreCB.Ins(), {mode, cla, eqptype, sofv, model} = nilistsettings, {
				RenderPiePopover, DownloadNiListEquip, RenderEquipsPie, PieClickEquip1,
				DownloadNiListDetail, RenderDetailsPie, PieClickDetail1, RenderChart
			} = E5CBDashboard;

		return <div className="e5cb-sys-temperature e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5AngularGauge
						gaugeinfo={{value: health, label: _("system-temp"), title: 'Temperature health score', loading}} isNewComponent chartOption={{type:'gauge'}}/>
				</div>
				<div className="e5linefull">
					<div className="e5line-0">
						{RenderPiePopover(mode === "equip1", this.props.downloadref,
							() => DownloadNiListEquip("temperature", eqptype, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderEquipsPie("temperature", equips, loading, PieClickEquip1))}
					</div>
				</div>
				<div className="e5linefull">
					<div className="e5line-0">
						{RenderPiePopover(mode === "detail1", this.props.downloadref, () =>
								DownloadNiListDetail("temperature", model, sofv, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderDetailsPie("temperature", details, loading, PieClickDetail1))}
					</div>
				</div>
			</div>
			{RenderChart("temperature", "gw", loading, this.props.percent, this.props.togglefunc,undefined,'bar')}
			{E5MainConfig.GetExtEnabled() && RenderChart(
				"temperature", "ext", loading, this.props.percent, this.props.togglefunc,undefined,'bar')}
		</div>;
	}
});
