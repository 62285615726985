import React from "react";
import * as BP from "@blueprintjs/core";
import { RouteComponentProps } from "react-router";
//
import { E5Store } from "../../store/E5Store";
import { E5Path } from "../E5MainStatics";
//
import "./E5LinkMenuItem.css";

interface E5LinkMenuItemProps {
	text: string
	page?: string
	disabled?: boolean;
	imageurl?: string
	classname?: string
	narrow?: boolean
	Icon?:any;
}

// E5
// <Link><BP.MenuItem> will give a warning (nested <a>)
// <BP.MenuItem href=""> will reload the page instead of changing the content
// Use this menu item in place of BP.MenuItem if you just need a menu targeting a route url
export class E5LinkMenuItem extends React.PureComponent<RouteComponentProps<{}> & E5LinkMenuItemProps, {}> {

	//E5
	render(): JSX.Element {
		let { imageurl, narrow, page, text, Icon } = this.props, disabled: boolean = this.props.disabled ?? false,
			classname: string = this.props.classname ?? "e5menuitem", tooltip: string = narrow ? text : "", 
			active: boolean = page !== undefined && this.props.location.pathname === E5Path.Get(page);
		if (narrow) classname += " narrowitem";
		if (active && !disabled) classname += " activeitem"
		let item: JSX.Element;
		if (imageurl === undefined)
			item = <div className={classname}>
				{!narrow && <BP.MenuItem
					text={text} onClick={this.Clicked} disabled={disabled}
					active={active} />}
			</div>;
		else {
			let imageclass = "e5menuimage";
			if (this.props.disabled) imageclass += " disabled";
			item = <div className={classname + " e5line-2"}>
				<BP.Tooltip content={tooltip} position={BP.Position.BOTTOM_RIGHT} boundary="window">
					{Icon ? <Icon className='e5menuicon' onClick={this.Clicked}/> :<img className={imageclass} src={imageurl} alt={text} onClick={this.Clicked} />}
				</BP.Tooltip>
				{!narrow && <BP.MenuItem
					id={page} text={text} onClick={this.Clicked} disabled={disabled}
					active={active} />}
			</div>;
		}
		return item;
	}

	//E5
	Clicked = (): void => {
		if (!this.props.disabled && this.props.page !== undefined)
			E5Store.Ins().ChangePage(this.props.page, this.props.history);
	};
}
