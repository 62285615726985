import React from "react";
import {observer} from "mobx-react";
import moment, {Moment} from "moment";
import * as BP from "@blueprintjs/core";
import Plot, {PlotParams} from "react-plotly.js";
import ReactECharts from 'echarts-for-react';

//
import {E5StoreHStations, E5StoreHConnectivity, E5StoreHSearchNi, E5StoreH} from "../../../store/E5StoreH";
import {E5EntHStationRssi} from "../../../entity/household/topo/E5EntHStationRssi";
import {E5StoreLangInfo} from "../../../store/E5Store";
import {E5UtilI18n} from "../../../global/E5MainLang";
//
import "./E5HHeatmap.css";

//E5
interface E5HHeatmapState {}

//E5
interface E5HHeatmapProps {
	langinfo: E5StoreLangInfo;
	connectivityinfo: E5StoreHConnectivity;
	stationinfo: E5StoreHStations;
	searchniinfo: E5StoreHSearchNi;
}

//E5
export const E5HHeatmap = observer(class E5HHeatmap extends React.PureComponent<E5HHeatmapProps, E5HHeatmapState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		//
		let rssis: (number|null)[] = [], hours: string[] = [], children: string[] = [];
		let colorscale: any[] = [
			// red
			[0.00000, "#ff0000"], [0.24999, "#ff0000"],
			// orange
			[0.25000, "#d9822b"], [0.49999, "#d9822b"],
			// gray
			[0.50000, "#5c7080"], [0.75000, "#5c7080"],
			// green
			[0.75001, "#15b371"], [1.00000, "#15b371"]
		];
		let data: any = {
			z: rssis,
			x: hours,
			y: children,
			zmin: -90,
			zmax: -50,
			showscale: false,
			colorscale: colorscale,
			hoverlabel: {font: {family: "montserrat"}},
			hovertemplate: "%{z}<extra>%{y} (%{x})</extra>",
			type: "heatmap"
		};
		let graphdata: PlotParams = {
			data: [data],
			config: {displayModeBar: false},
			layout: {
				font: {family: "montserrat"},
				width: 1200,
				height: this.props.stationinfo.stations.length * 40 + 50,
				annotations: [],
				margin: {t: 10, l: 140, b: 40},
				paper_bgcolor: "#00000000",
				plot_bgcolor: "#00000000"
			}
		};
		//
		let idx: number, idx2: number, idx3: number, statrssi: E5EntHStationRssi;
		for (idx = 0; idx < this.props.connectivityinfo.rssipersta.length; idx++) {
			statrssi = this.props.connectivityinfo.rssipersta[idx];
			let hostname: string = "";
			for (idx2 = 0; idx2 < this.props.stationinfo.stations.length; idx2++)
				if (this.props.stationinfo.stations[idx2].macaddress === statrssi.child) {
					hostname = this.props.stationinfo.stations[idx2].hostname;
					break;
				}
			for (idx2 = 0; idx2 < 28; idx2++) {
				children.push(hostname + statrssi.child);
				let hour: string = E5StoreH.Ins().curdateinfo.curdate;
				let hourmoment: Moment = moment(hour).startOf("d").add(-3, "h").add(idx2, "h");
				hour = hourmoment.format();
				hours.push(hour);
				let rssi: number|null = null;
				for (idx3 = 0; idx3 < statrssi.rssiperhour.length; idx3++)
					if (statrssi.rssiperhour[idx3].time.isSame(hourmoment)) {
						rssi = statrssi.rssiperhour[idx3].rssi;
						break;
					}
				rssis.push(rssi);
				if (graphdata.layout.annotations !== undefined)
					graphdata.layout.annotations.push({
						xref: "x",
						yref: "y",
						x: hour,
						y: hostname + statrssi.child,
						text: rssi === null ? "N/A" : "" + Math.round(rssi * 10) / 10,
						font: {size: 9},
						showarrow: false
					});
			}
		}

		const uniqueHours = Array.from(new Set(hours))
		const uniqueChildren = Array.from(new Set(children))

		const options = {
			tooltip: {
				position: 'top'
			},
			grid: {
				left: '10%',
				right: '5%',
				height: 'auto',
				width: '85%',
			},
			xAxis: {
				type: 'category',
				data: uniqueHours.map(time=>moment(time).format('HH:mm')),
				splitArea: {
				  show: true
				}
			},
			yAxis: {
				type: 'category',
				data: uniqueChildren,
				splitArea: {
				  show: true
				}
			},
			visualMap: {
				min: -80,
				max: -60,
				calculable: false,
				orient: 'horizontal',
				left: 'center',
				bottom: '0%',
				color: ["#15b371", "#5c7080","#d9822b","#ff0000"],
				itemWidth:0,
			},
			series: [{
				type: 'heatmap',
				label: {
					show: true
				},
				emphasis: {
					itemStyle: {
					  	shadowBlur: 10,
					}
				},
				data: hours.map((time,index)=>
				([
					uniqueHours.findIndex(value=>value===time),
					uniqueChildren.findIndex(value=>value===children[index]),
					rssis[index] ? Math.round((rssis?.[index] || 0) * 100) / 100 :  '-'
				]))
			}]
		}

		//
		return <div className="e5wifiheatmap">
			<div className="e5wifiheatmap-inner">
				{(this.props.searchniinfo.status.loading
					&& this.props.connectivityinfo.topologies.length === 0) &&
				<div><BP.Spinner size={15}/></div>
				}
				{this.props.connectivityinfo.topologies.length > 0 &&
				<>
				<ReactECharts option={options}/>
				<div className="e5line-10 legend">
					<div>{E5UtilI18n._("wifih-heatmap-avg-rssi")} :</div>
					<div className="e5line-5">
						<div className="legendrssi rssired"/>
						<div>{"< -80"}</div>
					</div>
					<div className="e5line-5">
						<div className="legendrssi rssiorange"/>
						<div>-80-70</div>
					</div>
					<div className="e5line-5">
						<div className="legendrssi rssigray"/>
						<div>-70-60</div>
					</div>
					<div className="e5line-5">
						<div className="legendrssi rssigreen"/>
						<div>{"> -60"}</div>
					</div>
				</div>
				</>
					
				// <Plot {...graphdata}/>
				}
			</div>
		</div>;
	}
});
