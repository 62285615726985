import moment, { Moment } from "moment";
import { action, makeObservable, observable } from "mobx";
//
import { E5EntHKpiSysProcStatus } from "../entity/household/metric/E5EntHKpiSysProcStatus";
import { E5EntHKpiSysProcMem } from "../entity/household/metric/E5EntHKpiSysProcMem";
import { E5EntHKpiSysProcCpu } from "../entity/household/metric/E5EntHKpiSysProcCpu";
import { E5EntHIncidSysFlash } from "../entity/household/incid/E5EntHIncidSysFlash";
import { E5EntHMetSysCpuMem } from "../entity/household/metric/E5EntHMetSysCpuMem";
import { E5EntHIndSysHealth } from "../entity/household/indic/E5EntHIndSysHealth";
import { E5EntHIncidSysConf } from "../entity/household/incid/E5EntHIncidSysConf";
import { E5EntHIncidSysProc } from "../entity/household/incid/E5EntHIncidSysProc";
import { E5EntHIncidSysTemp } from "../entity/household/incid/E5EntHIncidSysTemp";
import { E5EntHIndSysCpuMem } from "../entity/household/indic/E5EntHIndSysCpuMem";
import { E5EntHMetSysCrash } from "../entity/household/metric/E5EntHMetSysCrash";
import { E5EntHMetSysFlash } from "../entity/household/metric/E5EntHMetSysFlash";
import { E5EntHIndSysCrash } from "../entity/household/indic/E5EntHIndSysCrash";
import { E5EntHIndSysFlash } from "../entity/household/indic/E5EntHIndSysFlash";
import { E5EntHIncidSysCpu } from "../entity/household/incid/E5EntHIncidSysCpu";
import { E5EntHIncidSysMem } from "../entity/household/incid/E5EntHIncidSysMem";
import { E5EntHIncidSysReb } from "../entity/household/incid/E5EntHIncidSysReb";
import { E5EntHSummaryAnom } from "../entity/household/anom/E5EntHSummaryAnom";
import { E5EntHStationRssi } from "../entity/household/topo/E5EntHStationRssi";
import { E5EntHMetSysProc } from "../entity/household/metric/E5EntHMetSysProc";
import { E5EntHMetSysTemp } from "../entity/household/metric/E5EntHMetSysTemp";
import { E5EntHIndSysProc } from "../entity/household/indic/E5EntHIndSysProc";
import { E5EntHIndSysTemp } from "../entity/household/indic/E5EntHIndSysTemp";
import { E5EntHKpiSysReb } from "../entity/household/metric/E5EntHKpiSysReb";
import { E5EntHMetSysReb } from "../entity/household/metric/E5EntHMetSysReb";
import { E5EntHIndSysReb } from "../entity/household/indic/E5EntHIndSysReb";
import { E5EntHIncidWifi } from "../entity/household/incid/E5EntHIncidWifi";
import { E5EntHIncidWan } from "../entity/household/incid/E5EntHIncidWan";
import { E5EntHTopology } from "../entity/household/topo/E5EntHTopology";
import { E5EntHMetWifi } from "../entity/household/metric/E5EntHMetWifi";
import { E5EntHStation } from "../entity/household/topo/E5EntHStation";
import { E5EntHWanBytes } from "../entity/household/E5EntHWanBytes";
import { E5EntHWifiTime } from "../entity/household/E5EntHWifiTime";
import { E5EntHEquip } from "../entity/household/topo/E5EntHEquip";
import { E5EntHAnom } from "../entity/household/anom/E5EntHAnom";
import { E5EntWifiChanTime } from "../entity/E5EntWifiChanTime";
import { E5EntRdmCommand } from "../entity/rdm/E5EntRdmCommand";
import { E5RequestStatus } from "../request/E5ServiceCommon";
import { E5NetElementType } from "../entity/E5Enums";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5EntHEventLog } from "../entity/household/eventlog/E5EntHEventLog";
import { E5EntHActivityDevice } from "../entity/household/activity/E5EntHActivityDevices";
import { E5EntHEquipmentRdm } from "../entity/household/equipment/E5EntHEquipmentRdm";
import { E5EntHWanMetric } from "../entity/household/E5EntHWanMetric";

// --------- NETWORK IDS -------------

//E5
export interface E5StoreHSearchNi {
	status: E5RequestStatus;
	networkidtyped: string;
	networkid: string;
	timezone: string;
	timezonedelta: number;
	requesttime: Moment | undefined;
	datatime: Moment | undefined;
	curdate?: string | Moment | undefined;
}

//E5
export interface E5StoreHSearchNiAuto {
	status: E5RequestStatus;
	ids: string[];
}

//E5
export interface E5StoreHCurDate {
	curdate: string;//YYYY-MM-YY
}

// --------- HEALTH SCORES ----------

//E5
export interface E5HHealthScores {
	date: string
	sys: number | null;
	wan: number | null;
	wifi: number | null;
}
export type FingerprintResult = {
	childId: string,
	confidence: number,
	deviceInfo: string,
	deviceType: string,
	networkId: string,
	vendor: string,

}

export type FingerprintResultResponse = {
	network_id: string,
	device_info: string,
	confidence: number,
	device_type: string,
	vendor: string
	child_id: string;
}
// --------- INDIC GLOBAL -------------

//E5
export interface E5StoreHIndicGlobal {
	loading: boolean;
	healths: E5HHealthScores[];
	wifihealth: number | null | undefined;
	wanhealth: number | null | undefined;
	syshealth: number | null | undefined;
	globalhealth: number | null | undefined;
	unsureextstb: boolean;
	ext: number | undefined;
	stb: number | undefined;
	devices: number | undefined;
	samplingperiod: number | null | undefined;
	sendingperiod: number | null | undefined;
	fingerprintresult: FingerprintResult[]
}

//E5
export interface E5StoreHAnom {
	status: E5RequestStatus;
	anoms: E5EntHAnom[];
}

//E5
export interface E5StoreHEquips {
	loading: boolean;
	equips: E5EntHEquip[];
	equipmap: Map<string, E5EntHEquip>;
}

//E5
export interface E5StoreHStations {
	stations: E5EntHStation[];
	stationmap: Map<string, E5EntHStation>;
}

//E5
export interface E5StoreHTopoSelected {
	type: E5NetElementType;
	id: string;
	station: E5EntHStation;
	equip: E5EntHEquip;
}

// --------- INDIC WIFI -------------

//E5
export interface E5StoreHIndicWifi {
	status: E5RequestStatus;
	averagetimedev: number | undefined;
	totaltime: number | undefined;
	chantimes: E5EntWifiChanTime[];
	wifitimes: E5EntHWifiTime[];
}

// --------- INDIC WAN -------------

//E5
export interface E5StoreHIndicWan {
	status: E5RequestStatus;
	bytes: E5EntHWanBytes[];
	interfname: string;
	interftype: string;
	wanstatus: string;
	ipv4addrs: string[];
	ipv6addrs: string[];
	totalbytesreceived: number | undefined;
	totalbytessent: number | undefined;
	dns: E5EntHWanMetric[];
	ping: E5EntHWanMetric[];
}

// --------- INDIC SYS -------------

//E5
export interface E5StoreHIndicSys {
	status: E5RequestStatus;
	crash: E5EntHIndSysCrash[];
	flash: E5EntHIndSysFlash[];
	health: E5EntHIndSysHealth[];
	cpumem: E5EntHIndSysCpuMem[];
	process: E5EntHIndSysProc[];
	reboot: E5EntHIndSysReb[];
	temperature: E5EntHIndSysTemp[];
}

// --------- ANOM SUMMARY -------------

//E5
export interface E5StoreHAnomSummary {
	status: E5RequestStatus;
	anoms: E5EntHSummaryAnom[];
}

// --------- EQUIPS RDM -------------

//E5
export interface E5StoreHEquipsRdm {
	loading: boolean;
	statusmap: Map<string, Map<number, number>>;
	lastcmdsmap: Map<string, E5EntRdmCommand[]>;
}

export interface E5StoreHEquipmentRdm {
	loading: boolean;
	actions: E5EntHEquipmentRdm[];
}

// --------- INCIDENTS -------------

//E5
export type E5EntHIncidAny = E5EntHIncidWifi | E5EntHIncidWan | E5EntHIncidSysProc | E5EntHIncidSysMem | E5EntHIncidSysTemp |
	E5EntHIncidSysCpu | E5EntHIncidSysConf | E5EntHIncidSysFlash | E5EntHIncidSysReb;

//E5
export interface E5StoreHIncidents {
	loading: boolean;
	incidentmap: Map<string, E5EntHIncidAny[]>;
	messagemap: Map<string, string>;
	seldatestr: string;
}

//E5
export interface E5StoreHIncidentSelIdx {
	incidentindex: number;
}

// --------- CONNECTIVITY -------------

//E5
export interface E5StoreHConnectivity {
	loading: boolean;
	topologies: E5EntHTopology[];
	rssipersta: E5EntHStationRssi[];
}

export interface E5StoreHEventLog {
	loading: boolean;
	eventLogs: E5EntHEventLog[];
}

// --------- METRIC WIFI -------------

//E5
export interface E5StoreHMetWifi {
	loading: boolean;
	metrics: E5EntHMetWifi[];
}

// --------- METRIC SYS -------------

//E5
export interface E5StoreHMetSys {
	loading: boolean;
	rebootkpi: E5EntHKpiSysReb[];
	procmem: E5EntHKpiSysProcMem[];
	proccpu: E5EntHKpiSysProcCpu[];
	procstatus: E5EntHKpiSysProcStatus[];
	rebootmet: E5EntHMetSysReb[];
	cpumem: E5EntHMetSysCpuMem[];
	temperature: E5EntHMetSysTemp[];
	flash: E5EntHMetSysFlash[];
	crash: E5EntHMetSysCrash[];
	process: E5EntHMetSysProc[];
}

export interface E5StoreHMetProcess {
	loading: boolean;
	process: E5EntHMetSysProc[];
	reboot: E5EntHMetSysReb[];
}
// --------- STORE ITSELF -------------


// --------- NETWORK WEEKLY ACTIVITY -------------
//E5
export interface E5StoreHActivityDevices {
	loading: boolean;
	activityDetection: E5EntHActivityDevice[];
	deviceCountActive: E5EntHActivityDevice[];
	deviceCountAll: E5EntHActivityDevice[];
	mostActiveDevicesEthernet: E5EntHActivityDevice[];
	mostActiveDevicesWiFi: E5EntHActivityDevice[];
	mostConsumingDevices: E5EntHActivityDevice[];
}

//E5
export class E5StoreH {

	// --------- STATIC MEMBERS -------------

	//E5
	private static ins: E5StoreH;

	//E5
	static Ins(): E5StoreH {
		if (E5StoreH.ins === undefined)
			E5StoreH.ins = new E5StoreH();
		return E5StoreH.ins;
	}

	// --------- STATIC STORE MODIFICATION -------------

	// network ids

	//E5
	SetSearchNi(status: E5RequestStatus): void {
		E5StoreH.Ins().searchniinfo.status = status;
	}

	//E5
	SetSearchNiTyped(netid: string): void {
		E5StoreH.Ins().searchniinfo.networkidtyped = netid.replaceAll(" ", "");
	}

	//E5
	SetNi(networkid: string, requesttime: Moment | undefined, datatime: Moment | undefined, curdate?: string | Moment): void {
		E5StoreH.Ins().searchniinfo.networkid = networkid;
		E5StoreH.Ins().searchniinfo.requesttime = requesttime;
		E5StoreH.Ins().searchniinfo.datatime = datatime;
		E5StoreH.Ins().searchniinfo.curdate = curdate;

	}

	//E5
	SetSearchNiAuto(status: E5RequestStatus, ids: string[]): void {
		E5StoreH.Ins().searchniautoinfo.status = status;
		E5StoreH.Ins().searchniautoinfo.ids = ids;
	}

	//E5
	// dt = YYYY-MM-DD
	SetCurDate(dt: string): void {
		E5StoreH.Ins().curdateinfo.curdate = dt;
	}

	// indic global

	//E5
	SetIndicGlobal(loading: boolean, healths: E5HHealthScores[], wifihealth: number | null | undefined,
		wanhealth: number | null | undefined, syshealth: number | null | undefined, globalhealth: number | null | undefined,
		unsureextstb: boolean, ext: number | undefined, stb: number | undefined, devices: number | undefined,
		samplingperiod: number | null | undefined, sendingperiod: number | null | undefined, fingerprintresult: FingerprintResult[]): void {
		E5StoreH.Ins().indicglobalinfo.loading = loading;
		E5StoreH.Ins().indicglobalinfo.healths = healths;
		E5StoreH.Ins().indicglobalinfo.wifihealth = wifihealth;
		E5StoreH.Ins().indicglobalinfo.wanhealth = wanhealth;
		E5StoreH.Ins().indicglobalinfo.syshealth = syshealth;
		E5StoreH.Ins().indicglobalinfo.globalhealth = globalhealth;
		E5StoreH.Ins().indicglobalinfo.unsureextstb = unsureextstb;
		E5StoreH.Ins().indicglobalinfo.ext = ext;
		E5StoreH.Ins().indicglobalinfo.stb = stb;
		E5StoreH.Ins().indicglobalinfo.devices = devices;
		E5StoreH.Ins().indicglobalinfo.samplingperiod = samplingperiod;
		E5StoreH.Ins().indicglobalinfo.sendingperiod = sendingperiod;
		E5StoreH.Ins().indicglobalinfo.fingerprintresult = fingerprintresult;
	}

	//E5
	SetAnoms(status: E5RequestStatus, anoms: E5EntHAnom[]): void {
		E5StoreH.Ins().anominfo.status = status;
		E5StoreH.Ins().anominfo.anoms = anoms;
	}

	//E5
	SetTopoItems(equips: E5EntHEquip[], stations: E5EntHStation[], loading: boolean): void {
		E5StoreH.Ins().equipinfo.loading = loading;
		E5StoreH.Ins().equipinfo.equips = equips;
		E5StoreH.Ins().stationinfo.stations = stations;
		let equipmap: Map<string, E5EntHEquip> = new Map();
		let stationmap: Map<string, E5EntHStation> = new Map();
		let idx: number;
		for (idx = 0; idx < equips.length; idx++) {
			let equip: E5EntHEquip = equips[idx];
			equipmap.set(equip.imei, equip);
		}
		for (idx = 0; idx < stations.length; idx++) {
			let station: E5EntHStation = stations[idx];
			stationmap.set(station.macaddress, station);
		}
		E5StoreH.Ins().equipinfo.equipmap = equipmap;
		E5StoreH.Ins().stationinfo.stationmap = stationmap;
	}

	//E5
	ChangeSelectedElem(type: E5NetElementType, equip: E5EntHEquip, station: E5EntHStation): void {
		E5StoreH.Ins().selectedinfo.type = type;
		E5StoreH.Ins().selectedinfo.id = (type === E5NetElementType.device ? station.macaddress : equip.imei);
		E5StoreH.Ins().selectedinfo.station = station;
		E5StoreH.Ins().selectedinfo.equip = equip;
	}

	// indic wifi

	//E5
	SetIndicWifi(status: E5RequestStatus, averagetimedev: number | undefined,
		totaltime: number | undefined, chanconntimes: E5EntWifiChanTime[], wifitimes: E5EntHWifiTime[]): void {
		E5StoreH.Ins().indicwifiinfo.status = status;
		E5StoreH.Ins().indicwifiinfo.averagetimedev = averagetimedev;
		E5StoreH.Ins().indicwifiinfo.totaltime = totaltime;
		E5StoreH.Ins().indicwifiinfo.chantimes = chanconntimes;
		E5StoreH.Ins().indicwifiinfo.wifitimes = wifitimes;
	}

	// indic wan

	//E5
	SetWanStatus(status: E5RequestStatus): void {
		E5StoreH.Ins().indicwaninfo.status = status;
	}

	//E5
	SetIndicWan(status: E5RequestStatus, bytes: E5EntHWanBytes[], interfname: string,
		interftype: string, wanstatus: string, ipv4addrs: string[], ipv6addrs: string[],
		totalbytesreceived: number | undefined, totalbytessent: number | undefined, dns: E5EntHWanMetric[], ping: E5EntHWanMetric[]): void {
		E5StoreH.Ins().indicwaninfo.status = status;
		E5StoreH.Ins().indicwaninfo.bytes = bytes;
		E5StoreH.Ins().indicwaninfo.interfname = interfname;
		E5StoreH.Ins().indicwaninfo.interftype = interftype;
		E5StoreH.Ins().indicwaninfo.wanstatus = wanstatus;
		E5StoreH.Ins().indicwaninfo.ipv4addrs = ipv4addrs;
		E5StoreH.Ins().indicwaninfo.ipv6addrs = ipv6addrs;
		E5StoreH.Ins().indicwaninfo.totalbytesreceived = totalbytesreceived;
		E5StoreH.Ins().indicwaninfo.totalbytessent = totalbytessent;
		E5StoreH.Ins().indicwaninfo.dns = dns;
		E5StoreH.Ins().indicwaninfo.ping = ping;
	}

	// indic sys

	//E5
	SetIndicSys(status: E5RequestStatus, crash: E5EntHIndSysCrash[], flash: E5EntHIndSysFlash[],
		health: E5EntHIndSysHealth[], cpumem: E5EntHIndSysCpuMem[], process: E5EntHIndSysProc[],
		reboot: E5EntHIndSysReb[], temperature: E5EntHIndSysTemp[]): void {
		E5StoreH.Ins().indicsysinfo.status = status;
		E5StoreH.Ins().indicsysinfo.crash = crash;
		E5StoreH.Ins().indicsysinfo.flash = flash;
		E5StoreH.Ins().indicsysinfo.health = health;
		E5StoreH.Ins().indicsysinfo.cpumem = cpumem;
		E5StoreH.Ins().indicsysinfo.process = process;
		E5StoreH.Ins().indicsysinfo.reboot = reboot;
		E5StoreH.Ins().indicsysinfo.temperature = temperature;
	}

	// anom summary

	//E5
	SetAnomSummary(status: E5RequestStatus, anoms: E5EntHSummaryAnom[]): void {
		E5StoreH.Ins().anomsumminfo.status = status;
		E5StoreH.Ins().anomsumminfo.anoms = anoms;
	}

	// equips rdm

	//E5
	SetEquipsRdm(loading: boolean, statusmap?: Map<string, Map<number, number>>, lastcmdsmap?: Map<string,
		E5EntRdmCommand[]>): void {
		E5StoreH.Ins().equiprdminfo.loading = loading;
		if (statusmap !== undefined)
			E5StoreH.Ins().equiprdminfo.statusmap = statusmap;
		if (lastcmdsmap !== undefined)
			E5StoreH.Ins().equiprdminfo.lastcmdsmap = lastcmdsmap;
	}

	//E5
	SetEquipmentRdm(loading: boolean,actions: E5EntHEquipmentRdm[]): void {
		E5StoreH.Ins().equipmentRdmInfo.loading = loading;
		E5StoreH.Ins().equipmentRdmInfo.actions = actions;
	}

	// incidents

	//E5
	SetIncidents(loading: boolean | null, incidentmap: Map<string, E5EntHIncidAny[]> | null,
		messagemap: Map<string, string> | null, seldatestr: string | null): void {
		if (loading !== null) E5StoreH.Ins().incidentinfo.loading = loading;
		if (incidentmap !== null) E5StoreH.Ins().incidentinfo.incidentmap = incidentmap;
		if (messagemap !== null) E5StoreH.Ins().incidentinfo.messagemap = messagemap;
		if (seldatestr !== null) E5StoreH.Ins().incidentinfo.seldatestr = seldatestr;
	}

	//E5
	SetSelectedIncident(index: number): void {
		E5StoreH.Ins().selincidentinfo.incidentindex = index;
	}

	// connectivity

	//E5
	SetConnectivity(loading: boolean, rssipersta: E5EntHStationRssi[], topologies: E5EntHTopology[]): void {
		E5StoreH.Ins().connectivityinfo.loading = loading;
		E5StoreH.Ins().connectivityinfo.rssipersta = rssipersta;
		E5StoreH.Ins().connectivityinfo.topologies = topologies;
	}

	SetEventLogs(loading: boolean, eventLogs: E5EntHEventLog[]): void {
		E5StoreH.Ins().eventlogsinfo.loading = loading;
		E5StoreH.Ins().eventlogsinfo.eventLogs = eventLogs;
	}

	// metric wifi

	//E5
	SetMetricWifi(loading: boolean, metrics: E5EntHMetWifi[]): void {
		E5StoreH.Ins().metwifiinfo.loading = loading;
		E5StoreH.Ins().metwifiinfo.metrics = metrics;
	}

	// metric sys

	SetMetricProcess(loading: boolean, process: E5EntHMetSysProc[], rebootmet: E5EntHMetSysReb[]): void {
		E5StoreH.Ins().metprocessinfo.loading = loading;
		E5StoreH.Ins().metprocessinfo.process = process;
		E5StoreH.Ins().metprocessinfo.reboot = rebootmet;

	}
	//E5
	SetMetricSys(loading: boolean, rebootkpi: E5EntHKpiSysReb[], procmemusage: E5EntHKpiSysProcMem[],
		proccputime: E5EntHKpiSysProcCpu[], procstatus: E5EntHKpiSysProcStatus[], rebootmet: E5EntHMetSysReb[],
		cpumem: E5EntHMetSysCpuMem[], temperature: E5EntHMetSysTemp[], flash: E5EntHMetSysFlash[],
		crash: E5EntHMetSysCrash[], process: E5EntHMetSysProc[]): void {
		E5StoreH.Ins().metsysinfo.loading = loading;
		E5StoreH.Ins().metsysinfo.rebootkpi = rebootkpi;
		E5StoreH.Ins().metsysinfo.procmem = procmemusage;
		E5StoreH.Ins().metsysinfo.proccpu = proccputime;
		E5StoreH.Ins().metsysinfo.procstatus = procstatus;
		E5StoreH.Ins().metsysinfo.rebootmet = rebootmet;
		E5StoreH.Ins().metsysinfo.cpumem = cpumem;
		E5StoreH.Ins().metsysinfo.temperature = temperature;
		E5StoreH.Ins().metsysinfo.flash = flash;
		E5StoreH.Ins().metsysinfo.crash = crash;
		E5StoreH.Ins().metsysinfo.process = process;
	}

	// --------- NETWORK WEEKLY ACTIVITY -------------
	//E5
	SetActivityDevice(loading: boolean, activityDetection: E5EntHActivityDevice[],
		deviceCountActive: E5EntHActivityDevice[], deviceCountAll: E5EntHActivityDevice[],
		mostActiveDevicesEthernet: E5EntHActivityDevice[], mostActiveDevicesWiFi: E5EntHActivityDevice[],
		mostConsumingDevices: E5EntHActivityDevice[], timeframe: string): void {
		if (timeframe === "week") {
			E5StoreH.Ins().activityDeviceWeek.loading = loading;
			E5StoreH.Ins().activityDeviceWeek.activityDetection = activityDetection;
			E5StoreH.Ins().activityDeviceWeek.deviceCountActive = deviceCountActive;
			E5StoreH.Ins().activityDeviceWeek.deviceCountAll = deviceCountAll;
			E5StoreH.Ins().activityDeviceWeek.mostActiveDevicesEthernet = mostActiveDevicesEthernet;
			E5StoreH.Ins().activityDeviceWeek.mostActiveDevicesWiFi = mostActiveDevicesWiFi;
			E5StoreH.Ins().activityDeviceWeek.mostConsumingDevices = mostConsumingDevices;
		} else {
			E5StoreH.Ins().activityDeviceDay.loading = loading;
			E5StoreH.Ins().activityDeviceDay.activityDetection = activityDetection;
			E5StoreH.Ins().activityDeviceDay.deviceCountActive = deviceCountActive;
			E5StoreH.Ins().activityDeviceDay.deviceCountAll = deviceCountAll;
			E5StoreH.Ins().activityDeviceDay.mostActiveDevicesEthernet = mostActiveDevicesEthernet;
			E5StoreH.Ins().activityDeviceDay.mostActiveDevicesWiFi = mostActiveDevicesWiFi;
			E5StoreH.Ins().activityDeviceDay.mostConsumingDevices = mostConsumingDevices;
		}
	}

	// --------- INSTANCE -------------

	// network ids
	searchniinfo: E5StoreHSearchNi = {
		status: { loading: false, success: false, message: "" }, networkidtyped: "", networkid: "", timezone: "",
		timezonedelta: 0, requesttime: undefined, datatime: undefined, curdate: undefined
	};
	searchniautoinfo: E5StoreHSearchNiAuto = { status: { loading: false, success: false, message: "" }, ids: [] };
	curdateinfo: E5StoreHCurDate = { curdate: moment().format("YYYY-MM-DD") };
	// indic global
	indicglobalinfo: E5StoreHIndicGlobal = {
		loading: false, healths: [], wifihealth: undefined, wanhealth: undefined, syshealth: undefined,
		globalhealth: undefined, unsureextstb: false, ext: undefined, stb: undefined, devices: undefined,
		samplingperiod: undefined, sendingperiod: undefined, fingerprintresult: []
	};
	anominfo: E5StoreHAnom = { status: { loading: false, success: false, message: "" }, anoms: [] };
	stationinfo: E5StoreHStations = { stations: [], stationmap: new Map() };
	equipinfo: E5StoreHEquips = { loading: false, equips: [], equipmap: new Map() };
	// indic wifi
	indicwifiinfo: E5StoreHIndicWifi = {
		status: { loading: false, success: false, message: "" }, averagetimedev: undefined, totaltime: undefined,
		chantimes: [], wifitimes: []
	};
	// indic wan
	indicwaninfo: E5StoreHIndicWan = {
		status: { loading: false, success: false, message: "" }, bytes: [], interfname: "", interftype: "",
		wanstatus: "", ipv4addrs: [], ipv6addrs: [], totalbytesreceived: undefined, totalbytessent: undefined,
		dns: [], ping: []
	};
	// indic sys
	indicsysinfo: E5StoreHIndicSys = {
		status: { loading: false, success: false, message: "" }, crash: [], flash: [], health: [], cpumem: [],
		process: [], reboot: [], temperature: []
	};
	// anom summary
	anomsumminfo: E5StoreHAnomSummary = { status: { loading: false, success: false, message: "" }, anoms: [] };
	// equips rdm
	equiprdminfo: E5StoreHEquipsRdm = { loading: false, statusmap: new Map(), lastcmdsmap: new Map() };
	// equipment rdm
	equipmentRdmInfo: E5StoreHEquipmentRdm = { loading: false, actions: [] };
	// incidents
	incidentinfo: E5StoreHIncidents = { loading: false, incidentmap: new Map(), messagemap: new Map(), seldatestr: "" };
	selincidentinfo: E5StoreHIncidentSelIdx = { incidentindex: -1 };
	// connectivity
	selectedinfo: E5StoreHTopoSelected =
		{ type: E5NetElementType.none, id: "", station: new E5EntHStation(), equip: new E5EntHEquip() };
	connectivityinfo: E5StoreHConnectivity = { loading: false, rssipersta: [], topologies: [] };

	eventlogsinfo: E5StoreHEventLog = { loading: false, eventLogs: [] };
	// metric wifi
	metwifiinfo: E5StoreHMetWifi = { loading: false, metrics: [] };
	// metric sys
	metsysinfo: E5StoreHMetSys = {
		loading: false, rebootkpi: [], procmem: [], proccpu: [], procstatus: [], rebootmet: [], cpumem: [],
		temperature: [], flash: [], crash: [], process: []
	};
	// metric process
	metprocessinfo: E5StoreHMetProcess = {
		loading: false, process: [], reboot: []
	}
	// device weekly activity
	activityDeviceWeek: E5StoreHActivityDevices = {
		loading: false,
		activityDetection: [],
		deviceCountActive: [],
		deviceCountAll: [],
		mostActiveDevicesEthernet: [],
		mostActiveDevicesWiFi: [],
		mostConsumingDevices: []
	}
	// devices day activity
	activityDeviceDay: E5StoreHActivityDevices = {
		loading: false,
		activityDetection: [],
		deviceCountActive: [],
		deviceCountAll: [],
		mostActiveDevicesEthernet: [],
		mostActiveDevicesWiFi: [],
		mostConsumingDevices: []
	}

	//E5
	constructor() {
		makeObservable(this, {
			searchniinfo: observable, searchniautoinfo: observable, curdateinfo: observable,
			indicglobalinfo: observable, anominfo: observable, stationinfo: observable, equipinfo: observable,
			indicwifiinfo: observable, indicwaninfo: observable, indicsysinfo: observable, anomsumminfo: observable,
			equiprdminfo: observable, incidentinfo: observable, selincidentinfo: observable, selectedinfo: observable,
			connectivityinfo: observable, eventlogsinfo: observable, metwifiinfo: observable, metsysinfo: observable, metprocessinfo: observable,
			activityDeviceWeek: observable, activityDeviceDay: observable, equipmentRdmInfo: observable,
			SetSearchNi: action, SetSearchNiTyped: action, SetNi: action, SetSearchNiAuto: action, SetCurDate: action,
			SetIndicGlobal: action, SetAnoms: action, SetTopoItems: action, ChangeSelectedElem: action,
			SetIndicWifi: action, SetWanStatus: action, SetIndicWan: action, SetIndicSys: action, SetEquipmentRdm: action,
			SetAnomSummary: action, SetEquipsRdm: action, SetIncidents: action, SetSelectedIncident: action,
			SetConnectivity: action, SetEventLogs: action, SetMetricWifi: action, SetMetricSys: action, SetActivityDevice: action,
		});
	}

	//E5
	GetCurDateFormatted(): string {
		let mm: Moment = moment(this.curdateinfo.curdate);
		return mm.format(E5UtilI18n._("dateformat"));
	}
}
