import {E5RequestAdminRdm} from "./E5RequestAdminRdm";
import {E5RequestAdmin} from "./E5RequestAdmin";
import {E5RequestCBPop} from "./E5RequestCBPop";
import {E5RequestCBSys} from "./E5RequestCBSys";
import {E5RequestMeta} from "./E5RequestMeta";
import {E5EntUser} from "../entity/E5EntUser";
import {E5RequestACL} from "./E5RequestACL";
import {E5RequestCB} from "./E5RequestCB";
import {E5Store} from "../store/E5Store";
import {E5RequestH} from "./E5RequestH";

//E5
export class E5Request {

	// --------- STATIC -------------

	//E5
	private static ins: E5Request;

	//E5
	static Ins(): E5Request {
		if (E5Request.ins === undefined) E5Request.ins = new E5Request();
		return E5Request.ins;
	}

	//E5
	static GetJsonErrors(body: any): string {
		let err: string = "";
		if (Array.isArray(body.errors)) {
			let idx: number;
			for (idx = 0; idx < body.errors.length; idx++) {
				if (err.length > 0) err += ", ";
				err += body.errors[idx];
			}
		}
		if (body.error !== undefined && body.error.length > 0) {
			if (err.length > 0) err += ", ";
			err += body.error;
		}
		return err;
	}

	// --------- INSTANCE -------------

	//E5
	ClearAll(): void {
		E5RequestMeta.Ins().ClearAll();
		E5RequestCB.Ins().ClearAll();
		E5RequestCBSys.Ins().ClearAll();
		E5RequestCBPop.Ins().ClearAll();
		E5RequestH.Ins().ClearAll();
		E5RequestACL.Ins().ClearAll();
		E5RequestAdmin.Ins().ClearAll();
		E5RequestAdminRdm.Ins().ClearAll();
	}

	//E5
	SessionExpired() {
		let user: E5EntUser = new E5EntUser();
		user.isconnected = false;
		user.sessionexpired = true;
		this.ClearAll();
		E5Store.Ins().ChangeUser(user, undefined);
	}
}
