import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5StoreHStations, E5StoreHEquips, E5StoreHSearchNi, E5StoreHConnectivity } from "../../../store/E5StoreH";
import { E5EntHTopology, E5TopoConn } from "../../../entity/household/topo/E5EntHTopology";
import { E5EntHEquip, E5EquipInterf } from "../../../entity/household/topo/E5EntHEquip";
import { E5EntHStation } from "../../../entity/household/topo/E5EntHStation";
import { E5BandEnum, E5NodeTypeEnum } from "../../../entity/E5Enums";
import { E5StoreLangInfo } from "../../../store/E5Store";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Text } from "../../../util/E5Text";
//
import "./E5HInstability.css";

//E5
export interface E5HInstabilityBand {
	band: E5BandEnum;
	totalcount: number;
	totaltime: number;
}

//E5
export interface E5HInstabilityCountTime {
	count: number;
	time: number;
}

//E5
interface E5HInstabilityState {
	showtime: boolean;
	showcount: boolean;
	selparentmac_or_band: string;
	selchildkey: string;
}

//E5
interface E5HInstabilityProps {
	langinfo: E5StoreLangInfo;
	equipinfo: E5StoreHEquips;
	stationinfo: E5StoreHStations;
	connectivityinfo: E5StoreHConnectivity;
	searchniinfo: E5StoreHSearchNi;
}

//E5
export const E5HInstability = observer(class E5HInstability extends React.PureComponent
	<E5HInstabilityProps, E5HInstabilityState> {

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HInstabilityProps, state: E5HInstabilityState) {
		super(props, state);
		this.state = { showtime: true, showcount: true, selparentmac_or_band: "", selchildkey: "" };
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		//
		let idx: number, idx2: number;
		// build node map and unique band list
		let bands: E5HInstabilityBand[] = [], node: E5EntHEquip, itf: E5EquipInterf, uniqb: E5HInstabilityBand;
		let bandmap: Map<E5BandEnum, E5HInstabilityBand> = new Map(), equipmap: Map<string, E5EntHEquip> = new Map();
		// for (idx = 0; idx < this.props.equipinfo.equips.length; idx++) {
		// 	node = this.props.equipinfo.equips[idx];
		// 	for (idx2 = 0; idx2 < node.interfaces.length; idx2++) {
		// 		itf = node.interfaces[idx2];
		// 		equipmap.set(itf.macaddress, node);
		// 		if (bandmap.get(itf.band) === undefined) {
		// 			uniqb = { band: itf.band, totalcount: 0, totaltime: 0 };
		// 			bandmap.set(itf.band, uniqb);
		// 			bands.push(uniqb);
		// 		}
		// 	}
		// }
		// build values map
		let valmap: Map<string, E5HInstabilityCountTime> = new Map(), topo: E5EntHTopology, conn: E5TopoConn;
		for (idx = 0; idx < this.props.connectivityinfo.topologies.length; idx++) {
			topo = this.props.connectivityinfo.topologies[idx];
			for (idx2 = 0; idx2 < topo.connectivities.length; idx2++) {
				conn = topo.connectivities[idx2];
				valmap.set(topo.child + ":" + conn.interfmacaddr, { count: conn.conncount, time: conn.conntime });
			}
		}
		//
		let nodelistjsx: JSX.Element[] = [], bandlistjsx: JSX.Element[] = [], linesjsx: JSX.Element[] = [];
		let bandlist: E5EquipInterf[] = [];
		// gateway
		for (idx = 0; idx < this.props.equipinfo.equips.length; idx++) {
			node = this.props.equipinfo.equips[idx];
			if (node.nodetype === E5NodeTypeEnum.gw) {
				// node
				nodelistjsx.push(
					<td key={"headbox" + idx} className="header node box" colSpan={node.interfaces.length}>
						{E5UtilI18n._("nodetype-" + node.nodetype)} : {node.imei}
					</td>
				);
				// bands
				for (idx2 = 0; idx2 < node.interfaces.length; idx2++) {
					itf = node.interfaces[idx2];
					bandlistjsx.push(
						<td key={"headbandbox" + idx * 1000 + idx2} className="header band">
							<BP.Tooltip content={itf.macaddress}>
								{E5UtilI18n._(itf.band)}
							</BP.Tooltip>
						</td>
					);
					bandlist.push(itf);
				}
				break;
			}
		}
		// extenders at top
		for (idx = 0; idx < this.props.equipinfo.equips.length; idx++) {
			node = this.props.equipinfo.equips[idx];
			if (node.nodetype !== E5NodeTypeEnum.gw) {
				// node
				nodelistjsx.push(
					<td key={"headnode" + idx} className="header node" colSpan={node.interfaces.length}>
						{E5UtilI18n._("nodetype-" + node.nodetype)} : {node.imei}
					</td>
				);
				// bands
				for (idx2 = 0; idx2 < node.interfaces.length; idx2++) {
					itf = node.interfaces[idx2];
					bandlistjsx.push(
						<td key={"headnodebox" + (idx * 1000 + idx2)} className="header band">
							<BP.Tooltip content={itf.macaddress}>
								{E5UtilI18n._(itf.band)}
							</BP.Tooltip>
						</td>
					);
					bandlist.push(itf);
				}
			}
		}
		// total header
		// nodelistjsx.push(
		// 	<td key={"headnodetotal"} className="header node total" colSpan={bands.length}>
		// 		{E5UtilI18n._("total")}
		// 	</td>
		// );
		// bands
		for (idx2 = 0; idx2 < bands.length; idx2++)
			bandlistjsx.push(
				<td key={"headnodetotal" + (idx * 1000 + idx2)} className="header band total">
					{E5UtilI18n._(bands[idx2].band)}
				</td>
			);
		// extenders at left
		for (idx = 0; idx < this.props.equipinfo.equips.length; idx++) {
			node = this.props.equipinfo.equips[idx];
			if (node.nodetype !== E5NodeTypeEnum.gw) {
				let vals: JSX.Element[] = [];
				// init total
				for (idx2 = 0; idx2 < bands.length; idx2++) {
					bands[idx2].totalcount = 0;
					bands[idx2].totaltime = 0;
				}
				// values
				for (idx2 = 0; idx2 < bandlist.length; idx2++) {
					itf = bandlist[idx2];
					let parentname: string = "";
					let parentnode: E5EntHEquip | undefined = equipmap.get(itf.macaddress);
					if (parentnode !== undefined)
						parentname = E5UtilI18n._("nodetype-" + parentnode.nodetype) + " " + parentnode.imei;
					let val: E5HInstabilityCountTime | undefined = valmap.get(node.imei + ":" + itf.macaddress);
					let valjsx: JSX.Element[] = [];
					let cl: string = "";
					if (val !== undefined) {
						if (this.state.showcount)
							valjsx.push(
								<BP.Tag key={"cnt"} minimal={true} round={true}>
									{val.count} x
								</BP.Tag>
							);
						if (this.state.showtime)
							valjsx.push(
								<BP.Tag key={"tm"} minimal={true} round={true}>
									{E5Text.Seconds2DHMS_str(val.time)}
								</BP.Tag>
							);
						let avgtime: number = val.time / val.count;
						if (avgtime < 600)
							cl += " red";
						else if (avgtime >= 600 && avgtime <= 3600)
							cl += " orange";
						else if (avgtime > 3600)
							cl += " green";
						cl += " hasdata";
						// compute total
						let uniqb: E5HInstabilityBand | undefined = bandmap.get(itf.band);
						if (uniqb !== undefined) {
							uniqb.totalcount += val.count;
							uniqb.totaltime += val.time;
						}
					}
					let tip: JSX.Element = <div>
						{E5UtilI18n._("wifih-instability-parentname")} : {parentname}&nbsp;{itf.band} ({itf.macaddress})<br />
						{E5UtilI18n._("wifih-instability-childname")} : {E5UtilI18n._("nodetype-" + node.nodetype)} {node.imei}
					</div>;
					if (this.state.selchildkey === node.imei || this.state.selparentmac_or_band === itf.macaddress)
						cl += " selected";
					//title={parentname + " " + inter.band + " " + inter.macaddress + " / " + node.imei}
					vals.push(
						<td
							id={node.imei + "|" + itf.macaddress}
							key={"nodevalue" + (idx * 1000 + idx2)}
							className={"value" + cl}
							onClick={this.ValClicked}
						>
							<BP.Tooltip content={tip} position={BP.Position.BOTTOM}>
								<div>{valjsx}</div>
							</BP.Tooltip>
						</td>
					);
				}
				// total
				for (idx2 = 0; idx2 < bands.length; idx2++) {
					uniqb = bands[idx2];
					let valjsx: JSX.Element[] = [];
					let cl: string = "";
					let hasdata: boolean = false;
					if (this.state.showcount && uniqb.totalcount > 0) {
						valjsx.push(
							<BP.Tag key={"cnt"} minimal={true} round={true}>
								{uniqb.totalcount} x
							</BP.Tag>
						);
						hasdata = true;
					}
					if (this.state.showtime && uniqb.totaltime > 0) {
						valjsx.push(
							<BP.Tag key={"tm"} minimal={true} round={true}>
								{E5Text.Seconds2DHMS_str(uniqb.totaltime)}
							</BP.Tag>
						);
						hasdata = true;
					}
					let avgtime: number = uniqb.totaltime / uniqb.totalcount;
					if (avgtime < 600)
						cl += " red";
					else if (avgtime >= 600 && avgtime <= 3600)
						cl += " orange";
					else if (avgtime > 3600)
						cl += " green";
					if (hasdata)
						cl += " hasdata";
					let tip: JSX.Element = <div>
						{E5UtilI18n._("wifih-health-desc-band")} : {uniqb.band}<br />
						{E5UtilI18n._("wifih-instability-childname")} : {E5UtilI18n._("nodetype-" + node.nodetype)} {node.imei}
					</div>;
					if (this.state.selchildkey === node.imei || this.state.selparentmac_or_band === uniqb.band)
						cl += " selected";
					//title={uniqb.band + " / " + node.imei}
					vals.push(
						<td
							id={node.imei + "|" + uniqb.band}
							key={"nodevaluetot" + (idx * 1000 + idx2)}
							className={"value total" + cl}
							onClick={this.ValClicked}
						>
							<BP.Tooltip content={tip} position={BP.Position.BOTTOM}>
								<div>
									{valjsx}
								</div>
							</BP.Tooltip>
						</td>
					);
				}
				// network elements
				linesjsx.push(
					<tr key={"nodeline" + idx}>
						<td className="netelem">
							{E5UtilI18n._("nodetype-" + node.nodetype)} : {node.imei}
						</td>
						{vals}
					</tr>
				);
			}
		}
		// devices
		for (idx = 0; idx < this.props.stationinfo.stations.length; idx++) {
			let dev: E5EntHStation = this.props.stationinfo.stations[idx];
			let vals: JSX.Element[] = [];
			// init total
			for (idx2 = 0; idx2 < bands.length; idx2++) {
				bands[idx2].totalcount = 0;
				bands[idx2].totaltime = 0;
			}
			// values
			for (idx2 = 0; idx2 < bandlist.length; idx2++) {
				itf = bandlist[idx2];
				let parentname: string = "";
				let parentnode: E5EntHEquip | undefined = equipmap.get(itf.macaddress);
				if (parentnode !== undefined)
					parentname = E5UtilI18n._("nodetype-" + parentnode.nodetype) + " " + parentnode.imei;
				let val: E5HInstabilityCountTime | undefined = valmap.get(dev.macaddress + ":" + itf.macaddress);
				let valjsx: JSX.Element[] = [];
				let cl: string = "";
				if (val !== undefined) {
					if (this.state.showcount)
						valjsx.push(
							<BP.Tag key={"cnt"} minimal={true} round={true}>
								{val.count} x
							</BP.Tag>
						);
					if (this.state.showtime)
						valjsx.push(
							<BP.Tag key={"tm"} minimal={true} round={true}>
								{E5Text.Seconds2DHMS_str(val.time)}
							</BP.Tag>
						);
					let avgtime: number = val.time / val.count;
					if (avgtime < 600)
						cl += " red";
					else if (avgtime >= 600 && avgtime <= 3600)
						cl += " orange";
					else if (avgtime > 3600)
						cl += " green";
					cl += " hasdata";
					// compute total
					let uniqb: E5HInstabilityBand | undefined = bandmap.get(itf.band);
					if (uniqb !== undefined) {
						uniqb.totalcount += val.count;
						uniqb.totaltime += val.time;
					}
				}
				if (this.state.selchildkey === dev.macaddress || this.state.selparentmac_or_band === itf.macaddress)
					cl += " selected";
				let tip: JSX.Element = <div>
					{E5UtilI18n._("wifih-instability-parentname")} : {parentname}&nbsp;{itf.band} ({itf.macaddress})<br />
					{E5UtilI18n._("wifih-instability-childname")} : {dev.hostname} {dev.macaddress}
				</div>;
				vals.push(
					<td
						id={dev.macaddress + "|" + itf.macaddress}
						key={"devvalue" + (idx * 1000 + idx2)}
						className={"value" + cl}
						onClick={this.ValClicked}
					>
						<BP.Tooltip content={tip} position={BP.Position.BOTTOM}>
							<div>{valjsx}</div>
						</BP.Tooltip>
					</td>
				);
			}
			// total
			for (idx2 = 0; idx2 < bands.length; idx2++) {
				uniqb = bands[idx2];
				let valjsx: JSX.Element[] = [];
				let cl: string = "";
				let hasdata: boolean = false;
				if (this.state.showcount && uniqb.totalcount > 0) {
					valjsx.push(
						<BP.Tag key={"cnt"} minimal={true} round={true}>
							{uniqb.totalcount} x
						</BP.Tag>
					);
					hasdata = true;
				}
				if (this.state.showtime && uniqb.totaltime > 0) {
					valjsx.push(
						<BP.Tag key={"tm"} minimal={true} round={true}>
							{E5Text.Seconds2DHMS_str(uniqb.totaltime)}
						</BP.Tag>
					);
					hasdata = true;
				}
				let avgtime: number = uniqb.totaltime / uniqb.totalcount;
				if (avgtime < 600)
					cl += " red";
				else if (avgtime >= 600 && avgtime <= 3600)
					cl += " orange";
				else if (avgtime > 3600)
					cl += " green";
				if (hasdata)
					cl += " hasdata";
				if (this.state.selchildkey === dev.macaddress || this.state.selparentmac_or_band === uniqb.band)
					cl += " selected";
				let tip: JSX.Element = <div>
					{E5UtilI18n._("wifih-health-desc-band")} : {uniqb.band}<br />
					{E5UtilI18n._("wifih-instability-childname")} : {dev.hostname} {dev.macaddress}
				</div>;
				vals.push(
					<td
						id={dev.macaddress + "|" + uniqb.band}
						key={"nodevaluetot" + (idx * 1000 + idx2)}
						className={"value total" + cl}
						onClick={this.ValClicked}
					>
						<BP.Tooltip content={tip} position={BP.Position.BOTTOM}>
							<div>{valjsx}</div>
						</BP.Tooltip>
					</td>
				);
			}
			// network elements
			linesjsx.push(
				<tr key={"devline" + idx}>
					<td className="netelem">
						{dev.hostname} {dev.macaddress}
					</td>
					{vals}
				</tr>
			);
		}
		//
		let colcount: number = bandlist.length + +bands.length + 1;
		let fullwidth: number = 151 + 71 * (colcount - 1) + 1;// see css grid-template-columns = 150+70*N and gap=1
		//
		return <div className="e5wifi-instability">

			<div className="e5wifi-instability-inner">
				{this.props.equipinfo.equips.length > 0 && <>
					<table className="e5insta-table" style={{ width: fullwidth + "px" }}>
						<tbody>
							<tr>
								<td className="header netelem nodes">
									{E5UtilI18n._("wifih-instability-nodestitle")}:
								</td>
								{nodelistjsx}
							</tr>
							<tr>
								<td className="header netelem nodes">
									{E5UtilI18n._("wifih-instability-bandstitle")}:
								</td>
								{bandlistjsx}
							</tr>
							{linesjsx}
						</tbody>
					</table>
					<div className="legend-wrapper" style={{ width: fullwidth + "px" }}>
						<div className="e5line-10 legend">
							<div>{E5UtilI18n._("wifih-instability-avg-time")} :</div>
							<div className="e5line-5">
								<div className="legendrssi rssired" />
								<div>{"< 10m"}</div>
							</div>
							<div className="e5line-5">
								<div className="legendrssi rssiorange" />
								<div>{"10m-60m"}</div>
							</div>
							<div className="e5line-5">
								<div className="legendrssi rssigreen" />
								<div>{"> 60m"}</div>
							</div>
							{/* <BP.Divider/>
						<BP.Checkbox
							key={"time"} label={E5UtilI18n._("wifih-instability-time")} inline={true}
							value={"showtime"} checked={this.state.showtime} onChange={this.ChangeFilter}
						/>
						<BP.Checkbox
							key={"count"} label={E5UtilI18n._("wifih-instability-count")} inline={true}
							value={"showcount"} checked={this.state.showcount} onChange={this.ChangeFilter}
						/> */}
						</div>
					</div>
				</>
				}
				{(this.props.searchniinfo.status.loading
					&& this.props.connectivityinfo.topologies.length === 0) &&
					<div>
						<BP.Spinner size={15} />
					</div>
				}
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	ChangeFilter = (evt: React.FormEvent<HTMLInputElement>): void => {
		let st: any = {};
		st[evt.currentTarget.value] = evt.currentTarget.checked;
		this.setState(st);
	};

	//E5
	ValClicked = (evt: React.MouseEvent<HTMLElement>): void => {
		if (evt.currentTarget.id.indexOf("|") > 0) {
			let [childkey, parentmacorband]: string[] = evt.currentTarget.id.split("|");
			if (this.state.selparentmac_or_band !== parentmacorband || this.state.selchildkey !== childkey)
				this.setState({ selparentmac_or_band: parentmacorband, selchildkey: childkey });
			else
				this.setState({ selparentmac_or_band: "", selchildkey: "" });
		}
	};
});
