import {E5BandEnum} from "../../E5Enums";
import {E5Entity} from "../../E5Entity";

//E5
export class E5TopoLink {
	start: number = 0;
	end: number|null = null;
	band: E5BandEnum = E5BandEnum.none;
	rssi: number = 0;

	//E5
	constructor(json?: any) {
		if (json !== undefined) {
			this.start = E5Entity.ParseInt(json.start);
			this.end = E5Entity.ParseIntNull(json.end);
			this.band = Object.values(E5BandEnum).includes(json.band) ? json.band : E5BandEnum.none;
			this.rssi = E5Entity.ParseFloat(json.rssi);
		}
	}
}

//E5
export class E5TopoConn {
	conntime: number = 0;
	conncount: number = 0;
	interfmacaddr: string = "";

	//E5
	constructor(json?: any) {
		if (json !== undefined) {
			this.conntime = E5Entity.ParseInt(json.connection_time);
			this.conncount = E5Entity.ParseInt(json.connection_count);
			this.interfmacaddr = E5Entity.AssignString(json.interface_mac_address);
		}
	}
}

export class E5EntHTopology extends E5Entity {
	child: string = "";
	isbackhaul: boolean = false;
	parent: string = "";
	links: E5TopoLink[] = [];
	connectivities: E5TopoConn[] = [];

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.child = E5Entity.AssignString(json.child);
		this.isbackhaul = json.is_backhaul === true;
		this.parent = E5Entity.AssignString(json.parent);

		let idx: number;
		this.links = [];
		if (Array.isArray(json.links)) for (idx = 0; idx < json.links.length; idx++)
			this.links.push(new E5TopoLink(json.links[idx]));

		this.connectivities = [];
		if (Array.isArray(json.connectivities)) for (idx = 0; idx < json.connectivities.length; idx++)
			this.connectivities.push(new E5TopoConn(json.connectivities[idx]));
	}

	//E5
	CopyToJson(): any {
		let links: any = [], connectivities: any = [], idx: number;
		for (idx = 0; idx < this.links.length; idx++) links.push({
			start: this.links[idx].start, end: this.links[idx].end, band: this.links[idx].band,
			rssi: this.links[idx].rssi
		});

		for (idx = 0; idx < this.connectivities.length; idx++) connectivities.push({
			connection_time: this.connectivities[idx].conntime, connection_count: this.connectivities[idx].conncount,
			interface_mac_address: this.connectivities[idx].interfmacaddr
		});

		return {
			child: this.child, is_backhaul: this.isbackhaul, parent: this.parent, links: links,
			connectivities: connectivities
		};
	}
}
