import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5PieChart, E5PieChartClickCB} from "../../../global/plot/E5PieChart";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestPieChart.css";

//E5
interface E5TestPieChartState {
	stdvalues: number[];
	incvalues: number[];
	chanvalues: number[];
	confirmtitle: string|undefined;
}

//E5
interface E5TestPieChartProps {}

//E5
export const E5TestPieChart = observer(class E5TestPieChart extends React.PureComponent
	<E5TestPieChartProps, E5TestPieChartState> {

	// ---------------- MEMBERS ----------------

	//E5
	downloadbutton: any;
	stdids: string[] = ["Wi-Fi", "2.4GHz", "5GHz", "B", "A", "G", "N-2.4GHz", "N-5GHz", "AC", "AX-2.4GHz", "AX-5GHz"];
	stdlabels: string[] = [
		"Wi-Fi", "2.4GHz", "5GHz", "802.11b", "802.11a", "802.11g", "802.11n",
		"802.11n", "802.11ac", "802.11ax", "802.11ax"
	];
	stdparents: string[] = ["", "Wi-Fi", "Wi-Fi", "2.4GHz", "5GHz", "2.4GHz", "2.4GHz", "5GHz", "5GHz", "2.4GHz", "5GHz"];
	//
	incids: string[] = ["total", "I01", "I03", "I0101", "I0102", "I0301", "I0302"];
	inclabels: string[] = ["Total", "Cong.", "Cover.", "I0101", "I0102", "I0301", "I0302"];
	incparents: string[] = ["", "total", "total", "I01", "I01", "I03", "I03"];
	//
	chanids: string[] = ["total", "2ghz", "5ghz", "10", "24", "35", "72", "83", "145"];
	chanlabels: string[] = ["Total", "2GHz", "5GHz", "10", "24", "35", "72", "83", "145"];
	chanparents: string[] = ["", "total", "total", "2ghz", "2ghz", "2ghz", "5ghz", "5ghz", "5ghz"];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestPieChartProps, state: E5TestPieChartState) {
		super(props, state);
		this.state = {stdvalues: [], incvalues: [], chanvalues: [], confirmtitle: undefined};
	}

	//E5
	componentDidMount(): void {
		this.Refresh();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		return <div className="e5testpage e5test-pie-chart e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-pie-long")}</BP.H3>
			<BP.Callout title="Refresh">
				<BP.Button onClick={this.Refresh} icon="refresh"/>
			</BP.Callout>
			<div className="e5line-20">
				<BP.Popover
					content={<div className="popover-content e5column-10">
						<div>{this.state.confirmtitle}</div>
						<BP.Button
							text={E5UtilI18n._("ok")}
							intent={BP.Intent.WARNING}
							onClick={() => {
								this.setState({confirmtitle: undefined});
							}}
						/>
					</div>}
					position={BP.Position.TOP_RIGHT}
					isOpen={this.state.confirmtitle !== undefined}
				>
					<E5PieChart
						pieinfo={{
							title: "Test standard times",
							ids: this.stdids,
							parents: this.stdparents,
							values: this.state.stdvalues,
							labels: this.stdlabels,
							loading: false,
							valueisseconds: true,
							labelisincident: false,
						}}
						clickcb={this.PieClick}
					/>
				</BP.Popover>
				<E5PieChart
					pieinfo={{
						title: "Test incidents criticality",
						ids: this.incids,
						parents: this.incparents,
						values: this.state.incvalues,
						labels: this.inclabels,
						loading: false,
						valueisseconds: true,
						labelisincident: true
					}}
				/>
				<E5PieChart
					pieinfo={{
						title: "Test incidents criticality (without seconds)",
						ids: this.incids,
						parents: this.incparents,
						values: this.state.incvalues,
						labels: this.inclabels,
						loading: false,
						valueisseconds: false,
						labelisincident: true
					}}
				/>
			</div>
			<div className="e5line-20">
				<E5PieChart pieinfo={{
					title: "Test channels in use", valueisseconds: true, labelisincident: false, ids: this.chanids,
					parents: this.chanparents, values: this.state.chanvalues, labels: this.chanlabels,
					loading: false, showonlyhours: true
				}}/>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Refresh = (): void => {
		this.setState({
			stdvalues: this.GetStdValues(),
			incvalues: this.GetIncValues(),
			chanvalues: this.GetChanValues()
		});
	};

	//E5
	PieClick: E5PieChartClickCB = (id: string, label: string, parentlabel: string, value: number): void => {
		this.setState({confirmtitle: id + " @ " + parentlabel + " = " + value}, () => {
			if (this.downloadbutton !== null && this.downloadbutton !== undefined)
				this.downloadbutton.buttonRef.focus();
		});
	}

	// ---------------- UTILS ----------------

	//E5
	GetStdValues = (): number[] => {
		let bvalue: number = Math.floor(498 + Math.random() * 59731);
		let avalue: number = Math.floor(498 + Math.random() * 59731);
		let gvalue: number = Math.floor(498 + Math.random() * 59731);
		let n2value: number = Math.floor(498 + Math.random() * 59731);
		let n5value: number = Math.floor(498 + Math.random() * 59731);
		let acvalue: number = Math.floor(498 + Math.random() * 59731);
		let ax2value: number = Math.floor(498 + Math.random() * 59731);
		let ax5value: number = Math.floor(498 + Math.random() * 59731);
		return [0, 0, 0, bvalue, avalue, gvalue, n2value, n5value, acvalue, ax2value, ax5value];
	};

	//E5
	GetIncValues = (): number[] => {
		let i0101: number = Math.floor(350 + Math.random() * 6873);
		let i0102: number = Math.floor(350 + Math.random() * 6873);
		let i0301: number = Math.floor(350 + Math.random() * 6873);
		let i0302: number = Math.floor(350 + Math.random() * 6873);
		return [0, 0, 0, i0101, i0102, i0301, i0302];
	};

	//E5
	GetChanValues = (): number[] => {
		let idx: number, vals: number[] = [], pow: number = Math.ceil(Math.random() * 11);
		for (idx = 0; idx < this.chanids.length; idx++)
			vals.push(Math.round(Math.random() * Math.pow(10, pow)) * 60 * 60);
		return [0, 0, 0, ...vals];
	};
});
