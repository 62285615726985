import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHKpiSysProcCpu extends E5Entity {
	imei: string = "";
	cputimemap: Map<string, number>|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);

		let key: string;
		this.cputimemap = null;
		if (typeof json.processes_cpu_time === "object" && json.processes_cpu_time !== null) {
			this.cputimemap = new Map();
			for (key in json.processes_cpu_time) if (json.processes_cpu_time.hasOwnProperty(key))
				this.cputimemap.set(key, E5Entity.ParseInt(json.processes_cpu_time[key]));
		}
	}

	//E5
	CopyToJson(): any {
		let processes_cpu_time: any = null, key: string, val: number|null;
		if (this.cputimemap instanceof Map) for ([key, val] of this.cputimemap) {
			if (processes_cpu_time === null) processes_cpu_time = {};
			processes_cpu_time[key] = val;
		}

		return {node_imei: this.imei, processes_cpu_time};
	}
}
