import React from "react";
import {observer} from "mobx-react";
import moment, {Moment} from "moment";
import * as BP from "@blueprintjs/core";
//
import {E5XYCategData, E5XYChart, E5XYNumData} from "../../../global/plot/E5XYChart";
import {E5MainConfig} from "../../../global/E5MainConfig";
import {E5Static} from "../../../global/E5MainStatics";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Store} from "../../../store/E5Store";
import {E5Text} from "../../../util/E5Text";
//
import "./E5TestXYChart.css";

//E5
interface E5TestXYChartState {
	stacked: boolean;
	percent: boolean;
	numdata: E5XYNumData[];
	numdata_timesin1: E5XYNumData[];
	numdata_timesin2: E5XYNumData[];
	numdata_timetzforeign: E5XYNumData[];
	numdata_timetzlocal: E5XYNumData[];
	numdata_bar: E5XYNumData[];
	categdata: E5XYCategData[];
	tzoffsettest: number;
	fillleft: boolean;
}

//E5
interface E5TestXYChartProps {}

//E5
export const E5TestXYChart = observer(class E5TestXYChart extends React.PureComponent
	<E5TestXYChartProps, E5TestXYChartState> {

	// ---------------- MEMBERS ----------------

	//E5
	categories: string[] = ["BackEnd", "BackEnd", "FrontEnd", "FrontEnd", "DataScience", "DataScience", "AdminSys"];
	subcategories: string[] = ["Abdelilah", "Laurent", "Riccardo", "Pierre", "Maxime", "Thomas", "Aurélien"];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestXYChartProps, state: E5TestXYChartState) {
		super(props, state);
		this.state = {
			stacked: true,
			percent: false,
			numdata: [],
			categdata: [],
			numdata_timesin1: [],
			numdata_timesin2: [],
			numdata_timetzforeign: [],
			numdata_timetzlocal: [],
			numdata_bar: [],
			tzoffsettest: 0,
			fillleft: true
		};
	}

	//E5
	componentDidMount(): void {
		this.Refresh();
		this.Refresh2();
		this.Refresh3();
		this.Refresh4();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		return <div className="e5testpage e5test-xy-chart e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-xy-chart-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Trace 1 (left Y axis)">
					<div className="e5line-20">
						<BP.Button onClick={this.Refresh} icon="refresh" text="Refresh"/>
						<BP.Switch
							checked={this.state.stacked}
							label="5 stacked curves"
							onChange={this.ToggleStacked}
						/>
						{this.state.stacked &&
						<BP.Checkbox
							checked={this.state.percent}
							label="Percent"
							onChange={this.TogglePercent}
						/>
						}
						{!this.state.stacked &&
						<BP.Checkbox
							checked={this.state.fillleft}
							label="Fill"
							onChange={this.ToggleFillLeft}
						/>
						}
					</div>
				</BP.Callout>
				<BP.Callout title="Trace 2 (right Y axis)">
					<BP.Button onClick={this.Refresh2} icon="refresh" text="Refresh"/>
				</BP.Callout>
			</div>
			<E5XYChart
				leftsource={{
					numdatas: this.state.numdata,
					options: {stacked: this.state.stacked, percent: this.state.percent, fill: this.state.fillleft}
				}}
				rightsource={{categdatas: this.state.categdata}}
				title="Test data"
				loading={false}
				height={400}
			/>
			<E5XYChart
				leftsource={{numdatas: this.state.numdata_timesin1}}
				rightsource={{numdatas: this.state.numdata_timesin2}}
				title="Test time data"
				loading={false}
				height={200}
				xoptions={{
					xisdaytime: true,
					xisday: false,
					xtimezone: 0,
					holesizesec: E5MainConfig.GetHoleSizeSec()
				}}
			/>
			<BP.Callout title="Changer le timezone ci-dessous modifie le timezone des leftdata (blue)">
				<BP.Slider
					value={this.state.tzoffsettest}
					min={-12}
					max={12}
					onChange={this.ChangeOffset}
					showTrackFill={false}
				/>
			</BP.Callout>
			<E5XYChart
				leftsource={{numdatas: this.state.numdata_timetzforeign}}
				rightsource={{numdatas: this.state.numdata_timetzlocal}}
				title="Test TZ offset time data"
				loading={false}
				height={200}
				xoptions={{
					xisdaytime: true,
					xisday: false,
					xtimezone: this.state.tzoffsettest,
					holesizesec: E5MainConfig.GetHoleSizeSec()
				}}
			/>
			<BP.Callout title="Refresh">
				<BP.Button onClick={this.Refresh4} icon="refresh"/>
			</BP.Callout>
			<E5XYChart
				leftsource={{numdatas: this.state.numdata_bar, options: {bar: true, barstack: true}}}
				rightsource={{}}
				title="Test bar chart"
				loading={false}
				height={200}
			/>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	ToggleFillLeft = (): void => {
		this.setState({fillleft: !this.state.fillleft});
	};

	//E5
	ToggleStacked = (): void => {
		this.setState({stacked: !this.state.stacked}, this.Refresh);
	};

	//E5
	TogglePercent = (): void => {
		this.setState({percent: !this.state.percent});
	};

	//E5
	ChangeOffset = (value: number): void => {
		if (this.state.tzoffsettest !== value)
			this.setState({tzoffsettest: value}, this.Refresh3);
	};

	//E5
	Refresh = (): void => {
		let curvesnb: number = this.state.stacked ? 5 : 2;
		let datalabels: string[] = ["1team", "2team", "3team", "4team", "5team"];
		let xydatas: E5XYNumData[] = [];
		let idx, idx2: number;
		for (idx = 0; idx < curvesnb; idx++) {
			let curdata: E5XYNumData = {xaxisdata: [], yaxisdata: [], datalabel: datalabels[idx]};
			if (idx === 0)
				curdata.fillcolor = E5Static.plotcolors.plotred;
			else if (idx === 1)
				curdata.fillcolor = E5Static.plotcolors.plotorange;
			else if (idx === 2)
				curdata.fillcolor = E5Static.plotcolors.plotyellow;
			else if (idx === 3)
				curdata.fillcolor = E5Static.plotcolors.plotgreenlight;
			else
				curdata.fillcolor = E5Static.plotcolors.plotgreendark;
			for (idx2 = 0; idx2 < 20; idx2++) {
				curdata.xaxisdata.push(idx2);
				if (idx2 > 7 && idx2 < 13)
					curdata.yaxisdata.push(undefined);
				else
					curdata.yaxisdata.push(Math.random());
			}
			xydatas.push(curdata);
		}
		this.setState({numdata: xydatas});
	};

	//E5
	Refresh2 = (): void => {
		let idx: number, idx2: number;
		let xydata: E5XYCategData = {xaxisdata: [], yaxisdata: [], datalabel: "Category"};
		for (idx = 0; idx < 20; idx++) {
			xydata.xaxisdata.push(idx);
			idx2 = Math.floor(Math.random() * this.categories.length);
			xydata.yaxisdata.push(this.categories[idx2] + "-" + this.subcategories[idx2]);
		}
		this.setState({categdata: [xydata]});
	};

	//E5
	Refresh3 = (): void => {
		let xydatas_timesin1: E5XYNumData[] = [];
		let xydatas_timesin2: E5XYNumData[] = [];
		let xydatas_timetzforeign: E5XYNumData[] = [];
		let xydatas_timetzlocal: E5XYNumData[] = [];
		let idx: number;
		let curdata_sin1: E5XYNumData = {xaxisdata: [], yaxisdata: [], datalabel: "leftside"};
		let curdata_sin2: E5XYNumData = {xaxisdata: [], yaxisdata: [], datalabel: "rightside"};
		let curdata_tzforeign: E5XYNumData = {xaxisdata: [], yaxisdata: [], datalabel: "TZ" + this.state.tzoffsettest};
		let curdata_tzlocal: E5XYNumData = {xaxisdata: [], yaxisdata: [], datalabel: "localtime"};
		//
		let startlocal: Moment = moment().startOf("day");
		let starttm: number = startlocal.unix();
		//
		// 144*10 mn = 1 day
		// trim 20*10min at start and end
		for (idx = 20; idx < 124; idx++) {
			// trim middle data
			if (idx > 70 && idx < 90)
				continue;
			let tm: number = starttm + idx * 600;
			let tmstrlocal: string = moment.unix(tm).format().substr(0, 19);
			curdata_sin1.xaxisdata.push(tmstrlocal);
			curdata_sin1.yaxisdata.push(Math.sin(idx));
			curdata_sin2.xaxisdata.push(tmstrlocal);
			curdata_sin2.yaxisdata.push(100 * Math.cos(idx / 5));
			// tmstrforeign will contain the foreign time with no timestamp information ! thats the trick !
			let tmstrforeign: string = E5Text.Tm2MomentTz(tm, this.state.tzoffsettest).format().substr(0, 19);
			// if (idx === 20) {
			// 	console.log("tmstrlocal:" + tmstrlocal);
			// 	console.log("tmstrforeign:" + tmstrforeign);
			// }
			curdata_tzforeign.xaxisdata.push(tmstrforeign);
			curdata_tzforeign.yaxisdata.push(Math.sin(idx));
			curdata_tzlocal.xaxisdata.push(tmstrlocal);
			curdata_tzlocal.yaxisdata.push(100 * Math.cos(idx / 5));
		}
		xydatas_timesin1.push(curdata_sin1);
		xydatas_timesin2.push(curdata_sin2);
		xydatas_timetzforeign.push(curdata_tzforeign);
		xydatas_timetzlocal.push(curdata_tzlocal);
		this.setState({
			numdata_timesin1: xydatas_timesin1,
			numdata_timesin2: xydatas_timesin2,
			numdata_timetzforeign: xydatas_timetzforeign,
			numdata_timetzlocal: xydatas_timetzlocal
		});
	};

	//E5
	Refresh4 = (): void => {
		let numdata: E5XYNumData[] = [], idx: number;
		//
		let pommedata: E5XYNumData = {xaxisdata: [], yaxisdata: [], datalabel: "pomme"};
		pommedata.xaxisdata = ["2_4GHZ;1", "2_4GHZ;2", "2_4GHZ;3", "5GHZ;1"];
		for (idx = 0; idx < pommedata.xaxisdata.length; idx++) {
			let valseconds: number = Math.round(Math.random() * 80000);
			pommedata.yaxisdata.push(moment(valseconds * 1000).utc().format());
		}
		numdata.push(pommedata);
		//
		let poiredata: E5XYNumData = {xaxisdata: [], yaxisdata: [], datalabel: "poire"};
		poiredata.xaxisdata = ["2_4GHZ;1", "2_4GHZ;2", "2_4GHZ;3", "5GHZ;1"];
		for (idx = 0; idx < poiredata.xaxisdata.length; idx++) {
			let valseconds: number = Math.round(Math.random() * 80000);
			poiredata.yaxisdata.push(moment(valseconds * 1000).utc().format());
		}
		numdata.push(poiredata);
		//
		this.setState({numdata_bar: numdata});
	};
});
