import { E5Entity } from "../../E5Entity";

export interface E5EntHEquipmentRDMState {
    "comment": string,
    "creationTime": number,
    "deviceId": string,
    "expirationTime": number,
    "firmwareId": string,
    "lastUpdateTime": number,
    "loginId": string,
    "modelId": string,
    "networkId": string,
    "requestId": number,
    "requestResult": any,
    "requestStatusId": number,
    "requestStatusReason": any,
    "requestUuid": string,
    "ttlId": number
}

export class E5EntHEquipmentRdm extends E5Entity {
    comment!: string;
    creationTime!: number;
    deviceId!: string;
    expirationTime!: number;
    firmwareId!: string;
    lastUpdateTime!: number;
    loginId!: string;
    modelId!: string;
    networkId!: string;
    requestId!: number;
    requestResult: any;
    requestStatusId!: number;
    requestStatusReason: any;
    requestUuid!: string;
    ttlId!: number;

    constructor(json: E5EntHEquipmentRDMState) {
        super();
        this.CopyFromJson(json);
    }

    CopyFromJson(json: E5EntHEquipmentRDMState): void {
        this.comment = json.comment;
        this.creationTime = json.creationTime;
        this.deviceId = json.deviceId;
        this.expirationTime = json.expirationTime;
        this.firmwareId = json.firmwareId;
        this.lastUpdateTime = json.lastUpdateTime;
        this.loginId = json.loginId;
        this.modelId = json.modelId;
        this.networkId = json.networkId;
        this.requestId = json.requestId;
        this.requestResult = json.requestResult;
        this.requestStatusId = json.requestStatusId;
        this.requestStatusReason = json.requestStatusReason;
        this.requestUuid = json.requestUuid;
        this.ttlId = json.ttlId;
    }
}