import {E5RequestMeta} from "../../request/E5RequestMeta";
import {E5CBHealthClass} from "../../store/E5StoreCB";
import {E5RequestCB} from "../../request/E5RequestCB";
import {E5Entity} from "../E5Entity";

//E5
export interface E5SysOverMetInc {
	crit: number;
	dur: number;
}

//E5
export class E5EntCBSysOverMet extends E5Entity {
	date: string = "";
	healthmap: Map<number, E5CBHealthClass> = new Map();
	incmap: Map<string, E5SysOverMetInc> = new Map();
	inccatmap: Map<string, E5SysOverMetInc> = new Map();

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.date = E5Entity.AssignString(json.date);

		this.healthmap = new Map();
		if (Array.isArray(json.system_health_overview)) {
			this.healthmap = json.system_health_overview.reduce((acc: Map<number, E5CBHealthClass>, cur: any) => {
				if (typeof cur.class === "object" && cur.class !== null)
					return acc.set(E5Entity.ParseInt(cur.class.rank), {
						cla: E5Entity.AssignString(cur.class.label), rank: E5Entity.ParseInt(cur.class.rank),
						count: E5Entity.ParseInt(cur.count)
					});
				else return acc;
			}, new Map());
			this.healthmap = new Map([...this.healthmap.entries()].sort(
				([, health1], [, health2]) =>
					E5RequestCB.CompareRank(health2, health1)));
		}

		this.incmap = new Map();
		this.inccatmap = new Map();
		if (Array.isArray(json.system_incident_overview)) {
			let id: string, categ: string, oldinc: E5SysOverMetInc|undefined;
			for (let inc of json.system_incident_overview) {
				id = E5Entity.AssignString(inc.incident_type);
				inc = {crit: E5Entity.ParseFloat(inc.criticality), dur: E5Entity.ParseFloat(inc.duration)};
				this.incmap.set(id, {...inc});
				categ = id.substr(0, E5RequestMeta.Ins().h_incidentcateglen);
				oldinc = this.inccatmap.get(categ);
				if (oldinc === undefined) oldinc = {crit: 0, dur: 0};
				this.inccatmap.set(categ, {crit: oldinc.crit + inc.crit, dur: oldinc.dur + inc.dur});
			}
		}
	}

	//E5
	CopyToJson(): any {
		return {
			date: this.date,
			system_health_overview: Array.from(this.healthmap, ([, hcla]) =>
				({class: {label: hcla.cla, rank: hcla.rank}, count: hcla.count})),
			system_incident_overview: Array.from(this.incmap, ([id, inc]) =>
				({incident_type: id, criticality: inc.crit, duration: inc.dur}))
		};
	}
}
