import {E5EntHIncid} from "./E5EntHIncid";
import {E5BandEnum} from "../../E5Enums";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidWifi extends E5EntHIncid {
	isbackhaul: boolean = false;
	band: E5BandEnum|"MULTIPLE" = E5BandEnum.none;
	macs: string[] = [];

	static bandmultiple: string = "MULTIPLE";
	static bandunknown: string = "bandunknown";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		let idx: number;
		super.CopyFromJson(json);
		this.isbackhaul = json.affects_backhaul === true;

		this.band = Object.values(E5BandEnum).includes(json.band) || json.band === E5EntHIncidWifi.bandmultiple ?
			json.band : E5BandEnum.none;

		this.macs = [];
		if (Array.isArray(json.devices)) for (idx = 0; idx < json.devices.length; idx++)
			this.macs.push(E5Entity.AssignString(json.devices[idx]));
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), affects_backhaul: this.isbackhaul, band: this.band, devices: this.macs};
	}
}
