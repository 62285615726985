import React from "react";
import {observer} from "mobx-react";
//
import {E5StoreLangInfo} from "../../../store/E5Store";
import {E5HIncidentList} from "./E5HIncidentList";
import {E5StoreH} from "../../../store/E5StoreH";
//
import "./E5HIncIncident.css";

//E5
interface E5HIncIncidentState {}

//E5
interface E5HIncIncidentProps {
	langinfo: E5StoreLangInfo;
}

//E5
export const E5HIncIncident = observer(class E5HIncIncident extends React.PureComponent
	<E5HIncIncidentProps, E5HIncIncidentState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		return <div className="e5wifih-inc-incident e5line-20">
			<div className="e5linefull">
				<E5HIncidentList
					selincidentinfo={E5StoreH.Ins().selincidentinfo}
					incidentinfo={E5StoreH.Ins().incidentinfo} equipinfo={E5StoreH.Ins().equipinfo} showgraph={true}
					stationinfo={E5StoreH.Ins().stationinfo} searchniinfo={E5StoreH.Ins().searchniinfo}/>
			</div>
		</div>;
	}
});
