import React from "react";
import moment from "moment";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import { IReactionDisposer, reaction } from "mobx";
//
import { E5XYChart, E5XYChartClickCB, E5XYNumData, E5XYSource } from "../../../global/plot/E5XYChart";
import { E5EntHIncidWifi } from "../../../entity/household/incid/E5EntHIncidWifi";
import { E5EntHIncidSys } from "../../../entity/household/incid/E5EntHIncidSys";
import { E5RequestMeta } from "../../../request/E5RequestMeta";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5HIncidentHeatmap } from "./E5HIncidentHeatmap";
import { E5RequestH } from "../../../request/E5RequestH";
import { E5HIncidentButton } from "./E5HIncidentButton";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5BandEnum } from "../../../entity/E5Enums";
import { E5HIncidentHelp } from "./E5HIncidentHelp";
import { E5Store } from "../../../store/E5Store";
import { E5Text } from "../../../util/E5Text";
import E5HIncidentItem from './E5HIncidentItem';
import {
	E5StoreHIncidentSelIdx, E5StoreHStations, E5StoreHIncidents, E5StoreHEquips, E5StoreHSearchNi, E5StoreH,
	E5EntHIncidAny
} from "../../../store/E5StoreH";
import cn from 'classnames'
import { ReactComponent as FilterIcon } from '../../../assets/filter.svg'
//
import "./E5HIncidentList.css";

type E5HIncidWifiBackFront = "BF" | "B" | "F";

enum E5HIncidentChart {
	incidentbar = "incidentbar", equipmentbar = "equipmentbar", stationbar = "stationbar", heatmap = "heatmap"
}

//E5
interface E5HIncidentListState {
	critfilter?: number;
	all: boolean;
	wifi: boolean;
	wan: boolean;
	sys: boolean;
	catmap: Map<string, boolean>;
	bandmap: Map<string, boolean>;
	imeimap: Map<string, boolean>;
	macmap: Map<string, boolean>;
	sfwmap: Map<string, boolean>;
	modelmap: Map<string, boolean>;
	backfront: E5HIncidWifiBackFront;
	incidchart: E5HIncidentChart;
	barchartloop: boolean;
	weekmode: boolean;
}

//E5
interface E5HIncidentListProps {
	incidentinfo: E5StoreHIncidents;
	equipinfo: E5StoreHEquips;
	stationinfo: E5StoreHStations;
	selincidentinfo: E5StoreHIncidentSelIdx;
	searchniinfo: E5StoreHSearchNi;
	showgraph?: boolean;
}

//E5
export const E5HIncidentList = observer(class E5HIncidentList extends React.PureComponent
	<E5HIncidentListProps, E5HIncidentListState> {

	// ---------------- MEMBERS ----------------

	//E5
	bands: string[] = [
		E5BandEnum.freq2ghz, E5BandEnum.freq5ghz, E5BandEnum.freq6ghz,
		E5EntHIncidWifi.bandmultiple, E5EntHIncidWifi.bandunknown
	];
	stop_change_incs?: IReactionDisposer;
	stop_change_incs2?: IReactionDisposer;
	stop_change_date?: IReactionDisposer;
	bar_chart_interval?: NodeJS.Timer;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HIncidentListProps, state: E5HIncidentListState) {
		super(props, state);
		this.state = {
			critfilter: undefined, all: true, wifi: false, wan: false, sys: false, backfront: "BF", catmap: new Map(),
			bandmap: new Map(), imeimap: new Map(), macmap: new Map(), sfwmap: new Map(), modelmap: new Map(),
			incidchart: E5HIncidentChart.incidentbar, barchartloop: false, weekmode: false
		};
	}

	//E5
	componentDidMount(): void {
		this.Reset();
		this.stop_change_incs = reaction(() => this.props.incidentinfo.loading, () => this.Reset());
		this.stop_change_date = reaction(() => this.props.incidentinfo.seldatestr,
			() => E5StoreH.Ins().SetSelectedIncident(-1));
	}

	//E5
	componentWillUnmount(): void {
		this.stop_change_incs?.();
		this.stop_change_incs2?.();
		this.stop_change_date?.();
		if (this.bar_chart_interval !== undefined) clearInterval(this.bar_chart_interval);
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { wifi, wan, sys, catmap, bandmap, imeimap, macmap, sfwmap, modelmap } = this.state,
			{ incidentbar, equipmentbar, stationbar, heatmap } = E5HIncidentChart;

		let eqpintent: BP.Intent = BP.Intent.NONE, eqpall: boolean = true, eqpjsx: JSX.Element[] = [
			<div className="e5line-10" key="equip-allnone">
				<BP.Button text={_("all")} onClick={() => this.SetAllEquip(true)} />
				<BP.Button text={_("none")} onClick={() => this.SetAllEquip(false)} />
			</div>
		];
		for (let [, bool] of imeimap) if (!bool) {
			eqpintent = BP.Intent.PRIMARY;
			eqpall = false;
		}

		let staintent: BP.Intent = BP.Intent.NONE, staall: boolean = true, stajsx: JSX.Element[] = [
			<div className="e5line-10" key="station-allnone">
				<BP.Button text={_("all")} onClick={() => this.SetAllStation(true)} />
				<BP.Button text={_("none")} onClick={() => this.SetAllStation(false)} />
			</div>
		];
		for (let [, bool] of macmap) if (!bool) {
			staintent = BP.Intent.PRIMARY;
			staall = false;
		}

		let sfwintent: BP.Intent = BP.Intent.NONE, sfwall: boolean = true, sfwjsx: JSX.Element[] = [
			<div className="e5line-10" key="software-allnone">
				<BP.Button text={_("all")} onClick={() => this.SetAllSoftware(true)} />
				<BP.Button text={_("none")} onClick={() => this.SetAllSoftware(false)} />
			</div>
		];
		for (let [, bool] of sfwmap) if (!bool) {
			sfwintent = BP.Intent.PRIMARY;
			sfwall = false;
		}

		let modelintent: BP.Intent = BP.Intent.NONE, modelall: boolean = true, modeljsx: JSX.Element[] = [
			<div className="e5line-10" key="model-allnone">
				<BP.Button text={_("all")} onClick={() => this.SetAllModel(true)} />
				<BP.Button text={_("none")} onClick={() => this.SetAllModel(false)} />
			</div>
		];
		for (let [, bool] of modelmap) if (!bool) {
			modelintent = BP.Intent.PRIMARY;
			modelall = false;
		}

		let elemset: Set<string> = new Set(), sfwset: Set<string> = new Set(), modelset: Set<string> = new Set();
		let maxcrit: number = 1, critstep: number, critlabelstep: number;

		let { h_inc_wifi_nb, h_inc_sys_nb } = E5RequestMeta;
		let incsjsx: JSX.Element[] = [], { loading, incidentmap, seldatestr } =
			this.props.incidentinfo, incidents: E5EntHIncidAny[] | undefined = incidentmap.get(seldatestr);
		if (incidents !== undefined) for (let idx = 0; idx < incidents.length; idx++) {
			let inc: E5EntHIncidAny = incidents[idx];
			if (this.HasIncidentPassedFilters(inc, eqpall, staall, sfwall, modelall))
				incsjsx.push(<E5HIncidentItem
					key={"inc" + idx} incident={inc} langinfo={E5Store.Ins().langinfo}
					equipmap={this.props.equipinfo.equipmap} incidentinfo={E5StoreH.Ins().incidentinfo} indexinall={idx}
					stationmap={this.props.stationinfo.stationmap} selincidentinfo={E5StoreH.Ins().selincidentinfo} />);

			for (let imei of inc.imeis) {
				if (imei.length > 0 && !elemset.has(imei)) {
					elemset.add(imei);
					eqpjsx.push(<BP.Checkbox key={imei} id={imei} label={imei} onChange={this.SetEquip}
						checked={imeimap.get(imei)} />);
				}
			}

			if (inc instanceof E5EntHIncidWifi) for (let mac of inc.macs) {
				if (mac.length > 0 && !elemset.has(mac)) {
					elemset.add(mac);
					stajsx.push(<BP.Checkbox key={mac} id={mac} label={mac} onChange={this.SetStation}
						checked={macmap.get(mac)} />);
				}
			}

			if (E5HIncidentButton.IsSysSubClassIncid(inc)) {
				let { software, model } = (inc as E5EntHIncidSys);
				if (software.length > 0 && !sfwset.has(software)) {
					sfwset.add(software);
					sfwjsx.push(<BP.Checkbox key={software} id={software} label={software} onChange={this.SetSoftware}
						checked={sfwmap.get(software)} />);
				}
				if (model.length > 0 && !modelset.has(model)) {
					modelset.add(model);
					modeljsx.push(<BP.Checkbox key={model} id={model} label={model} onChange={this.SetModel}
						checked={modelmap.get(model)} />);
				}
			}

			if (inc.criticality > maxcrit) maxcrit = inc.criticality;
		}

		let { critfilter, weekmode } = this.state;
		if (critfilter === undefined) critfilter = 0;
		critstep = maxcrit / 50;
		critlabelstep = maxcrit / 3;

		let catjsx: JSX.Element[] = [];
		for (let cat of E5RequestMeta.Ins().h_incidentcategs) {
			let wifimodecat: boolean = wifi && cat.startsWith(h_inc_wifi_nb),
				sysmodecat: boolean = sys && cat.startsWith(h_inc_sys_nb),
				catisinconf: boolean = ["I0301", "I0302", "I0303", "I0304", "I0305", "I0306"].includes(cat),
				catenabled: boolean = (cat === "I0301" && E5MainConfig.GetSystemRebootEnabled()) ||
					(cat === "I0302" && E5MainConfig.GetSystemCpuEnabled()) ||
					(cat === "I0303" && E5MainConfig.GetSystemMemoryEnabled()) ||
					(cat === "I0304" && E5MainConfig.GetSystemProcessEnabled()) ||
					(cat === "I0305" && E5MainConfig.GetSystemFlashEnabled()) ||
					(cat === "I0306" && E5MainConfig.GetSystemTemperatureEnabled());
			if (wifimodecat || (sysmodecat && (catenabled || !catisinconf)))
				catjsx.push(<BP.Checkbox key={cat} checked={catmap.get(cat) ?? false} onChange={this.SetCat} id={cat}
					label={_("meta-wifi-incidentcateg-code-" + cat)} />);
		}

		let bandjsx: JSX.Element[] = [];
		for (let band of this.bands) bandjsx.push(<BP.Checkbox
			key={band} checked={bandmap.get(band) ?? false} onChange={this.SetBand} id={band} label={_(band)} />);

		let src: E5XYSource = { numdatas: undefined };
		src.numdatas = [];
		src.options = { stacked: false, bar: true, barstack: true, xtickvals: [] };
		let eqpsrc: E5XYSource = { numdatas: undefined };
		eqpsrc.numdatas = [];
		eqpsrc.options = { stacked: false, bar: true, barstack: true, xtickvals: [] };
		let stasrc: E5XYSource = { numdatas: undefined };
		stasrc.numdatas = [];
		stasrc.options = { stacked: false, bar: true, barstack: true, xtickvals: [] };
		if (incidentmap.size === 7) for (let idx = 0; idx < 7; idx++) {
			let datestr: string = moment().subtract(1 + idx, "day").format("YYYY-MM-DD");
			src.options.xtickvals?.push(datestr);
			eqpsrc.options.xtickvals?.push(datestr);
			stasrc.options.xtickvals?.push(datestr);
			let incids: E5EntHIncidAny[] | undefined = incidentmap.get(datestr);
			if (incids !== undefined && incids.length === 0) {
				incids = [new E5EntHIncidWifi({ nodes: [""], devices: [""] })];
				incidentmap.set(datestr, incids);
			}
		}
		let incmap: Map<string, E5XYNumData> = new Map();
		let eqpmap: Map<string, E5XYNumData> = new Map();
		let stamap: Map<string, E5XYNumData> = new Map();
		for (let [datestr, incidents] of incidentmap) {
			let inccritmap: Map<string, number> = new Map();
			let eqpcritmap: Map<string, number> = new Map();
			let stacritmap: Map<string, number> = new Map();
			for (let incid of incidents) {
				if (incid.id === "" || this.HasIncidentPassedFilters(incid, eqpall, staall, sfwall, modelall)) {
					let crit: number | undefined = inccritmap.get(incid.id);
					if (crit === undefined) crit = 0;
					crit += incid.criticality;
					inccritmap.set(incid.id, crit);
					for (let imei of incid.imeis) {
						let eqpcrit: number | undefined = eqpcritmap.get(imei);
						if (eqpcrit === undefined) eqpcrit = 0;
						eqpcrit += incid.criticality;
						eqpcritmap.set(imei, eqpcrit);
					}
					if (incid instanceof E5EntHIncidWifi) for (let mac of incid.macs) {
						let stacrit: number | undefined = stacritmap.get(mac);
						if (stacrit === undefined) stacrit = 0;
						stacrit += incid.criticality;
						stacritmap.set(mac, stacrit);
					}
				}
			}
			for (let [id, criticality] of inccritmap) {
				let numdata: E5XYNumData | undefined = incmap.get(id);
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: "", text: [] };
					if (id) {
						numdata.datalabel = id + " (" + _("meta-wifi-incident-code-" + id) + ")";
					}

					numdata.hovertemplate = "%{text} (%{x})<extra>%{fullData.name}</extra>";
					if (id === "") numdata.hidelegend = true;
					incmap.set(id, numdata);
				}
				numdata.xaxisdata.push(datestr);
				numdata.yaxisdata.push(criticality / 3600);
				numdata.text?.push(E5Text.Seconds2DHMS_str(criticality));
			}
			for (let [imei, criticality] of eqpcritmap) {
				let numdata: E5XYNumData | undefined = eqpmap.get(imei);
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: "", text: [] };
					numdata.datalabel = imei;
					numdata.hovertemplate = "%{text} (%{x})<extra>%{fullData.name}</extra>";
					if (imei === "") numdata.hidelegend = true;
					eqpmap.set(imei, numdata);
				}
				numdata.xaxisdata.push(datestr);
				numdata.yaxisdata.push(criticality / 3600);
				numdata.text?.push(E5Text.Seconds2DHMS_str(criticality));
			}
			for (let [mac, criticality] of stacritmap) {
				let numdata: E5XYNumData | undefined = stamap.get(mac);
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: "", text: [] };
					numdata.datalabel = mac;
					numdata.hovertemplate = "%{text} (%{x})<extra>%{fullData.name}</extra>";
					if (mac === "") numdata.hidelegend = true;
					stamap.set(mac, numdata);
				}
				numdata.xaxisdata.push(datestr);
				numdata.yaxisdata.push(criticality / 3600);
				numdata.text?.push(E5Text.Seconds2DHMS_str(criticality));
			}
		}
		for (let [, numdata] of incmap) src.numdatas.push(numdata);
		for (let [, numdata] of eqpmap) eqpsrc.numdatas.push(numdata);
		for (let [, numdata] of stamap) stasrc.numdatas.push(numdata);

		src.numdatas.sort((n1, n2) =>
			n1.datalabel !== undefined && n2.datalabel !== undefined ? n2.datalabel.localeCompare(n1.datalabel) : 0);
		for (let numdata of src.numdatas) if (numdata.datalabel !== undefined)
			numdata.fillcolor = E5MainConfig.GetIncidentColors()[numdata.datalabel.substring(0, 7)];

		let incidentfor: string = seldatestr.length > 0 ? E5Text.Substitute(_("wifih-incident-date"),
			[moment(seldatestr).format(_("dateformat"))]) : "";

		let weekready: boolean = weekmode && incidentmap.size === 7;
		let dayready: boolean = !weekmode && incidentmap.size >= 1;
		let anyready: boolean = !loading && (weekready || dayready);
		let canload: boolean = !this.props.searchniinfo.status.loading && !loading &&
			this.props.searchniinfo.networkid !== "";

		return <div className="e5column-20 e5h-incident-list">
			<div className="e5compo">
				<div className="e5line-20 filters">
					<FilterIcon className='filter-icon' />
					<div className="e5line-0 toggle">
						<div className={cn('toggle-item', { 'active': this.state.all })} onClick={this.SetAll}>{_("wifih-incident-all")}</div>
						{E5MainConfig.GetWifiEnabled() && <div className={cn('toggle-item', { 'active': wifi })} onClick={this.SetWifi}>Wi-Fi</div>}
						{E5MainConfig.GetWanEnabled() && <div className={cn('toggle-item', { 'active': wan })} onClick={this.SetWan}>WAN</div>}
						{E5MainConfig.GetSystemEnabled() && <div className={cn('toggle-item', { 'active': sys })} onClick={this.SetSys}>{_("system")}</div>}
					</div>
					<div className="e5line-0 toggle">
						<div className={cn('toggle-item', { 'active': !weekmode })} onClick={this.ToggleWeekMode}>Day</div>
						<div className={cn('toggle-item', { 'active': weekmode })} onClick={this.ToggleWeekMode}>Week</div>
					</div>
				</div>
				{/* <BP.Divider />
				<div className="e5line-20">
					<div className="filtertitle verticalcenter">{_("wifih-incident-criticality-filter")} :</div>
					<div className="critslider">
						<BP.Slider onChange={this.SetCrit} labelStepSize={critlabelstep} value={critfilter}
							labelRenderer={this.CritLabel} showTrackFill={false} min={0} max={maxcrit}
							disabled={!anyready} stepSize={critstep} />
					</div>
				</div>
				<BP.Divider /> */}
				{/* <div className="e5line-20">
					<div className="e5linefull" />
					{weekready &&
						<div className="e5line-0 toggle">
							{[incidentbar,equipmentbar,stationbar,heatmap].map((incidchart,index)=>
								<div key={index} className={cn('toggle-item', { 'active': incidchart === this.state.incidchart })} onClick={() => this.SetIncidentChart(incidchart)}>{E5UtilI18n._("wifih-incident-chart-" + incidchart)}</div>
							)}
						</div>}
				</div> */}
				{(wifi || sys) && <>
					<BP.Divider />
					<div className="e5line-20">
						<div className="filtertitle">{_("wifih-incident-categ-filter")} :</div>
						{catjsx}
					</div>
				</>}
				{wifi && <>
					<BP.Divider />
					<div className="e5line-20">
						<div className="filtertitle">{_("wifih-incident-band-filter")} :</div>
						{bandjsx}
					</div>
				</>}
				{(wifi || sys) && <>
					<BP.Divider />
					<div className="e5line-10">
						<div className="filtertitle verticalcenter">{_("wifih-incident-network-filter")} :</div>
						<BP.Popover position={BP.Position.BOTTOM}
							content={<div className="e5column-5 e5h-incident-list-popover">{eqpjsx}</div>}>
							<BP.Button intent={eqpintent} children={_("wifih-incident-equip-filter")} />
						</BP.Popover>
						{wifi && <>
							<BP.Popover position={BP.Position.BOTTOM} content={<div
								className="e5column-5 e5h-incident-list-popover">{stajsx}</div>}>
								<BP.Button intent={staintent} children={_("wifih-incident-station-filter")} />
							</BP.Popover>
							<BP.RadioGroup className="verticalcenter" inline={true}
								selectedValue={this.state.backfront} onChange={this.ChangeBFFilter}>
								<BP.Radio id="bf_filter_both" type="radio" value="BF"
									label={_("wifih-incident-bf_both")} />
								<BP.Radio id="bf_filter_both" type="radio" value="B"
									label={_("wifih-incident-bf_backhaul")} />
								<BP.Radio id="bf_filter_both" type="radio" value="F"
									label={_("wifih-incident-bf_fronthaul")} />
							</BP.RadioGroup>
						</>}
						{sys && <>
							<BP.Popover position={BP.Position.BOTTOM} content={<div
								className="e5column-5 e5h-incident-list-popover">{sfwjsx}</div>}>
								<BP.Button intent={sfwintent} children={_("wifih-incident-software-filter")} />
							</BP.Popover>
							<BP.Popover position={BP.Position.BOTTOM} content={<div
								className="e5column-5 e5h-incident-list-popover">{modeljsx}</div>}>
								<BP.Button intent={modelintent} children={_("wifih-incident-model-filter")} />
							</BP.Popover>
						</>}
					</div>
				</>}
			</div>
			{anyready &&
				<div className="incident-list">
					{incsjsx}
				</div>
			}
		</div>;
	}

	//E5
	RenderChartButton = (incidchart: E5HIncidentChart): JSX.Element => <BP.Button
		onClick={() => this.SetIncidentChart(incidchart)} text={E5UtilI18n._("wifih-incident-chart-" + incidchart)}
		intent={incidchart === this.state.incidchart ? BP.Intent.PRIMARY : BP.Intent.NONE} small
		disabled={this.props.incidentinfo.loading || this.props.incidentinfo.incidentmap.size !== 7} />;

	//E5
	CritLabel = (value: number): JSX.Element =>
		<span style={{ whiteSpace: "nowrap" }}>{E5Text.Seconds2DHMS_str(value)}</span>;

	// ---------------- EVENTS ----------------

	//E5
	ToggleWeekMode = (): void => {
		if (!this.props.incidentinfo.loading) {
			this.setState({ weekmode: !this.state.weekmode })
			this.LoadIncidents(!this.state.weekmode);
		}
	};

	//E5
	LoadIncidents = (isWeek: boolean): void => {
		let { networkid } = this.props.searchniinfo;
		let { incidentmap } = this.props.incidentinfo;
		const curDate = E5StoreH.Ins().curdateinfo.curdate

		if (incidentmap.has(curDate) && !isWeek) {
			E5StoreH.Ins().incidentinfo.seldatestr = curDate
			return
		}
		if (isWeek) E5RequestH.Ins().FetchWeekIncidents(networkid, undefined);
		else E5RequestH.FetchIncidents(networkid, 0, curDate, true,
			() => E5StoreH.Ins().SetIncidents(false, null, null, null));
	};

	//E5
	SetAll = (): void =>
		this.setState({ all: true, wifi: false, wan: false, sys: false }, this.Reset);

	//E5
	SetWifi = (): void =>
		this.setState({ all: false, wifi: true, wan: false, sys: false }, this.Reset);

	//E5
	SetWan = (): void =>
		this.setState({ all: false, wifi: false, wan: true, sys: false }, this.Reset);

	//E5
	SetSys = (): void =>
		this.setState({ all: false, wifi: false, wan: false, sys: true }, this.Reset);

	//E5
	SetCrit = (value: number) =>
		this.setState({ critfilter: value });

	//E5
	SetCat = (event: React.FormEvent<HTMLInputElement>) => {
		let cat: string = event.currentTarget.id;
		let catmap: Map<string, boolean> = new Map(this.state.catmap);
		let catval: boolean | undefined = this.state.catmap.get(cat);
		if (catval !== undefined) catmap.set(cat, !catval);
		this.setState({ catmap });
	};

	//E5
	SetBand = (event: React.FormEvent<HTMLInputElement>) => {
		let band: string = event.currentTarget.id;
		let bandmap: Map<string, boolean> = new Map(this.state.bandmap);
		let bandval: boolean | undefined = this.state.bandmap.get(band);
		if (bandval !== undefined) bandmap.set(band, !bandval);
		this.setState({ bandmap });
	};

	//E5
	SetEquip = (event: React.FormEvent<HTMLInputElement>) => {
		let imei: string = event.currentTarget.id, checked: boolean = event.currentTarget.checked;
		let imeimap: Map<string, boolean> = new Map(this.state.imeimap);
		imeimap.set(imei, checked);
		this.setState({ imeimap });
	};

	//E5
	SetStation = (event: React.FormEvent<HTMLInputElement>) => {
		let mac: string = event.currentTarget.id, checked: boolean = event.currentTarget.checked;
		let macmap: Map<string, boolean> = new Map(this.state.macmap);
		macmap.set(mac, checked);
		this.setState({ macmap });
	};

	//E5
	SetSoftware = (event: React.FormEvent<HTMLInputElement>) => {
		let software: string = event.currentTarget.id, checked: boolean = event.currentTarget.checked;
		let sfwmap: Map<string, boolean> = new Map(this.state.sfwmap);
		sfwmap.set(software, checked);
		this.setState({ sfwmap });
	};

	//E5
	SetModel = (event: React.FormEvent<HTMLInputElement>) => {
		let model: string = event.currentTarget.id, checked: boolean = event.currentTarget.checked;
		let modelmap: Map<string, boolean> = new Map(this.state.modelmap);
		modelmap.set(model, checked);
		this.setState({ modelmap });
	};

	//E5
	ChangeBFFilter = (event: React.FormEvent<HTMLInputElement>): void =>
		this.setState({ backfront: (event.currentTarget.value) as E5HIncidWifiBackFront });

	//E5
	SetAllEquip(val: boolean): void {
		let imeimap: Map<string, boolean> = new Map(this.state.imeimap);
		imeimap.forEach((value: boolean, imei: string) => imeimap.set(imei, val));
		this.setState({ imeimap });
	}

	//E5
	SetAllStation(val: boolean): void {
		let macmap: Map<string, boolean> = new Map(this.state.macmap);
		macmap.forEach((value: boolean, mac: string) => macmap.set(mac, val));
		this.setState({ macmap });
	}

	//E5
	SetAllSoftware(val: boolean): void {
		let sfwmap: Map<string, boolean> = new Map(this.state.sfwmap);
		sfwmap.forEach((value: boolean, software: string) => sfwmap.set(software, val));
		this.setState({ sfwmap });
	}

	//E5
	SetAllModel(val: boolean): void {
		let modelmap: Map<string, boolean> = new Map(this.state.modelmap);
		modelmap.forEach((value: boolean, mac: string) => modelmap.set(mac, val));
		this.setState({ modelmap });
	}

	//E5
	SetIncidentChart = (incidchart: E5HIncidentChart): void => {
		if (this.bar_chart_interval !== undefined) clearInterval(this.bar_chart_interval);
		this.setState({ incidchart, barchartloop: false });
	};

	//E5
	ClickWeekBar: E5XYChartClickCB = (date: string): void =>
		E5StoreH.Ins().SetIncidents(null, null, null, date);

	// ---------------- UTILS ----------------

	//E5
	Reset = (): void => {
		E5StoreH.Ins().SetSelectedIncident(-1);
		let imeimap: Map<string, boolean> = new Map(), macmap: Map<string, boolean> = new Map();
		let sfwmap: Map<string, boolean> = new Map(), modelmap: Map<string, boolean> = new Map();
		for (let [, incidents] of this.props.incidentinfo.incidentmap) for (let inc of incidents) {
			for (let imei of inc.imeis) if (imei.length > 0) imeimap.set(imei, true);
			if (inc instanceof E5EntHIncidWifi) for (let mac of inc.macs) if (mac.length > 0) macmap.set(mac, true);
			if (E5HIncidentButton.IsSysSubClassIncid(inc)) {
				if ((inc as E5EntHIncidSys).software.length > 0) sfwmap.set((inc as E5EntHIncidSys).software, true);
				if ((inc as E5EntHIncidSys).model.length > 0) modelmap.set((inc as E5EntHIncidSys).model, true);
			}
		}

		let { all, wifi, wan, sys } = this.state, catmap: Map<string, boolean> = new Map();
		for (let cat of E5RequestMeta.Ins().h_incidentcategs) {
			let wifimodecat: boolean = (all || wifi) && cat.startsWith(E5RequestMeta.h_inc_wifi_nb),
				sysmodecat: boolean = (all || sys) && cat.startsWith(E5RequestMeta.h_inc_sys_nb),
				wanmodecat: boolean = (all || wan) && cat.startsWith(E5RequestMeta.h_inc_wan_nb),
				catisinconf: boolean = ["I0301", "I0302", "I0303", "I0304", "I0305", "I0306"].includes(cat),
				catenabled: boolean = (cat === "I0301" && E5MainConfig.GetSystemRebootEnabled()) ||
					(cat === "I0302" && E5MainConfig.GetSystemCpuEnabled()) ||
					(cat === "I0303" && E5MainConfig.GetSystemMemoryEnabled()) ||
					(cat === "I0304" && E5MainConfig.GetSystemProcessEnabled()) ||
					(cat === "I0305" && E5MainConfig.GetSystemFlashEnabled()) ||
					(cat === "I0306" && E5MainConfig.GetSystemTemperatureEnabled());
			if (wifimodecat || wanmodecat || (sysmodecat && (catenabled || !catisinconf))) catmap.set(cat, true);
		}
		let weekmode = false;
		if (this.props.incidentinfo.incidentmap.size === 7) {
			weekmode = true;
		}

		let bandmap: Map<string, boolean> = new Map();
		for (let band of this.bands) bandmap.set(band, true);

		this.setState(
			{ imeimap, macmap, sfwmap, modelmap, critfilter: undefined, catmap, bandmap, backfront: "BF", weekmode });
	};

	//E5
	HasIncidentPassedFilters = (inc: E5EntHIncidAny, eqpall: boolean, staall: boolean, sfwall: boolean,
		modelall: boolean): boolean => {
		let { wifi, wan, sys, catmap, bandmap, imeimap, macmap, sfwmap, modelmap } = this.state,
			iswifi: boolean = inc.id.startsWith(E5RequestMeta.h_inc_wifi_nb),
			iswan: boolean = inc.id.startsWith(E5RequestMeta.h_inc_wan_nb),
			issystem: boolean = inc.id.startsWith(E5RequestMeta.h_inc_sys_nb),
			wifiok: boolean = (E5MainConfig.GetWifiEnabled() && wifi) || !iswifi,
			wanok: boolean = (E5MainConfig.GetWanEnabled() && wan) || !iswan,
			sysok: boolean = (E5MainConfig.GetSystemEnabled() && sys) || !issystem,
			catok: boolean | undefined = catmap.get(inc.id.substr(0, E5RequestMeta.Ins().h_incidentcateglen)),
			band: string = inc instanceof E5EntHIncidWifi && this.bands.includes(inc.band) ?
				inc.band : E5EntHIncidWifi.bandunknown,
			bandok: boolean | undefined = bandmap.get(band), backfrontok: boolean,
			critok: boolean = this.state.critfilter === undefined || inc.criticality >= this.state.critfilter,
			eqpok: boolean = false, staok: boolean = false, sfwok: boolean = false, modelok: boolean = false;

		if (eqpall) eqpok = true;
		else for (let imei of inc.imeis) if (imeimap.get(imei)) {
			eqpok = true;
			break;
		}

		if (staall || !(inc instanceof E5EntHIncidWifi)) staok = true;
		else for (let mac of inc.macs) if (macmap.get(mac)) {
			staok = true;
			break;
		}

		if (sfwall || !E5HIncidentButton.IsSysSubClassIncid(inc)) sfwok = true;
		else if (sfwmap.get((inc as E5EntHIncidSys).software)) sfwok = true;

		if (modelall || !E5HIncidentButton.IsSysSubClassIncid(inc)) modelok = true;
		else if (modelmap.get((inc as E5EntHIncidSys).model)) modelok = true;

		backfrontok = !(inc instanceof E5EntHIncidWifi)
			|| (inc.isbackhaul && this.state.backfront.includes("B"))
			|| (!inc.isbackhaul && this.state.backfront.includes("F"));

		return (this.state.all && critok && ((E5MainConfig.GetWifiEnabled() && iswifi) || (E5MainConfig.GetWanEnabled()
			&& iswan) || (E5MainConfig.GetSystemEnabled() && issystem)) && catok === true) || (critok && wifiok && wanok
				&& sysok && catok === true && bandok === true && eqpok && staok && backfrontok && sfwok && modelok);
	};
});
