import React from 'react';
import { MobileDatePicker } from "@mui/x-date-pickers";
import { border, fontWeight, styled } from '@mui/system';
const StyledMobileDatePicker = styled(MobileDatePicker)({
    '& label': {
        fontFamily: 'inherit',
        fontSize: '1rem',
    },
    '& input': {
        fontFamily: 'Montserrat',
        padding: '8.5px 14px',
        fontSize: '14px',
    },
    '& .MuiInputBase-root': {
        borderRadius: '10px',
        height: '40px',
    },
    '& .MuiButton-contained': {
        backgroundColor: '#0080FF', // Customize the background color of the button
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            fontFamily: 'Montserrat',
            borderColor: '#0080FF !important', // Use !important to ensure the border color takes precedence
        },
    },
    '& .MuiInputLabel-root': {
        color: '#0080FF  !important', // Use !important to ensure the label color takes precedence
    },
    // Add more styles as needed
});

function MyStyledDatePicker({ onChange, label, value, className }) {

    return (
        <StyledMobileDatePicker
            label={label}
            value={value}
            onChange={onChange}
            className={className}
        />
    );
}

export default MyStyledDatePicker;