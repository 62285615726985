import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5AdminAclList } from "./E5AdminAclList";
import { E5Store } from "../../../store/E5Store";
//
import "./E5AdminAcl.css";

//E5
interface E5AdminAclState { }

//E5
interface E5AdminAclProps { }

//E5
export const E5AdminAcl = observer(class E5AdminAcl extends React.PureComponent<E5AdminAclProps, E5AdminAclState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		return <div className="e5page e5admin-acl e5columnfull e5column-20">
			<div className="e5column-5">
				{/* <BP.Breadcrumbs items={[
					{icon: "bookmark", text: E5UtilI18n._("mainmenu-management")},
					{text: E5UtilI18n._("mainmenu-m-acl")}
				]}/> */}
				<E5PageTitle titlekey="pagetitle-management-acl" />
			</div>
			<div className="e5admin-acl-content e5columnfull e5column-0">
				<E5AdminAclList />
			</div>
		</div>;
	}
});
