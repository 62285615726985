//
import {E5Entity} from "../E5Entity";

//E5
export class E5EntRdmRequestDev extends E5Entity {
	model: string = "";
	firmware: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.model = E5Entity.AssignString(json.deviceModelName);
		this.firmware = E5Entity.AssignString(json.deviceFirmwareVersion);
	}

	//E5
	CopyToJson(): any {
		return {deviceModelName: this.model, deviceFirmwareVersion: this.firmware};
	}

}

//E5
export class E5EntRdmRequest extends E5Entity {

	//E5
	id: number = 0;
	name: string = "";
	type: string = "";
	description: string = "";
	devices: E5EntRdmRequestDev[] = [];

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		let idx: number;
		this.id = E5Entity.ParseInt(json.requestId);
		this.name = E5Entity.AssignString(json.requestName);
		this.type = E5Entity.AssignString(json.requestType)
		this.description = E5Entity.AssignString(json.requestDescription);
		this.devices = [];
		if (Array.isArray(json.supportedDevices)) for (idx = 0; idx < json.supportedDevices.length; idx++) {
			let dev: E5EntRdmRequestDev = new E5EntRdmRequestDev(json.supportedDevices[idx]);
			this.devices.push(dev);
		}
	}

	//E5
	CopyToJson(): any {
		let devs: any = [], idx: number;
		for (idx = 0; idx < this.devices.length; idx++) devs.push(this.devices[idx].CopyToJson());

		return {
			requestId: this.id, requestName: this.name, requestType: this.type, requestDescription: this.description,
			supportedDevices: devs
		};
	}
}
