import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5EntAclGroup } from "../../../entity/E5EntAclGroup";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Store } from "../../../store/E5Store";
//
import "./E5AdminAclPopup.css";

//E5
interface E5AdminAclPopupState { }

//E5
interface E5AdminAclPopupProps {
	group: E5EntAclGroup;
	defaultexists: boolean;
	updatefunc: (group: E5EntAclGroup) => void;
}

//E5
export const E5AdminAclPopup = observer(class E5AdminAclPopup extends React.PureComponent
	<E5AdminAclPopupProps, E5AdminAclPopupState> {

	// ---------------- MEMBERS ----------------

	// ---------------- INIT ----------------

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, defaultdisabled: boolean = this.props.defaultexists && !this.props.group.default;
		let redstarjsx: JSX.Element = <span style={{ color: "#FF0000" }}>*</span>;

		return <div className="e5admin-acl-popup">
			<div className="e5line-10 padding-bottom-10">
				<div className="e5linefull">
					<BP.FormGroup >
						<BP.InputGroup placeholder={_("admin-acl-creationdate")} disabled />
					</BP.FormGroup>
				</div>
				<div className="e5linefull">
					<BP.FormGroup>
						<BP.InputGroup placeholder={_("admin-acl-updatedate")} disabled />
					</BP.FormGroup>
				</div>
				<div className="e5linefull">
					<BP.FormGroup>
						<BP.InputGroup placeholder={_("admin-acl-updatedbyuser")} value={this.props.group.updatedbyuser} disabled />
					</BP.FormGroup>
				</div>
			</div>
			<div className="e5line-10 padding-bottom-10">
				<div className="e5linefull">
					<BP.FormGroup>
						<BP.InputGroup placeholder={_("admin-acl-type")} value={this.props.group.type} disabled />
					</BP.FormGroup>
				</div>
				<div className="e5linefull">
					<BP.FormGroup>
						<BP.InputGroup placeholder={_("admin-acl-id")} value={this.props.group.id !== null ? this.props.group.id + "" : ""} disabled />
					</BP.FormGroup>
				</div>
			</div>
			<div className="e5line-10 padding-bottom-10">
				<div className="e5linefull">
					<BP.FormGroup style={{ position: 'relative' }}
						labelInfo={<BP.Icon icon="star" iconSize={5} />}>
						<div className="label">{_("admin-acl-name")}{redstarjsx}</div>
						<BP.InputGroup className="requiredLong" value={this.props.group.name}
							onChange={(event: any) => this.ChangeTextValue("name", event)} />
					</BP.FormGroup>
				</div>
				<div className="e5linefull">
					<BP.FormGroup label={_("admin-acl-default")} contentClassName="e5line-0" className="admin-acl-default">
						<BP.Checkbox className="admin-acl-default-checkbox" checked={this.props.group.default} onChange={this.ChangeDefault} large
							disabled={defaultdisabled} />
						{defaultdisabled && <b className="default-exists">{_("admin-acl-default-exists")}</b >}
					</BP.FormGroup>
				</div>
			</div>
			<div className="e5line-10">
				<BP.FormGroup className="e5linefull">
					<BP.TextArea value={this.props.group.listnetid} disabled={this.props.group.default}
						placeholder={_("admin-acl-listnetid")}
						onChange={(event: any) => this.ChangeTextValue("listnetid", event)} />
				</BP.FormGroup>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	ChangeTextValue = (field: string, event: any) => {
		let val: string = event.currentTarget.value, group: E5EntAclGroup = new E5EntAclGroup();
		group.Copy(this.props.group);
		if (field === "name" || field === "listnetid") group[field] = val;
		this.props.updatefunc(group);
	};

	//E5
	ChangeDefault = () => {
		let group: E5EntAclGroup = new E5EntAclGroup();
		group.Copy(this.props.group);
		group.default = !group.default;
		this.props.updatefunc(group);
	};

});
