import {Moment} from "moment";
//
import {E5Text} from "../../../util/E5Text";
import {E5Time} from "../../../util/E5Time";
import {E5Entity} from "../../E5Entity";

//E5
export interface E5HIncidRecom {
	id: string;
	contexts: string[];
}

//E5
export interface E5HIncidSever {
	time: Moment;
	severity: number;
}

//E5
export class E5EntHIncid extends E5Entity {
	id: string = "";
	duration: number = 0;
	criticality: number = 0;
	imeis: string[] = [];
	recoms: E5HIncidRecom[] = [];
	severs: E5HIncidSever[] = [];

	// not from backend json
	reqdatestr: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		let idx: number, idx2: number;
		this.id = E5Entity.AssignString(json.incident_id);
		this.duration = E5Entity.ParseInt(json.duration);
		this.criticality = E5Entity.ParseFloat(json.criticality);

		this.imeis = [];
		if (Array.isArray(json.nodes)) for (idx = 0; idx < json.nodes.length; idx++)
			this.imeis.push(E5Entity.AssignString(json.nodes[idx]));

		this.recoms = [];
		if (Array.isArray(json.recommendations)) for (idx = 0; idx < json.recommendations.length; idx++) {
			let recom: any = json.recommendations[idx], contexts: string[] = [];
			if (Array.isArray(recom.contexts)) for (idx2 = 0; idx2 < recom.contexts.length; idx2++)
				contexts.push(E5Entity.AssignString(recom.contexts[idx2]));
			this.recoms.push({id: E5Entity.AssignString(recom.recommendation_id), contexts});
		}

		this.severs = [];
		if (Array.isArray(json.severity_over_time)) for (idx = 0; idx < json.severity_over_time.length; idx++) {
			let sever: any = json.severity_over_time[idx];
			let time: Moment = E5Text.Tm2MomentTz(E5Entity.ParseInt(sever.bucket_id), E5Time.h_tzoffset);
			this.severs.push({time, severity: E5Entity.ParseFloat(sever.severity)});
		}

		// not from backend json
		this.reqdatestr = E5Entity.AssignString(json.reqdatestr);
	}

	//E5
	CopyToJson(): any {
		return {
			incident_id: this.id, duration: this.duration, criticality: this.criticality, nodes: this.imeis,
			recommendations: this.recoms.map(recom => ({recommendation_id: recom.id, contexts: recom.contexts})),
			severity_over_time: this.severs.map(sever => ({bucket_id: sever.time.unix(), severity: sever.severity}))
		};
	}
}
