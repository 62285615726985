import {E5EntHAnom} from "./E5EntHAnom";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHAnomSys extends E5EntHAnom {
	imei: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.imei = E5Entity.AssignString(json.node_imei);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), node_imei: this.imei};
	}
}
