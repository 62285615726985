import { Moment } from "moment";
//
import { E5BandEnum, E5NodeTypeEnum } from "../../E5Enums";
import { E5Text } from "../../../util/E5Text";
import { E5Entity } from "../../E5Entity";
import moment from "moment";

//E5
export class E5EquipInterf {
	status_as_is: string = ""; // useful to output the original status (prior to .toLowerCase) when needed

	band: E5BandEnum = E5BandEnum.none;
	macaddress: string = "";
	wifissid: string = "";
	status: string = "";
	wifistdavailable: string[] = [];
	wifistdused: string[] = [];
	supportbandwidth: string = "";
	curbandwidth: string = "";
	possiblechannels: string = "";
	channelsinuse: string = "";
	autochanenable: boolean = false;
	maxbitrate: number = 0;

	//E5
	constructor(json?: any) {
		if (json !== undefined) {
			this.band = Object.values(E5BandEnum).includes(json.band) ? json.band : E5BandEnum.none;
			this.macaddress = E5Entity.AssignString(json.mac_address);
			this.wifissid = E5Entity.AssignString(json.ssid);
			this.status_as_is = E5Entity.AssignString(json.status);
			this.status = this.status_as_is.toLowerCase();
			this.wifistdavailable = E5Entity.ProcessStandards(json.capabilities);
			this.wifistdused = E5Entity.ProcessStandards(json.standards_used);
			this.supportbandwidth = E5Entity.AssignString(json.supported_bandwidth);
			this.curbandwidth = E5Entity.AssignString(json.current_operating_bandwidth);
			this.possiblechannels = E5Entity.AssignString(json.possible_channels);
			this.channelsinuse = E5Entity.AssignString(json.channels_in_use);
			this.autochanenable = json.auto_channel_enable === true;
			this.maxbitrate = E5Entity.ParseInt(json.max_bit_rate);

		}
	}
}

//E5
export class E5EntHEquip extends E5Entity {
	imei: string = "";
	manufacturer: string = "";
	model: string = "";
	hardwarev: string = "";
	softwarev: string = "";
	nodetype: E5NodeTypeEnum = E5NodeTypeEnum.none;
	lastseen: Moment | null = null;
	interfaces: E5EquipInterf[] = [];
	health: number | null = 0;
	posixtimezone: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.imei);
		this.manufacturer = E5Entity.AssignStringNA(json.manufacturer);
		this.model = E5Entity.AssignStringNA(json.model);
		this.hardwarev = E5Entity.AssignStringNA(json.hardware_version);
		this.softwarev = E5Entity.AssignStringNA(json.software_version);

		if (typeof json.node_type === "string") {
			let nodetype: any = json.node_type.toLowerCase();
			this.nodetype = Object.values(E5NodeTypeEnum).includes(nodetype) ? nodetype : E5NodeTypeEnum.none;
		}

		let lastseen: number | null = E5Entity.ParseIntNull(json.last_seen);
		this.lastseen = lastseen === null ? null : E5Text.Tm2MomentTz(lastseen, moment().utcOffset() / 60);

		let idx: number;
		this.interfaces = [];
		if (Array.isArray(json.interfaces)) for (idx = 0; idx < json.interfaces.length; idx++)
			this.interfaces.push(new E5EquipInterf(json.interfaces[idx]));

		this.health = json.health === null ? null : E5Entity.ParseFloat(json.health);
		this.posixtimezone = E5Entity.AssignString(json.timezone);
	}

	//E5
	CopyToJson(): any {
		return {
			imei: this.imei, manufacturer: this.manufacturer, model: this.model, hardware_version: this.hardwarev,
			software_version: this.softwarev, node_type: this.nodetype.toUpperCase(),
			last_seen: this.lastseen?.unix() ?? null, interfaces: this.interfaces.map((interf: E5EquipInterf): any => ({
				supported_bandwidth: interf.supportbandwidth, current_operating_bandwidth: interf.curbandwidth,
				possible_channels: interf.possiblechannels, channels_in_use: interf.channelsinuse,
				auto_channel_enable: interf.autochanenable, max_bit_rate: interf.maxbitrate, band: interf.band,
				mac_address: interf.macaddress, ssid: interf.wifissid, status: interf.status_as_is,
				capabilities: interf.wifistdavailable, standards_used: interf.wifistdused
			})), health: this.health, timezone: this.posixtimezone
		};
	}
}
