import {E5EntMetaLangs} from "./E5EntMetaLangs";
import {E5Entity} from "../E5Entity";

//E5
export interface E5MetaCateg {
	categid: string;
	langs: E5EntMetaLangs;
}

//E5
export class E5EntMetaIncident extends E5Entity {
	id: string = "";
	categ: E5MetaCateg = {categid: "", langs: new E5EntMetaLangs()};
	langs: E5EntMetaLangs = new E5EntMetaLangs();
	anomids: string[] = [];

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.id = E5Entity.AssignString(json.incident_id);

		this.categ = {categid: "", langs: new E5EntMetaLangs()};
		if (typeof json.category === "object" && json.category !== null) this.categ = {
			categid: E5Entity.AssignString(json.category.category_id),
			langs: new E5EntMetaLangs(json.category.languages)
		};

		this.langs = new E5EntMetaLangs(json.languages);

		let idx: number;
		this.anomids = [];
		if (Array.isArray(json.anomalies)) for (idx = 0; idx < json.anomalies.length; idx++)
			this.anomids.push(E5Entity.AssignString(json.anomalies[idx].id));
	}

	//E5
	CopyToJson(): any {
		let anomalies: any[] = [], idx: number;
		for (idx = 0; idx < this.anomids.length; idx++) anomalies.push({id: this.anomids[idx]});

		return {
			incident_id: this.id, category: {category_id: this.categ.categid, languages: this.categ.langs.CopyToJson()},
			languages: this.langs.CopyToJson(), anomalies
		};
	}
}
