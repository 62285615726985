import {E5Entity} from "../E5Entity";

//E5
export class E5EntMetaLangs extends E5Entity {
	en: string = "";
	fr: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.en = E5Entity.AssignString(json.en);
		this.fr = E5Entity.AssignString(json.fr);
	}

	//E5
	CopyToJson(): any {
		return {en: this.en, fr: this.fr};
	}
}
