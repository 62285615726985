import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5TextIndic, E5TextIndicUnitEnum } from "../../../global/component/E5TextIndic";
import { E5StoreHIndicGlobal } from "../../../store/E5StoreH";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5StoreLangInfo } from "../../../store/E5Store";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Text } from "../../../util/E5Text";
//
import "./E5HDashboardIndic.css";

//E5
interface E5HDashboardIndicState { }

//E5
interface E5HDashboardIndicProps {
	langinfo: E5StoreLangInfo;
	indicglobalinfo: E5StoreHIndicGlobal
}

//E5
export const E5HDashboardIndic = observer(class E5HDashboardIndic extends React.PureComponent
	<E5HDashboardIndicProps, E5HDashboardIndicState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let {
			globalhealth, wifihealth, wanhealth, syshealth, devices
		} = this.props.indicglobalinfo;;

		let globalhealthstr: string = globalhealth === undefined ?
			"" : globalhealth === null ? "N/A" : E5Text.ScoreToPercent(globalhealth) + "%",
			wifihealthstr: string = wifihealth === undefined ?
				"" : wifihealth === null ? "N/A" : E5Text.ScoreToPercent(wifihealth) + "%",
			wanhealthstr: string = wanhealth === undefined ?
				"" : wanhealth === null ? "N/A" : E5Text.ScoreToPercent(wanhealth) + "%",
			systemhealthstr: string = syshealth === undefined ?
				"" : syshealth === null ? "N/A" : E5Text.ScoreToPercent(syshealth) + "%";

		let healthtipjsx: JSX.Element = <div>
			<div><b>{E5UtilI18n._("h-dashboard-indic-health-global")} : {globalhealthstr}</b></div>
			<BP.Divider />
			<div>{E5UtilI18n._("h-dashboard-indic-health-wifi")} : {wifihealthstr}</div>
			<BP.Divider />
			<div>{E5UtilI18n._("h-dashboard-indic-health-wan")} : {wanhealthstr}</div>
			<BP.Divider />
			<div>{E5UtilI18n._("h-dashboard-indic-health-system")} : {systemhealthstr}</div>
		</div>;

		let { ext, unsureextstb } = this.props.indicglobalinfo,
			extvalstr: string | undefined = ext === undefined ? undefined : "" + ext;
		if (unsureextstb) {
			extvalstr += " ?";
		}

		return <div className="e5compo e5wifih-dashboard-indic e5line-0">
			<E5TextIndic valuestr={globalhealthstr} imageurl="/img/v3/nodes_and_devices/heart-with-border.svg" tooltip={healthtipjsx}
				label={E5UtilI18n._("h-indic-health")} unittype={E5TextIndicUnitEnum.score} />
			{E5MainConfig.GetExtEnabled() && <E5TextIndic
				valuestr={extvalstr} imageurl="/img/v3/nodes_and_devices/ext-with-border.svg"
				label={E5UtilI18n._("wifih-indic-ext")} unittype={E5TextIndicUnitEnum.count}
				tooltip={E5UtilI18n._("wifih-indic-ext-tip")} />}
			{E5MainConfig.GetWifiEnabled() && <E5TextIndic
				tooltip={E5UtilI18n._("wifih-indic-dev-tip")}
				value={devices} imageurl="/img/v3/nodes_and_devices/station-with-border.svg"
				label={E5UtilI18n._("wifih-indic-dev")} unittype={E5TextIndicUnitEnum.count} />}
		</div>;
	}
});
