import React from "react";
import { observer } from "mobx-react";

import { E5StoreHIndicWifi, E5StoreHSearchNi } from "../../../store/E5StoreH";
import { E5TextIndic, E5TextIndicUnitEnum } from "../../../global/component/E5TextIndic";
import { E5PieChart } from "../../../global/plot/E5PieChart";

import { E5StoreLangInfo } from "../../../store/E5Store";

import "./E5HMetricWifiOverview.css";
import { E5EntHWifiTime } from "../../../entity/household/E5EntHWifiTime";
import { E5BandEnum } from "../../../entity/E5Enums";
import { E5UtilI18n } from "../../../global/E5MainLang";

//E5

//E5
interface E5HMetricWifiOverviewProps {
    searchniinfo: E5StoreHSearchNi;
    wifiinfo: E5StoreHIndicWifi;
    langinfo: E5StoreLangInfo;
}

//E5
export const E5HMetricWifiOverview: React.FC<E5HMetricWifiOverviewProps> = observer((props) => {

    const { searchniinfo, wifiinfo, langinfo } = props;//eslint-disable-line

    let { _ } = E5UtilI18n, { status, wifitimes, chantimes } = props.wifiinfo;//eslint-disable-line

    let bandset: Set<string> = new Set(), bandstdset: Set<string> = new Set();
    let pieids: string[] = ["WiFi"], pieparents: string[] = [""], pievalues: number[] = [0];
    let pielabels: string[] = ["WiFi"], pietitle = _("wifih-health-pietitle");
    let idx: number, band: string, standard: string, bandwidth: string, key: string, bandstd: string;

    let pie2ids: string[] = ["WiFi"], pie2parents: string[] = [""], pie2values: number[] = [0];
    let pie2labels: string[] = ["WiFi"];

    chantimes.forEach(value => {
        pie2ids.push(value.band)
        pie2values.push(0)
        pie2parents.push('WiFi')
        pie2labels.push(value.band)

        value.chantime.forEach((v, key) => {
            pie2ids.push(`${value.band}:${key}`)
            pie2values.push(v)
            pie2parents.push(value.band)
            pie2labels.push(`Channel ${key}`)
        })

    })

    for (idx = 0; idx < wifitimes.length; idx++) {
        let item: E5EntHWifiTime = wifitimes[idx];
        let iseth: boolean = [E5BandEnum.eth, E5BandEnum.eth100, E5BandEnum.eth1000].includes(item.band);

        if (!iseth) {
            band = item.band;
            standard = item.standard;
            bandwidth = item.bandwidth;

            if (bandwidth === "") bandwidth = "N/A";
            if (standard === "") standard = "N/A";
            if (band === "") band = "?band";
            key = band + ":" + standard + ":" + bandwidth;
            bandstd = band + ":" + standard;

            if (!bandstdset.has(bandstd)) {
                bandstdset.add(bandstd);

                if (!bandset.has(band)) {
                    bandset.add(band);
                    pieids.push(band);
                    pielabels.push(band);
                    pieparents.push("WiFi");
                    pievalues.push(0);
                }
                pieids.push(bandstd);
                pielabels.push(standard);
                pieparents.push(band);
                pievalues.push(0);
            }

            pieids.push(key);
            pielabels.push(bandwidth);
            pieparents.push(bandstd);
            pievalues.push(item.duration);
        }
    }

    return <div className="e5wifih-metrics-content e5columnfull e5wifih-metrics-overview">
        <div className="e5wifih-metrics-components e5column-20">
            <div className="e5line-20">
                <div className="e5linefull e5column-20">
                    <div className="e5wifih-metrics-links e5column-20">
                        <div className="e5compo e5wifih-metrics-indic e5line-0">
                            <E5TextIndic value={wifiinfo.averagetimedev} unittype={E5TextIndicUnitEnum.seconds}
                                imageurl="/img/v3/metrics/time.svg" label={_("wifih-indic-timeperdev")}
                                tooltip={_("wifih-indic-timeperdev-tip")} />
                            <E5TextIndic value={wifiinfo.totaltime} unittype={E5TextIndicUnitEnum.seconds}
                                imageurl="/img/v3/metrics/time.svg" label={_("wifih-indic-totaltime")}
                                tooltip={_("wifih-indic-totaltime-tip")} />
                        </div>
                        <div className="e5line-20">
                            <E5PieChart pieinfo={{
                                title: pietitle, ids: pieids, parents: pieparents, values: pievalues, labels: pielabels,
                                loading: wifiinfo.status.loading, valueisseconds: true, labelisincident: false
                            }} withNewComponent chartOption={{ type: 'sunburst', parentId: 'WiFi' }} />
                            <E5PieChart pieinfo={{
                                title: _("wifih-health-chanconntime"), ids: pie2ids, parents: pie2parents, values: pie2values, labels: pie2labels,
                                loading: wifiinfo.status.loading, valueisseconds: true, labelisincident: false
                            }} withNewComponent chartOption={{ type: 'sunburst', parentId: 'WiFi' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
});
