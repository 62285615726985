import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5BulletGauge} from "../../../global/plot/E5BulletGauge";
import {E5BulletInfo} from "../../../request/E5ServiceCommon";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestBulletGauge.css";

//E5
interface E5TestBulletGaugeState {
	values: (number|null)[];
}

//E5
interface E5TestBulletGaugeProps {}

//E5
export const E5TestBulletGauge = observer(class E5TestBulletGauge extends React.PureComponent
	<E5TestBulletGaugeProps, E5TestBulletGaugeState> {

	// ---------------- MEMBERS ----------------

	labels: string[] = ["CPU", "Memory", "Temperature", "Flash", "Process"];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestBulletGaugeProps, state: E5TestBulletGaugeState) {
		super(props, state);
		this.state = {values: []};
	}

	//E5
	componentDidMount() {
		this.Refresh();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		let onebullet: E5BulletInfo[] = [], sixbullets: E5BulletInfo[] = [], bullet: E5BulletInfo, idx: number;
		for (idx = 0; idx < this.labels.length; idx++) {
			bullet = {value: this.state.values[idx], label: this.labels[idx], unit: "percent"};
			if (idx === 0) onebullet.push(bullet);
			sixbullets.push(bullet);
		}
		//
		return <div className="e5testpage e5test-bullet-gauge e5columnfull e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-bullet-gauge-long")}</BP.H3>
			<BP.Callout title="Refresh">
				<BP.Button onClick={this.Refresh} icon="refresh"/>
			</BP.Callout>
			<div className="e5line-20">
				<E5BulletGauge gaugeinfo={{bullets: onebullet, title: "One bullet gauge", loading: false}}/>
				<E5BulletGauge gaugeinfo={{bullets: sixbullets, title: "Multiple bullet gauges", loading: false}}/>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Refresh = (): void => {
		let values: (number|null)[] = [], idx: number;
		for (idx = 0; idx < this.labels.length; idx++)
			values.push(Math.random() < 0.25 ? null : Math.random() * 100);
		this.setState({values: values});
	};
});
