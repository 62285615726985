import { E5MainConfig } from "../global/E5MainConfig";
import { E5Storage } from "../util/E5Storage";
import { requestApi } from "./E5Service";

interface User {
    "lastName": "string",
    "firstName": "string",
    "username": "string",
    "email": "string",
    "company": "string",
    "jobTitle": "string",
    "phoneNumber": "string",
    "roles": Array<{[key: string]: string}>,
    "lastSuccessfulLogin": "string",
    "accountExpirationDate": "string",
    "enabled": boolean,
    "accountNonExpired": boolean,
    "credentialsNonExpired": boolean,
    "accountNonLocked": boolean,
    "aclGroups": Array<object>,
    [key: string]: any, // Add index signature
}

export interface PasswordInterface {
    currentPassword: string;
    newPassword: string;
}

export async function getUser(username: string): Promise<User> {
    const bearer = E5Storage.GetLSString(E5MainConfig.ls_bearer);
    if (bearer) {
        requestApi().defaults.headers['Authorization'] = bearer;
    }

    return requestApi().get(`/v4/users`)
        .then((response) => {
            const users = response.data;
            const filteredUser = users.data.find((user: User) => user.username === username);
            return filteredUser;
        });
}

export async function getUserProfilePicture(username: string): Promise<any> {
    const bearer = E5Storage.GetLSString(E5MainConfig.ls_bearer);
    if (bearer) {
        requestApi().defaults.headers['Authorization'] = bearer;
    }

    return requestApi().get(`/v3/user/${username}/image`, {
        responseType: 'arraybuffer'
    });
}

export async function updateUserProfile(username: string, data: any): Promise<void> {
    return requestApi().post(`/v3/user/${username}/image`, data);
}

export async function updateUserProfileData(username: string, data: Partial<User>): Promise<void> {
    const dataToApi = Object.keys(data).map((key) => {
        return {
            "op": "replace",
            "path": `/${key}`,
            "value": data[key]
        };
    });

    return requestApi().patch(`/v4/users/${username}`, dataToApi, {
        headers: {
            'Content-Type': 'application/json-patch+json'
        }
    });
}

export async function updateUserPassword(username: string, data: PasswordInterface): Promise<void> {
    return requestApi().put(`/v4/users/${username}/password`, data);
}

export async function logout(): Promise<void> {
    return requestApi().post(`/v2/logout`);
}