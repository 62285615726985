export const DEFAULT_COLORS =['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];

export function incidentsChartOptions(values: [string, number, string, string][], customColor: boolean = false) {
    const xAxisData = values?.map((value) => `${value[0]}\n${value[2]}`);
    const seriesData = values?.map((value, index) => {
        return {
            value: Number.isInteger(value[1]) ? value[1] : value[1].toFixed(2),
            itemStyle: {
                color: customColor ? value[3] : DEFAULT_COLORS[index],
            },
        }
    });

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'line',
            },
            formatter: function (params: any) {
                const propId = params[0].axisValueLabel.split('\n')[1];
                const propValue = params[0].axisValueLabel.split('\n')[0];
                return `<span style="display: flex; align-items: center; justify-content: center; ">${propId}</span><span style="float: left; margin-right: 20px">${params[0].marker}${propValue}</span><b>${params[0].data.value}</b>`
            }
        },
        toolbox: {
            right: '4%',
            feature: {
                dataView: {
                    show: true,
                    readOnly: true
                },
                dataZoom: {
                    yAxisIndex: 'none'
                },
                magicType: {
                    show: true,
                    type: ['line', 'bar'],
                    option: { bar: { stack: 'Add' }, line: { stack: '' } }
                },
                restore: {
                    show: true,
                },
                saveAsImage: {}
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisLabel: {
                interval: 0,
                width: 100, //fixed number of pixels
                overflow: 'break', // or 'break' to continue in a new line
            },
            axisPointer: {
                show: true,
                snap: true,
                triggerTooltip: false,
                type: 'line',
                label: {
                    show: false,
                },
            },
        },
        yAxis: {
            type: 'value',
        },
        series: [{
            type: 'bar',
            data: seriesData,
        }],
    }
};
