import {E5BandEnum} from "../../E5Enums";
import {E5EntHAnom} from "./E5EntHAnom";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHAnomWifi extends E5EntHAnom {
	imei: string = "";
	mac: string = "";
	band: E5BandEnum = E5BandEnum.none;
	child: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.imei = E5Entity.AssignString(json.node_imei);
		this.mac = E5Entity.AssignString(json.interface);
		this.band = Object.values(E5BandEnum).includes(json.band) ? json.band : E5BandEnum.none;
		this.child = E5Entity.AssignString(json.child_id);
	}

	//E5
	CopyToJson(): any {
		return {
			...super.CopyToJson(), node_imei: this.imei, interface: this.mac, band: this.band, child_id: this.child
		};
	}
}
