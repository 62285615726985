import { observer } from 'mobx-react';
import React from 'react';
import { E5EntHWanBytes } from '../../../entity/household/E5EntHWanBytes';
import { E5UtilI18n } from '../../../global/E5MainLang';
import { E5Store } from '../../../store/E5Store';
import { E5StoreHIndicWan } from '../../../store/E5StoreH';
import { trafficMetricsChartData, metricWanChartOptions, statusClass, statusText, throughputChartData, MetricChartDataInterface } from '../../../util/E5MetricWanUtils';
import ReactECharts from 'echarts-for-react';

import './E5HMetricWan.css';
import { Spinner } from '@blueprintjs/core';

interface E5HMetricWanProps {
    wanInfo: E5StoreHIndicWan;
}

export const E5HMetricWan = observer((props: Readonly<E5HMetricWanProps>) => {
    // force re render when language changes
    const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

    const download: string = props.wanInfo.totalbytesreceived === undefined ? "N/A" : `${E5EntHWanBytes.BytesToMBytes(props.wanInfo.totalbytesreceived).toFixed(2)} MB`;
    const upload: string = props.wanInfo.totalbytessent === undefined ? "N/A" : `${E5EntHWanBytes.BytesToMBytes(props.wanInfo.totalbytessent).toFixed(2)} MB`;
    const total: string = props.wanInfo.totalbytesreceived === undefined || props.wanInfo.totalbytessent === undefined ? "N/A" :
        `${E5EntHWanBytes.BytesToMBytes(props.wanInfo.totalbytesreceived + props.wanInfo.totalbytessent).toFixed(2)} MB`;

    const throughputData = throughputChartData(props.wanInfo.bytes);
    const dnsResolutionChart = trafficMetricsChartData(props.wanInfo.dns);
    const pingPerformanceChart = trafficMetricsChartData(props.wanInfo.ping);

    const chartsDisplay = (data: MetricChartDataInterface[], title: string, metric: string) => {
        if (props.wanInfo.status.loading) {
            return (
                <div className="e5-main-content-box mb-3">
                    <p className="e5-chart-title">{title}</p>

                    <div className="e5-align-center chart-height">
                        <Spinner size={50} />
                    </div>
                </div>
            )
        } else if (data.length === 0) {
            return (
                <div className="e5-main-content-box mb-3">
                    <p className="e5-chart-title">{title}</p>

                    <div className="e5-align-center chart-height">
                        <p className="no-data">{E5UtilI18n._(props.wanInfo.status.loading ? "wificb-dashboard-notfound" : "no-data-collected")}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="e5-main-content-box mb-3">
                    <ReactECharts option={metricWanChartOptions(data, title, metric)} />
                </div>
            )
        }
    }

    return (
        <div className="e5h-metric-wan">
            <div className="e5-main-content-box mb-3">
                <div className="mb-3 align-content-center">
                    <p className="e5-text-color mr-3">{E5UtilI18n._("wifih-health-wan-interf-name")}</p>
                    <p className="e5-bold-text-color">{props.wanInfo.interfname}</p>
                </div>

                <div className="e5-inside-box-top-rounded">
                    <div className="e5-grid-two-columns">
                        <div className="align-content-center">
                            <div className="w-100">
                                <div className="content-box mb-2">
                                    <p className="e5-text-color mr-3">{E5UtilI18n._("wifih-health-wan-interf-type")}</p>
                                    <p className="e5-bold-text-color">{props.wanInfo.interftype}</p>
                                </div>

                                <div className="content-box">
                                    <p className="e5-text-color mr-3">{E5UtilI18n._("admin-rdm-statusid")}</p>
                                    <p className={`${props.wanInfo.wanstatus ? statusClass(props.wanInfo.wanstatus) : ''}`}>{props.wanInfo.wanstatus ? statusText(props.wanInfo.wanstatus) : null}</p>
                                </div>
                            </div>
                        </div>

                        <div className="align-content-center">
                            <div className="w-100">
                                <div className="content-box mb-2">
                                    <p className="e5-text-color mr-3">{E5UtilI18n._("wifih-health-wan-interf-totalbytesreceived")}</p>
                                    <p className="e5-bold-text-color">{download}</p>
                                </div>

                                <div className="content-box mb-2">
                                    <p className="e5-text-color mr-3">{E5UtilI18n._("wifih-health-wan-interf-totalbytessent")}</p>
                                    <p className="e5-bold-text-color">{upload}</p>
                                </div>

                                <div className="content-box">
                                    <p className="e5-text-color mr-3">{E5UtilI18n._("wifih-health-wan-interf-totalbytessum")}</p>
                                    <p className="e5-bold-text-color">{total}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="e5-inside-box-bottom-rounded">
                    <div className="e5-grid-two-columns">
                        <div className="align-content-center">
                            <div className="w-100">
                                <div className="mb-2">
                                    <p className="e5-text-color e5-underline-text">IPv4 ({props.wanInfo.ipv4addrs.length})</p>
                                </div>

                                {
                                    props.wanInfo.ipv4addrs.map((ip) => {
                                        return (
                                            <div className="mb-2" key={ip}>
                                                <p className="e5-bold-text-color mb-2">{ip}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="align-content-center">
                            <div className="w-100">
                                <div className="mb-2">
                                    <p className="e5-text-color e5-underline-text">IPv6 ({props.wanInfo.ipv6addrs.length})</p>
                                </div>

                                {
                                    props.wanInfo.ipv6addrs.map((ip) => {
                                        return (
                                            <div className="mb-2" key={ip}>
                                                <p className="e5-bold-text-color mb-2">{ip}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {chartsDisplay(throughputData, E5UtilI18n._("wifih-health-wan-interf-bytes"), 'Mb/s')}

            {chartsDisplay(dnsResolutionChart, E5UtilI18n._("wifih-metric-dns-resolution"), 'ms')}

            {chartsDisplay(pingPerformanceChart, E5UtilI18n._("wifih-metric-ping-wan-performance"), 'ms')}
        </div>
    )
});
