import {E5EntHIncidSys} from "./E5EntHIncidSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidSysProc extends E5EntHIncidSys {
	procname: string = "";
    coredump: string = "";
	pid: number[] = [];
	priority: number|null|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		let idx: number;
		super.CopyFromJson(json);
		this.procname = E5Entity.AssignString(json.process_name);
		this.coredump = E5Entity.AssignString(json.core_dump);

		this.pid = [];
		if (Array.isArray(json.pid)) for (idx = 0; idx < json.pid.length; idx++)
			this.pid.push(E5Entity.ParseInt(json.pid[idx]));

		this.priority = E5Entity.ParseIntNull(json.priority);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), process_name: this.procname, core_dump: this.coredump, pid: this.pid, priority: this.priority};
	}
}
