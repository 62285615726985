import React from "react";
import moment from "moment";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5EntHTopology, E5TopoLink} from "../../../entity/household/topo/E5EntHTopology";
import {E5BandEnum, E5NetElementType, E5NodeTypeEnum} from "../../../entity/E5Enums";
import {E5EntHStation} from "../../../entity/household/topo/E5EntHStation";
import {E5StoreH, E5StoreHTopoSelected} from "../../../store/E5StoreH";
import {E5EntHEquip} from "../../../entity/household/topo/E5EntHEquip";
import {E5HTopology} from "../../household/connectivity/E5HTopology";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Store} from "../../../store/E5Store";
//
import "./E5TestTopoGraph.css";

//E5
interface E5TestTopoGraphState {
	nbext: number;
	nbdevpern: number;
	gateway: E5EntHEquip;
}

//E5
interface E5TestTopoGraphProps {
	selectedinfo: E5StoreHTopoSelected;
}

//E5
export const E5TestTopoGraph = observer(class E5TestTopoGraph extends React.PureComponent
	<E5TestTopoGraphProps, E5TestTopoGraphState> {

	// ---------------- MEMBERS ----------------

	//E5
	deviceindex: number = 0;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5TestTopoGraphProps, state: E5TestTopoGraphState) {
		super(props, state);
		this.state = {nbext: 2, nbdevpern: 2, gateway: this.GetNodeEntity(E5NodeTypeEnum.gw)};
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let exts: E5EntHEquip[] = [], devices: E5EntHStation[] = [];
		for (let idx = 0; idx < this.state.nbext; idx++)
			exts.push(this.GetNodeEntity(idx % 2 === 0 ? E5NodeTypeEnum.ext : E5NodeTypeEnum.stb));

		for (let idx = 0; idx < (this.state.nbext + 1) * this.state.nbdevpern; idx++)
			devices.push(new E5EntHStation(
				{device_type: this.GetRandomDeviceType(), mac_address: E5TestTopoGraph.GetFakeMacAddress()}));

		return <div className="e5testpage e5test-topo-graph e5fullheight e5column-20">
			<BP.H3>{E5UtilI18n._("mainmenu-test-topo-graph-long")}</BP.H3>
			<div className="e5line-20">
				<BP.Callout title="Selected">
					<div className="e5line-20">
						<BP.Button onClick={this.SelectGW}>Select GW</BP.Button>
						<div>{this.props.selectedinfo.type === E5NetElementType.none ?
							"none" : this.props.selectedinfo.type + " " +
							(this.props.selectedinfo.type === E5NetElementType.node ?
								this.props.selectedinfo.equip.imei : this.props.selectedinfo.station.macaddress)}
						</div>
					</div>
				</BP.Callout>
				<BP.Callout title="Extenders">
					<div className="e5line-20">
						<BP.Button
							disabled={this.state.nbext < 1} text="-" onClick={() => this.UpdateNbExtBy(-1)}/>
						<BP.Button text="+" onClick={() => this.UpdateNbExtBy(+1)}/>
						<BP.H4>{this.state.nbext}</BP.H4>
					</div>
				</BP.Callout>
				<BP.Callout title="Stations connected to each node">
					<div className="e5line-20">
						<BP.Button disabled={this.state.nbdevpern < 1} text="-"
								   onClick={() => this.UpdateNbDevPerNodeBy(-1)}/>
						<BP.Button text="+" onClick={() => this.UpdateNbDevPerNodeBy(+1)}/>
						<BP.H4>{this.state.nbdevpern}</BP.H4>
					</div>
				</BP.Callout>
			</div>
			<div className="e5columnfull">
				<E5HTopology
					stationinfo={{stations: devices, stationmap: new Map()}}
					equipinfo={{equips: [this.state.gateway, ...exts], equipmap: new Map()}}
					langinfo={E5Store.Ins().langinfo} selectedinfo={E5StoreH.Ins().selectedinfo}
					connectivityinfo={{loading: false, rssipersta: [], topologies: this.GetTopologies(exts, devices)}}
				/>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	UpdateNbExtBy = (amount: number): void =>
		this.setState({nbext: amount < 0 && this.state.nbext < -amount ? 0 : this.state.nbext + amount});

	//E5
	UpdateNbDevPerNodeBy = (amount: number): void => this.setState(
		{nbdevpern: amount < 0 && this.state.nbdevpern < -amount ? 0 : this.state.nbdevpern + amount});

	//E5
	SelectGW = () => E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.node, this.state.gateway, new E5EntHStation());

	// ---------------- UTILS ----------------

	//E5
	GetTopologies = (exts: E5EntHEquip[], devices: E5EntHStation[]): E5EntHTopology[] => {
		let topologies: E5EntHTopology[] = [], devcnt: number = 0;

		// extenders to gateway
		for (let idx = 0; idx < exts.length; idx++)
			topologies.push(new E5EntHTopology({
				child: exts[idx].imei, is_backhaul: true, parent: this.state.gateway.imei, links: this.GetLinks(),
				connectivities: []
			}));

		// devices to gateway
		for (let idx = 0; idx < this.state.nbdevpern; idx++) {
			topologies.push(new E5EntHTopology({
				child: devices[devcnt].macaddress, is_backhaul: false, parent: this.state.gateway.imei,
				links: this.GetLinks(), connectivities: []
			}));
			devcnt++;
		}

		// devices to extenders
		for (let idx = 0; idx < exts.length; idx++) for (let idx2 = 0; idx2 < this.state.nbdevpern; idx2++) {
			topologies.push(new E5EntHTopology({
				child: devices[devcnt].macaddress, is_backhaul: false, parent: exts[idx].imei,
				links: this.GetLinks(), connectivities: []
			}));
			devcnt++;
		}

		return topologies;
	};

	//E5
	GetLinks = (): E5TopoLink[] => {
		let links: E5TopoLink[] = [], cut: number = Math.ceil(Math.random() * 20),
			bands: string[] = Object.values(E5BandEnum);
		links.push(new E5TopoLink({
			band: bands[Math.floor(Math.random() * bands.length)], start: moment().startOf("day").unix(),
			end: moment().startOf("day").add(cut, "hours").unix(), rssi: Math.random() * 40 - 90
		}));
		links.push(new E5TopoLink({
			band: bands[Math.floor(Math.random() * bands.length)], rssi: Math.random() * 40 - 90,
			start: moment().startOf("day").add(cut, "hours").unix()
		}));
		return links;
	};

	//E5
	GetNodeEntity = (nodetype: E5NodeTypeEnum): E5EntHEquip => {
		let imei: string = "imeiGW";
		if (nodetype !== E5NodeTypeEnum.gw) imei = E5TestTopoGraph.GetFakeImei();
		return new E5EntHEquip({
			imei: imei, node_type: nodetype, interfaces: [
				{band: "ETH", mac_address: E5TestTopoGraph.GetFakeMacAddress(), status: "up"},
				{band: "ETH100", mac_address: E5TestTopoGraph.GetFakeMacAddress(), status: "up"},
				{band: "ETH1000", mac_address: E5TestTopoGraph.GetFakeMacAddress(), status: "up"},
				{band: "2_4GHZ", mac_address: E5TestTopoGraph.GetFakeMacAddress(), status: "down"},
				{band: "5GHZ", mac_address: E5TestTopoGraph.GetFakeMacAddress(), status: "up"},
				{band: "6GHZ", mac_address: E5TestTopoGraph.GetFakeMacAddress(), status: "up"},
				{band: "", mac_address: E5TestTopoGraph.GetFakeMacAddress(), status: "down"}
			]
		});
	};

	//E5
	static GetFakeImei: () => string = (): string =>
		"XXXXXXXXXXXXXXX".replace(/X/g, (): string => "" + Math.floor(Math.random() * 10));

	//E5
	static GetFakeMacAddress: () => string = (): string =>
		"XX:XX:XX:XX:XX:XX".replace(/X/g, (): string =>
			"0123456789ABCDEF".charAt(Math.floor(Math.random() * 16)));

	//E5
	GetRandomDeviceType = (): string => {
		let vals: string[] = [
			"phone", "pc", "smartphone", "null", "smart tv", "computer", "printer", "stb", "hidden", "router",
			"gameconsole", "tv_decoder", "audiovideo", "plc", "wifi_bridge", "network_storage", "BLACKLISTED",
			"IP phone", "FEMTO", "UNIK", "WIFI_REPEATER", "router", "PERIPHERAL", "MISCELLANEOUS", "NETWORKACCESSPOINT",
			"IMAGING", "TABLET", "NAS", "Multimedia device", "Game console", "DVR", "Camera", "eBook reader",
			"Unknown", "PERIPHERAL", "MISCELLANEOUS", "MOBILE_PHONE"
		];
		let val: string = vals[this.deviceindex];
		this.deviceindex++;
		if (this.deviceindex >= vals.length) this.deviceindex = 0;
		return val;
	};
});
