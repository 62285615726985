// Request data
//E5
export interface E5RestRequest {
	method: string; // POST or GET
	uri: string; // something like "/GetList"
	header: any; // something like [["Content-Type", "application/json"],[...]]
	body: any;
	otherdata?: any;
}

// Fetch result
//E5
export enum E5RestFetchResult {
	ReceiveUnknown = "FetchUnknwon",
	ReceiveOK = "FetchOk",
	ReceiveERR = "FetchErr",
	ReceiveEX = "FetchException"
}

// Class that will be called when response arrives
//E5
export class E5RestResponse {
	fetchresult: E5RestFetchResult;
	request: E5RestRequest;
	message: string;
	body: any;
	respheader: any;
	status: number;

	constructor(fetchresult: E5RestFetchResult, request: E5RestRequest, message: string,
		body: any, respheader: any, status: number) {
		this.fetchresult = fetchresult;
		this.request = request;
		this.message = message;
		this.body = body;
		this.respheader = respheader;
		this.status = status;
	}

	//E5
	FindRespHeader(key: string): string {
		let ret: string = "";
		for (let item of this.respheader) {
			if (item[0] === key) {
				ret = item[1];
				break;
			}
		}
		return ret;
	}
}

// Responder interface
export interface E5RestResponder {
	// only one setState in this function
	RequestCallback(resp: E5RestResponse): void;
}


//E5
export class E5RestSender {
	responder: E5RestResponder;
	canceled: Map<number, boolean>;
	serverurl: string;

	//E5
	constructor(responder: E5RestResponder, serverurl: string) {
		this.responder = responder;
		this.serverurl = serverurl;
		this.canceled = new Map();
	}

	//E5
	Send(req: E5RestRequest, callback?: Function): number {
		if (callback) {
			callback()
		}
		let debug: boolean = false;
		let cursent: boolean = false;
		let curtoken: number = Date.now();//milliseconds
		let cursrv: E5RestSender = this;
		let url: string = this.serverurl + req.uri;
		let resp: E5RestResponse = new E5RestResponse(
			E5RestFetchResult.ReceiveUnknown,
			req,
			"",
			{ authorized: false, success: false, message: "", messageargs: [] },
			[],
			0
		);
		fetch(url, {
			method: req.method,
			body: (req.body === undefined ? undefined : req.otherdata?.multipart ? req.body : JSON.stringify(req.body)),
			headers: new Headers(req.header),
			credentials: "include"
		}).then(result => {
			if (debug)
				console.log("then");
			for (let entry of result.headers.entries())
				resp.respheader.push(entry);
			resp.status = result.status;
			if (result.ok) {
				if (debug)
					console.log("result.ok");
				return result.json().catch(ex => { return {}; });
			} else {
				if (debug)
					console.log("result.notok");
				resp.fetchresult = E5RestFetchResult.ReceiveERR;
				resp.message = "status:" + result.status;
				return result.json().catch(ex => { return {}; });
			}
		},
			error => {
				if (debug)
					console.log("error");
				if (!cursent && this.canceled.get(curtoken) === undefined) {
					cursent = true;
					resp.fetchresult = E5RestFetchResult.ReceiveERR;
					resp.message = error.toString();
					cursrv.responder.RequestCallback(resp);
				}
			}
		).then(data => {
			if (data.error) {
				if (callback) {
					callback(data.error);
				}
			}
			if (debug)
				console.log("then:" + data);
			if (!cursent && this.canceled.get(curtoken) === undefined) {
				cursent = true;
				if (resp.fetchresult !== E5RestFetchResult.ReceiveUnknown)
					resp.fetchresult = E5RestFetchResult.ReceiveOK;
				resp.body = data;
				cursrv.responder.RequestCallback(resp);
			}
		}).catch(ex => {
			if (debug)
				console.log("exception");
			console.log(ex);
			if (!cursent && this.canceled.get(curtoken) === undefined) {
				cursent = true;
				resp.fetchresult = E5RestFetchResult.ReceiveEX;
				resp.message = ex.toString();
				cursrv.responder.RequestCallback(resp);
			}
		});
		return curtoken;
	}

	//E5
	SendAndDownload(req: E5RestRequest): number {
		let cursent: boolean = false;
		let curtoken: number = Date.now();//milliseconds
		let cursrv: E5RestSender = this;
		let url: string = this.serverurl + req.uri;
		let resp: E5RestResponse = new E5RestResponse(
			E5RestFetchResult.ReceiveUnknown,
			req,
			"",
			{ authorized: false, success: false, message: "", messageargs: [] },
			[],
			0
		);
		let filename: string = "download.csv";
		fetch(url, {
			method: req.method,
			body: (req.body === undefined ? undefined : JSON.stringify(req.body)),
			headers: new Headers(req.header),
			credentials: "include"
		}).then(result => {
			for (let entry of result.headers.entries())
				resp.respheader.push(entry);
			let contentdisposition: string | null = result.headers.get("content-disposition");
			if (contentdisposition !== null)
				filename = contentdisposition.split("\"")[1];
			resp.status = result.status;
			if (result.ok) {
				return result.blob().catch(ex => { return {}; });
			} else {
				resp.fetchresult = E5RestFetchResult.ReceiveERR;
				resp.message = "status:" + result.status;
				return result.json().catch(ex => { return {}; });
			}
		},
			error => {
				if (!cursent && this.canceled.get(curtoken) === undefined) {
					cursent = true;
					resp.fetchresult = E5RestFetchResult.ReceiveERR;
					resp.message = error.toString();
					cursrv.responder.RequestCallback(resp);
				}
			}
		).then(data => {
			if (!cursent && this.canceled.get(curtoken) === undefined) {
				cursent = true;
				if (resp.fetchresult !== E5RestFetchResult.ReceiveERR) {
					if (resp.fetchresult !== E5RestFetchResult.ReceiveUnknown)
						resp.fetchresult = E5RestFetchResult.ReceiveOK;
					let url = window.URL.createObjectURL(data);
					let link: HTMLAnchorElement = document.createElement("a");
					link.href = url;
					link.download = filename;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				}
				cursrv.responder.RequestCallback(resp);
			}
		}).catch(ex => {
			console.log(ex);
			if (!cursent && this.canceled.get(curtoken) === undefined) {
				cursent = true;
				resp.fetchresult = E5RestFetchResult.ReceiveEX;
				resp.message = ex.toString();
				cursrv.responder.RequestCallback(resp);
			}
		});
		return curtoken;
	}

	//E5
	Cancel(token: number | undefined): void {
		if (token !== undefined) {
			this.canceled.set(token, true);
		}
	}


}