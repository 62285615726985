import {E5Entity} from "../E5Entity";

//E5
export class E5EntRdmStatus extends E5Entity {

	//E5
	id: number = 0;
	name: string = "";
	description: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.id = E5Entity.ParseInt(json.statusId);
		this.name = E5Entity.AssignString(json.statusName);
		this.description = E5Entity.AssignString(json.statusDescription);
	}

	//E5
	CopyToJson(): any {
		return {statusId: this.id, statusName: this.name, statusDescription: this.description};
	}
}
