import moment, {Moment} from "moment";
//
import {E5Time} from "../util/E5Time";
import {E5Entity} from "./E5Entity";

//E5
export class E5EntAclGroup extends E5Entity {
	creationdate: Moment|null = null;
	default: boolean = false;
	type: string = "";
	id: number|null = null;
	listnetid: string = "";
	name: string = "";
	updatedate: Moment|null = null;
	updatedbyuser: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.creationdate = moment(json.creationDate).utcOffset(E5Time.h_tzoffset * 60);

		this.default = json.defaultGroup === true;
		this.type = E5Entity.AssignString(json.groupType);
		this.id = E5Entity.ParseIntNull(json.id);
		this.listnetid = E5Entity.AssignString(json.listNetworkId);
		this.name = E5Entity.AssignString(json.name);

		this.updatedate = moment(json.updateDate).utcOffset(E5Time.h_tzoffset * 60);

		this.updatedbyuser = E5Entity.AssignString(json.updatedByUsername);
	}

	//E5
	CopyToJson(): any {
		return {
			creationDate: this.creationdate?.unix() ?? null, defaultGroup: this.default, groupType: this.type,
			id: this.id, listNetworkId: this.listnetid, name: this.name, updateDate: this.updatedate?.unix() ?? null,
			updatedByUsername: this.updatedbyuser
		};
	}

	//E5
	CopyToCreate = (): any => ({
		defaultGroup: this.default, name: this.name.trim(), listNetworkId: this.listnetid === "" ||
		this.listnetid === "*" || this.listnetid.includes(",") ? this.listnetid : this.listnetid + ","
	});
}
