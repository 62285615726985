
import * as BP from "@blueprintjs/core";
import * as BPT from "@blueprintjs/table";
import React, { useState } from "react";
import { E5UtilI18n } from "../../../global/E5MainLang";
import styles from './UpdateSelectedUsersModal.module.css';
import { E5AdminUserSaveSend, E5AdminUsersAction } from "./E5AdminUsersList";
import { E5EntUser } from "../../../entity/E5EntUser";
import Button from "../../../global/component/Button";
interface IObjectKeys {
    [key: string]: any;
}
type componentProps = {
    saveFunction: Function,
    updateAction: E5AdminUsersAction.update,
    users: E5EntUser[],

    onClose: () => void;
    usersEmails: string[]
};
interface Roles extends IObjectKeys {
    ROLE_L1: boolean,
    ROLE_L2: boolean,
    ROLE_L3: boolean,
    ROLE_RDMANAGER: boolean,
    ROLE_ADMIN: boolean,
    ROLE_ANALYST: boolean,
    ROLE_MANAGER: boolean,
};

interface Statuses extends IObjectKeys {
    enabled: boolean,
    accountnonexpired: boolean,
    credentialsnonexpired: boolean,
    accountnonlocked: boolean,

}

const UpdateSelectedUsersModal = ({ saveFunction, updateAction, users, onClose, usersEmails }: componentProps) => {
    interface E5AdminUsersListProps {
        savefunc: E5AdminUserSaveSend;
    }

    const [rolesStatus, setRolestatus] = useState<Roles>({
        ROLE_L1: false,
        ROLE_L2: false,
        ROLE_L3: false,
        ROLE_RDMANAGER: false,
        ROLE_ADMIN: true,
        ROLE_ANALYST: false,
        ROLE_MANAGER: false
    });
    const [accauntStatus, setAccauntSatuses] = useState<Statuses>({
        enabled: true,
        accountnonexpired: false,
        credentialsnonexpired: false,
        accountnonlocked: false,
    })
    const roleslistfilter: string[] =
        ["ROLE_ADMIN", "ROLE_ANALYST", "ROLE_MANAGER", "ROLE_RDMANAGER", "ROLE_L1", "ROLE_L2", "ROLE_L3"];
    const statuses: string[] = ['enabled', 'accountnonexpired', 'credentialsnonexpired', 'accountnonlocked'];

    const statusesNamings: any = {
        enabled: 'Enabled',
        accountnonexpired: 'Account Expired',
        credentialsnonexpired: 'Credentials expired',
        accountnonlocked: 'Account Locked',
    }
    const changeSelectedRole = (role: string) => {
        setRolestatus((prev) => ({ ...prev, [role]: !rolesStatus[role] }))
    }
    const changeSelectedStatuses = (status: string) => {
        setAccauntSatuses((prev) => ({ ...prev, [status]: !accauntStatus[status] }))
    }

    const updateUser = async () => {
        usersEmails.forEach((email) => {

            const userForUpdate = users.find((user) => user.email === email);

            const roles: string[] = [];
            for (const role in rolesStatus) {
                if (rolesStatus[role] === true) {
                    roles.push(role)
                }

            };
            userForUpdate.roles = roles;
            userForUpdate.enabled = accauntStatus.enabled;
            userForUpdate.accountnonexpired = !accauntStatus.accountnonexpired;
            userForUpdate.credentialsnonexpired = !accauntStatus.credentialsnonexpired;
            userForUpdate.accountnonlocked = !accauntStatus.accountnonlocked;
            saveFunction(updateAction, userForUpdate, userForUpdate.username, () => onClose())
        })

    }
    return <BP.Dialog isOpen={true} canOutsideClickClose={false} canEscapeKeyClose={false} className="update-user-modal"
        title={'Update the selected user'}
        onClose={() => onClose()}>
        <div className="admin-user-update-many">
            <div className="admin-user-update-many-left">

                <div className={styles.roles}>
                    <p className={styles.rolesHeader}>Roles <span className={styles.roles_question}>?</span></p>
                    {roleslistfilter.map((role) => <BP.Tooltip
                        key={role} boundary="window" content={E5UtilI18n._("admin-users-role-" + role + "_long")}>
                        <BP.Checkbox key={role} inline label={E5UtilI18n._("admin-users-role-" + role)}
                            checked={rolesStatus[role]} value={role}
                            className="'as-radio"
                            onChange={(event: any) => changeSelectedRole(role)} />
                    </BP.Tooltip>)}
                </div>
            </div>

            <div className="admin-user-update-many-right">
                <p className={styles.statusHeader}>Status</p>
                {statuses.map((status) => <BP.Tooltip
                    key={status} boundary="window" content={statusesNamings[status]}>
                    <BP.Checkbox key={status} inline label={statusesNamings[status]}
                        checked={accauntStatus[status]} value={status}
                        onChange={(event: any) => changeSelectedStatuses(status)} />
                </BP.Tooltip>)}
            </div>
        </div>
        <div className={styles.buttonsWrapper}>
            <Button theme="green" onClick={() => updateUser()}>
                Update
            </Button>
            <Button theme="blue" onClick={() => onClose()}>
                Close without saving
            </Button>
        </div>
    </BP.Dialog>
}
export default UpdateSelectedUsersModal;