import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { DEFAULT_CHART_COLORS, E5XYChart, E5XYNumData, E5XYSource } from "../../../../global/plot/E5XYChart";
import { E5CBSysProcMet, E5StoreCBSys } from "../../../../store/E5StoreCBSys";
import { E5AngularGauge } from "../../../../global/plot/E5AngularGauge";
import { E5UtilI18n } from "../../../../global/E5MainLang";
import { E5Store } from "../../../../store/E5Store";
import { E5Text } from "../../../../util/E5Text";
//
import "./E5CBSysProcess.css";
import { E5CBDashboard } from "../E5CBDashboard";
import { E5StoreCB } from "../../../../store/E5StoreCB";

//E5
interface E5CBSysProcessProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
	percent: boolean;
	togglefunc: () => void;
}

//E5
export const E5CBSysProcess = observer(class E5CBSysProcess extends React.PureComponent
	<E5CBSysProcessProps, E5CBSysProcessState> {
	constructor(props: any, state: any) {
		super(props, state);
		this.state = {};
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		const sor = E5CBSysProcess.GetSource("cpu");
		let { _ } = E5UtilI18n, { status, health } = E5StoreCBSys.Ins().process, { loading } = status,
			{ nilist, nilistsettings } = E5StoreCB.Ins(),
			{ mode, downloadstr, date, cla } = nilistsettings;
		return <div className="e5cb-sys-process e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5AngularGauge
						gaugeinfo={{ value: health, label: _("system-proc"), title: 'Process health score', loading }} isNewComponent chartOption={{ type: 'gauge' }} />
				</div>
				<div className="e5linefull2">
					<E5XYChart leftsource={E5CBSysProcess.GetSource("status")} rightsource={{}} height={550}
						title={E5UtilI18n._("cb-sys-process-statusmet")} loading={loading}
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} withNewComponent chartOption={{ filled: true, type: 'bar' }} />
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">{E5CBSysProcess.RenderProcTable("cpu", this.state)}</div>
				<div className="e5linefull2">
					<BP.Popover className="e5linefull e5line-0" targetClassName="e5linefull"
						isOpen={mode === "cpu"} content={<div className="e5cb-dashboard-downloaddiv">
							<div className="title">{downloadstr}</div>
							{nilist.status.loading && <BP.Spinner className="e5spinwait" size={15} />}
							{!nilist.status.loading && <BP.Button
								text={E5UtilI18n._("download")} intent={BP.Intent.PRIMARY} autoFocus
								onClick={() => E5CBDashboard.DownloadNiListProcess("cpu", date, cla, this.props.toasterref)}
								onBlur={() => E5StoreCB.Ins().SetNiListSettings("none", "",
									undefined, undefined, undefined, undefined, undefined,
									undefined, undefined)} ref={this.props.downloadref} />}
						</div>} position={BP.Position.BOTTOM_LEFT}>
						<E5XYChart leftsource={E5CBSysProcess.GetSource("cpu")} rightsource={{}}
							title={E5UtilI18n._("cb-sys-process-cpumet")} loading={loading} height={550} baseYaxis
							xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} withNewComponent chartOption={{ filled: false, legendScroll: true }}
							clickcb={E5CBDashboard.CpuClick} />
					</BP.Popover>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">{E5CBSysProcess.RenderProcTable("mem", this.state)}</div>
				<div className="e5linefull2">
					<BP.Popover className="e5linefull e5line-0" targetClassName="e5linefull"
						isOpen={mode === "mem"} content={<div className="e5cb-dashboard-downloaddiv">
							<div className="title">{downloadstr}</div>
							{nilist.status.loading && <BP.Spinner className="e5spinwait" size={15} />}
							{!nilist.status.loading && <BP.Button
								text={E5UtilI18n._("download")} intent={BP.Intent.PRIMARY} autoFocus
								onClick={() => E5CBDashboard.DownloadNiListProcess("mem", date, cla, this.props.toasterref)}
								onBlur={() => E5StoreCB.Ins().SetNiListSettings("none", "",
									undefined, undefined, undefined, undefined, undefined,
									undefined, undefined)} ref={this.props.downloadref} />}
						</div>} position={BP.Position.BOTTOM_LEFT}>
						<E5XYChart leftsource={E5CBSysProcess.GetSource("mem")} rightsource={{}}
							title={E5UtilI18n._("cb-sys-process-memmet")} loading={loading} height={550} baseYaxis
							xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} withNewComponent chartOption={{ filled: false, legendScroll: true }}
							clickcb={E5CBDashboard.MemClick} />
					</BP.Popover>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">{E5CBSysProcess.RenderProcTable("crash", this.state)}</div>
				<div className="e5linefull2">
					<BP.Popover className="e5linefull e5line-0" targetClassName="e5linefull"
						isOpen={mode === "crash"} content={<div className="e5cb-dashboard-downloaddiv">
							<div className="title">{downloadstr}</div>
							{nilist.status.loading && <BP.Spinner className="e5spinwait" size={15} />}
							{!nilist.status.loading && <BP.Button
								text={E5UtilI18n._("download")} intent={BP.Intent.PRIMARY} autoFocus
								onClick={() => E5CBDashboard.DownloadNiListProcess("crash", date, cla, this.props.toasterref)}
								onBlur={() => E5StoreCB.Ins().SetNiListSettings("none", "",
									undefined, undefined, undefined, undefined, undefined,
									undefined, undefined)} ref={this.props.downloadref} />}
						</div>} position={BP.Position.BOTTOM_LEFT}>
						{
							E5CBSysProcess.GetSource("crash")?.numdatas?.length > 0 ? 
								<E5XYChart leftsource={E5CBSysProcess.GetSource("crash")} rightsource={{}}
									title={'Most intensive process crashes over time'} loading={loading} height={550} baseYaxis
									xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} withNewComponent chartOption={{ filled: false, legendScroll: true }}
									clickcb={E5CBDashboard.CrashClick} />
							: (<div className="e5line-full e5compo most-intensive-no-data">
								<span className="e5compotitle">{'Most intensive process crashes over time'}</span>
								<div className="no-data">
									<span>{E5UtilI18n._("no-data-collected")}</span>
								</div>
							</div>)
						}
					</BP.Popover>
				</div>
			</div>
		</div>;
	}

	//E5
	static RenderProcTable: (key: "cpu" | "mem" | "crash", state: E5CBSysProcessState) => JSX.Element = (key: "cpu" | "mem" | "crash", state: E5CBSysProcessState): JSX.Element => {
		let { _ } = E5UtilI18n, procmap: Map<string, number> | null | undefined, name: string, val: number, title: string,
			valueColTitle: string, procs: [string, number][] = [];

		switch (key) {
			case "cpu":
				procmap = E5StoreCBSys.Ins().process.cpuprocmap;
				title = "cb-sys-process-cpuproc";
				valueColTitle = "cpuval";
				break;
			case "mem":
				procmap = E5StoreCBSys.Ins().process.memprocmap;
				title = "cb-sys-process-memproc";
				valueColTitle = "memval";
				break;
			default:
				procmap = E5StoreCBSys.Ins().process.crashprocmap;
				title = "cb-sys-process-crashproc";
				valueColTitle = "crashval";
				break;
		}

		if (procmap !== undefined && procmap !== null) {
			procs.sort((p1, p2) => p2[1] - p1[1]);
			for ([name, val] of procmap) {
				procs.push([name, val]);
			}
		}

		return (
			<div className="e5column-5 e5compo proc-table">
				<div className="e5compotitle">
					{_(title)}
				</div>
				<div className="e5line-0">
					<div className="e5linefull e5column-0">
						<div className="e5line-0 left-header">
							<div>{_("cb-sys-process-name")}</div>
						</div>
						{procs.map(([processName, proccessValue], idx) => {
							if (proccessValue === 0) {
								return (
									<div key={idx} className="e5line-0">
										<div className="cb-sys-circle" style={{ borderColor: '#373737' }}></div>
										<div>-</div>
									</div>
								)
							}
							return (
								<div key={idx} className="e5line-0">
									<div className="cb-sys-circle" style={{ borderColor: DEFAULT_CHART_COLORS[idx % 9] }}></div>
									{processName.length > 33 && <BP.Tooltip content={processName} position={BP.Position.BOTTOM_RIGHT}>
										{processName.substring(0, 29) + " ..."}
									</BP.Tooltip>}
									{processName.length <= 33 && <div>{processName}</div>}
								</div>
							);
						})}
					</div>

					<div className="e5linefull e5column-0">
						<div className="e5line-0 right-header">
							<div>&nbsp;{_("cb-sys-process-" + valueColTitle)}</div>
						</div>

						{procs.map(([_processName, proccessValue], idx) => {
							if (proccessValue === 0) {
								return (
									<div key={idx} className="e5line-0">
										<div>&nbsp;-</div>
									</div>
								)
							}
							return (
								<div key={idx} className="e5line-0">
									<div>&nbsp;{proccessValue.toFixed(4)}{(key === "cpu" || key === "mem") && " %"}</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	// ---------------- UTILS ----------------

	//E5
	static GetSource: (key: "cpu" | "crash" | "mem" | "status") => E5XYSource = (key: "cpu" | "crash" | "mem" | "status"): E5XYSource => {
		let src: E5XYSource = { numdatas: undefined, options: { stacked: true, markers: true } };
		src.numdatas = [];
		if (key === "status") src.options = { stacked: false, bar: true, barstack: true };
		let met: E5CBSysProcMet, metrics: E5CBSysProcMet[] = key === "cpu" ? E5StoreCBSys.Ins().process.cpuprocmetrics :
			key === "mem" ? E5StoreCBSys.Ins().process.memprocmetrics : key === "crash" ? E5StoreCBSys.Ins().process.crashprocmetrics : E5StoreCBSys.Ins().process.procstatmetrics,
			procmap: Map<string, E5XYNumData> = new Map(), numdata: E5XYNumData | undefined, proc: string, val: number;
		for (met of metrics) for ([proc, val] of met.vals) {
			numdata = procmap.get(proc);
			if (numdata === undefined) {
				numdata = {
					xaxisdata: [], yaxisdata: [], datalabel: proc.length > 23 ? proc.substring(0, 19) + " ..." : proc,
					hovertemplate: (key === "crash") ? "%{y} (%{x})<extra>" + (proc.length > 63 ? proc.substring(0, 59) + " ..." : proc) + "</extra>"
						: "%{y} % (%{x})<extra>" + (proc.length > 63 ? proc.substring(0, 59) + " ..." : proc) + "</extra>"
				};
				procmap.set(proc, numdata);
			}
			numdata.xaxisdata.push(met.date);
			numdata.yaxisdata.push(key === "cpu" || key === "mem" || key === "crash" ?
				Math.round(val * 10) / 10 : E5Text.ScoreToPercent(val));
		}
		for ([, numdata] of procmap) {
			if (key === "crash") {
				if (!numdata.yaxisdata.every((val) => val === 0)) {
					src.numdatas.push(numdata)
				}
			} else {
				src.numdatas.push(numdata)
			}
		}
		return src;
	};
});
