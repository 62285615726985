import moment from "moment";
import { E5EntHActivityDevice } from "../entity/household/activity/E5EntHActivityDevices";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5Store } from "../store/E5Store";

export const timeMap: { [key: string]: string } = {
    '00:00': '00:00',
    '01:00': '1h',
    '02:00': '2h',
    '03:00': '3h',
    '04:00': '4h',
    '05:00': '5h',
    '06:00': '6h',
    '07:00': '7h',
    '08:00': '8h',
    '09:00': '9h',
    '10:00': '10h',
    '11:00': '11h',
    '12:00': '12h',
    '13:00': '13h',
    '14:00': '14h',
    '15:00': '15h',
    '16:00': '16h',
    '17:00': '17h',
    '18:00': '18h',
    '19:00': '19h',
    '20:00': '20h',
    '21:00': '21h',
    '22:00': '22h',
    '23:00': '23h',
}

const heatmapXAxis = ['00:00', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'];
const newHeatmapXAxis = [
    "00:00",
    "00:30h",
    "1h",
    "1:30h",
    "2h",
    "2:30h",
    "3h",
    "3:30h",
    "4h",
    "4:30h",
    "5h",
    "5:30h",
    "6h",
    "6:30h",
    "7h",
    "7:30h",
    "8h",
    "8:30h",
    "9h",
    "9:30h",
    "10h",
    "10:30h",
    "11h",
    "11:30h",
    "12h",
    "12:30h",
    "13h",
    "13:30h",
    "14h",
    "14:30h",
    "15h",
    "15:30h",
    "16h",
    "16:30h",
    "17h",
    "17:30h",
    "18h",
    "18:30h",
    "19h",
    "19:30h",
    "20h",
    "20:30h",
    "21h",
    "21:30h",
    "22h",
    "22:30h",
    "23h",
    "23:30h"
];
const heatmapWeekDateTooltipConversion: {[key: string]: string} = {
    "00:00": "00:00h",
    "00:30h": "00:30h",
    "1h": "01:00h",
    "1:30h": "01:30h",
    "2h": "02:00h",
    "2:30h": "02:30h",
    "3h": "03:00h",
    "3:30h": "03:30h",
    "4h": "04:00h",
    "4:30h": "04:30h",
    "5h": "05:00h",
    "5:30h": "05:30h",
    "6h": "06:00h",
    "6:30h": "06:30h",
    "7h": "07:00h",
    "7:30h": "07:30h",
    "8h": "08:00h",
    "8:30h": "08:30h",
    "9h": "09:00h",
    "9:30h": "09:30h",
    "10h": "10:00h",
    "10:30h": "10:30h",
    "11h": "11:00h",
    "11:30h": "11:30h",
    "12h": "12:00h",
    "12:30h": "12:30h",
    "13h": "13:00h",
    "13:30h": "13:30h",
    "14h": "14:00h",
    "14:30h": "14:30h",
    "15h": "15:00h",
    "15:30h": "15:30h",
    "16h": "16:00h",
    "16:30h": "16:30h",
    "17h": "17:00h",
    "17:30h": "17:30h",
    "18h": "18:00h",
    "18:30h": "18:30h",
    "19h": "19:00h",
    "19:30h": "19:30h",
    "20h": "20:00h",
    "20:30h": "20:30h",
    "21h": "21:00h",
    "21:30h": "21:30h",
    "22h": "22:00h",
    "22:30h": "22:30h",
    "23h": "23:00h",
    "23:30h": "23:30h"
};
const heatmapDaysConversion: { [key: string]: string } = {
    'sunday': 'Sun',
    'monday': 'M',
    'tuesday': 'Tu',
    'wednesday': 'W',
    'thursday': 'Th',
    'friday': 'F',
    'saturday': 'Sat',
    'dimanche': 'Dim',
    'lundi': 'Lun',
    'mardi': 'Mar',
    'mercredi': 'Mer',
    'jeudi': 'Jeu',
    'vendredi': 'Ven',
    'samedi': 'Sam',
};
const weekendDays = ['sunday', 'saturday', 'dimanche', 'samedi'];
export const heatmapTimeSlots = ['00:00 - 00:30', '00:30 - 01:00', '01:00 - 01:30', '01:30 - 02:00', '02:00 - 02:30', '02:30 - 03:00', '03:00 - 03:30', '03:30 - 04:00', '04:00 - 04:30',
    '04:30 - 05:00', '05:00 - 05:30', '05:30 - 06:00', '06:00 - 06:30', '06:30 - 07:00', '07:00 - 07:30', '07:30 - 08:00', '08:00 - 08:30', '08:30 - 09:00', '09:00 - 09:30',
    '09:30 - 10:00', '10:00 - 10:30', '10:30 - 11:00', '11:00 - 11:30', '11:30 - 12:00', '12:00 - 12:30', '12:30 - 13:00', '13:00 - 13:30', '13:30 - 14:00', '14:00 - 14:30',
    '14:30 - 15:00', '15:00 - 15:30', '15:30 - 16:00', '16:00 - 16:30', '16:30 - 17:00', '17:00 - 17:30', '17:30 - 18:00', '18:00 - 18:30', '18:30 - 19:00', '19:00 - 19:30',
    '19:30 - 20:00', '20:00 - 20:30', '20:30 - 21:00', '21:00 - 21:30', '21:30 - 22:00', '22:00 - 22:30', '22:30 - 23:00', '23:00 - 23:30', '23:30 - 00:00'];

export function devicesActivityOptions(weeklyData: any) {
    return {
        xaxis: {
            group: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                },
                groups: weeklyData.length ? heatmapXAxis.map(day => ({ title: day, cols: 2 })) : [],
            },
            tickPlacement: 'between',
            labels: {
                style: {
                    fontSize: '0px',
                },
            },
            tooltip: {
                enabled: false
            },
            axisBorder: {
                show: true,
                color: '#000',
                height: 2,
                width: '100%',
                offsetX: 0,
                offsetY: 2
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                }
            },
            tooltip: {
                enabled: false
            },
        },
        tooltip: {
            enabled: false
        },
        legend: {
            show: false,
        },
        plotOptions: {
            heatmap: {
                enableShades: true,
                shadeIntensity: 1,
                colorScale: {
                    ranges: [
                        {
                            from: -1,
                            to: -1,
                            color: '#bebebd',
                            name: E5UtilI18n._('wificb-dashboard-notfound'),
                        },
                        {
                            from: 0,
                            to: 0,
                            color: '#fff',
                            name: E5UtilI18n._('cb-dashboard-device-disconected'),
                        },
                        {
                            from: 0,
                            to: 1,
                            color: '#0080FF',
                            name: E5UtilI18n._('cb-dashboard-avgval-usage'),
                        },
                    ]
                },
            },
        },
        colors: ['#bebebd', '#fff', '#0080FF'],
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
        },
        dataLabels: {
            enabled: false
        },
    }
}

export function parseDataForBarDonutGraph(data: E5EntHActivityDevice[], orderDesc: boolean = true) {
    const values: { [key: string]: number } = {};
    const parsedData: { value: number, name: string }[] = [];

    data.forEach((device) => {
        device.activityInfo.forEach((deviceInfo) => {
            if (values[deviceInfo.name]) {
                values[deviceInfo.name] += deviceInfo.value;
            } else {
                values[deviceInfo.name] = deviceInfo.value;
            }
        });
    });

    Object.keys(values).forEach((key) => {
        parsedData.push({
            value: values[key],
            name: key,
        });
    });

    if (orderDesc) {
        return parsedData.sort((a, b) => a.value - b.value);
    }

    return parsedData.sort((a, b) => b.value - a.value);
}

export function devicesCountOptions(data: Array<{ name: string, value: number }>) {
    const total = data.reduce((acc, item) => acc + item.value, 0);
    return {
        tooltip: {
            trigger: 'item',
            formatter(params: any) {
                return `${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b> (${params.percent}%)</span>`;
            },
            textStyle: {
                fontSize: 14,
                fontFamily: 'Montserrat',
                overflow: 'truncate',
            },
        },
        icon: 'circle',
        legend: {
            orient: 'vertical',
            type: 'scroll',
            left: 'right',
            top: 'center',
            align: 'left',
            padding: 5,
            pageIconColor: '#0080FF',
            symbolRotate: "inherit",
            formatter: (name: string) => {
                return `${name} (${calculatePercentage(data.find((item) => item.name === name)?.value, total)}) ${data.find((item) => item.name === name)?.value}`;
            },
            textStyle: {
                fontSize: 14,
                fontFamily: 'Montserrat',
                overflow: 'breakAll',
            },
            pageTextStyle: {
                fontSize: 14,
                fontFamily: 'Montserrat',
                overflow: 'truncate',
            },
            data: data
        },
        series: [
            {
                type: 'pie',
                left: 'left',
                right: 'center',
                radius: ['40%', '80%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    formatter: `${total}\n${E5UtilI18n._('h-dashboard-stations')}`,
                    position: 'center',
                    fontSize: 16,
                    fontFamily: 'Montserrat',
                    overflow: 'truncate',
                    fontWeight: 'bold',
                    color: '#000',
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                labelLine: {
                    show: false
                },
                data: data,
            }
        ]
    }

}

export function devicesActivityDonutOptions(data: Array<{ name: string, value: number }>, isMostConsuming: boolean) {
    return {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            show: false,
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '80%'],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    position: 'inner',
                    color: 'white',
                    rotate: 'tangential',
                    fontSize: 16,
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    width: 100,
                    overflow: 'truncate',
                },
                emphasis: {},
                labelLine: {
                    show: false
                },
                tooltip: {
                    valueFormatter: (value: number) => {
                        if (isMostConsuming) {
                            return bytesToSize(value);
                        }
                        return `${value} h`;
                    }
                },
                data: data,
            }
        ]
    }
}

export function devicesAcitivityBarOptions(data: Array<{ name: string, value: number }>, isMostConsuming: boolean) {
    return {
        xAxis: {
            type: 'value',
            axisLabel: {
                color: '#7e8188',
                fontFamily: 'Montserrat',
                formatter: (value: number) => {
                    if (isMostConsuming) {
                        return bytesToSize(value);
                    }
                    return `${value} h`;
                }
            },
        },
        yAxis: {
            type: 'category',
            data: data.map((device) => device.name),
            axisLabel: {
                color: '#7e8188',
                fontFamily: 'Montserrat',
            },
        },
        colorBy: 'data',
        series: [
            {
                seriesLayoutBy: 'column',
                type: 'bar',
                data: data.map((device) => device.value),
            }
        ]
    }
}

export function devicesActivityDetectionDayOptions(data: any): any {
    const colorData = data.map((day: any) => day.itemStyle.color);

    return {
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontSize: 14,
                fontFamily: 'Montserrat',
                overflow: 'truncate',
            }
        },
        legend: {
            show: false,
        },
        color: colorData,
        series: [
            {
                type: 'pie',
                radius: ['40%', '90%'],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    position: 'inner',
                    rotate: 'radial',
                    fontSize: 8,
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    width: 100,
                    overflow: 'truncate',
                    color: '#fff',
                },
                emphasis: {},
                labelLine: {
                    show: false
                },
                data: data,
            }
        ]
    }
}

export function devicesActivityDetectionWeekOptions(data: any): any {
    return {
        tooltip: {
            position: 'top'
        },
        grid: {
            height: '80%',
            top: '10%',
        },
        xAxis: {
            type: 'category',
            offset: 2,
            data: newHeatmapXAxis,
            axisLabel: {
                interval: 1,
                color: '#000',
                fontSize: '14px',
                fontFamily: 'Montserrat',
                align: 'center',
                padding: [0, 0, 0, 10],
            },
            axisTick: {
                interval: 1,
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'black',
                    width: 2,
                    type: 'solid'
                }
            },
            splitArea: {
                show: false
            },
        },
        yAxis: {
            type: 'category',
            data: data.map((day: any) => day.name),
            offset: 2,
            axisLabel: {
                interval: 0,
                formatter: function (value: string) {
                    const dayOfWeek = moment(value, 'YYYY-MM-DD', E5Store.Ins().langinfo.curlang).format('dddd').toLowerCase();
                    const parsedDayOfWeek = heatmapDaysConversion[dayOfWeek];
                    const parsedDay = moment(value, 'YYYY-MM-DD', E5Store.Ins().langinfo.curlang).format('D MMM');

                    if (weekendDays.includes(dayOfWeek)) {
                        return `{greyedBold|${parsedDayOfWeek}} {greyed|${parsedDay}}`;
                    }
                    return `{bold|${parsedDayOfWeek}} ${parsedDay}`;
                },
                rich: {
                    greyed: {
                        color: '#9f9f9e',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    },
                    greyedBold: {
                        fontWeight: 'bold',
                        color: '#9f9f9e',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    },
                    bold: {
                        color: '#000',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    }
                },
                fontSize: '14px',
                fontFamily: 'Montserrat',
                color: '#000'
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'white',
                    width: 2,
                    type: 'solid'
                }
            },
            minorSplitLine: {
                show: true,
                lineStyle: {
                    color: 'white',
                    width: 2,
                    type: 'solid'
                }
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            splitArea: {
                show: false
            },
        },
        visualMap: {
            show: false,
        },
        series: {
            id: 'week-activity',
            type: 'heatmap',
            data: data.map((day: any) => [...day.data]).flat(),
            tooltip: {
                formatter: function (params: any) {
                    if (params.value[2] !== -1) {
                        return `${params.marker}<span style="float: right; margin-left: 20px">${moment(params.value[4]).format('DD/MM/YYYY')}: ${heatmapWeekDateTooltipConversion[params.name]}<br>${E5UtilI18n._("h-dashboard-activity")}: <b>${(params.value[2] * 100).toFixed(2).toString() + '%'}</b><br>${E5UtilI18n._("h-dashboard-activity-traffic")}: <b>${params.value[3]}</b></span>`;
                    }
                },
            },
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2,
                borderType: 'solid',
            },
        }
    }
}

export function parseActivityDetectionDay(activities: E5EntHActivityDevice[]) {
    let parsedActivity: Array<any> = [];

    activities.forEach((activity) => {
        parsedActivity = activity.activityInfo.map((day) => {
            const color = calculateGraphColorActivity(day.value);

            const option: { [key: string]: any } = {
                name: day.name,
                value: 1,
                itemStyle: {
                    color,
                },
                label: {
                    color: day.value === 0 ? '#000' : '#fff',
                },
                tooltip: {
                    show: true,
                    formatter: function (params: any) {
                        if (day.value !== -1) {
                            return `${params.marker}<span style="float: right; margin-left: 20px">${moment(activity.label).format('DD/MM/YYYY')}: ${day.name}h<br>${E5UtilI18n._("h-dashboard-activity")}: <b>${(day.value * 100).toFixed(2).toString() + '%'}</b><br>${E5UtilI18n._("h-dashboard-activity-traffic")}: <b>${bytesToSize(day.bytes)}</b></span>`;
                        }
                    },
                },
                emphasis: {
                    disabled: day.value === -1,
                }
            }

            if (day.value === 0) {
                option.itemStyle.borderColor = '#000';
                option.itemStyle.borderWidth = 0.1;
            }

            return option;
        });
    });

    return parsedActivity;
}

export function parseActivityDetectionWeek(activities: E5EntHActivityDevice[]) {
    return activities.map((activity, dayIndex) => {
        return {
            name: activity.label,
            data: activity.activityInfo.map((day, activityIndex) => {
                return {
                    value: [activityIndex, dayIndex, day.value, (day.bytes !== -1) ? bytesToSize(day.bytes) : 'N/A', activity.label],
                    itemStyle: {
                        color: calculateGraphColorActivity(day.value),
                    },
                    emphasis: {
                        disabled: day.value === -1,
                    },
                }
            }),
        }
    });
}

function calculatePercentage(value: number, total: number): string {
    return ((value / total) * 100).toFixed(2) + '%';
}

function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
        return '0 MB';
    }

    const i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024));

    if (i === 0) {
        return `${bytes} ${sizes[i]}`;
    }

    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}

function calculateGraphColorActivity(value: number): string {
    const normalizedValue = Math.max(0, Math.min(1, value));

    // Colors in RGB format
    const colorWhite = { r: 0xff, g: 0xff, b: 0xff }; // #ffffff
    const colorGrey = { r: 0xbe, g: 0xbe, b: 0xbd }; // #bebebd
    const color1 = { r: 0x5e, g: 0x6b, b: 0xc0 }; // #5E6BC0
    const color2 = { r: 0xef, g: 0x53, b: 0x52 }; // #EF5352

    let r, g, b;

    if (value === 0) {
        r = colorWhite.r;
        g = colorWhite.g;
        b = colorWhite.b;
    } else if (value === -1) {
        r = colorGrey.r;
        g = colorGrey.g;
        b = colorGrey.b;
    } else {
        // Linearly interpolate between color1 and color2
        r = Math.round(color1.r + normalizedValue * (color2.r - color1.r));
        g = Math.round(color1.g + normalizedValue * (color2.g - color1.g));
        b = Math.round(color1.b + normalizedValue * (color2.b - color1.b));
    }

    // Convert to hex and pad with zeroes if necessary
    const toHex = (n: number) => n.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

