import React from "react";
import "./TextFieldStyled";
import { alpha, styled } from '@mui/material/styles';

import TextField from '@mui/material/TextField';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0080FF',
    },
    '& label.Mui': {
        color: '#0080FF',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0080FF'
    },
    '& .MuiOutlinedInput-root': {

        '& fieldset': {
            borderColor: '#0080FF'

        },


        '&.Mui-focused fieldset': {
            borderColor: '#0080FF'

        },
    },
    '& input:-ms-input-placeholder, & input::-ms-input-placeholder, & input::placeholder': {
        color: '#0080FF', // Set the desired color for the placeholder text
        opacity: 1,
    },
});
export default function CustomizedInput({ inputRef, id, label, variant, onChange, className, value, InputProps }) {
    return (
        <div>
            <CssTextField inputRef={inputRef} placeholder={label} id={id} value={value} variant={variant} InputProps={InputProps} onChange={onChange} className={className} size='small' />
        </div>
    );
}