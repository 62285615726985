import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHSummaryAnom extends E5Entity {
	id: string = "";
	criticality: number = 0;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.id = E5Entity.AssignString(json.anomaly_id);
		this.criticality = E5Entity.ParseFloat(json.criticality);
	}

	//E5
	CopyToJson(): any {
		return {anomaly_id: this.id, criticality: this.criticality};
	}
}
