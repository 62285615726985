import { useEffect, useState } from "react";
import { MultipleFieldErrors, useForm } from "react-hook-form";
import { E5EntUser } from "../../../entity/E5EntUser";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { PasswordInterface, updateUserPassword } from "../../../request/E5RequestProfile";
import './E5ProfilePassword.css';

export interface E5ProfilePasswordPageProps {
    user: E5EntUser;
    updateFunction: (result: string, message: string, sendLogout: boolean) => void;
}

export default function E5ProfilePassword(props: Readonly<E5ProfilePasswordPageProps>) {
    const { register, handleSubmit, watch, setError, clearErrors, reset, formState: { errors } } = useForm({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        criteriaMode: 'all',
    });
    const formValues = watch();
    const newPassword = watch('newPassword');
    const confirmPassword = watch('confirmPassword');

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        clearErrors('newPassword');
        clearErrors('confirmPassword');

        let validating: { [key: string]: boolean } = {
            minLength: false,
            hasLowercase: false,
            hasUppercase: false,
            hasNumber: false,
            hasSpecialChar: false
        };

        if (newPassword.length) {
            validating = {
                minLength: newPassword.length >= 13,
                hasLowercase: /[a-z]/.test(newPassword),
                hasUppercase: /[A-Z]/.test(newPassword),
                hasNumber: /\d/.test(newPassword),
                hasSpecialChar: /[!/:;<=>?@[\]^_`{|}~]/.test(newPassword)
            }
        }

        const error: MultipleFieldErrors = {};

        Object.keys(validating).forEach((key) => {
            if (!validating[key]) {
                error[key] = 'error'
            }
        });

        if (Object.keys(error).length === 0) {
            clearErrors('newPassword')
        } else {
            setError('newPassword', {
                types: error,
            });
        }

        if (confirmPassword === newPassword) {
            clearErrors('confirmPassword');
        } else {
            setError('confirmPassword', {
                type: 'validate',
            });
        }
    }, [newPassword, confirmPassword]);

    const onSubmit = (data: any): void => {
        const dataToSave: PasswordInterface = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword
        };

        updateUserPassword(props.user.username, dataToSave)
            .then(() => {
                reset();
                props.updateFunction('success', E5UtilI18n._('profile-password-updated'), false);
            })
            .catch(() => {
                let errorMessage = 'profile-password-not-updated';

                if (dataToSave.currentPassword === dataToSave.newPassword) {
                    errorMessage = 'profile-password-same';
                }

                props.updateFunction('danger', E5UtilI18n._(errorMessage), false);
            });
    }

    const canSave = () => {
        return Object.keys(errors).length === 0 && formValues.newPassword === formValues.confirmPassword && formValues.currentPassword !== '';
    }

    return (
        <div className="e5section">
            <div className="e5sectionpadding e5borderbottom">
                <h3 className="e5nomargin">{E5UtilI18n._("admin-users-password")}</h3>
            </div>

            <div className="e5sectionpadding e5ypadding-20">
                <form id="password" onSubmit={handleSubmit(onSubmit)}>
                    <div className="e5formgroup e5line-20 e5justifycontentbewteen">
                        <div className="e5column-20 e5twocolumnsflex">
                            <label htmlFor="currentpassword">{E5UtilI18n._("profile-current-password")}</label>
                            <input type={showCurrentPassword ? 'text' : 'password'} id="currentpassword" placeholder={E5UtilI18n._("profile-write-password")}
                                {...register('currentPassword', { required: true })} />
                        </div>

                        <div className="e5flexcenter">
                            <label className="e5nomarginbottom e5cursorpointer" htmlFor="showcurrentpassword">
                                <img src="./img/v3/profile/eye-open.svg" alt={E5UtilI18n._("profile-show-password")} />
                            </label>
                            <input type="checkbox" id="showcurrentpassword" name="showcurrentpassword" checked={showCurrentPassword} onChange={(e) => setShowCurrentPassword(e.target.checked)} />
                        </div>
                    </div>

                    <div className="e5formgroup e5line-20 e5justifycontentbewteen">
                        <div className="e5column-20 e5twocolumnsflex">
                            <label htmlFor="newpassword">{E5UtilI18n._("profile-new-password")}</label>
                            <input type={showNewPassword ? 'text' : 'password'} id="newpassword" placeholder={E5UtilI18n._("profile-write-password")}
                                {...register('newPassword', { required: true })} />
                        </div>

                        <div className="e5flexcenter">
                            <label className="e5nomarginbottom e5cursorpointer" htmlFor="shownewpassword">
                                <img src="./img/v3/profile/eye-open.svg" alt={E5UtilI18n._("profile-show-password")} />
                            </label>
                            <input type="checkbox" id="shownewpassword" name="shownewpassword" checked={showNewPassword} onChange={(e) => setShowNewPassword(e.target.checked)} />
                        </div>
                    </div>

                    <div className="e5marginbottom-15">
                        <div className="e5flexcenterstart e5marginbottom-10">
                            <span className={`circle-validation ${formValues.newPassword === '' || errors?.newPassword?.types?.minLength ? 'invalid' : 'valid'}`} />
                            <span className="e5text e5textcolor">{E5UtilI18n._("profile-password-validate-char-amount")}</span>
                        </div>
                        <div className="e5flexcenterstart e5marginbottom-10 ">
                            <span className={`circle-validation ${formValues.newPassword === '' || errors?.newPassword?.types?.hasLowercase ? 'invalid' : 'valid'}`} />
                            <span className="e5text e5textcolor">{E5UtilI18n._("profile-password-validate-lowercase")}</span>
                        </div>
                        <div className="e5flexcenterstart e5marginbottom-10 ">
                            <span className={`circle-validation ${formValues.newPassword === '' || errors?.newPassword?.types?.hasUppercase ? 'invalid' : 'valid'}`} />
                            <span className="e5text e5textcolor">{E5UtilI18n._("profile-password-validate-uppercase")}</span>
                        </div>
                        <div className="e5flexcenterstart e5marginbottom-10 ">
                            <span className={`circle-validation ${formValues.newPassword === '' || errors?.newPassword?.types?.hasNumber ? 'invalid' : 'valid'}`} />
                            <span className="e5text e5textcolor">{E5UtilI18n._("profile-password-validate-number")}</span>
                        </div>
                        <div className="e5flexcenterstart">
                            <span className={`circle-validation ${formValues.newPassword === '' || errors?.newPassword?.types?.hasSpecialChar ? 'invalid' : 'valid'}`} />
                            <span className="e5text e5textcolor">{E5UtilI18n._("profile-password-validate-special-char")}</span>
                        </div>
                    </div>

                    <div className="e5formgroup e5line-20 e5justifycontentbewteen">
                        <div className="e5column-20 e5twocolumnsflex">
                            <label htmlFor="confirmpassword">{E5UtilI18n._("profile-repeat-password")}</label>
                            <input type={showConfirmPassword ? 'text' : 'password'} id="confirmpassword" placeholder={E5UtilI18n._("profile-write-password")}
                                {...register('confirmPassword', { required: true })} />
                        </div>

                        <div className="e5flexcenter">
                            <label className="e5nomarginbottom e5cursorpointer" htmlFor="showconfirmpassword">
                                <img src="./img/v3/profile/eye-open.svg" alt={E5UtilI18n._("profile-show-password")} />
                            </label>
                            <input type="checkbox" id="showconfirmpassword" name="showconfirmpassword" checked={showConfirmPassword} onChange={(e) => setShowConfirmPassword(e.target.checked)} />
                        </div>
                    </div>

                    <button className="e5button" type='submit' disabled={!canSave()}>
                        {E5UtilI18n._("profile-change-password")}
                    </button>
                </form>
            </div>
        </div>
    );
}