import React from 'react';
import { Base64 } from "js-base64";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import { RouteComponentProps } from "react-router";
//
import { E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender } from "../../util/E5RestSender";
import { E5Store, E5StoreUserInfo } from "../../store/E5Store";
import { E5MainConfig } from "../../global/E5MainConfig";
import { E5UtilI18n } from "../../global/E5MainLang";
import { E5Request } from "../../request/E5Request";
import { E5EntUser } from "../../entity/E5EntUser";
import { ReactComponent as LoginIcon } from '../../assets/login.svg'
import { ReactComponent as XMark } from '../../assets/xmark.svg'

import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
//
import "./E5LoginPage.css"

//E5
interface E5LoginPageState {
	username: string;
	password: string;
	isemptyname: boolean;
	isemptypass: boolean;
	hastypedname: boolean;
	hastypedpass: boolean;
	message: string;
	loading: boolean;
	show: boolean;
	error: string | undefined
	isShowErrorModal: boolean;
}

//E5
interface E5LoginPageProps {
	userinfo: E5StoreUserInfo;
	loginsuccess: () => void;
}

//E5
export const E5LoginPage = observer(class E5LoginPage extends React.PureComponent
	<RouteComponentProps<{}> & E5LoginPageProps, E5LoginPageState> implements E5RestResponder {

	// ---------------- MEMBERS ----------------

	//E5
	toasterref: React.RefObject<BP.Toaster>;
	usernameinput: HTMLInputElement | undefined;
	usernameinputref = (ref: any) => { this.usernameinput = ref };
	sender: E5RestSender;
	curtoken: number | undefined = undefined;
	error: string | undefined;
	isShowErrorModal: boolean = false;;

	// ---------------- INIT ----------------

	//E5
	constructor(props: RouteComponentProps<{}> & E5LoginPageProps, state: E5LoginPageState) {
		super(props, state);
		this.toasterref = React.createRef();
		this.sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v2");
		this.state = {
			username: "", password: "", isemptyname: true, isemptypass: true,
			hastypedname: false, hastypedpass: false, message: "", loading: false, show: false,
			error: undefined, isShowErrorModal: false,
		};
	}

	//E5
	componentDidMount(): void {
		if (this.usernameinput !== undefined)
			this.usernameinput.focus();
		if (this.toasterref.current !== null && this.props.userinfo.curuser.sessionexpired) {
			this.toasterref.current.show({
				message: E5UtilI18n._("login-expired"),
				intent: BP.Intent.WARNING
			});
		}
	}

	//E5
	componentWillUnmount(): void {
		this.sender.Cancel(this.curtoken);
		this.curtoken = undefined;
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// console.log(this.state.message)
		return <div className="e5page e5loginpage e5fullheight e5column-0">
			<BP.Toaster ref={this.toasterref} />
			<div className="e5line-0 content">
				<div className="e5loginform e5column-10">
					<div className="welcomemessage">
						<span className='title'>{E5UtilI18n._("login-welcome")}</span>
						<span className='subtitle'>{E5UtilI18n._("login-welcome-text")}</span>
					</div>
					<TextField
						error={!!this.state.message}
						className='login-input'
						size="small"
						label={E5UtilI18n._("login-username")}
						variant="outlined"
						id="username"
						onKeyDown={this.KeyDown}
						value={this.state.username}
						onChange={this.Changed}
						inputRef={this.usernameinputref}
					/>
					<TextField
						error={!!this.state.message}
						className='login-input'
						size="small"
						variant="outlined"
						type="password"
						id="password"
						label={E5UtilI18n._("login-password")}
						onKeyDown={this.KeyDown}
						value={this.state.password}
						onChange={this.Changed}
						helperText={this.state.error && this.state.error}
					/>
					{!this.state.loading &&
						<BP.Button
							id="validate"
							className="button"
							intent={BP.Intent.PRIMARY}
							onClick={this.Validate}
						>
							{E5UtilI18n._("login-ok")}
						</BP.Button>
					}
					{this.state.loading &&
						<BP.Spinner size={30} />
					}
				</div>
				<LoginIcon className='login-icon' />
			</div>
			<Modal open={this.state.show} onClose={() => { this.setState({ show: false }) }}>

				<div className='modal-content'>
					<XMark className='x-mark' />
					<span>
						Too many wrong attempts. You are locked out!
					</span>
					<span>
						Please contact an admin to unlock your account
					</span>
					<BP.Button className='cancel-button' onClick={() => this.setState({ show: false })}>Cancel</BP.Button>
				</div>
			</Modal>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Changed = (event: React.ChangeEvent<HTMLElement>) => {
		let st: any = {};
		let id = event.currentTarget.id;
		let value = (event.currentTarget as any).value;
		if (id === "username")
			st.username = value;
		else if (id === "password")
			st.password = value;
		this.setState(st);
	};

	//E5
	KeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
		if (event.keyCode === 13) {
			event.preventDefault(); // bug on Chrome that cancel the ajax request and reload the page
			this.Validate();
		}
	};

	//E5
	Validate = () => {
		if (this.state.username !== "" && this.state.password !== "") {
			this.sender.Cancel(this.curtoken);
			this.curtoken = undefined;
			let req: E5RestRequest = {
				method: "POST",
				uri: "/login",
				header: [
					["Content-Type", "application/json"],
					["Authorization", "Basic " + Base64.encode(this.state.username + ":" + this.state.password)]
				],
				body: undefined
			};
			this.setState({ loading: true }, () => {
				this.curtoken = this.sender.Send(req,
					(errorMessage: string | undefined) => {
						if (errorMessage === 'Too many wrong attempts. You are locked out! Please contact an admin to unlock your account.') {
							this.setState({ error: errorMessage, show: true });
						} else {
							this.setState({ error: errorMessage });
						}

					});
			});
		} else {
			this.setState({ hastypedname: true, hastypedpass: true }, () => {
				this.SetEmpty();
			});
		}
	};

	//E5
	SetEmpty() {
		let newstate = { isemptypass: false, isemptyname: false };
		if (this.state.username === "")
			newstate.isemptyname = true;
		if (this.state.password === "")
			newstate.isemptypass = true;
		this.setState(newstate);
	}

	// ---------------- UTILS ----------------

	//E5
	RequestCallback(resp: E5RestResponse): void {
		let user: E5EntUser | undefined = undefined;
		let st: any = { loading: false };
		if (resp.status === 401) {
			st.message = resp.body.error;
		} else if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			st.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			st.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			let err: string = "unknown";
			if (resp.body !== undefined && resp.body.error !== undefined && resp.body.error !== "")
				err = resp.body.error;
			st.message = E5UtilI18n._("login-forbidden-" + err);
		} else if (resp.status !== 200) {
			st.message = E5UtilI18n._("processingerror");
		} else {
			user = new E5EntUser();
			user.bearer = resp.FindRespHeader("authorization");
			user.GetDataFromBearer();
			//
			st.message = "";
			st.username = "";
			st.isemptyname = true;
			st.password = "";
			st.isemptypass = true;
		}
		this.setState(st, () => {
			this.SetEmpty();
			// change user at the end because this will unmount present component
			if (user !== undefined) {
				E5Store.Ins().ChangeUser(user, this.props.history);
				this.props.loginsuccess();
			}
		});
	}
});
