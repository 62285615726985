import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5StoreLangInfo} from "../../../store/E5Store";
import {E5UtilI18n} from "../../../global/E5MainLang";
//
import "./E5AdminUsersRolesLegend.css";

//E5
interface E5AdminUsersRolesLegendState {}

//E5
interface E5AdminUsersRolesLegendProps {
	langinfo: E5StoreLangInfo;
	text: string;
}

//E5
export const E5AdminUsersRolesLegend = observer(class E5AdminUsersRolesLegend extends React.PureComponent
	<E5AdminUsersRolesLegendProps, E5AdminUsersRolesLegendState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		//
		return <div className="e5admin-users-roles-legend">
			<BP.Popover
				content={<div className="e5admin-users-roles-legend-table">
					<div className="e5column-0">
						<div>{E5UtilI18n._("roles-legend-table-menu-page")}</div>
						<div>{E5UtilI18n._("roles-legend-table-cust-dash")}</div>
						<div>{E5UtilI18n._("roles-legend-table-cust-pops")}</div>
						<div>{E5UtilI18n._("roles-legend-table-cust-rdm")}</div>
						<div>{E5UtilI18n._("roles-legend-table-hoho-dash")}</div>
						<div>{E5UtilI18n._("roles-legend-table-hoho-equip")}</div>
						<div>{E5UtilI18n._("roles-legend-table-hoho-stations")}</div>
						<div>{E5UtilI18n._("roles-legend-table-hoho-incs")}</div>
						<div>{E5UtilI18n._("roles-legend-table-hoho-conn")}</div>
						<div>{E5UtilI18n._("roles-legend-table-hoho-mets")}</div>
						<div>{E5UtilI18n._("roles-legend-table-hoho-elogs")}</div>
						<div>{E5UtilI18n._("roles-legend-table-mana-usrs")}</div>
						<div>{E5UtilI18n._("roles-legend-table-mana-acl")}</div>
					</div>
					<div className="e5column-0">
						<div>{E5UtilI18n._("roles-legend-table-cust-supp-1")}</div>
						<div/>
						<div/>
						<div/>
						<div>x</div>
						<div/>
						<div/>
						<div>x</div>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
					</div>
					<div className="e5column-0">
						<div>{E5UtilI18n._("roles-legend-table-cust-supp-2")}</div>
						<div/>
						<div/>
						<div/>
						<div>x</div>
						<div/>
						<div/>
						<div>x</div>
						<div>x</div>
						<div/>
						<div/>
						<div/>
						<div/>
					</div>
					<div className="e5column-0">
						<div>{E5UtilI18n._("roles-legend-table-cust-supp-3")}</div>
						<div/>
						<div/>
						<div/>
						<div>x</div>
						<div/>
						<div/>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div/>
						<div/>
						<div/>
					</div>
					<div className="e5column-0">
						<div>{E5UtilI18n._("roles-legend-table-cust-manager")}</div>
						<div>x</div>
						<div>x</div>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
					</div>
					<div className="e5column-0">
						<div>{E5UtilI18n._("roles-legend-table-cust-analyst")}</div>
						<div>x</div>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
					</div>
					<div className="e5column-0">
						<div>{E5UtilI18n._("roles-legend-table-rdm-manager")}</div>
						<div/>
						<div/>
						<div>x</div>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
						<div/>
					</div>
					<div className="e5column-0">
						<div>{E5UtilI18n._("roles-legend-table-admin")}</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
						<div>x</div>
					</div>
				</div>}
				position={BP.Position.BOTTOM_LEFT}
			>
				<BP.Button icon="help" text={this.props.text} small={this.props.text === ""}/>
			</BP.Popover>
		</div>;
	}
});
