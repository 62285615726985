import {E5Entity} from "../E5Entity";

//E5
export interface E5MetaLangLengths {
	long: string;
	short: string;
}

//E5
export class E5EntMetaRole extends E5Entity {
	en: E5MetaLangLengths = {long: "", short: ""};
	fr: E5MetaLangLengths = {long: "", short: ""};

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		if (typeof json.en === "object" && json.en !== null)
			this.en = {long: E5Entity.AssignString(json.en.long), short: E5Entity.AssignString(json.en.short)};
		if (typeof json.fr === "object" && json.fr !== null)
			this.fr = {long: E5Entity.AssignString(json.fr.long), short: E5Entity.AssignString(json.fr.short)};
	}

	//E5
	CopyToJson(): any {
		return {en: {long: this.en.long, short: this.en.short}, fr: {long: this.fr.long, short: this.fr.short}};
	}
}
