import moment from "moment";
import {action, makeObservable, observable} from "mobx";
//
import {E5EntWifiChanTime} from "../entity/E5EntWifiChanTime";
import {E5RequestStatus} from "../request/E5ServiceCommon";
import {E5MainConfig} from "../global/E5MainConfig";

//E5
export interface E5StoreCBFilters {
	startdate: string;//YYYY-MM-YY
	enddate: string;//YYYY-MM-YY
	populationid: string;//population id
}

export interface E5CBHealthClass {
	cla: string;
	rank: number;
	count: number;
}

//E5
export interface E5StoreCBGlobal {
	status: E5RequestStatus;
	health: number|null|undefined;
	healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined;
	statypemap: Map<string, number>|null|undefined;
	eqpdetailmap: Map<string, Map<string, number>>|null|undefined;
	equipmap: Map<string, Map<string, number>>|null|undefined;
	stationmap: Map<string, Map<string, number>>|null|undefined;
	maxavgmap: Map<string, Map<string, number>>|null|undefined;
}

//E5
export interface E5StoreCBWifi {
	status: E5RequestStatus;
	health: number|null|undefined;
	connmap: Map<string, number|null>|null|undefined;
	catcritmap: Map<string, Map<string, number>>|null|undefined;
	catdurmap: Map<string, Map<string, number>>|null|undefined;
	healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined;
	incidcritmap: Map<string, number>|null|undefined;
	connbandmap: Map<string, Map<string, number>>|null|undefined;
	connstdmap: Map<string, Map<string, number>>|null|undefined;
	chanconntimes: E5EntWifiChanTime[];
	autochanmap: Map<string, number>|null|undefined;
	conntimemap: Map<string, number>|null|undefined;
}

//E5
export interface E5StoreCBWan {
	status: E5RequestStatus;
	health: number|null|undefined;
	healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined;
	incidcritmap: Map<string, number>|null|undefined;
	wanaccessmap: Map<string, number>|null|undefined;
}

//E5
export interface E5StoreCBNiList {
	status: E5RequestStatus;
	ids: string[];
}

//E5
export type E5CBNiListMode = "health"|"cpu"|"mem"|"crash"|"incid"|"equip1"|"detail1"|"equip2"|"detail2"|"none";

//E5
export interface E5StoreCBNiListSettings {
	mode: E5CBNiListMode;
	downloadstr: string;
	nilistopen: boolean;
	date: string;
	incid: string;
	cla: string;
	eqptype: string;
	sofv: string;
	model: string;
}

// --------- STORE ITSELF -------------

//E5
export class E5StoreCB {

	// --------- STATIC MEMBERS -------------

	//E5
	private static ins: E5StoreCB;

	//E5
	static Ins(): E5StoreCB {
		if (E5StoreCB.ins === undefined) E5StoreCB.ins = new E5StoreCB();
		return E5StoreCB.ins;
	}

	// --------- STATIC STORE MODIFICATION -------------

	//E5
	// dates are YYYY-MM-DD
	SetStartDate(startdate: string) {
		E5StoreCB.Ins().filterinfo.startdate = startdate;
	}

	//E5
	// dates are YYYY-MM-DD
	SetEndDate(enddate: string) {
		E5StoreCB.Ins().filterinfo.enddate = enddate;
	}

	SetDates(startdate: string, enddate: string) {
		E5StoreCB.Ins().filterinfo.startdate = startdate;
		E5StoreCB.Ins().filterinfo.enddate = enddate;
	}

	//E5
	SetCurPopulation(curpop: string, startdate: string, enddate: string) {
		if (startdate) E5StoreCB.Ins().filterinfo.startdate = startdate;
		if (enddate) E5StoreCB.Ins().filterinfo.enddate = enddate;
		E5StoreCB.Ins().filterinfo.populationid = curpop;
	}

	//E5
	SetRequestSet = (set: Set<string>): void => {
		E5StoreCB.Ins().request.set = set;
	};

	//E5
	SetGlobal(status: E5RequestStatus, health: number|null|undefined,
		healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined,
		statypemap: Map<string, number>|null|undefined, eqpdetailmap: Map<string, Map<string, number>>|null|undefined,
		equipmap: Map<string, Map<string, number>>|null|undefined,
		stationmap: Map<string, Map<string, number>>|null|undefined,
		maxavgmap: Map<string, Map<string, number>>|null|undefined) {
		E5StoreCB.Ins().global.status = status;
		E5StoreCB.Ins().global.health = health;
		E5StoreCB.Ins().global.healthmetmap = healthmetmap;
		E5StoreCB.Ins().global.statypemap = statypemap;
		E5StoreCB.Ins().global.eqpdetailmap = eqpdetailmap;
		E5StoreCB.Ins().global.equipmap = equipmap;
		E5StoreCB.Ins().global.stationmap = stationmap;
		E5StoreCB.Ins().global.maxavgmap = maxavgmap;
	}

	//E5
	SetWifi(status: E5RequestStatus, health: number|null|undefined,
		connmap: Map<string, number|null>|null|undefined, catcritmap: Map<string, Map<string, number>>|null|undefined,
		catdurmap: Map<string, Map<string, number>>|null|undefined,
		healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined,
		incidcritmap: Map<string, number>|null|undefined, connbandmap: Map<string, Map<string, number>>|null|undefined,
		connstdmap: Map<string, Map<string, number>>|null|undefined, chanconntimes: E5EntWifiChanTime[],
		autochanmap: Map<string, number>|null|undefined, conntimemap: Map<string, number>|null|undefined) {
		E5StoreCB.Ins().wifi.status = status;
		E5StoreCB.Ins().wifi.health = health;
		E5StoreCB.Ins().wifi.connmap = connmap;
		E5StoreCB.Ins().wifi.catcritmap = catcritmap;
		E5StoreCB.Ins().wifi.catdurmap = catdurmap;
		E5StoreCB.Ins().wifi.healthmetmap = healthmetmap;
		E5StoreCB.Ins().wifi.incidcritmap = incidcritmap;
		E5StoreCB.Ins().wifi.connbandmap = connbandmap;
		E5StoreCB.Ins().wifi.connstdmap = connstdmap;
		E5StoreCB.Ins().wifi.chanconntimes = chanconntimes;
		E5StoreCB.Ins().wifi.autochanmap = autochanmap;
		E5StoreCB.Ins().wifi.conntimemap = conntimemap;
	}

	//E5
	SetWan(status: E5RequestStatus, health: number|null|undefined,
		healthmetmap: Map<string, Map<number, E5CBHealthClass>>|null|undefined,
		incidcritmap: Map<string, number>|null|undefined, wanaccessmap: Map<string, number>|null|undefined) {
		E5StoreCB.Ins().wan.status = status;
		E5StoreCB.Ins().wan.health = health;
		E5StoreCB.Ins().wan.healthmetmap = healthmetmap;
		E5StoreCB.Ins().wan.incidcritmap = incidcritmap;
		E5StoreCB.Ins().wan.wanaccessmap = wanaccessmap;
	}

	//E5
	SetNiList(status: E5RequestStatus, nilist: string[]) {
		E5StoreCB.Ins().nilist.status = status;
		E5StoreCB.Ins().nilist.ids = nilist;
	}

	SetNiListNodes(nodes: string[]) {
		E5StoreCB.Ins().ninodelist = nodes;
	}

	SetNiListUsages(usages: string[]) {
		E5StoreCB.Ins().niusagelist = usages;
	}

	SetNiType(type: string) {
		E5StoreCB.Ins().niType = type;
	}

	//E5
	SetNiListSettings(mode: E5CBNiListMode|undefined, downloadstr: string|undefined,
		nilistopen: boolean|undefined, date: string|undefined, incid: string|undefined, cla: string|undefined,
		eqptype: string|undefined, sofv: string|undefined, model: string|undefined): void {
		if (mode !== undefined) E5StoreCB.Ins().nilistsettings.mode = mode;
		if (downloadstr !== undefined) E5StoreCB.Ins().nilistsettings.downloadstr = downloadstr;
		if (nilistopen !== undefined) E5StoreCB.Ins().nilistsettings.nilistopen = nilistopen;
		if (date !== undefined) E5StoreCB.Ins().nilistsettings.date = date;
		if (incid !== undefined) E5StoreCB.Ins().nilistsettings.incid = incid;
		if (cla !== undefined) E5StoreCB.Ins().nilistsettings.cla = cla;
		if (eqptype !== undefined) E5StoreCB.Ins().nilistsettings.eqptype = eqptype;
		if (sofv !== undefined) E5StoreCB.Ins().nilistsettings.sofv = sofv;
		if (model !== undefined) E5StoreCB.Ins().nilistsettings.model = model;
	}

	// --------- INSTANCE -------------

	filterinfo: E5StoreCBFilters = {
		startdate: moment().subtract(E5MainConfig.GetNbDaysCustomerBase(), "days").format("YYYY-MM-DD"),
		enddate: moment().subtract(1, "day").format("YYYY-MM-DD"), populationid: ""
	};
	request: {set: Set<string>} = {set: new Set()};
	global: E5StoreCBGlobal = {
		status: {loading: false, success: false, message: ""}, health: undefined, healthmetmap: undefined,
		statypemap: undefined, eqpdetailmap: undefined, equipmap: undefined, stationmap: undefined, maxavgmap: undefined
	};
	wifi: E5StoreCBWifi = {
		status: {loading: false, success: false, message: ""}, health: undefined, connmap: undefined,
		catcritmap: undefined, catdurmap: undefined, healthmetmap: undefined, incidcritmap: undefined,
		connbandmap: undefined, connstdmap: undefined, chanconntimes: [], autochanmap: undefined, conntimemap: undefined
	};
	wan: E5StoreCBWan = {
		status: {loading: false, success: false, message: ""}, health: undefined, healthmetmap: undefined,
		incidcritmap: undefined, wanaccessmap: undefined
	};
	nilist: E5StoreCBNiList = {status: {loading: false, success: false, message: ""}, ids: []};
	ninodelist: string[] = [];
	niusagelist: string[] = [];
	niType: string = "none";
	nilistsettings: E5StoreCBNiListSettings = {
		mode: "none", downloadstr: "", nilistopen: false, date: "", incid: "", cla: "", eqptype: "", sofv: "", model: ""
	};

	//E5
	constructor() {
		makeObservable(this, {
			filterinfo: observable, request: observable, global: observable, wifi: observable, wan: observable, nilist: observable,
			nilistsettings: observable, ninodelist: observable, niusagelist: observable, niType: observable,
			SetStartDate: action, SetEndDate: action, SetDates: action, SetCurPopulation: action, SetRequestSet: action,
			SetGlobal: action, SetWifi: action, SetWan: action, SetNiList: action, SetNiListUsages: action, SetNiListSettings: action
		})
	}
}
