import moment, {Moment} from "moment";
//
import {E5Text} from "../../../util/E5Text";
import {E5Time} from "../../../util/E5Time";
import {E5Entity} from "../../E5Entity";

export class E5HourRssi {
	rssi: number|null = null;
	time: Moment = moment();

	//E5
	constructor(json?: any) {
		if (json !== undefined) {
			this.rssi = E5Entity.ParseFloatNull(json.rssi);
			this.time = E5Text.Tm2MomentTz(E5Entity.ParseInt(json.time), E5Time.h_tzoffset);
		}
	}
}

export class E5EntHStationRssi extends E5Entity {
	child: string = "";
	rssiperhour: E5HourRssi[] = [];

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.child = E5Entity.AssignString(json.child_id);

		this.rssiperhour = [];
		let idx: number;
		if (Array.isArray(json.rssi_per_hour)) for (idx = 0; idx < json.rssi_per_hour.length; idx++)
			this.rssiperhour.push(new E5HourRssi(json.rssi_per_hour[idx]));
	}

	//E5
	CopyToJson(): any {
		return {
			child_id: this.child,
			rssi_per_hour: this.rssiperhour.map(hourrssi => ({rssi: hourrssi.rssi, time: hourrssi.time.unix()}))
		};
	}
}
