import {Base64} from "js-base64";
import moment, {Moment} from "moment";
//
import {E5EntAclGroup} from "./E5EntAclGroup";
import {E5Text} from "../util/E5Text";
import {E5Time} from "../util/E5Time";
import {E5Entity} from "./E5Entity";

//E5
export class E5EntUser extends E5Entity {
	username: string = "";
	lastname: string = "";
	firstname: string = "";
	email: string = "";
	company: string = "";
	jobtitle: string = "";
	phonenumber: string = "";
	password: string = "";
	roles: string[] = [];
	lastsuccessfullogin: Moment|undefined = undefined;
	accountexpirationdate: Moment|undefined = undefined;
	enabled: boolean = false;
	accountnonexpired: boolean = false;
	credentialsnonexpired: boolean = false;
	accountnonlocked: boolean = false;
	// internal
	bearer: string = "";
	isconnected: boolean = false;
	sessionexpired: boolean = false;

	aclgroups: E5EntAclGroup[] = [];

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.username = E5Entity.AssignString(json.username);
		this.bearer = E5Entity.AssignString(json.bearer);
		this.lastname = E5Entity.AssignString(json.lastName);
		this.firstname = E5Entity.AssignString(json.firstName);
		this.email = E5Entity.AssignString(json.email);
		this.company = E5Entity.AssignString(json.company);
		this.jobtitle = E5Entity.AssignString(json.jobTitle);
		this.phonenumber = E5Entity.AssignString(json.phoneNumber);
		this.password = E5Entity.AssignString(json.password);

		this.roles = [];
		if (Array.isArray(json.roles)) for (let idx = 0; idx < json.roles.length; idx++)
			this.roles.push(json.roles[idx].name);

		if (json.lastSuccessfulLogin === null || json.lastSuccessfulLogin === undefined)
			this.lastsuccessfullogin = undefined;
		else this.lastsuccessfullogin = moment(json.lastSuccessfulLogin);

		if (json.accountExpirationDate === null || json.accountExpirationDate === undefined)
			this.accountexpirationdate = undefined;
		else this.accountexpirationdate = moment(json.accountExpirationDate);

		this.enabled = json.enabled === true;
		this.accountnonexpired = json.accountNonExpired === true;
		this.credentialsnonexpired = json.credentialsNonExpired === true;
		this.sessionexpired = json.sessionexpired === true;
		this.accountnonlocked = json.accountNonLocked === true;

		this.aclgroups = [];
		if (Array.isArray(json.aclGroups)) for (let idx = 0; idx < json.aclGroups.length; idx++)
			this.aclgroups.push(new E5EntAclGroup(json.aclGroups[idx]));
	}

	//E5
	CopyToJson(): any {
		let roles: any[] = [];
		for (let idx = 0; idx < this.roles.length; idx++) roles.push({"name": this.roles[idx]});

		return {
			username: this.username, bearer: this.bearer, lastName: this.lastname, firstName: this.firstname,
			email: this.email, company: this.company, jobTitle: this.jobtitle, phoneNumber: this.phonenumber,
			password: this.password, roles: roles,
			lastSuccessfulLogin: this.lastsuccessfullogin === undefined ? null : this.lastsuccessfullogin.format(),
			accountExpirationDate: this.accountexpirationdate === undefined ? null :
				this.accountexpirationdate.format(), enabled: this.enabled, accountNonExpired: this.accountnonexpired,
			credentialsNonExpired: this.credentialsnonexpired, sessionexpired: this.sessionexpired,
			accountNonLocked: this.accountnonlocked, aclGroups: this.aclgroups.map(acl => acl.CopyToJson())
		};
	}

	//E5
	GetDataFromBearer(): void {
		this.isconnected = false;
		if (this.bearer.length > 8) {
			try {
				let bearer: string = this.bearer.substr(7), bearerparts = bearer.split(".");
				if (bearerparts.length === 3) {
					let jsonstr: string = Base64.decode(bearerparts[1]), json: any = JSON.parse(jsonstr);
					this.username = json["username"];
					this.email = json["email"];
					this.roles = json["roles"].split(",");
					let exp: Moment = E5Text.Tm2MomentTz(parseInt(json["exp"]), E5Time.h_tzoffset);
					let now: Moment = moment();
					this.isconnected = exp > now;
					this.sessionexpired = !this.isconnected;
				}
			} catch (ex) {
				console.log(ex);
			}
		}
	}
}
