import {E5EntHMetSys} from "./E5EntHMetSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHMetSysCrash extends E5EntHMetSys {
	procname: string = "";

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.procname = E5Entity.AssignString(json.process_name);
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), process_name: this.procname};
	}
}
