import React from 'react';
import { observer } from "mobx-react";
import { Tooltip, Position, Button, Spinner, MenuItem, Icon, Intent, Menu, Popover, InputGroup } from '@blueprintjs/core';
//
import { E5StoreH, E5StoreHSearchNi, E5StoreHSearchNiAuto } from "../../store/E5StoreH";
import { E5RequestH } from "../../request/E5RequestH";
import { E5StoreLangInfo } from "../../store/E5Store";
import { E5MainConfig } from "../E5MainConfig";
import { E5UtilI18n } from "../E5MainLang";
//
import "./E5SearchNI.css"

//E5
interface E5SearchNIState {
	searchidsjsx: JSX.Element;
	searchidsopen: boolean;
	networkidtyped: string;
}

//E5
interface E5SearchNIProps {
	langinfo: E5StoreLangInfo;
	callback: (networkid: string) => void;
	searchniinfo: E5StoreHSearchNi;
	searchniautoinfo: E5StoreHSearchNiAuto;
	householdIdFromQuery: string;
}

//E5
export const E5SearchNI = observer(class E5SearchNI extends React.PureComponent<E5SearchNIProps, E5SearchNIState> {

	//E5
	searchinput: HTMLInputElement | undefined;
	searchinputref = (ref: any) => { this.searchinput = ref };
	minlen: number = 4;

	//E5
	constructor(props: E5SearchNIProps, state: E5SearchNIState) {
		super(props, state);
		this.state = { searchidsjsx: <div />, searchidsopen: false, networkidtyped: "" };
		if (E5MainConfig.GetDevMode())
			this.minlen = 1;

	}

	componentDidMount() {
		if (this.props.householdIdFromQuery) {
			this.props.searchniinfo.networkidtyped = this.props.householdIdFromQuery;
			this.Validate(this.props.householdIdFromQuery)
		}
	}
	
	componentDidUpdate() {
		this.searchinput?.focus({ preventScroll: true });
	}

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		//
		let autocomplete: JSX.Element = <div />;
		let refresh: JSX.Element = <div />;
		let loading = this.props.searchniautoinfo.status.loading ||
			this.props.searchniinfo.status.loading;
		// show buttons
		if (this.props.searchniinfo.networkidtyped.length >= this.minlen && !loading) {
			autocomplete =
				<Tooltip content={E5UtilI18n._("searchni-autocomplete")} position={Position.TOP_RIGHT}>
					<Button icon="lightbulb" onClick={this.AutoComplete} />
				</Tooltip>;
			refresh =
				<Tooltip content={E5UtilI18n._("searchni-refresh")} position={Position.TOP_RIGHT}>
					<Button icon="refresh" onClick={this.Validate} />
				</Tooltip>;
		}
		let rightelement: JSX.Element =
			<div>
				{autocomplete}
				{refresh}
				{loading && <Spinner className="e5spinwait" size={30} />}
			</div>;
		// show autocomplete
		let menu: JSX.Element[] = [];
		let idx: number;
		for (idx = 0; idx < this.props.searchniautoinfo.ids.length; idx++)
			menu.push(<MenuItem key={idx} text={this.props.searchniautoinfo.ids[idx]}
				onClick={this.AutoCompleteSelected} />);
		if (menu.length === 0)
			menu.push(<MenuItem key={idx} text={E5UtilI18n._("searchni-autocomplete-none")} />);
		let searchids: JSX.Element =
			<div className="e5searchniauto">
				<div className="e5line-2">
					<div className="e5linefull" />
					<Icon className="e5searchniautoclose" icon="delete" intent={Intent.DANGER}
						onClick={this.AutoCompleteClose} />
				</div>
				<Menu>
					{menu}
				</Menu>
			</div>;
		//
		return <div className="e5searchni e5line-10">
			<Popover
				content={searchids}
				position={Position.BOTTOM_LEFT}
				isOpen={this.state.searchidsopen}
			>
				<InputGroup
					disabled={loading}
					className="e5searchniinput"
					id="networkid"
					placeholder={E5UtilI18n._("searchni-label") + " :"}
					round={true}
					onKeyDown={this.KeyDown}
					onChange={this.Changed}
					value={this.props.searchniinfo.networkidtyped}
					inputRef={this.searchinputref}
					rightElement={rightelement}
				/>
			</Popover>
		</div>;
	}

	//E5
	Changed = (event: React.ChangeEvent<HTMLElement>) => {
		let value = (event.currentTarget as any).value;
		let st: any = { searchidsopen: false };
		this.setState(st, () => {
			E5StoreH.Ins().SetSearchNiTyped(value);
		});
	};

	//E5
	KeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
		if (event.keyCode === 13)
			this.Validate();
	};

	//E5
	Validate = (householdId) => {
		if (householdId && typeof householdId === 'string') {
			let loading = this.props.searchniautoinfo.status.loading ||
				this.props.searchniinfo.status.loading;
			this.setState({ searchidsopen: false });
			this.props.callback(householdId);
		} else {
			let loading = this.props.searchniautoinfo.status.loading ||
				this.props.searchniinfo.status.loading;
			if (this.props.searchniinfo.networkidtyped.length >= this.minlen && !loading) {
				this.setState({ searchidsopen: false });
				this.props.callback(this.props.searchniinfo.networkidtyped);
			}
		}

		this.searchinput?.focus({ preventScroll: true });
	};

	//E5
	AutoComplete = () => {
		let loading = this.props.searchniautoinfo.status.loading ||
			this.props.searchniinfo.status.loading;
		if (this.props.searchniinfo.networkidtyped.length >= this.minlen && !loading) {
			this.setState({ searchidsopen: false }, () => {
				E5RequestH.Ins().FetchNetworkIds(this.props.searchniinfo.networkidtyped.trim(), () => {
					this.setState({ searchidsopen: true });
				}, 300);
			});
		}
	};

	//E5
	AutoCompleteSelected = (event: React.MouseEvent<HTMLElement>) => {
		let value: string = event.currentTarget.innerText;
		this.setState({ searchidsopen: false, searchidsjsx: <div /> }, () => {
			E5StoreH.Ins().SetSearchNiTyped(value);
		});
	};

	//E5
	AutoCompleteClose = () => {
		this.setState({ searchidsopen: false, searchidsjsx: <div /> });
	};
});
