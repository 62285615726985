import moment, {Moment} from "moment";
//
import {E5Text} from "../../../util/E5Text";
import {E5Time} from "../../../util/E5Time";
import {E5Entity} from "../../E5Entity";

//E5
export interface E5HMetSysField {
	name: string;
	curveper?: string;
	group?: string[];
	categ?: boolean;
	unit: string;
	addholes: boolean;
}

//E5
export class E5EntHMetSys extends E5Entity {
	imei: string = "";
	endtime: Moment = moment();
	starttime: Moment = moment();
	time: Moment = moment();
	duration: number = 0;

	// ------------- STATIC --------------------

	static fields: E5HMetSysField[] = [
		{name: "reboot", unit: "", addholes: false},
		{name: "temperature", group: ["lowlimit", "highlimit", "tempval"], unit: "°C", addholes: true},
		{name: "cpuload", group: ["load1", "load5", "load15"], unit: "", addholes: true},
		{name: "memory", group: ["memtotal", "memfree", "memcached", "memcommit"], unit: "MB", addholes: true},
		{name: "uptime", unit: "h", addholes: true},
		{name: "crashevent", unit: "", addholes: false},
		{name: "processmemusage", curveper: "name", unit: "0-100%", addholes: true},
		{name: "processcputime", curveper: "name", unit: "h", addholes: true},
		{name: "flashtotalerase", curveper: "name", unit: "MB", addholes: true},
		{name: "flashavailerase", curveper: "name", unit: "MB", addholes: true},
		{name: "flashbadpebcnt", curveper: "name", unit: "", addholes: true},
		{name: "flashmaxerasecnt", curveper: "name", unit: "", addholes: true},
		{name: "flashvolumestrue", curveper: "name", unit: "", addholes: true},
		{name: "flashvolumesfalse", curveper: "name", unit: "", addholes: true}
	];

	// ------------- INSTANCE --------------------

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.imei = E5Entity.AssignString(json.node_imei);
		this.starttime = E5Text.Tm2MomentTz(E5Entity.ParseInt(json.bucket_start_time), E5Time.h_tzoffset);
		this.endtime = E5Text.Tm2MomentTz(E5Entity.ParseInt(json.bucket_end_time), E5Time.h_tzoffset);
		this.time = E5Text.Tm2MomentTz(E5Entity.ParseInt(json.time), E5Time.h_tzoffset);
		this.duration = E5Entity.ParseInt(json.duration);
	}

	//E5
	CopyToJson(): any {
		return {
			node_imei: this.imei, bucket_start_time: this.starttime.unix(), bucket_end_time: this.endtime.unix(),
			time: this.time.unix(), duration: this.duration
		};
	}
}
