
import * as BP from "@blueprintjs/core";
import * as BPT from "@blueprintjs/table";
import React, { useMemo, useState } from "react";
import { E5UtilI18n } from "../../../global/E5MainLang";
import styles from './RemoveSelectedUsersModal.module.css';
import { E5AdminUserSaveSend, E5AdminUsersAction } from "./E5AdminUsersList";
import { E5EntUser } from "../../../entity/E5EntUser";
import Button from "../../../global/component/Button";
type componentProps = {
    saveFunction: Function,
    deleteAction: E5AdminUsersAction.deleteMany,
    users: E5EntUser[],
    usersEmails: string[],
    onClose: () => void;
    updateRows: Function;
};

const RemoveSelectedUsersModal = ({ saveFunction, deleteAction, users, onClose, usersEmails, updateRows }: componentProps) => {


    const { usersForRemoving, usernames } = useMemo(() => {
        const usersForRemoving: E5EntUser[] = []
        const usernames: string[] = [];
        usersEmails && usersEmails.length && usersEmails.forEach((email => {
            const user = users.find((user) => user.email === email);
            usersForRemoving.push(user)
            usernames.push(user.username)
        }))
        return { usersForRemoving, usernames };
    }, [usersEmails]);

    const removeUser = async () => {
        saveFunction(deleteAction, null, null, () => onClose(), usernames, usersForRemoving, updateRows)

    }
    return <BP.Dialog isOpen={true} canOutsideClickClose={false} canEscapeKeyClose={false} className="delete-user-modal"
        title={'Delete the selected users'}
        onClose={() => onClose()}>
        <div className={styles.margin_line}></div>
        <div className={styles.usernameWrapper}>
            {(usersForRemoving && usersForRemoving.length) ? usersForRemoving.map((user: any) => <p className={styles.userFullname}>{user.firstname} {user.lastname}</p>) : <div />}
        </div>
        <div className={styles.buttonsWrapper}>
            <Button theme="red" onClick={() => removeUser()}>
                Delete
            </Button>
            <Button theme="blue" onClick={() => onClose()}>
                Close without deleting
            </Button>
        </div>
    </BP.Dialog>
}
export default RemoveSelectedUsersModal;