import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import { IReactionDisposer, reaction } from "mobx";
//
import { E5CBSysClass, E5CBSysClassDetails, E5CBSysMet, E5StoreCBSys, E5CBSysMetType } from "../../../store/E5StoreCBSys";
import { E5XYChart, E5XYChartClickCB, E5XYNumData, E5XYSource } from "../../../global/plot/E5XYChart";
import { E5RequestCallback, E5RequestStatus } from "../../../request/E5ServiceCommon";
import { E5PieChart, E5PieChartClickCB } from "../../../global/plot/E5PieChart";
import { E5CBHealthClass, E5StoreCB } from "../../../store/E5StoreCB";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5RequestCBSys } from "../../../request/E5RequestCBSys";
import { E5CBDashboardFilters } from "./E5CBDashboardFilters";
import { E5CBSysTemperature } from "./sys/E5CBSysTemperature";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5RequestCB } from "../../../request/E5RequestCB";
import { E5CBSysOverview } from "./sys/E5CBSysOverview";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5CBSysProcess } from "./sys/E5CBSysProcess";
import { E5CBSysMemory } from "./sys/E5CBSysMemory";
import { E5CBSysReboot } from "./sys/E5CBSysReboot";
import { E5CBSysFlash } from "./sys/E5CBSysFlash";
import { E5Store } from "../../../store/E5Store";
import { E5CBOverview } from "./E5CBOverview";
import { E5CBSysCpu } from "./sys/E5CBSysCpu";
import { E5Text } from "../../../util/E5Text";
import { E5CBWifi } from "./E5CBWifi";
import { E5CBWan } from "./E5CBWan";
//
import "./E5CBDashboard.css";

//E5
export type E5CBSysEqpType = "gw" | "ext" | "stb";

//E5
export type E5RgbStep = {
	red: number;
	green: number;
	blue: number;
	percent: number;
};

//E5
interface E5CBDashboardState {
	tabid: string;
	percent: boolean;
}

//E5
interface E5CBDashboardProps { }

//E5
export const E5CBDashboard = observer(class E5CBDashboard extends React.PureComponent
	<E5CBDashboardProps, E5CBDashboardState> {

	// ---------------- MEMBERS ----------------

	//E5
	stop_change_filter?: IReactionDisposer;
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5CBDashboardProps, state: E5CBDashboardState) {
		super(props, state);
		this.toasterref = React.createRef();
		this.downloadref = React.createRef();
		this.state = { tabid: "cb-dashboard-over", percent: true };
	}

	//E5
	componentDidMount(): void {
		this.stop_change_filter = reaction(() => E5StoreCB.Ins().filterinfo.populationid +
			E5StoreCB.Ins().filterinfo.startdate + E5StoreCB.Ins().filterinfo.enddate, () => {
				E5StoreCB.Ins().SetRequestSet(new Set());
				const pageRequests = ["cb-dashboard-over", "cb-dashboard-wifi", "cb-dashboard-wan", "cb-sys-overview", "system-cpu", "system-mem", "system-reb", "system-temp", "system-proc", "system-flash"];
				pageRequests.forEach((req) => E5CBDashboard.Fetch(req));
			});
	}

	//E5
	componentWillUnmount(): void {
		this.stop_change_filter?.();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { nilist, ninodelist, niusagelist} = E5StoreCB.Ins(),
			noid: boolean = E5StoreCB.Ins().filterinfo.populationid.length === 0;


		const mappedList = nilist.ids.map((value, index) => ({ name: value, node: ninodelist[index], value: niusagelist[index] })).sort((a, b) => Number(b.value) - Number(a.value))

		let systabids: string[] =
			["cb-sys-overview", "system-cpu", "system-mem", "system-reb", "system-temp", "system-proc", "system-flash"];
		let issystab: boolean = systabids.includes(this.state.tabid);

		return <div className="e5page e5cb-dashboard e5columnfull e5column-20">
			<BP.Toaster ref={this.toasterref} />
			<E5PageTitle titlekey="pagetitle-cb-dashboard" />
			<E5CBDashboardFilters />
			<BP.Tabs className="e5columnfull" renderActiveTabPanelOnly onChange={this.OnChangeTab}
				selectedTabId={this.state.tabid}>
				<BP.Tab title={_("wificb-dashboard-over")} id="cb-dashboard-over" disabled={noid} className={'initial-tab'}
					panel={<E5CBOverview toasterref={this.toasterref} downloadref={this.downloadref} />} />

				{E5MainConfig.GetWifiEnabled() &&
					<BP.Tab title={_("wificb-dashboard-wifi")} id="cb-dashboard-wifi" disabled={noid} className={'initial-tab'}
						panel={<E5CBWifi toasterref={this.toasterref} downloadref={this.downloadref} />} />}

				{E5MainConfig.GetWanEnabled() &&
					<BP.Tab title={_("wificb-dashboard-wan")} id="cb-dashboard-wan" disabled={noid} className={'initial-tab'}
						panel={<E5CBWan toasterref={this.toasterref} downloadref={this.downloadref} />} />}

				{E5MainConfig.GetSystemEnabled() &&
					<BP.Tab title={_("wificb-dashboard-system")} id="cb-dashboard-sys" disabled={noid} className={'initial-tab'} />}
				{/* 
				{E5MainConfig.GetSystemEnabled() && issystab &&
				<BP.Tab className="tab-disabled" title="&rsaquo;" disabled/>} */}

				{E5MainConfig.GetSystemEnabled() && issystab && [
					["cb-sys-overview", E5CBSysOverview], ["system-cpu", E5CBSysCpu], ["system-mem", E5CBSysMemory],
					["system-reb", E5CBSysReboot], ["system-proc", E5CBSysProcess],
					["system-flash", E5CBSysFlash], ["system-temp", E5CBSysTemperature],
				].filter(([name]) => name === "cb-sys-overview" ||
					(name === "system-cpu" && E5MainConfig.GetSystemCpuEnabled()) ||
					(name === "system-mem" && E5MainConfig.GetSystemMemoryEnabled()) ||
					(name === "system-reb" && E5MainConfig.GetSystemRebootEnabled()) ||
					(name === "system-temp" && E5MainConfig.GetSystemTemperatureEnabled()) ||
					(name === "system-proc" && E5MainConfig.GetSystemProcessEnabled()) ||
					(name === "system-flash" && E5MainConfig.GetSystemFlashEnabled())).map(([name, Component]) =>
						<BP.Tab key={name as string} title={_(name as string)} id={name as string} disabled={noid}
							panel={<Component toasterref={this.toasterref} downloadref={this.downloadref}
								percent={this.state.percent} togglefunc={this.TogglePercent} />} />)}
			</BP.Tabs>
			<BP.Dialog icon="people" title={_("cb-dashboard-nilist")} onClose={E5CBDashboard.CloseNiList}
				isOpen={nilist.ids.length > 0 && E5StoreCB.Ins().nilistsettings.nilistopen} className='e5cb-dashboard-dialog'>
				<div className="e5compo proc-table e5border e5rounded-md">
					<table>
						<thead>
							<tr>
								<th className="e5border e5p-2 e5bg-gray-100">{E5UtilI18n._("cb-dashboard-serialnumber")}</th>
								{!!ninodelist?.length && <th className="e5border e5p-2 e5bg-gray-100">{E5UtilI18n._("cb-dashboard-imei")}</th>}
								{!!niusagelist?.length && <th className="e5border e5p-2 e5bg-gray-100">{E5UtilI18n._("cb-dashboard-avgval-" + E5StoreCB.Ins().niType)}</th>}
							</tr>
						</thead>
						<tbody>
							{mappedList.map(({ name, node, value }) => (
								<tr key={name}>
									<td className="e5border e5p-2">{name}</td>
									{!!ninodelist?.length && <td className="e5border e5p-2">{node}</td>}
									{!!niusagelist?.length && <td className="e5border e5p-2 e5text-right">{value}{(E5StoreCB.Ins().niType === "crash") ? "" : " %"}</td>}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</BP.Dialog>
		</div>;
	}

	//E5
	static RenderPiePopover: (isopen: boolean, downloadref: React.RefObject<BP.Button>, onclick: any, onblur: any,
		childjsx: JSX.Element) => JSX.Element = (isopen: boolean, downloadref: React.RefObject<BP.Button>, onclick: any,
			onblur: any, childjsx: JSX.Element): JSX.Element => {
			let { nilist, nilistsettings } = E5StoreCB.Ins(), { loading } = nilist.status;
			return <BP.Popover className="e5linefull e5line-0" targetClassName="e5linefull" isOpen={isopen} content={
				<div className="e5cb-dashboard-downloaddiv">
					<div className="title">{nilistsettings.downloadstr}</div>
					{loading && <BP.Spinner className="e5spinwait" size={15} />}
					{!loading && <BP.Button text={E5UtilI18n._("download")} intent={BP.Intent.PRIMARY} autoFocus
						onClick={onclick} onBlur={onblur} ref={downloadref} />}
				</div>} position={BP.Position.TOP_RIGHT}>{childjsx}</BP.Popover>;
		};

	//E5
	static RenderEquipsPie: (key: E5CBSysMetType | "reboot" | "flash", equips: E5CBSysClass[] | null | undefined,
		loading: boolean, PieClick: E5PieChartClickCB, prefix?: "reb" | "upt" | "flusa" | "flcor") => JSX.Element = (key:
			E5CBSysMetType | "reboot" | "flash", equips: E5CBSysClass[] | null | undefined, loading: boolean,
			PieClick: E5PieChartClickCB, prefix?: "reb" | "upt" | "flusa" | "flcor"): JSX.Element => {
			let ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (equips === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (equips !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				let eqpset: Set<string> = new Set(), eqp: string;
				for (let obj of equips) {
					eqp = obj.eqptype === "" ? "N/A" : obj.eqptype;
					if (!eqpset.has(eqp)) {
						eqpset.add(eqp);
						ids.push(eqp);
						parents.push("total");
						values.push(0);
						labels.push(eqp);
					}
					ids.push("last;" + obj.rank + ";" + eqp + ";" + obj.class);
					parents.push(eqp);
					values.push(obj.count);
					labels.push(obj.class);
				}
			}

			return <E5PieChart pieinfo={{
				title: E5UtilI18n._("cb-sys-" + key + "-pie" + (prefix ?? "")), loading, valueisseconds: false,
				subtitle: E5UtilI18n._("cb-sys-pie-equips-sub"), ids, parents, values, labels, labelisincident: false,
				roundvalues: true, valsuffix: E5UtilI18n._("cb-sys-pie-val-suffix")
			}} clickcb={PieClick} chartOption={{ type: 'sunburst' }} withNewComponent />;
		};

	//E5
	static RenderDetailsPie: (key: E5CBSysMetType | "reboot" | "flash", details: E5CBSysClassDetails[] | null | undefined,
		loading: boolean, PieClick: E5PieChartClickCB, prefix?: "reb" | "upt" | "flusa" | "flcor", type?: 'GW' | 'EXT') => JSX.Element = (key:
			E5CBSysMetType | "reboot" | "flash", details: E5CBSysClassDetails[] | E5CBSysClassDetails[] | null | undefined, loading: boolean,
			PieClick: E5PieChartClickCB, prefix?: "reb" | "upt" | "flusa" | "flcor", type?: 'GW' | 'EXT'): JSX.Element => {
			let ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (details === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (details !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				let modelset: Set<string> = new Set(), sofvset: Set<string> = new Set();
				for (let obj of details) {
					let { model, sofv } = obj, modelsofv: string;
					model = model === "" ? "N/A" : model;
					sofv = sofv === "" ? "N/A" : sofv;
					modelsofv = sofv + ";" + model;
					if (!sofvset.has(modelsofv)) {
						sofvset.add(modelsofv);
						if (!modelset.has(model)) {
							modelset.add(model);
							ids.push(model);
							parents.push("total");
							values.push(0);
							labels.push(model);
						}
						ids.push(modelsofv);
						parents.push(model);
						values.push(0);
						labels.push(sofv);
					}
					ids.push("last;" + obj.rank + ";" + modelsofv + ";" + obj.class);
					parents.push(modelsofv);
					values.push(obj.count);
					labels.push(obj.class);
				}
			}
			let subtitle = E5UtilI18n._("cb-sys-pie-details-sub");
			if (type) {
				if (type === 'EXT') {
					subtitle = E5UtilI18n._("cb-sys-pie-ext-sub");
				} else {
					subtitle = E5UtilI18n._("cb-sys-pie-gw-sub");
				}
			}
			return <E5PieChart pieinfo={{
				title: E5UtilI18n._("cb-sys-" + key + "-pie" + (prefix ?? "")), loading, valueisseconds: false,
				subtitle: subtitle, ids, parents, values, labels, labelisincident: false,
				roundvalues: true, valsuffix: E5UtilI18n._("cb-sys-pie-val-suffix")
			}} clickcb={PieClick} chartOption={{ type: 'sunburst' }} withNewComponent />;
		};

	//E5
	static RenderChart: (key: E5CBSysMetType | "reboot" | "flash", name: E5CBSysEqpType, loading: boolean,
		percent: boolean, togglefunc?: () => void, prefix?: "reb" | "upt" | "flusa" | "flcor", type?: string) => JSX.Element = (key:
			E5CBSysMetType | "reboot" | "flash", name: E5CBSysEqpType, loading: boolean, percent: boolean,
			togglefunc?: () => void, prefix?: "reb" | "upt" | "flusa" | "flcor", type?: string): JSX.Element =>
			<div className="e5line-20">
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBDashboard.GetSource(key, name, percent, prefix)} rightsource={{}}
						title={E5UtilI18n._("cb-sys-" + key + "-" + name + (prefix ?? ""))} loading={loading}
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} height={350}
						withNewComponent chartOption={{ filled: false, type }}
						topcontent={togglefunc !== undefined ? <div className="topcontentswitch e5line-0">
							<BP.Switch large checked={percent} disabled={loading} onClick={() => togglefunc()} />
							<div className="modenamestr">
								{E5UtilI18n._("cb-dashboard-topcontentswitch-" + percent)}</div>
						</div> : <></>}
						normaltraceorder />
				</div>
			</div>;

	// ---------------- EVENTS ----------------

	//E5
	TogglePercent = (): void => this.setState({ percent: !this.state.percent });

	//E5
	OnChangeTab = (newtabid: string): void => {
		let tabid: string = newtabid === "cb-dashboard-sys" ? "cb-sys-overview" : newtabid;

		this.setState({ tabid }, () => E5CBDashboard.Fetch(tabid));
	};

	//E5
	static HealthClick: E5XYChartClickCB = (date: string, cla: string): void =>
		E5StoreCB.Ins().SetNiListSettings("health", E5Text.Substitute(
			E5UtilI18n._("cb-dashboard-downloadni-health"), ["1000", cla, date]), true, date, "",
			cla, "", "", "");

	static CpuClick: E5XYChartClickCB = (date: string, cla: string): void =>
		E5StoreCB.Ins().SetNiListSettings("cpu", E5Text.Substitute(
			E5UtilI18n._("cb-dashboard-downloadni-cpu"), ["100", cla, date]), true, date, "",
			cla, "", "", "");

	static MemClick: E5XYChartClickCB = (date: string, cla: string): void =>
		E5StoreCB.Ins().SetNiListSettings("mem", E5Text.Substitute(
			E5UtilI18n._("cb-dashboard-downloadni-mem"), ["100", cla, date]), true, date, "",
			cla, "", "", "");

	static CrashClick: E5XYChartClickCB = (date: string, cla: string): void =>
		E5StoreCB.Ins().SetNiListSettings("crash", E5Text.Substitute(
			E5UtilI18n._("cb-dashboard-downloadni-crash"), ["100", cla, date]), true, date, "",
			cla, "", "", "");

	//E5
	static DownloadNiListHealth: (type: string, date: string, cla: string, clarankmap: Map<string, string>,
		ref: React.RefObject<BP.Toaster>) => void = (type: string, date: string, cla: string,
			clarankmap: Map<string, string>, ref: React.RefObject<BP.Toaster>): void =>
			E5RequestCBSys.FetchNiListHealth(E5StoreCB.Ins().filterinfo.populationid, type,
				clarankmap.get(cla) ?? "", date, (status: E5RequestStatus) => {
					if (!status.success) ref.current?.show({
						message: E5UtilI18n._("cb-dashboard-downloaderr") + " : " + status.message,
						intent: BP.Intent.DANGER
					});
				});

	static DownloadNiListProcess: (type: string, date: string, cla: string,
		ref: React.RefObject<BP.Toaster>) => void = (type: string, date: string, cla: string, ref: React.RefObject<BP.Toaster>): void =>
			E5RequestCBSys.FetchNiListProcess(E5StoreCB.Ins().filterinfo.populationid, type,
				cla ?? "", date, (status: E5RequestStatus) => {
					if (!status.success) ref.current?.show({
						message: E5UtilI18n._("cb-dashboard-downloaderr") + " : " + status.message,
						intent: BP.Intent.DANGER
					});
				});

	//E5
	static PieClickEquip1: E5PieChartClickCB = (id: string, label: string): void => {
		if (id.startsWith("last;")) {
			let [cla, eqp] = id.slice(5, id.length).split(";");
			E5StoreCB.Ins().SetNiListSettings("equip1", E5Text.Substitute(
				E5UtilI18n._("cb-dashboard-downloadni-equip"), ["1000", label, eqp]), true, "",
				"", cla, eqp, "", "");
		}
	};

	//E5
	static PieClickEquip2: E5PieChartClickCB = (id: string, label: string): void => {
		if (id.startsWith("last;")) {
			let [cla, eqp] = id.slice(5, id.length).split(";");
			E5StoreCB.Ins().SetNiListSettings("equip2", E5Text.Substitute(
				E5UtilI18n._("cb-dashboard-downloadni-equip"), ["1000", label, eqp]), true, "",
				"", cla, eqp, "", "");
		}
	};

	//E5
	static PieClickDetail1: E5PieChartClickCB = (id: string, label: string): void => {
		if (id.startsWith("last;")) {
			let [cla, sofv, model] = id.slice(5, id.length).split(";");
			E5StoreCB.Ins().SetNiListSettings("detail1", E5Text.Substitute(
				E5UtilI18n._("cb-dashboard-downloadni-detail"), ["1000", label, sofv, model]), true,
				"", "", cla, "", sofv, model);
		}
	};

	//E5
	static PieClickDetail2: E5PieChartClickCB = (id: string, label: string): void => {
		if (id.startsWith("last;")) {
			let [cla, sofv, model] = id.slice(5, id.length).split(";");
			E5StoreCB.Ins().SetNiListSettings("detail2", E5Text.Substitute(
				E5UtilI18n._("cb-dashboard-downloadni-detail"), ["1000", label, sofv, model]), true,
				"", "", cla, "", sofv, model);
		}
	};

	//E5
	static DownloadNiListEquip: (systype: string, equip: string, cla: string,
		ref: React.RefObject<BP.Toaster>) => void = (systype: string, equip: string, cla: string,
			ref: React.RefObject<BP.Toaster>): void =>
			E5RequestCBSys.FetchNiListEquip(E5StoreCB.Ins().filterinfo.populationid, systype, equip, cla,
				E5StoreCB.Ins().filterinfo.startdate, E5StoreCB.Ins().filterinfo.enddate,
				(status: E5RequestStatus) => {
					if (!status.success) ref.current?.show({
						message: E5UtilI18n._("cb-dashboard-downloaderr") + " : " + status.message,
						intent: BP.Intent.DANGER
					});
				});

	//E5
	static DownloadNiListDetail: (systype: string, model: string, sofv: string, cla: string,
		ref: React.RefObject<BP.Toaster>) => void = (systype: string, model: string, sofv: string, cla: string,
			ref: React.RefObject<BP.Toaster>): void =>
			E5RequestCBSys.FetchNiListDetail(E5StoreCB.Ins().filterinfo.populationid, systype, model, sofv, cla,
				E5StoreCB.Ins().filterinfo.startdate, E5StoreCB.Ins().filterinfo.enddate,
				(status: E5RequestStatus) => {
					if (!status.success) ref.current?.show({
						message: E5UtilI18n._("cb-dashboard-downloaderr") + " : " + status.message,
						intent: BP.Intent.DANGER
					});
				});

	// ---------------- UTILS ----------------

	//E5
	static Fetch: (curtabid: string) => void = (curtabid: string): void => {
		let { populationid, startdate, enddate } = E5StoreCB.Ins().filterinfo, tabreqs: [
			string, (popid: string, startdate: string, enddate: string, callback: E5RequestCallback) => void
		][] = [
				["cb-dashboard-over", E5RequestCB.FetchGlobal], ["cb-dashboard-wifi", E5RequestCB.FetchWifi],
				["cb-dashboard-wan", E5RequestCB.FetchWan], ["cb-sys-overview", E5RequestCBSys.FetchOverview],
				["system-cpu", E5RequestCBSys.FetchCpu], ["system-mem", E5RequestCBSys.FetchMemory],
				["system-reb", E5RequestCBSys.FetchReboot], ["system-temp", E5RequestCBSys.FetchTemperature],
				["system-proc", E5RequestCBSys.FetchProcess], ["system-flash", E5RequestCBSys.FetchFlash]
			];
		if (populationid.length > 0) {
			if (!E5StoreCB.Ins().request.set.has(curtabid))
				for (let [tabid, Fetch] of tabreqs) if (curtabid === tabid) {
					Fetch(populationid, startdate, enddate, () => this.SetSysReqDone(curtabid));
					break;
				}
		} else {
			E5RequestCB.Ins().ClearAll();
			E5RequestCBSys.Ins().ClearAll();
		}
	};

	//E5
	static SetSysReqDone: (tabid: string) => void = (tabid: string): void => {
		let set: Set<string> = new Set(E5StoreCB.Ins().request.set);
		set.add(tabid);
		E5StoreCB.Ins().SetRequestSet(set);
	};

	//E5
	static GetScores: (healthmetmap: Map<string, Map<number, E5CBHealthClass>> | null | undefined, caller:
		{ clarankmap: Map<string, string> }, isNotStacked?: boolean | undefined | null
	) => E5XYSource = (healthmetmap: Map<string, Map<number,
		E5CBHealthClass>> | null | undefined, caller: { clarankmap: Map<string, string> }, isNotStacked = false): E5XYSource => {
			caller.clarankmap = new Map();
			let src: E5XYSource = { numdatas: undefined, options: { stacked: isNotStacked ? false : true, markers: true } };
			src.numdatas = [];
			let classmap: Map<number, E5XYNumData> = new Map(), numdata: E5XYNumData | undefined, rank: number;
			if (healthmetmap !== null && healthmetmap !== undefined)
				for (let [date, clamap] of healthmetmap) for (let [rank, { cla, count }] of clamap) {
					caller.clarankmap.set(cla, String(rank));
					numdata = classmap.get(rank);
					if (numdata === undefined) {
						numdata = { xaxisdata: [], yaxisdata: [], datalabel: cla };
						classmap.set(rank, numdata);
					}
					numdata.xaxisdata.push(date);
					numdata.yaxisdata.push(count);
				}
			for ([rank, numdata] of classmap) {
				numdata.fillcolor = E5CBDashboard.GetGradientColor(E5MainConfig.GetHealthColorSteps(),
					100 / (classmap.size - 1) * rank);
				src.numdatas.push(numdata);
			}
			src.numdatas.sort((a, b) => Number(a?.datalabel.charCodeAt(1)) - Number(b?.datalabel.charCodeAt(1)));
			return src;
		};

	//E5
	static GetSource: (key: E5CBSysMetType | "reboot" | "flash", name: E5CBSysEqpType, percent: boolean,
		prefix?: "reb" | "upt" | "flusa" | "flcor") => E5XYSource = (key: E5CBSysMetType | "reboot" | "flash",
			name: E5CBSysEqpType, percent: boolean, prefix?: "reb" | "upt" | "flusa" | "flcor"): E5XYSource => {
			let metrics: E5CBSysMet[] = key !== "reboot" && key !== "flash" ? E5StoreCBSys.Ins()[key].metrics :
				key === "reboot" ? prefix === "reb" ? E5StoreCBSys.Ins()[key].rebmetrics :
					E5StoreCBSys.Ins()[key].uptmetrics : prefix === "flusa" ? E5StoreCBSys.Ins()[key].flusametrics :
					E5StoreCBSys.Ins()[key].flcormetrics,
				src: E5XYSource = { numdatas: undefined, options: { stacked: true, percent, markers: true } };
			src.numdatas = [];
			let met: E5CBSysMet, metelem: E5CBSysClass, classmap: Map<number, E5XYNumData> = new Map(),
				namestr: string = name === "gw" ? "GWs" : name === "ext" ? "EXTs" : "STBs",
				numdata: E5XYNumData | undefined, rank: number;

			for (met of metrics) for (metelem of met[name]) {
				numdata = classmap.get(metelem.rank);
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: metelem.class };
					numdata.hovertemplate = (percent ? "%{y:.2f}% " + E5UtilI18n._("wificb-dashboard-of") : "%{y}") +
						" " + namestr + " (%{x})<extra>%{fullData.name}</extra>";
					classmap.set(metelem.rank, numdata);
				}
				numdata.xaxisdata.push(met.date);
				numdata.yaxisdata.push(metelem.count);
			}
			for ([rank, numdata] of classmap) {
				numdata.fillcolor = E5CBDashboard.GetGradientColor(E5MainConfig.GetClassColorSteps(),
					100 / (classmap.size - 1) * rank);
				src.numdatas.push(numdata);
			}
			return src;
		};

	static HexToRgb: (hex: string) => { red: number, green: number, blue: number } =
		(hex: string): { red: number, green: number, blue: number } => {
			if (hex.startsWith("#")) hex = hex.slice(1);
			return {
				red: parseInt(hex.substr(0, 2), 16),
				green: parseInt(hex.substr(2, 2), 16),
				blue: parseInt(hex.substr(4, 2), 16)
			};
		};

	static ColorToHex: (val: number) => string = (val: number): string => {
		const hex: string = val.toString(16);
		return hex.length === 1 ? "0" + hex : hex;
	};

	static RgbToHex: (rgb: E5RgbStep, withhash?: boolean) => string = (rgb: E5RgbStep, withhash?: boolean): string =>
		(withhash === true ? "#" : "") +
		this.ColorToHex(rgb.red) + this.ColorToHex(rgb.green) + this.ColorToHex(rgb.blue);

	static GetGradientColor: (steps: E5RgbStep[], percent: number) => string =
		(steps: E5RgbStep[], percent: number): string => {
			let start: [number, number, number] = [0, 0, 0], diff: [number, number, number] = [0, 0, 0], idx: number;
			for (idx = 1; idx < steps.length; idx++) {
				let cur: E5RgbStep = steps[idx], prev: E5RgbStep = steps[idx - 1];
				if (cur.percent >= percent) {
					start = [prev.red, prev.green, prev.blue];
					diff = [cur.red - start[0], cur.green - start[1], cur.blue - start[2]];
					percent = (percent - prev.percent) / (cur.percent - prev.percent);
					break;
				}
			}

			let rgbnew: string[] = diff.map((diff, idx) =>
				(diff * percent + start[idx]).toString(16).split(".")[0]).map(newstr =>
					newstr.length === 1 ? "0" + newstr : newstr);

			return "#".concat(...rgbnew);
		};

	//E5
	static CloseNiList: () => void = (): void => {
		E5StoreCB.Ins().SetNiList({ loading: false, success: false, message: "" }, []);
		E5StoreCB.Ins().SetNiListNodes([]);
		E5StoreCB.Ins().SetNiListUsages([]);
		E5StoreCB.Ins().SetNiListSettings("none", "", false, "", "", "",
			"", "", "");
	};
});
