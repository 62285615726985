import { E5EntUser } from "../../../entity/E5EntUser";
import E5UserProfile from "../../../global/component/E5UserProfile";
import { E5UtilI18n } from "../../../global/E5MainLang";

import "./E5ProfileInfo.css";

interface E5ProfileInfoProps {
    user: E5EntUser;
}

export default function E5ProfileInfo(props: Readonly<E5ProfileInfoProps>) {
    const name = props.user.firstname && props.user.lastname ? `${props.user.firstname} ${props.user.lastname}` : props.user.username;
    const isAdmin = props.user.roles.includes("ROLE_ADMIN");

    return (
        <div className="e5line-20 e5section e5sectionpadding">
            <div className="e5line-5">
                <E5UserProfile size={108} fontSize={50} upload={true} user={props.user} />
            </div>

            <div className="e5line-full">
                <p className="username">{name}</p>
                <p className="e5text">{props.user.email}</p>
                {
                    isAdmin &&
                    (
                        <p className="e5text e5textweight600 e5admintext">
                            {E5UtilI18n._("roles-legend-table-admin")}
                        </p>
                    )
                }
                <div>
                    <span className="e5text e5marginright-8">
                        {E5UtilI18n._("profile-last-connection")}:
                    </span>
                    <span className="e5textweight600">
                        {props.user.lastsuccessfullogin?.format("DD/MM/YYYY")}
                    </span>
                </div>
            </div>
        </div>
    )
}