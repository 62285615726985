import {E5EntHIncidSys} from "./E5EntHIncidSys";
import {E5Entity} from "../../E5Entity";

//E5
export class E5EntHIncidSysReb extends E5EntHIncidSys {
	rebreasons: string[] = [];

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		let idx: number;
		super.CopyFromJson(json);

		this.rebreasons = [];
		if (Array.isArray(json.reboot_reason)) for (idx = 0; idx < json.reboot_reason.length; idx++)
			this.rebreasons.push(E5Entity.AssignString(json.reboot_reason[idx]));
	}

	//E5
	CopyToJson(): any {
		return {...super.CopyToJson(), reboot_reason: this.rebreasons};
	}
}
