import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5XYChart, E5XYNumData, E5XYSource } from "../../../global/plot/E5XYChart";
import { E5PieChart, E5PieChartClickCB } from "../../../global/plot/E5PieChart";
import { E5HDashboard } from "../../household/dashboard/E5HDashboard";
import { E5AngularGauge } from "../../../global/plot/E5AngularGauge";
import { E5RequestStatus } from "../../../request/E5ServiceCommon";
import { E5RequestMeta } from "../../../request/E5RequestMeta";
import { E5RequestCB } from "../../../request/E5RequestCB";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5StoreCB } from "../../../store/E5StoreCB";
import { E5Store } from "../../../store/E5Store";
import { E5CBWifiIndic } from "./E5CBWifiIndic";
import { E5CBDashboard } from "./E5CBDashboard";
import { E5Text } from "../../../util/E5Text";
//
import "./E5CBWifi.css";

//E5
interface E5CBWifiState {
	downloadstr: string | undefined;
	nilistopen: boolean;
	incid: string;
}

//E5
interface E5CBWifiProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
}

//E5
export const E5CBWifi = observer(class E5CBWifi extends React.PureComponent<E5CBWifiProps, E5CBWifiState> {

	// ---------------- MEMBERS ----------------

	static clarankmap: Map<string, string> = new Map();

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5CBWifiProps, state: E5CBWifiState) {
		super(props, state);
		this.state = { downloadstr: undefined, nilistopen: false, incid: "", dataFor5: {} };
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { wifi } = E5StoreCB.Ins(), { nilist, nilistsettings } = E5StoreCB.Ins(),
			{ status, ids } = nilist, { mode, downloadstr, date, cla } = nilistsettings;

		let chanok: boolean = wifi.chanconntimes.length > 0,
			chansrc: E5XYSource = { numdatas: [], options: { bar: chanok, yistime: chanok } },
			chansrc1: E5XYSource = { numdatas: [], options: { bar: chanok, yistime: chanok } },
			chansrc2: E5XYSource = { numdatas: [], options: { bar: chanok, yistime: chanok } };

		const wifi24ChannelIndex = wifi.chanconntimes.findIndex((el) => el?.band === "2_4GHZ");
		const wifi5ChannelIndex = wifi.chanconntimes.findIndex((el) => el?.band === "5GHZ");
		const wifi6ChannelIndex = wifi.chanconntimes.findIndex((el) => el?.band === "6GHZ");

		if (wifi24ChannelIndex !== -1) {
			E5HDashboard.BuildChanConnTimeSource([wifi.chanconntimes[wifi24ChannelIndex]], chansrc, true);
		}

		if (wifi5ChannelIndex !== -1) {
			E5HDashboard.BuildChanConnTimeSource([wifi.chanconntimes[wifi5ChannelIndex]], chansrc1, true);
		}

		if (wifi6ChannelIndex !== -1) {
			E5HDashboard.BuildChanConnTimeSource([wifi.chanconntimes[wifi6ChannelIndex]], chansrc2, true);
		}

		let [autoids, autoparents, autovalues, autolabels] = E5CBWifi.GetAutoPieData(),
			[stdids, stdparents, stdvalues, stdlabels] = E5CBWifi.GetStandardPieData(),
			[incids, incparents, incvalues, inclabels] = E5CBWifi.GetIncidentPieData();

		return <div className="e5cb-wifi e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5CBWifiIndic />
					<E5AngularGauge gaugeinfo={
						{ value: wifi.health, label: "Wi-Fi", title: 'Wi-Fi Health score', loading: false }} isNewComponent chartOption={{ type: 'gauge' }} />
				</div>
				<div className="e5linefull2">
					<div className="e5line-0 h-100">
						<BP.Popover className="e5linefull e5line-0" targetClassName="e5linefull"
							isOpen={mode === "health"} content={<div className="e5cb-dashboard-downloaddiv">
								<div className="title">{downloadstr}</div>
								{nilist.status.loading && <BP.Spinner className="e5spinwait" size={15} />}
								{!nilist.status.loading && <BP.Button
									text={E5UtilI18n._("download")} intent={BP.Intent.PRIMARY} autoFocus
									onClick={() => E5CBDashboard.DownloadNiListHealth("wifiHealth", date, cla,
										E5CBWifi.clarankmap, this.props.toasterref)}
									onBlur={() => E5StoreCB.Ins().SetNiListSettings("none", "",
										undefined, undefined, undefined, undefined, undefined,
										undefined, undefined)} ref={this.props.downloadref} />}
							</div>} position={BP.Position.BOTTOM_LEFT}>
							<E5XYChart leftsource={E5CBDashboard.GetScores(wifi.healthmetmap, E5CBWifi)}
								rightsource={{}} title={_("cb-health-over-time")} loading={false}
								xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }}
								clickcb={E5CBDashboard.HealthClick} height={350} withNewComponent chartOption={{ filled: true, newColorScheme: true }} />
						</BP.Popover>
					</div>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull2">
					<E5XYChart
						helpcontent={<div className="e5cb-incidents-legend">{_("cb-incidents-help")}</div>}
						leftsource={E5CBWifi.GetIncidents()} rightsource={{}} title={_("cb-inc-over-time")}
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }}
						loading={false} height={350} subtitle={_("cb-incidents-sub")} withNewComponent chartOption={{ filled: false }} />
				</div>
				<div className="e5linefull">
					<div className="e5line-0">
						<BP.Popover
							className="e5linefull e5line-0" targetClassName="e5linefull"
							content={<div className="e5cb-dashboard-downloaddiv">
								<div className="title">{this.state.downloadstr}</div>
								{!status.loading && <BP.Button
									text={_("download")} onBlur={() => { this.setState({ downloadstr: undefined }); }}
									onClick={this.DownloadNI} ref={this.props.downloadref} intent={BP.Intent.PRIMARY}
									autoFocus />}
								{status.loading && <BP.Spinner className="e5spinwait" size={15} />}
							</div>} position={BP.Position.TOP_RIGHT} isOpen={this.state.downloadstr !== undefined}>
							<E5PieChart pieinfo={{
								title: _("cb-pie-inc-cat"), valueisseconds: true, labelisincident: true,
								ids: incids, parents: incparents, values: incvalues, labels: inclabels, loading: false
							}} clickcb={this.PieClick} helpcontent={<div className="e5cb-incidents-legend">
								{_("cb-wifi-pie-categ-help")}
							</div>} withNewComponent />
						</BP.Popover>
					</div>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBWifi.GetConnectionsBands()} rightsource={{}}
						title={_("cb-wifi-connections-bands")} loading={false} height={350}
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} withNewComponent chartOption={{ filled: false }} />
				</div>
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBWifi.GetConnectionsStandards()} rightsource={{}}
						title={_("cb-wifi-connections-standards")} loading={false} height={350}
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} withNewComponent chartOption={{ filled: false }} />
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">
					<E5XYChart leftsource={chansrc} rightsource={{}} title={'2.4 GHz channels'}
						hidelegend={true} loading={false} height={350} />
				</div>
				<div className="e5linefull">
					<E5XYChart leftsource={chansrc1} rightsource={{}} title={'5 GHz channels'}
						hidelegend={true} loading={false} height={350} />
				</div>
			</div>
	
			{wifi6ChannelIndex !== -1 && <div className="e5line-20">
				<div style={{ margin: '0 auto' }}>
					<E5XYChart leftsource={chansrc2} rightsource={{}} title={'6 GHz channels'}
						hidelegend={true} loading={false} height={350} width={600} />
				</div>
			</div>}
			<div className="e5line-20">
				<div className="e5linefull">
					<E5PieChart pieinfo={{
						title: _("cb-wifi-pie-autochan"), valueisseconds: false, labelisincident: false,
						ids: autoids, parents: autoparents, values: autovalues, labels: autolabels, loading: false
					}} withNewComponent chartOption={{ colors: ['#7EE8F6', '#AED581', '#F472B6'] }} />
				</div>
				<div className="e5linefull">
					<E5PieChart pieinfo={{
						title: _("cb-wifi-pie-bandstd"), valueisseconds: true, labelisincident: false,
						ids: stdids, parents: stdparents, values: stdvalues, labels: stdlabels, loading: false
					}} withNewComponent chartOption={{ type: 'sunburst', parentId: "Wi-Fi" }} />
				</div>
			</div>
			<BP.Dialog icon="people" title={_("cb-dashboard-nilist")} onClose={this.CloseNiList}
				isOpen={ids.length > 0 && this.state.nilistopen}>
				<BP.TextArea className="e5cb-dashboard-nilistarea" value={ids.join("\n")} />
			</BP.Dialog>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	PieClick: E5PieChartClickCB = (id: string, label: string, parentlabel: string): void => {
		if (id.startsWith("I0")) {
			let title: string;
			[id] = id.split(";");
			if (id.length > E5RequestMeta.Ins().h_incidentcateglen) {
				// bug in plotly, sometimes the parentlabel is the parent id !
				[parentlabel] = parentlabel.split(";");
				if (parentlabel.startsWith("I0") && parentlabel.length === E5RequestMeta.Ins().h_incidentcateglen)
					parentlabel = E5UtilI18n._("meta-wifi-incidentcateg-code-" + parentlabel);
				id = id.substr(0, E5RequestMeta.Ins().h_incidentcateglen + 2);
				title = E5Text.Substitute(E5UtilI18n._("cb-dashboard-downloadni-incid"),
					["100", id, E5UtilI18n._("meta-wifi-incident-code-" + id), parentlabel]);
			} else title = E5Text.Substitute(E5UtilI18n._("wificb-dashboard-downloadni-cat"),
				["100", id, label]);
			this.setState({ incid: id, downloadstr: title, nilistopen: true });
		}
	};

	//E5
	DownloadNI = (): void =>
		E5RequestCB.Ins().FetchNiList(E5StoreCB.Ins().filterinfo.populationid, this.state.incid,
			E5StoreCB.Ins().filterinfo.startdate, E5StoreCB.Ins().filterinfo.enddate,
			(status: E5RequestStatus) => {
				if (!status.success) this.props.toasterref.current?.show({
					message: E5UtilI18n._("cb-dashboard-downloaderr") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});

	//E5
	CloseNiList = (): void => {
		E5StoreCB.Ins().SetNiList({ loading: false, success: false, message: "" }, []);
		this.setState({ nilistopen: false });
	};

	// ---------------- UTILS ----------------

	//E5
	static GetIncidents: () => E5XYSource = (): E5XYSource => {
		let src: E5XYSource = { numdatas: undefined, options: { stacked: false, markers: true, rangemode: "tozero" } };
		src.numdatas = [];
		let bandmap: Map<string, E5XYNumData> = new Map(), numdata: E5XYNumData | undefined, { wifi } = E5StoreCB.Ins();
		if (wifi.catcritmap !== null && wifi.catcritmap !== undefined)
			for (let [date, clamap] of wifi.catcritmap) for (let [inccat, criticality] of clamap) {
				if (inccat.startsWith("I02") || inccat.startsWith("I03")) continue;
				numdata = bandmap.get(inccat + "crit");
				if (numdata === undefined) {
					numdata = {
						xaxisdata: [], yaxisdata: [],
						datalabel: E5UtilI18n._("meta-wifi-incidentcateg-code-" + inccat) +
							E5UtilI18n._("cb-wifi-incidents-criticality")
					};
					bandmap.set(inccat + "crit", numdata);
				}
				numdata.xaxisdata.push(date);
				numdata.yaxisdata.push(E5Text.RoundUp(criticality / 3600));
			}
		if (wifi.catdurmap !== null && wifi.catdurmap !== undefined)
			for (let [date, clamap] of wifi.catdurmap) for (let [inccat, duration] of clamap) {
				numdata = bandmap.get(inccat + "dur");
				if (numdata === undefined) {
					numdata = {
						xaxisdata: [], yaxisdata: [],
						datalabel: E5UtilI18n._("meta-wifi-incidentcateg-code-" + inccat) +
							E5UtilI18n._("cb-wifi-incidents-duration"), dotted: true
					};
					bandmap.set(inccat + "dur", numdata);
				}
				numdata.xaxisdata.push(date);
				numdata.yaxisdata.push(E5Text.RoundUp(duration / 3600));
			}
		for ([, numdata] of bandmap) src.numdatas.push(numdata);
		return src;
	};

	//E5
	static GetConnectionsBands: () => E5XYSource = (): E5XYSource => {
		let src: E5XYSource = { numdatas: undefined, options: { stacked: false, bar: true, barstack: true } };
		src.numdatas = [];
		let bandmap: Map<string, E5XYNumData> = new Map(), numdata: E5XYNumData | undefined, { wifi } = E5StoreCB.Ins();
		if (wifi.connbandmap !== null && wifi.connbandmap !== undefined)
			for (let [date, clamap] of wifi.connbandmap) for (let [band, count] of clamap) {
				numdata = bandmap.get(band);
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._(band) };
					bandmap.set(band, numdata);
				}
				numdata.xaxisdata.push(date);
				numdata.yaxisdata.push(count);
			}
		for ([, numdata] of bandmap) src.numdatas.push(numdata);
		return src;
	};

	//E5
	static GetConnectionsStandards: () => E5XYSource = (): E5XYSource => {
		let src: E5XYSource = { numdatas: undefined, options: { stacked: false, bar: true, barstack: true } };
		src.numdatas = [];
		let stdmap: Map<string, E5XYNumData> = new Map(), numdata: E5XYNumData | undefined, { wifi } = E5StoreCB.Ins();
		if (wifi.connstdmap !== null && wifi.connstdmap !== undefined)
			for (let [date, clamap] of wifi.connstdmap) for (let [std, count] of clamap) {
				numdata = stdmap.get(std);
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._("wificb-indic-w" + std) };
					stdmap.set(std, numdata);
				}
				numdata.xaxisdata.push(date);
				numdata.yaxisdata.push(count);
			}
		for ([, numdata] of stdmap) src.numdatas.push(numdata);
		return src;
	};

	//E5
	static GetAutoPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let { wifi } = E5StoreCB.Ins(),
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (wifi.autochanmap === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (wifi.autochanmap !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				for (let [key, count] of wifi.autochanmap) {
					ids.push(key === "" ? "N/A" : key);
					parents.push("total");
					values.push(count);
					labels.push(key === "" ? "N/A" : key);
				}
			}
			return [ids, parents, values, labels];
		};

	//E5
	static GetStandardPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let { wifi } = E5StoreCB.Ins(),
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (wifi.conntimemap === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (wifi.conntimemap !== undefined) {
				ids = ["Wi-Fi"];
				parents = [""];
				values = [0];
				labels = ["Wi-Fi"];
				let bandset: Set<string> = new Set(), bandstdset: Set<string> = new Set(), bandstd: string;
				for (let [key, duration] of wifi.conntimemap) {
					let [band, standard, bandwidth] = key.split(";");
					band = band === "" ? "?band" : band;
					standard = standard === "" ? "N/A" : standard;
					bandwidth = bandwidth === "" ? "N/A" : bandwidth;
					bandstd = band + ":" + standard;
					if (!bandstdset.has(bandstd)) {
						bandstdset.add(bandstd);
						if (!bandset.has(band)) {
							bandset.add(band);
							ids.push(band);
							labels.push(band);
							parents.push("Wi-Fi");
							values.push(0);
						}
						ids.push(bandstd);
						labels.push(standard);
						parents.push(band);
						values.push(0);
					}
					ids.push(band + ":" + standard + ":" + bandwidth);
					labels.push(bandwidth);
					parents.push(bandstd);
					values.push(duration);
				}
			}
			return [ids, parents, values, labels];
		};

	//E5
	static GetIncidentPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let { wifi } = E5StoreCB.Ins(),
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (wifi.incidcritmap === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (wifi.incidcritmap !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				let bandset: Set<string> = new Set(), catset: Set<string> = new Set(), categ: string;
				for (let [key, criticality] of wifi.incidcritmap) {
					let [band, incidcode] = key.split(";");
					if (!incidcode.startsWith("I01")) continue;
					categ = incidcode.substr(0, E5RequestMeta.Ins().h_incidentcateglen);
					if (!catset.has(categ + ";" + band)) {
						catset.add(categ + ";" + band);
						if (!bandset.has(band)) {
							bandset.add(band);
							ids.push(band);
							parents.push("total");
							values.push(0);
							labels.push(band);
						}
						ids.push(categ + ";" + band);
						parents.push(band);
						values.push(0);
						labels.push(E5UtilI18n._("meta-wifi-incidentcateg-code-" + categ));
					}
					ids.push(incidcode + ";" + categ + band);
					parents.push(categ + ";" + band);
					values.push(criticality);
					labels.push(incidcode);
				}
			}
			return [ids, parents, values, labels];
		};
});
