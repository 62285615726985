import { Moment } from "moment";
//
import { E5BandEnum, E5DeviceTypeEnum } from "../../E5Enums";
import { E5Text } from "../../../util/E5Text";
import { E5Time } from "../../../util/E5Time";
import { E5Entity } from "../../E5Entity";
import { FingerprintResult } from "../../../store/E5StoreH";

//E5 E5StoreH.Ins().indicglobalinfo
export class E5EntHStation extends E5Entity {
	hostname: string = "";
	ip: string = "";
	devicetype: string = "";
	macaddress: string = "";
	wifistdavailable: string[] = [];
	wifistdused: string[] = [];
	health: number | null = 0;
	band: E5BandEnum = E5BandEnum.none;
	channelinuse: number = 0;
	curbandwidth: string = "";
	lastseen: Moment | null = null;
	linktype: string = "";
	maxbitrate: number = 0;
	devicetypecateg: E5DeviceTypeEnum = E5DeviceTypeEnum.unknown;
	connectedStation: string = "";
	uptime: number = 0;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		this.hostname = E5Entity.AssignString(json.hostname);
		//this.deviceinfo = E5Entity.AssignString(json.deviceInfo);
		this.ip = E5Entity.AssignString(json.ip_address);
		this.devicetype = E5Entity.AssignString(json.device_type);
		this.macaddress = E5Entity.AssignString(json.mac_address);
		this.health = E5Entity.ParseFloat(json.health);
		this.wifistdavailable = E5Entity.ProcessStandards(json.capabilities);
		this.wifistdused = E5Entity.ProcessStandards(json.standards_used);
		this.band = Object.values(E5BandEnum).includes(json.band) ? json.band : E5BandEnum.none;
		this.connectedStation = E5Entity.AssignString(json.node_imei);
		this.channelinuse = E5Entity.ParseInt(json.channel_in_use);

		this.curbandwidth = E5Entity.AssignString(json.current_operating_bandwidth);

		let lastseen: number | null = E5Entity.ParseIntNull(json.last_seen);
		this.lastseen = lastseen === null ? null : E5Text.Tm2MomentTz(lastseen, E5Time.h_tzoffset);

		this.linktype = E5Entity.AssignString(json.link_type);
		this.maxbitrate = E5Entity.ParseInt(json.max_bit_rate);
		this.uptime = E5Entity.ParseInt(json.uptime)
		this.devicetypecateg = this.SetDeviceTypeCategory();
	}

	//E5
	CopyToJson(): any {
		return {
			band: this.band, channel_in_use: this.channelinuse, current_operating_bandwidth: this.curbandwidth,
			last_seen: this.lastseen?.unix() ?? null, link_type: this.linktype, max_bit_rate: this.maxbitrate,
			hostname: this.hostname, ip_address: this.ip, device_type: this.devicetype, mac_address: this.macaddress,
			health: this.health, capabilities: this.wifistdavailable, standards_used: this.wifistdused,
			connectedStation: this.connectedStation,
		};
	}

	//E5
	SetDeviceTypeCategory = (): E5DeviceTypeEnum => {
		let type: E5DeviceTypeEnum;
		switch (this.devicetype.toLowerCase()) {
			case "":
			case "none":
			case "unknown":
				type = E5DeviceTypeEnum.unknown;
				break;
			case "pc":
			case "computer":
			case "notebooks":
			case "desktop":// simulator
				type = E5DeviceTypeEnum.desktop;
				break;
			case "phone":
			case "mobile_phone":
			case "smartphone":
			case "pda":
			case "iphone":// simulator
				type = E5DeviceTypeEnum.mobile;
				break;
			case "gameconsole":
			case "game console":
				type = E5DeviceTypeEnum.gaming;
				break;
			case "smart tv":
				type = E5DeviceTypeEnum.tv;
				break;
			case "stb":
			case "tv_decoder":
				type = E5DeviceTypeEnum.tvadapter;
				break;
			case "tablet":
				type = E5DeviceTypeEnum.tablet;
				break;
			default:
				type = E5DeviceTypeEnum.default;
		}
		return type;
	}
}
