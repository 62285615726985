import { Spinner, Toaster } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { E5EntUser } from '../../entity/E5EntUser';
import { getUserProfilePicture, updateUserProfile } from '../../request/E5RequestProfile';
import { E5Store } from '../../store/E5Store';
import { E5UtilI18n } from '../E5MainLang';
import './E5UserProfile.css';

interface E5UserProfileProps {
    size: number;
    fontSize: number;
    upload?: boolean;
    user: E5EntUser;
}

export default function E5UserProfile(props: Readonly<E5UserProfileProps>) {
    // force rerender when lang changes
    const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

    const [profilePicture, setProfilePicture] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    const toasterref: React.RefObject<Toaster> = React.createRef();

    useEffect(() => {
        fetchImage();
    }, []);

    const fetchImage = async () => {
        try {
            const response = await getUserProfilePicture(props.user.username);

            const blob = new Blob([response.data], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(blob);
            setProfilePicture(imageUrl);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            if (profilePicture) {
                URL.revokeObjectURL(profilePicture);
            }
        };
    }, [profilePicture]);

    const UploadProfilePicture = (event: any) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('image', event.target.files[0]);

        updateUserProfile(props.user.username, formData)
            .then(() => {
                toasterref.current?.show({ message: E5UtilI18n._('profile-pic-updated'), intent: 'success' });
                window.location.reload();
            })
            .catch(() => {
                toasterref.current?.show({ message: E5UtilI18n._('profile-pic-not-updated'), intent: 'danger' });
            });
    }

    const formatUserInitials = (user: E5EntUser) => {
        if (user.firstname && user.lastname) {
            return `${user.firstname.charAt(0).toUpperCase()}${user.lastname.charAt(0).toUpperCase()}`;
        } else {
            return `${props.user.username.charAt(0)}${props.user.username.charAt(1)}`;
        }
    }

    if (loading) {
        return (
            <div className="e5-align-center" style={{ width: props.size, height: props.size }}>
                <Spinner size={30} />
            </div>
        );
    }

    return (
        <>
            <Toaster ref={toasterref} />

            <div className="circle" style={{ width: props.size, height: props.size }}>
                {profilePicture ?
                    <img className="profile-picture" style={{ width: props.size, height: props.size }} src={profilePicture} alt="profile" />
                    :
                    <span className="inner-circle" style={{ fontSize: props.fontSize }}>{formatUserInitials(props.user)}</span>
                }
                {props.upload &&
                    <div className="camera-container">
                        <label htmlFor="profile" className={`upload ${profilePicture ? 'has-image' : 'no-image'}`}>
                            <img src="./img/v3/profile/camera.svg" alt="upload" />
                        </label>
                        <input type="file" id="profile" accept="image/*" className="camera"
                            style={{ display: "none" }} onChange={UploadProfilePicture} />
                    </div>
                }
            </div>
        </>
    )
}