import { E5RgbStep } from "../page/customer_base/dashboard/E5CBDashboard";

export class E5MainConfig {
	// from json
	defaultlang: string = "en";
	// backendurl: string = "http://localhost:8090/becmswifi/api/";
	backendurl: string = "https://cms-dev-rnd.sagemcom.com/becmswifi/api/"
	// backendurl: string = 'https://cms-demo.sagemcom.com/becmswifi/api/'
	customerlogo: string = "";
	devmode: boolean = false;
	devmodedate: string = "";
	devmodepage: string = "";
	maxinlist: number = 10;
	showlayoutbuts: boolean = false;
	holesizesec: number = 700;
	extenabled: boolean = true;
	stbenabled: boolean = true;
	greystbenabled: boolean = true;
	wifienabled: boolean = true;
	wanenabled: boolean = true;
	systemenabled: boolean = true;
	systemcpuenabled: boolean = true;
	systemmemoryenabled: boolean = true;
	systemrebootenabled: boolean = true;
	systemtemperatureenabled: boolean = true;
	systemprocessenabled: boolean = true;
	systemflashenabled: boolean = true;
	rdmenabled: boolean = true;
	nbdayshousehold: number = 7;
	nbdayscustomerbase: number = 29;
	healthcolorsteps: E5RgbStep[] = [];
	classcolorsteps: E5RgbStep[] = [];
	incidcolorsteps: E5RgbStep[] = [];
	incidentcolors: any = {};

	// static
	frontversion: string = "2.16.1";
	cmsversion: string = "RC5";

	//E5
	private InsSetConfig(obj: E5MainConfig): void {
		Object.assign(this, obj);
	}

	// ------- STATIC ------------

	private static ins: E5MainConfig;
	static configurl: string = "/frontendconf/client_config.json";
	static ls_bearer: string = "cms-fe-bearer";

	//E5
	private static Ins(): E5MainConfig {
		if (this.ins === undefined) this.ins = new E5MainConfig();
		return this.ins;
	}

	//E5
	static SetConfig = (obj: E5MainConfig): void => this.Ins().InsSetConfig(obj);

	//E5
	static GetDefaultLang = (): string => this.Ins().defaultlang;

	//E5
	static GetBackendUrl = (): string => this.Ins().backendurl;

	//E5
	static GetDevMode = (): boolean => this.Ins().devmode;

	//E5
	static GetDevModeDate = (): string => this.Ins().devmodedate;

	//E5
	static GetDevModePage = (): string => this.Ins().devmodepage;

	//E5
	static GetFrontVersion = (): string => this.Ins().frontversion;

	//E5
	static GetCmsVersion = (): string => this.Ins().cmsversion;

	//E5
	static GetMaxInList = (): number => this.Ins().maxinlist;

	//E5
	static GetCustomerLogo = (): string => this.Ins().customerlogo;

	//E5
	static GetShowLayoutButs = (): boolean => this.Ins().showlayoutbuts;

	//E5
	static GetHoleSizeSec = (): number => this.Ins().holesizesec;

	//E5
	static GetExtEnabled = (): boolean => this.Ins().extenabled;

	//E5
	static GetStbEnabled = (): boolean => this.Ins().stbenabled;

	static GetGreyStbEnabled = (): boolean => this.Ins().greystbenabled;

	//E5
	static GetWifiEnabled = (): boolean => this.Ins().wifienabled;

	//E5
	static GetWanEnabled = (): boolean => this.Ins().wanenabled;

	//E5
	static GetSystemEnabled = (): boolean => this.Ins().systemenabled;

	//E5
	static GetSystemCpuEnabled = (): boolean => this.Ins().systemcpuenabled;

	//E5
	static GetSystemMemoryEnabled = (): boolean => this.Ins().systemmemoryenabled;

	//E5
	static GetSystemRebootEnabled = (): boolean => this.Ins().systemrebootenabled;

	//E5
	static GetSystemTemperatureEnabled = (): boolean => this.Ins().systemtemperatureenabled;

	//E5
	static GetSystemProcessEnabled = (): boolean => this.Ins().systemprocessenabled;

	//E5
	static GetSystemFlashEnabled = (): boolean => this.Ins().systemflashenabled;

	//E5
	static GetRdmEnabled = (): boolean => this.Ins().rdmenabled;

	//E5
	static GetNbDaysHousehold = (): number => this.Ins().nbdayshousehold;

	//E5
	static GetNbDaysCustomerBase = (): number => this.Ins().nbdayscustomerbase;

	//E5
	static GetHealthColorSteps = (): E5RgbStep[] => this.Ins().healthcolorsteps;

	//E5
	static GetClassColorSteps = (): E5RgbStep[] => this.Ins().classcolorsteps;

	//E5
	static GetIncidColorSteps = (): E5RgbStep[] => this.Ins().incidcolorsteps;

	//E5
	static GetIncidentColors = (): any => this.Ins().incidentcolors;
}
