import React from "react";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import * as BPT from "@blueprintjs/table";

import { E5AdminRdmSortDir, E5EntRdmCommand } from "../../../entity/rdm/E5EntRdmCommand";
import { E5RequestAdminRdm } from "../../../request/E5RequestAdminRdm";
import { E5EntRdmRequest } from "../../../entity/rdm/E5EntRdmRequest";
import { E5EntRdmStatus } from "../../../entity/rdm/E5EntRdmStatus";
import { E5RequestStatus } from "../../../request/E5ServiceCommon";
import { E5Store, E5StoreLangInfo } from "../../../store/E5Store";
import { E5EntRdmTtl } from "../../../entity/rdm/E5EntRdmTtl";
import { E5AdminRdmReqLegend } from "./E5AdminRdmReqLegend";
import { E5AdminRdmResponse } from "./E5AdminRdmResponse";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5AdminRdmPopup } from "./E5AdminRdmPopup";
import { ReactComponent as Filter } from '../../../assets/table-filter.svg'
import Table from '../../../global/component/E5Table'
import {
	E5StoreAdmin, E5StoreAdminRdmCmds, E5StoreAdminRdmInfo, E5StoreAdminRdmRequest, E5StoreAdminRdmStatus,
	E5StoreAdminRdmTtl, E5StoreAdminRdmUi
} from "../../../store/E5StoreAdmin";
//
import dayjs from "dayjs";
import "./E5AdminRdmList.css";
import StyledMobileDatePicker from '../../../global/component/DatePickerStyled';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputStyledField from "../../../global/component/InputStyledField";
import { Button, Classes, Dialog, IconName, Intent, Menu, MenuItem, Popover, PopoverInteractionKind, Position, Spinner, Toaster, Tooltip } from "@blueprintjs/core";

//E5
interface E5AdminRdmListState {
	activefilter: any;
	filterinput: any;
	sortfield: string;
	sortdirection: E5AdminRdmSortDir;
	curpage: number;
	maxpage: number;
	dialogopen: boolean;
	cancelopen: boolean;
	listcancelopen: boolean;
	listresponseopen: boolean;
	curselectedcmd: any; //temp
	cureditedcmd: E5EntRdmCommand;
	cureditedcmdchanged: boolean;
	filteredData: E5EntRdmCommand[];
	userFilter: string;
	equipmentFilter: string;
	householdFilter: string;
	fromDate: string;
	untilDate: string;
	isFiltered: boolean;
	statusFilter: number;
	typeFilter: number;
}

//E5
interface E5AdminRdmListProps {
	langinfo: E5StoreLangInfo;
	ttlinfo: E5StoreAdminRdmTtl;
	statusinfo: E5StoreAdminRdmStatus;
	requestinfo: E5StoreAdminRdmRequest;
	cmdinfo: E5StoreAdminRdmCmds;
	uiinfo: E5StoreAdminRdmUi;
	rdminfo: E5StoreAdminRdmInfo;
}

type Prams = {
	row: {
		updatetime: string,
		userlogin: string,
		createtime: any,
		requestid: string,
		statusid: string,
		firmware: string,
		model: string,
		networkid: string,
		nodeimei: string,
		ttlid: string,
		uuid: string,
		deviceId: string,
		fromDate: string,
		untilDate: string,
	}
}
//E5
export const E5AdminRdmList = observer(class E5AdminRdmList extends React.PureComponent
	<E5AdminRdmListProps, E5AdminRdmListState> {

	// ---------------- MEMBERS ----------------

	//E5
	filtertimer: any;
	requiredfield: any;
	toasterref: React.RefObject<Toaster>;
	statusFilter = [
		{
			label: E5UtilI18n._('all'),
			value: 0
		},
		...E5StoreAdmin.Ins().rdmstatusinfo.statuslist.map((status) => {
			return {
				label: `${status.name[0].toUpperCase()}${status.name.slice(1).toLowerCase()}`,
				value: status.id
			}
		})
	];
	typeFilter = [
		{
			label: E5UtilI18n._('all'),
			value: 0
		},
		...this.props.requestinfo.requestlist.map((type) => {
			return {
				label: type.name,
				value: type.id
			}
		})
	];

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5AdminRdmListProps, state: E5AdminRdmListState) {
		super(props, state);
		this.toasterref = React.createRef();
		this.requiredfield = {
			userlogin: true, nodeimei: true, requestid: true, ttlid: true
		};
		this.state = {
			activefilter: {}, filterinput: {}, sortfield: "createtime", sortdirection: E5AdminRdmSortDir.down,
			curpage: 0, maxpage: 0, dialogopen: false, cancelopen: false, listcancelopen: false,
			listresponseopen: false, curselectedcmd: new E5EntRdmCommand(), cureditedcmd: new E5EntRdmCommand(),
			cureditedcmdchanged: false,
			filteredData: [],
			userFilter: "",
			equipmentFilter: "",
			householdFilter: "",
			statusFilter: -1,
			typeFilter: -1,
			fromDate: new Date(0).toUTCString(),
			untilDate: new Date().toString(),
			isFiltered: false,
		};
	}
	onChangeFilterByDateFrom = (date: any) => {
		if (date) {
			const formattedDate = dayjs(date.$d).format('YYYY-MM-DD');
			this.setState({ fromDate: formattedDate })
		} else {
			this.setState({ fromDate: '' })
		}
		//this.showResetFiltersButton()
	}
	onChangeFilterByDateUntil = (date: any) => {
		if (date) {
			const formattedDate = dayjs(date.$d).format('YYYY-MM-DD');
			this.setState({
				untilDate: formattedDate 
			})
		} else {
			this.setState({
				untilDate: new Date().toString()
			})
		}
		//this.showResetFiltersButton()
	}
	filterData(user: string, equipment: string, household: string, fromDate: string, untilDate: string, type?: number, status?: number) {
		if (!user) {
			user = "";
		}
		if (!equipment) {
			equipment = "";
		}
		if (!household) {
			household = "";
		}

		this.setState({
			statusFilter: status ?? -1,
			typeFilter: type ?? -1,
		});

		const filteredData = this.props.cmdinfo?.commands.filter(item => {
			// Check if user input matches the item's properties
			const normalizedFromDate = new Date(`${fromDate}T00:00:00`);
			const normalizedUntilDate = this.parseOrOriginalDate(untilDate);
			const normalizedCreateTime = new Date(item.createtime);
			const userMatch = !user || item.userlogin.toLowerCase().includes(user.toLowerCase());
			const equipmentMatch = !equipment || item.nodeimei.toLowerCase().includes(equipment.toLowerCase());
			const householdMatch = !household || item.networkid.toLowerCase().includes(household.toLowerCase());
			const dateMatch = (!fromDate || !untilDate) || (normalizedCreateTime >= normalizedFromDate && normalizedCreateTime <= normalizedUntilDate);
			const typeMatch = type && type > 0 && this.state.statusFilter ? item.requestid === type : true;
			const statusMatch = status && status > 0 && this.state.typeFilter ? item.statusid === status : true;

			// Return true only if all criteria match
			return userMatch && equipmentMatch && dateMatch && householdMatch && typeMatch && statusMatch;
		});

		this.setState({ filteredData, isFiltered: true });
	}

	parseOrOriginalDate(date: string): Date {
		const newDate = new Date(`${date}T00:00:00`);
		if (isNaN(newDate.getTime())) {
			const formatedDate = dayjs(date).format('YYYY-MM-DD');
			return new Date(`${formatedDate}T00:00:00`);
		}

		return newDate;
	}

	//E5
	componentDidMount(): void {
		// load if nothing
		if (this.props.cmdinfo.commands.length === 0) this.LaunchSearch();
		this.setState({ filteredData: this.props.cmdinfo?.commands })
	}

	//E5
	componentDidUpdate(_previousProps: E5AdminRdmListProps, previousState: E5AdminRdmListState): void {
		if (previousState.fromDate !== this.state.fromDate) {
			this.filterData(this.state.userFilter,
				this.state.equipmentFilter,
				this.state.householdFilter,
				this.state.fromDate,
				this.state.untilDate,
				this.state.statusFilter,
				this.state.typeFilter)
		};
		if (previousState.untilDate !== this.state.untilDate) {
			this.filterData(this.state.userFilter,
				this.state.equipmentFilter,
				this.state.householdFilter,
				this.state.fromDate,
				this.state.untilDate,
				this.state.statusFilter,
				this.state.typeFilter)
		};
	}

	//E5
	componentWillUpdate(): void {
		if (this.state.filteredData.length === 0 && this.props.cmdinfo?.commands && !this.state.isFiltered) {
			this.setState({ filteredData: this.props.cmdinfo?.commands })
		}
	}

	//E5
	componentWillUnmount(): void {
		clearTimeout(this.filtertimer);
	}
	// Sample data



	// Function to filter data based on user input


	// Example usage:



	//console.log(filteredData); // Output: [{ user: "John", equipment: "Laptop", household: "Kitchen" }]

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		let idx: number, field: string, curcmdisnew: boolean = this.state.cureditedcmd.uuid === "", curcmdhasall = true;
		if (curcmdisnew) {
			for (field in this.requiredfield) {
				if (field === "ttlid" || field === "requestid") {
					if ((this.state.cureditedcmd as any)[field] === 0) curcmdhasall = false;
				} else if ((this.state.cureditedcmd as any)[field] === "") curcmdhasall = false;
			}
		}
		let curcmdisscheduled: boolean = this.state.curselectedcmd.statusid === this.props.statusinfo.scheduledid,
			filterdisabled: boolean = this.props.cmdinfo.status.loading;

		// paging
		// let haspaging = false;
		// if (this.props.cmdinfo.commands.length === this.linescount || this.state.curpage > 0) haspaging = true;
		let paging: JSX.Element[] = [];
		paging.push(<Tooltip key="pgfirst" content={E5UtilI18n._("firstpage")} position={Position.TOP}
			disabled={false}>
			<Button className="pagingbut" icon="fast-backward" onClick={this.FirstPage}
				disabled={false} small />
		</Tooltip>);
		paging.push(<Tooltip key="pgprev" content={E5UtilI18n._("prevpage")} position={Position.TOP}
			disabled={false}>
			<Button className="pagingbut" icon="caret-left" onClick={this.PrevPage}
				disabled={false} small />
		</Tooltip>);
		for (idx = 0; idx <= this.state.maxpage; idx++) {
			paging.push(<Button key={"pg:" + idx} id={"pg:" + idx} className="pagingbut" onClick={this.ChangePage}
				disabled={false} small text={idx + 1} />);
		}
		paging.push(<Tooltip key="pgnext" content={E5UtilI18n._("nextpage")} position={Position.TOP}
			disabled={false}>
			<Button className="pagingbut" icon="caret-right" onClick={this.NextPage}
				disabled={false} small={true} />
		</Tooltip>);

		// filter createtime
		let createstarthasfilter: string = (this.state.filterinput.createtime !== undefined &&
			this.state.filterinput.createtime.start !== undefined) ? " hasfilter" : "",
			createendhasfilter: string = (this.state.filterinput.createtime !== undefined &&
				this.state.filterinput.createtime.end !== undefined) ? " hasfilter" : "";
		let createstartval: Moment | undefined = undefined, createendval: Moment | undefined = undefined;
		if (this.state.filterinput.createtime !== undefined) {
			createstartval = this.state.filterinput.createtime.start;
			createendval = this.state.filterinput.createtime.end;
		}
		let createmax: Moment = moment(), createmin: Moment = moment();
		createmin.subtract(1, "year");

		// filter statusname
		let statusopt: JSX.Element[] = [<option key="status" value="" />];
		for (idx = 0; idx < this.props.statusinfo.statuslist.length; idx++) {
			let st: E5EntRdmStatus = this.props.statusinfo.statuslist[idx];
			statusopt.push(<option key={"status" + idx} value={st.id}>{st.name}</option>);
		}
		let statushasfilter: string = this.state.filterinput.statusid !== undefined ? " hasfilter" : "",
			statusval: number = this.state.filterinput.statusid !== undefined ? this.state.filterinput.statusid : "";

		// filter request type
		let reqopt: JSX.Element[] = [<option key={"req"} value="" />];
		for (idx = 0; idx < this.props.requestinfo.requestlist.length; idx++) {
			let req: E5EntRdmRequest = this.props.requestinfo.requestlist[idx];
			reqopt.push(<option key={"req" + idx} value={req.id}>{req.name}</option>);
		}
		let reqhasfilter: string = this.state.filterinput.requestid !== undefined ? " hasfilter" : "",
			reqval: string = this.state.filterinput.requestid !== undefined ? this.state.filterinput.requestid : 0;

		// filter userlogin
		let userloginval: string = "";
		let userloginintent: Intent = Intent.NONE;
		if (this.state.filterinput.userlogin !== undefined) {
			userloginval = this.state.filterinput.userlogin;
			userloginintent = Intent.DANGER;
		}

		// filter firmware
		let firmwareval: string = "";
		let firmwareintent: Intent = Intent.NONE;
		if (this.state.filterinput.firmware !== undefined) {
			firmwareval = this.state.filterinput.firmware;
			firmwareintent = Intent.DANGER;
		}

		// filter model
		let modelval: string = "";
		let modelintent: Intent = Intent.NONE;
		if (this.state.filterinput.model !== undefined) {
			modelval = this.state.filterinput.model;
			modelintent = Intent.DANGER;
		}

		// filter nodeimei
		let nodeimeival: string = "";
		let nodeimeiintent: Intent = Intent.NONE;
		if (this.state.filterinput.nodeimei !== undefined) {
			nodeimeival = this.state.filterinput.nodeimei;
			nodeimeiintent = Intent.DANGER;
		}

		// filter networkid
		let networkidval: string = "";
		let networkidintent: Intent = Intent.NONE;
		if (this.state.filterinput.networkid !== undefined) {
			networkidval = this.state.filterinput.networkid;
			networkidintent = Intent.DANGER;
		}

		// filter uuid
		let uuidval: string = "";
		let uuidintent: Intent = Intent.NONE;
		if (this.state.filterinput.uuid !== undefined) {
			uuidval = this.state.filterinput.uuid;
			uuidintent = Intent.DANGER;
		}

		const filterPopover = (
			<Menu className={Classes.ELEVATION_1}>
				<MenuItem className="e5filter-header-menu" text={
					<div className="e5line-5 e5filter-header">
						<p>{E5UtilI18n._('admin-rdm-filter')}</p>
						<button className="e5admin-rdm-filterbuttonreset" onClick={(_e) => {
							this.filterData(
								this.state.userFilter,
								this.state.equipmentFilter,
								this.state.householdFilter,
								this.state.fromDate,
								this.state.untilDate,
							)
						}}>{E5UtilI18n._('admin-rdm-filterreset')}</button>
					</div>
				} />
				<MenuItem className="e5filter-menu" text={
					<>
						<p>{E5UtilI18n._('admin-rdm-filtertype')}</p>
						<small>{this.state.typeFilter > -1 && this.typeFilter.find((status) => status.value === this.state.typeFilter)?.label}</small>
					</>
				}>
					{
						this.typeFilter.map((type) => {
							return (
								<MenuItem key={type.label} text={type.label} onClick={(_e) => {
									this.filterData(
										this.state.userFilter,
										this.state.equipmentFilter,
										this.state.householdFilter,
										this.state.fromDate,
										this.state.untilDate,
										type.value,
										this.state.statusFilter
									)
								}}/>
							)
						})
						
					}
				</MenuItem>
				<MenuItem className="e5filter-menu" text={
					<>
						<p>{E5UtilI18n._('admin-rdm-statusid')}</p>
						<small>{this.state.statusFilter > -1 && this.statusFilter.find((status) => status.value === this.state.statusFilter)?.label}</small>
					</>
				}>
					{this.statusFilter.map((status) => {
						return (
							<MenuItem key={status.label} text={status.label} onClick={(_e) => {
								this.filterData(
									this.state.userFilter,
									this.state.equipmentFilter,
									this.state.householdFilter,
									this.state.fromDate,
									this.state.untilDate,
									this.state.typeFilter,
									status.value
								)
							}} />
						)
					})}
				</MenuItem>
			</Menu>
		);

		let buttontextstr: string;
		if (this.CheckHasFilter()) buttontextstr = E5UtilI18n._("admin-rdm-button-search");
		else buttontextstr = E5UtilI18n._("admin-rdm-button-refresh");

		return <div className="e5admin-rdm-list e5columnfull e5column-20">
			<Toaster ref={this.toasterref} />
			<div className="e5compo e5column-5 header">
				<div className="e5line-5 e5admin-rdm-titleline">
					<div className="e5compotitle">{E5UtilI18n._("admin-rdm-filters")}</div>
					{this.props.cmdinfo.status.loading && <Spinner className="e5spinwait" size={25} />}
				</div>
				<div className="e5line-5 e5admin-rdm-filterline">
					<div className="e5admin-rdm-filterbloc">
						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-filterinput"
							label={E5UtilI18n._("admin-rdm-userlogin")}
							variant="outlined"
							autoComplete="true"
							onChange={(e) => {
								this.setState({ userFilter: e.target.value })
								this.filterData(
									e.target.value,
									this.state.equipmentFilter,
									this.state.householdFilter,
									this.state.fromDate,
									this.state.untilDate,
									this.state.statusFilter,
									this.state.typeFilter
								)
							}}
							value={this.state.userFilter}
							inputProps={{ sx: { borderRadius: 3 } }}
						/>
					</div>
					<div className="e5admin-rdm-filterbloc">
						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-filterinput"
							label={E5UtilI18n._("admin-rdm-nodeimei")}
							variant="outlined"
							autoComplete="true"
							onChange={(e) => {
								this.setState({ equipmentFilter: e.target.value })
								this.filterData(
									this.state.userFilter,
									e.target.value,
									this.state.householdFilter,
									this.state.fromDate,
									this.state.untilDate,
									this.state.statusFilter,
									this.state.typeFilter
								)
							}}
							value={this.state.equipmentFilter}
							inputProps={{ sx: { borderRadius: 3 } }}
						/>
					</div>
					<div className="e5admin-rdm-filterbloc">
						<InputStyledField
							id="outlined-basic"
							className="e5admin-rdm-filterinput"
							label={E5UtilI18n._("admin-rdm-networkid")}
							variant="outlined"
							autoComplete="true"
							onChange={(e) => {
								this.setState({ householdFilter: e.target.value })
								this.filterData(
									this.state.userFilter,
									this.state.equipmentFilter,
									e.target.value,
									this.state.fromDate,
									this.state.untilDate,
									this.state.statusFilter,
									this.state.typeFilter
								)
							}}
							value={this.state.householdFilter}
							inputProps={{ sx: { borderRadius: 3 } }}
						/>
					</div>
					<div className={"e5admin-rdm-filterbloc filterblock-date" + createstarthasfilter}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<div className="datepicker-wrapper">
								<StyledMobileDatePicker className="datepicker-from-rdm" label={E5UtilI18n._("admin-rdm-createstart")}
									value={this.state.fromDate === new Date(0).toUTCString() ? null : dayjs(this.state.fromDate)}
									onChange={this.onChangeFilterByDateFrom}
								/>
								<div style={{ position: 'relative' }}>
									<StyledMobileDatePicker className="datepicker-to-rdm" label={E5UtilI18n._("admin-rdm-createend")}
										value={this.state.untilDate === new Date().toString() ? null : dayjs(this.state.untilDate)}
										onChange={this.onChangeFilterByDateUntil} />
								</div>
							</div>
						</LocalizationProvider>
					</div>

					<Popover
						openOnTargetFocus hasBackdrop
						placement={Position.BOTTOM}
						interactionKind={PopoverInteractionKind.CLICK}
						minimal={true}
						content={filterPopover}
					>
						<Filter className='filter-icon' />
					</Popover>

					<E5AdminRdmReqLegend langinfo={E5Store.Ins().langinfo} requestinfo={E5StoreAdmin.Ins().rdmrequestinfo} />
					<Button icon="add" text={E5UtilI18n._("admin-rdm-popup-add")} onClick={this.AddCmd} />
				</div>
			</div>
			<div className="e5admin-rdm-rdmtableouter e5columnfull">
				<div className="e5admin-rdm-rdmtableinner">
					<Table className='' rowHeight={35}
						pageSize={25}
						columns={[
							{
								field: 'createtime',
								headerName: 'Creation',
								editable: true,
								headerAlign: 'center',
								align: 'left',
								height: 45,
								valueGetter: (params: Prams) => {
									return params.row.createtime?.format(E5UtilI18n._("dateformat") + " HH:mm")
								},
							},
							{
								field: 'userlogin',
								headerName: 'User',
								editable: true,
								headerAlign: 'center',
								align: 'left',
								height: 45,
								renderCell: (params: Prams) => {
									return params.row.userlogin;
								},
							},
							{
								field: 'type',
								headerName: 'Type',
								editable: true,
								headerAlign: 'center',
								align: 'left',
								height: 45,
								valueGetter: (params: Prams) => {
									const availableTypes = E5StoreAdmin.Ins().rdmrequestinfo.requestlist;
									const currentType = availableTypes.find((type) => type.id.toString() === params.row.requestid.toString())
									return currentType?.name;
								},
							},
							{
								field: 'status',
								headerName: 'Status',
								editable: true,
								headerAlign: 'center',
								align: 'left',
								height: 45,
								renderCell: (params: Prams) => {
									const statuses = E5StoreAdmin.Ins().rdmstatusinfo;
									const currentStatusByStatusId = statuses.statuslist.find((item) => item.id.toString() === params.row.statusid.toString())
									return <div className={`role-in-table rdm-status-id-${currentStatusByStatusId?.id}`}>{`${currentStatusByStatusId?.name[0].toUpperCase()}${currentStatusByStatusId?.name.slice(1)}`}</div>
								},
								valueGetter: (params: Prams) => {
									const statuses = E5StoreAdmin.Ins().rdmstatusinfo;
									const currentStatusByStatusId = statuses.statuslist.find((item) => item.id.toString() === params.row.statusid.toString())
									return `${currentStatusByStatusId?.name[0].toUpperCase()}${currentStatusByStatusId?.name.slice(1)}`
								},
							},
							{
								field: 'networkid',
								headerName: 'Household id',
								editable: true,
								headerAlign: 'center',
								align: 'left',
								height: 45,
								valueGetter: (params: Prams) => {
									return params.row.networkid;
								},
							},
							{
								field: 'deviceId',
								headerName: 'Equipment id',
								editable: true,
								headerAlign: 'center',
								align: 'left',
								height: 45,
								valueGetter: (params: Prams) => {
									return params.row.nodeimei;
								},
							},
							{
								field: 'ttlid',
								headerName: 'TTL',
								editable: true,
								headerAlign: 'center',
								height: 45,
								align: 'left',
								valueGetter: (params: Prams) => {
									const currentTtl = E5StoreAdmin.Ins().rdmttlinfo.ttllist.find((item) => item.id.toString() === params.row.ttlid.toString())
									return currentTtl?.name;
								},
							}
						]}
						rows={[
							...this.state.filteredData.map((row, index) => {
								return ({
									...row,
									id: index,
								})
							})
						]}
						onRowClick={(this.OpenCmd)}
					/>
				</div>
			</div>
			<Dialog isOpen={this.state.dialogopen} canOutsideClickClose={false}
				canEscapeKeyClose={false} onClose={this.CloseCmd} style={{ width: "900px" }}>
					<div className={Classes.DIALOG_HEADER}>
						<div className="e5admin-rdm-marginauto">
							<h4 className="bp3-heading">
								{E5UtilI18n._(curcmdisnew ? "admin-rdm-popup-add" : "admin-rdm-uiactionedit")}
							</h4>
						</div>

						{curcmdisnew && <E5AdminRdmReqLegend langinfo={E5Store.Ins().langinfo} requestinfo={E5StoreAdmin.Ins().rdmrequestinfo} onlyicon/>}
					</div>
					<div className={Classes.DIALOG_BODY}>
						<E5AdminRdmPopup langinfo={this.props.langinfo} cmd={this.state.curselectedcmd}
							updatefunc={this.CmdIsUpdated} ttlinfo={E5StoreAdmin.Ins().rdmttlinfo}
							statusinfo={E5StoreAdmin.Ins().rdmstatusinfo} userinfo={E5Store.Ins().userinfo}
							requestinfo={E5StoreAdmin.Ins().rdmrequestinfo} />
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							{(!this.props.cmdinfo.status.loading && !this.props.uiinfo.status.loading)
								? <>
									{(this.state.cureditedcmdchanged && curcmdhasall) &&
										<Button className="e5-rdm-adm-nomargin e5-rdm-savebutton" text={E5UtilI18n._(curcmdisnew ? "admin-rdm-createaction" : "update")} onClick={this.HandleSave} />}
									{!curcmdisnew && <Button className={`e5-rdm-deletebutton ${this.state.cureditedcmdchanged && curcmdhasall ? '': 'e5-rdm-adm-nomargin'}`} text={E5UtilI18n._("delete")} onClick={this.HandleNewCancel}/>}
									<div style={{ flex: 1 }} />
									<Button className="e5-rdm-savenochangebutton" text={E5UtilI18n._("admin-rdm-closenosave")} onClick={this.CloseCmd} />
								</>
								: <div style={{ height: "30px" }}><Spinner size={30} /></div>
							}
						</div>
					</div>
			</Dialog>
			<Dialog icon="delete" title={E5UtilI18n._("admin-rdm-cancel")} isOpen={this.state.listcancelopen}
				canOutsideClickClose={true} canEscapeKeyClose={true} onClose={this.CloseListCancel}
				style={{ width: "600px" }}>
				<div className={Classes.DIALOG_BODY}>
					<div>{E5UtilI18n._("admin-rdm-confirmcancel")}</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						{(!this.props.cmdinfo.status.loading && !this.props.uiinfo.status.loading)
							? <>
								<Button text={E5UtilI18n._("admin-rdm-cancel")}
									intent={Intent.DANGER} onClick={this.HandleListCancel2} />
								<Button text={E5UtilI18n._("admin-rdm-closenocancel")}
									onClick={this.CloseListCancel} />
							</>
							: <div style={{ height: "40px" }}><Spinner size={30} /></div>
						}
					</div>
				</div>
			</Dialog>
			<Dialog
				icon="horizontal-bar-chart" title={E5UtilI18n._("admin-rdm-fullresponse")} canEscapeKeyClose={true}
				canOutsideClickClose={true} style={{ width: "800px", height: "500px", transition: "none" }}
				isOpen={this.state.listresponseopen} onClose={this.CloseListFullResponse}>
				<div className={Classes.DIALOG_BODY + " e5admin-rdm-fullresponsedial"}>
					<div className="e5admin-rdm-fullresponsecontent">
						<E5AdminRdmResponse
							cmd={this.state.curselectedcmd} requestinfo={E5StoreAdmin.Ins().rdmrequestinfo}
							statusinfo={E5StoreAdmin.Ins().rdmstatusinfo} langinfo={E5Store.Ins().langinfo} />
					</div>
				</div>
			</Dialog>
		</div>;
	}

	//E5
	RenderColumn = (field: string): JSX.Element =>
		<BPT.Column cellRenderer={(ridx) => this.CellRenderer(ridx, field)}
			columnHeaderCellRenderer={() => this.HeadRenderer(field)} />;

	//E5
	CellRenderer = (rowidx: number, field: string): JSX.Element => {
		let cell: JSX.Element = <div />;
		if (rowidx < this.props.cmdinfo.commands.length) {
			// double check commands.length because this code may be called outside of boundaries
			let val: JSX.Element = <div />;
			let item: E5EntRdmCommand = this.props.cmdinfo.commands[rowidx];
			let datavalue: any = (item as any)[field];
			let curselectedcmd: E5EntRdmCommand = this.props.cmdinfo.commands[rowidx];
			if (field === "uiaction") {
				cell = <div className="rdmactioncell">
					<Tooltip content={E5UtilI18n._("admin-rdm-uiactionedit")} position={Position.RIGHT}
						boundary={"window"}>
						<Button icon="edit" onClick={() => this.OpenCmd(curselectedcmd)} small minimal />
					</Tooltip>
					<Tooltip content={E5UtilI18n._("admin-rdm-canceltip")} position={Position.RIGHT}
						boundary="window"
						disabled={curselectedcmd.statusid !== this.props.statusinfo.scheduledid}>
						<Button icon="delete" onClick={() => this.HandleListCancel(curselectedcmd)} small
							disabled={curselectedcmd.statusid !== this.props.statusinfo.scheduledid} minimal />
					</Tooltip>
					<Tooltip content={E5UtilI18n._("admin-rdm-showresponse")} position={Position.RIGHT}
						boundary="window" disabled={curselectedcmd.response === ""}>
						<Button icon="horizontal-bar-chart" onClick={() => this.HandleListResponse(curselectedcmd)}
							disabled={curselectedcmd.response === ""} small minimal />
					</Tooltip>
				</div>
			} else {
				if (field === "createtime" || field === "updatetime") {
					let mm: Moment | undefined = datavalue as Moment;
					if (mm !== undefined)
						val = <div>{mm.format(E5UtilI18n._("dateformat") + " HH:mm")}</div>;
				} else if (field === "ttlid") {
					let tmp: E5EntRdmTtl | undefined = this.props.ttlinfo.ttlmap.get(datavalue);
					if (tmp !== undefined) val = <div>{tmp.name}</div>;
				} else if (field === "statusid") {
					let tmp: E5EntRdmStatus | undefined = this.props.statusinfo.statusmap.get(datavalue);
					if (tmp !== undefined) {
						let imageName = '';
						if (tmp.name === 'canceled' || tmp.name === 'annulé') {
							imageName = 'canceled'
						}
						if (tmp.name === 'failure' || tmp.name === 'échec') {
							imageName = 'failure'
						}
						if (tmp.name === 'received' || tmp.name === 'reçu') {
							imageName = 'received'
						}
						if (tmp.name === 'scheduled' || tmp.name === 'programmé') {
							imageName = 'scheduled'
						}
						if (tmp.name === 'success' || tmp.name === 'succès') {
							imageName = 'success'
						}
						if (tmp.name === 'timeout' || tmp.name === 'temps mort') {
							imageName = 'timeout'
						}
						val = <div className="statusimgdiv">
							<Tooltip content={tmp.name}>
								<img src={"/img/rdm/status-" + imageName + ".png"} alt="" />
							</Tooltip>
						</div>;
					}
				} else if (field === "requestid") {
					let tmp: E5EntRdmRequest | undefined = this.props.requestinfo.requestmap.get(datavalue);
					if (tmp !== undefined) val = <div>{tmp.name}</div>;
				} else val = <div>{datavalue}</div>;
				cell = <BPT.Cell>
					<div onDoubleClick={() => this.OpenCmd(curselectedcmd)}>{val}</div>
				</BPT.Cell>;
			}
		}
		return cell;
	};

	//E5
	HeadRenderer = (field: string): JSX.Element => {
		let cell: JSX.Element, classname: IconName = "double-caret-vertical" as IconName,
			intent: Intent = Intent.NONE;
		if (this.state.sortfield === field) {
			intent = Intent.DANGER;
			if (this.state.sortdirection === E5AdminRdmSortDir.up) classname = "caret-up";
			else classname = "caret-down";
		}
		let hassort: boolean = field !== "uiaction" && E5EntRdmCommand.GetJavaSortField(field) !== "";
		cell = <div className="tbhead">
			<div className="tbheadname">{E5UtilI18n._("admin-rdm-" + field)}</div>
			{hassort && <Button icon={classname} minimal small intent={intent} className="tbheadsort"
				onClick={() => this.ChangeSort(field)} />}
		</div>;
		//
		return cell;
	};

	// ---------------- EVENTS ----------------

	//E5
	ChangeFilterInput = (field: string, event: any): void => {
		let value: string | undefined = event.currentTarget.value;
		let newfilterinput: any = this.CloneFilterInput();
		if (value === "") value = undefined;
		newfilterinput[field] = value;
		this.setState({ filterinput: newfilterinput }, () => {
			clearTimeout(this.filtertimer);
			this.filtertimer = setTimeout(() => this.ChangeFilterInput2(field, value), 600);
		});
	};

	//E5
	ChangeFilterInput2 = (field: string, value: string | undefined): void => {
		let newactivefilter: any = this.CloneActiveFilter();
		newactivefilter[field] = value;
		this.setState({ activefilter: newactivefilter, curpage: 0, maxpage: 0 }, this.LaunchSearch);
	};


	//E5
	ChangeFilterSelect = (field: string, event: any): void => {
		let value: string | undefined = event.currentTarget.value,
			filterinput: any = this.CloneFilterInput(), activefilter: any = this.CloneActiveFilter();
		if (value === "") value = undefined;
		filterinput[field] = value;
		activefilter[field] = value;
		this.setState({ filterinput, activefilter, curpage: 0, maxpage: 0 }, this.LaunchSearch);
	};

	//E5
	ChangeFilterDateRange = (field: string, isstart: boolean, seldate: Date, userchange: boolean): void => {
		if (userchange) {
			let activefilter: any = this.CloneActiveFilter(), filterinput: any = this.CloneFilterInput();
			let start: Moment | undefined = undefined, end: Moment | undefined = undefined;
			if (activefilter[field] !== undefined) {
				start = activefilter[field].start;
				end = activefilter[field].end;
			}
			let isvalid: boolean = seldate !== null && !isNaN(seldate.getTime());
			if (isstart) {
				start = undefined;
				if (isvalid) {
					start = moment(seldate);
					start.hour(2);
					start.minute(0);
					start.second(0);
				}
			} else {
				end = undefined;
				if (isvalid) {
					end = moment(seldate);
					end.hour(23);
					end.minute(59);
					end.second(59);
				}
			}
			activefilter[field] = { start, end };
			filterinput[field] = { start, end };
			this.setState({ filterinput, activefilter, curpage: 0, maxpage: 0 }, this.LaunchSearch);
		}
	};

	//E5
	ChangeSort = (sortfield: string): void => {
		let sortdirection: E5AdminRdmSortDir = E5AdminRdmSortDir.up;
		if (this.state.sortfield === sortfield && this.state.sortdirection === E5AdminRdmSortDir.up)
			sortdirection = E5AdminRdmSortDir.down;
		this.setState({ sortfield, sortdirection, curpage: 0, maxpage: 0 }, this.LaunchSearch);
	};

	//E5
	LaunchSearch = (): void =>
		E5RequestAdminRdm.Ins().LoadCommands(
			this.state.activefilter, this.state.sortfield, this.state.sortdirection,
			this.state.curpage, (status: E5RequestStatus): void => {
				if (!status.success && this.toasterref.current !== null)
					this.toasterref.current.show({
						message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message,
						intent: Intent.DANGER
					});
			});

	//E5
	OpenCmd = (curselectedcmd: E5EntRdmCommand): void => {
		let curcmdisnew: boolean = curselectedcmd.uuid === "";
		this.setState({
			dialogopen: true, cureditedcmd: curcmdisnew ? curselectedcmd : curselectedcmd.row, 
			curselectedcmd: curcmdisnew ? curselectedcmd : curselectedcmd.row, cureditedcmdchanged: false
		});
	};

	//E5
	AddCmd = (): void => {
		let cmd: E5EntRdmCommand = new E5EntRdmCommand();
		cmd.customerid = this.props.rdminfo.customerid;
		this.setState({ dialogopen: true, cureditedcmd: cmd, curselectedcmd: cmd, cureditedcmdchanged: false });
	};

	//E5
	HandleCancel = (): void => this.setState({ cancelopen: true });

	//E5
	HandleCancel2 = (): void => {
		if (this.state.curselectedcmd.statusid === this.props.statusinfo.scheduledid &&
			!this.props.uiinfo.status.loading) {
			E5RequestAdminRdm.Ins().CancelCommand(this.state.curselectedcmd.uuid, (status: E5RequestStatus) => {
				if (status.success) {
					this.CloseCancel();
					this.CloseCmd();
					this.LaunchSearch();
				} else if (this.toasterref.current !== null)
					this.toasterref.current.show({
						message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message,
						intent: Intent.DANGER
					});
			});
		}
	};

	//E5
	HandleListCancel = (curselectedcmd: E5EntRdmCommand): void =>
		this.setState({ listcancelopen: true, curselectedcmd: curselectedcmd });

	//E5
	HandleListCancel2 = (): void => {
		if (this.state.curselectedcmd.statusid === this.props.statusinfo.scheduledid && !this.props.uiinfo.status.loading) {
			E5RequestAdminRdm.Ins().CancelCommand(this.state.curselectedcmd.uuid, (status: E5RequestStatus) => {
				if (status.success) {
					this.CloseListCancel();
					this.LaunchSearch();
				} else if (this.toasterref.current !== null)
					this.toasterref.current.show({
						message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message,
						intent: Intent.DANGER
					});
			});
		}
	};

	//E5
	HandleNewCancel = (): void => {
		if (this.state.cureditedcmd.uuid !== "" && !this.props.uiinfo.status.loading) {
			E5RequestAdminRdm.Ins().CancelCommand(this.state.cureditedcmd.uuid, (status: E5RequestStatus) => {
				if (status.success) {
					this.CloseCmd();
					this.LaunchSearch();
					this.setState({ filteredData: [] });
				} else if (this.toasterref.current !== null)
					this.toasterref.current.show({
						message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message,
						intent: Intent.DANGER
					});
			});
		}
	}

	//E5
	HandleSave = (): void => {
		if (this.state.cureditedcmd.uuid === "") {
			E5RequestAdminRdm.Ins().CreateCommand(this.state.cureditedcmd, (status: E5RequestStatus) => {
				if (status.success) {
					this.CloseCmd();
					this.LaunchSearch();
					this.setState({ filteredData: [] });
				} else if (this.toasterref.current !== null)
					this.toasterref.current.show({
						message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message,
						intent: Intent.DANGER
					});
			});
		} else {
			E5RequestAdminRdm.Ins().UpdateCommand(this.state.cureditedcmd.uuid, this.state.cureditedcmd.comment,
				(status: E5RequestStatus) => {
					if (status.success) {
						this.CloseCmd();
						this.LaunchSearch();
						this.setState({ filteredData: [] });
					} else if (this.toasterref.current !== null)
						this.toasterref.current.show({
							message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message,
							intent: Intent.DANGER
						});
				});
		}
	};

	//E5
	CloseCmd = (): void => this.setState({ dialogopen: false, cancelopen: false });

	//E5
	CloseCancel = (): void => this.setState({ cancelopen: false });

	//E5
	CloseListCancel = (): void => this.setState({ listcancelopen: false });

	//E5
	CloseListFullResponse = (): void => this.setState({ listresponseopen: false });

	//E5
	CmdIsUpdated = (cmd: E5EntRdmCommand): void =>
		this.setState({ cureditedcmd: cmd, cureditedcmdchanged: true, cancelopen: false });

	//E5
	DelFilters = (): void => this.setState({ filterinput: {}, activefilter: {} }, this.LaunchSearch);

	//E5
	FirstPage = (): void => this.setState({ curpage: 0 }, this.LaunchSearch);

	//E5
	PrevPage = (): void => {
		if (this.state.curpage > 0) this.setState({ curpage: this.state.curpage - 1 }, this.LaunchSearch);
	};

	//E5
	NextPage = (): void => {
		let maxpage: number = this.state.maxpage;
		if (this.state.curpage + 1 > maxpage) maxpage = this.state.curpage + 1;
		this.setState({ curpage: this.state.curpage + 1, maxpage: maxpage }, this.LaunchSearch);
	};

	//E5
	Refresh = (): void => this.setState({ curpage: 0, maxpage: 0 }, this.LaunchSearch);

	//E5
	ChangePage = (evt: React.MouseEvent<HTMLElement>): void => {
		let pg: number = parseInt(evt.currentTarget.id.substr(3));
		this.setState({ curpage: pg }, this.LaunchSearch);
	};

	//E5
	HandleListResponse = (curselectedcmd: E5EntRdmCommand): void =>
		this.setState({ listresponseopen: true, curselectedcmd: curselectedcmd });

	// ---------------- UTILS ----------------

	//E5
	CheckHasFilter = (): boolean => {
		let key: string, has: boolean = false;
		for (key in this.state.filterinput)
			if (this.state.filterinput.hasOwnProperty(key)) {
				let value = this.state.filterinput[key];
				if (value !== undefined) {
					if (key === "createtime") {
						if (value.start !== undefined || value.end !== undefined) has = true;
					} else has = true;
					if (has) break;
				}
			}
		return has;
	};

	//E5
	CloneActiveFilter = (): any => Object.assign({}, this.state.activefilter);

	//E5
	CloneFilterInput = (): any => Object.assign({}, this.state.filterinput);

	//E5
	FormatDate = (date: Date): string => moment(date).format(E5UtilI18n._("dateformat"));

	//E5
	ParseDate = (str: string): Date => moment(str, E5UtilI18n._("dateformat")).toDate();
});
