import { E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender } from "../util/E5RestSender";
import { E5AdminRdmSortDir, E5EntRdmCommand } from "../entity/rdm/E5EntRdmCommand";
import { E5RequestCallback, E5RequestStatus } from "./E5ServiceCommon";
import { E5EntRdmRequest } from "../entity/rdm/E5EntRdmRequest";
import { E5EntRdmStatus } from "../entity/rdm/E5EntRdmStatus";
import { E5EntRdmTtl } from "../entity/rdm/E5EntRdmTtl";
import { E5MainConfig } from "../global/E5MainConfig";
import { E5StoreAdmin } from "../store/E5StoreAdmin";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5Storage } from "../util/E5Storage";
import { E5Request } from "./E5Request";

//E5
export enum E5RequestAdminRdmTags {
	NONE = "", LOADTTLLIST = "loadttllist", LOADSTATUSLIST = "loadstatuslist", LOADREQUESTLIST = "loadrequestlist",
	LOADCOMMANDS = "loadcommands", CREATECOMMAND = "createcommand", UPDATECOMMAND = "updatecommand",
	CANCELCOMMAND = "cancelcommand"
}

//E5
export class E5RequestAdminRdm implements E5RestResponder {

	// --------- STATIC -------------

	//E5
	private static ins: E5RequestAdminRdm;

	//E5
	static Ins = (): E5RequestAdminRdm => {
		if (this.ins === undefined) this.ins = new E5RequestAdminRdm();
		return this.ins;
	};

	// --------- INSTANCE -------------

	//E5
	sender: E5RestSender;
	tokenmap: Map<string, number>;

	//E5
	constructor() {
		this.sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v1/operator/request");
		this.tokenmap = new Map();
	}

	//E5
	RequestCallback = (resp: E5RestResponse): void => {
		let requesttag: any = resp.request.otherdata.requesttag;
		if (requesttag === E5RequestAdminRdmTags.LOADTTLLIST) this.LoadTtlListCB(resp);
		else if (requesttag === E5RequestAdminRdmTags.LOADSTATUSLIST) this.LoadStatusListCB(resp);
		else if (requesttag === E5RequestAdminRdmTags.LOADREQUESTLIST) this.LoadRequestListCB(resp);
		else if (requesttag === E5RequestAdminRdmTags.LOADCOMMANDS) this.LoadCommandsCB(resp);
		else if (requesttag === E5RequestAdminRdmTags.CREATECOMMAND) this.CreateCommandCB(resp);
		else if (requesttag === E5RequestAdminRdmTags.UPDATECOMMAND) this.UpdateCommandCB(resp);
		else if (requesttag === E5RequestAdminRdmTags.CANCELCOMMAND) this.CancelCommandCB(resp);
	};

	//E5
	CancelTag = (tag: E5RequestAdminRdmTags): void => this.sender.Cancel(this.tokenmap.get(tag));

	//E5
	ClearAll() {
		E5StoreAdmin.Ins().SetRdmTtlList({ loading: false, success: false, message: "" }, []);
		E5StoreAdmin.Ins().SetRdmStatusList({ loading: false, success: false, message: "" }, []);
		E5StoreAdmin.Ins().SetRdmRequestList({ loading: false, success: false, message: "" }, []);
		E5StoreAdmin.Ins().SetRdmCmds({ loading: false, success: false, message: "" }, []);
		E5StoreAdmin.Ins().SetRdmUi({ loading: false, success: false, message: "" });

		E5RequestAdminRdm.Ins().CancelTag(E5RequestAdminRdmTags.LOADTTLLIST);
		E5RequestAdminRdm.Ins().CancelTag(E5RequestAdminRdmTags.LOADSTATUSLIST);
		E5RequestAdminRdm.Ins().CancelTag(E5RequestAdminRdmTags.LOADREQUESTLIST);
		E5RequestAdminRdm.Ins().CancelTag(E5RequestAdminRdmTags.LOADCOMMANDS);
		E5RequestAdminRdm.Ins().CancelTag(E5RequestAdminRdmTags.CREATECOMMAND);
		E5RequestAdminRdm.Ins().CancelTag(E5RequestAdminRdmTags.UPDATECOMMAND);
		E5RequestAdminRdm.Ins().CancelTag(E5RequestAdminRdmTags.CANCELCOMMAND);
	};

	// --------- Init -------------

	LoadLists = (lang: string): void => {
		if (E5MainConfig.GetRdmEnabled())
			this.LoadTtlList((): void => this.LoadStatusList((): void =>
				this.LoadRequestList(undefined, lang), lang), lang);

	};


	// --------- Load TtlList -------------

	//E5
	LoadTtlList = (callback: E5RequestCallback, lang = 'en'): void => {
		E5StoreAdmin.Ins().SetRdmTtlList({ loading: true, success: false, message: "" }, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestAdminRdmTags.LOADTTLLIST));
		let req: E5RestRequest = {
			method: "GET", uri: `/ttl?lang=${lang}`, body: undefined,
			header: [
				["Content-Type", "application/json"],
				["Authorization", E5Storage.GetLSString(E5MainConfig.ls_bearer)]
			],
			otherdata: { requesttag: E5RequestAdminRdmTags.LOADTTLLIST, callback }
		};
		this.tokenmap.set(E5RequestAdminRdmTags.LOADTTLLIST, this.sender.Send(req));
	};

	//E5
	LoadTtlListCB = (resp: E5RestResponse): void => {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			status.message = E5UtilI18n._("error-rdm-403-401");
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let ttllist: E5EntRdmTtl[] = [];
			if (status.success && Array.isArray(resp.body)) for (let json of resp.body)
				ttllist.push(new E5EntRdmTtl(json));
			E5StoreAdmin.Ins().SetRdmTtlList(status, ttllist);
			if (ttllist.length > 0)
				E5StoreAdmin.Ins().SetRdmTimeZone(ttllist[0].timezone);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	};

	// --------- Load StatusList -------------

	//E5
	LoadStatusList = (callback: E5RequestCallback, lang = 'en'): void => {
		E5StoreAdmin.Ins().SetRdmStatusList({ loading: true, success: false, message: "" }, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestAdminRdmTags.LOADSTATUSLIST));
		let req: E5RestRequest = {
			method: "GET", uri: `/status?lang=${lang}`, body: undefined,
			header: [
				["Content-Type", "application/json"],
				["Authorization", E5Storage.GetLSString(E5MainConfig.ls_bearer)]
			],
			otherdata: { requesttag: E5RequestAdminRdmTags.LOADSTATUSLIST, callback }
		};
		this.tokenmap.set(E5RequestAdminRdmTags.LOADSTATUSLIST, this.sender.Send(req));
	};

	//E5
	LoadStatusListCB = (resp: E5RestResponse): void => {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			status.message = E5UtilI18n._("error-rdm-403-401");
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let statuslist: E5EntRdmStatus[] = [];
			if (status.success && Array.isArray(resp.body)) for (let json of resp.body)
				statuslist.push(new E5EntRdmStatus(json));
			E5StoreAdmin.Ins().SetRdmStatusList(status, statuslist);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	};

	// --------- Load Requestlist -------------

	//E5
	LoadRequestList = (callback: E5RequestCallback, lang = 'en'): void => {
		E5StoreAdmin.Ins().SetRdmRequestList({ loading: true, success: false, message: "" }, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestAdminRdmTags.LOADREQUESTLIST));
		let req: E5RestRequest = {
			method: "GET", uri: `/available?lang=${lang}`, body: undefined,
			header: [
				["Content-Type", "application/json"],
				["Authorization", E5Storage.GetLSString(E5MainConfig.ls_bearer)]
			],
			otherdata: { requesttag: E5RequestAdminRdmTags.LOADREQUESTLIST, callback }
		};
		this.tokenmap.set(E5RequestAdminRdmTags.LOADREQUESTLIST, this.sender.Send(req));
	};

	//E5
	LoadRequestListCB = (resp: E5RestResponse): void => {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			status.message = E5UtilI18n._("error-rdm-403-401");
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let requestlist: E5EntRdmRequest[] = [];
			if (status.success && Array.isArray(resp.body)) for (let json of resp.body)
				requestlist.push(new E5EntRdmRequest(json));
			E5StoreAdmin.Ins().SetRdmRequestList(status, requestlist);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	};

	// --------- Load Commands -------------

	//E5
	LoadCommands = (filters: any, sortfield: string, sortdirection: E5AdminRdmSortDir,
		pagenumber: number, callback: E5RequestCallback): void => {
		E5StoreAdmin.Ins().SetRdmCmds({ loading: true, success: false, message: "" }, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestAdminRdmTags.LOADCOMMANDS));

		let params: string = "?pageNbr=" + pagenumber + "&pageSize=" + 1000 +
			"&orderField=" + E5EntRdmCommand.GetJavaSortField(sortfield) + "&orderType=" + sortdirection;
		if (filters.firmware !== undefined)
			params += "&firmwareId=" + filters.firmware;
		if (filters.userlogin !== undefined)
			params += "&loginId=" + filters.userlogin;
		if (filters.model !== undefined)
			params += "&modelId=" + filters.model;
		if (filters.nodeimei !== undefined)
			params += "&deviceId=" + filters.nodeimei;
		if (filters.networkid !== undefined)
			params += "&networkId=" + filters.networkid;
		if (filters.requestid !== undefined)
			params += "&requestId=" + filters.requestid;
		if (filters.uuid !== undefined)
			params += "&requestUuid=" + filters.uuid;
		if (filters.statusid !== undefined)
			params += "&statusId=" + filters.statusid;
		if (filters.createtime !== undefined) {
			if (filters.createtime.start !== undefined)
				params += "&startTime=" + filters.createtime.start.unix();
			if (filters.createtime.end !== undefined)
				params += "&endTime=" + filters.createtime.end.unix();
		}

		let req: E5RestRequest = {
			method: "GET", uri: "" + params, body: undefined,
			header: [
				["Content-Type", "application/json"],
				["Authorization", E5Storage.GetLSString(E5MainConfig.ls_bearer)]
			],
			otherdata: { requesttag: E5RequestAdminRdmTags.LOADCOMMANDS, callback, pagenumber }
		};
		this.tokenmap.set(E5RequestAdminRdmTags.LOADCOMMANDS, this.sender.Send(req));
	};

	//E5
	LoadCommandsCB = (resp: E5RestResponse): void => {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		let data: E5EntRdmCommand[] = [];
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			status.message = E5UtilI18n._("error-rdm-403-401");
		} else if (resp.status !== 200 && resp.status !== 404) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			let cmdlist: E5EntRdmCommand[] = [];
			if (status.success && Array.isArray(resp.body)) for (let json of resp.body) {
				let entcmd: E5EntRdmCommand = new E5EntRdmCommand();
				entcmd.timezone = E5StoreAdmin.Ins().rdminfo.timezone;
				entcmd.CopyFromJson(json);
				cmdlist.push(entcmd);
			}
			E5StoreAdmin.Ins().SetRdmCmds(status, cmdlist);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status, data);
	};

	// --------- Create Command -------------

	//E5
	CreateCommand = (cmd: E5EntRdmCommand, callback: E5RequestCallback): void => {
		E5StoreAdmin.Ins().SetRdmUi({ loading: true, success: false, message: "" });
		this.sender.Cancel(this.tokenmap.get(E5RequestAdminRdmTags.CREATECOMMAND));
		let req: E5RestRequest = {
			method: "POST", uri: "", body: cmd.CopyToCreate(),
			header: [
				["Content-Type", "application/json"],
				["Authorization", E5Storage.GetLSString(E5MainConfig.ls_bearer)]
			],
			otherdata: { requesttag: E5RequestAdminRdmTags.CREATECOMMAND, callback }
		};
		this.tokenmap.set(E5RequestAdminRdmTags.CREATECOMMAND, this.sender.Send(req));
	};

	//E5
	CreateCommandCB = (resp: E5RestResponse): void => {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		let data: E5EntRdmCommand[] = [];
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			status.message = E5UtilI18n._("error-rdm-403-401");
		} else if (resp.status !== 202) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreAdmin.Ins().SetRdmUi(status);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status, data);
	};

	// --------- Update Command -------------

	//E5
	UpdateCommand = (uuid: string, comment: string, callback: E5RequestCallback): void => {
		E5StoreAdmin.Ins().SetRdmUi({ loading: true, success: false, message: "" });
		this.sender.Cancel(this.tokenmap.get(E5RequestAdminRdmTags.UPDATECOMMAND));
		let req: E5RestRequest = {
			method: "PUT", uri: "/comment", body: { comment, requestUuid: uuid },
			header: [
				["Content-Type", "application/json"],
				["Authorization", E5Storage.GetLSString(E5MainConfig.ls_bearer)]
			],
			otherdata: { requesttag: E5RequestAdminRdmTags.UPDATECOMMAND, callback }
		};
		this.tokenmap.set(E5RequestAdminRdmTags.UPDATECOMMAND, this.sender.Send(req));
	};

	//E5
	UpdateCommandCB = (resp: E5RestResponse): void => {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		let data: E5EntRdmCommand[] = [];
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			status.message = E5UtilI18n._("error-rdm-403-401");
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreAdmin.Ins().SetRdmUi(status);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status, data);
	};

	// --------- Cancel Command -------------

	//E5
	CancelCommand = (uuid: string, callback: E5RequestCallback): void => {
		E5StoreAdmin.Ins().SetRdmUi({ loading: true, success: false, message: "" });
		this.sender.Cancel(this.tokenmap.get(E5RequestAdminRdmTags.CANCELCOMMAND));
		let req: E5RestRequest = {
			method: "PUT", uri: "/cancel", body: { requestUuid: uuid },
			header: [
				["Content-Type", "application/json"],
				["Authorization", E5Storage.GetLSString(E5MainConfig.ls_bearer)]
			],
			otherdata: { requesttag: E5RequestAdminRdmTags.CANCELCOMMAND, callback }
		};
		this.tokenmap.set(E5RequestAdminRdmTags.CANCELCOMMAND, this.sender.Send(req));
	};

	//E5
	CancelCommandCB = (resp: E5RestResponse): void => {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		let data: E5EntRdmCommand[] = [];
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 401 || resp.status === 403) {
			status.message = E5UtilI18n._("error-rdm-403-401");
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else {
			status.success = true;
		}
		try {
			E5StoreAdmin.Ins().SetRdmUi(status);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status, data);
	};
}
