import { Button, Dialog, Intent, Toaster } from '@blueprintjs/core';
import React, { useState } from 'react'
import { E5Entity } from '../../../entity/E5Entity';
import { E5EntHEquipmentRdm } from '../../../entity/household/equipment/E5EntHEquipmentRdm'
import { E5EntHEquip } from '../../../entity/household/topo/E5EntHEquip';
import { E5EntRdmCommand } from '../../../entity/rdm/E5EntRdmCommand';
import Table from '../../../global/component/E5Table';
import InputStyledField from '../../../global/component/InputStyledField';
import SelectStyled from '../../../global/component/SelectStyled';
import { E5UtilI18n } from '../../../global/E5MainLang';
import { E5RequestAdminRdm } from '../../../request/E5RequestAdminRdm';
import { E5RequestH } from '../../../request/E5RequestH';
import { E5RequestStatus } from '../../../request/E5ServiceCommon';
import { E5Store } from '../../../store/E5Store';
import { E5StoreAdmin } from '../../../store/E5StoreAdmin';
import { E5StoreH } from '../../../store/E5StoreH';
import { E5Text } from '../../../util/E5Text';
import { E5AdminRdmPopupWiFiScanResult, TableColumns } from '../../admin/rdm/E5AdminRdmPopup';
import './E5HEquipmentItemRdm.css';

interface E5HEquipmentRdmProps {
    actions: E5EntHEquipmentRdm[];
    equip: E5EntHEquip;
}

export default function E5HEquipmentItemRdm(props: Readonly<E5HEquipmentRdmProps>) {
    // force rerender when lang changes
    const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

    const { rdmttlinfo, rdmrequestinfo, rdmstatusinfo, rdminfo } = E5StoreAdmin.Ins();
    const { searchniinfo } = E5StoreH.Ins();

    const ttlOptions = rdmttlinfo?.ttllist
        .map((ttlValues) => { return { label: ttlValues.name, value: ttlValues.id }; })
        .sort((a, b) => a.value - b.value);
    const typeOptions = rdmrequestinfo?.requestlist
        .map((requests) => { return { label: requests.name, value: requests.id }; })
        .sort((a, b) => a.value - b.value);

    const [ttl, setTtl] = useState<number>(ttlOptions[0]?.value);
    const [type, setType] = useState<number>(typeOptions[0]?.value);
    const [comment, setComment] = useState<string>('');

    const [isModalDetailsOpen, setIsModalDetailsOpen] = useState<boolean>(false);
    const [isModalCancelOpen, setIsModalCancelOpen] = useState<boolean>(false);

    const [action, setAction] = useState<E5EntHEquipmentRdm>();
    const [actionUuidDelete, setActionUuidDelete] = useState<string>('');

    const [cancelIcon, setCancelIcon] = useState<Array<string>>(new Array<string>(props.actions.length).fill("/img/rdm/delete.svg"));

    const toasterref = React.createRef<Toaster>();

    const setActionData = (data: E5EntHEquipmentRdm) => {
        setAction(data);
        setIsModalDetailsOpen(true);
    };

    const getStatus = (statusId?: number) => {
        const status = rdmstatusinfo.statuslist.find((data) => data.id === statusId);
        return <div className={`role-in-table rdm-status-id-${status?.id}`}>{`${status?.name[0].toUpperCase()}${status?.name.slice(1)}`}</div>
    }

    const createAction = () => {
        const command = new E5EntRdmCommand();
        command.ttlid = ttl;
        command.requestid = type;
        command.comment = comment;
        command.userlogin = E5Store.Ins().userinfo.curuser.username;
        command.model = props.equip.model;
        command.nodeimei = props.equip.imei;
        command.networkid = searchniinfo.networkid;
        command.customerid = rdminfo.customerid;
        command.firmware = props.equip.softwarev;

        E5RequestAdminRdm.Ins().CreateCommand(command, (status: E5RequestStatus) => {
            if (status.success) {
                E5RequestH.Ins().FetchEquipmentRdm();
            } else {
                if (toasterref.current) {
                    toasterref.current.show({
                        message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message,
                        intent: Intent.DANGER
                    });
                }
            }
        })
    }

    const updateIconDelete = (index: number, focus: boolean = true) => {
        const icons = [...cancelIcon];

        if (focus) {
            icons[index] = "/img/rdm/delete-red.svg";
        } else {
            icons[index] = "/img/rdm/delete.svg";
        }

        setCancelIcon(icons);
    }

    const setupModalCancel = (id: string) => {
        setActionUuidDelete(id);
        setIsModalCancelOpen(true);
    }

    const deleteAction = (id: string) => {
        setIsModalCancelOpen(false);
        E5RequestAdminRdm.Ins().CancelCommand(id, (status: E5RequestStatus) => {
            if (status.success) {
                E5RequestH.Ins().FetchEquipmentRdm();
            } else {
                if (toasterref.current) {
                    toasterref.current.show({
                        message: E5UtilI18n._("admin-rdm-reqerror") + " : " + status.message,
                        intent: Intent.DANGER
                    });
                }
            }
        })
    }

    const getResponse = (data: any) => {
        if (!data) {
            return;
        }

        const command = new E5EntRdmCommand();
        command.CopyFromJson(data);

        const statusdesc = rdmstatusinfo.statusmap.get(command.statusid)?.description;
        return (
            <div className="e5admin-rdm-statusdesc e5admin-popup-verticalspacement">
                <div className="text-center-start">
                    <span className="dialog-text-highlighted">{E5UtilI18n._("admin-rdm-response")}: </span>
                    <span className="text-spacement">{statusdesc}</span>
                    {command.statusreason.split("Possible reasons:")[0] ? <br /> : null}
                    {command.statusreason.split("Possible reasons:")[0]}
                </div>

                <div className="top-spacement">
                    <span className="e5admin-rdm-highlighted-text">{E5UtilI18n._("admin-rdm-possiblereason")}:</span>
                    {command.statusreason.split("Possible reasons:")[1]}
                </div>
            </div>
        );
    }

    const getResponseWifiScan = (data: any) => {
        if (!data) {
            return;
        }

        const command = new E5EntRdmCommand();
        command.CopyFromJson(data);

        return (
            <div className="e5admin-rdm-statusdesc e5admin-popup-verticalspacement">
                <Table className='' rowHeight={35}
                    columns={[
                        {
                            field: 'bssid',
                            headerName: 'BSSID',
                            editable: true,
                            headerAlign: 'center',
                            align: 'left',
                            width: 160,
                            renderCell: (params: TableColumns) => {
                                return params.row.bssid;
                            },
                        },
                        {
                            field: 'channel',
                            headerName: 'Channel',
                            editable: true,
                            headerAlign: 'center',
                            align: 'left',
                            width: 80,
                            renderCell: (params: TableColumns) => {
                                return params.row.channel;
                            },
                        },
                        {
                            field: 'mode',
                            headerName: 'Mode',
                            editable: true,
                            headerAlign: 'center',
                            align: 'left',
                            width: 80,
                            renderCell: (params: TableColumns) => {
                                return params.row.mode;
                            },
                        },
                        {
                            field: 'radio',
                            headerName: 'Radio',
                            editable: true,
                            headerAlign: 'center',
                            align: 'left',
                            width: 100,
                            renderCell: (params: TableColumns) => {
                                return params.row.radio;
                            },
                        },
                        {
                            field: 'rssi',
                            headerName: 'RSSI',
                            editable: true,
                            headerAlign: 'center',
                            align: 'left',
                            width: 80,
                            renderCell: (params: TableColumns) => {
                                return params.row.rssi;
                            },
                        },
                        {
                            field: 'signalStrength',
                            headerName: 'Strength',
                            editable: true,
                            headerAlign: 'center',
                            align: 'left',
                            width: 90,
                            renderCell: (params: TableColumns) => {
                                return params.row.signalStrength;
                            },
                        },
                        {
                            field: 'ssid',
                            headerName: 'SSID',
                            editable: true,
                            headerAlign: 'center',
                            align: 'left',
                            renderCell: (params: TableColumns) => {
                                return params.row.ssid;
                            },
                        }
                    ]}
                    rows={[
                        ...parseJSON(command.response).map((item, index) => {
                            return ({
                                ...item,
                                id: index,
                            });
                        })
                    ]}
                />
            </div>
        )
    }

    const parseJSON = (text: string): E5AdminRdmPopupWiFiScanResult[] => {
        try {
            const parsed = JSON.parse(text);
            return parsed.wifiScanResult;
        } catch (e) {
            return [];
        }
    }

    return (
        <div className="e5equipment-rdm">
            <Toaster ref={toasterref} />
            <div className="text-center-start">
                <span className="underline-text">
                    {E5UtilI18n._('h-equipment-rdm-create-action')}
                </span>
            </div>

            <div className="top-spacement equipment-rdm">
                <SelectStyled
                    className="ttlSelect"
                    onChange={(event: any) => setTtl(event)}
                    label={E5UtilI18n._("admin-rdm-ttlid")}
                    value={ttl}
                    defaultValue={ttlOptions[0]?.value}
                    data={ttlOptions}
                    required
                />

                <div className="form-spacement">
                    <SelectStyled
                        className="typeSelect"
                        onChange={(event: any) => setType(event)}
                        label={E5UtilI18n._("admin-rdm-requestid")}
                        value={type}
                        defaultValue={typeOptions[0]?.value}
                        data={typeOptions}
                        required
                    />
                </div>

                <div className="form-spacement">
                    <InputStyledField
                        id="commentInput"
                        label={E5UtilI18n._("admin-rdm-comment")}
                        variant="outlined"
                        autoComplete="true"
                        onChange={(event: any) => setComment(event.target.value)}
                        value={comment}
                        inputProps={{ sx: { borderRadius: 3 } }}
                    />
                </div>

                <div className="form-spacement">
                    <Button text={E5UtilI18n._("admin-rdm-createaction")} onClick={() => createAction()} />
                </div>
            </div>

            <div className="text-center-start top-spacement">
                <span className="underline-text">
                    {E5UtilI18n._('h-equipment-rdm-last-action').replace('#', props.actions.length.toString())}
                </span>
            </div>

            <div className="top-spacement e5columnfull">
                <Table className='rdm-equipment-table' rowHeight={35}
                    columns={[
                        {
                            field: 'cancel',
                            headerName: '',
                            editable: true,
                            headerAlign: 'center',
                            align: 'center',
                            width: 35,
                            height: 45,
                            renderCell: (params: any) => {
                                return (
                                    <div className="cancel" onClick={() => setupModalCancel(params.row.requestUuid)}
                                        onMouseEnter={() => updateIconDelete(params.id)} onMouseLeave={() => updateIconDelete(params.id, false)}>
                                        <img src={cancelIcon[params.id]} alt={E5UtilI18n._("delete")} />
                                    </div>
                                );
                            },
                        },
                        {
                            field: 'createtime',
                            headerName: E5UtilI18n._("admin-rdm-createtime"),
                            headerAlign: 'center',
                            align: 'center',

                            height: 45,
                            renderCell: (params: any) => {
                                const creationTime = E5Text.Tm2MomentTzStr(E5Entity.ParseInt(params.row.creationTime), "");
                                return creationTime?.format(E5UtilI18n._("dateformat") + " HH:mm")
                            },
                        },
                        {
                            field: 'userlogin',
                            headerName: E5UtilI18n._("admin-rdm-userlogin"),
                            headerAlign: 'center',
                            align: 'center',

                            height: 45,
                            renderCell: (params: any) => {
                                return params.row.loginId;
                            },
                        },
                        {
                            field: 'type',
                            headerName: E5UtilI18n._("admin-rdm-requestid"),
                            headerAlign: 'center',
                            align: 'center',

                            height: 45,
                            renderCell: (params: any) => {
                                const request = rdmrequestinfo.requestlist.find((requests) => requests.id === params.row.requestId);
                                return request?.name;
                            },
                        },
                        {
                            field: 'status',
                            headerName: E5UtilI18n._("admin-rdm-statusid"),
                            headerAlign: 'center',
                            align: 'center',

                            height: 45,
                            renderCell: (params: any) => {
                                return getStatus(params.row.requestStatusId);
                            }
                        },
                        {
                            field: 'ttlid',
                            headerName: 'TTL',
                            editable: true,
                            headerAlign: 'center',

                            height: 45,
                            align: 'center',
                            renderCell: (params: any) => {
                                const currentTtl = rdmttlinfo.ttllist.find((item) => item.id === params.row.ttlId)
                                return currentTtl?.name;
                            },
                        },
                        {
                            field: 'response',
                            headerName: E5UtilI18n._("admin-rdm-response"),
                            headerAlign: 'center',
                            align: 'center',

                            height: 45,
                            renderCell: (params: any) => {
                                return <Button className="rdm-equipment-table-details" text={E5UtilI18n._("wifih-health-desc-details")} onClick={() => setActionData(params.row)} />;
                            },
                        }
                    ]}
                    rows={props.actions.map((data, index) => {
                        return {
                            ...data,
                            id: index,
                        }
                    })}
                />
            </div>

            <Dialog isOpen={isModalCancelOpen} onClose={() => setIsModalCancelOpen(false)}>
                <div className="center-alignment">
                    <span className="dialog-title">{E5UtilI18n._("admin-rdm-cancel")}</span>
                </div>

                <div className="top-spacement bp3-dialog-body">
                    <span>{E5UtilI18n._("admin-rdm-cancel-confirm")}</span>
                </div>

                <div className="bp3-dialog-footer">
                    <div className="bp3-dialog-footer-actions">
                        <Button className="e5-rdm-deletebutton e5-rdm-adm-nomargin" text={E5UtilI18n._("admin-rdm-cancel")} onClick={() => deleteAction(actionUuidDelete)} />
                        <Button className="e5-rdm-cancel" text={E5UtilI18n._("admin-rdm-closenocancel")} onClick={() => setIsModalCancelOpen(false)} />
                    </div>
                </div>

            </Dialog>

            <Dialog isOpen={isModalDetailsOpen} onClose={() => setIsModalDetailsOpen(false)} className="equipment-rdm-dialog">
                <div className="center-alignment">
                    <span className="dialog-title">{E5UtilI18n._("admin-rdm-response")}</span>
                </div>

                <div className="top-spacement bp3-dialog-body">
                    <div className="dialog-title-grid">
                        <div className="text-center-start">
                            <span className="dialog-text-highlighted">{E5UtilI18n._("admin-rdm-requestid")}: </span>
                            <span className="text-spacement">{rdmrequestinfo.requestlist.find((requests) => requests.id === action?.requestId)?.name}</span>
                        </div>

                        <div className="text-center-start">
                            <span className="dialog-text-highlighted">{E5UtilI18n._("h-equipment-date")}: </span>
                            <span className="text-spacement">{E5Text.Tm2MomentTzStr(E5Entity.ParseInt(action?.creationTime), "").format(E5UtilI18n._("dateformat") + " HH:mm")}</span>
                        </div>
                    </div>

                    <div className="dialog-title-grid top-spacement">
                        <div className="text-center-start">
                            <span className="dialog-text-highlighted">{E5UtilI18n._("admin-rdm-statusid")}: </span>
                            <span className="text-spacement">{getStatus(action?.requestStatusId)}</span>
                        </div>

                        <div className="text-center-start">
                            <span className="dialog-text-highlighted">Id: </span>
                            <span className="text-spacement">{action?.requestUuid}</span>
                        </div>
                    </div>

                    <div className="top-spacement">
                        {action?.requestId === 8 && action?.requestStatusId === 4 ? getResponseWifiScan(action) : getResponse(action)}
                    </div>
                </div>

                <div className="bp3-dialog-footer">
                    <div className="bp3-dialog-footer-actions">
                        <Button text={E5UtilI18n._("cancel")} onClick={() => setIsModalDetailsOpen(false)} />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
